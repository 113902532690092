export const getXFirstAndYLast = (str: string, x: number, y: number): string => {
  if (str.length <= x + y) {
    return str;
  }
  const firstString: string = str.substring(0, x);
  const lastString: string = str.substring(str.length - y);
  return firstString.concat('...', lastString);
};

export const checkIsSupportMultiChain = (futuresName: string): boolean => {
  if (!futuresName) {
    return false;
  }
  const futuresSupportMultiChain = JSON.parse(process.env.REACT_APP_FEATURES_SUPPORT_MULTI_CHAIN || '[]');
  let isSupportMultiChain = false;
  futuresSupportMultiChain.forEach((e) => {
    if (futuresName.includes(e)) {
      isSupportMultiChain = true;
      return;
    }
  });
  return isSupportMultiChain;
};
