/* eslint-disable prefer-template */
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import Web3 from 'web3';
import './App.css';
import withClearCache from './ClearCache';
import PageMeta from './components/PageMeta';
import RouteCustom from './components/RouteCustom';
import { LIST_LANGUAGE } from './configs';
import useEagerConnect from './hooks/useEagerConnect';
import './i18n/config';
import { checkAndGetRefreshToken, useFetchPriceList, useFetchProfile, useFetchPublicData } from './redux/hooks';
import routes from './routes';
import NotFoundPage from './views/NotFoundPage';
import 'react-popper-tooltip/dist/styles.css';
import LayoutNoneFooter from './layout/LayoutNoneFooter';
import { useColorMode } from '@chakra-ui/react';
import { ApiENV, SwapCalculation } from '@positionex/swap';
import store from './redux/store';
import { setIsInitSdk } from './redux/swap/actions';
import { switchNetworkTransporter } from './services/SwitchNetworkTransporter';
import { checkIsSupportMultiChain } from './utils/stringUtil';
import useAuth from './hooks/useAuth';
import { sourceChains } from './configs/constants/bridgeAsset';
import { getNetworkDetail } from './adapters/services/BridgeAssetService';
import UAParser from 'ua-parser-js';

let currentNetwork;

function getCookie(cName: string) {
  const name = `${cName}=`;
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

export const instanceOfPosiExSwap = new SwapCalculation(
  // @ts-ignore
  process.env.REACT_APP_CHAIN_ID || '56',
  ApiENV.PROD,
  process.env.REACT_APP_NODE_3,
);

export const modeInUA = 'mode=posi_app';

function MainApp() {
  const parser = new UAParser();
  const uaString = parser.getResult().ua;
  if (uaString.indexOf(modeInUA) > -1) {
    window.isPosiApp = true;
    window.isUsePosiAppModal = true;
  }

  useEagerConnect();
  useFetchPublicData();
  useFetchProfile();
  useFetchPriceList();
  checkAndGetRefreshToken();
  const { toggleColorMode } = useColorMode();

  useEffect(() => {
    instanceOfPosiExSwap.init(['pancake']).then(() => {
      window.initTrade = true;
      store.dispatch(setIsInitSdk({ isInitSdk: true }));
    });
  }, []);

  const { account, chainId } = useWeb3React();
  const location = useLocation();
  const { search } = location;
  const refIdRaw = new URLSearchParams(search).get('ref');
  const mode = new URLSearchParams(search).get('mode');
  const themeParams = new URLSearchParams(search).get('theme');
  const { i18n } = useTranslation();

  const dispatchWhenLoginWithReferral = useCallback(async () => {
    if (
      account &&
      document.cookie &&
      chainId &&
      !sessionStorage.getItem('cancelSign') &&
      !sessionStorage.getItem('loading')
    ) {
      const refId = getCookie('refId');
      sessionStorage.setItem('loading', '1');
      if (refId && Number(refId) && !Number.isNaN(refId) && Number.isInteger(Number(refId))) {
        try {
          const response = await axios({
            method: 'GET',
            baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
            url: `/fetchRefInfo`,
            params: {
              address: account,
            },
          }).then((res) => res.data);
          if (!response?.exist) {
            try {
              const web3 = new Web3(Web3.givenProvider);
              const paramsQuery = `Agree to register referral for user ${refId} on ${new Date().toUTCString()}`;
              // @ts-ignore
              const signature = web3.currentProvider.bnbSign
                ? // @ts-ignore
                  (await web3.currentProvider.bnbSign(account, paramsQuery))?.signature
                : await web3.eth.personal.sign(web3.utils.utf8ToHex(paramsQuery), account, null as any);
              if (signature) {
                await axios({
                  method: 'POST',
                  baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
                  url: `/connect`,
                  data: {
                    address: account,
                    refId: Number(refId),
                    message: paramsQuery,
                    signature,
                  },
                }).then((res) => res.data);
                Cookies.remove('refId', {
                  domain: '.position.exchange',
                  path: '/',
                });
                Cookies.remove('refId', {
                  domain: 'localhost',
                  path: '/',
                });
                Cookies.remove('refId', {
                  domain: '.ngrok.io',
                  path: '/',
                });
              }
            } catch (err: any) {
              sessionStorage.setItem('cancelSign', '1');
            }
          }
        } catch (e: any) {}
      }
    }
  }, [account, document.cookie, chainId]);

  useEffect(() => {
    dispatchWhenLoginWithReferral();
  }, [dispatchWhenLoginWithReferral]);

  useEffect(() => {
    if (location) {
      const { search } = location;
      const refId = new URLSearchParams(search).get('ref');
      if (Number(refId) && !Number.isNaN(refId) && Number.isInteger(Number(refId))) {
        try {
          Cookies.set('refId', refId?.toString() || '', {
            expires: 30,
            domain: '.position.exchange',
            path: '/',
          });
          Cookies.set('refId', refId?.toString() || '', {
            expires: 30,
            domain: 'localhost',
            path: '/',
          });
          Cookies.set('refId', refId?.toString() || '', {
            expires: 30,
            domain: '.ngrok.io',
            path: '/',
          });
        } catch (e: any) {}
      }
      const boundaryTest = new URLSearchParams(search).get('boundaryTest');
      if (boundaryTest) {
        throw Error('Boundary Test');
      }
    }
  }, [location]);

  window.addEventListener('beforeunload', () => {
    sessionStorage.removeItem('cancelSign');
    sessionStorage.removeItem('loading');
  });

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      const docs = document.getElementsByTagName('iframe');
      if (docs && docs.length > 0) {
        for (let i = 0; i < docs.length; i++) {
          if (docs[i].style.position == 'fixed') {
            docs[i].classList.add('d-none');
            clearInterval(interval);
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const { pathname } = useLocation();
  const pageDarkLight = ['/', '/swap'];
  const isActiveActionDarkLight = pageDarkLight.includes(pathname);

  useEffect(() => {
    const currentTheme = localStorage.getItem('chakra-ui-color-mode');
    const memoTheme = localStorage.getItem('memo-theme');
    if (!isActiveActionDarkLight) {
      if (currentTheme === 'light') {
        toggleColorMode();
      }
    } else {
      if (currentTheme !== memoTheme) {
        toggleColorMode();
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (isActiveActionDarkLight) {
      localStorage.setItem('memo-theme', localStorage.getItem('chakra-ui-color-mode'));
    }
  }, [localStorage.getItem('chakra-ui-color-mode')]);

  useEffect(() => {
    if (localStorage.getItem('lang')) {
      const exist = LIST_LANGUAGE.find((i) => i?.value === localStorage.getItem('lang'));
      if (exist) {
        i18n.changeLanguage(localStorage.getItem('lang'));
      } else {
        i18n.changeLanguage('en');
        localStorage.setItem('lang', 'en');
      }
    } else {
      i18n.changeLanguage('en');
      localStorage.setItem('lang', 'en');
    }
  }, []);

  useEffect(() => {
    if (!!themeParams && window.isPosiApp) {
      if (isActiveActionDarkLight) {
        const currentTheme = localStorage.getItem('chakra-ui-color-mode');
        if (currentTheme !== themeParams) {
          toggleColorMode();
        }
      }
    }
  }, [themeParams, isActiveActionDarkLight, window.isPosiApp]);

  (() => {
    const subscribe = switchNetworkTransporter.onMessage().subscribe((detail: any) => {
      currentNetwork = detail;
    });
    return subscribe.unsubscribe;
  })();
  const isSupportMultiChain = window && checkIsSupportMultiChain(window.location.pathname);
  const { logout } = useAuth();
  useEffect(() => {
    if (!isSupportMultiChain && currentNetwork?.nativeCurrency !== 'BNB' && currentNetwork) {
      logout();
      const defaultSourceChain = sourceChains[0];
      const sourceChainDetail = getNetworkDetail(defaultSourceChain.name);
      switchNetworkTransporter.sendMessage(sourceChainDetail);
    }
  }, [currentNetwork, isSupportMultiChain]);
  return (
    <>
      <PageMeta />
      <Switch>
        {routes.map((item) => (
          <RouteCustom key={item?.path} {...item} />
        ))}
        <Redirect from="/liquidity/#/add/" to="/liquidity/#/add/0x5CA42204cDaa70d5c773946e69dE942b85CA6706/BNB" />
        <Redirect from="/liquidity/#/add/:currencyIdA" to="/liquidity/#/add/:currencyIdA/BNB" />
        {refIdRaw && <Redirect to={`/${refIdRaw ? `?ref=${refIdRaw}` : ''}`} />}
        <RouteCustom component={NotFoundPage} layout={LayoutNoneFooter} />
      </Switch>
    </>
  );
}

export default App;
