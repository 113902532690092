import Web3 from "web3";
import Web3Modal from "web3modal";

const providerOptions = {
  /* See Provider Options Section */
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions // required
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const web3Connect = async () => {
  const provider = await web3Modal.connect();
  const web3 = new Web3(provider);
  return web3
}
export const getLibrary = (provider:any): Web3 => {
  return provider
}