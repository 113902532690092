import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Flex, Image, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import { Login } from '../WalletModal/types';
import { generateAvatar } from '../../utils/common';
import { useWeb3React } from '@web3-react/core';
import { WrappedTokenInfo } from '../../redux/lists/hooks';
import { POSI_MAIN, POSI_TEST } from '../../constants';
import { useModalInfo } from '../../hooks/useModalInfo';
import ModalInformation from '../ModalInfomation';
import { getListPendingTransactions } from '../../redux/transactions/updater';
import { usePopper } from 'react-popper';
import { useDispatch } from 'react-redux';
import { setDetailShowedModalInfo } from '../../redux/global/reducer';
import { DetailRenderModalInfo } from '../../redux/types';
import ConnectToWallet from '../ConnectToWallet/ConnectWalletNew';

const avatarGenerate = generateAvatar();

interface Props {
  account?: string;
  login: Login;
  logout: () => void;
}

let timeInterval: any = null;
const UserBlock: React.FC<Props> = ({ account, logout }) => {
  const [formState, setFormState] = useState<any>(null);
  const [isSmallThan479] = useMediaQuery('(max-width: 479px ) and (min-width: 0px )');
  const [isSmallThan768] = useMediaQuery('(max-width: 768px ) and (min-width: 0px )');
  const [isSmallThan992] = useMediaQuery('(max-width: 992px ) and (min-width: 0px )');
  const { chainId, account: accountGet } = useWeb3React();

  const listPendingTransaction = getListPendingTransactions() || [];

  const posiToken = chainId === 56 ? new WrappedTokenInfo(POSI_MAIN, []) : new WrappedTokenInfo(POSI_TEST, []);

  const [isFirst, setIsFirst] = useState(false);

  const getUserInfo = useCallback(() => {
    if (accountGet && posiToken && accountGet !== '' && chainId) {
      useModalInfo(accountGet ?? undefined, posiToken ?? undefined, isSmallThan768 ? 4 : 6, chainId).then((res) =>
        setFormState(res),
      );
    }
  }, [accountGet, posiToken, isSmallThan768, chainId]);

  useEffect(() => {
    if (!accountGet) {
      setFormState(null);
    }
    clearInterval(timeInterval);
    getUserInfo();
    timeInterval = setInterval(() => {
      getUserInfo();
    }, 10000);
    return () => clearInterval(timeInterval);
  }, [accountGet, isSmallThan768]);

  useEffect(() => {
    getUserInfo();
  }, [chainId])

  const [showBalance, setShowBalance] = useState(false);

  const handleSetIsShowBalance = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (showBalance) {
      localStorage.setItem('showBalance', '2');
    } else {
      localStorage.setItem('showBalance', '1');
    }
    setShowBalance(!showBalance);
  };

  useEffect(() => {
    if (localStorage.getItem('showBalance') && !isFirst) {
      const item = Number(localStorage.getItem('showBalance')) || 1;
      setShowBalance(item === 1 ? true : false);
      setIsFirst(true);
    }
  }, [localStorage.getItem('showBalance')]);

  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'fixed',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener('mouseenter', showDropdownMenu);
    targetRef?.addEventListener('mouseleave', hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu);
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(setDetailShowedModalInfo(DetailRenderModalInfo.default));
    }
  }, [isOpen]);

  const gray700ToGray100 = useColorModeValue('gray.100', 'gray.700');
  const whiteToGray700 = useColorModeValue('gray.700', 'white');
  const bgModalInformation = useColorModeValue('#fff', 'gray.800');
  const borderWidth = useColorModeValue('0px', '1px');
  const borderColor = useColorModeValue('none', 'gray.700');
  const boxShadow = useColorModeValue('0px 4px 24px rgba(0, 0, 0, 0.12)', 'none');

  return (
    <div>
      {account ? (
        <Flex ref={setTargetRef} h="60px" align={'center'}>
          <Button
            scale="sm"
            position="relative"
            onTouchStart={() => {
              setIsOpen((s) => !s);
            }}
            maxWidth="272px"
            height={['35px', '35px', '35px', '40px']}
            w="100%"
            px={['15px', '15px', '15px', '15px', '15px', '15px']}
            bg={gray700ToGray100}
            color={whiteToGray700}
            _hover={{
              background: gray700ToGray100,
            }}
            _active={{
              background: 'none',
            }}
            borderRadius="36px"
            fontSize={['13px', '13px', '13px', '13px', '16px', '16px']}
          >
            {showBalance ? (
              <Text as="span" id="text-1" mr={isSmallThan479 ? ['20px', '35px', '35px', '35px'] : []}>
                {formState?.balanceOfPosi || 0}&nbsp;
              </Text>
            ) : (
              <Text as="span" mt="5px" id="text-1" mr={isSmallThan479 ? ['20px', '35px', '35px', '35px'] : []}>
                ******&nbsp;
              </Text>
            )}

            <Text hidden={isSmallThan479} as="span" color="teal.150" mr={['20px', '35px', '35px', '35px']}>
              POSI
            </Text>
            {listPendingTransaction && listPendingTransaction.length ? (
              <Flex
                w={['35px', '35px', '35px', '40px']}
                h={['35px', '35px', '35px', '40px']}
                position="absolute"
                right="0px"
                top="0px"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress
                  size={isSmallThan992 ? '35px' : '40px'}
                  px="0px"
                  position="absolute"
                  right="0px"
                  thickness="5px"
                  top="0px"
                  isIndeterminate
                  color="teal.150"
                  capIsRound={true}
                />
                <Text color="teal.150" fontSize="14px">
                  {listPendingTransaction.length || 0}
                </Text>
              </Flex>
            ) : (
              <Image
                src={avatarGenerate}
                w={['35px', '35px', '35px', '40px']}
                h={['35px', '35px', '35px', '40px']}
                position="absolute"
                right="0px"
                top="0px"
                borderRadius="36px"
              />
            )}
          </Button>
          {isOpen && (
            <Flex style={styles.popper} ref={setTooltipRef} {...attributes.popper}>
              <Flex
                mt="6px"
                isSmallThan479={isSmallThan479}
                id="content-1"
                visibility={!isOpen ? 'hidden' : 'visible'}
                zIndex="999"
                pointerEvents={!isOpen ? 'none' : 'auto'}
                bg={bgModalInformation}
                borderWidth={borderWidth}
                borderColor={borderColor}
                boxShadow={boxShadow}
                borderRadius="24px"
                w={isSmallThan479 ? '320px' : '400px'}
              >
                <ModalInformation
                  handleSetIsShowBalance={handleSetIsShowBalance}
                  showBalance={showBalance}
                  pendingTx={listPendingTransaction.length}
                  logout={logout}
                  dataState={formState}
                  account={account || ''}
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      ) : (
        <ConnectToWallet size="button3" maxWidth="272px" width="100%" />
      )}
    </div>
  );
};

export default React.memo(UserBlock, (prevProps, nextProps) => prevProps.account === nextProps.account);
