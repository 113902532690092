import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BONDS_URL}/v1/api`,
  timeout: 10000,
});

export const getBondStatistic = () => {
  return instance({
    method: 'GET',
    url: `/bonds/statistics?type=launchpad`,
  }).then((response) => response.data.data);
};
