import { Bond } from '../redux/types';

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
}

export interface Address {
  97?: string;
  56?: string; // TODO: delete ? in mainnet
}

export interface Token {
  symbol: string;
  address?: Address;
  decimals?: number;
  projectLink?: string;
}

export interface FarmConfig {
  pid: number;
  lpSymbol: string;
  lpAddresses: Address;
  token: Token;
  quoteToken: Token;
  multiplier?: string;
  isCommunity?: boolean;
  dual?: {
    rewardPerBlock: number;
    earnLabel: string;
    endBlock: number;
  };
  type?: string;
}

export enum PoolType {
  EarnPOSI,
  EarnToken, // stake POSI earn token X
}

export enum POOL_CATEGORY {
  DEFAULT,
  NFT,
  BOND,
}

export enum LIQUIDITY_NFT {
  liquidityNFT = 'LiquidityNFT',
}

export interface TokenLiquidityExchange {
  symbol: string;
  contract_address: Address;
  logo_url?: string;
}

export interface PairLiquidityExchange {
  base: TokenLiquidityExchange;
  quote: TokenLiquidityExchange;
}

export interface PoolConfig {
  id: number;
  pname?: string;
  sousId: number;
  type: PoolType;
  poolCategory: POOL_CATEGORY;
  earningToken: Token;
  stakingToken: Token;
  contractAddress: Address;
  isFinished?: boolean;
  poolImage?: string;
  harvestLockupPeriod?: number;
  idBE?: number;
  bondData?: Bond;
  pair?: PairLiquidityExchange;
  depositFee?: string;
  startBlock?: number;
  endBlock?: number;
  periodFinishTime?: number;
  apr: number;
  poolKey?: Address;
  liquidityPoolAddress?: Address;
  harvestFee?: number;
  decimalFormat?: {
    earning: number;
  };
  abiName?: string;
  isScheduleHarvest?: boolean;
  needMigration?: boolean;
  isCompoundable?: boolean;
  pairManager?: Address;
}

export interface LendingPoolReserveData {
  pid: string;
  aTokenAddress: string;
  availableLiquidity: string;
  averageStableRate: string;
  baseLTVasCollateral: string;
  borrowingEnabled: boolean;
  decimals: string;
  interestRateStrategyAddress: string;
  isActive: boolean;
  isFrozen: boolean;
  lastUpdateTimestamp: string;
  liquidityIndex: string;
  liquidityRate: string;
  name: string;
  price: {
    priceInEth: string;
    priceInBnb: string;
  };
  priceInEth: string;
  priceInBnb: string;
  reserveFactor: string;
  reserveLiquidationBonus: string;
  reserveLiquidationThreshold: string;
  stableBorrowRate: string;
  stableBorrowRateEnabled: boolean;
  stableDebtLastUpdateTimestamp: string;
  stableDebtTokenAddress: string;
  stableRateSlope1: string;
  stableRateSlope2: string;
  symbol: string;
  totalPrincipalStableDebt: string;
  totalScaledVariableDebt: string;
  underlyingAsset: string;
  usageAsCollateralEnabled: boolean;
  variableBorrowIndex: string;
  variableBorrowRate: string;
  variableDebtTokenAddress: string;
  variableRateSlope1: string;
  variableRateSlope2: string;
}

/* Example data
"principalStableDebt": "0",
"scaledATokenBalance": "999927488937249033",
"scaledVariableDebt": "99975130070055583878",
"stableBorrowLastUpdateTimestamp": "0",
"stableBorrowRate": "0",
"underlyingAsset": "0xD32f54FA3D5BC2c6f93c8d4a75a7cF0AEBFb7415",
"usageAsCollateralEnabledOnUser": true
 */
export interface LendingPoolUserReserveData {
  pid: string;
  principalStableDebt: string;
  scaledATokenBalance: string;
  scaledVariableDebt: string;
  stableBorrowLastUpdateTimestamp: string;
  stableBorrowRate: string;
  underlyingAsset: string;
  usageAsCollateralEnabledOnUser: boolean;
  underlyingBalance: string;
  underlyingBalanceBNB: string;
  underlyingBalanceUSD: string;
  variableBorrowIndex: string;
  variableBorrows: string;
  variableBorrowsBNB: string;
  variableBorrowsUSD: string;
  stableBorrows: string;
  stableBorrowsBNB: string;
  stableBorrowsUSD: string;
  totalBorrows: string;
  totalBorrowsBNB: string;
  totalBorrowsUSD: string;
}

/**
 * @dev Returns the user account data across all the reserves
 * @param user The address of the user
 * @return totalCollateralETH the total collateral in ETH of the user
 * @return totalDebtETH the total debt in ETH of the user
 * @return availableBorrowsETH the borrowing power left of the user
 * @return currentLiquidationThreshold the liquidation threshold of the user
 * @return ltv the loan to value of the user
 * @return healthFactor the current health factor of the user
 * */

export interface LendingPoolUserAccountData {
  totalCollateralETH: string;
  totalCollateralUSD: string;
  totalDebtETH: string;
  totalDebtUSD: string;
  availableBorrowsETH: string;
  availableBorrowsUSD: string;
  currentLiquidationThreshold: string;
  ltv: string;
  healthFactor: string;
}

export interface TokenBalance {
  [address: string]: string;
}

export interface listItemNft {
  id: string;
  character: string;
  productivity: string;
  image: string;
  star: string;
  stardissable: string;
  miningPower: string | number;
}

export interface CustomHTMLProps {
  bg?: string[] | string;
  pl?: string[] | string;
  maxH?: string[] | string;
  maxW?: string[] | string;
  color?: string[] | string;
  bgHover?: string[] | string;
  w?: string[] | string;
  h?: string[] | string;
  mb?: string[] | string;
  isDisable?: boolean;
  fontSize?: string[] | string;
  placeholder?: string;
  onBlur?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  borderRadius?: string[] | string;
  mt?: string[] | string;
  p?: string[] | string;
  mx?: string[] | string;
  fontWeight?: string[] | string;
  textAlign?: any;
  justifyContent?: any;
  alignItems?: any;
  boxShadow?: any;
}

export enum TabMenu {
  'SWAP' = 'swap',
  'EARN' = 'earn',
  'DERIVATIVES' = 'derivatives',
  'WIN' = 'win',
  'NFTS' = 'nfts',
  'NULL' = '',
  'OTHERS' = 'others',
  'BONDS' = 'bonds',
  REFERRAL = 'referral',
}

export interface BondPool {
  earningToken: Token;
  poolImage: string;
  description: string;
  harvestLockup: number;
  listPool: BondPoolItem[];
  totalReward: number;
}

export interface BondPoolItem {
  pid: number;
  idBE: number;
  pname: string;
  apr: number;
  contractAddress: Address;
  stakingToken: Token;
  stakingTokenImage: string;
  endTime: number;
  activeTime: number;
  lastPrice: number;
  faceAssetName: string;
}
