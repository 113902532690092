import { CopyIcon } from '@chakra-ui/icons';
import {
  Button,
  ChakraProvider,
  Flex,
  Image as ImageCharka,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode-svg';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import farmConfig from '../../configs/constants/farms';
import themeContext from '../../configs/contexts/ThemeContext';
import poolConfig from '../../configs/pools';
import { getBscScanLink } from '../../utils';
import { formatNumberWithNumeral } from '../../utils/common';

interface IModalHarvestSuccess {
  isOpenModal: boolean;
  onDismiss?: (val?: any) => void;
  objectResult?: any;
}

enum TypeOfConfig {
  farm = farmConfig as any,
  pool = poolConfig as any,
}

const ModalHarvestSuccess: React.FC<IModalHarvestSuccess> = ({ isOpenModal, onDismiss = () => null, objectResult }) => {
  const [isSmallThan1024] = useMediaQuery('(max-width: 768px ) and (min-width: 0px )');

  const [posiPrice, setPosiPrice] = useState(0);
  const [referralCode, setReferralCode] = useState(null);
  const [canvasData, setCanvasData] = useState(null);
  const [linkToShare, setLinkToShare] = useState('');

  const { t } = useTranslation(['swap', 'common']);

  const type = objectResult?.type === 'farm' ? t('common:farms') : t('common:pools');
  const currentPair = (TypeOfConfig[objectResult?.type] as any).find(
    (a: any) => Number(a.pid || a.sousId || 0) === Number(objectResult?.pid),
  );

  let nameOfPair;
  if (objectResult?.type === 'farm') {
    nameOfPair = currentPair.lpSymbol.replace(' LP', ` ${t('common:farm')}`);
  } else {
    if (Number(objectResult?.pid) === 1) {
      nameOfPair = `POSI ${t('common:pool')}`;
    } else if (Number(objectResult?.pid) !== 100) {
      nameOfPair = currentPair.pname || '';
    } else {
      nameOfPair = `NFT ${t('common:pool')}`;
    }
  }

  useEffect(() => {
    if (posiPrice && referralCode) {
      const canvas = document.getElementById('myCanvas') as HTMLCanvasElement;
      if (canvas) {
        try {
          const context = canvas.getContext('2d');
          // @ts-ignore
          const imageObj = new Image(1800, 900);
          imageObj.onload = function () {
            context.drawImage(imageObj, 0, 0, 1800, 900);

            context.font = '40px Poppins';
            context.fillStyle = '#F3FF64';
            context.fillText(type, 115, 205);
            context.fillText(nameOfPair, 315, 205);

            context.font = '600 112px Poppins';
            context.fillStyle = '#28FF94';
            context.fillText(`+${formatNumberWithNumeral(objectResult?.earning, 2)} POSI`, 115, 460);

            context.font = '56px Poppins';
            context.fillStyle = '#00E7FF';
            context.fillText(`~$${formatNumberWithNumeral(objectResult?.earning * posiPrice, 2)}`, 130, 550);

            context.font = '500 56px Poppins';
            context.fillStyle = '#FFFFFF';
            context.fillText(referralCode, 340, 740);
            const qrcodjs = new QRCode({
              content: `https://position.exchange/?ref=${referralCode}`,
              width: 190,
              height: 190,
              padding: 3,
              color: '#000000',
              background: '#ffffff',
              ecl: 'L',
            }).svg();
            const DOMURL = window.URL || window.webkitURL || window;
            const imgQR = new Image();
            const svg = new Blob([qrcodjs], { type: 'image/svg+xml' });
            // @ts-ignore
            const url = DOMURL.createObjectURL(svg);
            imgQR.onload = function () {
              context.drawImage(imgQR, 115, 625);
              // @ts-ignore
              DOMURL.revokeObjectURL(url);
            };
            imgQR.src = url;

            setTimeout(() => {
              const base64Data = canvas.toDataURL('image/png');
              const newData = base64Data.replace(/^data:image\/[a-z]+;base64,/, '');
              uploadImageToServer(newData);
              setCanvasData(base64Data);
            }, 500);
          };
          imageObj.src = '/img/harvest_success.png';
        } catch (e) {}
      }
    }
  }, [posiPrice, referralCode, type, nameOfPair, objectResult]);

  const uploadImageToServer = async (imgData: string) => {
    try {
      const response = await axios({
        method: 'POST',
        baseURL: process.env.REACT_APP_UPLOAD,
        url: '/upload',
        data: { referralCode, imageData: imgData },
      }).then((res) => res.data);
      if (response?.url) {
        setLinkToShare(`${process.env.REACT_APP_UPLOAD}${response?.url}`);
      }
    } catch (e) {}
  };

  const callApiGetData = useCallback(async () => {
    if (objectResult?.from) {
      try {
        const responsePrice = await fetch(`${process.env.REACT_APP_PRICE_BASE_URL}/prices`).then((res) => res.json());
        if (responsePrice?.prices?.POSI) {
          setPosiPrice(responsePrice?.prices?.POSI);
        }
        const responseReferral = await fetch(
          `${process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL}/link?address=${objectResult?.from}`,
        ).then((res) => res.json());
        if (responseReferral?.data?.refId) {
          setReferralCode(responseReferral?.data?.refId);
        }
      } catch (e) {}
    }
  }, [objectResult?.from]);

  useEffect(() => {
    callApiGetData();
  }, [callApiGetData]);

  const link = getBscScanLink(
    Number(process.env.REACT_APP_CHAIN_ID || 56),
    objectResult?.transactionHash,
    'transaction',
  );

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
  function displayTooltip() {
    setIsTooltipDisplayed(true);
    setTimeout(() => {
      setIsTooltipDisplayed(false);
    }, 1500);
  }

  const copyToClipboardWithCommand = (content: string) => {
    copy(content, { format: 'text/plain' });
    displayTooltip();
  };

  const handleDownloadPicture = () => {
    const linkToDownload = document.createElement('a');
    document.body.appendChild(linkToDownload);
    linkToDownload.download = 'Harvest_image.png';
    linkToDownload.href = canvasData;
    linkToDownload.click();
    setTimeout(() => {
      document.body.removeChild(linkToDownload);
    }, 2000);
  };

  return (
    <>
      <ChakraProvider theme={themeContext}>
        <Modal
          size={isSmallThan1024 ? 'lg' : 'xl'}
          isCentered
          blockScrollOnMount={true}
          isOpen={isOpenModal}
          onClose={onDismiss}
        >
          <ModalOverlay width="100%" height="100%" />
          <ModalContent zIndex="9999" background={'gray.800'} borderRadius="24px" py={0} px={0}>
            <ModalCloseButton py="0px" align="center" color={'gray.500'} />
            <ModalBody px={['8px', '8px', '24px', '24px', '24px', '24px']} pt="0px">
              <Flex flexDirection={'column'} pt={'38px'} justifyContent={'center'}>
                <Flex textAlign={'center'} flexDirection={'column'} justifyContent={'center'}>
                  <canvas width="1800px" height="900px" id="myCanvas" hidden={true} />
                  <Flex id="harvest-banner" zIndex="0" position="relative" width="100%">
                    {canvasData ? <ImageCharka src={canvasData} w="100%" /> : <Skeleton w="100%" h="150px" />}
                  </Flex>
                  <Flex color="white" mt="16px" fontSize="14px" alignItems="center" justifyContent="center">
                    {t('common:share_with')}:{' '}
                    {linkToShare ? (
                      <>
                        <Link href={`https://www.reddit.com/submit?url=${linkToShare}`} ml="8px" isExternal>
                          <ImageCharka src={`/img/reddit.svg`} w={'24px'} h={'24px'} />
                        </Link>
                        <Link href={`https://telegram.me/share/url?url=${linkToShare}`} ml="8px" isExternal>
                          <ImageCharka src={`/img/telegram.svg`} w={'24px'} h={'24px'} />
                        </Link>
                        <Link href={`https://twitter.com/share?url=${linkToShare}`} ml="8px" isExternal>
                          <ImageCharka src={`/img/twitter.svg`} w={'24px'} h={'24px'} />
                        </Link>
                        <Link href={`https://www.facebook.com/sharer.php?u=${linkToShare}`} ml="8px" isExternal>
                          <ImageCharka src={`/img/facebook.svg`} w={'24px'} h={'24px'} />
                        </Link>
                      </>
                    ) : (
                      <Skeleton w="150px" h="24px" />
                    )}
                  </Flex>
                  {linkToShare && (
                    <Flex
                      py="4px"
                      px="8px"
                      bg="gray.700"
                      width="75%"
                      maxW="75%"
                      mx="auto"
                      borderRadius="16px"
                      justifyContent="center"
                      mt="8px"
                      color="gray.500"
                      fontSize="13px"
                    >
                      <Flex w="auto" whiteSpace={'nowrap'} overflow="hidden" textOverflow="ellipsis">
                        {linkToShare}
                      </Flex>
                      <Tooltip label={t('Copied')} hasArrow={true} placement="bottom" isOpen={isTooltipDisplayed}>
                        <CopyIcon
                          ml="8px"
                          cursor="pointer"
                          fontSize="18px"
                          color="gray.500"
                          onClick={() => {
                            copyToClipboardWithCommand(linkToShare);
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  )}
                  <Flex w="100%" justify="space-between" flexDirection={['column', 'row', 'row', 'row', 'row', 'row']}>
                    <Button
                      onClick={() => window.open(link)}
                      mx={'auto'}
                      mt={['32px', '32px', '32px', '32px', '32px', '32px']}
                      mb={['0px', '32px', '32px', '32px', '32px', '32px']}
                      background={'blue.400'}
                      _hover={{ background: 'blue.400' }}
                      color={'white'}
                      borderRadius={'24px'}
                      h={'48px'}
                      w={['100%', '48%', '48%', '48%', '48%', '48%']}
                    >
                      {t('swap:View on BscScan')}
                    </Button>
                    <Button
                      mx={'auto'}
                      mt={['16px', '32px', '32px', '32px', '32px', '32px']}
                      mb={['0px', '32px', '32px', '32px', '32px', '32px']}
                      background={'blue.400'}
                      _hover={{ background: 'blue.400' }}
                      color={'white'}
                      borderRadius={'24px'}
                      onClick={handleDownloadPicture}
                      h={'48px'}
                      w={['100%', '48%', '48%', '48%', '48%', '48%']}
                    >
                      {t('common:download')}
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};

export default ModalHarvestSuccess;
