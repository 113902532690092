import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';
import IUniswapV2Router02ABI from '../constants/IUniswapV2Router02.json';
import { ChainId, Currency, CurrencyAmount, ETHER, JSBI, Percent, Token } from '@pancakeswap/sdk';
import { ROUTER_ADDRESS, ROUTER_ADDRESS_POSITION_EX } from '../constants';
import { TokenAddressMap } from '../redux/lists/hooks';
import routerPositionExchange from '../configs/abi/posi-router-swap.json';
import { getAddress as getAddressCustom } from '../utils/addressHelpers';
import { explorerUrlPerChainId } from '../configs/constants/bridgeAsset';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// const BSCSCAN_PREFIXES: { [chainId in ChainId]: string } = {
//   56: '',
//   97: 'testnet.',
// };

export function getChainId(): ChainId {
  // TODO get chainId from provider
  return process.env.REACT_APP_CHAIN_ID as unknown as ChainId;
}

export function getBscScanLink(
  chainId: ChainId,
  urlHash: string | number,
  type: 'transaction' | 'token' | 'address' | 'countdown' | 'block',
): string {
  chainId = chainId || getChainId();
  const prefix = explorerUrlPerChainId[chainId];

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${urlHash}`;
    }
    case 'token': {
      return `${prefix}/token/${urlHash}`;
    }
    case 'countdown': {
      return `${prefix}/block/countdown/${urlHash}`;
    }
    case 'block': {
      return `${prefix}/block/${urlHash}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${urlHash}`;
    }
  }
}

export const getSnapshotProposalLink = (hash: string) => {
  const origin = window.location.origin;
  const link = `${origin}/governance/community/${hash}`;
  return link;
};

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000));
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(Math.floor(num)), JSBI.BigInt(10000));
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`);
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ];
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(getAddressCustom(ROUTER_ADDRESS), IUniswapV2Router02ABI, library, account);
}

// account is optional
export function getRouterContractPositionExchange(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(getAddressCustom(ROUTER_ADDRESS_POSITION_EX), routerPositionExchange, library, account);
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency?.symbol === ETHER?.symbol) return true;
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address]);
}

export const getLinkToExplorer = (baseUrl, hash, type = 'transaction') => {
  return `${baseUrl}tx/${hash}`;
};
