import { Flex, Text, Tabs, TabList, Tab, TabPanels, TabPanel, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatBalanceWithNumerals } from '../../utils/common';
import FarmItem from './FarmItem';
import PoolItem from './PoolItem';
import VaultItem from './VaultItem';
interface ModalLockBalanceProps {
  account: any;
  dataState: any;
  isSmallerThan768: any;
  showBalance: boolean;
}

const LockedBalance: React.FC<ModalLockBalanceProps> = ({ isSmallerThan768, account, dataState, showBalance }) => {
  const { t } = useTranslation('header');

  const renderValue = (value: any) => {
    if (!showBalance) return '✲✲✲✲✲✲';

    if (isSmallerThan768) {
      return formatBalanceWithNumerals(Number(value), 4);
    }
    return formatBalanceWithNumerals(Number(value), 6);
  };

  const bgTab = useColorModeValue('gray.100', '#171923');
  const gray400ToGray500 = useColorModeValue('gray.500', 'gray.400');
  const whiteToGray700 = useColorModeValue('gray.700', 'white');
  const bgTabSelect = useColorModeValue('#fff', 'gray.700');
  const boxShadow = useColorModeValue('0px 2px 4px rgba(0, 0, 0, 0.1)', 'none');
  const classNameScroll = useColorModeValue('css-scroll css-scroll--light', 'css-scroll');
  const tabPanelStyles = {
    maxHeight: ['156px', '222px'],
    overflow: 'auto',
    className: classNameScroll,
  };
  return (
    <Flex p="10px" w={'100%'}>
      <Tabs variant="unstyled" w={'100%'}>
        <Flex bg={bgTab} borderRadius={'16px'} p={['4px', '8px', '8px', '8px', '8px']} w={'100%'}>
          <TabList w={'100%'}>
            <Tab
              color={gray400ToGray500}
              w={'33%'}
              borderRadius={'12px'}
              _selected={{ bg: bgTabSelect, color: whiteToGray700, boxShadow: boxShadow }}
            >
              <Text fontWeight="500" fontSize={'14px'} fontFamily="Poppins" fontStyle="normal">
                {t('farming')}
              </Text>
            </Tab>
            <Tab
              color={gray400ToGray500}
              w={'33%'}
              borderRadius={'12px'}
              _selected={{ bg: bgTabSelect, color: whiteToGray700, boxShadow: boxShadow }}
            >
              <Text fontWeight="500" fontSize={'14px'} fontFamily="Poppins" fontStyle="normal">
                {t('vaults')}
              </Text>
            </Tab>
            <Tab
              color={gray400ToGray500}
              w={'33%'}
              borderRadius={'12px'}
              _selected={{ bg: bgTabSelect, color: whiteToGray700, boxShadow: boxShadow }}
            >
              <Text fontWeight="500" fontSize={'14px'} fontFamily="Poppins" fontStyle="normal">
                {t('pool')}
              </Text>
            </Tab>
          </TabList>
        </Flex>
        <TabPanels p={'0px'} w={'100%'}>
          <TabPanel p={'0px'} {...tabPanelStyles}>
            <Flex direction={'column'}>
              {dataState?.listAnalyticsFarm?.map((farm) => (
                <FarmItem
                  key={`farm${farm?.id}`}
                  account={account}
                  value={farm?.locked}
                  valueUsd={farm?.lockedUsd}
                  farm={farm}
                  renderValues={renderValue}
                />
              ))}
            </Flex>
          </TabPanel>
          <TabPanel p="0px" {...tabPanelStyles}>
            <Flex direction={'column'} mb="10px">
              {dataState?.listAnalyticsVault?.map((vault) => (
                <VaultItem
                  key={`vault${vault?.id}`}
                  value={vault?.locked}
                  valueUsd={vault?.lockedUsd}
                  vault={vault}
                  account={account}
                  renderValues={renderValue}
                />
              ))}
            </Flex>
          </TabPanel>
          <TabPanel p={'0px'} {...tabPanelStyles}>
            {dataState?.listAnalyticsPool?.map((pool) => (
              <PoolItem
                key={`vault${pool?.id}`}
                account={account}
                value={pool?.locked}
                valueUsd={pool?.lockedUsd}
                dataState={pool}
                renderValues={renderValue}
              />
            ))}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default LockedBalance;
