const correctProductivity = (grade: string | number, productivity: string | number): number => {
  grade = Number(grade);
  productivity = Number(productivity);
  if (grade == 1 && productivity > 5000) {
    return 5000;
  } else if (grade == 2 && productivity > 8000) {
    return 8000;
  } else if (grade == 3 && productivity > 9000) {
    return 9000;
  } else if (grade == 4 && productivity > 9800) {
    return 9500;
  } else if (grade == 5 && productivity > 9980) {
    return 9970;
  } else if (grade == 6 && productivity > 10000) {
    return 9980;
  }
  return productivity;
};

export const calculateMiningPower = (grade: string | number, productivity: string | number, value: number) => {
  productivity = correctProductivity(grade, productivity);
  grade = `${grade}`;
  switch (grade) {
    case '1':
      const v = (1.1 + (0.1 * Number(productivity)) / 5000) * value;
      return v;
    case '2':
      return (1.2 + (0.1 * (Number(productivity) - 5000)) / 3000) * value;
    case '3':
      return (1.3 + (0.1 * (Number(productivity) - 8000)) / 1000) * value;
    case '4':
      return (1.4 + (0.2 * (Number(productivity) - 9000)) / 800) * value;
    case '5':
      return (1.6 + (0.2 * (Number(productivity) - 9800)) / 180) * value;
    case '6':
      return (1.8 + (0.2 * (Number(productivity) - 9980)) / 20) * value;
    case '7':
      return (1.8 + (0.2 * (Number(productivity) - 9980)) / 20) * value;
    case '8':
      return (1.8 + (0.2 * (Number(productivity) - 9980)) / 20) * value;
    case '9':
      return (1.8 + (0.2 * (Number(productivity) - 9980)) / 20) * value;
  }
};

export const calculateMiningEfficiency = (grade: string | number, productivity: string | number) => {
  grade = `${grade}`;
  productivity = correctProductivity(grade, productivity);
  switch (grade) {
    case '1':
      return (1.1 + (0.1 * Number(productivity)) / 5000) * 100;
    case '2':
      return (1.2 + (0.1 * (Number(productivity) - 5000)) / 3000) * 100;
    case '3':
      return (1.3 + (0.1 * (Number(productivity) - 8000)) / 1000) * 100;
    case '4':
      return (1.4 + (0.2 * (Number(productivity) - 9000)) / 800) * 100;
    case '5':
      return (1.6 + (0.2 * (Number(productivity) - 9800)) / 180) * 100;
    case '6':
      return (1.8 + (0.2 * (Number(productivity) - 9980)) / 20) * 100;
    case '7':
      return (1.8 + (0.2 * (Number(productivity) - 9980)) / 20) * 100;
    case '8':
      return (1.8 + (0.2 * (Number(productivity) - 9980)) / 20) * 100;
    case '9':
      return (1.8 + (0.2 * (Number(productivity) - 9980)) / 20) * 100;
  }
};

export const getNameNft = (grade: string | number) => {
  grade = `${grade}`;
  switch (grade) {
    case '1':
      return 'Farmer';
    case '2':
      return 'Janitor';
    case '3':
      return 'Accountant';
    case '4':
      return 'Engineer';
    case '5':
      return 'Pilot';
    case '6':
      return 'Boss';
    case '7':
      return 'Dreamer';
    case '8':
      return 'Challenger';
    case '9':
      return 'Conqueror';
  }
};

export const getImage = (grade: string | number) => {
  grade = `${grade}`;
  switch (grade) {
    case '1':
      return '/img/farmer.png';
    case '2':
      return '/img/janitor.png';
    case '3':
      return '/img/accountant.png';
    case '4':
      return '/img/engineer.png';
    case '5':
      return '/img/pilot.png';
    case '6':
      return '/img/boss.png';
    case '7':
      return 'https://storage.googleapis.com/app.position.exchange/assets/dreamer-strange.svg'
    case '8':
      return 'https://storage.googleapis.com/app.position.exchange/assets/challenger.svg'
    case '9':
      return 'https://storage.googleapis.com/app.position.exchange/assets/conquer-strange.svg'
  }
};

export const calculatePercent = (soFar: string | number, vsValue: string | number) => {
  const percent = (Number(vsValue) !== 0 && ((Number(soFar) - Number(vsValue)) / Number(vsValue)) * 100) || 0;
  return percent?.toFixed(2);
};

export const calculateEarnedFromReferral = (soFar: number, vsValue: number) => {
  const earned = soFar - vsValue;
  return earned || 0;
};
