import { Box, Divider, Flex, Image, Link, Text, Tooltip, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { connectorLocalStorageKey } from '../WalletModal/config';
import TotalBalance from './TotalBalance';
import LockedContainer from './LockedContainer';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { getBscScanLink } from '../../utils';
import { setDetailShowedModalInfo } from '../../redux/global/reducer';
import { DetailRenderModalInfo, Pool } from '../../redux/types';
import {
  useFarmInRedux,
  useGetApiPrices,
  useGetDetailShowedModalInfo,
  usePoolsWithoutUserData,
} from '../../redux/hooks';
import { getBondLastPriceByUsd, getTitleInfoModal } from '../../utils/common';
import Loader from '../../constants/loading.json';
import Lottie from 'react-lottie';
import { Farm, TYPE_OF_FARM } from '../../configs/constants/types';
import { formatBalance } from '../../utils/formatBalance';
import { POOL_CATEGORY } from '../../configs/types';
import { formatBigNumber } from '../../hooks/useModalInfo';
import BigNumber from 'bignumber.js';

interface ModalInformationProps {
  dataState: any;
  logout: any;
  account: string;
  handleSetIsShowBalance: (e: any) => void;
  showBalance: boolean;
  pendingTx: number;
}

const analyticUserData = (farms: Farm[], pools: Pool[]) => {
  const listAnalyticsFarm = [];
  const listAnalyticsPool = [];
  const listAnalyticsVault = [];
  let totalUsdLocked = 0;
  let totalUsdEarning = 0;
  const prices = useGetApiPrices();
  farms.forEach((farm: Farm) => {
    if (!farm?.isFinished) {
      if (farm?.type === TYPE_OF_FARM.MANUAL) {
        const p = prices[farm?.name?.replace(/[^A-Za-z0-9]/g, '_').toLowerCase() || ''];
        const lockedUsd = Number(farm?.userData?.lpStaked) * Number(p);
        const earnedUsd =
          Number(farm?.userData?.earnings) * Number(prices[farm?.earnedToken?.symbol.toLowerCase() || 'ptx']);
        listAnalyticsFarm.push({
          ...farm,
          locked: Number(farm?.userData?.lpStaked),
          lockedUsd: lockedUsd,
          earningsUsd: earnedUsd,
          earnings: Number(farm?.userData?.earnings),
        });
        totalUsdLocked += lockedUsd;
        totalUsdEarning += earnedUsd;
      } else {
        const tokenPrice = prices[farm.token.symbol.toLowerCase()];
        const rewardPrice = prices[farm.rewardPriceLabel.toLowerCase()];
        const amountEarned =
          Number(farm.userData?.earnings) * rewardPrice * (farm?.token?.symbol === 'BNB' ? tokenPrice : 1);

        const earned = Number(farm?.userData?.earnings) * Number(rewardPrice);

        const lockedUsd = Number(farm?.userData?.stakedBalance) * Number(tokenPrice) + earned;

        listAnalyticsVault.push({
          ...farm,
          locked: Number(farm?.userData?.stakedBalance) + earned,
          earnings: earned,
          lockedUsd: lockedUsd,
          earningsUsd: amountEarned,
        });
        totalUsdLocked += lockedUsd;
        totalUsdEarning += amountEarned;
      }
    }
  });
  pools.forEach((pool: Pool) => {
    if (!pool?.isFinished) {
      if (pool?.poolCategory === POOL_CATEGORY.DEFAULT) {
        const stakingTokenPrice = prices[pool?.stakingToken?.symbol.toLowerCase()];
        const earningTokenPrice = prices[pool?.earningToken?.symbol.toLowerCase()];
        listAnalyticsPool.push({
          ...pool,
          locked: Number(formatBigNumber(new BigNumber(pool?.userData?.stakedBalance || '0'))),
          earnings: Number(pool?.userData?.pendingReward || '0'),
          lockedUsd:
            Number(formatBigNumber(new BigNumber(pool?.userData?.stakedBalance || '0'))) * Number(stakingTokenPrice),
          earningsUsd: Number(pool?.userData?.pendingReward || '0') * Number(earningTokenPrice),
        });
        totalUsdLocked +=
          Number(formatBigNumber(new BigNumber(pool?.userData?.stakedBalance || '0'))) * Number(stakingTokenPrice);
        totalUsdEarning += Number(pool?.userData?.pendingReward || '0') * Number(earningTokenPrice);
      } else if (pool?.poolCategory === POOL_CATEGORY.NFT) {
        const totalAmountStakedNft =
          (pool?.userData?.listCardStaked &&
            pool?.userData?.listCardStaked.reduce((acc, cur) => {
              return acc + Number(cur.amount);
            }, 0)) ||
          0;
        listAnalyticsPool.push({
          ...pool,
          locked: Number(totalAmountStakedNft || '0'),
          earnings: Number(formatBalance(Number(pool?.userData?.pendingReward))),
          lockedUsd: Number(totalAmountStakedNft) * Number(prices['posi']),
          earningsUsd: Number(pool?.userData?.pendingReward) * Number(prices['ptx']),
        });
        totalUsdLocked += Number(totalAmountStakedNft) * Number(prices['posi']);
        totalUsdEarning += Number(pool?.userData?.pendingReward) * Number(prices['ptx']);
      } else {
        const stakingTokenPrice = getBondLastPriceByUsd(pool?.bondData, prices);
        const earningTokenPrice = prices[pool?.earningToken?.symbol.toLowerCase()];
        listAnalyticsPool.push({
          ...pool,
          locked: Number(formatBigNumber(new BigNumber(pool?.userData?.stakedBalance || '0'))),
          earnings: Number(pool?.userData?.pendingReward || '0'),
          lockedUsd:
            Number(formatBigNumber(new BigNumber(pool?.userData?.stakedBalance || '0'))) * Number(stakingTokenPrice),
          earningsUsd: Number(pool?.userData?.pendingReward || '0') * Number(earningTokenPrice),
        });
        totalUsdLocked +=
          Number(formatBigNumber(new BigNumber(pool?.userData?.stakedBalance || '0'))) * Number(stakingTokenPrice);
        totalUsdEarning += Number(pool?.userData?.pendingReward || '0') * Number(earningTokenPrice);
      }
    }
  });

  return { listAnalyticsFarm, listAnalyticsPool, listAnalyticsVault, totalUsdLocked, totalUsdEarning };
};

const ModalInformation: React.FC<ModalInformationProps> = ({
  pendingTx,
  dataState,
  account,
  logout,
  handleSetIsShowBalance,
  showBalance,
}) => {
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
  const [isSmallerThan478] = useMediaQuery('(max-width: 478px ) and (min-width: 0px )');
  const { chainId } = useWeb3React();
  const copyToClipboardWithCommand = (content: string) => {
    copy(content, { format: 'text/plain' });
    displayTooltip();
  };

  const farms = useFarmInRedux();
  const pools = usePoolsWithoutUserData();
  const farm = farms.filter((e) => !e?.isFinished);
  const pool = pools.filter((e) => !e?.isFinished);
  const dataAnalysis = analyticUserData(farm, pool);

  const dispatch = useDispatch();

  function displayTooltip() {
    setIsTooltipDisplayed(true);
    setTimeout(() => {
      setIsTooltipDisplayed(false);
    }, 1500);
  }

  const { t } = useTranslation('header');
  const accountEllipsis = account
    ? !isSmallerThan478
      ? `${account.substring(0, 12)}...${account.substring(account.length - 20)}`
      : `${account.substring(0, 8)}...${account.substring(account.length - 12)}`
    : null;
  const linkBsc = getBscScanLink(chainId, account, 'address');
  const showDetail = useGetDetailShowedModalInfo();

  const handleBack = () => {
    dispatch(setDetailShowedModalInfo(DetailRenderModalInfo.default));
  };
  const title = getTitleInfoModal(showDetail);
  const gray700ToGray75 = useColorModeValue('gray.75', 'gray.700');
  const whiteToGray700 = useColorModeValue('gray.700', 'white');

  return (
    <div style={{ width: '100%' }}>
      <Flex flexDir="column" id="contentModal" p="16px" color="gray.500" width={['320px', '400px', '400px', '400px']}>
        <Flex
          borderRadius="990px"
          w="100%"
          justifyContent="space-between"
          p="12px"
          borderColor="gray.700"
          h="40px"
          bg={gray700ToGray75}
        >
          <Tooltip label={'View your wallet on Bscscan'} hasArrow={true} placement="bottom">
            <Text
              as={Link}
              href={linkBsc}
              maxW={'90%'}
              _target="_blank"
              isExternal
              fontWeight={'500'}
              fontFamily={'Poppins'}
              fontStyle={'normal'}
              fontSize={'14px'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              color="blue.400"
              ml="8px"
            >
              {accountEllipsis || ''}
            </Text>
          </Tooltip>
          <Tooltip label={t('Copied')} hasArrow={true} placement="bottom" isOpen={isTooltipDisplayed}>
            <Image
              cursor="pointer"
              src={'/img/copy-address.svg'}
              w={'30px'}
              h={'30px'}
              mt="-5px"
              mr={'5px'}
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboardWithCommand(account || '');
              }}
            />
          </Tooltip>
        </Flex>
        {pendingTx ? (
          <>
            <Flex my="12px" fontSize="14px" color="teal.150" fontWeight="semibold" alignItems="center">
              <Flex width={'22px'} height={'22px'}>
                <Lottie
                  options={{
                    loop: true,
                    animationData: Loader,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  width={'22px'}
                  height={'22px'}
                />
              </Flex>
              <Text as={'span'} ml="5px">
                {pendingTx} {pendingTx > 1 ? t('many_trans') : t('one_trans')}
              </Text>
            </Flex>
            <Divider mb="8px" />
          </>
        ) : null}
        <Flex
          color={whiteToGray700}
          fontWeight={600}
          fontSize="18px"
          display={title ? 'flex' : 'none'}
          alignItems="center"
          mt="8px"
        >
          <Flex onClick={handleBack} cursor="pointer" align="center">
            <Box p="2px">
              <Image src="/img/icon_back.svg" mr="6px" cursor="pointer" />
            </Box>
            <Text fontSize={['14px', '14px', '16px', '16px']} color={whiteToGray700}>
              {t(`${title}`)}
            </Text>
          </Flex>
        </Flex>
        <TotalBalance
          dataState={{ ...dataState, ...dataAnalysis }}
          handleSetIsShowBalance={handleSetIsShowBalance}
          showBalance={showBalance}
        />
      </Flex>
      <LockedContainer account={account} dataState={{ ...dataState, ...dataAnalysis }} showBalance={showBalance} />
      <Flex
        flexDir="column"
        id="contentModal"
        pb="10px"
        px={'10px'}
        pt={['0px', '10px', '10px', '10px']}
        color="gray.500"
      >
        <Divider mb="16px" />
        <Flex w="100%" justifyContent="flex-start">
          <Flex
            color="#FC8481"
            p={['0px', '6px', '8px', '8px']}
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation();
              logout();
              window.localStorage.removeItem(connectorLocalStorageKey);
            }}
            align={'center'}
          >
            <Image src={'/img/logout-address.svg'} mr="8px" width="32px" />
            <Text color={'#F56565'} fontFamily={'Poppins'} fontStyle={'normal'} fontWeight={'500'} fontSize={'14px'}>
              {t('Logout')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default ModalInformation;
