import { TransactionResponse } from '@ethersproject/providers';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useActiveWeb3React } from '../../hooks/useActiveWeb3React';
import { AppDispatch, AppState } from '../store';
import { addTransaction } from './actions';
import { TransactionDetails } from './reducer';

// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder(): (
  response: TransactionResponse,
  customData?: {
    summary?: string;
    approval?: { tokenAddress: string; spender: string };
    trigger?: any;
    title?: string;
    titleFail?: string;
  },
) => void {
  const { chainId, account } = useActiveWeb3React();
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(
    (
      response: TransactionResponse,
      {
        title,
        summary,
        approval,
        trigger,
        titleFail,
      }: {
        summary?: string;
        approval?: { tokenAddress: string; spender: string };
        trigger?: any;
        title?: string;
        titleFail?: string;
      } = {},
    ) => {
        if (!account) return;
      if (!chainId) return;

      const { hash } = response;
      if (!hash) {
        throw Error('No transaction hash found.');
      }
      window.listTransactionsTrigger[hash] = trigger;
      dispatch(addTransaction({ hash, from: account, chainId, approval, summary, title, titleFail }));
    },
    [dispatch, chainId, account],
  );
}

// returns all the transactions for the current chain
export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  const { chainId } = useActiveWeb3React();

  const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions);

  return chainId ? state[chainId] ?? {} : {};
}

export function useIsTransactionPending(transactionHash?: string): boolean {
  const transactions = useAllTransactions();

  if (!transactionHash || !transactions[transactionHash]) return false;

  return !transactions[transactionHash].receipt;
}

/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx: TransactionDetails): boolean {
  return new Date().getTime() - tx.addedTime < 86400000;
}

// returns whether a token has a pending approval transaction
export function useHasPendingApproval(tokenAddress: string | undefined, spender: string | undefined): boolean {
  const allTransactions = useAllTransactions();
  return useMemo(
    () =>
      typeof tokenAddress === 'string' &&
      typeof spender === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash];
        if (!tx) return false;
        if (tx.receipt) {
          return false;
        }
        const { approval } = tx;
        if (!approval) return false;
        return (
          approval.spender.toLowerCase() === spender.toLowerCase() &&
          approval.tokenAddress.toLowerCase() === tokenAddress.toLowerCase() &&
          isTransactionRecent(tx)
        );
      }),
    [allTransactions, spender, tokenAddress],
  );
}

// returns whether a token has a pending approval transaction
export function useHasTransactionPending(txh: string): boolean {
  const allTransactions = useAllTransactions();
  return useMemo(
    () =>
      typeof txh === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash];
        if (!tx) return false;
        if (tx.receipt) {
          return false;
        }
        return tx.hash === txh && isTransactionRecent(tx);
      }),
    [allTransactions, txh],
  );
}
