import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import _, { isEqual, kebabCase } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { walletConnectedLocalStorageKey, walletLocalStorageKey } from '../components/WalletModal/config';
import { Farm, TYPE_OF_FARM } from '../configs/constants/types';
import useRefresh from '../hooks/useRefresh';
import { getFarmApy } from '../utils/apy';
import { getWeb3NoAccount } from '../utils/web3';
import {
  clear as clearToast,
  fetchFarmsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchProposalsGovernance,
  push as pushToast,
  remove as removeToast,
  setBlock,
} from './global/action';
import { fetchPriceFeedKeys, fetchPrices } from './prices';
import store, { AppState } from './store';

import { Bond, PairExchange, Pool, PriceState, State, Toast, types as toastTypes, Vault } from './types';

import { fetchGlobalListTokenOfUser } from './tokens';
import { serialize } from '../utils/common';
import { fetchFarmUserDataAsync } from './farms';
import { fetchPoolsUserDataAsync } from './pools';
import { WrappedTokenInfo } from './lists/hooks';
import { POSI_MAIN } from '../constants';
import { fetchStatisticsDataAsync } from './home';
import {
  fetchOnlyAprForPoolsExchange,
  fetchPublicDataPoolsExchange,
  fetchUserDataPoolExchange,
  setListPairLiquidity,
} from './bondExchange';
import { LIQUIDITY_NFT } from '../configs/types';
import axios from 'axios';
import useInterval from '../hooks/useInterval';
import { isAddress } from 'web3-utils';
import { useLocation } from 'react-router-dom';
import { getCurrentRoundId, updateDraw, updateLuckyNumberToCurrentRound } from './lottery';
import { getProvider } from '../utils/ether';
import { getLotteryAddress } from '../utils/addressHelpers';
import { ethers } from 'ethers';
import { listTask } from '../configs/constants/taskTreasureHunt';
import { addDataToStorage, readDataInStorage, updateDataToStorage } from '../indexedDB/cacheController';
import { checkingAndGetToken } from '../firebase/noticeServices';

export interface LogResult {
  blockNumber: number;
  blockHash: string;
  transactionIndex: number;

  removed: boolean;

  address: string;
  data: string;

  topics: Array<string>;

  transactionHash: string;
  logIndex: number;
}

export const useFetchPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();
  const { account } = useWeb3React();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync());
    dispatch(fetchPoolsPublicDataAsync());
    if (
      window?.location?.pathname?.includes('bond-exchange') ||
      window?.location?.pathname?.includes('trade') ||
      window?.location?.pathname?.includes('liquidity') ||
      window?.location?.pathname?.includes('farming') ||
      window?.location?.pathname?.includes('swap')
    ) {
      dispatch(setListPairLiquidity());
      dispatch(fetchOnlyAprForPoolsExchange());
      dispatch(fetchPublicDataPoolsExchange());
    }
    if (window?.location?.pathname?.includes('posi-birthday/daily-lottery')) {
      dispatch(getCurrentRoundId());
    }
  }, [dispatch, slowRefresh]);

  useEffect(() => {
    if (
      window?.location?.pathname?.includes('bond-exchange') ||
      window?.location?.pathname?.includes('trade') ||
      window?.location?.pathname?.includes('liquidity') ||
      window?.location?.pathname?.includes('farming') ||
      window?.location?.pathname?.includes('swap')
    ) {
      dispatch(setListPairLiquidity());
      dispatch(fetchOnlyAprForPoolsExchange());
      dispatch(fetchPublicDataPoolsExchange());
    }
  }, [pathname]);

  useEffect(() => {
    if (account && window.nodeValid) {
      if (
        window?.location?.pathname?.includes('bond-exchange') ||
        window?.location?.pathname?.includes('trade') ||
        window?.location?.pathname?.includes('liquidity') ||
        window?.location?.pathname?.includes('farming') ||
        window?.location?.pathname?.includes('swap')
      ) {
        dispatch(fetchUserDataPoolExchange(account));
        dispatch(setListPairLiquidity());
      }
    }
  }, [pathname, account, window.nodeValid]);

  useEffect(() => {
    if (window.nodeValid) {
      const posiToken = new WrappedTokenInfo(POSI_MAIN, []);
      dispatch(fetchStatisticsDataAsync(account || '', posiToken));
    }
  }, [dispatch, slowRefresh, account, window.nodeValid]);

  useEffect(() => {
    if (account && window.nodeValid) {
      dispatch(fetchFarmUserDataAsync(account));
      dispatch(fetchPoolsUserDataAsync(account));
      if (
        window?.location?.pathname?.includes('bond-exchange') ||
        window?.location?.pathname?.includes('trade') ||
        window?.location?.pathname?.includes('liquidity') ||
        window?.location?.pathname?.includes('farming') ||
        window?.location?.pathname?.includes('swap')
      ) {
        dispatch(fetchUserDataPoolExchange(account));
        dispatch(setListPairLiquidity());
      }
    }
  }, [dispatch, slowRefresh, account, window.nodeValid]);

  useEffect(() => {
    if (account && window.nodeValid) {
      if (localStorage.getItem(walletLocalStorageKey)) {
        localStorage.setItem(walletConnectedLocalStorageKey, localStorage.getItem(walletLocalStorageKey));
      }
      dispatch(fetchGlobalListTokenOfUser(account));
    }
  }, [account, window.nodeValid]);

  useEffect(() => {
    if (window.nodeValid) {
      // dispatch(fetchPublicDataOfFarmingCompetition());
      dispatch(fetchProposalsGovernance(serialize({ page_size: 10, page_number: 1 })));
    }
  }, [dispatch, window.nodeValid]);

  useInterval(async () => {
    const web3 = getWeb3NoAccount();
    const blockNumber = await web3.eth.getBlockNumber();
    dispatch(setBlock(blockNumber));
  }, 3000);

  useEffect(() => {
    const provider = getProvider();
    provider.on(
      {
        address: getLotteryAddress(),
        topics: ['0x98e31a6607b8b15b4d5b91de54f4c09ffe4c4cf162aa532c70b5213754e2e703', null, null],
      },
      (log: LogResult) => {
        try {
          const [luckyNumber] = formatDrawLog(log.data);
          if (String(luckyNumber).length === 4) {
            dispatch(updateLuckyNumberToCurrentRound(luckyNumber));
            dispatch(updateDraw(true));
          }
        } catch (e) {}
      },
    );
  }, []);
};

export const upsertAccountIntoIndexedDB = async (accountRaw: { user_address: string; topic: string }) => {
  try {
    if (!accountRaw) return;
    const currentAccount = await readDataInStorage('account');
    if (!!currentAccount) {
      const dataAccount = JSON.parse(currentAccount?.data);
      const exist =
        dataAccount &&
        dataAccount.find((a) => a?.user_address === accountRaw?.user_address && a?.topic === accountRaw?.topic);
      if (!exist) {
        dataAccount.push(accountRaw);
        updateDataToStorage({
          name: 'account',
          data: JSON.stringify(dataAccount),
          exp: 'infinity',
        });
      }
    } else {
      addDataToStorage({
        name: 'account',
        data: JSON.stringify([accountRaw]) || '',
        exp: 'infinity',
      });
    }
  } catch (error) {}
};

export const formatDrawLog = (data) => {
  return ethers.utils.defaultAbiCoder
    .decode(['uint256', 'uint256'], data)
    .map((bn) => bn.toString()) as unknown as string[];
};

/*
 {
  blockNumber: 12189865,
  blockHash: '0x38712eba922b76eac7d07d2a894d10db32c49153d35008c4c607e974e69ba452',
  transactionIndex: 348,
  removed: false,
  address: '0xC016F93D1b11878804c345e93C8588794480CD83',
  data: '0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001864e2b5ce370f00000000000000000000000000000000000000000000003635c9adc5dea000000000000000000000000000000000000000000000000000000000000000000000',
  topics: [
    '0xd78ad95fa46c994b6551d0da85fc275fe613ce37657fb8d5e3d130840159d822',
    '0x00000000000000000000000010ed43c718714eb63d5aa57b78b54704e256024e',
    '0x00000000000000000000000007556d31e4ce4fd061e525d0052523f6475c3dec'
  ],
  transactionHash: '0x2f3ad01c883df29ccb02224a5197548536989628337e5ab739ba8436668ecad9',
  logIndex: 1120
}
 */

// Wallet
export const useWalletBalance = () => {
  const balance = useSelector((state: State) => state.wallet.balance);
  return balance;
};

// Farms
export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data);
  return farms;
};

export const useVaults = (): Vault[] => {
  const vaults = useSelector((state: State) => state.vault.data);
  return vaults;
};

export const useFarmFromPid = (pid: any): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid));
  return farm as Farm;
};

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.name === lpSymbol));
  return farm as Farm;
};

export const useFarmUser = (pid: any) => {
  const farm = useFarmFromPid(pid);

  return {
    allowance: farm?.userData ? new BigNumber(farm.userData.lpAllowance) : new BigNumber(0),
    tokenBalance: farm?.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm?.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm?.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  };
};

// nft
export const useNft = () => {
  const nft = useSelector((state: RootStateOrAny) => state.nfts);
  return nft;
};
export const useNftUserData = () => {
  const nft = useSelector((state: AppState) => state.nfts.userData);
  const { allowance = [], tokenBalances } = nft || {};
  return {
    isApproveCast: new BigNumber(allowance[0] || 0),
    isApproveDecompose: new BigNumber(allowance[1] || 0),
    tokenBalances: new BigNumber(tokenBalances || 0),
  };
};
export const usePriceBySymbol = (symbol: string): number => {
  const prices = useSelector((state: State) => state.prices.data);
  return Number((prices || {})[symbol]);
};

// // LendingPools
// export const useLendingPools = (): LendingPool[] => {
//   const lendingPools = useSelector((state: State) => state.lendingPool.data)
//   return lendingPools
// }
//
// export const useLendingPoolFromPid = (pid): LendingPool => {
//   const lendingPool = useSelector((state: State) => state.lendingPool.data.find((f) => f.pid === pid))
//   return lendingPool
// }
/**
 * @_amount is in BigNumber which is the raw amount = realAmount * 10**deciml
 *
 * Convert asset in to AssetAmount which contain {inBNB, inUSD, raw, rate}
 * Ex: convert an DAI with the rate 1 BNB = 500 DAI & 1 BNB = 506 USD
 * _amount: 250 -> {inBNB: 0.5, inUSD, inUSD: 253, raw: 500, rate: 0.002 }
 */
// export const useParseAssetAmountFromPid = (_amount: BigNumber , pid: number, isConverted?: boolean): AssetAmount => {
//   const _parsedAmount = _amount.isNaN() ? 0 : isConverted && _amount.toNumber() || Number(web3Utils.fromWei(_amount.toString()))
//   const lendingPoolData: LendingPoolsState = useSelector((state: State) => state.lendingPool)
//   const pIndex = lendingPoolData.data.findIndex(obj => obj.pid === pid)
//   const priceInBNB: string = lendingPoolData.assetPricesInBNB[pIndex] || '0'
//   const parsePriceBnb = Number( web3Utils.fromWei(priceInBNB) )
//   const inBNB: number = _parsedAmount* parsePriceBnb
//   const inUSD: number = inBNB*lendingPoolData.bnbUsdPrice
//   const _assetAmount: AssetAmount = {
//     inBNB,
//     inUSD,
//     raw: _parsedAmount,
//     rate: parsePriceBnb
//   }
//   return _assetAmount
//
// }
//
// Pools
export const usePools = (): Pool[] => {
  // const { fastRefresh } = useRefresh();
  // const dispatch = useDispatch();
  // const generalNFTRewardContract = useGeneralNFTRewards();
  // useEffect(() => {
  //   if (account) {
  //     dispatch(fetchPoolsUserDataAsync(account));
  //   }
  // }, [account, dispatch, fastRefresh, generalNFTRewardContract]);

  const pools = useSelector((state: State) => state.pools.data);
  return pools;
};

export const useExchangeLiquidityPools = (): Pool[] => {
  const pools = useSelector((state: State) => state.pools.data);
  const listExchangePool = pools.filter((p) => p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT);
  return useMemo(() => listExchangePool, [listExchangePool]);
};

export const usePoolsWithoutUserData = (): Pool[] => {
  const pools = useSelector((state: State) => state.pools.data);
  return pools;
};

export const usePoolFromPid = (sousId: number): Pool | undefined => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId));
  return pool;
};

// Toasts
export const useToast = () => {
  const dispatch = useDispatch();
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast));

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description });
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description });
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description });
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description });
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    };
  }, [dispatch]);

  return helpers;
};

// Profile
export const useFetchProfile = () => {
  // const { account } = useWeb3React();
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchProfile(account || ''));
  // }, [account, dispatch]);
};

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPrices());
  }, [dispatch, slowRefresh]);
  useEffect(() => {
    dispatch(fetchPriceFeedKeys());
  }, [dispatch]);
};

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data);
  return prices || {};
};

export const getPriceFromApi = async (
  data: { symbol?: string; address: string }[],
): Promise<{ [address: string]: { symbol: string; price: string } } | 0> => {
  try {
    const response = await axios({
      method: 'POST',
      baseURL: process.env.REACT_APP_PRICE_BASE_V2_URL,
      url: '/prices',
      headers: {
        'content-type': 'application/json',
      },
      data: data,
    });
    if (response.status === 200) {
      const priceRes = response.data?.data;
      return priceRes;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

export const useGetApiPrice = (tokenOrAddress: string) => {
  const [pricesOfToken, setPricesOfToken] = useState(0);
  const prices = useGetApiPrices();
  const { slowRefresh } = useRefresh();

  const getPrice = useCallback(
    async (tokenOrAddress: string) => {
      if (isAddress(tokenOrAddress)) {
        const price = await getPriceFromApi([{ address: tokenOrAddress }]);
        if (!!price) {
          if (Number(pricesOfToken) !== Number(price[tokenOrAddress].price)) {
            setPricesOfToken(Number(price[tokenOrAddress].price || 0));
          }
        } else {
          setPricesOfToken(0);
        }
      } else {
        setPricesOfToken(0);
      }
    },
    [tokenOrAddress],
  );

  useEffect(() => {
    if (!!prices && Object.keys(prices).length > 0 && !!tokenOrAddress) {
      const token = tokenOrAddress.toLowerCase();
      if (!!prices[token]) {
        if (Number(pricesOfToken) !== Number(prices[token])) {
          setPricesOfToken(prices[token]);
        }
      } else {
        getPrice(tokenOrAddress);
      }
    }
  }, [tokenOrAddress, prices, slowRefresh, getPrice]);
  return pricesOfToken;
};

export const useGetPriceFeedKeyHook = (tokenOrAddress: string) => {
  const [priceFeedKey, setPriceFeedKey] = useState('');
  const priceFeedKeys: PriceState['priceFeedKey'] = useSelector((state: State) => state.prices.priceFeedKey) || {};

  useEffect(() => {
    const tokenAddressLower = tokenOrAddress.toLowerCase();
    if (priceFeedKeys[tokenAddressLower]) {
      setPriceFeedKey(priceFeedKeys[tokenAddressLower] || '');
    } else {
      setPriceFeedKey('');
    }
  }, [tokenOrAddress, priceFeedKeys]);
  return priceFeedKey;
};

export const useGetPriceFeedKey = async (tokenOrAddress: string): Promise<string> => {
  return new Promise((resolve) => {
    const tokenAddressLower = tokenOrAddress.toLowerCase();
    const priceFeedKeys = store.getState().prices.priceFeedKey || {};
    if (priceFeedKeys && priceFeedKeys[tokenAddressLower]) {
      resolve(priceFeedKeys[tokenAddressLower]);
    } else {
      return retryGetPriceFeedKey(tokenAddressLower, resolve);
    }
  });
};

export const retryGetPriceFeedKey = async (tokenOrAddress: string, resolve) => {
  const priceFeedKeys = store.getState().prices.priceFeedKey || {};
  if (priceFeedKeys && priceFeedKeys[tokenOrAddress]) {
    resolve(priceFeedKeys[tokenOrAddress]);
  } else {
    setTimeout(() => {
      return retryGetPriceFeedKey(tokenOrAddress, resolve);
    }, 200);
  }
};

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block);
};

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock);
};

export const useGetDataMyNftSorted = () => {
  const dataMyNft = useSelector((state: RootStateOrAny) => state?.nfts?.myNftData);
  const dataNftStaked = useSelector((state: RootStateOrAny) => state?.nfts?.nftStaked);
  const totalNftData = dataMyNft.concat(dataNftStaked);
  const myNftDataSorted = _.orderBy(totalNftData, (detail) => moment(detail?.createdTime * 1000), 'desc');
  return useMemo(() => myNftDataSorted, [dataMyNft, dataNftStaked]);
};

export const useGetDetailShowedModalInfo = () => {
  const showDetail = useSelector((state: RootStateOrAny) => state.global.showDetail);
  return showDetail;
};

export const useGetVaultWithApy = () => {
  const vaults = useSelector((state: RootStateOrAny) => state.vault.data);
  const posiPrices = useGetApiPrice('posi');
  const prices = useGetApiPrices();
  const vaultsToDisplayWithAPY: any = vaults.map((vault) => {
    if (!vault.lpTotalInQuoteToken && !prices) {
      return vault;
    }
    const totalLiquidity = new BigNumber(vault.lpTotalInQuoteToken).times(prices[vault?.tokenSymbol?.toLowerCase()]);
    const apy = getFarmApy(
      vault.poolWeight as BigNumber,
      new BigNumber(posiPrices),
      totalLiquidity,
      new BigNumber(vault.posiPerBlock),
    );

    return { ...vault, apr: apy, liquidity: totalLiquidity };
  });
  return useMemo(() => vaultsToDisplayWithAPY, [posiPrices, vaults, prices]);
};

export const useGetSetScrollId = () => {
  const scrollId = useSelector((state: AppState) => state.global?.scrollId);
  return useMemo(() => scrollId, [scrollId]);
};

export const useGetIsModal = () => {
  const data = useSelector((state: AppState) => state.bonds.isModal);
  return data;
};
export const useProposalsData = () => {
  const overviewProposalsData = useSelector((state: AppState) => state?.governance?.proposalsData) ?? {};
  return useMemo(() => overviewProposalsData, [overviewProposalsData]);
};

export const useGetLastProposalState = () => {
  const dataProposal = useProposalsData();
  const stateData = dataProposal && dataProposal?.proposals && dataProposal?.proposals[0]?.states;
  const lastState = stateData && stateData[stateData.length - 1];
  return lastState;
};

export const useIsClickNewBond = () => {
  const objHasNewBond = useSelector((state: AppState) => state?.global?.hasNewBond);
  const res = Number(objHasNewBond.visit) > 0 ? Number(objHasNewBond.visit) : 0;
  return res;
};
export const useIsLoadingDataBondChart = () => {
  const isLoadingDataChart = useSelector((state: AppState) => state?.bondDetail?.isLoadingDataChart);
  return !isLoadingDataChart;
};

export const useGetCurrentPair = (): PairExchange => {
  const currentPair = useSelector((state: AppState) => state?.bondExchange.currentPair);

  return useMemo(() => currentPair, [currentPair]);
};

export const useCurrentBondInExchange = (): Bond | null => {
  const currentBond = useSelector((state: AppState) => state?.bondExchange.currentBond);

  return useMemo(() => currentBond, [currentBond]);
};

export const useAddDetailToken = () => {
  const addDetailToken = useSelector((state: AppState) => state?.global?.addDetailToken);
  return addDetailToken;
};

export const useGetReloadBalance = () => {
  const reloadBalance = useSelector((state: AppState) => state.global.reloadBalance);
  return useMemo(() => reloadBalance, [reloadBalance]);
};

export const useFarmFromId = (id: number): Farm => {
  const listFarm = useSelector((state: State) => state.farms.data || []);
  const farm = listFarm.find((f) => f.id === id);
  return useMemo(() => farm, [farm]);
};

export const useFarmUserById = (id: any) => {
  const farm = useFarmFromId(id);
  const getAllowanceByType = (type) => {
    if (isEqual(type, TYPE_OF_FARM.MANUAL)) {
      return farm.userData.lpAllowance;
    }
    return farm.userData.tokenAllowance;
  };

  return {
    allowance: farm?.userData ? new BigNumber(getAllowanceByType(farm.type)) : new BigNumber(0),
    tokenBalance: farm?.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm?.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm?.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  };
};

export const useFarmInRedux = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data);
  return farms;
};

export const useProposalDetailInRedux = () => {
  const proposalDetailData = useSelector((state: AppState) => state.governance.proposalsDetailData);
  return proposalDetailData;
};

export const useGetVotedList = () => {
  const voteAgainstList = useSelector((state: AppState) => state.governance.voteAgainstList);
  const voteForList = useSelector((state: AppState) => state.governance.voteForList);

  return useMemo(
    () => ({
      voteAgainstList,
      voteForList,
    }),
    [voteAgainstList, voteForList],
  );
};

export const getSpaceListInRedux = () => {
  const spaceData = useSelector((state: AppState) => state.governance.spaceData);
  return useMemo(() => spaceData, [spaceData]);
};

export const getSearchTokenInputInRedux = () => {
  const dataInput = useSelector((state: AppState) => state.swapPage.searchTokenInput);
  return useMemo(() => dataInput, [dataInput]);
};

export const useContentTaskById = (id: string | number) => {
  const existItem = listTask.find((a) => Number(id) === a.id);

  return useMemo(() => existItem, [existItem]);
};

export const getNftBirthdayInRedux = () => {
  const exist = useSelector((state: AppState) => state?.nfts?.nftsBirthday);
  return useMemo(() => exist, [exist]);
};

export const checkAndGetRefreshToken = () => {
  const { account } = useWeb3React();
  useEffect(() => {
    if (!!account) {
      checkingAndGetToken(account, true);
    }
  }, [account]);
};
