import { Flex, Image, Link } from '@chakra-ui/react';
import React, { memo, useState } from 'react';

interface ImageHoverComponentsProps {
  index: number;
  imgUrl: string;
  imgUrlHover: string;
  link: string;
  [key: string]: any;
}

const ImageHoverComponents: React.FC<ImageHoverComponentsProps> = memo(
  ({ index, imgUrl, imgUrlHover, link, ...rest }) => {
    const [isIndexHidden, setIsIndexHidden] = useState(-1);
    const handleHiddenImage = (index: number) => {
      setIsIndexHidden(index);
    };
    const isHidden = isIndexHidden === index;

    return (
      <Flex align={'center'} justify="center" cursor="pointer" {...rest}>
        <Image hidden={isHidden} onMouseOver={() => handleHiddenImage(index)} src={imgUrl} />
        <Link href={link} isExternal hidden={!isHidden}>
          <Image onMouseLeave={() => handleHiddenImage(-1)} src={imgUrlHover} />
        </Link>
      </Flex>
    );
  },
);

export default ImageHoverComponents;
