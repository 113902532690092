import axios from 'axios';
import poolsSpotExchange from '../../configs/poolsSpotExchange';
import { getAddress } from '../../utils/addressHelpers';
import multicall, { Call, multicallERC } from '../../utils/multicall';
import exchangeLiquidityPoolABI from '../../configs/abi/exchange-liquidity-pool.json';
import liquidityNftStakinglABI from '../../configs/abi/liquidityNftStaking.json';
import liquidityNftStakingAbi from '../../configs/abi/liquidityNftStaking.json';
import positionStakingManagerAbi from '../../configs/abi/positionStakingManagerLiquid.json';
import ERC_20_ABI from '../../configs/abi/erc20.json';
import { formatBigNumber } from '../../hooks/useModalInfo';
import BigNumber from 'bignumber.js';
import { getPairPriceWithPairManager } from '../../hooks/useBondExchangeData';
import { uniqBy } from '../../utils/common';
import { getLiquidityPoolRewardApr, getLiquidityPoolRewardAprWithData } from '../../utils/apy';
import { chunk } from 'lodash';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BOND_EXCHANGE_URL,
  timeout: 10000,
});

export const getDataOrderBook = async (currentPair): Promise<any> => {
  try {
    const res = await instance({
      method: 'GET',
      url: `/order-books/${currentPair}`,
    });

    return res.data.data;
  } catch {
  }
};

export const getListPairInSpotExchange = (queryString?: string) => {
  return instance({
    method: 'GET',
    url: `/pairs?sort-by=usd_volume_24h&sort-order=DESC&${queryString || ''}`,
  }).then((response) => response.data.data);
};

export const getPairsDetail = (pairSymbol: string) => {
  return instance({
    method: 'get',
    url: `/pairs/${pairSymbol}`,
  }).then((response) => response.data?.data);
};

export const getListOrder = (queryString: string) => {
  return instance({
    method: 'get',
    url: `/orders?${queryString}`,
  }).then((response) => response?.data?.data);
};

export const getListMarketTradeFromApi = (queryString: string) => {
  return instance({
    method: 'get',
    url: `/trades?${queryString}`,
  }).then((response) => response?.data?.data);
};

export const getMyLiquidityNftStaked = async (account: any, nftRewardContract: any) => {
  try {
    const res = await nftRewardContract?.methods?.getPlayerIds(account).call();
    return res;
  } catch {
    return [];
  }
};

export const fetchPublicDataPoolsExchangeService = async (listPrices: any) => {
  try {
    const listCallPoolInfo: Call[] = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.liquidityPoolAddress),
      name: 'poolInfo',
      params: [getAddress(pool?.poolKey)],
    }));

    const listCallPoolLiquidity: Call[] = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.liquidityPoolAddress),
      name: 'getPoolLiquidity',
      params: [getAddress(pool?.poolKey)],
    }));

    const listCallPoolReward: Call[] = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.liquidityPoolAddress),
      name: 'getPoolPnL',
      params: [getAddress(pool?.poolKey)],
    }));

    const listCallLimitLiquidity: Call[] = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.liquidityPoolAddress),
      name: 'limitLiquidity',
      params: [getAddress(pool?.poolKey)],
    }));

    const multicallRes = await multicall(exchangeLiquidityPoolABI, [
      ...listCallPoolInfo,
      ...listCallPoolLiquidity,
      ...listCallPoolReward,
      ...listCallLimitLiquidity,
    ]);

    const [listPoolInfoRaw, listPoolLiquidityRaw, listPoolRewardRaw, listLimitLiquidityRaw] = chunk(
      multicallRes,
      poolsSpotExchange.length,
    );

    const listGetPosiStakingManagerAddress: Call[] = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.rewardPoolAddress),
      name: 'posiStakingManager',
      params: [],
    }));

    const listHistoryApr = await getListHistoryApr();

    const listCallPosiStakingPid: Call[] = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.rewardPoolAddress),
      name: 'posiStakingPid',
      params: [],
    }));

    const multicallResRewardContract = await multicall(liquidityNftStakinglABI, [
      ...listGetPosiStakingManagerAddress,
      ...listCallPosiStakingPid,
    ]);

    const [listPosiStakingManagerAddress, listPosiStakingPid] = chunk(
      multicallResRewardContract,
      poolsSpotExchange.length,
    );

    const listCallPendingPositionOfStakingManager = listPosiStakingManagerAddress.length
      ? listPosiStakingManagerAddress.map((z, index) => ({
        address: z[0],
        name: 'pendingPosition',
        params: [
          new BigNumber(
            (listPosiStakingPid && listPosiStakingPid[index] && listPosiStakingPid[index][0]._hex) || 1,
          ).toNumber(),
          getAddress(poolsSpotExchange[index].rewardPoolAddress),
        ],
      }))
      : [];

    const listPendingPositionOfStakingManagerRaw = await multicall(
      positionStakingManagerAbi,
      listCallPendingPositionOfStakingManager,
    );

    const newList = await Promise.all(
      poolsSpotExchange.map(async (pool, index: number) => {
        const currentPoolInfo = listPoolInfoRaw && listPoolInfoRaw[index];
        const currentPoolLiquidity = listPoolLiquidityRaw && listPoolLiquidityRaw[index];
        const currentPoolReward = listPoolRewardRaw && listPoolRewardRaw[index];
        const currentLimitLiquidity = (listLimitLiquidityRaw && listLimitLiquidityRaw[index][0]) || { _hex: 0 };
        const currentLimitLiquidityPool = (listLimitLiquidityRaw && listLimitLiquidityRaw[index][1]) || { _hex: 0 };

        const currentHistoryApr =
          listHistoryApr &&
          listHistoryApr?.liquidity_pools &&
          listHistoryApr?.liquidity_pools.find(
            (z) => getAddress(pool?.poolKey).toLowerCase() === z?.pool_key.toLowerCase(),
          );

        const currentPnl = formatBigNumber(new BigNumber((currentPoolReward && currentPoolReward[0]._hex) || 0));

        const totalDepositedInQuote = formatBigNumber(new BigNumber((currentPoolInfo && currentPoolInfo[3]._hex) || 0));

        const currentPair = getPairPriceWithPairManager(currentPoolInfo && currentPoolInfo[0]);
        const currentPrice = currentPair?.price || 1;

        const totalQuoteDeposited = formatBigNumber(new BigNumber(currentPoolInfo && currentPoolInfo[2]._hex));

        const totalLiquidity = formatBigNumber(
          new BigNumber((currentPoolLiquidity && currentPoolLiquidity[0]._hex) || 0)
            .multipliedBy(currentPrice)
            .plus(new BigNumber((currentPoolLiquidity && currentPoolLiquidity[1]._hex) || 0)),
        );

        const limitLiquidity = formatBigNumber(new BigNumber(currentLimitLiquidity._hex));
        const limitLiquidityPool = formatBigNumber(new BigNumber(currentLimitLiquidityPool._hex));

        const apr = (currentPnl / totalDepositedInQuote) * 365 * 100;

        const earningTokenPrice = listPrices['posi'];
        const quoteTokenPrice = listPrices[pool?.pair?.quote?.symbol.toLowerCase()];

        const { apr: aprRewardContract, poolWeight } = await getLiquidityPoolRewardApr(
          getAddress(pool?.rewardPoolAddress),
          getAddress(pool?.poolKey),
          earningTokenPrice,
          quoteTokenPrice,
        );

        const currentTotalPendingPosition =
          listPendingPositionOfStakingManagerRaw &&
          listPendingPositionOfStakingManagerRaw[index] &&
          formatBigNumber(new BigNumber(listPendingPositionOfStakingManagerRaw[index][0]._hex));

        const rewardInStaking = new BigNumber(currentTotalPendingPosition).times(poolWeight);

        return {
          ...pool,
          pairManager: currentPoolInfo[0],
          strategy: currentPoolInfo[1],
          totalQuoteDeposited,
          liquidity: {
            base: formatBigNumber(new BigNumber((currentPoolLiquidity && currentPoolLiquidity[0]._hex) || 0)),
            quote: formatBigNumber(new BigNumber((currentPoolLiquidity && currentPoolLiquidity[1]._hex) || 0)),
            total: totalLiquidity,
          },
          limitLiquidity,
          limitLiquidityPool,
          apr: apr,
          aprHistory: {
            7: currentHistoryApr?.apr_7d || 0,
            30: currentHistoryApr?.apr_30d || 0,
          },
          rewardInStaking: rewardInStaking.toNumber(),
          aprReward: (aprRewardContract || 0).toFixed(2),
          totalRewards: currentPnl, // need add staking reward
        };
      }),
    );
    return newList;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const getListHistoryApr = async () => {
  try {
    const res = await instance({
      method: 'get',
      url: '/liquidity-pools',
    });
    return res.data.data;
  } catch {
    return [];
  }
};

export const fetchUserDataPoolsExchange = async (account: string) => {
  try {
    const listCallBalance = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.pair?.base?.contract_address),
      name: 'balanceOf',
      params: [account],
    }));
    poolsSpotExchange.forEach((pool) => {
      listCallBalance.push({
        address: getAddress(pool?.pair?.quote?.contract_address),
        name: 'balanceOf',
        params: [account],
      });
    });

    const newListBalance = uniqBy(listCallBalance, 'address');

    const listBalanceRaw = await multicallERC(ERC_20_ABI, newListBalance);

    const listBalance = poolsSpotExchange.map((pool) => {
      const indexBaseOfListCall = newListBalance.findIndex(
        (a) => a.address === getAddress(pool?.pair?.base?.contract_address),
      );
      const indexQuoteOfListCall = newListBalance.findIndex(
        (a) => a.address === getAddress(pool?.pair?.quote?.contract_address),
      );

      if (indexBaseOfListCall > -1 && indexQuoteOfListCall > -1) {
        const balance = {
          base: formatBigNumber(new BigNumber(listBalanceRaw[indexBaseOfListCall][0]._hex || 0)),
          quote: formatBigNumber(new BigNumber(listBalanceRaw[indexQuoteOfListCall][0]._hex || 0)),
        };
        return { balance };
      } else {
        return {
          balance: {
            base: 0,
            quote: 0,
          },
        };
      }
    });

    return listBalance;
  } catch (e) {
    return [];
  }
};

export const getTransactionHistoryList = (queryString: string) => {
  return instance({
    method: 'get',
    url: `/swap/transactions?${queryString}`,
  }).then((response) => response?.data?.data);
};

export const getAprOfListPoolsExchange = async (listPrices: any) => {
  try {
    const listCallPoolInfo: Call[] = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.liquidityPoolAddress),
      name: 'poolInfo',
      params: [getAddress(pool?.poolKey)],
    }));
    const listCallPoolReward: Call[] = poolsSpotExchange.map((pool) => ({
      address: getAddress(pool?.liquidityPoolAddress),
      name: 'getPoolPnL',
      params: [getAddress(pool?.poolKey)],
    }));
    const multicallResponse = await multicall(exchangeLiquidityPoolABI, [...listCallPoolInfo, ...listCallPoolReward]);

    const [listPoolInfoRaw, listPoolRewardRaw] = chunk(multicallResponse, listCallPoolInfo.length);

    const listCallDetailRewardContract = poolsSpotExchange.reduce((acc, pool) => {
      const newList = [
        {
          address: getAddress(pool?.rewardPoolAddress),
          name: 'poolInfo',
          params: [getAddress(pool?.poolKey)],
        },
        {
          address: getAddress(pool?.rewardPoolAddress),
          name: 'totalAllocPoint',
          params: [],
        },
        {
          address: getAddress(pool?.rewardPoolAddress),
          name: 'getPositionPerBlock',
          params: [],
        },
      ];

      const concatList = acc.concat(newList);
      return concatList;
    }, []);

    const rewardContractResponse = await multicall(liquidityNftStakingAbi, listCallDetailRewardContract);
    const listAfterChunk = chunk(rewardContractResponse, 3);

    const newList = await Promise.all(
      poolsSpotExchange.map(async (pool, index: number) => {
        const currentPoolInfo = listPoolInfoRaw && listPoolInfoRaw[index];
        const currentPoolReward = listPoolRewardRaw && listPoolRewardRaw[index];

        const currentPnl = formatBigNumber(new BigNumber((currentPoolReward && currentPoolReward[0]._hex) || 0));
        const totalDepositedInQuote = formatBigNumber(new BigNumber((currentPoolInfo && currentPoolInfo[3]._hex) || 0));

        const apr = (currentPnl / totalDepositedInQuote) * 365 * 100;

        const earningTokenPrice = listPrices['posi'];
        const quoteTokenPrice = listPrices[pool?.pair?.quote?.symbol.toLowerCase()];

        const dataFromRewardContract = listAfterChunk[index];

        const { apr: aprRewardContract } = await getLiquidityPoolRewardAprWithData(
          dataFromRewardContract[0],
          dataFromRewardContract[1],
          dataFromRewardContract[2],
          earningTokenPrice,
          quoteTokenPrice,
        );

        return {
          ...pool,
          apr: apr,
          aprReward: (aprRewardContract || 0).toFixed(2),
        };
      }),
    );
    return newList;
  } catch (e) {
    return [];
  }
};
