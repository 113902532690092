import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

// 2^256 - 1
export const MAX_UINT_256 = ethers.constants.MaxUint256;

export const MIN_UINT_256 = ethers.constants.Zero;

export const MAX_STABLE_LOAN_PERCENT = new BigNumber(2500); // Get from LendingPool contract

export const Max_256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const ALGORITHM = 'aes-256-cbc';
export const CIPHER_KEY = 'abcdefghijklmnopqrstuvwxyz012345'; // Same key used in Golang
export const BLOCK_SIZE = 16;
