import { ChainTypes } from '../../adapters/types';

export interface getCurrency {
  titleCurrency: string;
  amount: string;
  iamgeCurrency: string;
}

export interface Language {
  title: string;
  language: string;
  image: string;
}
export interface listMenu {
  title: string;
}

export interface IconFooter {
  iconDark: string;
  iconLight: string;
  iconHover: string;
  href: string;
}

export interface ItemBenefits {
  icon: string;
  title: string;
  subTitle: string;
}

export interface ItemFAQ {
  title: string;
  subtile: string;
}
export interface listItem {
  title: string;
  content: string;
}

export interface itemContentDetailFAQsPosiBirthDay {
  title: string;
}

export interface listItemsFAQsPosiBirthday {
  title: string;
  content?: string;
  contentDetail?: itemContentDetailFAQsPosiBirthDay[];
}

export interface ItemFooter {
  title: string;
  content: itemContact[];
}

export interface itemContact {
  title: string;
  href?: string;
  active: boolean;
}

export interface titleNftHistory {
  title: string;
}

export interface itemRefAbout {
  title: string;
  description: string;
  imgUrl?: string;
}

export interface titleTableRefferral {
  title: string;
  placeholder: string;
}

export interface ItemTitleTableNftMarketplace {
  title: string;
}

export interface iamgeMarket {
  iamge1: string;
  iamge2: string;
  iamge3: string;
  iamge4: string;
  iamge5: string;
  iamge6: string;
  id: string;
}
export interface TabMenu {
  id: string;
  title: string;
  keyword: string;
}

export interface ContentTermsConditions {
  id: string;
  content: string;
}

export interface ItemDetailMenuMobile {
  title: string;
  description: string;
  href?: string;
  isLink?: boolean;
  isBlank?: string;
  imgUrl?: string;
  isInDevelop?: boolean;
}

export interface ItemMenuMobile {
  title: string;
  isActive?: any;
  contentDetail: ItemDetailMenuMobile[];
  class: string;
}

export interface ItemContentReferral {
  title: string;
  description: string;
  imgUrl: string;
  withImg: string | string[];
  heightImg: string | string[];
}

export interface ItemBondTitle {
  id: number;
  content: string;
  hasTooltip?: boolean;
  tooltipContent?: string;
  percentWidth?: any;
  display?: any;
}

export const enum BondStatus {
  ON_SALE = 'on_sale',
  ACTIVE = 'active',
  PENDING = 'pending',
  COMMIT = 'commit',
  DISTRIBUTION = 'distribution',
  MATURED = 'matured',
  CALCULATION = 'calculation',
  LIQUIDATED = 'liquidated',
  CANCEL = 'canceled',
}

export const enum BondAction {
  PURCHASE = 'purchase',
  DETAIL = 'detail',
  CLAIM = 'claim',
  COMMIT = 'commit',
}

export const enum TypeBondP2P {
  P2P = '/bonds/p2p',
  ISSUER = '/bonds/my',
}

export interface IBondDetail {
  bond_name: string;
  stake_apr: number;
  est_apr: string;
  ytm: number;
  maturity_date?: number;
  last_price: number;
  face_value: number;
  supply: number;
  status: BondStatus;
  action: BondAction;
  status_end_time?: string;
  status_start_time?: string;
  face_asset?: {
    address: string;
    symbol: string;
  };
  underlying_asset: {
    address: string;
    symbol: string;
  };
  pending_date?: number;
  on_sale_date?: number;
  active_date?: number;
  matured_date?: number;
  name?: string;
  symbol?: string;
  id?: any;
  issuer: string;
  totalStake?: any;
  posiPerBlock?: any;
  type: 'normal' | 'sale-commit' | 'launchpad';
  committed_time?: number;
  distributed_time?: number;
  calculated_date?: number;
  issue_price?: string | number;
  liquidated_date?: number;
  cancel_date?: number;
  liquidation_price?: number;
}
export interface ItemWhyBond {
  title: string;
  description: string;
  imgUrl?: string;
}

export interface ItemCheckBoxFeedback {
  title: string;
  name: string;
  value?: string;
}

export const enum CompetitionTypes {
  REFERRAL = 'referral',
  BUYING = 'buying',
  FARMING = 'farming',
}

export interface ItemMapRules {
  content?: string;
  beforecontent?: string;
  subcontent?: string;
}

export interface RecipientNameDefaults {
  nameRecipient?: string;
  imageRecipient?: string;
}

export interface ItemListPartner {
  imgUrlHoverLight: string;
  imgUrlHover: string;
  imgUrl: string;
  link: string;
}

export interface ItemListNeedHelp {
  href: string;
  des: string;
  title: string;
}

export interface TimeLine {
  id: number;
  month: string;
  day: string;
  startDate?: any;
  percent?: string;
  endDate?: any;
}

export interface CardPlay {
  id: number;
  imgUrl: string;
  title: string;
  content: string;
}

export interface MyTicket {
  time: string;
  numberOne: number;
  numberTwo: number;
  numberThree: number;
  ticket: number;
  status: string;
}

export interface MyPrize {
  round: number;
  prize: number;
  itemTicket?: ItemTicket[];
}

export interface ItemTicket {
  time: string;
  numberOne: number;
  numberTwo: number;
  numberThree: number;
  ticket: number;
  matches: number;
  price: number;
}

export interface ITokenBridgeSupported {
  symbol: string;
  imgUrl: string;
  address: {
    [key: number]: string;
  };
  hasRfi: boolean;
}

export interface IBridgeChain {
  name: ChainTypes;
  imgUrl: string;
}
