import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from '../../utils/common';
import { getListBondPools } from './service';

interface BondPools {
  data: any;
  page: number;
  hasMore: boolean;
  loading: boolean;
}

const initialState: BondPools = {
  data: [],
  page: 0,
  hasMore: false,
  loading: false,
};
export const bondPoolsSlice = createSlice({
  name: 'bondPools',
  initialState,
  reducers: {
    setBondPoolsData: (state, action) => {
      state.data = uniqBy(state.data.concat(action.payload), function (e) {
        return e.earningToken.symbol;
      });
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setHasMore: (state) => {
      state.hasMore = true;
    },
  },
});

export const { setBondPoolsData, setLoading, setPage, setHasMore } = bondPoolsSlice.actions;

export const fetchBondPools = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const bondPools = await getListBondPools(queryString);
    let newBondPool = [];

    if (bondPools.length) {
      newBondPool = bondPools.map((item) => {
        const earningTokenAddress = {};

        item.earning_token.addresses.forEach((address) => {
          earningTokenAddress[address.chain_id] = address.address;
        });

        const listPool = [];

        item.pools.forEach((pool) => {
          const contractAddress = {};
          pool.contract_addresses.forEach((address) => {
            contractAddress[address.chain_id] = address.address;
          });

          const stakingToken = {};

          pool.staking_token.addresses.forEach((address) => {
            stakingToken[address.chain_id] = address.address;
          });

          listPool.push({
            pid: pool.pid,
            pname: pool.pool_name,
            apr: pool.est_apr,
            contractAddress,
            stakingToken: {
              symbol: pool.staking_token.symbol,
              address: stakingToken,
              projectUrl: pool.staking_token.project_url,
              decimals: pool.staking_token.decimal_point,
            },
            stakingTokenImage: pool.staking_token_image_uri,
            activeTime: pool.active_time,
            endTime: pool.end_time,
            lastPrice: Number(pool.last_price || 0) || 10,
            faceAssetName: pool.face_asset_name || 'BUSD',
          });
        });

        return {
          earningToken: {
            symbol: item.earning_token.symbol,
            address: earningTokenAddress,
            projectUrl: item.earning_token.project_url,
            decimals: item.earning_token.decimal_point,
          },
          poolImage: item.pool_image_uri,
          description: item.description,
          harvestLockup: item.harvest_lockup,
          totalReward: item.total_reward,
          listPool,
        };
      });
    }

    dispatch(setBondPoolsData(newBondPool));
    dispatch(setHasMore());
  } catch (error) {}
  dispatch(setLoading(false));
};

export default bondPoolsSlice.reducer;
