import { createAction } from '@reduxjs/toolkit';

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const updateVersion = createAction<void>('global/updateVersion');
export { clear, remove, push } from '../toasts';
export { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from '../farms';
export { setBlock } from '../block';
export { fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from '../pools';
export {
  handlerAddDataToMyNft as addDataToMyNft,
  handlerDeleteDataFromToMyNft as deleteDataFromMyNft,
  handlerAddDataToNftCastedHistory as addDataToNftCastedHistory,
  fetchNftUserDataAsync,
} from '../nft';

export { fetDataRefferalHistory, fetchListReferral, fetchListFarm } from '../referral';

export { fetchAuctionHistory, fetchMarketplaceHistory } from '../nftMarketplace';

export { fetchProposalsDetailGovernance, fetchAddressDetailGovernance, fetchProposalsGovernance } from '../governance';

export default createAction;
