import React, { useState } from "react"
import { Text, Box, Flex } from "@chakra-ui/react"
import CopyIcon from "./icons/CopyIcon"
import copy from 'copy-to-clipboard';

interface Props {
  toCopy: string
}

const CopyToClipboard: React.FC<Props> = ({ toCopy, children, ...props }) => {
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  // const copyToClipboardWithCommand = (content: string) => {
  //   const el = document.createElement("textarea")
  //   el.value = content
  //   document.body.appendChild(el)
  //   el.select()
  //   document.execCommand("copy")
  //   document.body.removeChild(el)
  // }

  function displayTooltip() {
    setIsTooltipDisplayed(true)
    setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1500)
  }

  return (
    <Flex position={"relative"} pr="24px">
      <Text
        as={"button"}
        position={"relative"}
        display={"flex"}
        alignItems={"center"}
        onClick={() => {
          copy(`${toCopy}`)
          displayTooltip()
        }}
        {...props}
      >
        {children}
        <CopyIcon width="20px" color="teal.150" />
      </Text>
      <Box
        cursor="pointer"
        position="absolute"
        right="10px"
        top={"45px"}
        display={isTooltipDisplayed ? "block" : "none"}
      >
        <Box
          bg="gray.700"
          p="3px"
          fontWeight="500"
          fontSize="14px"
          color="gray.400"
          textAlign="center"
          w="80px"
          borderRadius="24px"
          lineHeight="20px"
        >
          Copied
        </Box>
      </Box>
    </Flex>
  )
}

export default CopyToClipboard
