import { useEffect } from 'react';
import { ConnectorNames } from '../components/WalletModal/types';
import { connectorLocalStorageKey, walletLocalStorageKey } from '../components/WalletModal/config';
import useAuth from './useAuth';
import UAParser from 'ua-parser-js';
import { modeInUA } from '../App';
import { useWeb3React } from '@web3-react/core';

const _binanceChainListener = async () =>
  new Promise<void>((resolve) =>
    Object.defineProperty(window, 'BinanceChain', {
      get() {
        return this.bsc;
      },
      set(bsc) {
        this.bsc = bsc;

        resolve();
      },
    }),
  );

const useEagerConnect = () => {
  const { login } = useAuth();
  const {account} = useWeb3React();

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;
    let isPosiApp = false;
    const parser = new UAParser();
    const uaString = parser.getResult().ua;

    if (uaString.indexOf(modeInUA) > -1) {
      window.isPosiApp = true;
      isPosiApp = true;
    }

    if (isPosiApp && !account && !window.isNoWalletInApp) {
      setTimeout(() => {
        login('injected' as any as ConnectorNames);
        localStorage.setItem(walletLocalStorageKey, 'Metamask');
        localStorage.setItem(connectorLocalStorageKey, ConnectorNames.Injected);
      }, 2000)
      return;
    }
    if (connectorId && !isPosiApp) {
      const isConnectorBinanceChain = connectorId === ConnectorNames.BSC;
      const isBinanceChainDefined = Reflect.has(window, 'BinanceChain');

      // Currently BSC extension doesn't always inject in time.
      // We must check to see if it exists, and if not, wait for it before proceeding.
      if (isConnectorBinanceChain && !isBinanceChainDefined) {
        _binanceChainListener().then(() => login(connectorId));

        return;
      }

      login(connectorId);
    }
  }, [login, account, window.isPosiApp, window.isNoWalletInApp]);
};

export default useEagerConnect;
