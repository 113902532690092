import moment from 'moment';
import { resolveDB } from './dbInit';

const cacheDb = resolveDB('cache', ['name', 'data', 'exp']);

const timeCache = 300; // seconds

export const addDataToStorage = async (data: { [key: string]: number | string | null | undefined }) => {
  try {
    const result = await cacheDb.write(data);
    return result;
  } catch (error) {
    return false;
  }
};

const deleteDataInStorage = async (name: string) => {
  try {
    await cacheDb.delete(name);
    return true;
  } catch (error) {
    return false;
  }
};

export const readDataInStorage = async (name: string) => {
  try {
    const data = await cacheDb.read(name);
    return data;
  } catch (error) {
    return null;
  }
};

export const updateDataToStorage = async (data: { [key: string]: number | string | null | undefined }) => {
  try {
    await cacheDb.update(data);
    return true;
  } catch (error) {
    return null;
  }
};

export class InitFunctionToCall {
  private readonly args: any[];
  private readonly function: any;

  constructor(func: any, ...args: any[]) {
    this.function = func;
    this.args = args;
  }

  public call() {
    return this.function(...this.args);
  }
}

export const getDataWithCache = async (key: string, getRawDataFunc: InitFunctionToCall, timeCacheInit = timeCache) => {
  const data = await readDataInStorage(key);

  if (data) {
    if (!(data?.exp === 'infinity') && moment(data?.exp).isBefore(moment())) {
      deleteDataInStorage(key);
      const response = await getRawDataFunc.call();
      if (response) {
        const objectToSave = {
          name: key,
          data: JSON.stringify(response),
          exp: timeCacheInit === 0 ? 'infinity' : moment().add(timeCacheInit, 'second').unix() * 1000,
        };
        addDataToStorage(objectToSave);
      }
      return response;
    }
    return JSON.parse(data?.data);
  } else {
    const response = await getRawDataFunc.call();
    if (response) {
      const objectToSave = {
        name: key,
        data: JSON.stringify(response),
        exp: timeCacheInit === 0 ? 'infinity' : moment().add(timeCacheInit, 'second').unix() * 1000,
      };
      addDataToStorage(objectToSave);
    }
    return response;
  }
};
