import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';
import { getWeb3NoAccountByChain } from '../adapters/services/BridgeAssetService';
import { ChainTypes } from '../adapters/types';
import getRpcUrl, { getNodeUrlCastNft } from './getRpcUrl';

let web3NoAccount = null;
let httpProvider = null;

const RPC_URL_CAST_NFT = getNodeUrlCastNft() as string;
const httpProviderCastNft = new Web3.providers.HttpProvider(RPC_URL_CAST_NFT, {
  timeout: 10000,
} as HttpProviderOptions);
const web3NoAccountCastNft = new Web3(httpProviderCastNft);

const getWeb3NoAccount = () => {
  if (!!web3NoAccount) {
    return web3NoAccount;
  } else {
    const RPC_URL = getRpcUrl() as string;
    const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions);
    web3NoAccount = new Web3(httpProvider);
    return web3NoAccount;
  }
};
export const getHttpProvider = () => {
  if (!!httpProvider) {
    return httpProvider;
  } else {
    const RPC_URL = getRpcUrl() as string;
    const httpProviderRaw = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions);
    httpProvider = httpProviderRaw;
    return httpProviderRaw;
  }
};

const getWeb3NoAccountCastNft = () => {
  return web3NoAccountCastNft;
};

const getWeb3ByChain = (chain: ChainTypes) => {
  return getWeb3NoAccountByChain(chain);
};

export { getWeb3NoAccount, getWeb3NoAccountCastNft, getWeb3ByChain };
