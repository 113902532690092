import React from 'react';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { NetworkContextName } from './constants';
import store from './redux/store';
import { ChakraProvider } from '@chakra-ui/react';
import ModalProvider from './configs/contexts/ModalContext';
import themeContext from './configs/contexts/ThemeContext';
import { RefreshContextProvider } from './configs/contexts/RefreshContext';
import { getLibrary as libraryForFarm } from './utils/web3React';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={libraryForFarm}>
      <Web3ProviderNetwork getLibrary={libraryForFarm}>
      <GoogleReCaptchaProvider reCaptchaKey="6Ldslw8hAAAAAHfjSe38VUK30Kg_VBNTu-EnZhWK">
        <Provider store={store}>
          <ChakraProvider theme={themeContext}>
            <RefreshContextProvider>
              <ModalProvider>{children}</ModalProvider>
            </RefreshContextProvider>
          </ChakraProvider>
        </Provider>
        </GoogleReCaptchaProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
};

export default Providers;
