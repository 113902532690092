import { formatBigNumber } from './useModalInfo';
import { Contract } from '@ethersproject/contracts';
import { ChainId, WETH } from '@pancakeswap/sdk';
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import { useMemo } from 'react';
import ENS_ABI from '../constants/abis/ens-registrar.json';
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json';
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20';
import ERC20_ABI from '../constants/abis/erc20.json';
import WETH_ABI from '../constants/abis/weth.json';
import masterchefABI from '../configs/abi/masterchef.json';
import PoolEarn3rdTokenABI from '../configs/abi/PoolEarn3rdToken.json';
import CONVERT_LP_ABI from '../constants/abis/convert_lp.json';
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall';
import { getContract } from '../utils';
import { useActiveWeb3React } from './../hooks/useActiveWeb3React';
import useWeb3 from './useWeb3';
import {
  getBep20Contract,
  getGeneralNFTRewardsContract,
  getMasterchefContract,
  getPosiNftContract,
  getPosiNFTMintProxyContract,
  getPosiNFTFactoryContract,
  getSouschefContract,
  getPosiVaultContract,
  getNewGeneralNFTRewardsContract,
  getStakeManagerToEarn3rdContract,
  getBridgeAssetContract,
  getV3GeneralNFTRewardsContract,
} from '../utils/contractHelpers';
import web3Utils from 'web3-utils';
import { getMasterChefAddress } from '../utils/addressHelpers';
import multicall from '../utils/multicall';
import { BigNumber } from 'bignumber.js';
import getLibrary from '../utils/getLibrary';
import { getHttpProvider } from '../utils/web3';
import { ChainTypes } from '../adapters/types';

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export const useERC20 = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getBep20Contract(address, web3), [address, web3]);
};

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(chainId ? WETH[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible);
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  let address: string | undefined;
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case 97:
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible);
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible);
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible);
}

export function useMulticallContract(): Contract | null {
  try {
    const { chainId, account, library } = useActiveWeb3React();
    if (account && library) {
      return getContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, library, account);
    }
  } catch (error) {}
}

export function useConvertLPContract(): Contract | null {
  return useContract('0x47BA847993D64c0a9C03C320C15F6fb4Cc708fE3', CONVERT_LP_ABI, true);
}

export const useMasterchef = () => {
  const web3 = useWeb3();
  return useMemo(() => getMasterchefContract(web3), [web3]);
};

export const useStakingManagerToEarn3rdToken = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getStakeManagerToEarn3rdContract(address, web3), [web3]);
};

export const usePosiNFTMintProxy = () => {
  const web3 = useWeb3();
  return useMemo(() => getPosiNFTMintProxyContract(web3), [web3]);
};

export const usePosiNFTFactory = () => {
  const web3 = useWeb3();
  return useMemo(() => getPosiNFTFactoryContract(web3), [web3]);
};

export const useSousChef = (id: any) => {
  const web3 = useWeb3();
  return useMemo(() => getSouschefContract(id, web3), [id, web3]);
};

export const usePosiNft = () => {
  const web3 = useWeb3();
  return useMemo(() => getPosiNftContract(web3) as any, [web3]);
};

export const useGeneralNFTRewards = () => {
  const web3 = useWeb3();
  return useMemo(() => getGeneralNFTRewardsContract(web3) as any, [web3]);
};

export const useNewGeneralNFTRewards = () => {
  const web3 = useWeb3();
  return useMemo(() => getNewGeneralNFTRewardsContract(web3) as any, [web3]);
};

export const useV3GeneralNFTRewards = () => {
  const web3 = useWeb3();
  return useMemo(() => getV3GeneralNFTRewardsContract(web3) as any, [web3]);
};

export const usePosiVault = (vid) => {
  const web3 = useWeb3();
  return useMemo(() => getPosiVaultContract(vid, web3) as any, [web3]);
};

export const usePosiNftFactory = () => {
  const web3 = useWeb3();
  return useMemo(() => getPosiNFTFactoryContract(web3) as any, [web3]);
};
export const usePosiPerBlock = () => {
  try {
    const calls = [{ address: getMasterChefAddress(), name: 'positionPerBlock', params: [] }];
    return multicall(masterchefABI, calls).then((res) => formatBigNumber(new BigNumber(res)));
  } catch (e) {}
};

export const usePosiPerBlockForPool = async (sousId: any, newStakingManager?: string) => {
  try {
    if (newStakingManager !== undefined) {
      const [rewardPerBlock] = await multicall(PoolEarn3rdTokenABI, [
        {
          address: newStakingManager,
          name: 'rewardPerBlock',
          params: [],
        },
      ]);
      const posiPerBlockNumber = Number(web3Utils.fromWei(rewardPerBlock.toString(), 'ether'));

      return posiPerBlockNumber;
    } else {
      const [positionPerBlock, info, totalAllocPoint] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'positionPerBlock',
          params: [],
        },
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [sousId],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
      ]);
      const posiPerBlockNumber = Number(web3Utils.fromWei(positionPerBlock.toString(), 'ether'));

      const allocePoint: BigNumber = new BigNumber(info.allocPoint._hex);
      const totalAllocPointBig: BigNumber = new BigNumber(totalAllocPoint);
      const positionPerBlockBig: BigNumber = new BigNumber(posiPerBlockNumber);

      const a: BigNumber = new BigNumber(positionPerBlockBig.multipliedBy(allocePoint).toNumber());
      const newPositionPerBlock = a.dividedBy(totalAllocPointBig);
      return newPositionPerBlock.toNumber();
    }
  } catch (e) {
    return 0;
  }
};

export const useGetBridgeAssetContract = (type: ChainTypes) => {
  const web3 = useWeb3();
  return useMemo(() => getBridgeAssetContract(type, web3), [web3, type]);
};
