import axios from 'axios';
import moment from 'moment';

const instance = axios.create({ baseURL: process.env.REACT_APP_TREASURE_HUNT_URL, timeout: 10000 });

export const getAllTask = async (queryString?: string) => {
  try {
    const listTask = await instance({
      url: `tasks?${queryString || ''}`,
      method: 'GET',
    });
    return listTask?.data?.data?.tasks || [];
  } catch (error) {
    return [];
  }
};

export const getProcessOfAllTasks = async (player_address: string) => {
  try {
    const dataProcess = await instance({
      url: `results/processes?player_address=${player_address || ''}`,
      method: 'GET',
    });
    return dataProcess?.data?.data || [];
  } catch (error) {
    return [];
  }
};

export const registerTasks = async (player_address: string, taskIds: number[]) => {
  try {
    await instance({
      url: `players/register`,
      method: 'POST',
      data: {
        player_address,
        task_ids: taskIds,
      },
    });
  } catch (error) {
    return null;
  }
};

export const verifySocialMediaTask = async (account: string, taskId: number, userName: string) => {
  return await instance({
    url: `tasks/social-media`,
    method: 'POST',
    data: {
      player_address: account,
      task_id: taskId,
      type: 'social-media',
      user_name: userName,
    },
  });
};

/**
 *
 * @description get result of task
 * @param player_address address of player
 * @param taskId id of task
 * @returns list data of task result
 */
export const getResultOfTask = async (player_address: string, taskId: number, args?: any) => {
  try {
    const data = await instance({
      url: `results`,
      method: 'GET',
      params: {
        player_address,
        task_id: taskId,
        ...args,
      },
    });
    const listValid = [];
    if (taskId === 1) {
      return data?.data?.data.length
        ? data?.data?.data
        : [
            {
              player_address: player_address,
              task_id: taskId,
              task_status: 'open',
              token_amount: '0',
              usd_amount_traded: '0',
              count: 0,
            },
          ];
    }
    if (data?.data?.data.length > 0) {
      const startOfDay = moment().utc().startOf('day').unix();
      const endOfDay = moment().utc().endOf('day').unix();
      data?.data?.data.forEach((item: any) => {
        const taskTime = moment(item?.task_date).utc().unix();

        if (taskTime >= startOfDay && taskTime <= endOfDay) {
          listValid.push(item);
        }
      });
    }

    return listValid.length
      ? listValid
      : [
          {
            player_address: player_address,
            task_id: taskId,
            task_status: 'open',
            token_amount: '0',
            usd_amount_traded: '0',
            count: 0,
          },
        ];
  } catch (error) {
    return [
      {
        player_address: player_address,
        task_id: taskId,
        task_status: 'open',
        token_amount: '0',
        usd_amount_traded: '0',
        count: 0,
      },
    ];
  }
};

/**
 *
 * @description get result of task
 * @param player_address address of player
 * @param taskId id of task
 * @returns list data of task result
 */
export const getResultOfTaskList = async (player_address: string, taskId: number, args?: any) => {
  try {
    const data = await instance({
      url: `results`,
      method: 'GET',
      params: {
        player_address,
        task_id: taskId,
        ...args,
      },
    });
    return (
      data?.data?.data || [
        {
          player_address: player_address,
          task_id: taskId,
          task_status: 'open',
          token_amount: '0',
          usd_amount_traded: '0',
          count: 0,
        },
      ]
    );
  } catch (error) {
    return [
      {
        player_address: player_address,
        task_id: taskId,
        task_status: 'open',
        token_amount: '0',
        usd_amount_traded: '0',
        count: 0,
      },
    ];
  }
};

export const SpinReward = async (player_address: string, type: string, reward_level: string, signature: string) => {
  return await instance({
    url: `rewards/spin`,
    method: 'POST',
    data: {
      player_address,
      type,
      reward_level,
      signature,
    },
  }).then((res) => res.data.data);
};

export const ClaimRewardItem = (player_address: string, reward_item_id: number) => {
  return instance
    .post(`rewards/claim`, {
      player_address,
      reward_item_id,
    })

    .then((res) => res.data.data || {})

    .catch((err) => {
      return {};
    });
};

export const getPlayerPoint = async (account: string) => {
  try {
    const res = await instance({
      url: `players?player_address=${account}`,
      method: 'GET',
      data: {
        player_address: account,
      },
    });
    return (
      (res?.data?.data?.players && res?.data?.data?.players[0]) || {
        player_address: account,
        points: 0,
      }
    );
  } catch (error) {
    return {
      player_address: account,
      points: 0,
    };
  }
};

export const getSpinedReward = async (account: string) => {
  try {
    const res = await instance({
      url: `players/spinned-levels?player_address=${account}`,
      method: 'GET',
      data: {
        player_address: account,
      },
    });
    return res?.data?.data?.spinned_level || [];
  } catch (error) {
    return [];
  }
};

export const checkInDailyTask = async (player_address: string) => {
  try {
    return await instance({
      url: `tasks/check-in`,
      method: 'POST',
      data: {
        player_address,
      },
    });
  } catch (error) {
    return null;
  }
};

export const getUserReward = async (player_address: string) => {
  try {
    const data = await instance({
      url: `players/reward-items?player_address=${player_address}&limit=100`,
      method: 'GET',
      data: {
        player_address,
      },
    });
    return data?.data?.data?.items || [];
  } catch (error) {
    return [];
  }
};

export const getListLeaderBoard = async () => {
  try {
    const data = await instance({
      url: `players/leader-board?limit=10`,
      method: 'GET',
    });
    return data?.data?.data?.items || [];
  } catch (error) {
    return [];
  }
};

export const claimReward = async (player_address: string, id: number, signature: string) => {
  try {
    const data = await instance({
      url: `rewards/claim`,
      method: 'POST',
      data: {
        player_address,
        player_reward_id: id,
        signature,
      },
    });
    return data?.data?.data || null;
  } catch (error) {
    return null;
  }
};

export const getPlayerRanking = async (player_address: string) => {
  try {
    const data = await instance({
      url: `players/ranking?player_address=${player_address}`,
      method: 'GET',
      data: {
        player_address,
      },
    });

    return data?.data?.data?.rank_level || 0;
  } catch (error) {
    return 0;
  }
};
