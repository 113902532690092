import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { getStatusCompetition } from '../../utils/common';
import { BuyingCompetitionState, TYPE_COMPETITIONS } from '../types';
import {
  fetchAllCompetition,
  fetchDataBuyingCompetition,
  fetchDataCompetitionBalance,
  fetchDataReferralRankingList,
  fetchDataWithdrawalHistory,
  getBuyingCompetitionUserData,
} from './fetchData';

const initialState: BuyingCompetitionState = {
  listRanking: [],
  currentCompetition: null,
  totalPosi: {
    purchased: 0,
    score: 0,
    sold: 0,
    posiChange: 0,
    percentChange: 0,
  },
  competitionOverview: {},
  competitionBalance: 0,
  withdrawHistory: [],
  allCompetition: [],
};
export const buyingCompetitionSlice = createSlice({
  name: 'buyingCompetition',
  initialState,
  reducers: {
    setDataRankingList: (state, action) => {
      const data = state;
      data.listRanking = action.payload;
      return data;
    },
    setCurrentCompetition: (state, action) => {
      const data = state;
      data.currentCompetition = action.payload;
      return data;
    },
    setTotalPosi: (state, action) => {
      const data = state;
      data.totalPosi = action.payload;
      return data;
    },
    setDataCompetitionOverview: (state, action) => {
      const data = state;
      data.competitionOverview = action.payload;
      return data;
    },
    setDataCompetitionBalance: (state, action: PayloadAction<{ balance: number | string; historyList: any[] }>) => {
      const data = state;
      data.competitionBalance = action.payload.balance;
      data.withdrawHistory = action.payload.historyList;
      return data;
    },
    setDataAllCompetition: (state, action) => {
      const data = state;
      data.allCompetition = action.payload;
      return data;
    },
  },
});

export const {
  setCurrentCompetition,
  setDataCompetitionBalance,
  setDataRankingList,
  setTotalPosi,
  setDataCompetitionOverview,
  setDataAllCompetition,
} = buyingCompetitionSlice.actions;

export const fetchPublicDataOfBuyingCompetition = () => async (dispatch: any) => {
  dispatch(fetchListRankingOfBuyingCompetition());
  dispatch(fetchCurrentCompetition());
  dispatch(fetchDataAllCompetition());
};

export const fetchCurrentCompetition = () => async (dispatch: any) => {
  const data = await fetchDataBuyingCompetition();
  if (data && data.length > 0) {
    const currentData = data[0];
    dispatch(
      setCurrentCompetition({
        ...currentData,
        startTime: currentData?.start,
        endTime: currentData?.end,
        prizePool: currentData?.prize_pool,
      }),
    );
    const status = getStatusCompetition(currentData?.start, currentData?.end);
    const customData = { ...currentData, status };
    dispatch(setDataCompetitionOverview(customData));
  }
};
export const fetchListRankingOfBuyingCompetition = () => async (dispatch: any) => {
  const listRankings = await fetchDataReferralRankingList();
  if (listRankings) {
    const newList = listRankings.sort((a: any, b: any) => Number(a.place) - Number(b.place));
    dispatch(
      setDataRankingList((newList && newList?.sort((a: any, b: any) => Number(a.place) - Number(b.place))) ?? []),
    );
  }
};

export const fetchDataTotalPosi = (competitionId: string | number, address: string) => async (dispatch: any) => {
  try {
    const res = await getBuyingCompetitionUserData(competitionId, address);
    dispatch(
      setTotalPosi({
        purchased: res?.totalPurchase,
        score: res?.score,
        sold: res?.totalSold,
        posiChange: res?.posiChange,
        percentChange: res?.percentChange,
      }),
    );
  } catch (e) {}
};

export const getBuyingCompetition = (address: string) => async (dispatch: any) => {
  try {
    const listHistoryResponse = await fetchDataWithdrawalHistory(address);
    const balanceResponse = await fetchDataCompetitionBalance(address);
    dispatch(setDataCompetitionBalance({ balance: balanceResponse || 0, historyList: listHistoryResponse || [] }));
  } catch (e) {}
};

export const fetchDataAllCompetition = () => async () => {
  try {
    const currentDate = moment();
    const competitions = await fetchAllCompetition();
    if (competitions?.length) {
      const competitionActive = [];
      let endTimeLastCompetition = 0;
      competitions.forEach((e) => {
        if (moment(currentDate).isSameOrBefore(e?.end) && TYPE_COMPETITIONS.includes(e?.type)) {
          competitionActive.push({
            id: e?.id,
            type: e?.type,
          });
        }
        if (moment(endTimeLastCompetition).isSameOrBefore(e?.end)) {
          endTimeLastCompetition = e?.end;
        }
      });
      localStorage.setItem('has_new_competition', JSON.stringify(competitionActive));
    }
  } catch (e) {}
};

export default buyingCompetitionSlice.reducer;
