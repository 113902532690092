import tokens from './tokens';
import { FarmConfig, TYPE_OF_FARM } from './types';

const farms: FarmConfig[] = [
  // {
  //   pid: 0,
  //   id: 1,
  //   rewardPriceLabel: 'POSI',
  //   name: 'BUSD',
  //   type: TYPE_OF_FARM.AUTO,
  //   vaultManager: {
  //     97: '0x69ec4d70ac5d2d2db496a158e5628862824944dc',
  //     56: '0xf35848441017917a034589BfbEc4B3783BB39cb2',
  //   },
  //   lpAddresses: {
  //     97: '0x787cF64b9F6E3d9E120270Cb84e8Ba1Fe8C1Ae09',
  //     56: '0x00222d964a2077301309809ab3bf56485c126a9c',
  //   },
  //   stakingManager: {
  //     97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
  //     56: '0x0c54b0b7d61de871db47c3ad3f69feb0f2c8db0b',
  //   },
  //   token: tokens.busd,
  //   stakingSingleToken: 'token',
  //   isFinished: true,
  //   harvestLockupPeriod: 24,
  //   quoteToken: tokens.posiv2,
  //   apr: 0,
  //   formatDecimals: 3,
  //   images: '/images/farms/busd.png',
  //   abiName: 'vaultAbiBUSD',
  //   needMigration: true,
  // },
  // {
  //   pid: 2,
  //   id: 2,
  //   formatDecimals: 8,
  //   rewardPriceLabel: 'POSI_IN_BNB',
  //   name: 'BNB',
  //   type: TYPE_OF_FARM.AUTO,
  //   vaultManager: {
  //     97: '0x69ec4d70ac5d2d2db496a158e5628862824944dc',
  //     56: '0xc1742a30b7469f49f37239b1c2905876821700e8',
  //   },
  //   lpAddresses: {
  //     97: '0x787cF64b9F6E3d9E120270Cb84e8Ba1Fe8C1Ae09',
  //     56: '0x254baa324a7e8876f4d51c3eff4b962f16672c5f',
  //   },
  //   stakingManager: {
  //     97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
  //     56: '0x0c54b0b7d61de871db47c3ad3f69feb0f2c8db0b',
  //   },
  //   token: tokens.bnb,
  //   quoteToken: tokens.posiv2,
  //   stakingSingleToken: 'token',
  //   isFinished: true,
  //   harvestLockupPeriod: 24,
  //   apr: 0,
  //   images: '/images/farms/bnb.png',
  //   abiName: 'vaultAbiBNB',
  //   needMigration: true,
  // },
  {
    pid: 0,
    id: 3,
    name: 'POSI-BUSD LP',
    type: TYPE_OF_FARM.MANUAL,
    lpAddresses: {
      97: '0x787cF64b9F6E3d9E120270Cb84e8Ba1Fe8C1Ae09',
      56: '0x00222d964a2077301309809ab3bf56485c126a9c',
    },
    stakingManager: {
      97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
      56: '0x0c54b0b7d61de871db47c3ad3f69feb0f2c8db0b',
    },
    token: tokens.posiv2,
    quoteToken: tokens.busd,
    stakingSingleToken: 'quoteToken',
    isFinished: true,
    harvestLockupPeriod: 24,
    apr: 0,
    images: '/images/farms/posi-busd.svg',
    abiName: 'masterchef',
    needMigration: true,
  },
  {
    pid: 2,
    id: 4,
    type: TYPE_OF_FARM.MANUAL,
    name: 'POSI-BNB LP',
    lpAddresses: {
      97: '0x787cF64b9F6E3d9E120270Cb84e8Ba1Fe8C1Ae09',
      56: '0x254baa324a7e8876f4d51c3eff4b962f16672c5f',
    },
    stakingManager: {
      97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
      56: '0x0c54b0b7d61de871db47c3ad3f69feb0f2c8db0b',
    },
    stakingSingleToken: 'quoteToken',
    token: tokens.posiv2,
    quoteToken: tokens.wbnb,
    isFinished: true,
    harvestLockupPeriod: 24,
    apr: 0,
    images: '/images/farms/posi-bnb.svg',
    abiName: 'masterchef',
    needMigration: true,
  },
  {
    pid: 0,
    id: 5,
    rewardPriceLabel: 'PTX_BUSD_VAULT_BUSD_TWAP',
    name: 'BUSD',
    type: TYPE_OF_FARM.AUTO,
    vaultManager: {
      97: '0xbaa0a38b258fee0eec25b3f92509250d05cbd34c',
      56: '0xf35848441017917a034589BfbEc4B3783BB39cb2',
    },
    lpAddresses: {
      97: '0x7664a77703673D9313692B479C71c3E8A16e3671',
      56: '0x00222D964a2077301309809Ab3bf56485C126A9C',
    },
    stakingManager: {
      97: '0xfbB0a9c48eDe2fF3Aad8b0b7c79c8eFa8221121B',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    token: tokens.busd,
    stakingSingleToken: 'token',
    isFinished: false,
    harvestLockupPeriod: 12,
    quoteToken: tokens.posiv2,
    apr: 0,
    formatDecimals: 3,
    images: '/images/farms/busd.png',
    abiName: 'vaultAbiBUSDNew',
    earnedToken: tokens.prt,
    isScheduleHarvest: true,
  },
  {
    pid: 2,
    id: 6,
    formatDecimals: 8,
    rewardPriceLabel: 'PTX_BNB_VAULT_BNB_TWAP',
    name: 'BNB',
    type: TYPE_OF_FARM.AUTO,
    vaultManager: {
      97: '0x69ec4d70ac5d2d2db496a158e5628862824944dc',
      56: '0xc1742a30b7469f49f37239b1c2905876821700e8',
    },
    lpAddresses: {
      97: '0x3209C6D5456b499cfac1aA394b90F6e36991cc5b',
      56: '0x254baa324a7e8876f4d51c3eff4b962f16672c5f',
    },
    stakingManager: {
      97: '0xfbB0a9c48eDe2fF3Aad8b0b7c79c8eFa8221121B',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    token: tokens.bnb,
    quoteToken: tokens.posiv2,
    stakingSingleToken: 'token',
    isFinished: false,
    harvestLockupPeriod: 12,
    apr: 0,
    images: '/images/farms/bnb.png',
    abiName: 'vaultAbiBNBNew',
    earnedToken: tokens.prt,
    isScheduleHarvest: true,
  },
  {
    pid: 0,
    id: 7,
    name: 'POSI-BUSD LP',
    type: TYPE_OF_FARM.MANUAL,
    lpAddresses: {
      97: '0x7664a77703673D9313692B479C71c3E8A16e3671',
      56: '0x00222d964a2077301309809ab3bf56485c126a9c',
    },
    stakingManager: {
      97: '0xfbB0a9c48eDe2fF3Aad8b0b7c79c8eFa8221121B',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    token: tokens.posiv2,
    quoteToken: tokens.busd,
    stakingSingleToken: 'quoteToken',
    isFinished: false,
    harvestLockupPeriod: 12,
    apr: 0,
    images: '/images/farms/posi-busd.svg',
    abiName: 'masterchefNew',
    earnedToken: tokens.prt,
    isScheduleHarvest: true,
  },
  {
    pid: 2,
    id: 8,
    type: TYPE_OF_FARM.MANUAL,
    name: 'POSI-BNB LP',
    lpAddresses: {
      97: '0x3209C6D5456b499cfac1aA394b90F6e36991cc5b',
      56: '0x254baa324a7e8876f4d51c3eff4b962f16672c5f',
    },
    stakingManager: {
      97: '0xfbB0a9c48eDe2fF3Aad8b0b7c79c8eFa8221121B',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    stakingSingleToken: 'quoteToken',
    token: tokens.posiv2,
    quoteToken: tokens.wbnb,
    isFinished: false,
    harvestLockupPeriod: 12,
    apr: 0,
    images: '/images/farms/posi-bnb.svg',
    abiName: 'masterchefNew',
    earnedToken: tokens.prt,
    isScheduleHarvest: true,
  },
];

export default farms;
