import BigNumber from 'bignumber.js/bignumber';

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
  ROUNDING_MODE: 1,
});

export const BSC_BLOCK_TIME = 3;

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 18 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 12 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in components/CakeStats.tsx = 22 (40 - Amount sent to burn pool)

export const CAKE_PER_BLOCK = new BigNumber(40);
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365); // 10512000
export const BASE_URL = 'https://position.exchange';
export const BASE_EXCHANGE_URL = window.location.origin;
export const BASE_SWAP = `${BASE_EXCHANGE_URL}/swap`;
export const BASE_NFT = `${BASE_EXCHANGE_URL}/nfts`;
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/liquidity/#/add`;
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`;
export const BASE_REFERRAL_LINK = 'https://position.exchange';
export const BASE_BSC_SCAN_URL = 'https://bscscan.com';
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50;
export const LOTTERY_TICKET_PRICE = 1;
export const FARMABLE_TOKEN_PREFIX = 'da';
export const FARMABLE_VARIABLE_DEBT_TOKEN_PREFIX = 'fvDebt';
export const FARMABLE_STABLE_DEBT_TOKEN_PREFIX = 'fsDebt';
export const LIST_LANGUAGE = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'cn',
    label: '中文简体',
  },
  {
    value: 'dt',
    label: 'Nederlands',
  },
  {
    value: 'id',
    label: 'Bahasa Indonesia',
  },
  {
    value: 'jp',
    label: '日本語',
  },
  {
    value: 'prs',
    label: 'فارسی',
  },
  {
    value: 'rn',
    label: 'русский',
  },
  {
    value: 'vi',
    label: 'Tiếng Việt',
  },
  {
    value: 'srb',
    label: 'Српски',
  },
  {
    value: 'arb',
    label: 'عربي',
  },
  {
    value: 'sp',
    label: 'Español',
  },
  {
    value: 'fr',
    label: 'français',
  },
  {
    value: 'hi',
    label: 'हिंदी',
  },
  {
    value: 'por',
    label: 'Portuguese',
  },
];
export const SUFFIX_EST = 4;
export const SUFFIX_BALANCE = 6;
export const ADDRESS0 = '0x0000000000000000000000000000000000000000';
export const SUFFIX_VALUE = 3;

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
