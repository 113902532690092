import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { serialize, uniqBy } from '../../utils/common';
import { ListBond } from '../types';
import { getListBond } from './fetchListBond';

const initialState: ListBond = {
  listBond: [],
  isLoading: false,
  totalPage: 0,
  isModal: localStorage.getItem('is_modal') || '0',
  totalBond: 0,
  payAmount: '',
  bondAmount: '',
};
export const bondSlice = createSlice({
  name: 'bonds',
  initialState,
  reducers: {
    setListBond: (state, action) => {
      state.listBond = action.payload;
      return state;
    },
    addDataToListBond: (state, action) => {
      const data = state;
      const { payload } = action;
      const newList = data.listBond.concat(payload);
      const uniqueList = uniqBy(newList, 'id');
      data.listBond = uniqueList;
      return data;
    },
    setTotalPage: (state, action) => {
      state.totalPage = action.payload;
      return state;
    },
    updateCurrentBond: (state, action) => {
      state.listBond.map((e) => {
        if (e?.id === action.payload.id) {
          e = { ...e, totalStake: action.payload.totalStake, posiPerBlock: action.payload.posiPerBlock };
        }
        return e;
      });
      return state;
    },
    setOpenModal: (state, action) => {
      const newData = action.payload;
      state.isModal = newData;
      localStorage.setItem('is_modal', newData.toString());
      return state;
    },
    setIsLoading: (state, action) => {
      const newData = action.payload;
      state.isLoading = newData;
      return state;
    },
    setTotalBond: (state, action) => {
      const newData = action.payload;
      state.totalBond = newData;
      return state;
    },
    setBondAmount: (state, action: PayloadAction<{ amount: string; price: number }>) => {
      const data = state;
      const { amount, price } = action.payload;
      data.bondAmount = amount
      const total = Number(amount) * Number(price);
      data.payAmount = amount === '' ? '' : total.toString();

      return data;
    },
    setPayAmount: (state, action: PayloadAction<{ amount: string; price: number }>) => {
      const data = state;
      const { amount, price } = action.payload;
      data.payAmount = amount
      const total = Number(amount) / Number(price)
      data.bondAmount = amount === '' ? '' : total.toString();
      return data;
    },
  },
});

export const {
  setIsLoading,
  setListBond,
  addDataToListBond,
  setTotalPage,
  setOpenModal,
  updateCurrentBond,
  setTotalBond,
  setPayAmount,
  setBondAmount,
} = bondSlice.actions;

export const fetchListBond = (params?: any) => async (dispatch: any) => {
  const queryString = serialize(params);
  dispatch(setIsLoading(true));
  const bonds = await getListBond(queryString);
  dispatch(setIsLoading(false));
  if (bonds && bonds.items && bonds.items.length > 0) {
    dispatch(setListBond(bonds.items ? bonds.items : []));
    dispatch(setTotalPage(bonds ? bonds.total_page : 0));
    dispatch(setTotalBond(bonds ? Number(bonds.total_bonds) : 0));
  }
};

export const fetchMoreListBond = (params, isLoading?: boolean) => async (dispatch: any) => {
  isLoading && dispatch(setIsLoading(true));
  const queryString = serialize(params);
  const bonds = await getListBond(queryString);
  isLoading && dispatch(setIsLoading(false));
  if (bonds && bonds.items && bonds.items.length > 0) {
    dispatch(addDataToListBond(bonds.items));
  }
};

export const fetchPageBonds = (params) => async (dispatch: any) => {
  const queryString = serialize(params);
  const bonds = await getListBond(queryString);
  dispatch(setTotalPage(bonds.total_page));
};

export default bondSlice.reducer;
