import { useWeb3React } from '@web3-react/core';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPopup, PopupContent, removePopup, toggleSettingsMenu, toggleWalletModal } from './actions';
import { AppState } from '../store';
import BigNumber from 'bignumber.js';

export function useBlockNumber(): number | undefined {
  const { chainId } = useWeb3React();
  const block1 = useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1]) ?? 0;
  const block2 = useSelector((state: AppState) => state.block.currentBlock) ?? 0;
  const block3 = window.blockNumber ? new BigNumber(window.blockNumber).toNumber() : 0;

  return useMemo(() => {
    return block2 || block1 || block3;
  }, [block1, block2, block3]);
}

export function useWalletModalOpen(): boolean {
  return useSelector((state: AppState) => state.application.walletModalOpen);
}

export function useWalletModalToggle(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleWalletModal()), [dispatch]);
}

export function useSettingsMenuOpen(): boolean {
  return useSelector((state: AppState) => state.application.settingsMenuOpen);
}

export function useToggleSettingsMenu(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleSettingsMenu()), [dispatch]);
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch();

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }));
    },
    [dispatch],
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch();
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }));
    },
    [dispatch],
  );
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList);
  return useMemo(() => list.filter((item: any) => item.show), [list]);
}
