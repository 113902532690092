/* eslint-disable react/no-children-prop */
import {
  Button,
  ChakraProvider,
  Divider,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import themeContext from '../../../configs/contexts/ThemeContext';

interface IConfirmNetworkFeeModal {
  onDismiss: (val: boolean) => void;
  isOpenModal: boolean;
  config: any;
}

const ConfirmNetworkFeeModal: React.FC<IConfirmNetworkFeeModal> = ({ onDismiss, isOpenModal, config }) => {
  const { t } = useTranslation(['common']);

  const handleConfirm = () => {
    onDismiss(true);
  };

  const handleClose = () => {
    onDismiss(false);
  };

  return (
    <ChakraProvider theme={themeContext}>
      <Modal onClose={handleClose} blockScrollOnMount={true} isOpen={isOpenModal} size="md" isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" width="100%" height="100%" />
        <ModalContent bg="gray.800" px="24px" pb="24px" borderRadius="24px">
          <ModalHeader pb={'0px'} color={'teal.150'} fontSize={18} textAlign={'center'} mt={'16px'}>
            {config?.title || ''}
          </ModalHeader>
          <ModalCloseButton color={'gray.500'} mt={'20px'} onClick={handleClose} />
          <ModalBody>
            <Divider mt={'4px'} mb={'8px'} />
            <Flex flexDirection="column" mt="16px">
              <Text color="gray.500" mt="16px" textAlign="center" wordBreak="normal">
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={config?.content || ''} />
              </Text>
              {config?.listNote && config?.listNote.length > 0 && (
                <Flex
                  mt="12px"
                  flexDirection="column"
                  py="8px"
                  px="16px"
                  borderWidth="1px"
                  borderRadius="12px"
                  borderColor="yellow.400"
                >
                  <Text mx="auto" color="yellow.400" fontSize="16px" fontWeight="semibold">
                    {t('please_note')}
                  </Text>
                  <UnorderedList id="list-note-warning" color="gray.500" fontSize="12px">
                    {config?.listNote.map((item, index) => (
                      <ListItem mt="8px" key={`note-warning${index}`} pl="5px">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} children={item || ''} />
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Flex>
              )}
            </Flex>
            <Flex flexDirection={'row'} mt={'30px'} mb={'20px'} justifyContent={'space-between'}>
              <Button
                w={'48%'}
                color={'white'}
                borderRadius={'25px'}
                h={'45px'}
                _hover={{ bg: 'gray.700' }}
                _active={{ bg: 'gray.700' }}
                bg="gray.800"
                borderWidth={'1px'}
                borderColor={'gray.500'}
                onClick={handleClose}
              >
                {t('Cancel')}
              </Button>
              <Button
                onClick={handleConfirm}
                w={'48%'}
                borderRadius={'25px'}
                h={'45px'}
                bg={'teal.150'}
                _hover={{ bg: 'teal.150', opacity: '0.7' }}
                _active={{ bg: 'teal.150', opacity: '0.7' }}
                color={'white'}
              >
                {t('Confirm')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ConfirmNetworkFeeModal;
