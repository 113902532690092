import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toWei } from 'web3-utils';
import useWeb3 from '../../../hooks/useWeb3';
import { useTransactionAdder } from '../../../redux/transactions/hooks';
import { callEstGas } from '../../../utils/callHelpers';
import { formatBondBalance } from '../../../utils/common';
import { useResponseModal } from '../../../utils/confirmModal';
import { getBondCommitContract } from '../../../utils/contractHelpers';

export default function ConfirmCommit({ isOpen, onClose, amount, price, assetId, account, bondAddress, faceAsset }) {
  const { t } = useTranslation('bonds');
  const [isLargerThan480] = useMediaQuery('(max-width: 480px ) and (min-width: 0px )');
  const addTransaction = useTransactionAdder();
  const web3 = useWeb3();
  const onCommit = useCallback(async () => {
    if (account) {
      try {
        const contract = getBondCommitContract(bondAddress, web3);
        const total = Number(amount);
        const totalWei = toWei(total.toFixed(6), 'ether');
        const method = contract.methods.commit(totalWei);
        let gas;
        try {
          gas = await callEstGas(method, account);
        } catch (e) {}
        method.send({ from: account, gas }, async function (err, hash) {
          if (hash) {
            addTransaction({ hash } as any, {
              summary: t('commit_success_content', { balance: `${amount} ${faceAsset?.name}` }),
              title: t('commit_success'),
              trigger: () => {
                onClose();
              },
            });
            await useResponseModal({
              title: t('transaction_submitted'),
              txHash: hash,
            });
          }
          if (err) {
            if (err?.message) {
              if (err?.message) {
                await useResponseModal({
                  title: t('commit_failed'),
                  errorMsg: err?.message,
                });
              }
            }
            throw err;
          }
        });
      } catch (e) {
        await useResponseModal({
          title: t('commit_failed'),
          errorMsg: e?.message,
        });
      }
    }
  }, [account, amount, price, web3, bondAddress]);
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} isCentered={!isLargerThan480}>
      <ModalOverlay width="100%" height="100%" />
      <ModalContent
        mt={['126px', '3.75rem']}
        mx={['16px', '0px']}
        px={['12px', '16px', '24px']}
        pt="20px"
        borderRadius="24px"
        w={['410px']}
        // height={['480px', '480px', '480px', '600px', '800px', '800px']}
        height={['auto']}
        maxH="320px"
        color="white"
        bg="gray.800"
        boxShadow={'0px 8px 32px rgba(0, 0, 0, 0.25)'}
      >
        <ModalHeader p={['0px']} textAlign={'center'}>
          <Text fontSize={['14px', '16px']} fontWeight={500} color={'gray.100'}>
            {t('confirm_commit')}
          </Text>
        </ModalHeader>
        <ModalCloseButton size="lg" color="white" />
        <ModalBody fontSize="14px" bg="none" textAlign="center" color="white" mb="24px" py="0px" px={'0px'}>
          <Text fontSize={['24px', '32px']} fontWeight={600} color={'teal.150'}>
            {assetId}
          </Text>
          <Box
            px={['16px']}
            py={['12px']}
            fontWeight={500}
            color={'gray.500'}
            mt={['20px']}
            background={'gray.900'}
            borderRadius={'8px'}
          >
            <Flex fontWeight={600} fontSize={'20px'} color={'gray.200'} justifyContent={'space-between'}>
              <Text>{t('amount')}:</Text>
              <Text>{formatBondBalance(Number(amount))}</Text>
            </Flex>
          </Box>
          <Button
            _hover={{
              bg: 'green.500',
            }}
            _active={{
              bg: 'green.500',
            }}
            width={'100%'}
            mt={['24px']}
            color={'white'}
            bg={'teal.150'}
            borderRadius={'8px'}
            fontWeight={500}
            fontSize={['14px']}
            onClick={onCommit}
          >
            {t('confirm_commit')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
