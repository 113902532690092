import BigNumber from 'bignumber.js';
import erc20ABI from '../../configs/abi/erc20.json';
import masterchefABI from '../../configs/abi/masterchef.json';
import positionReferralABI from '../../configs/abi/positionReferral.json';
import vaultAbiBUSD from '../../configs/abi/vaultAbiBUSD.json';
import multicall from '../../utils/multicall';
import { getAddress, getPosiReferralAddress } from '../../utils/addressHelpers';
import { FarmConfig, TYPE_OF_FARM } from '../../configs/constants/types';
import { formatBigNumber } from '../../hooks/useModalInfo';
import tokens from '../../configs/constants/tokens';

export const fetchFarmUserData = async (farmConfig: FarmConfig, account: string) => {
  if (!account) {
    return {
      tokenAllowance: 0,
      quoteTokenAllowance: 0,
      lpAllowance: 0,
      tokenBalance: 0,
      quoteTokenBalance: 0,
      lpBalance: 0,

      earnings: 0,
      lpStaked: 0,
      stakedBalance: 0,
      pendingEarned: 0,
    };
  }
  if (farmConfig.type === TYPE_OF_FARM.MANUAL) {
    const stakingManager = getAddress(farmConfig.stakingManager);
    const quoteTokenAddress =
      farmConfig?.quoteToken?.symbol === 'wBNB'
        ? getAddress(tokens.bnb.address)
        : getAddress(farmConfig.quoteToken.address);
    const callToErc20 = [
      { address: getAddress(farmConfig.token.address), name: 'allowance', params: [account, stakingManager] },
      { address: quoteTokenAddress, name: 'allowance', params: [account, stakingManager] },
      { address: getAddress(farmConfig.lpAddresses), name: 'allowance', params: [account, stakingManager] },
      { address: getAddress(farmConfig.token.address), name: 'balanceOf', params: [account] },
      {
        address: quoteTokenAddress,
        name: 'balanceOf',
        params: [account],
      },
      { address: getAddress(farmConfig.lpAddresses), name: 'balanceOf', params: [account] },
    ];

    const callToStakingManager = [
      {
        address: stakingManager,
        name: 'userInfo',
        params: [farmConfig.pid, account],
      },
      {
        address: stakingManager,
        name: 'pendingPosition',
        params: [farmConfig.pid, account],
      },
    ];

    const [
      tokenAllowanceRaw,
      quoteTokenAllowanceRaw,
      lpAllowanceRaw,
      tokenBalanceRaw,
      quoteTokenBalanceRaw,
      lpBalanceRaw,
    ] = await multicall(erc20ABI, callToErc20);
    const [lpStakingRaw, earningsRaw] = await multicall(masterchefABI, callToStakingManager);
    const tokenAllowance = new BigNumber(tokenAllowanceRaw[0]._hex).toJSON();
    const quoteTokenAllowance = new BigNumber(quoteTokenAllowanceRaw[0]._hex).toJSON();
    const lpAllowance = new BigNumber(lpAllowanceRaw[0]._hex).toJSON();

    const tokenBalance = formatBigNumber(new BigNumber(tokenBalanceRaw[0]._hex));
    const quoteTokenBalance = formatBigNumber(new BigNumber(quoteTokenBalanceRaw[0]._hex));
    const lpBalance = formatBigNumber(new BigNumber(lpBalanceRaw[0]._hex));

    const earnings = formatBigNumber(new BigNumber(earningsRaw[0]._hex));
    const lpStaked = formatBigNumber(new BigNumber(lpStakingRaw[0]._hex));

    let lpBalanceInCake = 0;
    if (!!farmConfig?.pancakePid) {
      const arrConfig = farmConfig.pancakePid.split('_');
      const lpAddressCake = arrConfig[1];

      const callToCake = [
        {
          address: lpAddressCake,
          name: 'balanceOf',
          params: [account],
        },
      ];

      const [lpBalanceInCakeRaw] = await multicall(erc20ABI, callToCake);

      lpBalanceInCake = formatBigNumber(new BigNumber(lpBalanceInCakeRaw[0]._hex));
    }

    return {
      tokenAllowance,
      quoteTokenAllowance,
      lpAllowance,
      tokenBalance,
      quoteTokenBalance,
      lpBalance,

      earnings,
      lpStaked,
      stakedBalance: 0,
      pendingEarned: 0,
      lpBalanceInCake,
    };
  } else {
    const callsActionArray = [
      {
        address: getAddress(farmConfig.vaultManager),
        name: 'balanceOf',
        params: [account],
      },
      {
        address: getAddress(farmConfig.vaultManager),
        name: 'earned',
        params: [account],
      },
      {
        address: getAddress(farmConfig.vaultManager),
        name: 'lpOf',
        params: [account],
      },
    ];

    const callsPendingEarn = [
      {
        address: getAddress(farmConfig.vaultManager),
        name: 'pendingEarned',
        params: [account],
      },
    ];

    const vaultTokenAllowance = [
      {
        address: getAddress(farmConfig.token.address),
        name: 'allowance',
        params: [account, getAddress(farmConfig.vaultManager)],
      },
      {
        address: getAddress(farmConfig.token.address),
        name: 'balanceOf',
        params: [account],
      },
      {
        address: getAddress(farmConfig.lpAddresses),
        name: 'balanceOf',
        params: [account],
      },
      {
        address: getAddress(farmConfig.lpAddresses),
        name: 'allowance',
        params: [account, getAddress(farmConfig.vaultManager)],
      },
      {
        address: getAddress(tokens.posiv2.address),
        name: 'balanceOf',
        params: [account],
      },
      {
        address: getAddress(tokens.posiv2.address),
        name: 'allowance',
        params: [account, getAddress(farmConfig.vaultManager)],
      },
    ];

    const referralCall = [
      {
        address: getPosiReferralAddress(),
        name: 'getReferrer',
        params: [account],
      },
    ];

    const [vaultTokenAllowanceRaw, balanceOfRaw, balanceOfLPRaw, allowanceLpRaw, posiBalanceRaw, posiAllowanceRaw] =
      await multicall(erc20ABI, vaultTokenAllowance);

    let vaultReferralCodeRaw;

    try {
      [vaultReferralCodeRaw] = await multicall(positionReferralABI, referralCall);
    } catch (errorABI) {}

    const [stakedBalanceRaw, earnedRaw, lpStaked] = (await multicall(vaultAbiBUSD, callsActionArray)) ?? [];

    let pendingEarnedRaw: any;
    try {
      [pendingEarnedRaw] = await multicall(vaultAbiBUSD, callsPendingEarn);
    } catch (errr) {}
    const stakedBalance = formatBigNumber(new BigNumber(stakedBalanceRaw[0]._hex));
    const earned = formatBigNumber(new BigNumber(earnedRaw[0]._hex));
    const pendingEarned = pendingEarnedRaw ? formatBigNumber(new BigNumber(pendingEarnedRaw[0]._hex)) : 0;
    const allowance = new BigNumber(vaultTokenAllowanceRaw[0]._hex).toJSON();
    const tokenBalance = formatBigNumber(new BigNumber(balanceOfRaw[0]._hex));
    const farmLpBalance = formatBigNumber(new BigNumber(balanceOfLPRaw[0]._hex));
    const allowanceLpFarm = new BigNumber(allowanceLpRaw[0]._hex).toJSON();
    const totalLpStaked = formatBigNumber(new BigNumber(lpStaked[0]._hex));
    const posiBalance = formatBigNumber(new BigNumber(posiBalanceRaw[0]._hex));
    const allowancePosi = new BigNumber(posiAllowanceRaw[0]._hex).toJSON();
 
    return {
      tokenAllowance: farmConfig.token.symbol === 'BNB' ? 1 : allowance,
      quoteTokenAllowance: allowancePosi,
      lpAllowance: allowanceLpFarm,
      tokenBalance,
      quoteTokenBalance: posiBalance,
      lpBalance: farmLpBalance,

      earnings: earned,
      lpStaked: totalLpStaked,
      stakedBalance,
      pendingEarned,

      vaultReferralCode: (vaultReferralCodeRaw && vaultReferralCodeRaw.toString()) || '',
    };
  }
};

export const fetchUserBalance = async (farmConfig: FarmConfig, account: string) => {
  try {
    if (farmConfig.type === TYPE_OF_FARM.MANUAL) {
      const quoteTokenAddress =
        farmConfig?.quoteToken?.symbol === 'wBNB'
          ? getAddress(tokens.bnb.address)
          : getAddress(farmConfig.quoteToken.address);
      const callToErc20 = [
        { address: getAddress(farmConfig.token.address), name: 'balanceOf', params: [account] },
        {
          address: quoteTokenAddress,
          name: 'balanceOf',
          params: [account],
        },
        { address: getAddress(farmConfig.lpAddresses), name: 'balanceOf', params: [account] },
      ];

      const [tokenBalanceRaw, quoteTokenBalanceRaw, lpBalanceRaw] = await multicall(erc20ABI, callToErc20);

      const tokenBalance = formatBigNumber(new BigNumber(tokenBalanceRaw[0]._hex));
      const quoteTokenBalance = formatBigNumber(new BigNumber(quoteTokenBalanceRaw[0]._hex));
      const lpBalance = formatBigNumber(new BigNumber(lpBalanceRaw[0]._hex));

      return {
        tokenBalance,
        quoteTokenBalance,
        lpBalance,
      };
    } else {
      const vaultTokenAllowance = [
        {
          address: getAddress(farmConfig.token.address),
          name: 'balanceOf',
          params: [account],
        },
        {
          address: getAddress(farmConfig.lpAddresses),
          name: 'balanceOf',
          params: [account],
        },
        {
          address: getAddress(tokens.posiv2.address),
          name: 'balanceOf',
          params: [account],
        },
      ];

      const [balanceOfRaw, balanceOfLPRaw, posiBalanceRaw] = await multicall(erc20ABI, vaultTokenAllowance);

      const tokenBalance = formatBigNumber(new BigNumber(balanceOfRaw[0]._hex));
      const farmLpBalance = formatBigNumber(new BigNumber(balanceOfLPRaw[0]._hex));
      const posiBalance = formatBigNumber(new BigNumber(posiBalanceRaw[0]._hex));

      return {
        tokenBalance,
        quoteTokenBalance: posiBalance,
        lpBalance: farmLpBalance,
      };
    }
  } catch (e: any) {
    throw e;
  }
};
