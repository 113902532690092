const roundToTwoDp = (number: number) => Math.round(number * 100) / 100;

export const calculateCakeEarnedPerThousandDollars = ({ numberOfDays, farmApy, cakePrice }: any) => {
  // Everything here is worked out relative to a year, with the asset compounding daily
  const timesCompounded = 365;
  //   We use decimal values rather than % in the math for both APY and the number of days being calculates as a proportion of the year
  const apyAsDecimal = farmApy / 100;
  const daysAsDecimalOfYear = numberOfDays / timesCompounded;
  //   Calculate the starting CAKE balance with a dollar balance of $1000.
  const principal = 1000 / cakePrice;

  // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
  const finalAmount = principal * (1 + apyAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear);

  // To get the cake earned, deduct the amount after compounding (finalAmount) from the starting CAKE balance (principal)
  const interestEarned = finalAmount - principal;
  return roundToTwoDp(interestEarned);
};

export const calculateCakeEarnedPerThousandDollarsForPool = ({ numberOfDays, farmApy, cakePrice }: any) => {
  // Everything here is worked out relative to a year, with the asset compounding daily
  const timesCompounded = 365;
  //   We use decimal values rather than % in the math for both APY and the number of days being calculates as a proportion of the year
  const apyAsDecimal = farmApy / 100;
  const daysAsDecimalOfYear = numberOfDays / timesCompounded;
  //   Calculate the starting CAKE balance with a dollar balance of $1000.
  const principal = 1000 / cakePrice;

  // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
  const finalAmount = principal * (1 + apyAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear); /// (1 + apyAsDecimal / timesCompounded): total compound on year
  // timesCompounded * daysAsDecimalOfYear: total compound in days

  // To get the cake earned, deduct the amount after compounding (finalAmount) from the starting CAKE balance (principal)
  const interestEarned = finalAmount - principal;
  return roundToTwoDp(interestEarned);
};

export const calculatePosiPerThousandDollarsForPool = ({ numberOfDays, farmApy, cakePrice }: any) => {
  const baseStakeDollars = 1000;
  const apyAsDecimal = farmApy / 100;

  const oneYearReward = baseStakeDollars * apyAsDecimal;

  const interestEarned = (oneYearReward / 365) * numberOfDays;

  return roundToTwoDp(interestEarned / cakePrice);
};

export const apyModalRoi = ({ amountEarned, amountInvested }: any) => {
  const percentage = (amountEarned / amountInvested) * 100; //amountInvested = 1000 /price
  return percentage.toFixed(2);
};
export const calculateCakeEarnedPerThousandDollarsForVault = ({
  numberOfDays,
  farmApy,
  cakePrice,
  harvestInterval,
}: any) => {
  // Everything here is worked out relative to a year, with the asset compounding daily
  const timesCompounded = 365;
  //   We use decimal values rather than % in the math for both APY and the number of days being calculates as a proportion of the year
  const apyAsDecimal = farmApy / 100;
  const daysAsDecimalOfYear = (numberOfDays * (24 / (harvestInterval / 3600))) / timesCompounded;
  //   Calculate the starting CAKE balance with a dollar balance of $1000.
  const principal = 1000 / cakePrice;

  // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
  const finalAmount = principal * (1 + apyAsDecimal / timesCompounded) ** daysAsDecimalOfYear;
  // To get the cake earned, deduct the amount after compounding (finalAmount) from the starting CAKE balance (principal)
  const interestEarned = finalAmount - principal;
  return roundToTwoDp(interestEarned);
};

export const calculateApyForVault = ({ numberOfDays, farmApy, harvestInterval }: any) => {
  // console.log(farmApy);
  // Everything here is worked out relative to a year, with the asset compounding daily
  const totalTimesCompounded = 365 * (24 / (harvestInterval / 3600));
  const timesCompounded = numberOfDays * (24 / (harvestInterval / 3600)); // harvestInterval / 3600 = 7 = 1251

  //   We use decimal values rather than % in the math for both APY and the number of days being calculates as a proportion of the year
  const apyAsDecimal = farmApy / 100;

  const apy = ((1 + apyAsDecimal / totalTimesCompounded) ** timesCompounded - 1) * 100;
  return roundToTwoDp(apy);
};

export const calculatePosiEarnedFrom1000 = ({ price, apy }) => {
  const amountAfterApy = 1000 * (apy / 100);
  return {
    posiEarn: roundToTwoDp(amountAfterApy / price),
    usd: roundToTwoDp(amountAfterApy),
  };
};

export const calculateCakeEarnedPerDollarForVault = ({
  numberOfDays,
  farmApy,
  cakePrice,
  harvestInterval,
  amount,
}: any) => {
  // Everything here is worked out relative to a year, with the asset compounding daily
  const totalTimesCompounded = 365 * (24 / (harvestInterval / 3600));
  const timesCompounded = numberOfDays * (24 / (harvestInterval / 3600)); // harvestInterval / 3600 = 7 = 1251
  //   We use decimal values rather than % in the math for both APY and the number of days being calculates as a proportion of the year
  const apyAsDecimal = farmApy / 100;
  //   Calculate the starting CAKE balance with a dollar balance of $1000.
  const principal = amount / cakePrice;

  // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
  const finalAmount = principal * (1 + apyAsDecimal / totalTimesCompounded) ** timesCompounded;

  // To get the cake earned, deduct the amount after compounding (finalAmount) from the starting CAKE balance (principal)
  const interestEarned = finalAmount - principal;
  return roundToTwoDp(interestEarned * cakePrice);
};

export const calculateRoiPerThousandDollarsFromApr = ({ numberOfDays, apr, assetPrice }: any) => {
  const aprPerNumberOfDays = calculateAprPerNumberOfDays({ numberOfDays, apr });

  const principal = 1000 / assetPrice;

  return ((Number(aprPerNumberOfDays) * principal) / 100).toFixed(2);
};

export const calculateAprPerNumberOfDays = ({ numberOfDays, apr }: any) => {
  return ((apr / 365) * numberOfDays).toFixed(2);
};

export const calculateTotalApy = ({ glxPrice, assetPrice, poolApy, farmApr }: any) => {
  const oneThousandDollarsWorthOfAsset = 1000 / assetPrice;

  const ratioGlxToAsset = glxPrice / assetPrice;

  const totalGlxEarnedPerThousandDollarsConvertToAsset =
    calculateCakeEarnedPerThousandDollars({ numberOfDays: 365, farmApy: farmApr, cakePrice: glxPrice }) *
    ratioGlxToAsset;

  const totalApy = {
    pool: Number(calculateAprPerNumberOfDays({ numberOfDays: 365, apr: poolApy })),
    farm: Number(
      apyModalRoi({
        amountEarned: totalGlxEarnedPerThousandDollarsConvertToAsset,
        amountInvested: oneThousandDollarsWorthOfAsset,
      }),
    ),
  };

  return totalApy;
};

export const calculateTotalApyAndRoi = ({ glxPrice, assetPrice, poolApy, farmApr }: any) => {
  const oneThousandDollarsWorthOfAsset = 1000 / assetPrice;

  const totalAssetEarnedPerThousandDollars = {
    oneDay: {
      asset: calculateRoiPerThousandDollarsFromApr({ numberOfDays: 1, apr: poolApy, assetPrice }),
      glx: calculateCakeEarnedPerThousandDollars({ numberOfDays: 1, farmApy: farmApr, cakePrice: glxPrice }),
    },
    oneWeek: {
      asset: calculateRoiPerThousandDollarsFromApr({ numberOfDays: 7, apr: poolApy, assetPrice }),
      glx: calculateCakeEarnedPerThousandDollars({ numberOfDays: 7, farmApy: farmApr, cakePrice: glxPrice }),
    },
    oneMonth: {
      asset: calculateRoiPerThousandDollarsFromApr({ numberOfDays: 30, apr: poolApy, assetPrice }),
      glx: calculateCakeEarnedPerThousandDollars({ numberOfDays: 30, farmApy: farmApr, cakePrice: glxPrice }),
    },
    oneYear: {
      asset: calculateRoiPerThousandDollarsFromApr({ numberOfDays: 365, apr: poolApy, assetPrice }),
      glx: calculateCakeEarnedPerThousandDollars({ numberOfDays: 365, farmApy: farmApr, cakePrice: glxPrice }),
    },
  };

  const ratioGlxToAsset = glxPrice / assetPrice;

  // Total earned = asset earned + glx earned convert to asset
  const totalEarned = {
    oneDay: {
      asset: Number(totalAssetEarnedPerThousandDollars.oneDay.asset),
      glxConvertToAsset: Number(totalAssetEarnedPerThousandDollars.oneDay.glx) * ratioGlxToAsset,
    },
    oneWeek: {
      asset: Number(totalAssetEarnedPerThousandDollars.oneWeek.asset),
      glxConvertToAsset: Number(totalAssetEarnedPerThousandDollars.oneWeek.glx) * ratioGlxToAsset,
    },
    oneMonth: {
      asset: Number(totalAssetEarnedPerThousandDollars.oneMonth.asset),
      glxConvertToAsset: Number(totalAssetEarnedPerThousandDollars.oneMonth.glx) * ratioGlxToAsset,
    },
    oneYear: {
      asset: Number(totalAssetEarnedPerThousandDollars.oneYear.asset),
      glxConvertToAsset: Number(totalAssetEarnedPerThousandDollars.oneYear.glx) * ratioGlxToAsset,
    },
  };

  // apy = apyPool + apyFarm
  const totalApy = {
    oneDay: {
      pool: Number(calculateAprPerNumberOfDays({ numberOfDays: 1, apr: poolApy })),
      farm: Number(
        apyModalRoi({
          amountEarned: totalEarned.oneDay.glxConvertToAsset,
          amountInvested: oneThousandDollarsWorthOfAsset,
        }),
      ),
    },
    oneWeek: {
      pool: Number(calculateAprPerNumberOfDays({ numberOfDays: 7, apr: poolApy })),
      farm: Number(
        apyModalRoi({
          amountEarned: totalEarned.oneWeek.glxConvertToAsset,
          amountInvested: oneThousandDollarsWorthOfAsset,
        }),
      ),
    },
    oneMonth: {
      pool: Number(calculateAprPerNumberOfDays({ numberOfDays: 30, apr: poolApy })),
      farm: Number(
        apyModalRoi({
          amountEarned: totalEarned.oneMonth.glxConvertToAsset,
          amountInvested: oneThousandDollarsWorthOfAsset,
        }),
      ),
    },
    oneYear: {
      pool: Number(calculateAprPerNumberOfDays({ numberOfDays: 365, apr: poolApy })),
      farm: Number(
        apyModalRoi({
          amountEarned: totalEarned.oneYear.glxConvertToAsset,
          amountInvested: oneThousandDollarsWorthOfAsset,
        }),
      ),
    },
  };

  return {
    totalAssetEarnedPerThousandDollars,
    totalEarned,
    totalApy,
  };
};
