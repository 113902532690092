import { ThemeOverride } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

type GlobalStyles = Pick<ThemeOverride, 'styles'>;

// mode(light, dark)

// edit text color by theme: className start  with prefix txt
// edit background color by theme: className start  with prefix bg

export default {
  styles: {
    global: (props) => ({
      '.txt': {
        color: mode('red.400', 'cyan.400')(props),
      },
    }),
  },
} as GlobalStyles;
