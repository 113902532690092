import { Text, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useCountUp } from 'react-countup';

const PriceComponent = ({ priceOfCoin, value, styles, prefix, suffix, decimals, emptyValue }: any) => {
  const [valueRaw, setValue] = useState(0);

  useEffect(() => {
    setValue(value);
  }, [value]);

  if (!value && !prefix) {
    return <Text {...styles}>{emptyValue ? emptyValue : '--'}</Text>;
  }

  return (
    <PriceIncrease
      decimals={decimals}
      value={Number(valueRaw) * Number(priceOfCoin)}
      styles={styles}
      prefix={prefix}
      suffix={suffix}
    />
  );
};

const PriceIncrease = ({ value, styles, prefix, suffix, decimals }: any) => {
  const [isSmallThan768] = useMediaQuery('(max-width: 768px ) and (min-width: 0px )');
  const { countUp, update } = useCountUp({
    start: 0,
    end: value,
    duration: 1,
    separator: ',',
    decimals: decimals !== undefined && decimals !== '' ? Number(decimals) : isSmallThan768 ? 4 : 6,
  });

  const updateValue = useRef(update);

  useEffect(() => {
    updateValue.current(value);
  }, [value, updateValue]);

  return (
    <Text {...styles}>
      {prefix}
      {countUp}
      {suffix && `${suffix}`}
    </Text>
  );
};

export default PriceComponent;
