import BigNumber from 'bignumber.js';

export const getBalanceNumber = (balance: BigNumber | number, decimals = 18, suffix = 4) => {
  const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals));
  const fixedBalance = displayBalance.toFixed(suffix, BigNumber.ROUND_DOWN);
  return Number(fixedBalance || 0);
};

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const balanceBN = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals));
  return balanceBN.toFixed(null, BigNumber.ROUND_DOWN);
};

export const convertETHToAssetBalance = (amountOfETH: BigNumber, assetPrice: BigNumber) => {
  const assetBalance = getBalanceNumber(amountOfETH) * (1 / getBalanceNumber(assetPrice));
  return new BigNumber(assetBalance);
};

export const formatBalance = (
  balance: string | number | BigNumber,
  decimals = 3,
  defaultNaN: string | undefined = undefined,
): string => {
  const n = Number((BigNumber.isBigNumber(balance) && balance.toNumber()) || balance);
  if (Number.isNaN(n) && defaultNaN) {
    return '-';
  }
  // eslint-disable-next-line prefer-template
  return n.toFixed(decimals);
};
