import { FeeTier, ObItem } from '../../redux/types';
import Decimal from 'decimal.js';
import { currencyEquals, Percent, Trade } from '@pancakeswap/sdk';

// @dev estimate output amount base on quantity and orderbook
// make sure ob is sorted before pass to this function
// if isBuy means quote is input
// else means quote is output
export function estimateQuoteAmountFromOB(
  quantity: number,
  isBuy: boolean,
  ob: ObItem[][],
  limitPrice?: number,
): number {
  const _ob = ob[isBuy ? 1 : 0];
  if (!_ob || _ob.length === 0) return limitPrice ? limitPrice * quantity : 0;
  if (limitPrice) {
    // isBuy & limitPrice < lastAsk
    // !isBuy & limitPrice > lastBid
    if ((isBuy && limitPrice < Number(_ob[0].price)) || (!isBuy && limitPrice > Number(_ob[0].price))) {
      return new Decimal(limitPrice).mul(quantity).toNumber();
    }
  }
  let filledQuantity = new Decimal(0);
  let cumulativeQuote = new Decimal(0);
  for (const orderData of _ob) {
    filledQuantity = filledQuantity.add(orderData.size);
    cumulativeQuote = cumulativeQuote.add(orderData.size * Number(orderData.price));
    // filledQuantity >= quantity
    if (filledQuantity.gte(quantity)) {
      cumulativeQuote = cumulativeQuote.sub(filledQuantity.sub(quantity).mul(orderData.price));
      break;
    }
    if (
      (limitPrice && isBuy && Number(orderData.price) >= limitPrice) ||
      (!isBuy && Number(orderData.price) <= limitPrice)
    ) {
      break;
    }
  }
  // means remaining size
  // remaining size should place a limit order
  if (limitPrice && filledQuantity.lt(quantity)) {
    cumulativeQuote = cumulativeQuote.add((quantity - filledQuantity.toNumber()) * limitPrice);
  }
  return cumulativeQuote.toNumber();
}

interface CalculateFeeArgs {
  base: number | string;
  quote: number;
  isBuy: boolean;
}

// calculate fee, add fee to amount by the feeRatio
// base, isBuy currently not use,
// leaves here for further use
export function calculateFee(
  { quote, base, isBuy }: CalculateFeeArgs,
  feeRatio = 0,
): { quote: number; base: number | string } {
  return {
    base,
    quote: _accumulateFee(quote, feeRatio, isBuy),
  };
}

// TODO pass the condition to calculate fee
export function getUserFee(feeTier: FeeTier): number {
  return (feeTier && feeTier[0]) || 0;
}

function _accumulateFee(amount: number, feeRatio: number, isBuy: boolean): number {
  // avoid error
  if (isNaN(feeRatio)) feeRatio = 0;
  return new Decimal(amount)[isBuy ? 'plus' : 'minus'](new Decimal(amount).mul(feeRatio)).toNumber();
}

export const ZERO_PERCENT = new Percent('0');
export const ONE_HUNDRED_PERCENT = new Percent('1');

export function isTradeBetter(
  tradeA: Trade | undefined | null,
  tradeB: Trade | undefined | null,
  minimumDelta: Percent = ZERO_PERCENT,
): boolean | undefined {
  if (tradeA && !tradeB) return false;
  if (tradeB && !tradeA) return true;
  if (!tradeA || !tradeB) return undefined;

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency)
  ) {
    throw new Error('Trades are not comparable');
  }

  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  }
  return tradeA.executionPrice.raw.multiply(minimumDelta.add(ONE_HUNDRED_PERCENT)).lessThan(tradeB.executionPrice);
}
