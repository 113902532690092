import { Text, TextProps, useMediaQuery } from '@chakra-ui/react';
import React from 'react';

export type TypographyType =
  | 'large-title'
  | 'headline1'
  | 'headline2'
  | 'headline3'
  | 'headline4'
  | 'headline5'
  | 'headline6'
  | 'paragraph1'
  | 'paragraph2'
  | 'paragraph3'
  | 'body1'
  | 'body2'
  | 'body1-r'
  | 'body2-r'
  | 'body3'
  | 'body3-r'
  | 'body4-r'
  | 'button1'
  | 'button2'
  | 'button3'
  | 'caption1-r'
  | 'caption2-r';

export interface ITypography extends TextProps {
  type: TypographyType;

  [key: string]: any;
}

const Typography: React.FC<ITypography> = React.forwardRef(({ children, type, ...rest }, ref) => {
  const [isSmallThan480] = useMediaQuery('(max-width: 480px ) and (min-width: 0px )');
  const [isBetween1024And481] = useMediaQuery('(max-width: 1024px ) and (min-width: 481px )');

  const getValue = (xl: string, md: string, xs: string) => {
    if (isSmallThan480) return xs;
    if (isBetween1024And481) return md;
    return xl;
  };

  const renderStyle = (typeOfStyle: string) => {
    switch (typeOfStyle) {
      case 'large-title':
        return {
          fontWeight: getValue('semibold', 'semibold', 'semibold'),
          fontSize: getValue('64px', '40px', '32px'),
          lineHeight: getValue('96px', '60px', '48px'),
        };
      case 'headline1':
        return {
          fontWeight: getValue('semibold', 'semibold', 'semibold'),
          fontSize: getValue('48px', '40px', '24px'),
          lineHeight: getValue('72px', '60px', '32px'),
        };
      case 'headline2':
        return {
          fontWeight: getValue('semibold', 'semibold', 'semibold'),
          fontSize: getValue('40px', '32px', '24px'),
          lineHeight: getValue('60px', '48px', '32px'),
        };
      case 'headline3':
        return {
          fontWeight: getValue('semibold', 'semibold', 'semibold'),
          fontSize: getValue('32px', '24px', '20px'),
          lineHeight: getValue('48px', '32px', '28px'),
        };
      case 'headline4':
        return {
          fontWeight: getValue('semibold', 'semibold', 'semibold'),
          fontSize: getValue('24px', '20px', '20px'),
          lineHeight: getValue('32px', '32px', '32px'),
        };
      case 'headline5':
        return {
          fontWeight: getValue('semibold', 'semibold', 'semibold'),
          fontSize: getValue('20px', '20px', '18px'),
          lineHeight: getValue('28px', '28px', '28px'),
        };
      case 'headline6':
        return {
          fontWeight: getValue('semibold', 'semibold', 'semibold'),
          fontSize: getValue('18px', '18px', '18px'),
          lineHeight: getValue('28px', '28px', '28px'),
        };
      case 'paragraph1':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('16px', '14px', '14px'),
          lineHeight: getValue('24px', '22px', '22px'),
        };
      case 'paragraph2':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('14px', '14px', '14px'),
          lineHeight: getValue('22px', '22px', '22px'),
        };
      case 'paragraph3':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('12px', '12px', '12px'),
          lineHeight: getValue('16px', '16px', '16px'),
        };
      case 'body1':
        return {
          fontWeight: getValue('medium', 'medium', 'medium'),
          fontSize: getValue('16px', '16px', '14px'),
          lineHeight: getValue('24px', '24px', '24px'),
        };
      case 'body1-r':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('16px', '16px', '16px'),
          lineHeight: getValue('24px', '24px', '24px'),
        };
      case 'body2':
        return {
          fontWeight: getValue('medium', 'medium', 'medium'),
          fontSize: getValue('14px', '14px', '14px'),
          lineHeight: getValue('20px', '20px', '20px'),
        };
      case 'body2-r':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('14px', '12px', '12px'),
          lineHeight: getValue('20px', '20px', '20px'),
        };
      case 'body3':
        return {
          fontWeight: getValue('medium', 'medium', 'medium'),
          fontSize: getValue('12px', '12px', '12px'),
          lineHeight: getValue('20px', '20px', '20px'),
        };
      case 'body3-r':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('12px', '12px', '12px'),
          lineHeight: getValue('20px', '20px', '20px'),
        };
      case 'body4-r':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('14px', '14px', '14px'),
          lineHeight: getValue('20px', '20px', '20px'),
        };
      case 'button1':
        return {
          fontWeight: getValue('medium', 'medium', 'medium'),
          fontSize: getValue('16px', '16px', '16px'),
          lineHeight: getValue('24px', '24px', '24px'),
        };
      case 'button2':
        return {
          fontWeight: getValue('medium', 'medium', 'medium'),
          fontSize: getValue('14px', '14px', '14px'),
          lineHeight: getValue('20px', '20px', '20px'),
        };
      case 'button3':
        return {
          fontWeight: getValue('medium', 'medium', 'medium'),
          fontSize: getValue('14px', '14px', '14px'),
          lineHeight: getValue('20px', '20px', '20px'),
        };
      case 'caption1-r':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('14px', '14px', '14px'),
          lineHeight: getValue('20px', '20px', '20px'),
        };
      case 'caption2-r':
        return {
          fontWeight: getValue('normal', 'normal', 'normal'),
          fontSize: getValue('10px', '10px', '10px'),
          lineHeight: getValue('16px', '16px', '16px'),
        };
      default:
        return {
          fontWeight: 'medium',
          fontSize: '12px',
          lineHeight: '20px',
        };
    }
  };
  return (
    <Text {...renderStyle(type)} {...rest} ref={(ref ?? { current: {} }) as any}>
      {children}
    </Text>
  );
});

export default Typography;
