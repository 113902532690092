import { BscConnector } from '@binance-chain/bsc-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ConnectorNames } from '../components/WalletModal/types';
import { connectorsByName as defaultConnectors } from '../connectors';

export const getConnectorsByName = (networkDetail) => {
  if (!networkDetail) {
    return defaultConnectors;
  }
  const { rpc, chainId, supportedChainIds } = networkDetail;
  const walletconnect = new WalletConnectConnector({
    rpc: { [chainId]: rpc },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: 15000,
  });
  const injected = new InjectedConnector({
    supportedChainIds: supportedChainIds,
  });
  const bscConnector = new BscConnector({ supportedChainIds: supportedChainIds });
  const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
    [ConnectorNames.BSC]: bscConnector,
  };
  return connectorsByName;
};
