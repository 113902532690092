import { Web3Provider } from '@ethersproject/providers';
import snapshot from '@snapshot-labs/snapshot.js';
import { useWeb3React } from '@web3-react/core';

import { gql } from 'graphql-request';
import { sumBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CommunityProposal, SnapshotChoiceType } from '../configs/constants/governance';
import { graphqlClient } from '../configs/graphql';
import { getSpaceListInRedux } from '../redux/hooks';
import { AppState } from '../redux/store';

export const getChainId = (): number => {
  const mainNetChainId = 56;
  const chainId = process.env.REACT_APP_CHAIN_ID;
  return chainId === '56' ? mainNetChainId : 97;
};

const getSnapShotStrategies = (strategiesData) => JSON.stringify(strategiesData);
const getSnapShotPlugins = () => JSON.stringify({});
const getSnapShotMetadata = () => JSON.stringify({});

export const useGovernance = () => {
  const web3 = window.ethereum ? new Web3Provider(window.ethereum) : null;
  const { account } = useWeb3React();
  const spaceData = getSpaceListInRedux();
  const strategiesData = spaceData?.strategies;
  const client = new snapshot.Client712(process.env.REACT_APP_SNAPSHOT_ENDPOINT);
  const network = getChainId();

  const plugins = getSnapShotPlugins();
  const metadata = getSnapShotMetadata();

  const onCreateProposal = async (
    type: SnapshotChoiceType,
    title: string,
    body: string,
    choices: string[],
    start: number,
    end: number,
    snapshot: number,
  ) => {
    try {
      const strategies = getSnapShotStrategies(strategiesData);
      //@ts-ignore
      const receipt = await client.proposal(web3, account, {
        space: process.env.REACT_APP_SNAPSHOT_SPACE_ID,
        type,
        title,
        body,
        choices,
        start,
        end,
        snapshot,
        network: Number(network).toString(),
        strategies,
        plugins,
        metadata,
      });
      return receipt as any;
    } catch (e) {
      throw e;
    }
  };

  const onVote = async (
    proposalId: string,
    choice: number,
    type: SnapshotChoiceType = SnapshotChoiceType.SingleChoice,
  ): Promise<any> => {
    //@ts-ignore
    const receipt = await client.vote(web3, account, {
      space: process.env.REACT_APP_SNAPSHOT_SPACE_ID,
      proposal: proposalId,
      type,
      choice,
      metadata,
    });
    return receipt;
  };

  const getVotingPower = async (blockNumber, strategies) => {
    const voters = [account];
    const scores: any[] = await snapshot.utils.getScores(
      process.env.REACT_APP_SNAPSHOT_SPACE_ID,
      strategies ? strategies : strategiesData,
      network.toString(),
      voters,
      blockNumber,
    );
    const scoresByAccount = Object.entries(scores[0]).reduce(
      (cur, [voter]) => ({
        ...cur,
        [voter]: sumBy(scores, (o) => (isFinite(o[voter]) ? Number(o[voter]) : 0)),
      }),
      {},
    );
    return scoresByAccount[account] ?? -1;
  };

  const getVotingPowers = async (blockNumber, voters, strategies = strategiesData) => {
    const scores = await snapshot.utils.getScores(
      process.env.REACT_APP_SNAPSHOT_SPACE_ID,
      strategies,
      network.toString(),
      voters,
      blockNumber,
    );
    const scoresByAccount = Object.entries(scores[0]).reduce(
      (cur, [voter]) => ({
        ...cur,
        [voter]: sumBy(scores, (o) => (isFinite(o[voter]) ? Number(o[voter]) : 0)),
      }),
      {},
    );
    return scoresByAccount;
  };

  return { onCreateProposal, onVote, getVotingPower, getVotingPowers };
};

export const getListCommunityProposals = async (skip: number, limit: number, state: string, orderDirection: string) => {
  try {
    const response: { proposals: CommunityProposal[] } = await graphqlClient.request(
      gql`
        query Proposals($first: Int!, $skip: Int!, $state: String!, $orderDirection: OrderDirection, $space: String!) {
          proposals(
            first: $first
            skip: $skip
            orderDirection: $orderDirection
            where: { space_in: [$space], state: $state }
          ) {
            id
            title
            body
            choices
            start
            end
            snapshot
            created
            state
            scores_total
            scores
            space {
              id
            }
          }
        }
      `,
      {
        first: limit,
        skip: skip,
        state: state,
        orderDirection: orderDirection,
        space: process.env.REACT_APP_SNAPSHOT_SPACE_ID,
      },
    );
    return response?.proposals;
  } catch (e) {
    return [];
  }
};

export const getCommunityProposalByHash = (hash: string, type: string) => {
  const listProposals = useSelector((state: AppState) => state.governance.communityGovernanceList);

  const list = listProposals[type] || [];

  const exist = list && list.find((proposal) => proposal.id === hash);

  return useMemo(() => exist, [exist]);
};

export const useListCommunityProposalsByStatus = (status: string) => {
  const proposalTotalList = useSelector((state: AppState) => state.governance.communityGovernanceList);
  return useMemo(() => {
    if (proposalTotalList && proposalTotalList[status]) {
      return proposalTotalList[status];
    }
    return [];
  }, [proposalTotalList]);
};

export const useListCommunityProposals = () => {
  const proposalTotalList = useSelector((state: AppState) => state.governance.communityGovernanceList);
  return useMemo(() => proposalTotalList, [proposalTotalList]);
};

export const getConfirmStateInNewCommunity = () => {
  const isConfirm = useSelector((state: AppState) => state.governance.newCommunityGovernanceState?.isConfirm);
  return useMemo(() => isConfirm, [isConfirm]);
};
