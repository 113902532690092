/* eslint-disable no-param-reassign */
import { Toast } from '../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum MessageType {
  EstimatingGas, // Metamask popup show
  ConfirmInWallet, // user confirm metamask
  WaitingServerResponse, // waiting server response
  Done, // done
  Reject, // user cancel metamask
  Failed, // transaction failed
  Close, // close popup
}

export interface ToastsState {
  data: Toast[];
  messageType: MessageType;
  message: string;
}

const initialState: ToastsState = {
  data: [],
  messageType: MessageType.EstimatingGas,
  message: '',
};

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    push: (state: ToastsState, action: PayloadAction<Toast>) => {
      const { payload } = action;
      const toastIndex = state.data.findIndex((toast) => toast.id === action.payload.id);

      if (toastIndex >= 0) {
        state.data.splice(toastIndex, 1);
      }

      state.data.unshift(payload);
    },
    remove: (state: ToastsState, action: PayloadAction<string>) => {
      const toastIndex = state.data.findIndex((toast) => toast.id === action.payload);

      if (toastIndex >= 0) {
        state.data.splice(toastIndex, 1);
      }
    },
    clear: (state: ToastsState) => {
      state.data = [];
    },
    changeMessage: (state: ToastsState, action: PayloadAction<{ type: MessageType; message?: string }>) => {
      state.messageType = action.payload.type;
      state.message = action.payload.message ?? '';
    },
  },
});

export const { clear, remove, push, changeMessage } = toastsSlice.actions;

export const clearMessageType = () => (dispatch: any) => {
  dispatch(changeMessage({ type: MessageType.EstimatingGas }));
};

export default toastsSlice.reducer;
