import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../components/Button';
import { Bond } from '../../../redux/types';
import multicall from '../../../utils/multicall';
import { getBalanceNumber } from '../../../utils/formatBalance';
import { getBep20Contract } from '../../../utils/contractHelpers';
import { callEstGas } from '../../../utils/callHelpers';
import { useResponseModal } from '../../../utils/confirmModal';
import useWeb3 from '../../../hooks/useWeb3';
import { useTransactionAdder } from '../../../redux/transactions/hooks';
import { getBondAbiByType } from '../../../utils/common';
import { Max_256 } from '../../../utils/constant';

interface IButtonAppoveForPurchase {
  detail: Bond;
}

const ButtonAppoveForPurchase: React.FC<IButtonAppoveForPurchase> = ({ detail }) => {
  const { t } = useTranslation(['bonds']);

  const [isAllowance, setIsAllowance] = useState(false);
  const web3 = useWeb3();

  const { faceAsset, bondAddress } = detail ?? {};
  const bondAbi = getBondAbiByType(detail?.type || 'normal');

  const { account } = useWeb3React();

  const getAllowance = async () => {
    try {
      const [[res]] = await multicall(bondAbi, [
        {
          address: faceAsset?.address,
          name: 'allowance',
          params: [account, bondAddress],
        },
      ]);
      setIsAllowance(!!getBalanceNumber(Number(res)));
    } catch (e) {}
  };

  useEffect(() => {
    getAllowance();
  }, [account]);

  const addTransaction = useTransactionAdder();

  const onApprove = useCallback(async () => {
    if (account) {
      try {
        const contract = getBep20Contract(faceAsset?.address, web3);
        const method = contract.methods.approve(bondAddress, Max_256);
        const gas = await callEstGas(method, account);
        method.send({ from: account, gas }, async function (err, hash) {
          if (hash) {
            addTransaction({ hash } as any, {
              trigger: () => {
                getAllowance();
              },
            });
            await useResponseModal({
              title: t('transaction_submitted'),
              txHash: hash,
            });
          }
          if (err) {
            if (err?.message) {
              if (err?.message) {
                await useResponseModal({
                  title: t('approve_failed'),
                  errorMsg: err?.message,
                });
              }
            }
            throw err;
          }
        });
      } catch (e) {
        await useResponseModal({
          title: t('approve_failed'),
          errorMsg: e?.message,
        });
      }
    }
  }, [account, web3, faceAsset, bondAddress]);

  return (
    <ButtonComponent
      hidden={isAllowance}
      onClick={onApprove}
      variant="contained"
      fontSize={['12px', '12px', '14px', '14px', '14px', '16px']}
    >
      {t('approve_for_purchase', { symbol: faceAsset?.name })}
    </ButtonComponent>
  );
};

export default ButtonAppoveForPurchase;
