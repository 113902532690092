const firebaseConfig = {
  apiKey: 'AIzaSyBqCfmg9uQSOIpAYivBuGcpsbxwbZePOsw',
  authDomain: 'technixo-prod.firebaseapp.com',
  projectId: 'technixo-prod',
  storageBucket: 'technixo-prod.appspot.com',
  messagingSenderId: '284746886391',
  appId: '1:284746886391:web:c45a7afd1a8e333da2b7f2',
  measurementId: 'G-8F66TLZPJ2',
};

export default firebaseConfig;
