import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  getAllTask,
  getListLeaderBoard,
  getPlayerPoint,
  getPlayerRanking,
  getProcessOfAllTasks,
  getResultOfTaskList,
  getSpinedReward,
  getUserReward,
} from './service';

export enum TYPE_OF_TASK {
  DAILY_CHECK_IN = 'daily-checkin',
  SOCIAL_MEDIA = 'social-media',
  FUTURES = 'futures',
  LUCKY_POSI = 'lucky-posi',
  DAILY_LOTTERY = 'daily-lottery',
  NFT = 'nft',
  STAKE = 'stake',
  SWAP = 'swap',
  REFERRAL = 'referral',
  SPOT = 'spot',
  RANKING = 'ranking',
}

export enum STATUS_OF_TASK {
  OPEN = 'open',
  DONE = 'done',
  PROGRESSING = 'progressing',
}

export interface TaskItem {
  id: number;
  point: number;
  type: TYPE_OF_TASK;
  start_at: number;
  end_at: number;
  statusPlayer: 'open' | 'done' | 'progressing';
}

export interface ProcessOfType {
  type: TYPE_OF_TASK;
  process_percent: number;
}

export enum ProcessStatus {
  NULL = '',
  WAITING = 'waiting',
  PENDING = 'pending',
  DONE = 'done',
  FAILED = 'failed',
}

export interface RewardUser {
  id: number;
  player_id: number;
  reward_id: number;
  reward_item_id: number;
  reward_level: number;
  is_claimed: boolean;
  name: string;
  process_status: ProcessStatus;
  amount: number;
  txid: string;
}

export interface LeaderBoardItem {
  id: number;
  player_address: string;
  points: number;
  usd_prize: string;
}

interface TreasureHuntState {
  listTask: TaskItem[];
  listProcessingOfType: ProcessOfType[];
  playerIno: PlayerInfo;
  listUserReward: RewardUser[];
  leaderBoard: LeaderBoardItem[];
  ranking: number;
}

interface PlayerInfo {
  player_address: string;
  points: number;
  id: number;
  spined_level: number[];
}

const initialState: TreasureHuntState = {
  listTask: [],
  listProcessingOfType: [],
  playerIno: null,
  leaderBoard: [],
  listUserReward: [],
  ranking: 0,
};

export const treasureHuntSlice = createSlice({
  name: 'treasureHunt',
  initialState,
  reducers: {
    setListTasks: (state, action: PayloadAction<TaskItem[]>) => {
      state.listTask = action.payload;
    },
    removeAllStatusOfTask: (state) => {
      const data = state.listTask;
      const newData = data.map((a) => ({
        ...a,
        statusPlayer: 'open' as 'open' | 'done' | 'progressing',
      }));
      state.listTask = newData;
    },
    addAllStatusOfTask: (state, action) => {
      const data = state.listTask;
      const newData = data.map((a) => {
        const exist = action.payload.find((z) => Number(z?.task_id) === Number(a?.id));
        return {
          ...a,
          statusPlayer: exist ? exist?.task_status : 'open',
        };
      });
      state.listTask = newData;
    },
    setListProcessingOfType: (state, action: PayloadAction<ProcessOfType[]>) => {
      state.listProcessingOfType = action.payload;
    },
    setListUserReward: (state, action: PayloadAction<RewardUser[]>) => {
      state.listUserReward = action.payload;
    },
    setListLeaderBoard: (state, action: PayloadAction<LeaderBoardItem[]>) => {
      state.leaderBoard = action.payload;
    },
    setRanking: (state, action: PayloadAction<number>) => {
      state.ranking = action.payload;
    },
    updatePlayerInfo: (state, action: PayloadAction<any>) => {
      state.playerIno = {
        ...state.playerIno,
        ...action.payload,
      };
    },
  },
});

// Actions
export const {
  setListTasks,
  setListProcessingOfType,
  updatePlayerInfo,
  addAllStatusOfTask,
  removeAllStatusOfTask,
  setListUserReward,
  setListLeaderBoard,
  setRanking,
} = treasureHuntSlice.actions;

/**
 *
 * @description get all task
 * @param queryString string query to get data
 * @returns void
 */
export const fetchAllTask = (queryString?: string) => async (dispatch: any) => {
  try {
    const listTask = await getAllTask(queryString);
    if (listTask) {
      dispatch(setListTasks(listTask));
    }
  } catch (error) {}
};

export const getAllResult = (account: string) => async (dispatch: any, getState: any) => {
  try {
    if (!!account) {
      const { listTask = [] } = getState().treasureHunt;
      if (listTask.length) {
        const listId = listTask.map((task) => task?.id);
        const listResultData = await Promise.all(
          listId.map(async (taskId: number) => {
            return getResultOfTaskList(account.toLowerCase(), taskId);
          }),
        );
        const listValid = [];
        if (listResultData.length) {
          const startOfDay = moment().utc().startOf('day').unix();
          const endOfDay = moment().utc().endOf('day').unix();
          listResultData.forEach((item: any) => {
            if (item.length) {
              item.forEach((e) => {
                const taskTime = moment(e?.task_date).utc().unix();

                if (taskTime >= startOfDay && taskTime <= endOfDay) {
                  listValid.push(e);
                }
              });
            }
          });
        }
        dispatch(addAllStatusOfTask(listValid));
      } else {
        setTimeout(() => {
          dispatch(getAllResult(account));
        }, 100);
      }
    } else {
      dispatch(removeAllStatusOfTask());
    }
  } catch (error) {}
};

/**
 *
 * @description get all processing of task
 * @param account account of user
 * @returns void
 */
export const fetchAllProcessOfTasks = (account?: string) => async (dispatch: any) => {
  try {
    if (account) {
      const listProcessTask = await getProcessOfAllTasks(account.toLowerCase());
      if (listProcessTask) {
        dispatch(setListProcessingOfType(listProcessTask));
      }
      const ranking = await getPlayerRanking(account.toLowerCase());

      dispatch(setRanking(ranking));
    } else {
      dispatch(setListProcessingOfType([]));
      dispatch(setRanking(0));
    }
  } catch (error) {}
};

export const fetchPlayerPoint = (account?: string) => async (dispatch: any) => {
  try {
    if (!!account) {
      const dataPlayer = await getPlayerPoint(account.toLowerCase());
      const dataSpined = await getSpinedReward(account.toLowerCase());
      dispatch(updatePlayerInfo({ ...dataPlayer, spined_level: dataSpined }));
    } else {
      dispatch(
        updatePlayerInfo({
          player_address: '',
          points: 0,
          spined_level: [],
        }),
      );
    }
  } catch (error) {
    dispatch(
      updatePlayerInfo({
        player_address: '',
        points: 0,
        spined_level: [],
      }),
    );
  }
};

export const fetchLeaderBoard = () => async (dispatch: any) => {
  try {
    const list = await getListLeaderBoard();
    dispatch(setListLeaderBoard(list));
  } catch (error) {}
};

export const fetchAllUserReward = (address: string) => async (dispatch: any) => {
  try {
    if (!!address) {
      const rewardData = await getUserReward(address.toLowerCase());

      dispatch(setListUserReward(rewardData));
    } else {
      dispatch(setListUserReward([]));
    }
  } catch (error) {}
};

export default treasureHuntSlice.reducer;
