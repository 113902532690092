import { useCallback } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { NoBscProviderError } from '@binance-chain/bsc-connector';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { connectorLocalStorageKey } from '../components/WalletModal/config';
import { ConnectorNames } from '../components/WalletModal/types';
import { setupNetwork } from '../utils/wallet';
import { useTranslation } from 'react-i18next';
import useToast from '../components/Toast';
import { switchNetworkTransporter } from '../services/SwitchNetworkTransporter';
import { getConnectorsByName } from '../utils/networkUtil';
let networkDetail;
let connectorsByName;
const useAuth = () => {
  const { activate, deactivate } = useWeb3React();
  const { toastError } = useToast();
  const { t } = useTranslation('common');
  (() => {
    const subscribe = switchNetworkTransporter.onMessage().subscribe((detail: any) => {
      networkDetail = detail;
    });
    return subscribe.unsubscribe;
  })();
  connectorsByName = getConnectorsByName(networkDetail);
  const login = useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID];
    if (connector) {
      window.connector = connector;
      activate(connector, async (error: Error) => {
        window.connector = null;
        if (error?.message?.includes('NoWalletError')) {
          window.isNoWalletInApp = true;
        } else if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork(networkDetail);
          if (hasSetup) {
            activate(connector);
          }
        } else {
          window.localStorage.removeItem(connectorLocalStorageKey);
          if (error instanceof UnsupportedChainIdError) {
            toastError(`${t('Unsupported Chain Id', 'Unsupported Chain Id Error. Check your chain Id.')}`);
          } else if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
            toastError(`${t('wrong_provider')}`, `${t('connect_through_wallet_connect')}`);
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector;
              walletConnector.walletConnectProvider = null;
            }
            toastError(`${t('Authorization Error')}`, `${t('Please authorize to access your account')}`);
          } else {
            toastError(error.name, error.message);
          }
        }
      }).then(() => {
        try {
          window.gtag('event', `login`);
          window.ethereum.on('accountsChanged', function () {
            window.gtag('event', `login`);
          });
        } catch (e) {}
      });
    } else {
      toastError(`${t("Can't find connector")}`, `${t('The connector config is wrong')}`);
    }
  }, []);

  const logout = useCallback(async () => {
    await deactivate();
    window.localStorage.removeItem(connectorLocalStorageKey);
    window.localStorage.removeItem('connectorId');
    try {
      // This localStorage key is set by @web3-react/walletconnect-connector
      if (localStorage.getItem('walletconnect') || connectorsByName.walletconnect) {
        connectorsByName.walletconnect.close();
        connectorsByName.walletconnect.walletConnectProvider = null;
      }
      if (window.connector) {
        await window.connector.handleClose(4900, 'Disconnected');
        window.connector = null;
      }
    } catch (e: any) {}
  }, []);

  return { login, logout };
};

export default useAuth;
