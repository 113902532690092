import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ConfirmModal from '../components/ConfirmModal';
import ConfirmNetworkFeeModal from '../components/ConfirmNetworkFee/Modal';
import ResponseModal from '../components/MarketSellAuctionModal/ResponseModal';
import ModalHarvestSuccess from '../components/ModalHarvestSuccess';
import PendingModal from '../components/PendingModal';
import PendingModalForIssueBond from '../components/PendingModal/PendingModalForIssueBond';
import PendingModalWithStateTrans from '../components/PendingModal/PendingModalWithConfirmWallet';
import store from '../redux/store';
import { changeMessage, MessageType } from '../redux/toasts';
import ModalWarningGas from './../components/ModalAlertGas/index';

export default function confirm(config?: any) {
  const promiseConfirm = new Promise((resolve: any) => {
    const handleConfirm = (val: boolean) => {
      const container = document.getElementById('root2');
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
      if (val) {
        resolve(val);
      } else {
        resolve(val);
      }
    };

    const renderDOM = () => {
      setTimeout(() => {
        ReactDOM.render(
          <ModalWarningGas isOpenModal={true} config={config} onDismiss={handleConfirm} />,
          document.getElementById('root2'),
        );
      });
    };

    renderDOM();
  });
  return promiseConfirm;
}

export const confirmModal = (config?: any) => {
  const promiseConfirm = new Promise((resolve: any) => {
    const handleConfirm = (val: boolean) => {
      const container = document.getElementById('root2');
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
      if (val) {
        resolve(val);
      } else {
        resolve(val);
      }
    };

    const renderDOM = () => {
      setTimeout(() => {
        ReactDOM.render(
          <ConfirmModal isOpenModal={true} config={config} onDismiss={handleConfirm} />,
          document.getElementById('root2'),
        );
      });
    };

    renderDOM();
  });
  return promiseConfirm;
};

export const confirmNetWorkFeeModalModal = (config?: any) => {
  const promiseConfirm = new Promise((resolve: any) => {
    const handleConfirm = (val: boolean) => {
      const container = document.getElementById('root2');
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
      if (val) {
        resolve(val);
      } else {
        resolve(val);
      }
    };

    const renderDOM = () => {
      setTimeout(() => {
        ReactDOM.render(
          <ConfirmNetworkFeeModal isOpenModal={true} config={config} onDismiss={handleConfirm} />,
          document.getElementById('root2'),
        );
      });
    };

    renderDOM();
  });
  return promiseConfirm;
};

export const useResponseModal = (config?: {
  title?: string;
  errorMsg?: string;
  txHash?: string;
  additionalMsg?: any;
  isSubmit?: any;
  idHash?: string;
  onDismiss?: any;
  customBtn?: any;
  isPending?: boolean;
}) => {
  const promiseResponse = new Promise((resolve: any) => {
    const handleCloseModalResponse = (val: boolean) => {
      config?.onDismiss && config?.onDismiss();
      const container = document.getElementById('root2');
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
      const container3 = document.getElementById('root3');
      if (container3) {
        ReactDOM.unmountComponentAtNode(container3);
      }
      if (val) {
        resolve(val);
      } else {
        resolve(val);
      }
    };

    const renderDOM = () => {
      if (window.isPosiApp && window.isUsePosiAppModal) return;
      const container = document.getElementById('root2');
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
      setTimeout(() => {
        ReactDOM.render(
          <ResponseModal
            isOpenModal={true}
            onDismiss={handleCloseModalResponse}
            errorMsg={config?.errorMsg}
            titleContent={config?.title}
            txh={config?.txHash}
            additionalMsg={config?.additionalMsg}
            isSubmit={config?.isSubmit}
            idHash={config?.idHash}
            customBtn={config?.customBtn}
            isPending={config?.isPending}
          />,
          document.getElementById('root2'),
        );
      });
    };

    renderDOM();
  });
  return promiseResponse;
};

export const useHarvestSuccessModal = (config?: any, root?: string) => {
  const promiseResponse = new Promise((resolve: any) => {
    const handleCloseModalResponse = (val: boolean) => {
      const container = document.getElementById(root ?? 'root2');
      if (container) {
        ReactDOM.unmountComponentAtNode(container);
      }
      if (val) {
        resolve(val);
      } else {
        resolve(val);
      }
    };

    const renderDOM = () => {
      setTimeout(() => {
        ReactDOM.render(
          <ModalHarvestSuccess isOpenModal={true} onDismiss={handleCloseModalResponse} objectResult={config} />,
          document.getElementById(root ?? 'root2'),
        );
      });
    };

    renderDOM();
  });
  return promiseResponse;
};

export const usePendingModal = (config?: any, root?: string) => {
  const handleCloseModalResponse = () => {
    const container = document.getElementById(root ?? 'root2');
    if (container) {
      ReactDOM.unmountComponentAtNode(container);
    }
  };

  const renderDOM = () => {
    setTimeout(() => {
      if (window.isPosiApp && window.isUsePosiAppModal) return;
      ReactDOM.render(
        <PendingModal
          isOpenModal={true}
          onDismiss={handleCloseModalResponse}
          titleContent={config?.titleContent}
          description={config?.description}
        />,
        document.getElementById(root ?? 'root2'),
      );
    });
  };

  renderDOM();
};

export const usePendingModalForIssueBond = (config?: any, root?: string) => {
  const handleCloseModalResponse = () => {
    const container = document.getElementById(root ?? 'root3');
    if (config?.onDismiss) {
      config.onDismiss();
    }
    if (container) {
      ReactDOM.unmountComponentAtNode(container);
    }
  };
  const renderDOM = () => {
    setTimeout(() => {
      store.dispatch(changeMessage({ type: MessageType.EstimatingGas, message: '' }));
      ReactDOM.render(
        <Provider store={store}>
          <PendingModalForIssueBond isOpenModal={true} onDismiss={handleCloseModalResponse} />
        </Provider>,
        document.getElementById(root ?? 'root3'),
      );
    });
  };

  renderDOM();
};

export const usePendingModalWithStateTrans = (config?: any, root?: string) => {
  const handleCloseModalResponse = () => {
    const container = document.getElementById(root ?? 'root3');
    if (container) {
      ReactDOM.unmountComponentAtNode(container);
    }
  };
  const renderDOM = () => {
    setTimeout(() => {
      store.dispatch(changeMessage({ type: MessageType.EstimatingGas }));
      ReactDOM.render(
        <Provider store={store}>
          <PendingModalWithStateTrans
            isOpenModal={true}
            description={config?.description}
            onDismiss={handleCloseModalResponse}
          />
        </Provider>,
        document.getElementById(root ?? 'root3'),
      );
    });
  };

  renderDOM();
};
