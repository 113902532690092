// import WebSocketInstance from 'ws';
/* eslint-disable */
import { io } from 'socket.io-client';
import { getEtherAddressFromString } from './common';

const listEventsListened: string[] = [];

class SocketClientIO {
  public baseUrl: string;

  public path: string;

  public handlers: any;

  public wsInstance: any;

  public event: any;

  constructor(path: string, event: string, baseUrl?: string) {
    this.baseUrl = baseUrl || process.env.REACT_APP_SOCKET_BOND_EXCHANGE_BASE;
    this.path = path;
    this.event = event;
    if (!window.socket) {
      window.socket = io(this.baseUrl, {
        reconnection: true,
        reconnectionDelayMax: 1000,
        reconnectionDelay: 500,
        transports: ['websocket'],
      });
    }
    if (path) this.createSocket();
    this.handlers = new Map();
  }

  emitAndRetry(event: string, data: any) {
    if (window.socket.connected) {
      window.socket.emit(event, data);
    } else {
      window.socket.connect();
      setTimeout(() => {
        this.emitAndRetry(event, data);
      }, 1000);
    }
  }

  joinRoom(room) {
    this.emitAndRetry('join', room);
  }

  leaveRoom(room) {
    this.emitAndRetry('leave', room);
    if (room !== 'ticker@all' && listEventsListened && listEventsListened.length > 0) {
      const eventName = this.getEventName(room) === 'order-book-update' ? 'depth' : this.getEventName(room);
      window.socket.off(eventName);
      const existIndex = listEventsListened.findIndex((a) => {
        return this.path.includes(this.getEventName(a));
      });
      if (existIndex !== -1) {
        if (window.socket) {
          listEventsListened.splice(existIndex, 1);
        }
      }
    }
  }

  subscribe(event: string, handler: any) {
    window.socket.on(event, handler);
  }

  getEventName(event: string) {
    return event.substring(
      event.indexOf('@') + 1,
      event.lastIndexOf('_') !== -1 ? event.lastIndexOf('_') : event.length,
    );
  }

  createSocket() {
    let isDup = false;
    if (this.path !== 'ticker@all' && listEventsListened && listEventsListened.length > 0) {
      const addressFromPath = getEtherAddressFromString(this.path);
      const existIndex = listEventsListened.findIndex((a) => {
        const addressFromLocal = getEtherAddressFromString(a);
        if (
          addressFromLocal.toLowerCase() === addressFromPath.toLowerCase() &&
          this.path.includes(this.getEventName(a))
        ) {
          isDup = true;
        }
        return this.path.includes(this.getEventName(a));
      });
      if (existIndex !== -1 && !isDup) {
        if (window.socket) {
          this.leaveRoom(listEventsListened[existIndex]);
        }
      }
    }
    if (!isDup) {
      this.emitAndRetry('join', this.path);
      if (this.path !== 'ticker@all' && listEventsListened) {
        listEventsListened.push(this.path);
      }
      window.socket.on(this.event, (msg: any) => {
        try {
          const message = msg;
          if (message.e) {
            if (this.handlers.has(message.e)) {
              this.handlers.get(message.e).forEach((cb: any) => {
                cb(message);
              });
            }
          } else if (message && typeof message === 'object' && message.length > 0) {
            const firstMsg = message[0];
            if (this.handlers.has(firstMsg.e)) {
              this.handlers.get(firstMsg.e).forEach((cb: any) => {
                cb(message);
              });
            }
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      });
    }
  }

  setHandler(method: any, callback: any) {
    if (!this.handlers.has(method)) {
      this.handlers.set(method, []);
    }
    this.handlers.get(method).push(callback);
  }
}

export default SocketClientIO;
