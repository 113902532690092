import { transferNativeToOtherChains } from '../../utils/callHelpers';
import EVMChain from './EVMChain';

class POSIChain extends EVMChain {
  bridgeAsset(
    bridgeAssetContract: any,
    payableAmount: number,
    destBcId,
    recipient,
    amount,
    account,
    callbackFunc,
    // gasPrice,
  ) {
    return transferNativeToOtherChains(
      bridgeAssetContract,
      payableAmount,
      Number(destBcId),
      recipient,
      amount,
      account,
      callbackFunc,
      // gasPrice
    );
  }
}

export default POSIChain;
