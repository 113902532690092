import axios from 'axios';

const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
  timeout: 10000,
});

export const fetchDataFarmingCompetition = async () => {
  try {
    return axiosBase({
      url: '/competition?compType=farming',
      method: 'GET',
    }).then((res) => res.data?.data[0]);
  } catch (err) {
    return undefined;
  }
};

export const fetchDataFarmingRankingList = async () => {
  try {
    return axiosBase({
      url: '/competition/farming/ranking',
      method: 'GET',
    }).then((res) => res.data?.data);
  } catch (err) {
    return [];
  }
};

export const getFarmingCompetitionUserData = async (competitionId: string | number, address: string) => {
  try {
    const scoreResponse = await axiosBase({
      url: `/competition/farming/scores?competition-id=${competitionId}&address=${address}`,
      method: 'GET',
    }).then((res) => res.data?.data);
    return scoreResponse;
  } catch (err) {
    return undefined;
  }
};
