import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import Footer from '../components/Footer';
import NewHeader from '../components/Menu/NewMenu';
import { dragElement } from '../utils/common';

interface BasicLayoutProps {
  isHiddenFooter?: boolean;
}

const BasicLayout: React.FC<BasicLayoutProps> = ({ children, isHiddenFooter }) => {
  const listPath = ['/competition/referral', '/competition/buying', '/posi-birthday'];
  const listPathRegex = new RegExp(`^(${listPath.join('|')})`, 'g');
  const isPaddingTop = !!location.pathname.match(listPathRegex);
  const element = document && document.getElementById('birthday-float-icon-basic-layout');
  if (element) {
    dragElement(element);
  }
  return (
    <>
      <Box bg={'#090a0e'} minH="100vh" w={'100%'}>
        {!window.isPosiApp && <NewHeader />}
        <Flex
          w={'100%'}
          flexDirection={'column'}
          position={'relative'}
          minH="calc(100vh - 64px)"
          h="max-content"
          overflow={'hidden'}
        >
          <Box
            position="absolute"
            top={'-150px'}
            right={['-130px', '-130px', '-154px', '-154px', '-213px', '-200px']}
            h={['300px', '300px', '350px', '350px', '700px', '800px']}
            w={['300px', '300px', '350px', '350px', '700px', '800px']}
            bg="radial-gradient(52.1% 52.1% at 47.9% 35.85%, rgba(11, 197, 234, 0.8) 0%, rgba(11, 140, 234, 0.8) 0.01%, rgba(11, 140, 234, 0) 100%)"
            opacity={0.19}
            zIndex={0}
          />
          <Flex
            top={'79px'}
            position="absolute"
            left={['-150px', '-150px', '-175px', '-175px', '-300px', '-350px']}
            flexDirection="column"
            zIndex={0}
          >
            <Box
              h={['300px', '300px', '350px', '350px', '500px', '990px']}
              w={['300px', '300px', '350px', '350px', '500px', '990px']}
              bg="radial-gradient(50% 50% at 50% 50%, rgba(11, 197, 234, 0.8) 0%, rgba(11, 197, 234, 0) 100%)"
              opacity={0.19}
              zIndex={0}
            />
            <Box
              mt="-70%"
              h={['300px', '300px', '350px', '350px', '500px', '700px']}
              w={['300px', '300px', '350px', '350px', '500px', '700px']}
              bg="linear-gradient(169.38deg, rgba(255, 255, 255, 0) 10.29%, rgba(255, 255, 255, 0.02) 41.9%)"
              opacity={0.8}
              transform={'matrix(0.71, -0.71, -0.71, -0.71, 0, 0)'}
              zIndex={0}
            />
          </Flex>
          <Flex
            top={'79px'}
            position="absolute"
            right={['-150px', '-150px', '-175px', '-175px', '-300px', '-550px']}
            flexDirection="column"
            zIndex={0}
          >
            <Box
              h={['300px', '300px', '350px', '350px', '500px', '900px']}
              w={['300px', '300px', '350px', '350px', '500px', '900px']}
              bg="radial-gradient(52.88% 52.88% at 56.21% 49.94%, rgba(130, 101, 243, 0.7) 0%, rgba(78, 163, 234, 0.450524) 48.51%, rgba(11, 197, 234, 0) 100%)"
              opacity={0.19}
              zIndex={0}
            />
            <Box
              mt="-70%"
              h={['300px', '300px', '350px', '350px', '500px', '700px']}
              w={['300px', '300px', '350px', '350px', '500px', '700px']}
              bg="linear-gradient(177.68deg, rgba(255, 255, 255, 0) -7.79%, rgba(255, 255, 255, 0.02) 60.44%)"
              opacity={0.8}
              transform={'rotate(-135deg)'}
              zIndex={0}
            />
          </Flex>
          <Box
            position="absolute"
            top={'1150px'}
            right={['-130px', '-130px', '-154px', '-154px', '-213px', '-250px']}
            h={['300px', '300px', '350px', '350px', '500px', '700px']}
            w={['300px', '300px', '350px', '350px', '500px', '700px']}
            bg="radial-gradient(47.29% 47.29% at 52.71% 48.71%, rgba(11, 197, 234, 0.8) 0%, rgba(11, 197, 234, 0) 100%)"
            opacity={0.19}
            zIndex={0}
          />

          <Box
            position="absolute"
            top="1214px"
            left={'-250px'}
            h={['300px', '300px', '350px', '350px', '500px', '700px']}
            w={['300px', '300px', '350px', '350px', '500px', '700px']}
            bg="radial-gradient(38.02% 38.02% at 48.07% 46.56%, rgba(26, 196, 134, 0.8) 0%, rgba(26, 196, 134, 0) 100%)"
            opacity={0.18}
            zIndex={0}
          />

          <Flex
            top={'2225px'}
            position="absolute"
            left={['-150px', '-150px', '-175px', '-175px', '-300px', '-400px']}
            flexDirection="column"
            zIndex={0}
          >
            <Box
              h={['300px', '300px', '350px', '350px', '500px', '700px']}
              w={['300px', '300px', '350px', '350px', '500px', '700px']}
              bg="radial-gradient(50.06% 50.06% at 57.49% 49.94%, rgba(159, 122, 234, 0.8) 0%, rgba(159, 122, 234, 0) 100%)"
              opacity={0.18}
              zIndex={0}
            />
            <Box
              mt="-70%"
              h={['300px', '300px', '350px', '350px', '500px', '700px']}
              w={['300px', '300px', '350px', '350px', '500px', '700px']}
              bg="linear-gradient(171.84deg, rgba(255, 255, 255, 0) 0.2%, rgba(255, 255, 255, 0.02) 33.72%)"
              opacity={0.8}
              transform={'matrix(0.71, -0.71, -0.71, -0.71, 0, 0)'}
              zIndex={0}
            />
          </Flex>
          <Flex
            top={'2225px'}
            position="absolute"
            right={['-150px', '-150px', '-175px', '-175px', '-300px', '-550px']}
            flexDirection="column"
            zIndex={0}
          >
            <Box
              h={['300px', '300px', '350px', '350px', '500px', '900px']}
              w={['300px', '300px', '350px', '350px', '500px', '900px']}
              bg="radial-gradient(51.97% 51.97% at 56.59% 51.87%, rgba(26, 196, 134, 0.8) 0%, rgba(26, 196, 134, 0) 100%)"
              opacity={0.18}
              zIndex={0}
            />
            <Box
              mt="-70%"
              h={['300px', '300px', '350px', '350px', '500px', '700px']}
              w={['300px', '300px', '350px', '350px', '500px', '700px']}
              bg="linear-gradient(177.68deg, rgba(255, 255, 255, 0) -7.79%, rgba(255, 255, 255, 0.02) 60.44%)"
              opacity={0.8}
              transform={'rotate(-135deg)'}
              zIndex={0}
            />
          </Flex>
          <Box
            position="absolute"
            top="3600px"
            left={'-250px'}
            h={['300px', '300px', '350px', '350px', '700px', '700px']}
            w={['300px', '300px', '350px', '350px', '700px', '700px']}
            bg="radial-gradient(50% 50% at 50% 50%, rgba(11, 197, 234, 0.8) 0%, rgba(11, 197, 234, 0) 100%)"
            opacity={0.18}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'3600px'}
            right={['-130px', '-130px', '-154px', '-154px', '-213px', '-250px']}
            h={['300px', '300px', '350px', '350px', '700px', '700px']}
            w={['300px', '300px', '350px', '350px', '700px', '700px']}
            bg="linear-gradient(177.68deg, rgba(255, 255, 255, 0) -7.79%, rgba(255, 255, 255, 0.02) 60.44%)"
            opacity={0.19}
            zIndex={0}
          />

          <div style={{ zIndex: '9', paddingTop: !isPaddingTop && '30px' }}>{children}</div>

          {!window.isPosiApp && (
            <Flex
              width={'100%'}
              flexDirection={'column'}
              zIndex="4"
              justifyContent="center"
              hidden={isHiddenFooter}
              display={location.pathname.includes('governance') ? 'none' : 'flex'}
            >
              <Footer />
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default BasicLayout;
