import { Web3Provider } from '@ethersproject/providers';
import { ChainId } from '@pancakeswap/sdk';
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import { NetworkContextName } from '../constants';

let library = null;

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>();
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName);
  const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 56;
  if (!library && context?.active) {
    library = new Web3Provider(context?.library as any, chainId);
    library.pollingInterval = 15000;
  }
  return context.active ? { ...context, library } : { ...contextNetwork, chainId: chainId };
}
