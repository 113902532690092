import { RepeatIcon } from '@chakra-ui/icons';
import {
  ChakraProvider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  keyframes,
  usePrefersReducedMotion,
  Image,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useSelector, Provider, useDispatch } from 'react-redux';
import themeContext from '../../configs/contexts/ThemeContext';
import LoaderJson from '../../constants/runloading.json';
import ErrorJson from '../../constants/error.json';
import store, { AppState } from '../../redux/store';
import Typography from '../Typography';
import { changeMessage, MessageType } from '../../redux/toasts';

interface IPendingModal {
  isOpenModal: boolean;
  onDismiss?: (val?: any) => void;
  titleContent?: any;
  description?: any;
}

const PendingModalForIssueBond: React.FC<IPendingModal> = ({ isOpenModal, onDismiss = () => null }) => {
  const issueBondMessageType = useSelector((state: AppState) => state.toasts?.messageType);
  const issueBondMessage = useSelector((state: AppState) => state.toasts?.message);
  const [isShowErrorMessage, setShowErrorMessage] = useState(false);
  const dispatch = useDispatch();

  const onClose = () => {
    onDismiss();
    dispatch(changeMessage({ type: MessageType.EstimatingGas, message: '' }));
  };

  const spin = keyframes`
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
  `;
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${spin} infinite 2s linear`;

  useEffect(() => {
    if (issueBondMessageType === MessageType.Close) {
      onClose();
      setShowErrorMessage(false);
    }
  }, [issueBondMessageType]);

  useEffect(() => {
    if ([MessageType.Reject, MessageType.Failed].includes(issueBondMessageType))
      setTimeout(() => {
        setShowErrorMessage(true);
      }, 1000);
  }, [issueBondMessageType]);

  return (
    <Provider store={store}>
      <ChakraProvider theme={themeContext}>
        <Modal size={'md'} isCentered blockScrollOnMount={true} isOpen={isOpenModal} onClose={onClose}>
          <ModalOverlay width="100%" height="100%" />
          <ModalContent zIndex="9999" background={'gray.800'} borderRadius="24px" py={0} px={0}>
            <ModalCloseButton align="center" color={'gray.500'} />
            <ModalBody py="0px">
              <Flex flexDirection={'column'} pt={'32px'} justifyContent={'center'}>
                {[MessageType.Failed, MessageType.Reject].includes(issueBondMessageType) ? (
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: ErrorJson,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    width={'100px'}
                    height={'100px'}
                  />
                ) : (
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: LoaderJson,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    width={'100px'}
                    height={'100px'}
                  />
                )}
                <Flex textAlign={'center'} mt="16px" flexDirection={'column'} justifyContent={'center'}>
                  {![MessageType.Failed, MessageType.Reject].includes(issueBondMessageType) ? (
                    <>
                      <Text color={'white'} fontSize={'22px'} fontWeight={600}>
                        The bond is issuing.
                      </Text>
                      <Text color={'white'} fontSize={'22px'} fontWeight={600}>
                        Please wait for a while.
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text color={'white'} fontSize={'22px'} fontWeight={600}>
                        Fail to issue bond.
                      </Text>
                      <Text color={'white'} fontSize={'22px'} fontWeight={600}>
                        Please try again later.
                      </Text>
                    </>
                  )}
                  {!isShowErrorMessage ? (
                    <Flex background="rgba(38, 47, 61, 0.6)" direction="column" pb="8px" mt="16px" borderRadius="12px">
                      <Flex mx="auto" color={'teal.150'} ml="16px" mt="8px" alignItems="center">
                        <RepeatIcon
                          boxSize="24px"
                          display={issueBondMessageType === MessageType.EstimatingGas ? 'block' : 'none'}
                          animation={animation}
                          color="teal.150"
                        />
                        <Image
                          display={
                            ![MessageType.EstimatingGas, MessageType.Reject].includes(issueBondMessageType)
                              ? 'block'
                              : 'none'
                          }
                          w="24px"
                          h="24px"
                          src="/img/check.svg"
                        />
                        <Image
                          display={[MessageType.Reject].includes(issueBondMessageType) ? 'block' : 'none'}
                          w="24px"
                          h="24px"
                          src="/img/exclamation-circle.svg"
                        />
                        <Typography
                          type="body1"
                          as="span"
                          ml="6px"
                          color={
                            issueBondMessageType === MessageType.EstimatingGas
                              ? 'teal.150'
                              : [MessageType.Reject].includes(issueBondMessageType)
                              ? 'red.400'
                              : 'gray.500'
                          }
                        >
                          Submitting tx
                        </Typography>
                      </Flex>

                      <Flex mx="auto" color={'teal.150'} ml="16px" mt="8px" alignItems="center">
                        <Image
                          display={issueBondMessageType === MessageType.EstimatingGas ? 'block' : 'none'}
                          w="24px"
                          h="24px"
                          src="/img/dots-circle-horizontal.svg"
                        />
                        <Image
                          display={
                            ![MessageType.EstimatingGas, MessageType.Reject].includes(issueBondMessageType)
                              ? 'block'
                              : 'none'
                          }
                          w="24px"
                          h="24px"
                          src="/img/check.svg"
                        />
                        <Image
                          display={issueBondMessageType === MessageType.Reject ? 'block' : 'none'}
                          w="24px"
                          h="24px"
                          src="/img/exclamation-circle.svg"
                        />
                        <Typography
                          type="body1"
                          as="span"
                          ml="6px"
                          color={[MessageType.Reject].includes(issueBondMessageType) ? 'red.400' : 'gray.500'}
                        >
                          Confirmed
                        </Typography>
                      </Flex>

                      <Flex mx="auto" color={'teal.150'} ml="16px" mt="8px" alignItems="center">
                        <RepeatIcon
                          boxSize="24px"
                          display={issueBondMessageType === MessageType.ConfirmInWallet ? 'block' : 'none'}
                          animation={animation}
                          color="teal.150"
                        />
                        <Image
                          display={
                            [MessageType.Reject, MessageType.Failed].includes(issueBondMessageType) ? 'block' : 'none'
                          }
                          w="24px"
                          h="24px"
                          src="/img/exclamation-circle.svg"
                        />
                        <Image
                          display={
                            ![
                              MessageType.ConfirmInWallet,
                              MessageType.WaitingServerResponse,
                              MessageType.Reject,
                              MessageType.Failed,
                              MessageType.Done,
                            ].includes(issueBondMessageType)
                              ? 'block'
                              : 'none'
                          }
                          w="24px"
                          h="24px"
                          src="/img/dots-circle-horizontal.svg"
                        />
                        <Image
                          display={
                            [MessageType.WaitingServerResponse, MessageType.Done].includes(issueBondMessageType)
                              ? 'block'
                              : 'none'
                          }
                          w="24px"
                          h="24px"
                          src="/img/check.svg"
                        />
                        <Typography
                          type="body1"
                          as="span"
                          ml="6px"
                          color={
                            issueBondMessageType === MessageType.ConfirmInWallet
                              ? 'teal.150'
                              : [MessageType.Reject, MessageType.Failed].includes(issueBondMessageType)
                              ? 'red.400'
                              : 'gray.500'
                          }
                        >
                          Issuing Bond
                        </Typography>
                      </Flex>

                      <Flex mx="auto" color={'teal.150'} ml="16px" mt="8px" alignItems="center">
                        <RepeatIcon
                          boxSize="24px"
                          display={issueBondMessageType === MessageType.WaitingServerResponse ? 'block' : 'none'}
                          animation={animation}
                          color="teal.150"
                        />
                        <Image
                          display={
                            ![
                              MessageType.WaitingServerResponse,
                              MessageType.Done,
                              MessageType.Reject,
                              MessageType.Failed,
                            ].includes(issueBondMessageType)
                              ? 'block'
                              : 'none'
                          }
                          w="24px"
                          h="24px"
                          src="/img/dots-circle-horizontal.svg"
                        />
                        <Image
                          display={issueBondMessageType === MessageType.Done ? 'block' : 'none'}
                          w="24px"
                          h="24px"
                          src="/img/check.svg"
                        />
                        <Image
                          display={
                            [MessageType.Reject, MessageType.Failed].includes(issueBondMessageType) ? 'block' : 'none'
                          }
                          w="24px"
                          h="24px"
                          src="/img/exclamation-circle.svg"
                        />
                        <Typography
                          type="body1"
                          as="span"
                          ml="6px"
                          color={
                            issueBondMessageType === MessageType.WaitingServerResponse
                              ? 'teal.150'
                              : [MessageType.Reject, MessageType.Failed].includes(issueBondMessageType)
                              ? 'red.400'
                              : 'gray.500'
                          }
                        >
                          Issued Successfully
                        </Typography>
                      </Flex>
                    </Flex>
                  ) : (
                    <Flex
                      background="rgba(38, 47, 61, 0.6)"
                      direction="column"
                      justifyContent={'center'}
                      pb="8px"
                      mt="16px"
                      borderRadius="12px"
                      height="128px"
                    >
                      <Typography type="body1" as="span" ml="16px" color={'red.400'}>
                        {issueBondMessage}
                      </Typography>
                    </Flex>
                  )}
                </Flex>
              </Flex>
              <Flex mb={'24px'} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </Provider>
  );
};

export default PendingModalForIssueBond;
