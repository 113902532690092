import multicall from '../../utils/multicall';
import bondLendingAbi from '../../configs/abi/bondLendingAbi.json';
import posiNFTFactoryABI from '../../configs/abi/posiNFTFactory.json';
import { formatBigNumber } from '../../hooks/useModalInfo';
import BigNumber from 'bignumber.js';
import { getBalanceNumber } from '../../utils/formatBalance';
import { getAddress } from '../../utils/addressHelpers';
import contracts from '../../configs/constants/contracts';
import { formatEtherByBN } from '../../utils/common';

export const fetchUserBondBalances = async (account: string, tokenAddress: string) => {
  const [bondBalance] = await multicall(bondLendingAbi, [
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: [account],
    },
  ]);

  const [listNft] = await multicall(bondLendingAbi, [
    {
      address: tokenAddress,
      name: 'getNfts',
    },
  ]);

  let listNFTAfterSort = [],
    listNFTWithInfoRaw = [],
    listNFTWithInfo = [];

  if (listNft) {
    listNFTAfterSort = listNft[0].map((a) => new BigNumber(a._hex).toString());

    if (listNFTAfterSort.length > 0) {
      const callsGego = listNFTAfterSort.map((x: string) => ({
        address: getAddress(contracts.posiNFTFactory),
        name: 'getGego',
        params: [x],
      }));

      listNFTWithInfoRaw = await multicall(posiNFTFactoryABI, callsGego);
    }

    if (listNFTWithInfoRaw && listNFTWithInfoRaw.length > 0) {
      listNFTWithInfo = listNFTWithInfoRaw.map((z: any, index: number) => {
        const newObject: any = {};
        Object.keys(z).forEach((prop: string) => {
          if (z[prop]?._isBigNumber) {
            if (prop === 'amount') {
              newObject[prop] = formatBigNumber(new BigNumber(z[prop]._hex));
            } else {
              newObject[prop] = new BigNumber(z[prop]._hex).toString();
            }
          }
        });
        return {
          tokenId: listNFTAfterSort[index] || 0,
          ...newObject,
        };
      });
    }
  }

  const newBondsBalance = formatEtherByBN(bondBalance[0]._hex)
  return { newBondsBalance, listNFTWithInfo };
};

export const fetchDataPublicBonds = async (tokenAddress: string) => {
  const [[_bondSupply], [_totalSupply], _collateralAmount, _totalSoldAmountClaimed, [_underlyingAssetStatus]] =
    await multicall(bondLendingAbi, [
      {
        address: tokenAddress,
        name: 'bondSupply',
      },
      {
        address: tokenAddress,
        name: 'totalSupply',
      },
      {
        address: tokenAddress,
        name: 'underlyingAsset',
      },
      {
        address: tokenAddress,
        name: 'totalSoldAmountClaimed',
      },
      {
        address: tokenAddress,
        name: 'getUnderlyingAssetStatus',
      },
    ]);


  const bondSupply = formatEtherByBN(_bondSupply._hex)
  const totalSupply = formatEtherByBN(_totalSupply._hex)
  const collateralAmount = getBalanceNumber(new BigNumber(_collateralAmount[1]._hex));
  const totalSoldAmountClaimed = getBalanceNumber(new BigNumber(_totalSoldAmountClaimed[0]._hex));
  const underlyingAssetStatus = Number(_underlyingAssetStatus);



  return {
    bondSupply,
    totalSupply,
    collateralAmount,
    totalSoldAmountClaimed,
    underlyingAssetStatus,
  };
};
