import { Flex, Image, Spacer, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setDetailShowedModalInfo } from '../../redux/global/reducer';
import { useGetApiPrice, useGetDetailShowedModalInfo } from '../../redux/hooks';
import { DetailRenderModalInfo } from '../../redux/types';
import { formatBalanceWithNumeral, formatBalanceWithNumerals } from '../../utils/common';
import HarvestBalance from './HarvestBalance';
import LockedBalance from './LockedBalance';

interface LockHarvestProp {
  account: any;
  dataState: any;
  showBalance: boolean;
}

const LockedContainer: React.FC<LockHarvestProp> = ({ account, dataState, showBalance }) => {
  const { t } = useTranslation('header');
  const showDetail = useGetDetailShowedModalInfo();
  const isShowDetailBalanceLocked = showDetail === DetailRenderModalInfo.locked_balance;
  const isShowLockedHarvest = showDetail === DetailRenderModalInfo.locked_harvest;
  const slideClassName = isShowDetailBalanceLocked || isShowLockedHarvest ? 'slide-in.show' : 'slide-in';
  const hideTotalBalance = !isShowDetailBalanceLocked && !isShowLockedHarvest ? 'slide-in.show' : 'slide-in';

  const pricePosi = useGetApiPrice('posi');
  const pricePTX = useGetApiPrice('ptx');
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px ) and (min-width: 0px )');

  const renderTotalLocked = (price: any) => {
    // console.log("render", price)
    if (!showBalance) return '✲✲✲✲✲✲';
    if (price) {
      if (isSmallerThan768) {
        return formatBalanceWithNumerals(Number(price), 4);
      }
      return formatBalanceWithNumeral(Number(price), 6);
    }
    if (isSmallerThan768) {
      return formatBalanceWithNumerals(Number(0), 4);
    }
    return formatBalanceWithNumeral(Number(0), 6);
  };
  const dispatch = useDispatch();
  const handleSlideLocked = () => {
    dispatch(setDetailShowedModalInfo(DetailRenderModalInfo.locked_balance));
  };

  const handleSlideHarvest = () => {
    dispatch(setDetailShowedModalInfo(DetailRenderModalInfo.locked_harvest));
  };

  const whiteToGray700 = useColorModeValue('gray.700', 'white');
  const gray700ToGray75 = useColorModeValue('gray.75', 'gray.700');

  return (
    <>
      <Flex
        className={`${hideTotalBalance} from-right`}
        overflowX="hidden"
        display={hideTotalBalance === 'slide-in' ? 'none' : 'flex'}
      >
        <Flex w="100%" direction={'column'} className="slide-in-content" p="0">
          <Flex
            h={['56px', '64px', '64px', '64px', '64px']}
            _hover={{
              bg: gray700ToGray75,
              cursor: 'pointer',
            }}
            onClick={() => {
              handleSlideLocked();
            }}
            px="20px"
            className="parent-modal-balance-row"
            alignItems="center"
          >
            <Flex w="100%">
              <Flex align="center">
                <Image w={['24px', '32px', '32px', '32px', '32px']} src={'/img/locked-balance.svg'} mr="6px" />
                <Text
                  color={whiteToGray700}
                  fontFamily={'Poppins'}
                  fontStyle={'normal'}
                  fontWeight={'500'}
                  fontSize={['12px', '14px', '14px', '14px', '14px']}
                >{`${t('Locked Balance')}`}</Text>
              </Flex>
              <Spacer />
              <Flex align="center">
                <Flex direction={'column'}>
                  <Text
                    color={whiteToGray700}
                    textAlign="right"
                    fontStyle={'normal'}
                    fontWeight={'600'}
                    fontSize={['13px', '16px', '16px', '16px', '16px']}
                  >
                    {showBalance
                      ? renderTotalLocked(Number(dataState?.totalUsdLocked) / Number(pricePosi || 1))
                      : '✲✲✲✲✲✲'}{' '}
                    POSI
                  </Text>
                  <Text
                    textAlign="right"
                    color={'cyan.400'}
                    fontStyle={'normal'}
                    fontSize={['12px', '13px', '13px', '13px', '13px']}
                  >
                    {showBalance ? `~$${renderTotalLocked(dataState?.totalUsdLocked)}` : '✲✲✲✲✲✲'}
                  </Text>
                </Flex>
                <Flex
                  width="20px"
                  height="20px"
                  ml="5px"
                  className={!isSmallerThan768 ? 'arrow-child' : ''}
                  justifyContent="center"
                  borderRadius="50%"
                  alignItems="center"
                >
                  <Image width="16px" height="16px" src="/img/arrow-gray.svg" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            w={'100%'}
            h={['56px', '64px', '64px', '64px', '64px']}
            _hover={{
              bg: gray700ToGray75,
              cursor: 'pointer',
            }}
            onClick={() => {
              handleSlideHarvest();
            }}
            px="20px"
            alignItems="center"
            justifyContent="space-between"
            className="parent-modal-balance-row"
          >
            <Flex w="100%">
              <Flex align="center">
                <Image w={['24px', '32px', '32px', '32px', '32px']} src="/img/posi-harvest.svg" mr="6px" />
                <Text
                  color={whiteToGray700}
                  fontFamily={'Poppins'}
                  fontStyle={'normal'}
                  fontWeight={'500'}
                  fontSize={['12px', '14px', '14px', '14px', '14px']}
                >{`${t('PTX to Harvest')}`}</Text>
              </Flex>
              <Spacer />
              <Flex align="center">
                <Flex direction={'column'}>
                  <Text
                    color={whiteToGray700}
                    textAlign="right"
                    fontFamily={'Poppins'}
                    fontStyle={'normal'}
                    fontWeight={'600'}
                    fontSize={['13px', '16px', '16px', '16px', '16px']}
                  >
                    {showBalance
                      ? renderTotalLocked(Number(dataState?.totalUsdEarning) / Number(pricePTX || 1))
                      : '✲✲✲✲✲✲'}{' '}
                    PTX
                  </Text>
                  <Text
                    textAlign="right"
                    color={'cyan.400'}
                    fontStyle={'normal'}
                    fontSize={['12px', '13px', '13px', '13px', '13px']}
                  >
                    {showBalance ? `~$${renderTotalLocked(dataState?.totalUsdEarning)}` : '✲✲✲✲✲✲'}
                  </Text>
                </Flex>
                <Flex
                  width="20px"
                  height="20px"
                  ml="5px"
                  className={!isSmallerThan768 ? 'arrow-child' : ''}
                  justifyContent="center"
                  borderRadius="50%"
                  alignItems="center"
                >
                  <Image width="16px" height="16px" src="/img/arrow-gray.svg" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex className={`${slideClassName} from-left`} overflowX="hidden">
        <Flex w="100%" className="slide-in-content">
          {isShowDetailBalanceLocked && (
            <LockedBalance
              isSmallerThan768={isSmallerThan768}
              account={account}
              dataState={dataState}
              showBalance={showBalance}
            />
          )}

          {isShowLockedHarvest && (
            <HarvestBalance
              isSmallerThan768={isSmallerThan768}
              account={account}
              dataState={dataState}
              showBalance={showBalance}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default LockedContainer;
