import { ChainTypes } from '../../adapters/types';
import { IBridgeChain, ITokenBridgeSupported } from './type';

export const sourceChainTokenSupported: ITokenBridgeSupported[] = [
  {
    symbol: 'POSI',
    imgUrl: '/images/pools/position-coin.svg',
    address: {
      56: '0x5CA42204cDaa70d5c773946e69dE942b85CA6706',
      97: '0x73941275a06354f795066E87b60112BbC49A3C88',
      1337: '0xd3e487b00dc3d1620f8663766c59f08aacdf185b',
    },
    hasRfi: true,
  },
];

export const sourceChains: IBridgeChain[] = [
  {
    name: ChainTypes.BNB,
    imgUrl: '/img/bnb-chain.svg',
  },
  {
    name: ChainTypes.POSI,
    imgUrl: '/images/pools/position-coin.svg',
  },
];

export const destinationChain: IBridgeChain[] = [
  {
    name: ChainTypes.POSI,
    imgUrl: '/images/pools/position-coin.svg',
  },
  {
    name: ChainTypes.BNB,
    imgUrl: '/img/bnb-chain.svg',
  },
];

export const explorerNamePerChainId = {
  56: 'BscScan',
  97: 'BscScan',
  900000: 'PscScan',
  910000: 'PscScan',
  920000: 'PscScan',
};

export const explorerUrlPerChainId = {
  56: 'https://bscscan.com/',
  97: 'https://testnet.bscscan.com',
  900000: 'https://explorer.posichain.org',
  910000: 'https://explorer-testnet.posichain.org',
  920000: 'https://explorer-devnet.posichain.org',
}