import tokens from '../configs/constants/tokens';
import { POOL_CATEGORY, PoolConfig, PoolType } from './types';

const pools: PoolConfig[] = [
  {
    id: 15,
    sousId: 1,
    pname: 'POSI Pool',
    type: PoolType.EarnPOSI,
    stakingToken: tokens.posiv2,
    earningToken: tokens.prt,
    contractAddress: {
      97: '0xfbB0a9c48eDe2fF3Aad8b0b7c79c8eFa8221121B',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    poolCategory: POOL_CATEGORY.DEFAULT,
    apr: 0,
    isFinished: false,
    harvestLockupPeriod: 12,
    poolImage: '/images/pools/position-coin.svg',
    depositFee: '1% RFI',
    abiName: 'masterchefNew',
    isScheduleHarvest: true,
    isCompoundable: false,
  },
  {
    id: 23,
    sousId: 8,
    pname: 'PTX Pool',
    type: PoolType.EarnPOSI,
    stakingToken: tokens.ptx,
    earningToken: tokens.ptx,
    contractAddress: {
      97: '0xfbB0a9c48eDe2fF3Aad8b0b7c79c8eFa8221121B',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    poolCategory: POOL_CATEGORY.DEFAULT,
    apr: 0,
    isFinished: false,
    harvestLockupPeriod: 12,
    poolImage: tokens.ptx.logoLink,
    depositFee: null,
    abiName: 'masterchefNew',
    isScheduleHarvest: true,
    isCompoundable: true,
  },
  {
    id: 1,
    sousId: 1,
    pname: 'POSI Pool',
    type: PoolType.EarnPOSI,
    stakingToken: tokens.posiv2,
    earningToken: tokens.posiv2,
    contractAddress: {
      97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
      56: '0x0c54b0b7d61de871db47c3ad3f69feb0f2c8db0b',
    },
    poolCategory: POOL_CATEGORY.DEFAULT,
    apr: 0,
    isFinished: true,
    harvestLockupPeriod: 24,
    poolImage: '/images/pools/position-coin.svg',
    depositFee: '1% RFI',
    abiName: 'masterchef',
    needMigration: true,
  },
  {
    id: 14,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'POSI NFT Pool v3',
    stakingToken: tokens.posiNft,
    earningToken: tokens.prt,
    contractAddress: {
      97: '0x7c8f9d96701165Bf3a3C90fe9931bB1a8348e229',
      56: '0xce0a236e240906600fe652bffba67b8d26387445',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 12,
    harvestFee: 5,
    isFinished: false,
    poolImage: '/images/pools/position-coin.svg',
    abiName: 'generalRewardNFTv3',
    isScheduleHarvest: true,
  },
  {
    id: 2,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'POSI NFT Pool v2',
    stakingToken: tokens.posiNft,
    earningToken: tokens.posiv2,
    contractAddress: {
      97: '0x3f70709d459e19df588a069900d1f9285fcd8bbe',
      56: '0x6257229fa379afdbb91732091b5de32cdb759845',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 24,
    harvestFee: 5,
    isFinished: true,
    poolImage: '/images/pools/position-coin.svg',
    abiName: 'generalNFTRewardNew',
    needMigration: true,
  },
  {
    id: 3,
    sousId: 249,
    pname: 'BABY Pool',
    type: PoolType.EarnToken,
    stakingToken: tokens.posiv2,
    earningToken: tokens.baby,
    contractAddress: {
      97: '0xac1538061ad0694e2c0ef68b7fb94a091d448d99',
      56: '0xac1538061ad0694e2c0ef68b7fb94a091d448d99',
    },
    poolCategory: POOL_CATEGORY.DEFAULT,
    apr: 0,
    isFinished: false,
    harvestLockupPeriod: 0,
    poolImage: '/images/pools/logo-baby.svg',
    depositFee: '0%',
    startBlock: 16308614,
    endBlock: 17172614,
    abiName: 'masterchef',
  },
  {
    id: 4,
    sousId: -1,
    pname: 'POSI NFT Pool v1',
    type: PoolType.EarnPOSI,
    stakingToken: tokens.posiNft,
    earningToken: tokens.posiv2,
    contractAddress: {
      97: '0x4820923cAEfcaa4e8Aa0b2c939b9FDc33949ADec',
      56: '0xbE9FF181BFA9dd78191B81B23Fd4ff774a3fB4F1',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    harvestLockupPeriod: 24,
    harvestFee: 1,
    isFinished: true,
    depositFee: '0%',
    poolImage: '/images/pools/position-coin.svg',
  },
  {
    id: 16,
    pname: 'PBond 001',
    sousId: 3,
    type: PoolType.EarnPOSI,
    stakingToken: tokens.bond001,
    earningToken: tokens.prt,
    contractAddress: {
      97: '0xfbB0a9c48eDe2fF3Aad8b0b7c79c8eFa8221121B',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    poolCategory: POOL_CATEGORY.BOND,
    apr: 0,
    isFinished: false,
    harvestLockupPeriod: 24,
    idBE: 15,
    poolImage: '/images/pools/position-coin.svg',
    abiName: 'masterchefNew',
    isScheduleHarvest: true,
  },
  {
    id: 5,
    pname: 'PBond 001',
    sousId: 3,
    type: PoolType.EarnPOSI,
    stakingToken: tokens.bond001,
    earningToken: tokens.posiv2,
    contractAddress: {
      97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
      56: '0x0c54b0b7d61de871db47c3ad3f69feb0f2c8db0b',
    },
    poolCategory: POOL_CATEGORY.BOND,
    apr: 0,
    isFinished: true,
    harvestLockupPeriod: 3,
    idBE: 15,
    poolImage: '/images/pools/position-coin.svg',
    abiName: 'masterchef',
    needMigration: true,
  },
  {
    id: 6,
    pname: 'PBond 002',
    sousId: 4,
    type: PoolType.EarnPOSI,
    stakingToken: tokens.bond002,
    earningToken: tokens.posiv2,
    contractAddress: {
      97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
      56: '0x0c54b0b7d61de871db47c3ad3f69feb0f2c8db0b',
    },
    poolCategory: POOL_CATEGORY.BOND,
    apr: 0,
    isFinished: true,
    harvestLockupPeriod: 3,
    idBE: 17,
    poolImage: '/images/pools/position-coin.svg',
    needMigration: true,
    abiName: 'masterchef',
  },
  {
    id: 17,
    pname: 'PBond 002',
    sousId: 4,
    type: PoolType.EarnPOSI,
    stakingToken: tokens.bond002,
    earningToken: tokens.prt,
    contractAddress: {
      97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    poolCategory: POOL_CATEGORY.BOND,
    apr: 0,
    isFinished: false,
    harvestLockupPeriod: 24,
    idBE: 17,
    poolImage: '/images/pools/position-coin.svg',
    isScheduleHarvest: true,
    abiName: 'masterchefNew',
  },
  {
    id: 7,
    pname: 'PBond 003',
    sousId: 5,
    type: PoolType.EarnPOSI,
    stakingToken: tokens.bond003,
    earningToken: tokens.posiv2,
    contractAddress: {
      97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
      56: '0x0c54b0b7d61de871db47c3ad3f69feb0f2c8db0b',
    },
    poolCategory: POOL_CATEGORY.BOND,
    apr: 0,
    isFinished: true,
    harvestLockupPeriod: 3,
    idBE: 18,
    poolImage: '/images/pools/position-coin.svg',
    needMigration: true,
    abiName: 'masterchef',
  },
  {
    id: 18,
    pname: 'PBond 003',
    sousId: 5,
    type: PoolType.EarnPOSI,
    stakingToken: tokens.bond003,
    earningToken: tokens.prt,
    contractAddress: {
      97: '0xD0A6C46316f789Ba3bdC320ebCC9AFdaE752fd73',
      56: '0xB02de8F384613d0aa2521B188b55c4D006dDB2D1',
    },
    poolCategory: POOL_CATEGORY.BOND,
    apr: 0,
    isFinished: false,
    harvestLockupPeriod: 24,
    idBE: 18,
    poolImage: '/images/pools/position-coin.svg',
    isScheduleHarvest: true,
    abiName: 'masterchefNew',
  },
  {
    id: 19,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'USDT-BUSD',
    stakingToken: tokens.liquidityNft,
    earningToken: tokens.prt,
    pair: {
      base: {
        symbol: 'USDT',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x55d398326f99059ff775485246999027b3197955',
          97: '0xBB88DCC878aE3d28e5D20aBF8057edd2f623Be85',
        },
      },
      quote: {
        symbol: 'BUSD',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
      },
    },
    contractAddress: {
      97: '0xc23faf9fe32030d353e3ecf5a6b0b0ab85e6f7e2',
      56: '0xccc1c1538f8870018239fb1b78a0758b7de80bfa',
    },
    liquidityPoolAddress: {
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    isFinished: false,
    harvestFee: 1,
    poolImage: '/images/pools/usdt-busd.svg',
    poolKey: {
      56: '0x260315a7879e77c7a7b30904fb8bdd5a588fb42d64cceb3329777da7f22ec636',
      97: '0x55d2869f370daaffba57815991e444b42018737d219b5b35c2cf5aebdacf3589',
    },
    isScheduleHarvest: true,
    abiName: 'liquidityNftStakingV2',
  },
  {
    id: 8,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'USDT-BUSD',
    stakingToken: tokens.liquidityNft,
    earningToken: tokens.posiv2,
    pair: {
      base: {
        symbol: 'USDT',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x55d398326f99059ff775485246999027b3197955',
          97: '0xBB88DCC878aE3d28e5D20aBF8057edd2f623Be85',
        },
      },
      quote: {
        symbol: 'BUSD',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
      },
    },
    contractAddress: {
      97: '0x4925bd0b4f23084f2f17e80ff0efdec8dea2a26c',
      56: '0xa595a1bfadc277ea56526b9c1b771138d3b8ae61',
    },
    liquidityPoolAddress: {
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    isFinished: true,
    needMigration: true,
    harvestFee: 1,
    poolImage: '/images/pools/usdt-busd.svg',
    poolKey: {
      56: '0x260315a7879e77c7a7b30904fb8bdd5a588fb42d64cceb3329777da7f22ec636',
      97: '0x55d2869f370daaffba57815991e444b42018737d219b5b35c2cf5aebdacf3589',
    },
    abiName: 'liquidityNftStaking',
  },
  {
    id: 9,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'DAI-BUSD',
    stakingToken: tokens.liquidityNft,
    earningToken: tokens.posiv2,
    pair: {
      base: {
        symbol: 'DAI',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
          97: '0xea8Aa8d9E0D13afcD0020648eE9B4e037D609422',
        },
      },
      quote: {
        symbol: 'BUSD',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
      },
    },
    contractAddress: {
      97: '0x4925bd0b4f23084f2f17e80ff0efdec8dea2a26c',
      56: '0xa595a1bfadc277ea56526b9c1b771138d3b8ae61',
    },
    liquidityPoolAddress: {
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    harvestFee: 1,
    isFinished: true,
    poolImage: '/images/pools/dai-busd.svg',
    poolKey: {
      56: '0xce20591cf15628214df53bec1367987d2551816c6f3ed2031487d14c30752f1a',
      97: '0x02b8c479ff59019b45bf22a932df67204406498f993a8d7f27945544b83b0305',
    },
    needMigration: true,
    abiName: 'liquidityNftStaking',
  },
  {
    id: 20,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'DAI-BUSD',
    stakingToken: tokens.liquidityNft,
    earningToken: tokens.prt,
    pair: {
      base: {
        symbol: 'DAI',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
          97: '0xea8Aa8d9E0D13afcD0020648eE9B4e037D609422',
        },
      },
      quote: {
        symbol: 'BUSD',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
      },
    },
    contractAddress: {
      97: '0x4925bd0b4f23084f2f17e80ff0efdec8dea2a26c',
      56: '0xccc1c1538f8870018239fb1b78a0758b7de80bfa',
    },
    liquidityPoolAddress: {
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    harvestFee: 1,
    isFinished: false,
    poolImage: '/images/pools/dai-busd.svg',
    poolKey: {
      56: '0xce20591cf15628214df53bec1367987d2551816c6f3ed2031487d14c30752f1a',
      97: '0x02b8c479ff59019b45bf22a932df67204406498f993a8d7f27945544b83b0305',
    },
    isScheduleHarvest: true,
    abiName: 'liquidityNftStakingV2',
  },
  {
    id: 10,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'USDC-BUSD',
    stakingToken: tokens.liquidityNft,
    earningToken: tokens.posiv2,
    pair: {
      base: {
        symbol: 'USDC',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
          97: '0x26c0a258E2918176aD83537c5801BBfb0aba71B1',
        },
      },
      quote: {
        symbol: 'BUSD',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xf4a986958d8831B2Ab5Aa06C41c0751ae2C45056',
        },
      },
    },
    contractAddress: {
      97: '0x818A4A336FAdc02eb4e66B35B11fF7e2048685AE',
      56: '0xa595a1bfadc277ea56526b9c1b771138d3b8ae61',
    },
    liquidityPoolAddress: {
      97: '0x9758d79b1e971c326acc83bd435eb080941c6647',
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    harvestFee: 1,
    isFinished: true,
    poolImage: '/images/pools/usdc-busd.svg',
    poolKey: {
      56: '0xbae21f18595bafa583945749d5e6f324e9ad2059939028cd224fd046d3b0c96c',
      97: '0x5bcf39cfcd58d16ed755a0acceb1c5995ee43d9f901836755c33500aa8ffb089',
    },
    abiName: 'liquidityNftStaking',
    needMigration: true,
  },
  {
    id: 21,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'USDC-BUSD',
    stakingToken: tokens.liquidityNft,
    earningToken: tokens.prt,
    pair: {
      base: {
        symbol: 'USDC',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
          97: '0x26c0a258E2918176aD83537c5801BBfb0aba71B1',
        },
      },
      quote: {
        symbol: 'BUSD',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xf4a986958d8831B2Ab5Aa06C41c0751ae2C45056',
        },
      },
    },
    contractAddress: {
      97: '0x818A4A336FAdc02eb4e66B35B11fF7e2048685AE',
      56: '0xccc1c1538f8870018239fb1b78a0758b7de80bfa',
    },
    liquidityPoolAddress: {
      97: '0x9758d79b1e971c326acc83bd435eb080941c6647',
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    harvestFee: 1,
    isFinished: false,
    poolImage: '/images/pools/usdc-busd.svg',
    poolKey: {
      56: '0xbae21f18595bafa583945749d5e6f324e9ad2059939028cd224fd046d3b0c96c',
      97: '0x5bcf39cfcd58d16ed755a0acceb1c5995ee43d9f901836755c33500aa8ffb089',
    },
    isScheduleHarvest: true,
    abiName: 'liquidityNftStakingV2',
  },
  {
    id: 11,
    sousId: 249,
    pname: 'PBond-004',
    type: PoolType.EarnToken,
    poolCategory: POOL_CATEGORY.BOND,
    stakingToken: tokens.bond004,
    earningToken: tokens.busd,
    contractAddress: {
      97: '0xad710f89c301bd91e87af77450734c94e5ea0331',
      56: '0xad710f89c301bd91e87af77450734c94e5ea0331',
    },
    apr: 150,
    isFinished: true,
    harvestLockupPeriod: 0,
    poolImage: '/images/pools/position-coin.svg',
    depositFee: '0%',
    startBlock: 18671038,
    endBlock: 19506238,
    idBE: 54,
    decimalFormat: {
      earning: 8,
    },
  },
  {
    id: 13,
    sousId: 248,
    pname: 'PBond-005',
    type: PoolType.EarnToken,
    poolCategory: POOL_CATEGORY.BOND,
    stakingToken: tokens.bond005,
    earningToken: tokens.busd,
    contractAddress: {
      97: '0x99d48cb2e456bf72646a21eb2564a84937322f3b',
      56: '0x99d48cb2e456bf72646a21eb2564a84937322f3b',
    },
    apr: 150,
    isFinished: true,
    harvestLockupPeriod: 0,
    poolImage: '/images/pools/position-coin.svg',
    depositFee: '0%',
    startBlock: 19088258,
    endBlock: 19913458,
    idBE: 55,
    decimalFormat: {
      earning: 8,
    },
  },
  {
    id: 12,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'USDT-BUSD02',
    stakingToken: tokens.liquidityNft,
    earningToken: tokens.posiv2,
    pair: {
      base: {
        symbol: 'USDT',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x55d398326f99059ff775485246999027b3197955',
          97: '0xBB88DCC878aE3d28e5D20aBF8057edd2f623Be85',
        },
      },
      quote: {
        symbol: 'BUSD',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
      },
    },
    contractAddress: {
      97: '0x4925bd0b4f23084f2f17e80ff0efdec8dea2a26c',
      56: '0x0bcf9b6903cf99647dad5c8c7dbbf7170eae3253',
    },
    liquidityPoolAddress: {
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
      56: '0x7fdb2e6e1e5300d54724b15bb4bb0b01ccba9567',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    isFinished: true,
    harvestFee: 1,
    poolImage: '/images/pools/usdt-busd.svg',
    poolKey: {
      56: '0xa7ebf223e72da7dd732b45a877fa9d42f47d145d747eece22972ddd118a3e143',
      97: '0x55d2869f370daaffba57815991e444b42018737d219b5b35c2cf5aebdacf3589',
    },
    abiName: 'liquidityNftStaking',
    needMigration: true,
  },
  {
    id: 22,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'USDT-BUSD02',
    stakingToken: tokens.liquidityNft,
    earningToken: tokens.prt,
    pair: {
      base: {
        symbol: 'USDT',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x55d398326f99059ff775485246999027b3197955',
          97: '0xBB88DCC878aE3d28e5D20aBF8057edd2f623Be85',
        },
      },
      quote: {
        symbol: 'BUSD',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
      },
    },
    contractAddress: {
      97: '0x4925bd0b4f23084f2f17e80ff0efdec8dea2a26c',
      56: '0xaa60a6f62e39a706594ef60dad5c13e709edd2d6',
    },
    liquidityPoolAddress: {
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
      56: '0x7fdb2e6e1e5300d54724b15bb4bb0b01ccba9567',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    isFinished: false,
    harvestFee: 1,
    poolImage: '/images/pools/usdt-busd.svg',
    poolKey: {
      56: '0xa7ebf223e72da7dd732b45a877fa9d42f47d145d747eece22972ddd118a3e143',
      97: '0x55d2869f370daaffba57815991e444b42018737d219b5b35c2cf5aebdacf3589',
    },
    abiName: 'liquidityNftStakingV2',
    isScheduleHarvest: true,
  },
  {
    id: 30,
    sousId: -1,
    type: PoolType.EarnPOSI,
    pname: 'PTX-POSI',
    stakingToken: tokens.liquidityNftV2,
    earningToken: tokens.prt,
    pair: {
      base: {
        symbol: 'PTX',
        logo_url: tokens.ptx.logoLink,
        contract_address: {
          56: '0x3C7142552250B10252A214ecccA332368D66c350',
          97: '0xF44875B83724e5Bbde8E6264792612Ed73BE67F8',
        },
      },
      quote: {
        symbol: 'POSI',
        logo_url: '/images/pools/position-coin.svg',
        contract_address: {
          56: '0x5CA42204cDaa70d5c773946e69dE942b85CA6706',
          97: '0x33e36E5C0Ed6d2615E678c095A5Be582a1E01844',
        },
      },
    },
    contractAddress: {
      97: '0x0C193d9d76aC79386fB2949fd62F86576cF11F12',
      56: '0x0C193d9d76aC79386fB2949fd62F86576cF11F12',
    },
    liquidityPoolAddress: {
      97: '0xeB148700200fe9213ac0877F94E007D617c8edf4',
      56: '0xeB148700200fe9213ac0877F94E007D617c8edf4',
    },
    poolCategory: POOL_CATEGORY.NFT,
    apr: 0,
    depositFee: '0%',
    harvestLockupPeriod: 0,
    isFinished: false,
    harvestFee: 0,
    poolImage: tokens.ptx.logoLink,
    poolKey: {
      56: '0x0b3d745dd0d894f2447ebad6096a151e408db8c3',
      97: '0xd8f8a7234905624299e3ee8755de8e78e5c530c3',
    },
    abiName: 'liquidityNftStakingDexV2',
    isScheduleHarvest: true,
  },
];

export default pools;
