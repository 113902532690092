export interface IBridgeAsset {
  getChainByType(type: ChainTypes);
}
export enum ChainTypes {
  EVM = 'EVM',
  BNB = 'BNB',
  POSI = 'POSI',
}
export interface IBridgeAssetCall {
  bridgeAsset(
    bridgeAssetContract?: any,
    payableAmount?: number,
    destBcId?: number,
    recipient?: string,
    amount?: string| number,
    account?: string,
    callbackFunc?:any,
    gasPrice?: number
  );
}
