import EVMChain from './models/EVMChain';
import { ChainTypes, IBridgeAsset } from './types';

class BridgeAssetAdapter implements IBridgeAsset {
  chains = new Map<ChainTypes, EVMChain>();

  constructor(chains: EVMChain[]) {
    chains.forEach((item) => {
      this.chains.set(item.getType(), item);
    });
  }

  getChainByType(type: ChainTypes) {
    return this.chains.get(type);
  }

  getNetworkDetail(type: ChainTypes) {
    const chain = this.chains.get(type);
    return {
      chainId: chain.getChainId(),
      rpc: chain.getRpcUrl(),
      listRpc: chain.getListRpc(),
      supportedChainIds: chain.getSupportedChainIds(),
      chainName: chain.getChainName(),
      nativeCurrency: chain.getNativeCurrency(),
      explorerUrl: chain.getExplorerUrl(),
    };
  }

  getBridgeAssetAbi(type: ChainTypes) {
    const chain = this.chains.get(type);
    return chain.getBridgeAssetAbi();
  }

  getBridgeAssetAddress(type: ChainTypes) {
    const chain = this.chains.get(type);
    return chain.getBridgeAssetAddress();
  }

  bridgeAsset(
    type: ChainTypes,
    bridgeAssetContract: any,
    payableAmount: number,
    destBcId,
    recipient,
    amount,
    account,
    callbackFunc,
    gasPrice?: number,
  ) {
    const chain = this.chains.get(type);
    return chain.bridgeAsset(
      bridgeAssetContract,
      payableAmount,
      destBcId,
      recipient,
      amount,
      account,
      callbackFunc,
      gasPrice,
    );
  }

  getChainId(type: ChainTypes) {
    const chain = this.chains.get(type);
    return chain.getChainId();
  }
}

export default BridgeAssetAdapter;
