import axios from 'axios';
import BigNumber from 'bignumber.js';
import { calculateMiningPower } from '../../utils/calculatePower';
import { getMarketplaceProxyContract, getPosiNFTFactoryContract } from '../../utils/contractHelpers';
import { getBalanceNumber } from '../../utils/formatBalance';
import { getWeb3NoAccount } from '../../utils/web3';
import { AutionDetail, MarketDetail } from '../types';

export const getDataMarketplaceHistory = [
  {
    Nft: 'Accountant #478597 ',
    Price: '1000.00',
    Time: '12:30 A.M 5/8/2021',
    Status: 'Bought',
    Operate: 'Hash',
  },
  {
    Nft: 'Accountant #478597 ',
    Price: '1000.00',
    Time: '12:30 A.M 5/8/2021',
    Status: 'On-Sale',
    Operate: 'More Info',
  },
  {
    Nft: 'Accountant #478597 ',
    Price: '1000.00',
    Time: '12:30 A.M 5/8/2021',
    Status: 'Bought',
    Operate: 'More Info',
  },
  {
    Nft: 'Accountant #478597 ',
    Price: '1000.00',
    Time: '12:30 A.M 5/8/2021',
    Status: 'On-Sale',
    Operate: 'More Info',
  },
];

export const getDataAuctionHistory = [
  {
    Nft: 'Accountant #478597 ',
    StartPrice: '700.00',
    LastedPrice: '1000.00',
    Status: 'Dealed',
    Profit: '',
    Operate: 'Claim',
  },
  {
    Nft: 'Accountant #478597 ',
    StartPrice: '700.00',
    LastedPrice: '1000.00',
    Status: 'Failed',
    Profit: '',
    Operate: 'Claim',
  },
  {
    Nft: 'Accountant #478597 ',
    StartPrice: '700.00',
    LastedPrice: '1000.00',
    Status: 'On-Going',
    Profit: '',
    Operate: 'More Info',
  },
  {
    Nft: 'Accountant #478597 ',
    StartPrice: '700.00',
    LastedPrice: '1000.00',
    Status: 'On-Going',
    Profit: '',
    Operate: 'More Info',
  },
];

export const getNftAuctionDetail = async (id: number) => {
  try {
    const web3 = getWeb3NoAccount();
    const marketPlaceProxyContract = getMarketplaceProxyContract(web3);
    const data: AutionDetail = await marketPlaceProxyContract.methods.getAuctionData(id).call();
    return {
      endingAt: Number(data.endingAt) * 1000,
      lastBidder: data.lastBidder,
      lastPrice: getBalanceNumber(new BigNumber(data.lastPrice), 18, 10),
      nft: data.nft,
      raisedAmount: getBalanceNumber(new BigNumber(data.raisedAmount), 18, 1),
      seller: data.seller,
      startedAt: Number(data.startedAt) * 1000,
      status: data.status,
      tokenId: data.tokenId,
    };
  } catch (error) {}
};

export const getAuctionData = async (queryString: string) => {
  // const { toastError } = useToast();
  // const { t } = useTranslation('common');
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_MARKETPLACE_SERVICE_BASE_URL,
      url: `/products?${queryString}`,
    }).then((res) => res.data);
    if (response?.success) {
      return response.data;
    } else {
      throw Error();
    }
  } catch (e: any) {
    throw Error();
  }
};

export const getMarketData = async (queryString: string) => {
  // const { toastError } = useToast();
  // const { t } = useTranslation('common');
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_MARKETPLACE_SERVICE_BASE_URL,
      url: `/markets?${queryString}`,
    }).then((res) => res.data);
    if (response?.success) {
      return response.data;
    } else {
      throw Error();
    }
  } catch (e: any) {
    throw Error();
  }
};

export const getBidData = async (queryString: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_MARKETPLACE_SERVICE_BASE_URL,
      url: `/bidHistory?perPage=20&page=1&${queryString}`,
    }).then((res) => res.data);
    if (response?.success) {
      return response.data;
    } else {
      throw Error();
    }
  } catch (e: any) {
    throw Error();
  }
};

export const getNftMarketDetail = async (id: number) => {
  try {
    const web3 = getWeb3NoAccount();
    const marketPlaceProxyContract = getMarketplaceProxyContract(web3);
    const data: MarketDetail = await marketPlaceProxyContract.methods.markets(id).call();
    return {
      isSold: data.isSold,
      nft: data.nft,
      price: getBalanceNumber(new BigNumber(data.price), 18, 10),
      purchaser: data.purchaser,
      seller: data.seller,
      tokenId: data.tokenId,
    };
  } catch (error) {}
};

export const getDetailNftById = async (id: string) => {
  try {
    // const web3 = new Web3(Web3.givenProvider);
    const web3 = getWeb3NoAccount();
    const posiNFTFactoryContract = getPosiNFTFactoryContract(web3);
    const res = await posiNFTFactoryContract.methods.getGego(id).call();
    const info: any = {};
    info.id = id;
    info.grade = res.grade;
    info.amount = getBalanceNumber(new BigNumber(res.amount), 18, 10);
    info.quality = res.quality;
    info.author = res.author;
    info.createdTime = res.createdTime;
    info.minBurnTime = Number(res.createdTime) * 1000 + Number(res.lockedDays) * 86400 * 1000;
    info.erc20 = res.erc20;
    info.action = 'myNft';
    info.lockedDays = res.lockedDays;
    info.miningPower = calculateMiningPower(
      res.grade,
      res.quality,
      getBalanceNumber(new BigNumber(res.amount), 18, 10),
    );
    return info;
  } catch (error) {}
};

export const fetchMyAuctionHistory = async (queryString: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_MARKETPLACE_SERVICE_BASE_URL,
      url: `/myAuctionHistory?${queryString}`,
    }).then((res) => res.data.data);
    return response;
  } catch (e: any) {
    return [];
  }
};

export const fetchMyMarketHistory = async (queryString: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_MARKETPLACE_SERVICE_BASE_URL,
      url: `/myMarketHistory?${queryString}`,
    }).then((res) => res.data.data);
    return response;
  } catch (e: any) {
    return [];
  }
};
