import { max, min, round } from "lodash";

export const sortListAsk = (a, b) => Number(a.price) - Number(b.price);
export const sortListBid = (a, b) => Number(b.price) - Number(a.price);

export const getListBid = (priceInterval: number, listBid: any) => {
    const precision = Math.log10(priceInterval) * -1;
    listBid.sort((a, b) => Number(a[0]) - Number(b[0]));
    const listBidObject = listBid.reduce((cur, [price, size]) => {
        const roundPrice = round(price, precision).toString();
        if (!cur[roundPrice]) return {
            ...cur,
            [roundPrice]: Number(size)
        }
        return {
            ...cur,
            [roundPrice]: cur[roundPrice] + Number(size)
        }
    }, {})
    const listBidToDisplay = Object.entries(listBidObject).map(([price, size]) => ({ price, size }));
    return listBidToDisplay;
};

export const getListAsk = (priceInterval: number, listAsk: any) => {
    const precision = Math.log10(priceInterval) * -1;
    const listAskObject = listAsk.reduce((cur, [price, size]) => {
        const roundPrice = round(price, precision).toString();
        if (!cur[roundPrice]) {
            return {
                ...cur,
                [roundPrice]: Number(size)
            }
        }
        return {
            ...cur,
            [roundPrice]: cur[roundPrice] + Number(size)
        }
    }, {})
    const listAskToDisplay = Object.entries(listAskObject).map(([price, size]) => ({ price, size }))
    return listAskToDisplay;
};

export const getListWithTotalSize = (collection: any[]): any[] => {
    return collection.reduce((prevArr: any[], { price, size }) => {
        const newArr = [...prevArr];
        const length = newArr.length;
        if (Number(size))
            newArr.push({
                price,
                size,
                totalSize: length ? Number(size) + Number(prevArr[length - 1].totalSize) : Number(size),
            });
        return newArr;
    }, []);
};

export const getOrderBookRowCount = (listBidLength: number, listAskLength: number): number => {
    const maxRows = max([listBidLength, listAskLength]);
    return min([maxRows, 10]);
};