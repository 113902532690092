class ListPair {
  public list: Array<any>;
  public listPairInit: Array<any>;

  constructor() {
    this.list = [];
    this.listPairInit = [];
  }

  initList(list: Array<any>): void {
    if (list.length) {
      const newList = list.map((a) => ({ ...a, address: a.spot_manager_address }));
      this.listPairInit = newList;
      this.list = newList;
    }
  }

  updateList(list) {
    this.list = this.list.concat(list);
  }

  getListUpdate(currentList) {
    const copyList = JSON.parse(JSON.stringify(this.list)).reverse();
    const newListUpdate = [];
    if (currentList.length > 0) {
      const newList = currentList.map((a) => ({ ...a, address: a.spot_manager_address }));
      newList.forEach((item) => {
        const index = copyList.findIndex((a) => a.address === item.address);
        if (index !== -1) {
          newListUpdate.push(copyList[index]);
        }
      });
      return newListUpdate;
    } else {
      return [];
    }
  }
}

export default ListPair;
