import {
  Alert,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import useAuth from '../../../hooks/useAuth';
import useScrollListener from '../../../hooks/useScrollListener';
import { useGetApiPrice } from '../../../redux/hooks';
import Translate from '../../../views/components/Translate';
import { getBasePath } from '../../PageMeta';
import Typography from '../../Typography';
import UserBlock from '../../UserBlock/UserBlock';
import MenuMobile from './MenuMobile';
import MenuPc from './MenuPc';
import logoChristmasDark from '../../../constants/xmas_dark_optimize.json';
import logoChristmasLight from '../../../constants/xmas_light_optimize.json';
import Lottie from 'react-lottie';
import axios from 'axios';

const NewHeader: React.FC = memo(() => {
  const [isSmallThan1024] = useMediaQuery('(max-width: 1024px ) and (min-width: 0px )');
  const [isSmallThan480] = useMediaQuery('(max-width: 480px ) and (min-width: 0px )');
  const gray800ToWhite = useColorModeValue('#fff', 'gray.800');

  const [navClassList, setNavClassList] = useState([]);
  const scroll = useScrollListener();
  // update classList of nav on scroll
  useEffect(() => {
    const _classList = [];

    if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
      _classList.push(isSmallThan480 ? 'hidden-price' : 'nav-bar--hidden');

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);

  const MenuMobileMemo = useMemo(() => {
    return (
      <Flex w="100%">
        <MenuMobile />
      </Flex>
    );
  }, []);

  const ContentOfHeaderMemo = useMemo(() => {
    return <ContentOfHeader />;
  }, []);

  return (
    <>
      <nav id={'nav-menu'} style={{ zIndex: 999 }} className={isSmallThan1024 ? '' : navClassList.join(' ')}>
        {ContentOfHeaderMemo}
      </nav>
      <Flex id={'price-menu'} zIndex={999} bg={gray800ToWhite} w="100%" className={navClassList.join(' ')}>
        <PriceComponents />
      </Flex>
      {MenuMobileMemo}
    </>
  );
});

const ContentOfHeader = memo(
  () => {
    const [isSmallThan1230] = useMediaQuery('(max-width: 1230px ) and (min-width: 0px )');
    const [isSmallThan1024] = useMediaQuery('(max-width: 1024px ) and (min-width: 0px )');
    const [isSmallThan359] = useMediaQuery('(max-width: 359px ) and (min-width: 0px )');
    const [isSmallThan480] = useMediaQuery('(max-width: 480px ) and (min-width: 0px )');
    const [isSmallThan650] = useMediaQuery('(max-width: 1110px ) and (min-width: 650px )');
    const isNotShowLogoAnimationMobile = useMedia('(max-width: 768px ) and (min-width: 0px )');
    const isNotShowLogoAnimationDesktop = useMedia('(max-width: 1230px ) and (min-width: 1110px )');
    const isNotShowLogoAnimation = isNotShowLogoAnimationMobile || isNotShowLogoAnimationDesktop;
    const [isPlayAnimation, playAnimation] = useState(false);
    const POSIPriceBusd = useGetApiPrice('posi');
    const POPriceBusd = useGetApiPrice('ptx');

    const gray800ToWhite = useColorModeValue('#fff', 'gray.800');

    const bgDownloadApp = useColorModeValue('#fff', '#1F2835');
    const colorBorderBottom = useColorModeValue('gray.200', 'rgba(45, 55, 72, 0.5)');
    const whiteToGray600 = useColorModeValue('gray.600', '#fff');
    const bgCustom = useColorModeValue('#fff', '#1F2835');
    const boxShadow = useColorModeValue('0px 4px 24px rgba(0, 0, 0, 0.12)', '0px 2px 36px rgba(0, 0, 0, 0.36)');
    const logoPositionDarkToLight = useColorModeValue('/img/logo_position_light.svg', '/img/logo_position_dark.svg');
    const animationLogo = useColorModeValue(logoChristmasLight, logoChristmasDark);
    const bgDriveDarkToLight = useColorModeValue('gray.200', 'gray.700');
    const { toggleColorMode, colorMode } = useColorMode();
    const history = useHistory();
    const { pathname } = useLocation();
    const pageDarkLight = ['/', '/swap'];
    const isActiveActionDarkLight = !pageDarkLight.includes(getBasePath(pathname));
    const handleHomepage = () => {
      history.push('/');
    };
    const UserBlockMemo = useMemo(() => {
      return <UserModalInfo />;
    }, []);

    // const memoImg = useMemo(
    //   () =>
    //     isNotShowLogoAnimation ? (
    //       <Image onClick={handleHomepage} w="64px" hidden={!isSmallThan1230} src="/img/logomobile.svg" />
    //     ) : null,
    //   [isSmallThan1230, handleHomepage],
    // );

    // const memoImgDarkLight = useMemo(
    //   () =>
    //     !isNotShowLogoAnimation ? (
    //       <Flex onClick={handleHomepage}>
    //         <Lottie
    //           options={{
    //             loop: false,
    //             animationData: animationLogo,
    //             rendererSettings: {},
    //           }}
    //           speed={1}
    //           isStopped={!isPlayAnimation}
    //           height={'64px'}
    //         />
    //       </Flex>
    //     ) : null,
    //   [isNotShowLogoAnimation, isSmallThan650, handleHomepage, colorMode],

    // );

    const memoImg = useMemo(
      () => <Image hidden={!isSmallThan1230} onClick={handleHomepage} src="/img/logo-posi-navbar.svg" />,
      [isSmallThan1230, handleHomepage],
    );

    const memoImgDarkLight = useMemo(
      () => <Image hidden={isSmallThan1230} onClick={handleHomepage} src={logoPositionDarkToLight} />,
      [isSmallThan1230, handleHomepage, logoPositionDarkToLight],
    );

    useEffect(() => {
      setTimeout(() => {
        playAnimation(true);
      }, 1000);
    }, [handleHomepage]);

    const handleNextPage = () => {
      history.push('/trade/PTX/POSI');
    };

    return (
      <Flex bg={gray800ToWhite} w="100%" direction={'column'}>
        <Flex
          // maxWidth="100%"
          h={['64px']}
          px={[!isSmallThan359 ? '20px' : '4px', '20px', '10px', '10px', '20px', '20px']}
          alignItems={'center'}
          w="100%"
          bg={gray800ToWhite}
        >
          <Flex cursor={'pointer'} w="100%" h={['64px']} alignItems={'center'}>
            {memoImgDarkLight}
            {memoImg}
            <Center mx={['32px', '32px', '32px', '10px', '0px', '24px']} display={isSmallThan1024 ? 'none' : 'flex'}>
              <Box w="1px" bg={bgDriveDarkToLight} h="32px" />
            </Center>
            <MenuPc />
            <Spacer h={'50px'} />
            <Flex h="inherit" color="white" align="center">
              {!isSmallThan480 && (
                <>
                  {/* price posi */}
                  <Popover trigger="hover">
                    <PopoverTrigger>
                      <Button
                        cursor="pointer"
                        scale="sm"
                        maxWidth="272px"
                        bg={'transparent'}
                        pl="0px"
                        height={['35px', '35px', '35px', '40px']}
                        fontSize={['13px', '13px', '13px', '13px', '16px', '16px']}
                        borderRadius="36px"
                        _hover={{
                          background: 'none',
                        }}
                        _active={{
                          background: 'none',
                        }}
                        // mr={'8px'}
                      >
                        <Image
                          boxSize={isSmallThan1024 ? '24px' : '16px'}
                          src={isSmallThan1024 ? '/img/posi-bg-dark.svg' : '/img/logo-position.svg'}
                          mr="6px"
                        />
                        <Text as="span" color={whiteToGray600}>
                          ${Number(Number(POSIPriceBusd).toFixed(4))}
                        </Text>
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      boxShadow={boxShadow}
                      w="150px"
                      role="tooltip"
                      mt="18px"
                      bg={bgCustom}
                      border="0px"
                      borderColor="gray.600"
                      top="-15px"
                    >
                      {/* <PopoverArrow bg="gray.600" boxShadow="none" /> */}
                      <Flex
                        color={whiteToGray600}
                        w="100%"
                        flexDirection="column"
                        bg={bgCustom}
                        sx={{ borderRadius: '8px !important' }}
                      >
                        <Flex
                          as="a"
                          h="40px"
                          justifyContent="center"
                          href="https://dex.guru/token/0x5ca42204cdaa70d5c773946e69de942b85ca6706-bsc"
                          target="_blank"
                          w="100%"
                          textAlign="center"
                          alignItems="center"
                          sx={{ borderRadius: '8px !important' }}
                        >
                          Dex.guru
                        </Flex>
                        <Flex
                          as="a"
                          h="40px"
                          justifyContent="center"
                          href="https://www.dextools.io/app/pancakeswap/pair-explorer/0x00222d964a2077301309809ab3bf56485c126a9c"
                          target="_blank"
                          w="100%"
                          textAlign="center"
                          alignItems="center"
                        >
                          Dextools
                        </Flex>
                        <Flex
                          as="a"
                          h="40px"
                          justifyContent="center"
                          alignItems="center"
                          href="https://poocoin.app/tokens/0x5ca42204cdaa70d5c773946e69de942b85ca6706"
                          target="_blank"
                          w="100%"
                          textAlign="center"
                          sx={{ borderRadius: '8px !important' }}
                        >
                          Poocoin
                        </Flex>
                      </Flex>
                    </PopoverContent>
                  </Popover>
                  {/* end price posi */}
                  {/* price of new token */}
                  <Button
                    cursor="pointer"
                    scale="sm"
                    maxWidth="272px"
                    onClick={handleNextPage}
                    pr="24px"
                    bg={'transparent'}
                    pl="24px"
                    height={['35px', '35px', '35px', '40px']}
                    fontSize={['13px', '13px', '13px', '13px', '16px', '16px']}
                    borderRadius="36px"
                    _hover={{
                      background: 'none',
                    }}
                    _active={{
                      background: 'none',
                    }}
                    // mr={'8px'}
                  >
                    <Image
                      boxSize={isSmallThan1024 ? '24px' : '16px'}
                      src={isSmallThan1024 ? '/ptx_logo_circle.svg' : '/ptx_logo.svg'}
                      mr="6px"
                    />
                    <Text as="span" color={whiteToGray600}>
                      ${Number(Number(POPriceBusd).toFixed(4))}
                    </Text>
                  </Button>

                  {/* end price of new token */}
                </>
              )}

              <Flex h="inherit" color="white" align="center">
                <Translate />
              </Flex>
              <Flex ml={!isSmallThan359 && '8px'}>
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <Flex
                      cursor="pointer"
                      py="12px"
                      h="inherit"
                      align="center"
                      className="hover-icon"
                      mr={!isSmallThan359 && '8px'}
                    >
                      <Image
                        cursor="pointer"
                        className={'arrow-child-icon'}
                        src={'/img/scan_download_active.svg'}
                        boxSize={'22px'}
                      />
                      <Image
                        cursor="pointer"
                        className={'arrow-child-icon-hover'}
                        src={'/img/scan_download.svg'}
                        boxSize={'22px'}
                      />
                    </Flex>
                  </PopoverTrigger>
                  <PopoverContent
                    boxShadow={'0px 2px 36px rgba(0, 0, 0, 0.36)'}
                    bg={bgDownloadApp}
                    border="none"
                    borderRadius="12px"
                    mt="16px"
                    p="12px"
                    w={'220px'}
                    textAlign={'center'}
                    fontSize="12px"
                    fontWeight={500}
                    top="-15px"
                  >
                    <Text color={whiteToGray600} pb="12px" borderBottom="1px" borderColor={colorBorderBottom}>
                      Scan to download app iOS & Android
                    </Text>
                    <Flex my="24px" mx="auto" p="10px" borderRadius="8px" bg="white" align="center">
                      {/* <QRCode
                    size={160}
                    includeMargin={false}
                    excavate={true}
                    value="https://docs.position.exchange/products/how-to-install-beta-mobile-app"
                  /> */}
                      {/* <QRCode
                    value={"https://docs.position.exchange/products/how-to-install-beta-mobile-app"}
                    size={160}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                    renderAs={"svg"}
                    imageSettings={{
                      src: "https://static.zpao.com/favicon.png",
                      x: null,
                      y: null,
                      height: 24,
                      width: 24,
                      excavate: true,
                    }}
                  /> */}
                      <Image boxSize={160} src="/img/install_beta_mobile_app.svg" />
                    </Flex>

                    <Flex align={'center'} mx="auto">
                      <Link
                        w="100%"
                        mx="auto"
                        href="https://docs.position.exchange/products/how-to-install-beta-mobile-app"
                      >
                        <Flex align="center">
                          <Text textAlign={'center'} color={'teal.150'}>
                            All download option
                          </Text>
                          <Image mx="6px" boxSize="12px" src="/img/arrow-teal.svg" />
                        </Flex>
                      </Link>
                    </Flex>
                  </PopoverContent>
                </Popover>
              </Flex>
              <Image
                hidden={colorMode === 'light' || isActiveActionDarkLight}
                onClick={toggleColorMode}
                cursor={'pointer'}
                mx={!isSmallThan359 && '8px'}
                src={'/icons/icon_sun.svg'}
              />
              <Image
                hidden={colorMode === 'dark' || isActiveActionDarkLight}
                onClick={toggleColorMode}
                cursor={'pointer'}
                mx="8px"
                src={'/icons/icon_moon.svg'}
              />
              {UserBlockMemo}
            </Flex>
          </Flex>
        </Flex>
        <AlertInfo />
      </Flex>
    );
  },
  () => true,
);

const calculateTimeleft = (timestamp: number): string => {
  const now = new Date().getTime();
  const distance = timestamp * 1000 - now;
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

const calculateYearMonthDayLeft = (timestamp: number): string => {
  // get year month day left from now to timestamp
  const now = new Date().getTime();
  const distance = timestamp * 1000 - now;
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const years = Math.floor(days / 365);
  const months = Math.floor((days % 365) / 30);
  const daysLeft = Math.floor((days % 365) % 30);
  return `${years > 0 ? `${years} year(s)` : ''} ${months > 0 ? `${months} month(s)` : ''} ${
    daysLeft > 0 && months === 0 && years === 0 ? `${daysLeft} day(s)` : ''
  }`;
};

const calculateYTM = (issuePrice: string, faceValue: string): number => {
  const issuePriceNumber = Number(issuePrice);
  const faceValueNumber = Number(faceValue);
  const ytm = (faceValueNumber / issuePriceNumber - 1) * 100;
  return ytm;
};

const getAPRFromYTM = (ytm: number, startTimestamp: number, endTimestamp: number): number => {
  const days = (endTimestamp - startTimestamp) / 86400;
  const apr = (1 + ytm / 100) ** (365 / days) - 1;
  return apr * 100;
};

const AlertInfo = () => {
  const [listOnSale, setListOnSale] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const fetchListOnSale = async () => {
      const baseUrl = process.env.REACT_APP_BONDS_URL;
      const res = await axios.get(`${baseUrl}/v1/api/bonds?status=on_sale&page=1&limit=10&type=lending`);
      setListOnSale(res.data?.data?.items || []);
    };
    fetchListOnSale();
  }, []);
  const alerts = [<AlertDPTPLaunch key="AlertDPTPLaunch" />];
  if (listOnSale.length !== 0) {
    // get random bond from listOnSale
    const bond = listOnSale[Math.floor(Math.random() * listOnSale.length)];
    const isInBond = history.location.pathname.includes(`/bonds/${bond?.id}`);
    if (!isInBond) {
      const bondYTM = calculateYTM(bond?.issue_price, bond?.face_value);
      const navigateToBond = () => {
        history.push(`/bonds/${bond?.id}`);
      };

      alerts.push(
        ...[
          <AlertNotice
            onClick={navigateToBond}
            key={2}
            forcusText={`P2P Bond ${bond?.name} is on sale! `}
            extraText={`YTM up to ${bondYTM.toFixed(
              2,
            )}%. Guaranteed by on-chain collateral & liquidation system. Purchase now!`}
          />,
          // Act now! Get a 30% return annually on your investments with P2P Bond Bond. Invest $1000 and get back $302.85 in just 1 year – guaranteed by our on-chain collateral and liquidation system. Don't miss out - this sale ends in 1d 14h 27m 55s, so invest now for maximum returns!
          <AlertNotice
            onClick={navigateToBond}
            key={3}
            forcusText={`Act now! Get a ${getAPRFromYTM(bondYTM, bond?.active_date, bond?.maturity_date).toFixed(
              2,
            )}% return annually on your investments with P2P Bond Bond. Invest $1000 and get back $${(
              (1000 * bondYTM) /
              100
            ).toFixed(2)} in just ${calculateYearMonthDayLeft(bond?.maturity_date)}.`}
            // forcusText={`P2P Bond ${bond?.name} sale ends in ${calculateTimeleft(bond?.active_date)} !`}
            extraText={` Secured by our on-chain collateral and liquidation system. Don't miss out - this sale ends in ${calculateTimeleft(
              bond?.active_date,
            )}, invest now for maximum returns!`}
          />,
        ],
      );
    }
  } else {
    return <></>;
  }

  // random from alerts
  return alerts[Math.floor(Math.random() * alerts.length)];
};

const AlertDPTPLaunch = () => {
  return (
    <AlertNotice
      forcusText={'Position Exchange’s Decentralized Perpetual Trading Protocol V2 is live now on main net! '}
      extraText={'Get started from 5 BUSD! Up to 125x Leverage!'}
      onClick={() => {
        window.open('https://position.exchange/futures/USD-M/BTC/BUSD', '_self');
      }}
    />
  );
};

const AlertNotice = ({ forcusText, extraText, onClick }) => {
  const dex2EventIntroTextSecondary = useColorModeValue('gray.800', 'gray.100');
  return (
    <Alert
      background={'rgba(26, 196, 134, 0.15)'}
      justifyContent="center"
      width="100%"
      cursor="pointer"
      position="unset"
      onClick={onClick}
    >
      <Image src="https://storage.googleapis.com/webapp-statics/assets/wep_app/rocket-launch.svg" mr="18px" />
      <Typography type="body2" color={dex2EventIntroTextSecondary}>
        <Typography type="body2" color="teal.150" as="span">
          {forcusText}
        </Typography>
        {extraText}
      </Typography>
    </Alert>
  );
};

const UserModalInfo = memo(
  () => {
    const { account } = useWeb3React();
    const { login, logout } = useAuth();
    return <UserBlock login={login} logout={logout} account={account as string} />;
  },
  () => true,
);

const PriceComponents: React.FC = () => {
  const [isSmallThan480] = useMediaQuery('(max-width: 480px ) and (min-width: 0px )');
  const [isSmallThan1024] = useMediaQuery('(max-width: 1024px ) and (min-width: 0px )');

  const POSIPriceBusd = useGetApiPrice('posi');
  const PRTPriceBusd = useGetApiPrice('ptx');

  const history = useHistory();
  const handleNextPage = (url: string) => {
    history.push(url);
  };
  const gray200ToGray700 = useColorModeValue('gray.200', 'gray.700');

  const gray600ToGray100 = useColorModeValue('gray.600', 'gray.100');
  return (
    <Flex
      position={'relative'}
      hidden={!isSmallThan480}
      align={'center'}
      w="100%"
      h="36px"
      borderTop={'1px'}
      borderColor={gray200ToGray700}
    >
      <Flex justify={'center'} w="50%">
        <Flex align={'center'} onClick={() => handleNextPage('/')}>
          <Image boxSize={'20px'} src={isSmallThan1024 ? '/img/posi-bg-dark.svg' : '/img/logo-position.svg'} />
          <Typography
            color={gray600ToGray100}
            ml="8px"
            type="body1"
            fontSize={'14px'}
            fontWeight="600"
            lineHeight={'20px'}
          >
            ${Number(Number(POSIPriceBusd).toFixed(4))}
          </Typography>
        </Flex>
      </Flex>
      <Divider orientation="vertical" color={gray200ToGray700} />
      <Flex justify={'center'} w="50%">
        <Flex align={'center'} onClick={() => handleNextPage('/trade/PTX/POSI')}>
          <Image boxSize={'20px'} src={isSmallThan1024 ? '/ptx_logo_circle.svg' : '/ptx_logo.svg'} />
          <Typography
            ml="8px"
            type="body1"
            fontSize={'14px'}
            fontWeight="600"
            color={gray600ToGray100}
            lineHeight={'20px'}
          >
            ${Number(Number(PRTPriceBusd).toFixed(4))}
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NewHeader;
