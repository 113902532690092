import { Flex, Link, Text } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailNetWorkByChainId } from '../../adapters/services/BridgeAssetService';
import { ChainTypes } from '../../adapters/types';
import useToast from '../../components/Toast';
import { explorerNamePerChainId } from '../../configs/constants/bridgeAsset';
import { getRevertReason, waitForReceipt } from '../../hooks/useVaults';
import { getBscScanLink } from '../../utils';
import { getWeb3ByChain, getWeb3NoAccount } from '../../utils/web3';
import { useBlockNumber } from '../application/hooks';
import { AppDispatch, AppState } from '../store';
import { checkedTransaction, finalizeTransaction } from './actions';

export function shouldCheck(
  lastBlockNumber: number,
  tx: { addedTime: number; receipt?: any; lastCheckedBlockNumber?: number; waiting?: boolean },
): boolean {
  if (tx.receipt) return false;
  if (tx.waiting) return false;
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  }
  if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  }
  // otherwise every block
  return true;
}

const isPendingTransaction = (tx: { receipt?: any }): boolean => {
  if (tx?.receipt) return false;
  return true;
};

export const getListPendingTransactions = () => {
  const { chainId } = useWeb3React();
  const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions);
  const transactions = chainId ? state[chainId] ?? {} : {};

  const listTransactionPending = [];
  if (transactions && Object.keys(transactions).length > 0) {
    Object.keys(transactions).forEach((hash) => {
      if (isPendingTransaction(transactions[hash])) {
        listTransactionPending.push(transactions[hash]);
      }
    });
  }
  return listTransactionPending;
};

export default function Updater(): null {
  const { chainId, library } = useWeb3React();
  const currentNetwork = getDetailNetWorkByChainId(chainId)
  const contractName = currentNetwork?.getType() || ChainTypes.BNB;
  const web3NoAccount = getWeb3ByChain(contractName) || getWeb3NoAccount();
  const { toastErrorTx, toastSuccessTx } = useToast();
  const lastBlockNumber = useBlockNumber();

  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions);

  const transactions = chainId ? state[chainId] ?? {} : {};
  const { t } = useTranslation('common');
  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return;

    Object.keys(transactions)
      .filter((hash) => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach(async (hash) => {
        dispatch(checkedTransaction({ chainId, hash, blockNumber: lastBlockNumber }));
        try {
          waitForReceipt(web3NoAccount, hash, async (receipt) => {
            const link = getBscScanLink(chainId, hash, 'transaction');
            if (receipt) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                }),
              );
              if (window.listTransactionsTrigger && window.listTransactionsTrigger[hash] && receipt.status) {
                const triggerFunction = window.listTransactionsTrigger[hash];
                triggerFunction(receipt);
              }
              const toast = receipt.status ? toastSuccessTx : toastErrorTx;
              let reason = '';
              if (!receipt.status) {
                reason = await getRevertReason(web3NoAccount, hash);
              }
              toast(
                (receipt.status
                  ? transactions[hash]?.approval
                    ? 'Approved'
                    : transactions[hash]?.title
                  : transactions[hash]?.approval
                  ? 'Failed to Approve'
                  : transactions[hash]?.titleFail) ?? t('Transaction receipt'),
                <Flex flexDirection="column">
                  <Text>
                    {receipt.status
                      ? transactions[hash]?.approval
                        ? 'Smartcontract approved'
                        : transactions[hash]?.summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`
                      : reason ?? transactions[hash]?.summary}
                  </Text>
                  {chainId && (
                    <Link isExternal href={link}>
                      {t(`swap:View on ${explorerNamePerChainId[currentNetwork.getChainId()] || 'BscScan'}`)}
                    </Link>
                  )}
                </Flex>,
              );
            } else {
              dispatch(checkedTransaction({ chainId, hash, blockNumber: lastBlockNumber }));
            }
          });
        } catch (err) {}
      });
  }, [chainId, library, transactions, lastBlockNumber, dispatch]);

  return null;
}
