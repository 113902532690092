import tokens from './tokens';
import { ItemTitleTableNftMarketplace } from './type';

export const TitleButtonVote: ItemTitleTableNftMarketplace[] = [
  {
    title: 'Vote For',
  },
  {
    title: 'Vote Against',
  },
];

export const ContentNotData: ItemTitleTableNftMarketplace[] = [
  {
    title: '1',
  },
  {
    title: '2',
  },
  {
    title: '3',
  },
  {
    title: '4',
  },
];

export const TitleOftheStatistics: ItemTitleTableNftMarketplace[] = [
  {
    title: 'Total (Address)',
  },
  {
    title: 'For',
  },
  {
    title: 'Against',
  },
];

export const statusColor = {
  active: 'purple.500',
  pending: 'purple.500',
  succeeded: 'teal.150',
  queued: 'teal.150',
  executed: 'teal.150',
  canceled: 'red.400',
  defeated: 'red.400',
};

export const statusProposal = {
  active: 'Running',
  pending: 'Running',
  succeeded: 'Passed',
  queued: 'Passed',
  executed: 'Passed',
  canceled: 'Failed',
  defeated: 'Failed',
};

export const statusDisplay = {
  active: 'none',
  pending: 'none',
  succeeded: ' ',
  queued: ' ',
  executed: ' ',
  defeated: ' ',
};

export const OPTION_VOTE = {
  VOTE_FOR: 1,
  VOTE_AGAINST: 0,
};

export enum StatusEnum {
  Executed = 'executed',
  Cancelled = 'canceled',
  Failed = 'failed',
  Pending = 'pending',
  Defeated = 'defeated',
}

export const option = ['NFTs', 'NFTsMarketplace'];

export enum SnapshotChoiceType {
  SingleChoice = 'single-choice',
  Approval = 'approval',
  Quadratice = 'quadratic',
  RankedChoice = 'ranked-choice',
  WEIGHTED = 'weighted',
  BASIC = 'basic',
}

export enum SnapshotProposalState {
  Active = 'active',
  Pending = 'pending',
  Closed = 'closed',
  All = 'all',
}

export interface CommunityProposal {
  id: string;
  title: string;
  body: string;
  choices: string[];
  start: number;
  end: number;
  snapshot: string;
  state: Omit<SnapshotProposalState, SnapshotProposalState.All>;
  scores_total: number;
  scores: number[];
  created: number;
  space: {
    id: string;
  };
}

export enum ProposalType {
  Core = 'core',
  Community = 'community',
}

export const strategies = {
  [56]: [
    {
      name: 'erc20-balance-of',
      params: {
        address: tokens?.posiv2?.address[56],
        symbol: 'POSI',
        decimals: 18,
      },
    },
  ]
}