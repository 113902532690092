import BigNumber from 'bignumber.js';
import positionReferralABI from '../../configs/abi/positionReferral.json';
import erc20ABI from '../../configs/abi/erc20.json';
import vaultAbiBUSD from '../../configs/abi/vaultAbiBUSD.json';
import multicall from '../../utils/multicall';
import { getAddress, getPosiReferralAddress } from '../../utils/addressHelpers';
import { VaultConfig } from '../../configs/constants/types';
import { formatBigNumber } from '../../hooks/useModalInfo';
import tokens from '../../configs/constants/tokens';

export const fetchVaultUserData = async (account: string, vaultConfig: VaultConfig) => {
  const callsActionArray = [
    {
      address: getAddress(vaultConfig.vaultAddress),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getAddress(vaultConfig.vaultAddress),
      name: 'earned',
      params: [account],
    },
    {
      address: getAddress(vaultConfig.vaultAddress),
      name: 'lpOf',
      params: [account],
    },
  ];

  const callsPendingEarn = [
    {
      address: getAddress(vaultConfig.vaultAddress),
      name: 'pendingEarned',
      params: [account],
    },
  ];

  const vaultTokenAllowance = [
    {
      address: getAddress(vaultConfig.token.address),
      name: 'allowance',
      params: [account, getAddress(vaultConfig.vaultAddress)],
    },
    {
      address: getAddress(vaultConfig.token.address),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getAddress(vaultConfig.pairAddress),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getAddress(vaultConfig.pairAddress),
      name: 'allowance',
      params: [account, getAddress(vaultConfig.vaultAddress)],
    },
    {
      address: getAddress(tokens[vaultConfig?.tokenSymbol?.toLowerCase()].address),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getAddress(tokens.posiv2.address),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getAddress(tokens.posiv2.address),
      name: 'allowance',
      params: [account, getAddress(vaultConfig.vaultAddress)],
    },
  ];

  const referralCall = [
    {
      address: getPosiReferralAddress(),
      name: 'getReferrer',
      params: [account],
    },
  ];

  const [
    vaultTokenAllowanceRaw,
    balanceOfRaw,
    balanceOfLPRaw,
    allowanceLpRaw,
    busdBalanceRaw,
    posiBalanceRaw,
    posiAllowanceRaw,
  ] = await multicall(erc20ABI, vaultTokenAllowance);

  const [vaultReferralCodeRaw] = await multicall(positionReferralABI, referralCall);
  const [stakedBalanceRaw, earnedRaw, lpStaked] = await multicall(vaultAbiBUSD, callsActionArray);

  let pendingEarnedRaw: any;
  try {
    [pendingEarnedRaw] = await multicall(vaultAbiBUSD, callsPendingEarn);
  } catch (errr) {}

  const stakedBalance = formatBigNumber(new BigNumber(stakedBalanceRaw[0]._hex));
  const earned = formatBigNumber(new BigNumber(earnedRaw[0]._hex));
  const pendingEarned = pendingEarnedRaw ? formatBigNumber(new BigNumber(pendingEarnedRaw[0]._hex)) : 0;
  const allowance = new BigNumber(vaultTokenAllowanceRaw[0]._hex).toJSON();
  const tokenBalance = formatBigNumber(new BigNumber(balanceOfRaw[0]._hex));
  const farmLpBalance = formatBigNumber(new BigNumber(balanceOfLPRaw[0]._hex));
  const allowanceLpFarm = formatBigNumber(new BigNumber(allowanceLpRaw[0]._hex));
  const totalLpStaked = formatBigNumber(new BigNumber(lpStaked[0]._hex));
  const busdBalance = formatBigNumber(new BigNumber(busdBalanceRaw[0]._hex));
  const posiBalance = formatBigNumber(new BigNumber(posiBalanceRaw[0]._hex));
  const allowancePosi = new BigNumber(posiAllowanceRaw[0]._hex).toJSON();

  return {
    stakedBalance,
    earned,
    pendingEarned,
    allowance,
    tokenBalance,
    farmLpBalance,
    allowanceLpFarm,
    totalLpStaked,
    vaultReferralCode: vaultReferralCodeRaw.toString(),
    busdBalance,
    posiBalance,
    allowancePosi,
  };
};
