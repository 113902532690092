import spotHouseV1 from '../configs/abi/spothouse/spotHousev1.json';
import spotHouseV2 from '../configs/abi/spothouse/spotHousev2.json';
import { spotHouseVersion } from '../configs/abiVersion';

export function getSpotHouseAbi(spotHouseAddress: string): any[] {
  const version = spotHouseVersion[spotHouseAddress && spotHouseAddress.toLowerCase()] || 'v2'; // default v2
  return {
    v1: spotHouseV1,
    v2: spotHouseV2,
  }[version];
}
