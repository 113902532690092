import { useEffect, useState } from 'react';

export const useGetChainIdFromWindow = () => {
  const [chainId, setChainId] = useState();
  const defaultChainId = process.env.REACT_APP_CHAIN_ID;
  useEffect(() => {
    const chainIdFromWindow = window && window.ethereum?.networkVersion;
    setChainId(chainIdFromWindow || defaultChainId);
  }, [window, window.ethereum]);
  return Number(chainId);
};
