import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Socket } from 'socket.io-client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import { IChartingLibraryWidget } from './charting_library/charting_library';
import ErrorBoundary from './components/ErrorBoundary';
import ScrollToTop from './components/ScrollToTop';
import './index.css';
import Providers from './Provides';
import ApplicationUpdater from './redux/application/updater';
import ListsUpdater from './redux/lists/updater';
import MulticallUpdater from './redux/multicall/updater';
import { history } from './redux/store';
import TransactionUpdater from './redux/transactions/updater';
import reportWebVitals from './reportWebVitals';
import ListPair from './utils/listPairClass';
import MarketTrade from './utils/marketTrade';
import './indexedDB/index';
import './firebase/index';
import { registerNoticeWithServiceWorker } from './firebase/common';

declare global {
  interface Window {
    connector: any;
    gtag: any;
    listTransactionsTrigger: { [key: string]: any };
    socket: Socket;
    tvWidget: IChartingLibraryWidget | null;
    marketTrade: MarketTrade;
    listPairClass: ListPair;
    nodeValid: string;
    mozIndexedDB: any;
    webkitIndexedDB: any;
    msIndexedDB: any;
    shimIndexedDB: any;
    webkitIDBTransaction: any;
    msIDBTransaction: any;
    webkitIDBKeyRange: any;
    msIDBKeyRange: any;
    blockNumber: string;
    initTrade: boolean;
    flutter_inappwebview: any;
    isPosiApp: boolean;
    isUsePosiAppModal: boolean;
    isNoWalletInApp: boolean;
  }
}
window.connector = window.connector || undefined;
window.socket = undefined;
window.listTransactionsTrigger = {};
window.marketTrade = window.marketTrade || undefined;
window.listPairClass = window.listPairClass || undefined;
window.tvWidget = window.tvWidget || null;
window.initTrade = false;
window.isPosiApp = window.isPosiApp || false;
window.isUsePosiAppModal = window.isUsePosiAppModal || false;
window.isNoWalletInApp =  false;

const renderApp = () => {
  try {
    const env = process.env.REACT_APP_ENV || 'development';
    if (env !== 'development') {
      Sentry.init({
        dsn: 'https://821c1437ab2b4394841c4e1b7ecb5931@o1302890.ingest.sentry.io/6541120',
        integrations: [new BrowserTracing()],
        environment: process.env.REACT_APP_ENV || 'development',

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
    ReactDOM.render(
      <React.StrictMode>
        <HelmetProvider>
          <ErrorBoundary>
            <Providers>
              <ConnectedRouter history={history}>
                <ScrollToTop>
                  <>
                    <ListsUpdater />
                    <ApplicationUpdater />
                    <TransactionUpdater />
                    <MulticallUpdater />
                  </>
                  <App />
                </ScrollToTop>
              </ConnectedRouter>
            </Providers>
          </ErrorBoundary>
        </HelmetProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  } catch (e) {}
};

const retryToRender = () => {
  if (window.nodeValid) {
    renderApp();
  } else {
    setTimeout(() => {
      retryToRender();
    }, 1000);
  }
};

if (window.nodeValid) {
  renderApp();
} else {
  setTimeout(() => {
    retryToRender();
  }, 1000);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// register notice with service worker
registerNoticeWithServiceWorker();
