import axios from 'axios';

export const getDataFarmHistory = async (queryString: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
      url: `/fetchCommissionHistory?${queryString}`,
    }).then((res) => res.data.data);
    return response;
  } catch (e: any) {
    return [];
  }
};

export const getDataBonusHistory = async (queryString: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
      url: `/bonusTransaction?${queryString}`,
    }).then((res) => res.data.data);
    return response;
  } catch (e: any) {
    return [];
  }
};

export const getGeneral = async (account: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
      url: `/link?address=${account}`,
    }).then((res) => res.data);
    return response;
  } catch (e: any) {
    return {};
  }
};

export const getDataPoolHistory = async (queryString: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
      url: `/fetchCommissionHistory?${queryString}`,
    }).then((res) => res.data.data);
    return response;
  } catch (e: any) {
    return [];
  }
};

export const getDataReferralHistory = async (queryString: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
      url: `/getReferralList?${queryString}`,
    }).then((res) => res.data.data);
    return response;
  } catch (e: any) {
    return [];
  }
};

export const getSummarys = async (address: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
      url: `/summaryStat?address=${address}`,
    }).then((res) => res.data.data);
    return response;
  } catch (e: any) {
    return {};
  }
};
