/* eslint-disable no-console */
import axiosBase from 'axios';

export const fetchDataReferral = async () => {
  try {
    return axiosBase({
      baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
      url: '/competition?compType=referral',
      method: 'GET',
    }).then((res) => res.data?.data);
  } catch (err) {
    return undefined;
  }
};

export const fetchDataReferralRankingList = async () => {
  try {
    return axiosBase({
      baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
      url: '/competition/referral/ranking',
      method: 'GET',
    }).then((res) => res.data?.data);
  } catch (err) {
    return undefined;
  }
};

export const fetchDataReferralByUser = async (compId: string, address: string) => {
  try {
    return axiosBase({
      baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
      url: `/competition/referral/user?compId=${compId}&address=${address}`,
      method: 'GET',
    }).then((res) => res.data?.data);
  } catch (err) {
    return undefined;
  }
};

export const getDataReferralLink = async (account: string) => {
  try {
    const response = await axiosBase({
      method: 'GET',
      baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
      url: `/link?address=${account}`,
    }).then((res) => res.data);
    return response;
  } catch (e: any) {
    console.log(`e`, e);
    return {};
  }
};

export const fetchDataWithdrawalHistory = async (account: string) => {
  try {
    const response = await axiosBase({
      method: 'GET',
      baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
      url: `/competition/withdrawHistory?address=${account}`,
    }).then((res) => res.data?.data);
    return response;
  } catch (e: any) {
    console.log(`e`, e);
    return [];
  }
};

export const fetchDataCompetitionBalance = async (account: string) => {
  try {
    const response = await axiosBase({
      method: 'GET',
      baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
      url: `/competition/totalReward?address=${account}`,
    }).then((res) => res.data?.data);
    return response;
  } catch (e: any) {
    console.log(`e`, e);
    return 0;
  }
};
