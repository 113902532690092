import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { includes, isEqual } from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useModal from '../../../components/UseModal/Modal';
import { BondStatus } from '../../../configs/constants/type';
import { useGetCurrentBondDetail } from '../../../hooks/useBondState';
import { setCurrentBondStatus } from '../../../redux/bondDetail';
import { useGetApiPrice } from '../../../redux/hooks';
import { formatBondBalance } from '../../../utils/common';
import ModalClaimingCollateral from '../Modal/ModalClaimingCollateral';
import ButtonAppoveForPurchase from './ButtonAppove';
import CommitPhase from './CommitPhase';
import DistributionPhase from './DistributionPhase';
import PurchaseBondInput from './PurchaseBondInput';

let interval;
export const calculateTime = (timeToCountDown) => {
  // @ts-ignore
  const time = timeToCountDown * 1000 - Date.parse(new Date());
  const seconds = Math.floor((time / 1000) % 60);
  const minutes = Math.floor((time / 1000 / 60) % 60);
  const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  if (isNaN(time)) return { days: 0, hours: 0, minutes: 0, seconds: 0, time };
  return { days, hours, minutes, seconds, time };
};
const TimeCountDown = ({ timeToCountDown, status, type }) => {
  const { t } = useTranslation('bonds');
  const [timeObj, setTime] = useState(calculateTime(timeToCountDown));
  const dispatch = useDispatch();

  const getTimeUntil = () => {
    setTime(calculateTime(timeToCountDown));
  };
  const formatTime = (n: number) => (n > 9 ? n : `0${n}`);
  useEffect(() => {
    if (timeToCountDown && timeObj?.time <= 0) {
      const _status = ((status) => {
        if (type === 'sale-commit') {
          switch (status) {
            case BondStatus.PENDING:
              return BondStatus.COMMIT;
            case BondStatus.COMMIT:
              return BondStatus.DISTRIBUTION;
            case BondStatus.DISTRIBUTION:
              return BondStatus.ACTIVE;
            default:
              return BondStatus.MATURED;
          }
        } else if (type === 'launchpad') {
          switch (status) {
            case BondStatus.PENDING:
              return BondStatus.CALCULATION;
            case BondStatus.CALCULATION:
              return BondStatus.COMMIT;
            case BondStatus.COMMIT:
              return BondStatus.DISTRIBUTION;
            case BondStatus.DISTRIBUTION:
              return BondStatus.ACTIVE;
            default:
              return BondStatus.MATURED;
          }
        } else {
          switch (status) {
            case BondStatus.PENDING:
              return BondStatus.ON_SALE;
            case BondStatus.ON_SALE:
              return BondStatus.ACTIVE;
            default:
              return BondStatus.MATURED;
          }
        }
      })(status);
      dispatch(setCurrentBondStatus(_status));
    }
  }, [timeObj]);
  useEffect(() => {
    clearInterval(interval);
    interval = setInterval(() => {
      getTimeUntil();
    }, 1000);
    return () => clearInterval(interval);
  }, [timeToCountDown]);
  return (
    <Flex>
      {['days', 'hours', 'minutes', 'seconds'].map((tu, idx) => (
        <Fragment key={tu}>
          <Flex direction={'column'} alignItems={'center'}>
            <Flex
              borderRadius={['4px']}
              width={['36px']}
              height={['36px']}
              alignItems={['center']}
              justifyContent={['center']}
              bg={['gray.900']}
            >
              <Text color={'teal.150'} fontWeight={[600]} fontSize={['20px']}>
                {formatTime(timeObj[tu])}
              </Text>
            </Flex>
            <Text color={'gray.600'} fontWeight={[400]} fontSize={['12px']}>
              {t(tu)}
            </Text>
          </Flex>
          {!isEqual(idx, 3) && (
            <Flex direction={'column'} alignItems={'center'}>
              <Flex width={['18px']} height={['36px']} alignItems={['center']} justifyContent={['center']}>
                <Text color={'teal.150'} fontWeight={[600]} fontSize={['20px']}>
                  :
                </Text>
              </Flex>
            </Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  );
};

export default function TimeLineContent({ isCollateral, total, timeToCountDown }) {
  const { t } = useTranslation('bonds');
  const detail = useGetCurrentBondDetail();
  const {
    status,
    pendingDate,
    onSaleDate,
    activeDate,
    maturityDate,
    faceValue,
    faceAsset,
    lastPrice,
    distributedDate,
    committedDate,
    type,
    calculationDate,
  } = detail ?? {};
  const [onPresentCollateralTooltip] = useModal(<ModalClaimingCollateral isOpenModal={true} />);
  const faceAssetPrice = useGetApiPrice(faceAsset?.name?.toLowerCase()) || 1;
  const unitPrice = isEqual(status, BondStatus.MATURED) ? Number(faceValue) : Number(lastPrice);
  const { account } = useWeb3React();
  const getDate = (phase) => {
    switch (phase) {
      case 1:
        return pendingDate;
      case 2:
        return onSaleDate;
      case 3:
        return activeDate;
      case 4:
        return maturityDate;
      default:
        '';
    }
  };
  const getDateCommit = (phase) => {
    switch (phase) {
      case 1:
        return pendingDate;
      case 2:
        return committedDate;
      case 3:
        return distributedDate;
      case 4:
        return activeDate;
      case 5:
        return maturityDate;
      default:
        '';
    }
  };
  const getDateLaunchpad = (phase) => {
    switch (phase) {
      case 1:
        return pendingDate;
      case 2:
        return calculationDate;
      case 3:
        return committedDate;
      case 4:
        return distributedDate;
      case 5:
        return activeDate;
      case 6:
        return maturityDate;
      default:
        '';
    }
  };

  return (
    <Box flex={1} width={['100%']} pr={[undefined, undefined, '134px', '64px']}>
      {type === 'launchpad' &&
        [
          BondStatus.PENDING,
          BondStatus.CALCULATION,
          BondStatus.COMMIT,
          BondStatus.DISTRIBUTION,
          BondStatus.ACTIVE,
          BondStatus.MATURED,
        ].map((s, index) => (
          <Flex key={s} mb={['12px']}>
            <Flex width={'36px'} flexDirection={'column'} alignItems={'center'} color={'gray.400'}>
              <Flex
                borderRadius={'50%'}
                width={['28px', '28px', '36px']}
                height={['28px', '28px', '36px']}
                justifyContent={'center'}
                alignItems={'center'}
                color={isEqual(status, s) ? 'white' : 'gray.500'}
                bg={isEqual(status, s) && 'teal.150'}
                border={!isEqual(status, s) && '2px solid'}
                borderColor={'gray.600'}
                fontWeight={600}
              >
                {index + 1}
              </Flex>
              {!isEqual(s, BondStatus.MATURED) && (
                <Box mt={['12px']} width={'1.5px'} bg={isEqual(status, s) ? 'teal.150' : 'gray.600'} flex={1} />
              )}
            </Flex>
            <Box w={'100%'} pl={['16px', '16px', '20px']} pb={['24px']} color={'gray.500'}>
              <Text fontWeight={[500]} fontSize={['16px']} color={'white'}>
                {t(`title_phase_launchpad_${index + 1}`)}
              </Text>
              <Text fontWeight={[400]} fontSize={['12px']}>
                {moment.unix(getDateLaunchpad(index + 1)).format('MM-DD-YYYY')}
              </Text>
              <Text as={'span'} display={'inline-block'} fontWeight={[400]} fontSize={['14px']} mt={['16px']}>
                <Text as={'span'}>
                  {isEqual(status, s)
                    ? t(`desc_active_phase_launchpad_${index + 1}`)
                    : t(`desc_inactive_phase_launchpad_${index + 1}`)}
                </Text>
                {isEqual(status, s) && (
                  <Text
                    as={'a'}
                    href="https://docs.position.exchange/products/position-bonds"
                    target="_blank"
                    cursor={'pointer'}
                    fontWeight={600}
                    color={'teal.150'}
                  >
                    &nbsp;{t('learn_more')}
                  </Text>
                )}
              </Text>

              {/* count down components */}
              {isEqual(status, s) &&
                includes(
                  [BondStatus.PENDING, BondStatus.CALCULATION, BondStatus.COMMIT, BondStatus.DISTRIBUTION],
                  status,
                ) && (
                  <Flex
                    mt={['16px', '16px', '34px']}
                    flexDirection={['column', 'column', 'row']}
                    justifyContent={'space-between'}
                    alignItems={'baseline'}
                  >
                    <Text fontWeight={[500]} fontSize={['14px']} color={'white'} mb={['16px', '16px', undefined]}>
                      {t('time_left')}:
                    </Text>
                    <TimeCountDown timeToCountDown={timeToCountDown} status={status} type={type} />
                  </Flex>
                )}
              {isEqual(status, s) && isEqual(s, BondStatus.COMMIT) && (
                <>
                  <Box width={'100%'} height={'1px'} my={['16px', '20px']} bg={'gray.700'} />
                  <CommitPhase bondDetail={detail} />
                </>
              )}
              {isEqual(status, s) && includes([BondStatus.DISTRIBUTION, BondStatus.ACTIVE], s) && (
                <>
                  <Box width={'100%'} height={'1px'} my={['16px', '20px']} bg={'gray.700'} />
                  <DistributionPhase bondDetail={detail} />
                </>
              )}
              {isEqual(status, s) &&
                includes([BondStatus.ACTIVE, BondStatus.MATURED], s) &&
                !!Number(total) &&
                !isCollateral && (
                  <Box
                    boxShadow={'0px 0px 36px rgba(0, 0, 0, 0.28)'}
                    px={['24px']}
                    py={['14px']}
                    width={'50%'}
                    minWidth={['250px']}
                    my={['20px']}
                    borderRadius={['12px']}
                    bg={'#1F2835'}
                  >
                    <Text fontWeight={600} fontSize={'14px'} color={'gray.500'}>
                      {isEqual(status, BondStatus.ACTIVE) ? t('total_unit') : t('est_face_value')}
                    </Text>
                    <Text fontWeight={600} fontSize={'24px'} color={'white'}>
                      {formatBondBalance(Number(total))}
                    </Text>
                    <Text fontWeight={600} fontSize={'12px'} color={'cyan.400'}>
                      {`~$${formatBondBalance(Number(total) * unitPrice * faceAssetPrice)}`}
                    </Text>
                  </Box>
                )}
              {isEqual(status, s) && isEqual(status, BondStatus.MATURED) && !!Number(total) && isCollateral && (
                <Box
                  boxShadow={'0px 0px 36px rgba(0, 0, 0, 0.28)'}
                  px={['24px']}
                  py={['14px']}
                  width={'50%'}
                  my={['20px']}
                  borderRadius={['12px']}
                  bg={'#1F2835'}
                  position={'relative'}
                >
                  <Text fontWeight={600} fontSize={'14px'} color={'gray.500'}>
                    {t('est_collateral')}
                  </Text>
                  <Text fontWeight={600} fontSize={'24px'} color={'white'}>
                    {formatBondBalance(Number(total))}
                  </Text>
                  <Text fontWeight={600} fontSize={'12px'} color={'cyan.400'}>
                    {`~$${formatBondBalance(Number(total) * Number(faceValue))}`}
                  </Text>
                  <Image
                    w={['18px']}
                    h={['18px']}
                    position={'absolute'}
                    right={'16px'}
                    top={0}
                    bottom={0}
                    my={'auto'}
                    cursor={'pointer'}
                    onClick={onPresentCollateralTooltip}
                    src="/img/information_circle_green.svg"
                  />
                </Box>
              )}
            </Box>
          </Flex>
        ))}
      {type === 'sale-commit' &&
        [BondStatus.PENDING, BondStatus.COMMIT, BondStatus.DISTRIBUTION, BondStatus.ACTIVE, BondStatus.MATURED].map(
          (s, index) => (
            <Flex key={s} mb={['12px']}>
              <Flex width={'36px'} flexDirection={'column'} alignItems={'center'} color={'gray.400'}>
                <Flex
                  borderRadius={'50%'}
                  width={['28px', '28px', '36px']}
                  height={['28px', '28px', '36px']}
                  justifyContent={'center'}
                  alignItems={'center'}
                  color={isEqual(status, s) ? 'white' : 'gray.500'}
                  bg={isEqual(status, s) && 'teal.150'}
                  border={!isEqual(status, s) && '2px solid'}
                  borderColor={'gray.600'}
                  fontWeight={600}
                >
                  {index + 1}
                </Flex>
                {!isEqual(s, BondStatus.MATURED) && (
                  <Box mt={['12px']} width={'1.5px'} bg={isEqual(status, s) ? 'teal.150' : 'gray.600'} flex={1} />
                )}
              </Flex>
              <Box w={'100%'} pl={['16px', '16px', '20px']} pb={['24px']} color={'gray.500'}>
                <Text fontWeight={[500]} fontSize={['16px']} color={'white'}>
                  {t(`title_phase_commit_${index + 1}`)}
                </Text>
                <Text fontWeight={[400]} fontSize={['12px']}>
                  {moment.unix(getDateCommit(index + 1)).format('MM-DD-YYYY')}
                </Text>
                <Text as={'span'} display={'inline-block'} fontWeight={[400]} fontSize={['14px']} mt={['16px']}>
                  <Text as={'span'}>
                    {isEqual(status, s)
                      ? t(`desc_active_phase_commit_${index + 1}`)
                      : t(`desc_inactive_phase_commit_${index + 1}`)}
                  </Text>
                  {isEqual(status, s) && (
                    <Text
                      as={'a'}
                      href="https://docs.position.exchange/products/position-bonds"
                      target="_blank"
                      cursor={'pointer'}
                      fontWeight={600}
                      color={'teal.150'}
                    >
                      &nbsp;{t('learn_more')}
                    </Text>
                  )}
                </Text>

                {/* count down components */}
                {isEqual(status, s) &&
                  includes([BondStatus.PENDING, BondStatus.COMMIT, BondStatus.DISTRIBUTION], status) && (
                    <Flex
                      mt={['16px', '16px', '34px']}
                      flexDirection={['column', 'column', 'row']}
                      justifyContent={'space-between'}
                      alignItems={'baseline'}
                    >
                      <Text fontWeight={[500]} fontSize={['14px']} color={'white'} mb={['16px', '16px', undefined]}>
                        {t('time_left')}:
                      </Text>
                      <TimeCountDown timeToCountDown={timeToCountDown} status={status} type={type} />
                    </Flex>
                  )}
                {isEqual(status, s) && isEqual(s, BondStatus.COMMIT) && (
                  <>
                    <Box width={'100%'} height={'1px'} my={['16px', '20px']} bg={'gray.700'} />
                    <CommitPhase bondDetail={detail} />
                  </>
                )}
                {isEqual(status, s) && includes([BondStatus.DISTRIBUTION, BondStatus.ACTIVE], s) && (
                  <>
                    <Box width={'100%'} height={'1px'} my={['16px', '20px']} bg={'gray.700'} />
                    <DistributionPhase bondDetail={detail} />
                  </>
                )}
                {isEqual(status, s) &&
                  includes([BondStatus.ACTIVE, BondStatus.MATURED], s) &&
                  !!Number(total) &&
                  !isCollateral && (
                    <Box
                      boxShadow={'0px 0px 36px rgba(0, 0, 0, 0.28)'}
                      px={['24px']}
                      py={['14px']}
                      width={'50%'}
                      minWidth={['250px']}
                      my={['20px']}
                      borderRadius={['12px']}
                      bg={'#1F2835'}
                    >
                      <Text fontWeight={600} fontSize={'14px'} color={'gray.500'}>
                        {isEqual(status, BondStatus.ACTIVE) ? t('total_unit') : t('est_face_value')}
                      </Text>
                      <Text fontWeight={600} fontSize={'24px'} color={'white'}>
                        {formatBondBalance(Number(total))}
                      </Text>
                      <Text fontWeight={600} fontSize={'12px'} color={'cyan.400'}>
                        {`~$${formatBondBalance(Number(total) * unitPrice * faceAssetPrice)}`}
                      </Text>
                    </Box>
                  )}
                {isEqual(status, s) && isEqual(status, BondStatus.MATURED) && !!Number(total) && isCollateral && (
                  <Box
                    boxShadow={'0px 0px 36px rgba(0, 0, 0, 0.28)'}
                    px={['24px']}
                    py={['14px']}
                    width={'50%'}
                    my={['20px']}
                    borderRadius={['12px']}
                    bg={'#1F2835'}
                    position={'relative'}
                  >
                    <Text fontWeight={600} fontSize={'14px'} color={'gray.500'}>
                      {t('est_collateral')}
                    </Text>
                    <Text fontWeight={600} fontSize={'24px'} color={'white'}>
                      {formatBondBalance(Number(total))}
                    </Text>
                    <Text fontWeight={600} fontSize={'12px'} color={'cyan.400'}>
                      {`~$${formatBondBalance(Number(total) * Number(faceValue))}`}
                    </Text>
                    <Image
                      w={['18px']}
                      h={['18px']}
                      position={'absolute'}
                      right={'16px'}
                      top={0}
                      bottom={0}
                      my={'auto'}
                      cursor={'pointer'}
                      onClick={onPresentCollateralTooltip}
                      src="/img/information_circle_green.svg"
                    />
                  </Box>
                )}
              </Box>
            </Flex>
          ),
        )}
      {type === 'normal' &&
        [BondStatus.PENDING, BondStatus.ON_SALE, BondStatus.ACTIVE, BondStatus.MATURED].map((s, index) => (
          <Flex key={s} mb={['12px']}>
            <Flex width={'36px'} flexDirection={'column'} alignItems={'center'} color={'gray.400'}>
              <Flex
                borderRadius={'50%'}
                width={['28px', '28px', '36px']}
                height={['28px', '28px', '36px']}
                justifyContent={'center'}
                alignItems={'center'}
                color={isEqual(status, s) ? 'white' : 'gray.500'}
                bg={isEqual(status, s) && 'teal.150'}
                border={!isEqual(status, s) && '2px solid'}
                borderColor={'gray.600'}
                fontWeight={600}
              >
                {index + 1}
              </Flex>
              {!isEqual(s, BondStatus.MATURED) && (
                <Box mt={['12px']} width={'1.5px'} bg={isEqual(status, s) ? 'teal.150' : 'gray.600'} flex={1} />
              )}
            </Flex>
            <Box w={'100%'} pl={['16px', '16px', '20px']} pb={['24px']} color={'gray.500'}>
              <Text fontWeight={[500]} fontSize={['16px']} color={'white'}>
                {t(`title_phase_${index + 1}`)}
              </Text>
              <Text fontWeight={[400]} fontSize={['12px']}>
                {moment.unix(getDate(index + 1)).format('MM-DD-YYYY')}
              </Text>
              <Text as={'span'} display={'inline-block'} fontWeight={[400]} fontSize={['14px']} mt={['16px']}>
                <Text as={'span'}>
                  {isEqual(status, s) ? t(`desc_active_phase_${index + 1}`) : t(`desc_inactive_phase_${index + 1}`)}
                </Text>
                {isEqual(status, s) && (
                  <Text
                    as={'a'}
                    href="https://docs.position.exchange/products/position-bonds"
                    target="_blank"
                    cursor={'pointer'}
                    fontWeight={600}
                    color={'teal.150'}
                  >
                    &nbsp;{t('learn_more')}
                  </Text>
                )}
              </Text>
              {isEqual(status, s) && includes([BondStatus.PENDING, BondStatus.ON_SALE], status) && (
                <Flex
                  mt={['16px', '16px', '34px']}
                  flexDirection={['column', 'column', 'row']}
                  justifyContent={'space-between'}
                  alignItems={'baseline'}
                >
                  <Text fontWeight={[500]} fontSize={['14px']} color={'white'} mb={['16px', '16px', undefined]}>
                    {t('time_left')}:
                  </Text>
                  <TimeCountDown timeToCountDown={timeToCountDown} status={status} type={type} />
                </Flex>
              )}
              {isEqual(status, s) && isEqual(s, BondStatus.ON_SALE) && (
                <>
                  <Box width={'100%'} height={'1px'} my={['16px', '20px']} bg={'gray.700'} />
                  <PurchaseBondInput bondDetail={detail} />
                </>
              )}
              {isEqual(status, BondStatus.PENDING) && account && isEqual(s, BondStatus.ON_SALE) && (
                <>
                  <Box width={'100%'} height={'1px'} my={['16px', '20px']} bg={'gray.700'} />
                  <ButtonAppoveForPurchase detail={detail} />
                </>
              )}
              {isEqual(status, s) &&
                includes([BondStatus.ACTIVE, BondStatus.MATURED], s) &&
                !!Number(total) &&
                !isCollateral && (
                  <Box
                    boxShadow={'0px 0px 36px rgba(0, 0, 0, 0.28)'}
                    px={['24px']}
                    py={['14px']}
                    width={'50%'}
                    minWidth={['250px']}
                    my={['20px']}
                    borderRadius={['12px']}
                    bg={'#1F2835'}
                  >
                    <Text fontWeight={600} fontSize={'14px'} color={'gray.500'}>
                      {isEqual(status, BondStatus.ACTIVE) ? t('total_unit') : t('est_face_value')}
                    </Text>
                    <Text fontWeight={600} fontSize={'24px'} color={'white'}>
                      {formatBondBalance(Number(total))}
                    </Text>
                    <Text fontWeight={600} fontSize={'12px'} color={'cyan.400'}>
                      {`~$${formatBondBalance(Number(total) * unitPrice * faceAssetPrice)}`}
                    </Text>
                  </Box>
                )}
              {isEqual(status, s) && isEqual(status, BondStatus.MATURED) && !!Number(total) && isCollateral && (
                <Box
                  boxShadow={'0px 0px 36px rgba(0, 0, 0, 0.28)'}
                  px={['24px']}
                  py={['14px']}
                  width={'50%'}
                  my={['20px']}
                  borderRadius={['12px']}
                  bg={'#1F2835'}
                  position={'relative'}
                >
                  <Text fontWeight={600} fontSize={'14px'} color={'gray.500'}>
                    {t('est_collateral')}
                  </Text>
                  <Text fontWeight={600} fontSize={'24px'} color={'white'}>
                    {formatBondBalance(Number(total))}
                  </Text>
                  <Text fontWeight={600} fontSize={'12px'} color={'cyan.400'}>
                    {`~$${formatBondBalance(Number(total) * Number(faceValue))}`}
                  </Text>
                  <Image
                    w={['18px']}
                    h={['18px']}
                    position={'absolute'}
                    right={'16px'}
                    top={0}
                    bottom={0}
                    my={'auto'}
                    cursor={'pointer'}
                    onClick={onPresentCollateralTooltip}
                    src="/img/information_circle_green.svg"
                  />
                </Box>
              )}
            </Box>
          </Flex>
        ))}
    </Box>
  );
}
