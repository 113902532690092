import { Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IInDevelopModal {
  isOpenModal: boolean;
  onDismiss?: () => void;
}

const InDevelopModal: React.FC<IInDevelopModal> = ({ isOpenModal, onDismiss = () => {} }) => {
  const { t } = useTranslation(['bonds']);
  return (
    <Modal scrollBehavior="outside" isCentered size={'md'} isOpen={isOpenModal} onClose={onDismiss}>
      <ModalOverlay width="100%" height="100%" />
      <ModalContent background={'gray.800'} borderRadius="24px" py={0} px={0} ml="16px" mr="16px">
        <ModalCloseButton color={'gray.500'} mt={'20px'} onClick={onDismiss} />
        <ModalBody py="32px" px="32px">
          <Flex w={'100%'}>
            <Flex flexDirection={'column'} mx={'auto'}>
              <Image mx={'auto'} src={`/img/posi_big.svg`} w={'100px'} h={'100px'} />
              <Flex
                textAlign={'center'}
                mt="16px"
                color={'white'}
                align="center"
                fontWeight="bold"
                justify="center"
                fontSize={'24px'}
              >
                {t('feature_in_develop')}
              </Flex>
              <Flex mx={'auto'} textAlign="center" color={'gray.500'} fontSize={'14px'} mt={'5px'}>
                {t('feature_in_develop_des')}
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InDevelopModal;
