import BigNumber from 'bignumber.js';
import moment from 'moment';
import numeral from 'numeral';
import bondABI from '../configs/abi/bond.json';
import bondCommitABI from '../configs/abi/bondCommit.json';
import { BondStatus, IBondDetail } from '../configs/constants/type';
import { TabMenu } from '../configs/types';
import { CompetitionStatus, DetailRenderModalInfo, IDetailProposal, IState, Pool } from '../redux/types';
import { apyModalRoi, calculateCakeEarnedPerThousandDollarsForPool } from './compoundApyHelpers';
import { TokenInfo } from '@uniswap/token-lists';
import { WrappedTokenInfo } from '../redux/lists/hooks';
// import tokens from '../constants/token/pancakeswap.json';
import DEFAULT_LIST from '../constants/token/pancakeswap.json';
import _ from 'lodash';
import { TypeOfInputConfig } from '../configs/constants/bondExchange';
import { getAddress, isAddress } from 'ethers/lib/utils';
import pools from '../configs/pools';
import { getAddress as getAddressCustom } from '../utils/addressHelpers';
import { ETHER } from '@pancakeswap/sdk';
import tokens from '../configs/constants/tokens';
import crypto from 'crypto';
import { ALGORITHM, BLOCK_SIZE, CIPHER_KEY } from './constant';

export const gWei = 0.000000005;

export const formatNumber = (num: any) => {
  if (num && Number(num)) {
    let firstNum = '';
    let lastNum = '';
    const numString = num.toString();
    if (numString !== '' && numString.includes(',')) {
      const newNum = numString.split(',');
      firstNum = newNum[0] || '';
      lastNum = newNum[1] || '';
    } else {
      const newNum = numString.split('.');
      firstNum = newNum[0] || '';
      lastNum = newNum[1] || '';
    }
    const newFistNum = firstNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return `${newFistNum}${lastNum ? '.' : ''}${lastNum !== '' ? lastNum.slice(0, 2) : '' || ''}`;
  }
  if (num && Number(num) === 0) {
    return 0;
  }

  return 0;
};

export const formatNumberToBigString = (number: any) => {
  if (number) {
    const cloneAPY = number.toString();
    const arrayNumber = cloneAPY.split('.');
    if (arrayNumber[0].length > 8) {
      const newNumber = arrayNumber[0];
      const numberAfterFormat = Number(newNumber.replaceAll(',', '')).toExponential();
      const newNumberAfterFormat = numberAfterFormat.toString().split('e');
      const frefix = Number(newNumberAfterFormat[0]).toFixed(2);
      const apyLatest = `${frefix}e${newNumberAfterFormat[1]}`;
      return apyLatest;
    } else {
      return Number(Number(number).toFixed(4));
    }
  }
  return 0;
};

// export const formatNumberToPosi = (number: any) => {
//   if(number){

//   }
//   return '';
// };

export const formatNumberWithNumeral = (val: number | string, suffix?: number) => {
  const stringDefault = '0000000000';
  const numberOfZero = stringDefault.slice(0, suffix ?? 3);
  if (val) {
    const newNumber = Number(val);
    if (newNumber < 0.0001) {
      return new BigNumber(newNumber).toFixed(suffix, BigNumber.ROUND_DOWN);
    } else {
      const newData = new BigNumber(val).toFixed(Number(suffix || 3), BigNumber.ROUND_DOWN);
      return numeral(newData).format(`0,0.${numberOfZero}`);
    }
  } else {
    return '0.000';
  }
};

export const formatNumberNotCommaWithNumeral = (val: number | string, suffix?: number) => {
  const stringDefault = '0000000000';
  const numberOfZero = stringDefault.slice(0, suffix ?? 3);
  if (val) {
    const newNumber = Number(val);
    if (newNumber < 0.0001) {
      return new BigNumber(newNumber).toFixed(suffix, BigNumber.ROUND_DOWN);
    } else {
      const newData = new BigNumber(newNumber).toFixed(Number(suffix || 3), BigNumber.ROUND_DOWN);
      return numeral(newData).format(`0.${numberOfZero}`);
    }
  } else {
    return '0.000';
  }
};

export const formatNumberWithBalance = (val: number | string, suffix?: number) => {
  return Number(val).toFixed(suffix);
};

export const serialize = (obj: any, prefix?: any): string => {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p;

      const v = obj[p];
      str.push(
        v !== null && typeof v === 'object' ? serialize(v, k) : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
      );
    }
  }
  return str.join('&');
};

export const generateAvatar = () => {
  /* Configuration constiables */
  const MAX_COLOR = 200; // Max value for a color component
  const MIN_COLOR = 120; // Min value for a color component
  const FILL_CHANCE = 0.5; // Chance of a square being filled [0, 1]
  const SQUARE = 80; // Size of a grid square in pixels
  const GRID = 5; // Number of squares width and height
  const PADDING = SQUARE / 2; // Padding on the edge of the canvas in px
  const SIZE = SQUARE * GRID + PADDING * 2; // Size of the canvas
  const FILL_COLOR = '#F0ECE6'; // canvas background color

  /* Create a temporary canvas */
  function setupCanvas() {
    const canvas = document.createElement('canvas');
    canvas.width = SIZE;
    canvas.height = SIZE;

    // Fill canvas background
    const context: any = canvas.getContext('2d');
    context.beginPath();
    context.rect(0, 0, SIZE, SIZE);
    context.fillStyle = FILL_COLOR;
    context.fill();
    return canvas;
  }

  /* Fill in a square of the canvas */
  function fillBlock(x: any, y: any, color: any, context: any) {
    context.beginPath();
    context.rect(PADDING + x * SQUARE, PADDING + y * SQUARE, SQUARE, SQUARE);
    context.fillStyle = `rgb(${color.join(',')})`;
    context.fill();
  }

  /* Generate a random color with low saturation. */
  function generateColor() {
    const rgb = [];
    for (let i = 0; i < 3; i++) {
      const val = Math.floor(Math.random() * 256);
      const minEnforced = Math.max(MIN_COLOR, val);
      const maxEnforced = Math.min(MAX_COLOR, minEnforced);
      rgb.push(maxEnforced);
    }
    return rgb;
  }

  /* Generate a random identicon */
  function generateIdenticon() {
    const canvas = setupCanvas();
    const context = canvas.getContext('2d');
    const color = generateColor(); // Generate custom tile color

    // Iterate through squares on left side
    for (let x = 0; x < Math.ceil(GRID / 2); x++) {
      for (let y = 0; y < GRID; y++) {
        // Randomly fill squares
        if (Math.random() < FILL_CHANCE) {
          fillBlock(x, y, color, context);

          // Fill right side symmetrically
          if (x < Math.floor(GRID / 2)) {
            fillBlock(GRID - 1 - x, y, color, context);
          }
        }
      }
    }
    return canvas.toDataURL();
  }

  // Attach finished identicon to DOM
  const urlDataImage = generateIdenticon(); // Generate identicon
  return urlDataImage; // Assign url data to image
};
export const paginate = (array: any, page_size: number, page_number: number) => {
  return array?.slice((page_number - 1) * page_size, page_number * page_size);
};

export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

export const convertPOSIToBNB = (posiValue: string | number, pricePOSI: number, priceBNB: number) => {
  return (Number(posiValue) * pricePOSI) / priceBNB || 0;
};

export const calculateLastPrice = (lastPrice: number, lastBidder: string) => {
  const address0 = '0x0000000000000000000000000000000000000000';
  if (lastBidder === address0) {
    return lastPrice;
  }
  return lastPrice + lastPrice * 0.1;
};

export const calculateNetworkFee = (gasLimit: number) => {
  return gasLimit * gWei;
};

export const convertToBusd = (price: number, earned: number) => {
  return earned * price;
};

export const formatBalanceWithNumeral = (val: number | string, suffix?: number) => {
  const stringDefault = '0000000000';
  const numberOfZero = stringDefault.slice(0, suffix ?? 3);
  if (val) {
    const newNumber = Number(val);
    if (newNumber < 1) {
      const numberString = newNumber.toString();
      const decimals = numberString.replace('0.', '');
      const existRealNumber = decimals.search(/[1-9]/);
      return newNumber.toFixed((existRealNumber !== -1 ? existRealNumber : 0) + (suffix ?? 0));
    } else if (new BigNumber(val).isGreaterThanOrEqualTo(1) && new BigNumber(val).isLessThanOrEqualTo(9999)) {
      const newData = new BigNumber(newNumber).toFixed(Number(suffix || 3), BigNumber.ROUND_DOWN);
      return numeral(newData).format(`0,0.${numberOfZero}`);
    } else if (new BigNumber(val).isGreaterThan(9999) && new BigNumber(val).isLessThanOrEqualTo(999999)) {
      const newData = new BigNumber(newNumber).toFixed(Number(2), BigNumber.ROUND_DOWN);
      return numeral(newData).format(`0,0.00`);
    } else {
      const newData = new BigNumber(newNumber).toFixed(Number(0), BigNumber.ROUND_DOWN);
      return numeral(newData).format(`0,0`);
    }
  } else {
    return '0';
  }
};

export const getEtherAddressFromString = (str: string): string => {
  const indexOf0x = str.indexOf('0x');
  if (indexOf0x >= 0) {
    const address = str.substring(indexOf0x, indexOf0x + 42);
    const isTrustAddress = isAddress(address);
    if (isTrustAddress) {
      return address;
    }
    return '';
  }
  return '';
};

export const formatBalanceWithNumeralNotRound = (val: number | string, suffix?: number) => {
  const stringDefault = '0000000000';
  const numberOfZero = stringDefault.slice(0, suffix ?? 3);
  if (val) {
    const newNumber = new BigNumber(val);
    if (newNumber.isLessThan(1)) {
      const numberString = newNumber.toFixed();
      const decimals = numberString.replace('0.', '');
      const existRealNumber = decimals.search(/[1-9]/);
      return new BigNumber(newNumber).toFixed(existRealNumber + (suffix ?? 0), BigNumber.ROUND_DOWN);
    } else if (newNumber.isGreaterThan(100000000)) {
      const fmt = {
        prefix: '',
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
        secondaryGroupSize: 0,
      };
      return newNumber.toFormat(0, BigNumber.ROUND_DOWN, fmt);
    } else {
      return numeral(newNumber.toFixed()).format(`0,0.${numberOfZero}`, Math.floor);
    }
  } else {
    return `0.${numberOfZero}`;
  }
};

export const formatBalanceWithNumerals = (val: number | string, suffix?: number) => {
  const stringDefault = '0000000000';
  const numberOfZero = stringDefault.slice(0, suffix ?? 3);
  if (val) {
    const newNumber = Number(val);
    if ((newNumber * 10) % 10 === 0) {
      return numeral(newNumber).format(`0,0`);
    } else {
      return numeral(newNumber).format(`0,0.${numberOfZero}`);
    }
  } else {
    const newNumber = Number(val);
    if (newNumber === 0) {
      numeral(val).format(`0.0000`);
    } else if ((newNumber * 10) % 10 === 0) {
      return numeral(newNumber).format(`0,0`);
    }
    return numeral(val).format(`0.${numberOfZero}`);
  }
};

export const truncateDecimals = (val: number, suffix?: number) => {
  const valPower = Math.pow(10, suffix);
  return ((val * valPower) | 0.0) / valPower;
};

export const getStatusCompetition = (startTime: any, endTime: any) => {
  const isActive = moment().isSameOrAfter(startTime);
  const isEnded = moment().isAfter(endTime);
  if (isEnded) {
    return CompetitionStatus.ended;
  } else if (isActive) {
    return CompetitionStatus.active;
  }
  return CompetitionStatus.coming_soon;
};
export const calculateBalanceAfterAction = (rawBalance: BigNumber) => {
  const rawTokenbalances = rawBalance.dividedBy(new BigNumber('1000000000000000000')).toNumber();
  const displayTokenBalance = new BigNumber(rawTokenbalances).dp(6, BigNumber.ROUND_DOWN).toString();
  return displayTokenBalance;
};

export const getTitleInfoModal = (state: DetailRenderModalInfo) => {
  switch (state) {
    case DetailRenderModalInfo.locked_balance:
      return 'Locked Balance';
    case DetailRenderModalInfo.locked_harvest:
      return 'harvest_balance';
    default:
      return '';
  }
};

export const getIsActiveMenu = (pathname: string) => {
  if (pathname.includes('/swap')) {
    return TabMenu.SWAP;
  }
  // if (pathname.includes('/swap')) {
  //   return TabMenu.SWAP;
  // }
  if (pathname.includes('/competition/referral')) {
    return TabMenu.WIN;
  }
  if (pathname.includes('/competition/buying')) {
    return TabMenu.WIN;
  }
  if (pathname.includes('/farming')) {
    return TabMenu.EARN;
  }
  if (pathname.includes('/pools')) {
    return TabMenu.EARN;
  }
  if (pathname.includes('/futures-staking')) {
    return TabMenu.EARN;
  }
  if (pathname.includes('/vault')) {
    return TabMenu.EARN;
  }
  if (pathname.includes('/referral')) {
    return TabMenu.REFERRAL;
  }
  if (pathname.includes('/testnet.position.exchange')) {
    return TabMenu.DERIVATIVES;
  }
  if (pathname.includes('/nfts')) {
    return TabMenu.NFTS;
  }
  if (pathname.includes('/nft/marketplace')) {
    return TabMenu.NFTS;
  }
  if (pathname.includes('/bonds')) {
    return TabMenu.BONDS;
  }
  if (pathname.includes('/launchpad/bond')) {
    return TabMenu.BONDS;
  }
  if (pathname === '/launchpad/bond/pools') {
    return TabMenu.BONDS;
  }
  if (pathname.includes('/bond-exchange')) {
    return TabMenu.BONDS;
  }
};

export const getImageUrlBySymbol = (symbol) => {
  const imageUrl =
    symbol && symbol.trim().toLowerCase().includes('posi')
      ? `/img/${symbol.trim().toLowerCase()}.svg`
      : `/img/${symbol.trim().toLowerCase()}.png`;
  return imageUrl;
};

export const replaceContent = (content, replaceText, positionReplace = 0) => {
  const contentSplit = content && content.split(' ');
  const lengthContentSplit = contentSplit.length;
  const contentIsReplaced = contentSplit[lengthContentSplit - 1 - positionReplace];
  const displayContent = content.replace(contentIsReplaced, replaceText as string);
  return displayContent;
};

export const calculateApy = (apr, posiPrice) => {
  const posiEarnedPerThousand365D = calculateCakeEarnedPerThousandDollarsForPool({
    numberOfDays: 365,
    farmApy: apr,
    cakePrice: posiPrice,
  });
  const oneThousandDollarsWorthOfCake = 1000 / posiPrice;

  const i365DROI = new BigNumber(
    apyModalRoi({
      amountEarned: posiEarnedPerThousand365D,
      amountInvested: oneThousandDollarsWorthOfCake,
    }),
  ).toString();

  const displayAPY = !new BigNumber(i365DROI).eq(Infinity)
    ? i365DROI.length < 15
      ? numeral(i365DROI).format('0,0.00')
      : new BigNumber(i365DROI).toExponential(3)
    : 'Infinity';
  return displayAPY;
};

export const getRangeMiningEfficient = (
  grade: string,
): {
  min: number;
  max: number;
} => {
  switch (grade) {
    case '1': {
      return {
        min: 1.1,
        max: 1.2,
      };
    }
    case '2': {
      return {
        min: 1.2,
        max: 1.3,
      };
    }
    case '3': {
      return {
        min: 1.3,
        max: 1.4,
      };
    }
    case '4': {
      return {
        min: 1.4,
        max: 1.6,
      };
    }
    case '5': {
      return {
        min: 1.6,
        max: 1.8,
      };
    }
    default: {
      return {
        min: 1.8,
        max: 2,
      };
    }
  }
};

export const calculateRewardNftPerGrade = (
  grade: string,
  parValue: number,
  lockedDays: number,
  apr: number,
  posiPrice: number,
): { minReward: number; maxReward: number } => {
  const rangeMiningEfficient = getRangeMiningEfficient(grade);
  const minReward =
    rangeMiningEfficient &&
    (calculateCakeEarnedPerThousandDollarsForPool({
      numberOfDays: lockedDays,
      farmApy: apr,
      cakePrice: posiPrice,
    }) *
      parValue *
      rangeMiningEfficient.min *
      posiPrice) /
      1000;
  const maxReward =
    rangeMiningEfficient &&
    (calculateCakeEarnedPerThousandDollarsForPool({
      numberOfDays: lockedDays,
      farmApy: apr,
      cakePrice: posiPrice,
    }) *
      parValue *
      rangeMiningEfficient.max *
      posiPrice) /
      1000;
  return {
    minReward,
    maxReward,
  };
};

export const handleOnKeyDown = (e: any) => {
  if (e.key === 'e' || e.key === '-' || e.key === '+' || (e.key === '.' && e.target.defaultValue.includes('.'))) {
    e.preventDefault();
    return false;
  }
  return true;
};

export const getTextAlignTitleBondTable = (id: number) => {
  switch (id) {
    case 1: {
      return 'flex-start';
    }
    case 9: {
      return 'flex-end';
    }
    default: {
      return 'center';
    }
  }
};

// Takes a long hash string and truncates it.
export const truncateAddress = (account: string, length = 4): string => {
  if (account) return `${account.substring(0, length)}...${account.substring(account.length - length)}`;
  return '';
};

export const formatBondBalance = (amount: number): string => {
  amount = Number(amount);
  if (amount < 10) {
    return amount.toFixed(3);
  } else if (amount < 100) {
    return amount.toFixed(3);
  } else return numeral(amount).format('0,0.00');
};

export const calculatePercentStatusDatePassed = (startTime: number, endTime: number): number => {
  const startTimeTimestamp = startTime;
  const endTimeTimestamp = endTime;
  const currentTimestamp = moment().unix();
  const percentPassed = (currentTimestamp - startTimeTimestamp) / (endTimeTimestamp - startTimeTimestamp);
  if (Number(percentPassed) < 0.02) {
    return 0.02;
  }
  return percentPassed * 100;
};

export const calculateRewardFromBond = (
  parValue: number,
  totalDay: number,
  apr: number,
  faceValue: number,
  faceAssetValueInPOSI: number,
  pricePurchase: number,
  posiPrice: number,
  faceAsset: number,
) => {
  const totalPOSIEarn =
    (parValue / (pricePurchase * faceAsset)) * (faceValue / faceAssetValueInPOSI) * (apr / 100) * (totalDay / 365);
  const totalReward = totalPOSIEarn * posiPrice;
  return totalReward;
};

export const formatTime = (time, typeFormat) => {
  return moment(time * 1000).format(typeFormat);
};
export const formatDateTimestamp = (date: number, format?: string) => {
  let newFormat = 'YYYY-MM-DD';
  if (format) {
    newFormat = format;
  }
  if (!date) {
    return '';
  }
  if (date.toString().length === 10) {
    return moment(date * 1000).format(newFormat);
  }
  return moment(date).format(newFormat);
};
export const getStatusFromTimeOfBond = (
  pendingDate: number,
  onSaleDate: number,
  activeDate: number,
  maturityDate: number,
  type?: string,
  commitDate?: number,
  distributionDate?: number,
  calculationDate?: number,
  liquidatedDate?: number,
  cancelDate?: number,
): BondStatus => {
  const currentDate = moment();
  if (type && type === 'sale-commit') {
    if (moment(maturityDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.MATURED;
    } else if (moment(activeDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.ACTIVE;
    } else if (moment(distributionDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.DISTRIBUTION;
    } else if (moment(commitDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.COMMIT;
    }
    return BondStatus.PENDING;
  } else if (type && type === 'launchpad') {
    if (moment(maturityDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.MATURED;
    } else if (moment(activeDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.ACTIVE;
    } else if (moment(distributionDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.DISTRIBUTION;
    } else if (moment(commitDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.COMMIT;
    } else if (moment(calculationDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.CALCULATION;
    }
    return BondStatus.PENDING;
  } else if (type && type === 'lending') {
    if (cancelDate || moment(cancelDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.CANCEL;
    } else if (liquidatedDate || moment(liquidatedDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.LIQUIDATED;
    } else if (cancelDate || moment(cancelDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.LIQUIDATED;
    } else if (moment(maturityDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.MATURED;
    } else if (moment(activeDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.ACTIVE;
    } else if (moment(onSaleDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.ON_SALE;
    }
    return BondStatus.PENDING;
  } else {
    if (moment(maturityDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.MATURED;
    } else if (moment(activeDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.ACTIVE;
    } else if (moment(onSaleDate * 1000).isSameOrBefore(currentDate)) {
      return BondStatus.ON_SALE;
    }
    return BondStatus.PENDING;
  }
};

export const getRangeTimeOfStatus = (status: BondStatus, detail: IBondDetail) => {
  const currentDate = moment();

  if (detail?.type !== 'normal') {
    if (detail?.type === 'launchpad') {
      switch (status) {
        case BondStatus.PENDING: {
          return {
            statusStartTime: detail?.pending_date,
            statusEndTime: detail?.calculated_date,
          };
        }
        case BondStatus.ACTIVE: {
          return {
            statusStartTime: detail?.active_date,
            statusEndTime: detail?.maturity_date,
          };
        }
        case BondStatus.COMMIT: {
          return {
            statusStartTime: detail?.committed_time,
            statusEndTime: detail?.distributed_time,
          };
        }
        case BondStatus.CALCULATION: {
          return {
            statusStartTime: detail?.calculated_date,
            statusEndTime: detail?.active_date,
          };
        }
        case BondStatus.DISTRIBUTION: {
          return {
            statusStartTime: detail?.distributed_time,
            statusEndTime: detail?.active_date,
          };
        }
        default: {
          return {
            statusStartTime: detail?.maturity_date,
          };
        }
      }
    } else if (detail?.type === 'sale-commit') {
      switch (status) {
        case BondStatus.PENDING: {
          return {
            statusStartTime: detail?.pending_date,
            statusEndTime: detail?.committed_time,
          };
        }
        case BondStatus.ACTIVE: {
          return {
            statusStartTime: detail?.active_date,
            statusEndTime: detail?.maturity_date,
          };
        }
        case BondStatus.COMMIT: {
          return {
            statusStartTime: detail?.committed_time,
            statusEndTime: detail?.distributed_time,
          };
        }
        case BondStatus.DISTRIBUTION: {
          return {
            statusStartTime: detail?.distributed_time,
            statusEndTime: detail?.active_date,
          };
        }
        default: {
          return {
            statusStartTime: detail?.maturity_date,
          };
        }
      }
    } else {
      if (detail?.cancel_date || moment(detail?.cancel_date * 1000).isSameOrBefore(currentDate)) {
        return { statusStartTime: detail?.cancel_date };
      } else if (detail?.liquidated_date || moment(detail?.liquidated_date * 1000).isSameOrBefore(currentDate)) {
        return { statusStartTime: detail?.liquidated_date };
      } else {
        switch (status) {
          case BondStatus.PENDING: {
            return {
              statusStartTime: detail?.pending_date,
              statusEndTime: detail?.on_sale_date,
            };
          }
          case BondStatus.ACTIVE: {
            return {
              statusStartTime: detail?.active_date,
              statusEndTime: detail?.maturity_date,
            };
          }
          case BondStatus.ON_SALE: {
            return {
              statusStartTime: detail?.on_sale_date,
              statusEndTime: detail?.active_date,
            };
          }
          default: {
            return {
              statusStartTime: detail?.maturity_date,
            };
          }
        }
      }
    }
  } else {
    switch (status) {
      case BondStatus.PENDING: {
        return {
          statusStartTime: detail?.pending_date,
          statusEndTime: detail?.on_sale_date,
        };
      }
      case BondStatus.ACTIVE: {
        return {
          statusStartTime: detail?.active_date,
          statusEndTime: detail?.maturity_date,
        };
      }
      case BondStatus.ON_SALE: {
        return {
          statusStartTime: detail?.on_sale_date,
          statusEndTime: detail?.active_date,
        };
      }
      default: {
        return {
          statusStartTime: detail?.maturity_date,
        };
      }
    }
  }
};

export const nFormatter = (num) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(2).replace(rx, '$1') + item.symbol : '0';
};

export const calculateYtm = (
  apr: number,
  faceValue: number,
  currentPrice: number,
  maturity_date: number,
  active_date: number,
) => {
  const maturity = (maturity_date - active_date) / 31536000;

  const ytm = apr * maturity + ((faceValue - currentPrice) * 100) / currentPrice;
  return ytm;
};

export const calculateYtmBondLending = (faceValue: number, issuerPrice: number) => {
  const ytm = ((faceValue - issuerPrice) / issuerPrice) * 100;
  return ytm;
};

export const getNextBondStatus = (
  currentStatus,
  currentEndTime,
  active_date,
  on_sale_date,
  maturity_date,
  type?: string,
  committed_time?: number,
  distributed_time?: number,
  calculated_date?: number,
  liquidated_date?: number,
  cancel_date?: number,
) => {
  const currentDate = moment();

  if (type !== 'normal') {
    if (type === 'launchpad') {
      switch (currentStatus) {
        case BondStatus.PENDING: {
          return {
            status: BondStatus.CALCULATION,
            startTime: currentEndTime,
            endTime: calculated_date,
          };
        }
        case BondStatus.CALCULATION: {
          return {
            status: BondStatus.COMMIT,
            startTime: currentEndTime,
            endTime: committed_time,
          };
        }
        case BondStatus.ACTIVE: {
          return {
            status: BondStatus.MATURED,
            startTime: currentEndTime,
            endTime: maturity_date,
          };
        }
        case BondStatus.COMMIT: {
          return {
            status: BondStatus.DISTRIBUTION,
            startTime: currentEndTime,
            endTime: distributed_time,
          };
        }
        case BondStatus.DISTRIBUTION: {
          return {
            status: BondStatus.ACTIVE,
            startTime: currentEndTime,
            endTime: active_date,
          };
        }
        default: {
          return {
            status: BondStatus.MATURED,
          };
        }
      }
    } else if (type === 'sale-commit') {
      switch (currentStatus) {
        case BondStatus.PENDING: {
          return {
            status: BondStatus.COMMIT,
            startTime: currentEndTime,
            endTime: committed_time,
          };
        }
        case BondStatus.ACTIVE: {
          return {
            status: BondStatus.MATURED,
            startTime: currentEndTime,
            endTime: maturity_date,
          };
        }
        case BondStatus.COMMIT: {
          return {
            status: BondStatus.DISTRIBUTION,
            startTime: currentEndTime,
            endTime: distributed_time,
          };
        }
        case BondStatus.DISTRIBUTION: {
          return {
            status: BondStatus.ACTIVE,
            startTime: currentEndTime,
            endTime: active_date,
          };
        }
        default: {
          return {
            status: BondStatus.MATURED,
          };
        }
      }
    } else {
      if (cancel_date || moment(cancel_date * 1000).isSameOrBefore(currentDate)) {
        return { status: BondStatus.CANCEL };
      } else if (liquidated_date || moment(liquidated_date * 1000).isSameOrBefore(currentDate)) {
        return { status: BondStatus.LIQUIDATED };
      } else {
        switch (currentStatus) {
          case BondStatus.PENDING: {
            return {
              status: BondStatus.ON_SALE,
              startTime: currentEndTime,
              endTime: on_sale_date,
            };
          }
          case BondStatus.ACTIVE: {
            return {
              status: BondStatus.MATURED,
              startTime: currentEndTime,
              endTime: maturity_date,
            };
          }
          case BondStatus.ON_SALE: {
            return {
              status: BondStatus.ACTIVE,
              startTime: currentEndTime,
              endTime: active_date,
            };
          }
          default: {
            return {
              status: BondStatus.MATURED,
            };
          }
        }
      }
    }
  } else {
    switch (currentStatus) {
      case BondStatus.PENDING: {
        return {
          status: BondStatus.ON_SALE,
          startTime: currentEndTime,
          endTime: on_sale_date,
        };
      }
      case BondStatus.ACTIVE: {
        return {
          status: BondStatus.MATURED,
          startTime: currentEndTime,
          endTime: maturity_date,
        };
      }
      case BondStatus.ON_SALE: {
        return {
          status: BondStatus.ACTIVE,
          startTime: currentEndTime,
          endTime: active_date,
        };
      }
      default: {
        return {
          status: BondStatus.MATURED,
        };
      }
    }
  }
};

export const generateDataByTotalLockedDays = (
  startTime: number,
  endTime: number,
  parValue: number,
  apr: number,
  paidOnMaturity: number,
  faceValue: number,
  faceAssetValueInPOSI: number,
  pricePurchase: number,
  posiPrice: number,
  faceAsset: number,
  maxPricePerUnit: number,
) => {
  const timeRange = endTime - startTime;
  const convertToMonth = Math.round(timeRange / (86400 * 30));
  const totalDay = timeRange / 86400;
  const estTimeToMonth = convertToMonth > 12 ? 12 : convertToMonth;
  const segmentTime = timeRange / estTimeToMonth;
  const totalReward = calculateRewardFromBond(
    Number(parValue),
    totalDay,
    apr,
    maxPricePerUnit,
    faceAssetValueInPOSI,
    pricePurchase,
    posiPrice,
    faceAsset,
  ); // dollar
  const listLabel = [];
  const listTime = [];
  for (let i = 0; i <= estTimeToMonth; i++) {
    const timeInSegment = startTime + i * segmentTime;
    const label = moment(timeInSegment * 1000).format('MMM YYYY');
    listLabel.push(label);
    listTime.push(timeInSegment * 1000);
  }
  const listData = listLabel.map((e, index) => {
    return {
      name: e,
      uv:
        Math.ceil(Number(parValue) + (index * totalReward) / estTimeToMonth) +
        (index === listLabel.length - 1 ? paidOnMaturity : 0),
      time: listTime[index],
      earn: index === 0 ? 0 : totalReward / estTimeToMonth + (index === listLabel.length - 1 ? paidOnMaturity : 0),
    };
  });
  return listData;
};

export const getBondLastPrice = (detail: any) => {
  const lastPrice = detail && detail.last_price;
  return Number(lastPrice);
};

export const getIsSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const getBondLastPriceByUsd = (detail: any, prices?: any) => {
  const lastPrice = detail && detail.last_price;
  const faceAssetPrice = prices && prices[detail?.face_asset?.symbol.toLowerCase() || 'busd'];
  return Number(lastPrice) * Number(faceAssetPrice || 0);
};

export const getBondPropertyByUsd = (detail: any, property: string, prices?: any) => {
  const prop = detail && detail[property];
  const faceAssetPrice = prices && prices[detail?.face_asset?.symbol.toLowerCase() || 'busd'];
  return Number(prop) * Number(faceAssetPrice || 0);
};

export const generateDataTableByTotalLockedDays = (
  startTime: number,
  endTime: number,
  parValue: number,
  apr: number,
  paidOnMaturity: number,
  faceValue: number,
  faceAssetValueInPOSI: number,
  pricePurchase: number,
  posiPrice: number,
  faceAsset: number,
  maxPricePerUnit: number,
) => {
  const timeRange = endTime - startTime;
  const convertToMonth = Math.round(timeRange / (86400 * 30));
  const totalDay = timeRange / 86400;
  const estTimeToMonth = convertToMonth;
  const totalReward = calculateRewardFromBond(
    Number(parValue),
    totalDay,
    apr,
    maxPricePerUnit,
    faceAssetValueInPOSI,
    pricePurchase,
    posiPrice,
    faceAsset,
  ); // dollar
  const listLabel = [];
  const listTime = [];
  for (let i = 0; i <= estTimeToMonth; i++) {
    // const timeInSegment = startTime + i * segmentTime;
    const label = moment(startTime * 1000)
      .add(i, 'months')
      .format();
    listLabel.push(label);
    listTime.push(label);
  }
  const listData = listLabel.map((e, index) => {
    return {
      name: e,
      uv:
        Math.ceil(Number(parValue) + (index * totalReward) / estTimeToMonth) +
        (index === listLabel.length - 1 ? paidOnMaturity : 0),
      time: listTime[index],
      earn: index === 0 ? 0 : totalReward / estTimeToMonth + (index === listLabel.length - 1 ? paidOnMaturity : 0),
    };
  });
  return listData;
};

export const setIsClickNewCompetition = (type: string) => {
  const competitions = JSON.parse(localStorage.getItem('has_new_competition')) || [];
  const clickData = JSON.parse(localStorage.getItem('competition_clicked')) || [];
  const competitionClicks = competitions.find((e) => e?.type === type);
  // detect click to one competition
  const isClickComp = clickData.find((e) => e?.type === type);
  if (!isClickComp && competitionClicks) {
    clickData.push(competitionClicks);
  } else {
    clickData.map((e) => {
      if (e?.type === type && e?.id !== competitionClicks?.id) {
        e.id = competitionClicks.id;
      }
      return e;
    });
  }
  localStorage.setItem('competition_clicked', JSON.stringify(clickData));
};

export const getIsClickToNewCompetition = (type: string) => {
  const competitions = JSON.parse(localStorage.getItem('has_new_competition')) || [];
  const clickData = JSON.parse(localStorage.getItem('competition_clicked')) || [];
  const newCompetition = competitions.find((e) => e?.type === type);
  if (!newCompetition) {
    return false;
  }
  const isClick = clickData.find((e) => {
    if (e?.type === type && e?.id === newCompetition.id) {
      return e;
    }
  });
  return !isClick;
};

export const getCalculateAmount = (firstValue: string | number, secondValue: string | number, type?: boolean) => {
  if (firstValue !== '' && secondValue !== '') {
    if (type) {
      return (Number(firstValue) * Number(secondValue)).toString();
    }
    return (Number(firstValue) / Number(secondValue || 1)).toString();
  }
};

export const getCalculatePercent = (firstValue: string | number, secondValue: string | number, type?: string) => {
  switch (type) {
    case TypeOfInputConfig.QUANTITY_SL: {
      const data = ((Number(firstValue) - Number(secondValue)) / Number(firstValue)) * 100;
      return data.toString();
    }
    case TypeOfInputConfig.QUANTITY_TP: {
      const data = ((Number(firstValue) - Number(secondValue)) / Number(secondValue)) * 100;
      return data.toString();
    }
    case TypeOfInputConfig.GAIN: {
      const data = (Number(secondValue) * Number(firstValue)) / 100 + Number(firstValue);
      return data.toString();
    }
    case TypeOfInputConfig.LOSS: {
      const data = ((100 - Number(secondValue)) / 100) * Number(firstValue);
      return data.toString();
    }
    default: {
      return '';
    }
  }
};

export const getBondAbiByType = (type: string) => {
  if (type !== 'normal') {
    return bondCommitABI;
  } else {
    return bondABI;
  }
};

const tokenServerToTokenInfo = (token): TokenInfo => {
  return {
    ...token,
    address: token.address,
    decimals: Number(token.decimals),
    chainId: 56,
    logoURI: token.logoURI,
  };
};

export const getWrappedTokenBySymbolOrAddress = (symbolOrAddress: string) => {
  // const displaySymbol = symbol == 'BNB' ? 'WBNB' : symbol;
  if (symbolOrAddress?.toUpperCase() === 'BNB') {
    return ETHER;
  }
  const chainId = process.env.REACT_APP_CHAIN_ID;
  const tokens = customListDefaultToken();
  const tokenList = tokens['tokens'];
  const tokenSelected = tokenList.find(
    (e) =>
      (e.symbol?.toLowerCase() === symbolOrAddress?.toLowerCase() ||
        e?.contract_address?.toLowerCase() === symbolOrAddress.toLowerCase()) &&
      e.chainId == Number(chainId),
  );
  const wrappedToken = tokenSelected && new WrappedTokenInfo(tokenServerToTokenInfo(tokenSelected), []);
  return wrappedToken;
};

export const getDisplayUrlFromSymbol = (path, token: TokenInfo): string => {
  const displayUrl = token.symbol == path ? token.symbol : token.address;
  return displayUrl;
};

export const customListDefaultToken = () => {
  const tokenImported = JSON.parse(localStorage.getItem('token_imported')) || [];
  const defaultObj = DEFAULT_LIST;
  const newListToken =
    tokenImported && tokenImported.length ? defaultObj.tokens.concat(tokenImported) : defaultObj.tokens;
  const customObj = {
    ...defaultObj,
    tokens: _.uniqBy(newListToken, (o: any) => o.address?.toLowerCase()),
  };
  return customObj;
};

export const getListDefaultToken = () => {
  return DEFAULT_LIST.tokens;
};

export const handleImportToken = (tokenList: any[]) => {
  const tokenImported = JSON.parse(localStorage.getItem('token_imported')) || [];
  // need filter same object in array
  const isExistsToken = _.differenceBy(tokenList, tokenImported, 'contract_address');
  const newTokenList = isExistsToken.length && tokenImported ? tokenImported.concat(tokenList) : tokenImported;
  const uniqList = _.uniqBy(newTokenList, (o: any) => o.address?.toLowerCase());
  localStorage.setItem('token_imported', JSON.stringify(uniqList));
};

export const filterUrlIsSymbol = (symbol: string, defaultReturn: string) => {
  const defaultList = customListDefaultToken().tokens;

  const isExist = defaultList.find((e) => e.symbol?.toLowerCase() == symbol?.toLowerCase());
  if (isExist || symbol?.toLowerCase() == 'bnb') {
    return symbol;
  } else {
    return defaultReturn;
  }
};

export const isExistsTokenSearch = (list, keyword) => {
  let isExist = false;
  list.forEach((e) => {
    if (
      e.symbol?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
      e?.name?.toLowerCase()?.includes(keyword?.toLowerCase()) ||
      e?.address?.toLowerCase()?.includes(keyword?.toLowerCase())
    ) {
      isExist = true;
      return true;
    }
  });
  return isExist;
};

export const getNewPairUrl = (currentPairUrl, newSymbol, type) => {
  const splitPairUrl = currentPairUrl && currentPairUrl.split('/');
  if (type == 'to') {
    return currentPairUrl.replace(splitPairUrl[4], newSymbol);
  }
  return currentPairUrl.replace(splitPairUrl[3], newSymbol);
};

export const formatSearchCurrency = (currency) => {
  return currency.replaceAll(' ', '');
};

export const deleteUndefinedOrNullOrEmptyStringObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key] === 0) {
      delete obj[key];
    }
  });
};

export const checkAmountNumber = (val: string | number, format?: number) => {
  if (Number(val) !== 0) {
    return new BigNumber(val).toFixed(format || 3, BigNumber.ROUND_DOWN);
  }
  return '';
};

export const getPoolBlockInfo = (pool: Pool, currentBlock: number) => {
  const { startBlock, endBlock, isFinished } = pool || {};
  const shouldShowBlockCountdown = Boolean(!isFinished && startBlock && endBlock);
  const blocksUntilStart = Math.max(startBlock - currentBlock, 0);
  const blocksRemaining = Math.max(endBlock - currentBlock, 0);
  const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0;
  const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart;
  return { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay };
};

export const hexToRgb = (hex: string, a?: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return Boolean(a)
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}, ${a})`
    : `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})`;
};

export const getParamsFromPath = (baseSymbol: string, quoteSymbol: string) => {
  let params: any = {
    page: 1,
    limit: 1000,
    'disable-token-address': true,
  };
  if (baseSymbol && baseSymbol.length > 30 && quoteSymbol && quoteSymbol.length > 30) {
    // both of symbol are address
    try {
      let baseAddress = '';
      const formattedAddress = getAddress(baseSymbol);
      const isValidAddress = isAddress(formattedAddress);
      if (isValidAddress) {
        baseAddress = formattedAddress;
        let quoteAddress = '';
        const formattedQuoteAddress = getAddress(quoteSymbol);
        const isValidQuoteAddress = isAddress(formattedQuoteAddress);
        if (isValidQuoteAddress) {
          quoteAddress = formattedQuoteAddress;
          params = {
            page: 1,
            limit: 1000,
            symbol: undefined,
            address: `${baseAddress}_${quoteAddress}`,
            published: undefined,
            'disable-token-address': true,
          };
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    } catch (e) {}
  } else if (baseSymbol && baseSymbol.length > 30 && !quoteSymbol) {
    // base symbol is address
    try {
      let baseAddress = '';
      const formattedAddress = getAddress(baseSymbol);
      const isValidAddress = isAddress(formattedAddress);
      if (isValidAddress) {
        baseAddress = formattedAddress;

        params = {
          page: 1,
          limit: 100,
          symbol: undefined,
          address: baseAddress,
          published: undefined,
          'disable-token-address': true,
        };
      } else {
        return undefined;
      }
    } catch (e) {}
  } else if (baseSymbol || quoteSymbol) {
    // both of symbol are not address
    params = {
      page: 1,
      limit: 100,
      symbol: `${baseSymbol}${quoteSymbol}`,
      address: undefined,
      published: true,
      'disable-token-address': true,
    };
  }
  return params;
};

export const formatNumberWithCompactShort = (val: string | number): string => {
  const suffix = ['', 'K', 'M', 'B', 'T'];
  const MAX_6_LENGTH_NUMBER = 999999.99;
  let suffixIndex = 0;
  let res = Number(val);
  while (res > MAX_6_LENGTH_NUMBER) {
    res /= 1000;
    suffixIndex += 1;
  }
  return `${formatNumberWithNumeral(res, 2)}${suffix[suffixIndex]}`;
};

export const getNumberWithPadZero = (num: number | string) => {
  if (Number(num) < 10) {
    return `0${num}`;
  }
  return num;
};

export const getPoolId = (poolKey: string) => {
  const data = pools?.find((e) => e?.poolKey && getAddressCustom(e?.poolKey).toLowerCase() === poolKey.toLowerCase());
  return data?.id;
};
export const percentSlider = (firstValue, secondValue) => {
  let data = 0;

  if (firstValue && secondValue) {
    data = (Number(firstValue) / (Number(firstValue) + Number(secondValue))) * 100;
  }

  return data;
};

export const getStatusOfProposal = (proposalDetailData: IDetailProposal) => {
  let statusProposals = null;
  if (proposalDetailData?.states) {
    const lastest = proposalDetailData?.states && proposalDetailData?.states[proposalDetailData?.states?.length - 1];
    if (lastest?.state === 'active') {
      const exist = proposalDetailData?.states.find((item: any) => {
        if (item?.end_time && item?.start_time) {
          return moment().isBetween(
            moment(Number(item?.start_time * 1000)),
            moment(Number(item?.end_time) * 1000),
            'millisecond',
          );
        }
        return false;
      });
      if (exist) {
        statusProposals = exist;
      } else {
        statusProposals =
          proposalDetailData?.states && proposalDetailData?.states[proposalDetailData?.states?.length - 1];
      }
    } else {
      statusProposals =
        proposalDetailData?.states && proposalDetailData?.states[proposalDetailData?.states?.length - 1];
    }
  }

  return statusProposals;
};

export const getStatusByTimeInProposalDetail = (states: IState[]) => {
  let statusProposalsList = [];
  if (states && states.length > 0) {
    let newArray = Array.from(states) as any;
    newArray = uniqBy(newArray, 'state');
    newArray = newArray.sort((a, b) => {
      return a.start_time - b.start_time;
    });
    const existCancel = newArray.findIndex((z) => z?.state === 'canceled');
    if (existCancel !== -1) {
      statusProposalsList = newArray.slice(0, existCancel + 1);
    } else {
      statusProposalsList = newArray;
    }
  }

  return statusProposalsList;
};

export const getValueOfObjByString = (o: any, s: string): any => {
  try {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < a.length; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    // eslint-disable-next-line consistent-return
    return o ?? '';
  } catch (e) {
    return '';
  }
};

export const getBnbWhenWbnb = (symbolOrAddress: string) => {
  if (
    symbolOrAddress.toLowerCase() === 'bnb' ||
    symbolOrAddress.toLowerCase() === 'wbnb' ||
    symbolOrAddress.toLowerCase() === getAddressCustom(tokens.wbnb.address).toLowerCase()
  ) {
    return 'bnb';
  }
  return symbolOrAddress;
};

export const serializeEtherToBigNumber = (amount: any) => {
  return new BigNumber(amount._hex || 0).toJSON();
};

export const formatTicketNumberToCallContract = (num: number | string) => {
  const reverseNumber = num.toString().split('').reverse().join('');
  return Number(`1${reverseNumber}`);
};

export const formatTicketNumberToUse = (ticketId: number | string) => {
  const ticketSplitList = ticketId.toString().split('');
  ticketSplitList.shift();
  const displayTicket = ticketSplitList.reverse().join('');
  return displayTicket;
};

let a = 0;
let b = 0;
export const dragElement = (elmnt: any) => {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;
  if (document.getElementById(`${`${elmnt.id}header`}`)) {
    document.getElementById(`${elmnt.id}header`).onmousedown = dragMouseDown;
  } else {
    elmnt.onmousedown = dragMouseDown;
  }
  elmnt.addEventListener('click', (e) => {
    if (a === b) {
      return 0;
    } else {
      e.stopPropagation();
      b = a;
    }
  });

  function dragMouseDown(e: any) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: any) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = `${elmnt.offsetTop - pos2}px`;
    elmnt.style.left = `${elmnt.offsetLeft - pos1}px`;
    a += 1;
  }

  function closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;
  }
};

export const isDesktopDevice = () => {
  const navigatorAgent =
    // @ts-ignore
    navigator.userAgent || navigator.vendor || window.opera;
  return !(
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      navigatorAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      navigatorAgent.substr(0, 4),
    )
  );
};

export const isMobileDevice = () => {
  const navigatorAgent =
    // @ts-ignore
    navigator.userAgent || navigator.vendor || window.opera;
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      navigatorAgent,
    )
  ) {
    return true;
  }
  return false;
};

export const range = ({ from = 0, to, step = 1, length = Math.ceil((to - from) / step) }) =>
  Array.from({ length }, (_, i) => from + i * step);

export function encrypt(plainText) {
  const iv = crypto.randomBytes(BLOCK_SIZE);
  const cipher = crypto.createCipheriv(ALGORITHM, CIPHER_KEY, iv);
  let cipherText;
  try {
    cipherText = cipher.update(plainText, 'utf8', 'hex');
    cipherText += cipher.final('hex');
    cipherText = iv.toString('hex') + cipherText;
  } catch (e) {
    cipherText = null;
  }
  return cipherText;
}

export const formatNumberDecimal = (val: string | number) => {
  const newNumber = Number(val);
  if (newNumber < 1000) {
    return formatNumberWithNumeral(newNumber, 4);
  } else if (newNumber >= 1000 && newNumber <= 10000) {
    return formatNumberWithNumeral(newNumber, 3);
  } else if (newNumber > 10000 && newNumber < 99999) {
    return formatNumberWithNumeral(newNumber, 2);
  } else if (newNumber >= 99999 && newNumber < 1000000) {
    return formatNumberWithNumeral(newNumber, 1);
  } else if (newNumber >= 1000000) {
    return formatNumberWithNumeral(newNumber, 0);
  } else {
    return '0.000';
  }
};

export const calculatorTotalEarningBondLending = (bondAmount: number, faceValue: number, issuePrice: number) => {
  return bondAmount * faceValue - bondAmount * issuePrice ?? 0;
};

const contextAbi = require.context('../configs/abi', true, /\.json$/);

export const getAbiContract = (abiName: string) => {
  let abiContract = null;

  contextAbi.keys().forEach((link: string, index: number) => {
    const linkArr = link.split('/');
    const abiNameRaw = linkArr[1].replace('.json', '');
    if (abiNameRaw === abiName) {
      abiContract = contextAbi(contextAbi.keys()[index]);
    }
  });

  return abiContract;
};

export const checkMath = (val) => {
  const newValue = Number(val);
  if (0 > newValue) {
    return '-';
  } else if (0 === newValue) {
    return '';
  } else {
    return '+';
  }
};

export const formatEtherByBN = (num: string | number): string => {
  const bn = new BigNumber(num);
  return bn.div(new BigNumber(1e18)).toFixed();
};
