import axios from 'axios';

const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
  timeout: 10000,
});

export const fetchDataBuyingCompetition = async () => {
  try {
    return axiosBase({
      url: '/competition?compType=buying',
      method: 'GET',
    }).then((res) => res.data?.data);
  } catch (err) {
    return undefined;
  }
};

export const fetchDataReferralRankingList = async () => {
  try {
    return axiosBase({
      url: '/competition/buying/ranking',
      method: 'GET',
    }).then((res) => res.data?.data);
  } catch (err) {
    return undefined;
  }
};

export const getBuyingCompetitionUserData = async (competitionId: string | number, address: string) => {
  try {
    let scoreResponse = 0;
    let totalPurchaseResponse = 0;
    let totalSoldResponse = 0;
    let posiChange = 0;
    let percentChange = 0;

    try {
      scoreResponse = await axiosBase({
        url: `/competition/buying/total-buying-amount?competition-id=${competitionId}&address=${address}`,
        method: 'GET',
      }).then((res) => res.data?.data);
    } catch (err) {}
    try {
      totalPurchaseResponse = await axiosBase({
        url: `/competition/buying/total-purchased?competition-id=${competitionId}&address=${address}`,
        method: 'GET',
      }).then((res) => res.data?.data);
    } catch (err) {}
    try {
      totalSoldResponse = await axiosBase({
        url: `/competition/buying/total-sold?competition-id=${competitionId}&address=${address}`,
        method: 'GET',
      }).then((res) => res.data?.data);
    } catch (err) {}
    try {
      const res = (await axiosBase({
        url: `/competition/buying/balance-change?competition-id=${competitionId}&address=${address}`,
        method: 'GET',
      }).then((res) => res.data?.data)) as unknown as { posi_amount: string; percent: string };
      if (totalSoldResponse) {
        posiChange = Number(res?.posi_amount || 0);
        percentChange = Number(res?.percent || 0);
      }
    } catch (err) {}

    return {
      score: scoreResponse,
      totalPurchase: totalPurchaseResponse,
      totalSold: totalSoldResponse,
      posiChange,
      percentChange,
    };
  } catch (err) {
    return undefined;
  }
};

export const fetchDataWithdrawalHistory = async (account: string) => {
  try {
    const response = await axiosBase({
      method: 'GET',
      baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
      url: `/competition/withdrawHistory?address=${account}`,
    }).then((res) => res.data?.data);
    return response;
  } catch (e: any) {
    return [];
  }
};

export const fetchDataCompetitionBalance = async (account: string) => {
  try {
    const response = await axiosBase({
      method: 'GET',
      baseURL: process.env.REACT_APP_COMPETITION_SERVICE_BASE_URL,
      url: `/competition/totalReward?address=${account}`,
    }).then((res) => res.data?.data);
    return response;
  } catch (e: any) {
    return 0;
  }
};

export const fetchAllCompetition = async () => {
  try {
    return axiosBase({
      url: '/competition?compType=all',
      method: 'GET',
    }).then((res) => res.data?.data);
  } catch (err) {
    return undefined;
  }
};
