import React from 'react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  Text,
  Flex,
  Modal,
  ModalContent,
  ModalCloseButton,
  Box,
  ModalBody,
  ModalOverlay,
} from '@chakra-ui/react';
import CopyToClipboard from './CopyToClipboard';
import { connectorLocalStorageKey } from './config';
import { getBscScanLink } from '../../utils';
interface Props {
  account: string;
  logout: () => void;
  onDismiss?: () => void;
}

const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null }) => (
  <Modal onClose={onDismiss} isOpen={true} size="xl" isCentered blockScrollOnMount={false}>
    <ModalOverlay bg="blackAlpha.800" width="100%" height="100%" />
    <ModalContent p="0" m="20px" bg="gray.800" color="white" borderRadius="24px" px="32px" py="26px">
      <Box borderBottom="1px" borderColor="gray.700" pb="20px">
        <Text fontSize="20px" color="teal.150" fontWeight="600">
          Your Wallet
        </Text>
      </Box>
      <ModalCloseButton mr="10px" mt="18px" onClick={onDismiss} color="gray.500" />
      <ModalBody p="0px" mt="26px" mb="10px">
        <Flex h="54px" background={'gray.700'} borderRadius={'12px'} alignItems={'center'}>
          <Text
            fontSize="16px"
            whiteSpace="nowrap"
            w="450px"
            mr="10px"
            pl="24px"
            color="gray.400"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {account}
          </Text>
          <Box textAlign="end">
            <CopyToClipboard toCopy={account} />
          </Box>
        </Flex>
        <Flex mb="20px" mt="20px" fontSize="16px">
          <Flex color={'blue.400'}>
            <Box>View on BscScan</Box>
            <ExternalLinkIcon
              mt="3px"
              ml="7px"
              onClick={() => window.open( getBscScanLink(undefined, account, 'address'))}
              cursor={'pointer'}
              href={ getBscScanLink(undefined, account, 'address')}
              mr="16px"
            >
              View on BscScan{' '}
            </ExternalLinkIcon>
          </Flex>
        </Flex>
        <Flex justifyContent="center">
          <Button
            scale="sm"
            border="1px solid green.400"
            bg="teal.150"
            h="44px"
            w="100%"
            maxW="192px"
            borderRadius={'25px'}
            _hover={{
              background: 'green.400',
            }}
            fontSize="16px"
            onClick={() => {
              logout();
              window.localStorage.removeItem(connectorLocalStorageKey);
              onDismiss();
            }}
          >
            Logout
          </Button>
        </Flex>
      </ModalBody>

      {/* <Flex mb="32px">
        <ExternalLinkIcon href={`https://bscscan.com/address/${account}`} mr="16px">
          View on BscScan
        </ExternalLinkIcon>
        <CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
      </Flex> */}
    </ModalContent>
  </Modal>
);

export default AccountModal;
