import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './global/reducer';
import listsReducer from './lists/reducer';
import userReducer from './user/reducer';
import multicallReducer from './multicall/reducer';
import transactionsReducer from './transactions/reducer';
import applicationReducer from './application/reducer';
import swapReducer from './swap/reducer';
import swapPage from './swap';
import mintReducer from './mint/reducer';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { updateVersion } from './global/action';
import burnReducer from './burn/reducer';
import farmReducer from './farms';
import blockReducer from './block';
import pricesReducer from './prices';
import toastsReducer from './toasts';
import poolsReducer from './pools';
import nftReducer from './nft';
import referral from './referral';
import farmingCompetition from './farmingCompetition';
import buyingCompetition from './buyingCompetition';
import nftMarketplace from './nftMarketplace';
import governance from './governance';
import home from './home';
import vaultReducer from './vault';
import referralCompetition from './referralCompetition';
import bondDetail from './bondDetail';
import bonds from './bonds';
import bondExchange from './bondExchange';
import bondPools from './bondPools';
import bondLending from './bondLending';
import bondLaunchpad from './bondLaunchpad';
import tokens from './tokens';
import recipient from './recipient';
import lottery from './lottery';
import treasureHunt from './treasureHunt';
import bridgeAsset from './bridgeAsset';

export const history = createBrowserHistory();

const store = configureStore({
  reducer: {
    toasts: toastsReducer,
    router: connectRouter(history),
    global: globalReducer,
    swap: swapReducer,
    lists: listsReducer,
    user: userReducer,
    multicall: multicallReducer,
    application: applicationReducer,
    transactions: transactionsReducer,
    burn: burnReducer,
    mint: mintReducer,
    swapPage: swapPage,
    prices: pricesReducer,
    farms: farmReducer,
    block: blockReducer,
    pools: poolsReducer,
    nfts: nftReducer,
    referral: referral,
    home: home,
    nftMarketplace: nftMarketplace,
    governance: governance,
    referralCompetition,
    vault: vaultReducer,
    buyingCompetition,
    farmingCompetition,
    bondDetail,
    bonds,
    bondExchange,
    bondPools,
    bondLaunchpad,
    bondLending,
    tokens,
    recipient,
    lottery,
    treasureHunt,
    bridgeAsset,
  },
  // middleware: [
  //   routerMiddleware(history),
  //   thunk // for dispatching history actions
  //   // ... other middlewares ...
  // ],
});

store.dispatch(updateVersion());

export default store;

export type AppState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
