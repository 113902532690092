export const registerNoticeWithServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./../../firebase-messaging-sw.js', {
        scope: '/',
      })
      .then(function (registration) {
        // console.log('Registration successful, scope is:', registration);
        // registration.pushManager.subscribe({
        //   userVisibleOnly: true,
        // }).then(res => {
        //   console.log('res', res);
        // });
      })
      .catch(function (err) {
        // console.log('Service worker registration failed, error:', err);
      });
  }
};
