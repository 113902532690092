import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';
import { getAllowance, getERCTokenAmount, getNativeTokenAmount } from '../../utils/callHelpers';
import { getNodeUrlByChain } from '../../utils/getRpcUrl';
import adapter, { bnbChain, configNetworkByChainId } from '../init';
import { ChainTypes } from '../types';

export const getNetworkDetail = (chainType: ChainTypes) => {
  return adapter.getNetworkDetail(chainType);
};

export const getRpcUrlByChain = (chainType: ChainTypes) => {
  const { listRpc } = adapter.getNetworkDetail(chainType);
  return getNodeUrlByChain(listRpc);
};

export const getWeb3NoAccountByChain = (chainType: ChainTypes) => {
  const RPC_URL = getRpcUrlByChain(chainType);
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions);
  const web3NoAccount = new Web3(httpProvider);
  return web3NoAccount;
};

export const getTokenAmount = (chainType: ChainTypes, tokenAddress: string, isNative: boolean, account: string) => {
  const web3 = getWeb3NoAccountByChain(chainType);
  if (isNative) {
    return getNativeTokenAmount(web3, account.toLowerCase());
  }
  return getERCTokenAmount(web3, account, tokenAddress).then((res) => res);
};

export const getAllowancePerChain = (
  chainType: ChainTypes,
  account: string,
  spenderAddress: string,
  tokenAddress: string,
) => {
  const web3 = getWeb3NoAccountByChain(chainType);
  return getAllowance(web3, account, spenderAddress, tokenAddress);
};

export const getBridgeAssetAbi = (chainType: ChainTypes) => {
  return adapter.getBridgeAssetAbi(chainType);
};

export const getBridgeAssetAddress = (chainType: ChainTypes) => {
  return adapter.getBridgeAssetAddress(chainType);
};

export const bridgeAssetPerChain = async (
  chainType: ChainTypes,
  bridgeAssetContract: any,
  payableAmount: number,
  destBcId,
  recipient,
  amount,
  account,
  callbackFunc,
  gasPrice?: number,
) => {
  return await adapter.bridgeAsset(
    chainType,
    bridgeAssetContract,
    payableAmount,
    destBcId,
    recipient,
    amount,
    account,
    callbackFunc,
    gasPrice
  );
};

export const getChainIdByType = (chainType: ChainTypes) => {
  return adapter.getChainId(chainType);
};

export const getDetailNetWorkByChainId = (chainId: number) => {
  const defaultChainId = Number(process.env.REACT_APP_CHAIN_ID);
  return configNetworkByChainId[chainId || defaultChainId] || bnbChain;
};
