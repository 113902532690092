import BigNumber from 'bignumber.js';

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
}

export interface Address {
  97?: string;
  56?: string; // TODO: delete ? in mainnet
}

export interface Token {
  symbol: string;
  address?: Address;
  decimals?: number;
  projectLink?: string;
}

export interface UserReserveData {
  index?: number;
  currentStableDebt: number;
  currentVariableDebt: number;
  usageAsCollateralEnabled: boolean;
  currentATokenBalance?: number;
  principalStableDebt?: number;
  scaledVariableDebt?: number;
  stableBorrowRate?: number;
  liquidityRate?: number;
  stableRateLastUpdated?: number;
}

export interface UserDebtData {
  totalCollateralETH: number;
  totalDebtETH: number;
  availableBorrowsETH: number;
  currentLiquidationThreshold: number;
  ltv: number;
  healthFactor: number;
  aVariableDebtTokenBalances?: number;
  debtWETHAllowance?: number;
  assetPrice?: number;
}

export enum TYPE_OF_FARM {
  AUTO,
  MANUAL,
}

export interface FarmConfig {
  pid: number;
  id: number;
  type: TYPE_OF_FARM;
  name: string;
  lpAddresses: Address;
  stakingManager: Address;
  vaultManager?: Address;
  isFinished: boolean;
  harvestLockupPeriod: number;
  depositFee?: string;
  apr: number;
  token: Token;
  quoteToken?: Token;
  stakingSingleToken?: string;
  rewardPriceLabel?: string;
  formatDecimals?: number;
  harvestInterval?: number;
  pendingPositionNextCompound?: number;
  rewardForCompounder?: number;
  percentFeeForCompounding?: number | string;
  images?: string;
  abiName?: string;
  pancakePid?: string;
  aprInPancake?: number;
  earnedToken?: Token;
  isScheduleHarvest?: boolean;
  needMigration?: boolean;
}

export interface Farm extends FarmConfig {
  totalAllocPoint?: BigNumber;
  rawQuoteTokenBalance?: number;
  rawTokenBalance?: number;
  allocPoint?: BigNumber;
  nearestCompoundingTime?: number;
  userData?: {
    tokenAllowance: BigNumber;
    quoteTokenAllowance: BigNumber;
    lpAllowance: BigNumber;

    tokenBalance: number;
    quoteTokenBalance: number;
    lpBalance: number;
    lpBalanceInCake: number;

    vaultReferralCode?: string;

    lpStaked?: number;
    pendingEarned?: number;
    stakedBalance?: number;
    earnings: number;
  };
}

export interface VaultConfig {
  pid: number;
  tokenSymbol: string;
  // label for rewards in vault
  // ex. BNB vault rewardPrice should be POSI_IN_BNB
  // BUSD vault rewards should be POSI
  rewardPriceLabel: string;
  token: Token;
  vaultAddress: Address;
  pairAddress: Address;
  multiplier?: string;
  masterchef?: string;
  abiContract?: any;
  formatDecimals?: number;
  dual?: {
    rewardPerBlock: number;
    earnLabel: string;
    endBlock: number;
  };
  isFinished?: boolean;
  isEmergencyWithdraw?: boolean;
  id?: number;
  time?: number;
  link?: string;
}

export interface PoolConfig {
  pname?: string;
  sousId: number;
  earningToken: Token;
  stakingToken: Token;
  stakingLimit?: number;
  contractAddress: Address;
  poolCategory: PoolCategory;
  tokenPerBlock: string;
  sortOrder?: number;
  harvest?: boolean;
  isFinished?: boolean;
  poolImage?: string;
  isNft?: boolean;
  time?: number;
  apy?: number;
  isBond?: boolean;
}

export type Images = {
  lg: string;
  md: string;
  sm: string;
  ipfs?: string;
};

export type NftImages = {
  blur?: string;
} & Images;

export type NftVideo = {
  webm: string;
  mp4: string;
};

export type Nft = {
  name: string;
  description: string;
  images: NftImages;
  sortOrder: number;
  bunnyId: number;
  video?: NftVideo;
};

export type TeamImages = {
  alt: string;
} & Images;

export type Team = {
  id: number;
  name: string;
  description: string;
  isJoinable?: boolean;
  users: number;
  points: number;
  images: TeamImages;
  background: string;
  textColor: string;
};

/*
Example data 
"aTokenAddress": "0xaa8c9B6c1d54dAe68093CCaB0934d6eC41F82EC8",
"availableLiquidity": "100174853834320174743985",
"averageStableRate": "39339345046230127166934014",
"baseLTVasCollateral": "7500",
"borrowingEnabled": true,
"decimals": "18",
"interestRateStrategyAddress": "0xcF2b5fAaC39A333AeE2B6209C3A74f3f73E88B2b",
"isActive": true,
"isFrozen": false,
"lastUpdateTimestamp": "1620375582",
"liquidityIndex": "1000073054475863657717489251",
"liquidityRate": "368107878347254151124027",
"name": "",
"priceInEth": "1580300000000000",
"reserveFactor": "1000",
"reserveLiquidationBonus": "10500",
"reserveLiquidationThreshold": "8000",
"stableBorrowRate": "39360254684143424193155299",
"stableBorrowRateEnabled": true,
"stableDebtLastUpdateTimestamp": "1620375582",
"stableDebtTokenAddress": "0x26b6b2D8cb9aAae9077D5e527ea3C1Ae8526Df2B",
"stableRateSlope1": "20000000000000000000000000",
"stableRateSlope2": "750000000000000000000000000",
"symbol": "aDAI",
"totalPrincipalStableDebt": "1049129364927878716832",
"totalScaledVariableDebt": "415410787346244439812",
"underlyingAsset": "0xD32f54FA3D5BC2c6f93c8d4a75a7cF0AEBFb7415",
"usageAsCollateralEnabled": true,
"variableBorrowIndex": "1000250494969338399264391214",
"variableBorrowRate": "720509368286848386310599",
"variableDebtTokenAddress": "0xAe1656Db27b200Fb421f7848505C511E96aA9176",
"variableRateSlope1": "40000000000000000000000000",
"variableRateSlope2": "750000000000000000000000000"
*/
export interface LendingPoolReserveData {
  pid: string;
  aTokenAddress: string;
  availableLiquidity: string;
  averageStableRate: string;
  baseLTVasCollateral: string;
  borrowingEnabled: boolean;
  decimals: string;
  interestRateStrategyAddress: string;
  isActive: boolean;
  isFrozen: boolean;
  lastUpdateTimestamp: string;
  liquidityIndex: string;
  liquidityRate: string;
  name: string;
  price: {
    priceInEth: string;
    priceInBnb: string;
  };
  priceInEth: string;
  priceInBnb: string;
  reserveFactor: string;
  reserveLiquidationBonus: string;
  reserveLiquidationThreshold: string;
  stableBorrowRate: string;
  stableBorrowRateEnabled: boolean;
  stableDebtLastUpdateTimestamp: string;
  stableDebtTokenAddress: string;
  stableRateSlope1: string;
  stableRateSlope2: string;
  symbol: string;
  totalPrincipalStableDebt: string;
  totalScaledVariableDebt: string;
  underlyingAsset: string;
  usageAsCollateralEnabled: boolean;
  variableBorrowIndex: string;
  variableBorrowRate: string;
  variableDebtTokenAddress: string;
  variableRateSlope1: string;
  variableRateSlope2: string;
}

/* Example data
"principalStableDebt": "0",
"scaledATokenBalance": "999927488937249033",
"scaledVariableDebt": "99975130070055583878",
"stableBorrowLastUpdateTimestamp": "0",
"stableBorrowRate": "0",
"underlyingAsset": "0xD32f54FA3D5BC2c6f93c8d4a75a7cF0AEBFb7415",
"usageAsCollateralEnabledOnUser": true
 */
export interface LendingPoolUserReserveData {
  pid: string;
  principalStableDebt: string;
  scaledATokenBalance: string;
  scaledVariableDebt: string;
  stableBorrowLastUpdateTimestamp: string;
  stableBorrowRate: string;
  underlyingAsset: string;
  usageAsCollateralEnabledOnUser: boolean;
  underlyingBalance: string;
  underlyingBalanceBNB: string;
  underlyingBalanceUSD: string;
  variableBorrowIndex: string;
  variableBorrows: string;
  variableBorrowsBNB: string;
  variableBorrowsUSD: string;
  stableBorrows: string;
  stableBorrowsBNB: string;
  stableBorrowsUSD: string;
  totalBorrows: string;
  totalBorrowsBNB: string;
  totalBorrowsUSD: string;
}

/**
 * @dev Returns the user account data across all the reserves
 * @param user The address of the user
 * @return totalCollateralETH the total collateral in ETH of the user
 * @return totalDebtETH the total debt in ETH of the user
 * @return availableBorrowsETH the borrowing power left of the user
 * @return currentLiquidationThreshold the liquidation threshold of the user
 * @return ltv the loan to value of the user
 * @return healthFactor the current health factor of the user
 * */

export interface LendingPoolUserAccountData {
  totalCollateralETH: string;
  totalCollateralUSD: string;
  totalDebtETH: string;
  totalDebtUSD: string;
  availableBorrowsETH: string;
  availableBorrowsUSD: string;
  currentLiquidationThreshold: string;
  ltv: string;
  healthFactor: string;
}

export interface TokenBalance {
  [address: string]: string;
}

export interface ItemCardOfWhyBonds {
  title: string;
  subTitle: string;
  description: string;
  imgUrl: string;
}
