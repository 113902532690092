// import { Toast } from 'galaxyf_uikit'
import BigNumber from 'bignumber.js';
import { ReactNode } from 'react';
import { LiquidityStakeStatus } from '../configs/constants/bondExchange';
import { CommunityProposal } from '../configs/constants/governance';
import { BondStatus, IBondDetail } from '../configs/constants/type';
import {
  Address,
  Farm,
  LendingPoolReserveData,
  LendingPoolUserAccountData,
  LendingPoolUserReserveData,
  Nft,
  Team,
  TokenBalance,
  VaultConfig,
} from '../configs/constants/types';
import { PoolConfig } from '../configs/types';

export const types = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
};

export type Types = typeof types[keyof typeof types];

export interface Toast {
  id: string;
  type: Types;
  title: string;
  description?: ReactNode;
}

export interface ToastContainerProps {
  toasts: Toast[];
  stackSpacing?: number;
  ttl?: number;
  // eslint-disable-next-line no-unused-vars
  onRemove: (id: string) => void;
}

export interface ToastProps {
  toast: Toast;
  onRemove: ToastContainerProps['onRemove'];
  ttl: number;
  style: Partial<CSSStyleDeclaration>;
}

export type TranslatableText =
  | string
  | {
      id: number;
      fallback: string;
      data?: {
        [key: string]: string | number;
      };
    };

export interface Vault extends VaultConfig {
  tokenAmount?: BigNumber;
  vaultWeight?: BigNumber;
  totalSupply?: number;
  allocPoint?: BigNumber;
  positionPerBlock?: BigNumber;
  apr?: number;
  lpTotalInQuoteToken?: BigNumber;
  poolWeight?: BigNumber;
  harvestInterval?: number;
  posiPerBlock?: BigNumber;
  nearestCompoundingTime?: number;
  rewardForCompounder?: number;
  pendingPositionNextCompound?: number;
  percentFeeForCompounding?: number;
  amountVaultInStaking?: number;
  totalPosiStaked?: number;
  userData?: {
    allowance: BigNumber;
    pendingEarned: number;
    stakedBalance: number;
    earned: number;
    tokenBalance: number;
    farmLpBalance: number;
    allowanceLpFarm: number;
    vaultReferralCode?: string;
    totalLpStaked?: number;
    busdBalance?: number;
    posiBalance?: number;
    allowancePosi?: number;
  };
}

export interface Pool extends PoolConfig {
  totalStaked?: BigNumber;
  startBlock?: number;
  endBlock?: number;
  bond?: any;
  userData?: {
    allowance: BigNumber;
    stakingTokenBalance: BigNumber;
    stakedBalance: BigNumber;
    pendingReward: BigNumber;
    listCardRemaining: any[];
    listCardStaked: any[];
  };
}

export interface Profile {
  userId: number;
  points: number;
  teamId: number;
  nftAddress: string;
  tokenId: number;
  isActive: boolean;
  username: string;
  nft?: Nft;
  team: Team;
  hasRegistered: boolean;
}

// Slices states

// export interface ToastsState {
//   data: Toast[]
// }

/*
 * Parsed balance in string mean balance has dividedBy 1e18 and in string format
 * */

export interface LendingPoolsState {
  reservesData: LendingPoolReserveData[];
  // user reserves data
  userReservesData: LendingPoolUserReserveData[];
  ethUsdPrice: string;
  bnbUsdPrice: string;
  assetPricesInBNB: string[];
  // aTokenAddress => parsed balance in string
  aTokenBalance: TokenBalance;
  // stableDebtTokenAddress => parsed balance in string
  stableDebtTokenBalance: TokenBalance;
  // variableDebtTokenAddress => parsed balance in string
  variableDebtTokenBalance: TokenBalance;
  // tokenAddress => parsed allowance amount in string
  tokenAllownace: TokenBalance;
  // underlyingAssetAddress => parsed balance in string
  walletBalance: TokenBalance;
  // user account data got from `getUserAccountData`
  userAccountData: LendingPoolUserAccountData;
}

export interface WalletState {
  balance: BigNumber;
}

export interface FarmsState {
  data: Farm[];
  maxApr?: any;
  forceRefresh: number;
  myReferralCode: any;
}

export interface VaultState {
  data: Vault[];
  maxApr?: any;
  myReferralCode: any;
}

export interface PoolsState {
  data: Pool[];
  history: History[];
}

export interface History {
  action: string;
  address: string;
  amount: string;
  hash: string;
  time: any;
}

export interface ProfileState {
  isInitialized: boolean;
  isLoading: boolean;
  hasRegistered: boolean;
  data: any;
}

export type TeamResponse = {
  0: string;
  1: string;
  2: string;
  3: string;
  4: boolean;
};

export type TeamsById = {
  [key: string]: Team;
};

export interface TeamsState {
  isInitialized: boolean;
  isLoading: boolean;
  data: TeamsById;
}

// API Price State
export interface PriceList {
  [key: string]: number;
}

export interface PriceApiResponse {
  /* eslint-disable camelcase */
  update_at: string;
  prices: PriceList;
}

export interface PriceFeedKeyResponse {
  data: PriceFeedKey[];
}

export interface PriceFeedKey {
  id: number;
  address: string;
  symbol: string;
  feed_key: string;
}

export interface PriceState {
  isLoading?: boolean;
  lastUpdated?: string;
  data?: PriceList;
  posiPerBlock?: number;
  priceFeedKey?: Record<string, string>;
}

// Block

export interface BlockState {
  currentBlock: number;
  initialBlock: number;
}

// Global state

export interface State {
  farms: FarmsState;
  // toasts: ToastsState
  prices: PriceState;
  pools: PoolsState;
  profile: ProfileState;
  teams: TeamsState;
  block: BlockState;
  lendingPool: LendingPoolsState;
  wallet: WalletState;
  vault: VaultState;
}

// deposit
export interface Deposit {
  value: any;
}

// borrow
export interface Borrow {
  value: any;
  type: any;
}

//  withdraw
export interface Withdraw {
  value: any;
}

//  repay
export interface Repay {
  value: any;
}

export type AssetAmount = {
  inBNB: number | BigNumber;
  inUSD: number | BigNumber;
  raw: number | BigNumber;
  rate: number | BigNumber;
};

export interface MyNft {
  name?: any;
  grade?: number;
  parValue?: any;
  miningPower?: number;
  time?: any;
  action?: any;
  image?: string;
  id?: string | number;
}

export interface NftCastedHistory {
  name?: any;
  grade?: number;
  parValue?: any;
  miningPower?: number;
  time?: any;
  action?: any;
  image?: string;
  id?: string | number;
}

export interface PosiNFT {
  id: string;
  grade: string;
  quality: string;
  amount: string;
  resBaseId: string;
  tLevel: string;
  ruleId: string;
  nftType: string;
  author: string;
  erc20: string;
  createdTime: string;
  blockNum: string;
}

export interface NftState {
  myNftData: MyNft[];
  nftCastedHistoryData: NftCastedHistory[];
  userData?: {
    allowance: any[];
    nfts?: PosiNFT[];
    tokenBalances: string;
  };
  nftStaked: any[];
  nftsBirthday: PosiNFT;
}

export interface DataReferral {
  time?: any;
  walletAdress?: string;
  amount: string | number;
  id?: string | number;
  farmsRewards?: string | number;
  stakedInPools?: string | number;
  totalEarned?: string | number;
}

export interface Summary {
  today: {
    sofar?: string | number;
    vsYesterDay?: string | number;
  };
  week: {
    sofar?: string | number;
    vsLastWeek?: string | number;
  };
  month: {
    sofar?: string | number;
    vsLastMonth?: string | number;
  };
}

export interface BonusItem {
  txid: string;
  amount: number;
  type: 'farm' | 'pool' | 'withdraw';
  createdAt: any;
}

export interface BonusResponse {
  rows: BonusItem[];
  count: number;
  totalPage: number;
}

export interface ReferralState {
  farmData: DataReferral[];
  poolData: DataReferral[];
  referralData: DataReferral[];
  bonusData: BonusResponse | null;
  summaryData: any;
  generalData: any;
  isLoadingRequest: boolean;
  showDetail: string;
}

export interface DataMarketplaceHistory {
  Nft?: any;
  Price: string | number;
  Time?: string;
  Status?: string;
  Operate?: string;
}

export interface DataAuctionHistory {
  Nft?: any;
  StartPrice: string | number;
  LastedPrice?: string;
  Status?: string;
  Profit: string;
  Operate?: string;
}

export interface AutionDetail {
  endingAt: number | string;
  lastBidder: string;
  lastPrice: number | BigNumber;
  nft: string;
  raisedAmount: number | BigNumber;
  seller: string;
  startedAt: number | string;
  status: string;
  tokenId: string;
}

export interface MarketDetail {
  isSold: boolean;
  nft: string;
  price: number | BigNumber;
  purchaser: string;
  seller: string;
  tokenId: string;
}

export interface MarketplaceHistoryState {
  MarketplaceHistoryData: DataMarketplaceHistory[];
  AuctionHistoryData: DataAuctionHistory[];
  filterGrade: string;
  filterPrice: string;
  searchQuery: string;
  auctionDetail: any;
  marketDetail: any;
  nftDetail: any;
  userData: any;
  auctionList: any;
  marketList: any;
  bidList: any;
  error: boolean;
  loading: boolean;
  myAuctionHistory: any;
  myMarketHistory: any;
  loadingBid: boolean;
}

export interface CommunityGovernanceState {
  title: string;
  description: string;
  options: {
    id: number;
    content: string;
    isDelete: boolean;
    isError: boolean;
    errorMsg: string;
  }[];
  startDate: null;
  endDate: null;
  blockNumber: number;
  isConfirm: boolean;
}

export interface ListVoteGovernance {
  proposalsDetailData: IDetailProposal;
  voteForList: IProposalVoteReceipts[];
  voteAgainstList: IProposalVoteReceipts[];
  proposalsData: any;
  isLoadingRequest: boolean;
  spaceData: ISpace;
  // community governance list
  communityGovernanceList: {
    active: CommunityProposal[];
    pending: CommunityProposal[];
    closed: CommunityProposal[];
  };
  newCommunityGovernanceState: CommunityGovernanceState;
}

export interface HistoryProposal {
  id: string;
  date: string;
  title: string;
  hash: string;
}

export interface ListVoteGovernance {
  listHistory: HistoryProposal[];
}

export interface DetailContent {
  id: string;
  title: string;
}

export interface ListVoteGovernance {
  contentDetail: DetailContent[];
}

export interface ListReward {
  reward: string;
}

export interface ItemListRanking {
  place: number;
  address: string;
  total_earned: number | string;
  reward: any;
}

export interface WithdrawalTx {
  txId: string;
  amount: string;
  time: string;
}

export interface ReferralCompetitionState {
  rewardData?: number;
  yourRanking: string;
  referralLink: string;
  referralEarned: ReferralEarned[];
  competitionOverview?: any;
  currentCompetition: any | null;
  listOfCompetition: any[];
  listRanking: ItemListRanking[];
  balance: number;
  history: WithdrawalTx[];
}

export interface IPairItem {
  symbol: string;
  total_usd_volume: string;
  total_lp_volume: string;
}

export interface FarmingCompetitionState {
  totalUsdVolume: number;
  listPair: IPairItem[];
  currentCompetition: any | null;
  listRanking: ItemListRanking[];
  competitionOverview: any;
  competitionBalance: number | string;
  withdrawHistory: any[];
}

export interface AmountPosiPurchased {
  posi: string;
  usd: string;
  raisePosi: string;
  percent: string;
}

export interface TotalPosi {
  purchased: string | number;
  sold: string | number;
  score: number | string;
  posiChange: number;
  percentChange: number;
}

export interface BuyingCompetitionState {
  currentCompetition: any | null;
  listRanking: ItemListRanking[];
  totalPosi: TotalPosi;
  competitionOverview: any;
  competitionBalance: number | string;
  withdrawHistory: any[];
  allCompetition: any[];
}

export interface ReferralEarned {
  users: string | number;
  usersInDay: string | number;
}

export enum CompetitionStatus {
  active = 'active',
  coming_soon = 'coming_soon',
  ended = 'ended',
}

export enum DetailRenderModalInfo {
  default = 'default',
  locked_balance = 'locked_balance',
  locked_harvest = 'locked_harvest',
}

interface CommitInfo {
  distributeBalance?: number;
  amountCommitted?: string;
  distributionAmount?: string;
}

export interface Bond {
  id: number;
  type: 'normal' | 'sale-commit' | 'launchpad' | 'lending';
  status: BondStatus;
  assetId: string;
  description: string;
  issuer: string;
  couponRate: string;
  underlyingAsset: {
    name: string;
    address: string;
    symbol: string;
    type: number;
  };
  faceAsset: {
    name: string;
    address: string;
    symbol: string;
    type: number;
  };
  issuePrice: string;
  lastPrice: string;
  pendingDate: number;
  onSaleDate?: number;
  activeDate: number;
  maturityDate: number;
  liquidatedDate: number;
  cancelDate: number;
  est_apr: string;
  faceValue: string;
  supply: string;
  apr: string;
  isERC20: boolean;
  saleRule: string[];
  collateralAmount: number;
  bondAddress: string;
  sousId: number;
  symbol: string;
  distributedDate?: number;
  committedDate?: number;
  calculationDate?: number;
  commitInfo?: CommitInfo;
  soldAmount: number;
  sale_rules_issuer?: string;
  sale_rules_holder?: string;
  liquidationPrice?: number;
}

export interface BondDetailState {
  currentBond: Bond | null;
  listTransaction: BondTransactionResponse | null;
  availableUnit?: number;
  stakedUnit?: number;
  underlyingAssetStatus?: number;
  isApprove?: boolean;
  apr?: number;
  ytm?: number;
  liquidationTime?: number;
  isLoadingDataChart: boolean;
  loading: boolean;
}

export interface BondTransactionResponse {
  current_page: number;
  total_page: number;
  total_transactions: number;
  transaction_per_page: number;
  items: BondTransaction[];
}

export interface BondTransaction {
  transaction_id: string;
  type: string;
  from: string;
  to: string;
  amount: number;
  time: number; // timestamp in seconds
}

export interface ListBond {
  listBond: IBondDetail[];
  isModal: any;
  totalPage: number;
  isLoading: boolean;
  totalBond: number;
  bondAmount: string;
  payAmount: string;
}

export const TYPE_COMPETITIONS = ['buying', 'farming', 'referral'];

export interface TokenExchange {
  symbol: string;
  name: string;
  contract_address: string;
  logo_url?: string;
  url?: string;
}

export enum TypeOfPairExchange {
  BOND = 'bond',
  STABLE = 'stable',
}

export interface PairExchange {
  quote: TokenExchange;
  base: TokenExchange;
  spot_manager_address: string;
  pair_symbol: string;
  price: number;
  basis_point: number;
  change_24h: number;
  volume_24h: number;
  max_range: number;
  quote_asset_precision: number;
  base_asset_precision: number;
  quote_volume_24h: number;
  base_volume_24h: number;
  spot_house_address?: string;
  liquidity_pool_address: string;
  isHidden?: boolean;
  is_published?: boolean;
  type?: TypeOfPairExchange;
  started_at?: number;
  disabled_trading_time?: number;
}

export interface ObItem {
  price: string;
  size: number;
  totalSize: number;
}

export interface OrderBook {
  data: ObItem[][];
  priceInterval: number;
  maxSize: number;
  totalRows: number;
  symbol: string;
}

export interface FeeTier {
  [tier: number]: number;
}

export interface OpenOrder {
  side: 'buy' | 'sell';
  size: number;
  filled: number;
  pip: number;
  price: number;
  blockNumber: number;
  orderId: number;
  orderIdOfTrader: number;
  quote: string;
  base: string;
  type: TypeOfPairExchange;
  positionManager: string;
}

export interface LiquidityItem {
  nftId: string;
  pid: number;
  poolId: string;
  rewardPoolAddress: string;
  quoteSymbol: string;
  baseSymbol: string;
  apr: string;
  aprReward: string;
  totalInvestment: string;
  totalEarnings: string;
  stakedStatus: LiquidityStakeStatus;
  quoteAmount: number;
  baseAmount: number;
  quoteDeposited: number;
  claimableBase: number;
  claimableQuote: number;
  totalClaimableInQuote: number;
}

interface ItemOrder {
  id: number;
  timestamp: number;
  base_symbol: string;
  quote_symbol: string;
  pair_symbol: string;
  type: string;
  side: string;
  amount: string;
  quote_price: string;
  filled: string;
  txn_hash: string;
  status: string;
  quote_usd_amount: string;
  avg_price: string;
  filled_amount: string;
}

export interface OrderExchange {
  orders: ItemOrder[];
  current_page: number;
  per_page: number;
  page_count: number;
  item_count: number;
}

export interface PairDetailExchange {
  base: TokenExchange;
  quote: TokenExchange;
  pair_symbol: string;
  spot_manager_address: string;
  min_order_size: number;
  min_price_increment: number;
  min_size_increment: number;
  price: number;
  change_24h: number;
  quote_volume_24h: number;
  base_volume_24h: number;
  basis_point: number;
  expired_at: string;
  base_asset_precision: number;
  quote_asset_precision: number;
  description: string;
  type: TypeOfPairExchange;
}

export enum PriceSide {
  BALANCE = 'BALANCE',
  UP = 'UP',
  DOWN = 'DOWN',
}

export interface BondExchangeState {
  currentPair: PairExchange;
  currentBond: Bond | null;
  currentPrice: {
    price: number;
    type: PriceSide;
  };
  tradeType: string;
  orderType: string;
  price: string;
  quantityBase: string | number;
  quantityQuote: string | number;
  marketTradeList: any[];
  quantityTP: string;
  quantitySL: string;
  percentEarned: string;
  percentLoss: string;
  orderBook: OrderBook;
  orderBookTemp: OrderBook;
  openOrders: OpenOrder[];
  myLiquidity: LiquidityItem[];
  openPositionManagers: Array<string>;
  hideOtherSymbols: boolean;
  listOfPair: PairExchange[];
  spotHouseAddress: string;
  spotFundingAddress: string;
  exchangeLiquidityPoolAddress: string;
  newExchangeLiquidityPoolAddress: string;
  liquidityNftManagerAddress: string;
  balance: {
    base: number;
    quote: number;
  };
  listOrderHistory: OrderExchange;
  listTradeHistory: OrderExchange;
  pairDetailData: PairDetailExchange;
  balanceClaim: {
    base: number;
    quote: number;
  };
  filterPairSymbol: string;
  filterSide: string;
  filterType: string;
  filterTime: {
    startTime: number;
    endTime: number;
  };
  poolsExchange: ILiquidityPoolDetail[];
  feeRatio: FeeTier;
  transactionHistoryList: ItemTrasnactionHistory;
  hidenOtherLiquidity: boolean;
}

export interface BondLaunchpad {
  statisticData: any;
}

export enum AddDetailToken {
  default = 'default',
  add_token = 'add_token',
  import_token = 'import_token',
  import_from_url = 'import_from_url',
  on_open_modal = 'on_open_modal',
  direct_import = 'direct_import',
}

export enum AddNewRecipient {
  default = 'default',
  add_new_recipient = 'add_new_recipient',
  save_confirm = 'save_confirm',
  remove_recipient = 'remove_recipient',
  edit_recipient = 'edit_recipient',
  edit_confirm = 'edit_confirm',
}

export interface ListRecipient {
  id: string;
  name: string;
  recipient_address: string;
  user_address: string;
}

export const SwapTokenSymbol = ['POSI', 'USDT', 'BNB', 'USDC', 'BUSD', 'DAI'];

export interface ImportTokenInfo {
  symbol: string;
  contract_address: string;
  name: string;
  decimal: any;
  logo_url?: string;
}

export interface TokenOfUser {
  symbol: string;
  contract_address: string;
  decimal: number;
  name: string;
  logo_url: string;
}

export interface TokenState {
  tokenInfo: any;
  tokenOfUser: TokenOfUser[];
}

export const SwapTokenPrices = ['BNB', 'BTC', 'BUSD', 'DAI', 'ETH', 'POSI'];

export const InputTokenPercent = [25, 50, 75, 100];

export interface IPoolLiquidityStat {
  total: number;
  // liquidity in base currency
  base: number;
  // liquidity in quote currency
  quote: number;
}

export interface IAPRHistory {
  [duration: number]: number;
}

export interface TokenExchangeExtend {
  symbol: string;
  name: string;
  contract_address: Address;
  logo_url?: string;
  url?: string;
}

export interface PairSpotExchange {
  base: TokenExchangeExtend;
  quote: TokenExchangeExtend;
}

export interface ItemUserDataPoolExchange {
  stakedBalance: number;
  balance: {
    base: number;
    quote: number;
  };
}

export interface ILiquidityPoolDetail {
  pid: number;
  // this hash will be used to send to the contract
  poolKey: Address; // the hash of the pool
  pair: PairSpotExchange;
  apr: number; //current pool apr
  aprReward: number; //current pool reward apr
  aprHistory: IAPRHistory;
  strategy: string; // address of the strategy
  pairManager: string; // address of the pair manager
  liquidity: IPoolLiquidityStat;
  totalRewards: number;
  userData: ItemUserDataPoolExchange;
  rewardPoolAddress: Address;
  liquidityPoolAddress: Address;
  limitLiquidity: number;
  limitLiquidityPool: number;
  totalQuoteDeposited: number;
  rewardInStaking: number;
}

// Space Governance

export interface ISpaceFilters {
  minScore: number;
  onlyMembers: boolean;
}

export interface IStrategies {
  name: string;
  params: any;
  network: string | number;
}

export interface ISpace {
  id: string;
  name: string;
  filters: ISpaceFilters;
  strategies: IStrategies[];
  symbol: string;
}

// Detail Governance

export interface IAuthor {
  account_url: string;
  address: string;
  display_name: string;
  createdAt: string;
  id: number;
  image_url: string;
  updatedAt: string;
}

export interface IState {
  createdAt: string;
  end_time: number;
  id: number;
  proposal_id: string;
  start_time: number;
  state: string;
  trx_hash: string;
  updatedAt: string;
}

export interface IDetailProposal {
  Author: IAuthor;
  states: IState[];
  against_votes: string;
  author_id: number;
  createdAt: string;
  description: string;
  end_block: number;
  for_votes: string;
  governanceAddress: string;
  id: string;
  isVoted: boolean;
  is_activated: boolean;
  proposal_id: string;
  proposal_id_per_index: number;
  require_balance: string;
  start_block: string;
  title: string;
  updatedAt: string;
  totalVotesByChoice?: number[];
  totalVotersByChoice?: number[][];
  totalVotePowerByChoice?: number[];
  snapshot?: string;
  isHideVoteWarning?: boolean;
  myChoice?: number;
  isLoadingVp?: boolean;
  strategies?: IStrategies[];
}

export interface IProposalVoteReceipts {
  Author: IAuthor[];
  createdAt: string;
  id: number;
  proposal_id: string;
  proposal_id_by_index: number;
  support: boolean;
  trx_hash: string;
  updatedAt: string;
  voter_id: number;
  votes: string;
}

export interface IInformationSwap {
  gasRefund?: IGasREfund;
  editGas?: IEditGas[];
  searchTokenInput: any;
}

export interface IEditGas {
  id: number;
  imgUrl: string;
  quoteSymbol: string | number;
  value: number;
  title: string;
}

export interface IGasREfund {
  id: string;
  campaign_name: string;
  campaign_id: string;
  campaign_type: string;
  enabled: boolean;
  started_at: number;
  ended_at: number;
  refunding_rate: number;
  refunding_token: string;
  refunding_token_address: string;
}

/* transaction history swap */
interface IPairSwap {
  symbol: string;
  name: string;
  url: string;
  contract_address: string;
  logo_url: string;
}

export interface ISwap {
  id: number;
  txn_hash: string;
  base: IPairSwap;
  quote: IPairSwap;
  timestamp: number;
  side: string;
  price: string;
  amount_in: number;
  amount_out: number;
  refunding_amount: number;
  refunding_txn_hash: string;
  refunding_rate: number;
}

interface ItemTrasnactionHistory {
  current_page: number;
  per_page: number;
  page_count: number;
  item_count: number;
  swap_transactions: ISwap[];
}

export enum LotteryStatus {
  PENDING = 'pending',
  OPEN = 'open',
  CLOSE = 'close',
  CLAIMABLE = 'claimable',
}

interface LotteryRoundGenerics {
  isLoading?: boolean;
  lotteryId: string;
  status: LotteryStatus;
  startTime: string;
  endTime: string;
  treasuryFee: string;
  firstTicketId: string;
  lastTicketId: string;
  finalNumber: number;
}

export type SerializedBigNumber = string;

export interface LotteryResponse extends LotteryRoundGenerics {
  priceTicketInPosi: SerializedBigNumber;
  fixedPrize: SerializedBigNumber[];
  amountCollectedInPosi: SerializedBigNumber;
  posiPerBracket: SerializedBigNumber[];
  countWinnersPerBracket: SerializedBigNumber[];
  rewardsBreakdown: SerializedBigNumber[];
  prizePool: SerializedBigNumber;
  listReward: number[];
}

export interface ListWinnerItem {
  id: string;
  ticketNumber: string;
  ticketCount: string;
  prizeLevel: string;
  address: string;
  prize: string;
}

export interface WinningHistory {
  round: string;
  time: string;
  luckyNumber: string;
  winners: string;
  prizePool: string;
}

export interface IBridgeAssetDetail {
  sourceChainTokenAmount: number;
  destinationChainTokenAmount: number;
  allowanceBridgeContract: number;
  feeBridgeAsset: number;
  recipient: string;
  relayerFee: number;
  minimumBridgeAmount: {
    [key: string]: number;
  };
}
