import { Divider } from '@chakra-ui/react';
import React from 'react';

interface DividerGradientProps {
  orientation?: 'horizontal' | 'vertical';
  [key: string]: any;
  color: string;
}

const DividerGradient: React.FC<DividerGradientProps> = ({ orientation, color, ...rest }) => {
  return (
    <Divider
      style={{
        borderWidth: '1px',
        borderStyle: 'solid',
        height: '100%',
        borderImage: color,
      }}
      borderColor={color}
      className="style-divider"
      orientation={orientation}
      {...rest}
    />
  );
};

export default DividerGradient;
