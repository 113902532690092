import { Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetApiPrice, useGetDetailShowedModalInfo } from '../../redux/hooks';
import { DetailRenderModalInfo } from '../../redux/types';
import CardExchange from '../CardExchange';
import LockedBalanceOverview from './LockedBalanceOverview';
import LockedHarvestOverview from './LockedHarvestOverview';

interface TotalBalanceProps {
  dataState: any;
  handleSetIsShowBalance: (e: any) => void;
  showBalance: boolean;
}

const TotalBalance: React.FC<TotalBalanceProps> = ({ dataState, handleSetIsShowBalance, showBalance }) => {
  const { t } = useTranslation('header');
  const posiPrice = useGetApiPrice('posi');
  const showDetail = useGetDetailShowedModalInfo();
  const isShowLockedHarvest = showDetail === DetailRenderModalInfo.locked_harvest;
  const isShowDetailBalanceLocked = showDetail === DetailRenderModalInfo.locked_balance;
  const isDefaultShowed = showDetail === DetailRenderModalInfo.default;
  const slideClassName = isShowLockedHarvest || isShowDetailBalanceLocked ? 'slide-in.show' : 'slide-in';
  const hideTotalBalance = isDefaultShowed ? 'slide-in.show' : 'slide-in';
  const imgBackground = useColorModeValue('/img/bg-total-balance-light.svg', '/img/bg-total-balance.svg');

  return (
    <Flex
      flexDirection="column"
      mt="16px"
      justifyContent="center"
      textAlign="center"
      bgImage={imgBackground}
      p="20px"
      bgSize="cover"
      bgRepeat="no-repeat"
      borderRadius="16px"
      overflowX="hidden"
      position="relative"
      mb="10px"
    >
      <Flex
        width={'100%'}
        className={`${hideTotalBalance} from-right`}
        display={hideTotalBalance === 'slide-in' ? 'none' : 'flex'}
      >
        <Flex direction={'column'} w="100%" className="slide-in-content">
          <Flex w="100%" flexDirection="column">
            <Flex mx="auto">
              <Text
                color={'gray.500'}
                textAlign="center"
                fontSize={['12px', '14px', '14px', '14px', '14px']}
                fontFamily="Poppins"
                fontStyle="normal"
                fontWeight="500"
                mb={['0px', '10px', '10px', '10px', '10px']}
              >
                {t('Total Balance')}
              </Text>
              <Image
                ml="3px"
                src={'/img/eye-close.svg'}
                hidden={showBalance}
                w={['22px']}
                onClick={handleSetIsShowBalance}
                h={['22px']}
                zIndex={999}
                cursor="pointer"
              />
              <Image
                ml="3px"
                src={'/img/eye-open.svg'}
                hidden={!showBalance}
                w={['22px']}
                onClick={handleSetIsShowBalance}
                h={['22px']}
                zIndex={999}
                cursor="pointer"
              />
            </Flex>
            <Text
              color={'teal.150'}
              textAlign="center"
              fontWeight="600"
              fontSize={'24px'}
              lineHeight="24px"
              verticalAlign={'middle'}
              fontFamily="Poppins"
              fontStyle="normal"
              mb={['0px', '10px', '10px', '10px', '10px']}
            >
              {showBalance ? dataState?.balanceOfPosi || '0' : '✲✲✲✲✲✲'}&nbsp;POSI
            </Text>
            {showBalance ? (
              <CardExchange color="cyan.400" value={Number(dataState?.balanceOfPosi)} priceOfCoin={Number(posiPrice)} />
            ) : (
              '✲✲✲✲✲✲'
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex className={`${slideClassName} from-left`} w="100%" overflowX="hidden">
        <Flex w="100%" className="slide-in-content">
          {isShowLockedHarvest && <LockedHarvestOverview dataState={dataState} showBalance={showBalance} />}
          {isShowDetailBalanceLocked && <LockedBalanceOverview dataState={dataState} showBalance={showBalance} />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TotalBalance;
