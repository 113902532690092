import BigNumber from 'bignumber.js';
import cakeABI from '../../configs/abi/cake.json';
import generalNFTRewardABI from '../../configs/abi/generalNFTReward.json';
import liquidityNftStakingAbi from '../../configs/abi/liquidityNftStaking.json';
import poolsConfig from '../../configs/pools';
import { LIQUIDITY_NFT, POOL_CATEGORY } from '../../configs/types';
import { getAddress } from '../../utils/addressHelpers';
import multicall from '../../utils/multicall';
import { getListBondById } from '../bondDetail/fetchBondDetailData';
import { getFarmApy } from '../../utils/apy';
import liquidityNftStakingDexV2Abi from '../../configs/abi/liquidityNftStakingDexV2.json';

export const fetchPoolsTotalStaking = async (listPrices) => {
  const nonBnbAndNonNFTPools = poolsConfig.filter(
    (p) => p.stakingToken.symbol !== 'BNB' && p.poolCategory !== POOL_CATEGORY.NFT,
  );

  const nonBnbAndNFTPools = poolsConfig.filter(
    (p) => p.stakingToken.symbol !== 'BNB' && p.poolCategory === POOL_CATEGORY.NFT,
  );

  const callsNonBnbPools = nonBnbAndNonNFTPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.stakingToken.address),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    };
  });

  const callPoolNft = nonBnbAndNFTPools
    .filter((p) => p?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress),
        name: 'totalSupply',
        params: [],
      };
    });

  const callPoolNftTimeFinish = nonBnbAndNFTPools
    .filter((p) => p?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress),
        name: '_periodFinish',
        params: [],
      };
    });

  const callPoolLiquidityNft = nonBnbAndNFTPools
    .filter(
      (p) =>
        p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT && p?.poolKey && getAddress(p?.poolKey).length !== 42,
    )
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress),
        name: 'poolInfo',
        params: [getAddress(poolConfig?.poolKey)],
      };
    });

  const callPoolLiquidityNftPositionPerBlock = nonBnbAndNFTPools
    .filter(
      (p) =>
        p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT && p?.poolKey && getAddress(p?.poolKey).length !== 42,
    )
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress),
        name: 'getPositionPerBlock',
        params: [],
      };
    });

  const callPoolLiquidityNftTotalAllocPoint = nonBnbAndNFTPools
    .filter(
      (p) =>
        p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT && p?.poolKey && getAddress(p?.poolKey).length !== 42,
    )
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress),
        name: 'totalAllocPoint',
        params: [],
      };
    });

  const callPoolLiquidityNftDexV2 = nonBnbAndNFTPools
    .filter(
      (p) =>
        p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT && p?.poolKey && getAddress(p?.poolKey).length === 42,
    )
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress),
        name: 'poolInfo',
        params: [getAddress(poolConfig?.poolKey)],
      };
    });

  const callPoolLiquidityNftPositionPerBlockDexV2 = nonBnbAndNFTPools
    .filter(
      (p) =>
        p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT && p?.poolKey && getAddress(p?.poolKey).length === 42,
    )
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress),
        name: 'positionPerBlock',
        params: [],
      };
    });

  const callPoolLiquidityNftTotalAllocPointDexV2 = nonBnbAndNFTPools
    .filter(
      (p) =>
        p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT && p?.poolKey && getAddress(p?.poolKey).length === 42,
    )
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress),
        name: 'totalAllocPoint',
        params: [],
      };
    });

  const ids = nonBnbAndNonNFTPools.filter((z) => z.idBE).map((a) => a.idBE);

  let dataBondDetail = [];
  try {
    dataBondDetail = await getListBondById(ids);
  } catch (err) {}

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools);
  const nftTotalStaked = await multicall(generalNFTRewardABI, callPoolNft);
  const nftPeriodFinishTime = await multicall(generalNFTRewardABI, callPoolNftTimeFinish);
  const poolLiquidityNft = await multicall(liquidityNftStakingAbi, callPoolLiquidityNft);
  const poolLiquidityNftPositionPerBlockRaw = await multicall(
    liquidityNftStakingAbi,
    callPoolLiquidityNftPositionPerBlock,
  );
  const poolLiquidityNftAllocPointRaw = await multicall(liquidityNftStakingAbi, callPoolLiquidityNftTotalAllocPoint);

  const poolLiquidityNftDexV2 = await multicall(liquidityNftStakingDexV2Abi, callPoolLiquidityNftDexV2);
  const poolLiquidityNftPositionPerBlockDexV2Raw = await multicall(
    liquidityNftStakingDexV2Abi,
    callPoolLiquidityNftPositionPerBlockDexV2,
  );
  const poolLiquidityNftAllocPointDexV2Raw = await multicall(
    liquidityNftStakingDexV2Abi,
    callPoolLiquidityNftTotalAllocPointDexV2,
  );

  return [
    ...nonBnbAndNonNFTPools.map((p, index) => {
      const exist = dataBondDetail.find((z) => z.id === p.idBE);
      return {
        id: p.id,
        sousId: p.sousId,
        totalStaked: new BigNumber((nonBnbPoolsTotalStaked && nonBnbPoolsTotalStaked[index]) || 0).toJSON(),
        bondData: exist,
      };
    }),
    ...nonBnbAndNFTPools
      .filter((p) => p?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
      .map((p: any, index: number) => ({
        id: p.id,
        sousId: p.sousId,
        totalStaked: new BigNumber((nftTotalStaked && nftTotalStaked[index]) || 0).toJSON(),
        periodFinishTime: Number(new BigNumber(nftPeriodFinishTime && nftPeriodFinishTime[index][0]._hex).toString()),
        bondData: undefined,
      })),
    ...nonBnbAndNFTPools
      .filter(
        (p) =>
          p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT && p?.poolKey && getAddress(p?.poolKey).length !== 42,
      )
      .map((p: any, index: number) => {
        const allocPoint = new BigNumber(
          (poolLiquidityNft && poolLiquidityNft[index] && poolLiquidityNft[index][2]._hex) || 0,
        );
        const poolWeight = allocPoint.div(
          new BigNumber((poolLiquidityNftAllocPointRaw && poolLiquidityNftAllocPointRaw[index]) || 0),
        );

        const apr = getFarmApy(
          poolWeight as BigNumber,
          new BigNumber(listPrices[p.earningToken.symbol.toLowerCase()]),
          new BigNumber((poolLiquidityNft && poolLiquidityNft[index] && poolLiquidityNft[index][1]._hex) || 0).times(
            listPrices[p?.pair?.quote?.symbol?.toLowerCase()],
          ),
          new BigNumber(poolLiquidityNftPositionPerBlockRaw && poolLiquidityNftPositionPerBlockRaw[index]),
        );

        return {
          id: p.id,
          sousId: p.sousId,
          totalStaked: new BigNumber(
            (poolLiquidityNft && poolLiquidityNft[index] && poolLiquidityNft[index][1]._hex) || 0,
          ).toJSON(),
          apr: apr,
          bondData: undefined,
        };
      }),
    ...nonBnbAndNFTPools
      .filter(
        (p) =>
          p?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT && p?.poolKey && getAddress(p?.poolKey).length === 42,
      )
      .map((p: any, index: number) => {
        const allocPoint = new BigNumber(
          (poolLiquidityNftDexV2 && poolLiquidityNftDexV2[index] && poolLiquidityNftDexV2[index][2]._hex) || 0,
        );
        const poolWeight = allocPoint.div(
          new BigNumber((poolLiquidityNftAllocPointDexV2Raw && poolLiquidityNftAllocPointDexV2Raw[index]) || 0),
        );

        const apr = getFarmApy(
          poolWeight as BigNumber,
          new BigNumber(listPrices[p.earningToken.symbol.toLowerCase()]),
          new BigNumber(
            (poolLiquidityNftDexV2 && poolLiquidityNftDexV2[index] && poolLiquidityNftDexV2[index][1]._hex) || 0,
          ).times(listPrices[p?.pair?.quote?.symbol?.toLowerCase()]),
          new BigNumber(poolLiquidityNftPositionPerBlockDexV2Raw && poolLiquidityNftPositionPerBlockDexV2Raw[index]),
        );

        return {
          id: p.id,
          sousId: p.sousId,
          totalStaked: new BigNumber(
            (poolLiquidityNftDexV2 && poolLiquidityNftDexV2[index] && poolLiquidityNftDexV2[index][1]._hex) || 0,
          ).toJSON(),
          apr: apr,
          bondData: undefined,
        };
      }),
  ];
};
