import { RootStateOrAny, useSelector } from 'react-redux';

export const checkBoolean = (val: any) => {
  if (val === 'true') {
    return true;
  }
  return false;
};

export const useGetRecipient = () => {
  const recipient = useSelector((state: RootStateOrAny) => state.recipient?.showRecipient);
  return recipient;
};

export const useGetPageAddNewRecipient = () => {
  const pageRecipient = useSelector((state: RootStateOrAny) => state.recipient?.addNewRecipient);
  return pageRecipient;
};

export const useGetListRecipient = () => {
  const recipient = useSelector((state: RootStateOrAny) => state.recipient?.listRecipient);
  return recipient;
};

export const useGetItemRecipient = () => {
  const recipient = useSelector((state: RootStateOrAny) => state.recipient?.itemRecipient);
  return recipient;
};

export const useGetContractSwap = () => {
  const token = useSelector((state: RootStateOrAny) => state?.swap?.OUTPUT?.currencyId);
  return token;
};

export const updateAddressErrorResolver = (error) => {
  if (error.response.status === 400) {
    return 'bad_request';
  }
  if (error.response.status === 409) {
    return 'recipient_existed';
  }
  return 'unexpected_error';
};
