import { Flex, Text } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../components/Button';
import { useWalletModal } from '../../../components/WalletModal';
import useAuth from '../../../hooks/useAuth';
import { formatBigNumber } from '../../../hooks/useModalInfo';
import useWeb3 from '../../../hooks/useWeb3';
import { useTransactionAdder } from '../../../redux/transactions/hooks';
import { Bond } from '../../../redux/types';
import { callEstGas } from '../../../utils/callHelpers';
import { formatBalanceWithNumeral, getBondAbiByType } from '../../../utils/common';
import { useResponseModal } from '../../../utils/confirmModal';
import { getBondCommitContract } from '../../../utils/contractHelpers';
import multicall from '../../../utils/multicall';

interface IDistributionPhase {
  bondDetail: Bond;
}

const DistributionPhase: React.FC<IDistributionPhase> = ({ bondDetail }) => {
  const { t } = useTranslation(['bonds']);
  const [amountClaim, setAmountClaim] = useState(0);
  const [statusClaim, setStatusClaim] = useState(false);
  const { account } = useWeb3React();
  const bondAbi = getBondAbiByType(bondDetail?.type || 'normal');

  const web3 = useWeb3();
  const addTransaction = useTransactionAdder();
  const contract = getBondCommitContract(bondDetail?.bondAddress, web3);

  const handleClaim = async () => {
    if (account) {
      try {
        const method = contract.methods.claimBond();
        let gas;
        try {
          gas = await callEstGas(method, account);
        } catch (e) {}
        method.send({ from: account, gas }, async function (err, hash) {
          if (hash) {
            addTransaction({ hash } as any, {
              summary: t('claim_success_content', { balance: `${amountClaim}` }),
              title: t('claim_success'),
              trigger: () => {
                getAmountCommit();
              },
            });
            await useResponseModal({
              title: t('transaction_submitted'),
              txHash: hash,
            });
          }
          if (err) {
            if (err?.message) {
              if (err?.message) {
                await useResponseModal({
                  title: t('claim_failed'),
                  errorMsg: err?.message,
                });
              }
            }
            throw err;
          }
        });
      } catch (e) {
        await useResponseModal({
          title: t('claim_failed'),
          errorMsg: e?.message,
        });
      }
    }
  };

  const getAmountCommit = useCallback(async () => {
    try {
      const [res, amountClaimRaw] = await multicall(bondAbi, [
        {
          address: bondDetail?.bondAddress,
          name: 'amountCommitted',
          params: [account],
        },
        {
          address: bondDetail?.bondAddress,
          name: 'distributionAmount',
          params: [account],
        },
      ]);
      setStatusClaim(!!res[0][1] || false);
      setAmountClaim(formatBigNumber(new BigNumber(amountClaimRaw || 0)));
    } catch (e) {}
  }, [account, bondDetail]);

  useEffect(() => {
    getAmountCommit();
  }, [account, getAmountCommit]);

  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(login, logout, account);

  return (
    <Flex
      flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
      justifyContent="space-between"
      alignItems="center"
    >
      <Text>
        {t('avail_to_claim')}:{' '}
        <Text as={'span'} color="white">
          {statusClaim ? '0.000' : formatBalanceWithNumeral(amountClaim, 4)} {t('units')}
        </Text>
      </Text>
      {account ? (
        <ButtonComponent isDisabled={statusClaim || Number(amountClaim) <= 0} variant="contained" onClick={handleClaim}>
          {t('claim')}
        </ButtonComponent>
      ) : (
        <ButtonComponent variant="contained" onClick={onPresentConnectModal}>
          {t('connect_wallet')}
        </ButtonComponent>
      )}
    </Flex>
  );
};

export default DistributionPhase;
