import { IEditGas } from '../types';
import { v4 as uuidv4 } from 'uuid';
import axiosBase from 'axios';

export const listGas: IEditGas[] = [
  {
    id: 1,
    value: 3,
    quoteSymbol: 'BNB',
    imgUrl: '/img/emoji_gauge.svg',
    title: 'low',
  },
  {
    id: 2,
    value: 5,
    quoteSymbol: 'BNB',
    imgUrl: '/img/emoji_minus.svg',
    title: 'standard',
  },
  {
    id: 3,
    value: 7,
    quoteSymbol: 'BNB',
    imgUrl: '/img/emoji_thunder_speed.svg',
    title: 'speedy',
  },
];

export const getDataRefunds = async () => {
  try {
    const response = await axiosBase({
      method: 'GET',
      baseURL: process.env.REACT_APP_BOND_EXCHANGE_URL,
      url: `swap/campaigns/${process.env.REACT_APP_CAMPAIGNS_ID}`,
    }).then((res) => res.data?.data);
    return response;
  } catch (e: any) {
    // console.log(`e`, e);
    return [];
  }
};
