import { createSlice } from '@reduxjs/toolkit';
import { customListToken, getListTokenNotExistOnArray } from '../../utils/getTokenList';
import { createTokenSwap, getListTokenBySymbol, getTokenInfo, getTokenOfUser } from './fetchData';

const initialState = {
  tokenOfUser: [],
  tokenInfo: [],
  tokenInActives: [],
};
export const tokenSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setDataTokenInfos: (state, action) => {
      const data = state;
      state.tokenInfo = action.payload;
      return data;
    },
    setListTokenInActive: (state, action) => {
      const data = state;
      data.tokenInActives = action.payload;
      return data;
    },
    updateTokenInfo: (state, action) => {
      const data = state;
      data.tokenInfo = action.payload;
      return state;
    },
  },
});

export const { setDataTokenInfos, setListTokenInActive, updateTokenInfo } = tokenSlice.actions;

export const fetchGlobalListTokenOfUser = (userAddress) => async (dispatch: any) => {
  dispatch(fetchListTokenOfUser(userAddress));
};

export const fetchDataTokenInfo = (listToken: any[]) => async (dispatch: any) => {
  const data = Promise.all(
    listToken.map(async (e) => {
      if (e) {
        const res = await getTokenInfo(e);
        return res;
      }
    }),
  );
  data
    .then((e) => {
      dispatch(setDataTokenInfos(e));
    })
    .catch(() => {});
};

export const fetchListTokenOfUser = (userAddress: string) => async () => {
  try {
    const listTokenOfUser = await getTokenOfUser(userAddress);
    if (listTokenOfUser?.items) {
      const filterTokenList = getListTokenNotExistOnArray(listTokenOfUser.items);
      const listTokenImported = customListToken(listTokenOfUser?.items);
      listTokenImported.length && localStorage.setItem('token_imported', JSON.stringify(listTokenImported));
      if (filterTokenList.length) {
        await createTokenSwap(userAddress, filterTokenList);
      }
    }
  } catch (error) {}
};

export const fetchListTokenInActive = (keyword: string) => async (dispatch: any) => {
  try {
    const tokenInActives = await getListTokenBySymbol(keyword);
    if (tokenInActives.items) {
      dispatch(setListTokenInActive(tokenInActives.items));
    }
  } catch (error) {}
};

export default tokenSlice.reducer;
