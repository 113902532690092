import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import config, { connectorLocalStorageKey, walletConnectedLocalStorageKey, walletLocalStorageKey } from './config';
import { Config, ConnectorNames, Login } from './types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UAParser from 'ua-parser-js';
import WalletCard from './WalletCard';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

interface Props {
  login: Login;
  onDismiss?: () => boolean;
  isOpen: boolean;
}

const sortByRefer = (sortedConfig: Config[]): Config[] => {
  let isMathWallet = false;
  let isCoin98 = false;
  let isDefaultBrowser = true;

  // sort by ext
  // MathWallet
  if (window?.ethereum && window?.ethereum?.isMathWallet) {
    isDefaultBrowser = false;
    const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'MathWallet');
    isMathWallet = true;
    if (existWallet) {
      sortedConfig = [
        existWallet,
        ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'MathWallet'),
      ];
    }
  } else if (
    localStorage.getItem(walletConnectedLocalStorageKey) &&
    localStorage.getItem(walletConnectedLocalStorageKey) === 'MathWallet'
  ) {
    localStorage.removeItem(walletConnectedLocalStorageKey);
  }

  // Coin98
  if ((window as any)?.coin98 || (window as any)?.isCoin98) {
    isDefaultBrowser = false;
    isCoin98 = true;
    const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'Coin98');

    if (existWallet) {
      sortedConfig = [
        existWallet,
        ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'Coin98'),
      ];
    }
  } else if (
    localStorage.getItem(walletConnectedLocalStorageKey) &&
    localStorage.getItem(walletConnectedLocalStorageKey) === 'Coin98'
  ) {
    localStorage.removeItem(walletConnectedLocalStorageKey);
  }

  // Binance Chain
  if (
    (window as any)?.BinanceChain &&
    !(window as any)?.BinanceChain?.isCoin98 &&
    !(window as any)?.BinanceChain?.isMetaMask
  ) {
    isDefaultBrowser = false;
    const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'Binance Chain');

    if (existWallet) {
      sortedConfig = [
        existWallet,
        ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'Binance Chain'),
      ];
    }
  } else if (
    localStorage.getItem(walletConnectedLocalStorageKey) &&
    localStorage.getItem(walletConnectedLocalStorageKey) === 'Binance Chain'
  ) {
    localStorage.removeItem(walletConnectedLocalStorageKey);
  }

  // metaMask
  if (
    (window?.ethereum && !isMathWallet && !isCoin98) ||
    // eslint-disable-next-line no-underscore-dangle
    (window?.ethereum && window?.ethereum?._metamask && window?.ethereum?.isMetaMask)
  ) {
    const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'Metamask');
    isDefaultBrowser = false;
    if (existWallet) {
      sortedConfig = [
        existWallet,
        ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'Metamask'),
      ];
    }
  } else if (
    localStorage.getItem(walletConnectedLocalStorageKey) &&
    localStorage.getItem(walletConnectedLocalStorageKey) === 'Metamask'
  ) {
    localStorage.removeItem(walletConnectedLocalStorageKey);
  }

  // trust wallet
  if (window?.ethereum && window?.ethereum?.isTrust) {
    const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'Trust Wallet');
    isDefaultBrowser = false;
    if (existWallet) {
      sortedConfig = [
        existWallet,
        ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'Trust Wallet'),
      ];
    }
  } else if (
    localStorage.getItem(walletConnectedLocalStorageKey) &&
    localStorage.getItem(walletConnectedLocalStorageKey) === 'Trust Wallet'
  ) {
    localStorage.removeItem(walletConnectedLocalStorageKey);
  }

  // coinbase wallet

  if ((window as any)?.coinbaseWalletExtension?.isCoinbaseWallet) {
    const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'Coinbase Wallet');
    isDefaultBrowser = false;
    if (existWallet) {
      sortedConfig = [
        existWallet,
        ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'Coinbase Wallet'),
      ];
    }
  } else if (
    localStorage.getItem(walletConnectedLocalStorageKey) &&
    localStorage.getItem(walletConnectedLocalStorageKey) === 'Coinbase Wallet'
  ) {
    localStorage.removeItem(walletConnectedLocalStorageKey);
  }

  const parser = new UAParser();
  const uaString = parser.getResult().ua;
  // token pocket
  if (
    (window as any)?.TPJSBrigeClient ||
    ((window as any)?.webkit &&
      (window as any)?.webkit.messageHandlers &&
      uaString.toLowerCase().includes('tokenpocket'))
  ) {
    const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'TokenPocket');

    isDefaultBrowser = false;
    if (existWallet) {
      sortedConfig = [
        existWallet,
        ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'TokenPocket'),
      ];
    }
  } else if (
    localStorage.getItem(walletConnectedLocalStorageKey) &&
    localStorage.getItem(walletConnectedLocalStorageKey) === 'TokenPocket'
  ) {
    localStorage.removeItem(walletConnectedLocalStorageKey);
  }

  // sort by ua
  const browserName = parser.getBrowser().name;
  const osName = parser.getOS().name;

  // special dapp
  if (browserName === 'Chrome WebView' || browserName === 'WebKit' || browserName === 'Mobile Safari') {
    if (isMathWallet) {
      isDefaultBrowser = false;
      const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'MathWallet');
      if (existWallet) {
        sortedConfig = [
          existWallet,
          ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'MathWallet'),
        ];
      }
    } else if (
      localStorage.getItem(walletConnectedLocalStorageKey) &&
      localStorage.getItem(walletConnectedLocalStorageKey) === 'MathWallet'
    ) {
      localStorage.removeItem(walletConnectedLocalStorageKey);
    }
    if ((window as any)?.coin98) {
      isDefaultBrowser = false;
      const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'Coin98');
      if (existWallet) {
        sortedConfig = [
          existWallet,
          ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'Coin98'),
        ];
      }
    } else if (
      localStorage.getItem(walletConnectedLocalStorageKey) &&
      localStorage.getItem(walletConnectedLocalStorageKey) === 'Coin98'
    ) {
      localStorage.removeItem(walletConnectedLocalStorageKey);
    }
  }

  // safari
  if (browserName === 'Safari' || (isDefaultBrowser && osName !== 'Windows' && osName !== 'Mac OS')) {
    const existWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === 'WalletConnect');
    if (existWallet) {
      sortedConfig = [
        existWallet,
        ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== 'WalletConnect'),
      ];
    }
  }
  return sortedConfig;
};

const getPreferredConfig = (walletConfig: Config[]) => {
  let sortedConfig = walletConfig.sort((a: Config, b: Config) => a.priority - b.priority);
  sortedConfig = sortByRefer(sortedConfig);

  // sort by connect latest
  const preferredWalletName = localStorage.getItem(walletConnectedLocalStorageKey);
  if (!preferredWalletName) {
    return sortedConfig;
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName);

  if (!preferredWallet) {
    return sortedConfig;
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ];
};

const ModalConnect: React.FC<Props> = ({ login, onDismiss = () => null, isOpen }) => {
  const { t } = useTranslation('common');
  const [isMore, setIsMore] = useState('More');
  const handleMore = () => {
    const data = isMore === 'More' ? 'Less' : 'More';
    setIsMore(data);
  };
  const isWalletSelected = localStorage.getItem(walletLocalStorageKey);
  const handleOpenDocs = () => {
    window.open('https://docs.position.exchange/get-started/create-a-wallet');
  };

  const sortedConfig = getPreferredConfig(config);
  const whiteToGray700 = useColorModeValue('gray.700', '#fff');
  const gray800ToWhite = useColorModeValue('#fff', 'gray.800');
  const gray700ToGray100 = useColorModeValue('gray.100', 'gray.700');
  const gray400ToGray500 = useColorModeValue('gray.400', 'gray.500');

  useEffect(() => {
    if (window.isPosiApp) {
      login('injected' as any as ConnectorNames);
      localStorage.setItem(walletLocalStorageKey, 'Metamask');
      localStorage.setItem(connectorLocalStorageKey, 'injected');
      onDismiss();
    }
  }, [])

  if(window.isPosiApp) {
    return null
  }
  return (
    <Modal isOpen={isOpen} onClose={onDismiss} isCentered>
      <ModalOverlay />
      <ModalContent bg={gray800ToWhite} borderRadius="22px" w={['360px', '360px', '380px', '380px']} mx="20px">
        <ModalHeader color={whiteToGray700} fontSize="20px" fontWeight="600">
          {t('connect_to_wallet')}
        </ModalHeader>
        <ModalCloseButton color="gray.500" mt="10px" onClick={onDismiss} />
        <ModalBody>
          <Flex flexWrap="wrap">
            {isMore === 'More' ? (
              <>
                {sortedConfig.slice(0, 4).map((entry, index) => (
                  <WalletCard
                    key={entry.title}
                    login={login}
                    walletConfig={entry}
                    onDismiss={onDismiss}
                    isLatestSelect={!index && !!isWalletSelected}
                    isFirst={index === 0}
                  />
                ))}
              </>
            ) : (
              <>
                {sortedConfig.map((entry, index) => (
                  <WalletCard
                    key={entry.title}
                    login={login}
                    walletConfig={entry}
                    onDismiss={onDismiss}
                    isLatestSelect={!index && !!isWalletSelected}
                    isFirst={index === 0}
                  />
                ))}
              </>
            )}
            <Flex
              py="10px"
              align="center"
              w="100%"
              cursor="pointer"
              onClick={handleMore}
              justify="center"
              color={gray400ToGray500}
            >
              <Text w="40px"> {t(isMore)} </Text>

              {isMore === 'More' ? (
                <ChevronDownIcon color={gray400ToGray500} />
              ) : (
                <ChevronUpIcon color={gray400ToGray500} />
              )}
            </Flex>
          </Flex>
          <Button
            w="100%"
            my="20px"
            fontSize="14px"
            fontWeight="500"
            h="44px"
            bg={gray700ToGray100}
            color={whiteToGray700}
            onClick={handleOpenDocs}
            _hover={{ bg: 'none' }}
          >
            {t('learn_how_to_connect_wallet')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalConnect;
