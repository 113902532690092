/* eslint-disable react/no-children-prop */
import {
  Button,
  Text,
  ModalBody,
  Modal,
  ModalContent,
  ModalOverlay,
  ChakraProvider,
  Flex,
  ModalHeader,
  ModalCloseButton,
  Image,
  Divider,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import themeContext from '../../configs/contexts/ThemeContext';
import { renderPower } from '../../views/NFTPage/components/TransferModal';
import rehypeRaw from 'rehype-raw';
import { calculateMiningEfficiency, calculateMiningPower } from '../../utils/calculatePower';

interface IConfirmModal {
  onDismiss: (val: boolean) => void;
  isOpenModal: boolean;
  config: any;
}

const ConfirmModal: React.FC<IConfirmModal> = ({ onDismiss, isOpenModal, config }) => {
  const { t } = useTranslation(['common']);

  const handleConfirm = () => {
    onDismiss(true);
  };

  const handleClose = () => {
    onDismiss(false);
  };

  return (
    <ChakraProvider theme={themeContext}>
      <Modal onClose={handleClose} blockScrollOnMount={true} isOpen={isOpenModal} size="md" isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" width="100%" height="100%" />
        <ModalContent bg="gray.800" px="24px" pb="24px" borderRadius="24px">
          <ModalHeader pb={'0px'} color={'teal.150'} fontSize={18} textAlign={'center'} mt={'16px'}>
            {config?.title || ''}
          </ModalHeader>
          <ModalCloseButton color={'gray.500'} mt={'20px'} onClick={handleClose} />
          <ModalBody>
            <Divider mt={'4px'} mb={'8px'} />
            <Flex flexDirection="column" mt="16px">
              {config?.item && (
                <>
                  <Image mx="auto" src={config?.item?.image} width="200px" />
                  <Text color="gray.500" mt="8px" textAlign="center">
                    #{config?.item?.tokenId || ''}
                  </Text>
                  <Text color="gray.500" textAlign="center">
                    Efficiency:{' '}
                    {Number(
                      calculateMiningEfficiency(Number(config?.item?.grade), Number(config?.item?.quality)),
                    ).toFixed(2)}
                    %
                  </Text>
                  <Text color="gray.500" textAlign="center">
                    Power:{' '}
                    {Number(
                      calculateMiningPower(
                        Number(config?.item?.grade),
                        Number(config?.item?.quality),
                        Number(config?.item?.amount),
                      ),
                    ).toFixed(2)}
                  </Text>
                </>
              )}

              <Text color="gray.500" mt="16px" textAlign="center" wordBreak="normal">
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={config?.content || ''} />
              </Text>
            </Flex>
            <Flex flexDirection={'row'} mt={'30px'} mb={'20px'} justifyContent={'space-between'}>
              <Button
                w={'48%'}
                color={'white'}
                borderRadius={'25px'}
                h={'45px'}
                _hover={{ bg: 'gray.700' }}
                _active={{ bg: 'gray.700' }}
                bg="gray.800"
                borderWidth={'1px'}
                borderColor={'gray.500'}
                onClick={handleClose}
              >
                {t('Cancel')}
              </Button>
              <Button
                onClick={handleConfirm}
                w={'48%'}
                borderRadius={'25px'}
                h={'45px'}
                bg={'teal.150'}
                _hover={{ bg: 'teal.150', opacity: '0.7' }}
                _active={{ bg: 'teal.150', opacity: '0.7' }}
                color={'white'}
              >
                {t('Confirm')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ConfirmModal;
