import { createReducer } from '@reduxjs/toolkit';
import {
  setActivePercent,
  Field,
  replaceSwapState,
  selectCurrency,
  setGasLimit,
  setGasLimitPanCake,
  setGasPrice,
  setIsInitSdk,
  setRecipient,
  switchCurrencies,
  switchCurrenciesV2,
  typeInput,
} from './actions';

export interface SwapState {
  readonly independentField: Field;
  readonly typedValue: string;
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined;
  };
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined;
  };
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null;
  readonly gasPrice: number;
  readonly gasLimit: number;
  readonly gasLimitPanCake: number;
  readonly isInitSdk: boolean;
  readonly activePercent?: boolean;
}

const initialState: SwapState = {
  independentField: Field.INPUT,
  typedValue: '1',
  [Field.INPUT]: {
    currencyId: '',
  },
  [Field.OUTPUT]: {
    currencyId: '',
  },
  recipient: null,
  gasLimit: 200000,
  gasLimitPanCake: 270000,
  gasPrice: 5,
  isInitSdk: false,
  activePercent: false
};

export default createReducer<SwapState>(initialState, (builder) =>
  builder
    .addCase(
      replaceSwapState,
      (
        state,
        {
          payload: {
            typedValue,
            recipient,
            field,
            inputCurrencyId,
            outputCurrencyId,
            gasLimit,
            gasPrice,
            gasLimitPanCake,
            isInitSdk,
          },
        },
      ) => {
        return {
          [Field.INPUT]: {
            currencyId: inputCurrencyId,
          },
          [Field.OUTPUT]: {
            currencyId: outputCurrencyId,
          },
          independentField: field,
          typedValue,
          recipient,
          gasLimit,
          gasPrice,
          gasLimitPanCake,
          isInitSdk,
        };
      },
    )
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT;
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [field]: { currencyId },
          [otherField]: { currencyId: state[field].currencyId },
        };
      }

      // the normal case
      return {
        ...state,
        [field]: { currencyId },
      };
    })
    .addCase(switchCurrencies, (state) => {
      return {
        ...state,
        independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
      };
    })
    .addCase(switchCurrenciesV2, (state) => {
      return {
        ...state,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
      };
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      };
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient;
    })
    .addCase(setGasLimit, (state, { payload: { gasLimit } }) => {
      state.gasLimit = gasLimit;
    })
    .addCase(setGasLimitPanCake, (state, { payload: { gasLimitPanCake } }) => {
      state.gasLimitPanCake = gasLimitPanCake;
    })
    .addCase(setGasPrice, (state, { payload: { gasPrice } }) => {
      state.gasPrice = gasPrice;
    })
    .addCase(setIsInitSdk, (state, { payload: { isInitSdk } }) => {
      state.isInitSdk = isInitSdk;
    })
    .addCase(setActivePercent, (state, { payload }) => {
      state.activePercent = payload;
    }),
);
