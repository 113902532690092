import { lazy } from 'react';
import BaseLayout from './layout/BaseLayout';
import BasicLayout from './layout/BasicLayout';
import LandingLayout from './layout/LandingLayout';
import LayoutNoneFooter from './layout/LayoutNoneFooter';

const Exchange = lazy(() => import('./views/Exchange/index'));
const SwapPage = lazy(() => import('./views/SwapPage/index'));
const FarmsPage = lazy(() => import('./views/FarmsPage/index'));
const PoolsPage = lazy(() => import('./views/NewPoolsPage/index'));
const NFTPage = lazy(() => import('./views/NFTPage/index'));
const Referral = lazy(() => import('./views/Referral/index'));
const Trading = lazy(() => import('./views/Trading/index'));
const Governance = lazy(() => import('./views/Governance/index'));
const NewCommunityGovernance = lazy(() => import('./views/Governance/NewCommunityGovernance/index'));
const CommunityProposal = lazy(() => import('./views/Governance/CommunityProposal'));
const ProposalDetail = lazy(() => import('./views/Governance/components/ProposalDetail'));
const NFTMarketplace = lazy(() => import('./views/NFTMarketplace/index'));
const NFTMarketplaceAuctionDetail = lazy(() => import('./views/NFTMarketplace/components/AuctionDetail'));
const NFTMarketplaceMarketDetail = lazy(() => import('./views/NFTMarketplace/components/MarketDetail/index'));
const MarketplaceHistory = lazy(() => import('./views/NFTMarketplace/components/MarketplaceHistory'));
const CompetitionPage = lazy(() => import('./views/Competition/ReferralCompetition'));
const FarmingCompetition = lazy(() => import('./views/Competition/FarmingCompetition'));
const BuyingCompetition = lazy(() => import('./views/Competition/BuyingCompetition'));
const HomePage = lazy(() => import('./views/HomePage'));
const IssuePage = lazy(() => import('./views/BondsPage/IssuePage'));
const NewBondLending = lazy(() => import('./views/BondLending/NewBond'));
const BondBrowserPage = lazy(() => import('./views/BondBrowserPage'));
const BondDetail = lazy(() => import('./views/BondDetail'));
const BondExchange = lazy(() => import('./views/BondExchange'));
const LaunchpadPage = lazy(() => import('./views/BondLaunchpad/LaunchpadPage'));
const BondLaunchpadPools = lazy(() => import('./views/BondLaunchpad/Pools'));
const BondLaunchpadOverview = lazy(() => import('./views/BondLaunchpad/BondLaunchpadOverview'));
const NotFoundPage = lazy(() => import('./views/NotFoundPage'));
const PosiBirthDay = lazy(() => import('./views/PosiBirthDayPage'));
const BridgeAsset = lazy(() => import('./views/BridgeAsset'));
const ListBondsHolder = lazy(() => import('./views/BondLending/ListBondsHolderPage'));
const ListBondsIssuerPage = lazy(() => import('./views/BondLending/ListBondsIsuersPage'));

const MigrationFarmPool = lazy(() => import('./views/MigrationFarmPool'));

export default [
  // {
  //   path: '/',
  //   exact: true,
  //   component: HomePage,
  // },
  {
    path: '/',
    exact: true,
    layout: LandingLayout,
    component: HomePage,
  },
  {
    path: '/bonds/my',
    exact: true,
    layout: BasicLayout,
    component: ListBondsIssuerPage,
  },
  {
    path: '/bonds/p2p/create',
    exact: true,
    layout: BasicLayout,
    component: NewBondLending,
  },
  {
    path: '/bonds/p2p',
    exact: true,
    layout: BasicLayout,
    component: ListBondsHolder,
  },
  {
    path: '/migration',
    exact: true,
    layout: BasicLayout,
    component: MigrationFarmPool,
  },
  {
    path: '/launchpad/bond/page',
    exact: true,
    layout: BasicLayout,
    component: LaunchpadPage,
  },
  {
    path: '/bond-exchange/',
    exact: true,
    layout: BaseLayout,
    component: BondExchange,
  },
  {
    path: '/bond-exchange/:baseSymbol',
    exact: true,
    layout: BaseLayout,
    component: BondExchange,
  },
  {
    path: '/bond-exchange/:baseSymbol/:quoteSymbol',
    exact: true,
    layout: BaseLayout,
    component: BondExchange,
  },
  {
    path: '/trade-v1/',
    exact: true,
    layout: BaseLayout,
    component: BondExchange,
  },
  {
    path: '/trade-v1/:baseSymbol',
    exact: true,
    layout: BaseLayout,
    component: BondExchange,
  },
  {
    path: '/trade-v1/:baseSymbol/:quoteSymbol',
    exact: true,
    layout: BaseLayout,
    component: BondExchange,
  },
  {
    path: '/launchpad/bond',
    exact: true,
    layout: BasicLayout,
    component: BondLaunchpadOverview,
  },
  {
    path: '/launchpad/bond/pools',
    exact: true,
    layout: BasicLayout,
    component: BondLaunchpadPools,
  },
  {
    path: '/bonds/new',
    exact: true,
    layout: BasicLayout,
    component: IssuePage,
  },
  {
    path: '/bonds',
    exact: true,
    layout: BasicLayout,
    component: BondBrowserPage,
  },
  {
    path: '/nft/marketplace/history',
    exact: true,
    layout: BasicLayout,
    component: MarketplaceHistory,
  },
  {
    path: '/nft/marketplace',
    exact: true,
    layout: BasicLayout,
    component: NFTMarketplace,
  },
  {
    path: '/nft/marketplace/:type_market',
    exact: true,
    layout: BasicLayout,
    component: NFTMarketplace,
  },
  {
    path: '/nft/marketplace/auction/:nftId/:contractAddress',
    exact: true,
    layout: BasicLayout,
    component: NFTMarketplaceAuctionDetail,
  },
  {
    path: '/nft/marketplace/market/:nftId/:contractAddress',
    exact: true,
    layout: BasicLayout,
    component: NFTMarketplaceMarketDetail,
  },
  {
    path: '/nft/marketplace/history/:type_history',
    exact: true,
    layout: BasicLayout,
    component: MarketplaceHistory,
  },
  {
    path: '/farming',
    exact: true,
    layout: BasicLayout,
    component: FarmsPage,
  },
  {
    path: '/referral',
    exact: true,
    layout: BasicLayout,
    component: Referral,
  },
  {
    path: '/trading',
    exact: true,
    layout: BasicLayout,
    component: Trading,
  },
  {
    path: '/pools/:tab',
    exact: true,
    layout: BasicLayout,
    component: PoolsPage,
  },
  {
    path: '/pools',
    exact: true,
    layout: BasicLayout,
    component: PoolsPage,
  },
  {
    path: '/bonds/:bondId',
    exact: true,
    layout: BasicLayout,
    component: BondDetail,
  },
  {
    path: '/nfts/:action',
    exact: true,
    layout: BasicLayout,
    component: NFTPage,
  },
  {
    path: '/nfts',
    exact: true,
    layout: BasicLayout,
    component: NFTPage,
  },
  {
    path: '/governance/:proposalType',
    exact: true,
    layout: BasicLayout,
    component: Governance,
  },
  {
    path: '/governance/community/new',
    exact: true,
    layout: BasicLayout,
    component: NewCommunityGovernance,
  },
  {
    path: '/governance/core/:proposal_id',
    exact: true,
    layout: BasicLayout,
    component: ProposalDetail,
  },
  {
    path: '/governance/community/:proposal_id',
    exact: true,
    layout: BasicLayout,
    component: CommunityProposal,
  },
  {
    path: '/competition/referral',
    exact: true,
    layout: BasicLayout,
    component: CompetitionPage,
  },
  {
    path: '/posi-birthday',
    exact: false,
    layout: BasicLayout,
    component: PosiBirthDay,
  },
  {
    path: '/competition/farming',
    exact: true,
    layout: BasicLayout,
    component: FarmingCompetition,
  },
  {
    path: '/competition/buying',
    exact: true,
    layout: BasicLayout,
    component: BuyingCompetition,
  },
  {
    path: '/swap',
    exact: true,
    layout: LayoutNoneFooter,
    // layout: AuthLayout,
    component: SwapPage,
  },
  {
    path: '/liquidity',
    exact: true,
    layout: LayoutNoneFooter,
    // layout: AuthLayout,
    component: SwapPage,
  },
  {
    path: '/liquidity/:action/:currencyIdA/:currencyIdB',
    exact: true,
    layout: LayoutNoneFooter,
    // layout: AuthLayout,
    component: Exchange,
  },
  {
    path: '/404',
    exact: true,
    layout: BasicLayout,
    // layout: AuthLayout,
    component: NotFoundPage,
  },
  {
    path: '/bridge',
    exact: false,
    layout: LayoutNoneFooter,
    // layout: AuthLayout,
    component: BridgeAsset,
  },
];
