class MarketTrade {
  public data: {
    symbol: string;
    list: any[];
  };

  constructor(symbol: string) {
    this.data = {
      symbol,
      list: [],
    };
  }

  updateListRecentOrder(newItem: any, symbol: string) {
    if (this.data.list.length) {
      if (newItem.pair_symbol === this.data.list[0].pair_symbol) {
        this.data.list.unshift(newItem);
        this.data.list = this.data.list.slice(0, 50);
      } else {
        this.data.symbol = symbol;
        this.data.list = [];
        this.data.list.unshift(newItem);
      }
    } else {
      this.data.symbol = symbol;
      this.data.list = [];
      this.data.list.unshift(newItem);
    }
  }

  updateWithAList(newList: any[], symbol: string) {
    this.data.symbol = symbol;
    this.data.list = newList;
  }

  getSymbol() {
    return this.data.symbol;
  }

  emptyList() {
    this.data.list = [];
  }

  getListData() {
    return this.data.list;
  }
}

export default MarketTrade;
