import { getLotteryAddress } from '../../utils/addressHelpers';
import multicall from '../../utils/multicall';
import lotteryAbi from '../../configs/abi/lottery.json';
import { LotteryResponse, LotteryStatus } from '../types';
import { formatTicketNumberToUse, serializeEtherToBigNumber } from '../../utils/common';
import BigNumber from 'bignumber.js';
import { formatBigNumber } from '../../hooks/useModalInfo';
import axios from 'axios';
import { isEqual, range } from 'lodash';

export const fetchCurrentLotteryId = async () => {
  try {
    const calls = [
      {
        address: getLotteryAddress(),
        name: 'currentLotteryId',
      },
      {
        address: getLotteryAddress(),
        name: 'maxNumberTicketsPerBuyOrClaim',
      },
    ];
    const [[currentLotteryId], [maxNumberTicketsPerBuyOrClaim]] = await multicall(lotteryAbi, calls);

    return {
      currentLotteryId: currentLotteryId ? currentLotteryId.toString() : null,
      maxNumberTicketsPerBuyOrClaim: maxNumberTicketsPerBuyOrClaim ? maxNumberTicketsPerBuyOrClaim.toString() : null,
    };
  } catch (error) {
    return {
      currentLotteryId: null,
      maxNumberTicketsPerBuyOrClaim: null,
    };
  }
};

const processViewLotterySuccessResponse = (response, lotteryId: string, accumulateReward?: string): LotteryResponse => {
  const [
    status,
    startTime,
    endTime,
    priceTicketInPosi,
    rewardsBreakdown,
    treasuryFee,
    posiPerBracket,
    countWinnersPerBracket,
    firstTicketId,
    lastTicketId,
    amountCollectedInPosi,
    finalNumber,
    fixedPrize,
  ] = response;

  const statusKey = Object.keys(LotteryStatus)[status];
  const serializedPosiPerBracket = posiPerBracket.map((posiInBracket) => serializeEtherToBigNumber(posiInBracket));
  const serializedCountWinnersPerBracket = countWinnersPerBracket.map((winnersInBracket) =>
    serializeEtherToBigNumber(winnersInBracket),
  );
  const serializedRewardsBreakdown = rewardsBreakdown.map((reward) => serializeEtherToBigNumber(reward));
  const serializedFixedPrize = fixedPrize.map((prize) => serializeEtherToBigNumber(prize));

  const totalFixedPrice = fixedPrize.reduce((acc, item) => {
    return new BigNumber(acc).plus(new BigNumber(item._hex || 0));
  }, new BigNumber(0));

  const prizePool = new BigNumber(totalFixedPrice)
    .plus(new BigNumber(amountCollectedInPosi._hex || 0))
    .plus(new BigNumber(accumulateReward || '0'));

  const treasuryFeeRaw = Number(treasuryFee?.toString()) / 10000;
  const listReward = [];

  for (let i = 2; i >= 0; i--) {
    const percent = new BigNumber(rewardsBreakdown[i]._hex).toNumber() / 10000;
    const fixedPrizePerPool = formatBigNumber(new BigNumber(fixedPrize[i]._hex));

    const amountCollectedInPosiRaw = formatBigNumber(new BigNumber(amountCollectedInPosi._hex));
    const accumulateRewardNumber = formatBigNumber(new BigNumber(accumulateReward || '0'));

    const reward =
      Number(amountCollectedInPosiRaw) * (1 - treasuryFeeRaw) * percent +
      fixedPrizePerPool +
      Number(accumulateRewardNumber || 0) * percent;

    listReward.push(reward);

    // burn amount
    if (i === 0) {
      listReward.push(Number(amountCollectedInPosiRaw) * treasuryFeeRaw);
    }
  }
  return {
    isLoading: false,
    lotteryId,
    status: LotteryStatus[statusKey],
    startTime: startTime?.toString(),
    endTime: endTime?.toString(),
    priceTicketInPosi: serializeEtherToBigNumber(priceTicketInPosi),
    treasuryFee: treasuryFee?.toString(),
    firstTicketId: firstTicketId?.toString(),
    lastTicketId: lastTicketId?.toString(),
    amountCollectedInPosi: serializeEtherToBigNumber(amountCollectedInPosi),
    finalNumber,
    posiPerBracket: serializedPosiPerBracket,
    countWinnersPerBracket: serializedCountWinnersPerBracket,
    rewardsBreakdown: serializedRewardsBreakdown,
    fixedPrize: serializedFixedPrize,
    prizePool: prizePool.toJSON(),
    listReward,
  };
};

const processViewLotteryErrorResponse = (lotteryId: string): LotteryResponse => {
  return {
    isLoading: true,
    lotteryId,
    status: LotteryStatus.PENDING,
    startTime: '',
    endTime: '',
    priceTicketInPosi: '',
    treasuryFee: '',
    firstTicketId: '',
    lastTicketId: '',
    amountCollectedInPosi: '',
    finalNumber: null,
    posiPerBracket: [],
    countWinnersPerBracket: [],
    rewardsBreakdown: [],
    fixedPrize: [],
    prizePool: '0',
    listReward: [],
  };
};

export const fetchCurrentLotteryRoundDetail = async (lotteryId: string) => {
  try {
    const calls = [
      {
        address: getLotteryAddress(),
        name: 'viewLottery',
        params: [lotteryId],
      },
      {
        address: getLotteryAddress(),
        name: 'accumulateReward',
        params: [],
      },
    ];
    const [[currentLottery], [accumulateRewardRaw]] = await multicall(lotteryAbi, calls);

    const data = processViewLotterySuccessResponse(
      currentLottery,
      lotteryId,
      new BigNumber(accumulateRewardRaw._hex || '0').toJSON(),
    );

    return data;
  } catch (error) {
    return processViewLotteryErrorResponse(lotteryId);
  }
};

export const fetchWinningHistory = async (lotteryId: number) => {
  try {
    const listCall = range(lotteryId - 1).map((index) => ({
      address: getLotteryAddress(),
      name: 'viewLottery',
      params: [index + 1],
    }));
    const listCallData = await multicall(lotteryAbi, listCall);
    if (listCallData && isEqual(listCallData.length, lotteryId - 1)) {
      const resData = range(lotteryId - 1).map((index) => {
        const data = processViewLotterySuccessResponse(listCallData[index][0], index.toString());
        return {
          round: index + 1,
          time: data.endTime,
          luckyNumber: formatTicketNumberToUse(data.finalNumber),
          winners: data.countWinnersPerBracket.reduce((acc, cur) => acc + Number(cur), 0),
          prizePool: data.prizePool,
        };
      });
      return resData;
    }
    return [];
  } catch (error) {
    return [];
  }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_LOTTERY_BASE_URL,
  timeout: 10000,
});

export const getLeaderBoardOfLottery = async () => {
  try {
    const ListLeaderBoard = await instance({
      method: 'GET',
      url: 'leader-board',
    });

    return ListLeaderBoard?.data?.data;
  } catch (error) {
    return [];
  }
};

const formatResponsePrizeSuccess = (response: any) => {
  const [listDataRaw, totalRewardRaw, timestampRaw, luckyNumberOfRoundRaw] = response;

  const timestamp = timestampRaw.toString();
  const totalReward = formatBigNumber(new BigNumber(totalRewardRaw?._hex || 0));

  const luckyNumberOfRound = formatTicketNumberToUse(Number(luckyNumberOfRoundRaw.toString()));

  const listData = [];

  if (listDataRaw.length) {
    listDataRaw.forEach((item) => {
      const [luckyNumberRaw, numberOfTicket, rewardBracket, reward] = item;

      const luckyNumber = Number(luckyNumberRaw.toString());

      if (luckyNumber !== 0) {
        const data = {
          timestamp,
          luckyNumber: formatTicketNumberToUse(luckyNumber),
          amount: Number(numberOfTicket.toString()),
          bracket: Number(rewardBracket.toString()) + 1,
          reward: formatBigNumber(new BigNumber(reward._hex || 0)),
        };
        listData.push(data);
      }
    });
  }
  const newList = listData.sort((a, b) => -Number(a.bracket) + Number(b.bracket));

  return {
    totalReward,
    luckyNumberOfRound,
    data: newList,
  };
};

export const getUserPrizeByRound = async (lotteryId: string, address: string) => {
  try {
    const [listPrizeRaw, isClaimed] = await multicall(lotteryAbi, [
      {
        address: getLotteryAddress(),
        name: 'getPrizeOfUser',
        params: [address, lotteryId],
      },
      {
        address: getLotteryAddress(),
        name: 'userClaimedTickets',
        params: [lotteryId, address],
      },
    ]);
    const { totalReward, data, luckyNumberOfRound } = formatResponsePrizeSuccess(listPrizeRaw);

    return {
      lotteryId,
      totalPrize: totalReward,
      luckyNumberOfRound,
      isClaimed: isClaimed[0] || false,
      data,
    };
  } catch (e) { }
};

const formatResponseTicketsSuccess = (response: any) => {
  const [listDataRaw, timestampRaw] = response;

  const timestamp = timestampRaw.toString();

  const listData = [];

  if (listDataRaw.length) {
    listDataRaw.forEach((item) => {
      const [luckyNumberRaw, numberOfTicket, status] = item;

      const luckyNumber = Number(luckyNumberRaw.toString());

      if (luckyNumber !== 0) {
        const data = {
          timestamp,
          luckyNumber: formatTicketNumberToUse(luckyNumber),
          amount: Number(numberOfTicket.toString()),
          status,
        };
        listData.push(data);
      }
    });
  }

  return {
    data: listData,
  };
};

export const getUserTicketsByRound = async (lotteryId: string, address: string) => {
  try {
    const [listTicketRaw] = await multicall(lotteryAbi, [
      {
        address: getLotteryAddress(),
        name: 'getTicketNumberOfUser',
        params: [address, lotteryId],
      },
    ]);
    const { data } = formatResponseTicketsSuccess(listTicketRaw);

    return {
      lotteryId,
      data,
    };
  } catch (e) {
    console.log(e);
  }
};

export const getListWinnerByLotteryId = async (lotteryId: number) => {
  try {
    const listTicket = await instance({
      method: 'GET',
      url: 'players',
      params: {
        'lottery-id': lotteryId,
        'is-winner': true,
        'limit': 999
      },
    });
    if (listTicket) return listTicket.data.data;
    return { players: [] };
  } catch (e) {
    return { players: [] };
  }
};
