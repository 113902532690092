import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BondStatus } from '../../configs/constants/type';
import { calculateYtm, getStatusFromTimeOfBond } from '../../utils/common';
import { Bond, BondDetailState, BondTransactionResponse } from '../types';
import { getCommitBondInfo, getCurrentBondWrapped, getListBondTransaction } from './fetchBondDetailData';

const initialState: BondDetailState = {
  currentBond: undefined,
  listTransaction: null,
  availableUnit: 0,
  stakedUnit: 0,
  underlyingAssetStatus: 0,
  isApprove: false,
  apr: 0,
  ytm: 0,
  liquidationTime: 0,
  isLoadingDataChart: false,
  loading: false,
};
export const bondDetailSlice = createSlice({
  name: 'bondDetail',
  initialState,
  reducers: {
    setCurrentBond: (state, action: PayloadAction<Bond>) => {
      const data = state;
      data.currentBond = action.payload;
      return data;
    },
    setListTransaction: (state, action: PayloadAction<BondTransactionResponse>) => {
      const data = state;
      data.listTransaction = action.payload;
      return data;
    },
    setLoadingPage: (state, action: PayloadAction<boolean>) => {
      const data = state;
      data.loading = action.payload;
      return data;
    },
    setUserData: (
      state,
      action: PayloadAction<{
        availableUnit: number;
        stakedUnit: number;
        underlyingAssetStatus: number;
        isApprove: boolean;
        apr: number;
        ytm: number;
        liquidationTime: number;
      }>,
    ) => {
      const data = state;
      data.availableUnit = action.payload.availableUnit;
      data.stakedUnit = action.payload.stakedUnit;
      data.underlyingAssetStatus = action.payload.underlyingAssetStatus;
      data.isApprove = action.payload.isApprove;
      data.apr = action.payload.apr;
      data.ytm = action.payload.ytm;
      data.liquidationTime = action.payload.liquidationTime;
      return data;
    },
    setCurrentBondStatus: (state, action: PayloadAction<BondStatus>) => {
      const data = state;
      if (data.currentBond) {
        data.currentBond.status = action.payload;
      }
    },
    setCancelDate: (state, action: PayloadAction<any>) => {
      const data = state;
      if (data.currentBond) {
        data.currentBond.cancelDate = action.payload;
      }
    },
    setDataBondsDetail: (state, action: PayloadAction<any>) => {
      const data = state;
      if (data.currentBond) {
        data.currentBond = action.payload;
      }
    },
    setIsLoadingDataChart: (state) => {
      state.isLoadingDataChart = true;
      return state;
    },
  },
});

export const {
  setCurrentBond,
  setLoadingPage,
  setListTransaction,
  setUserData,
  setCurrentBondStatus,
  setIsLoadingDataChart,
  setCancelDate,
  setDataBondsDetail,
} = bondDetailSlice.actions;

export const callFetchCurrentBond = async (id: string, account: string) => {
  try {
    const currentBond = await getCurrentBondWrapped(id);
    let commitInfo = {};

    if (account) {
      try {
        commitInfo = await getCommitBondInfo(account, currentBond?.address);
      } catch (e) {}
    }
    const status = getStatusFromTimeOfBond(
      currentBond?.pending_date,
      currentBond?.on_sale_date || 0,
      currentBond?.active_date,
      currentBond?.maturity_date,
      currentBond?.type,
      currentBond?.committed_time,
      currentBond?.distributed_time,
      currentBond?.calculated_date,
      currentBond?.liquidated_date,
      currentBond?.cancel_date,
    );
    const apr = Number(currentBond?.est_apr);
    const ytm = calculateYtm(
      apr,
      Number(currentBond?.faceValue),
      Number(currentBond?.lastPrice),
      currentBond?.maturityDate,
      currentBond?.activeDate,
    );
    const newBond = {
      ...currentBond,
      status,
      // type: 'normal',
      bondAddress: currentBond?.address || '',
      collateralAmount: currentBond.collateral_amount || 0,
      faceValue: currentBond.face_value || 0,
      issuePrice: currentBond.issue_price || 0,
      lastPrice: currentBond.last_price || 0,
      faceAsset: {
        name: currentBond?.face_asset?.symbol || '',
        address: currentBond?.face_asset?.address || '',
        type: currentBond?.face_asset?.type,
        symbol: currentBond?.face_asset?.symbol,
      },
      underlyingAsset: {
        name: currentBond?.underlying_asset?.symbol || '',
        address: currentBond?.underlying_asset?.address || '',
        type: currentBond?.underlying_asset?.type,
        symbol: currentBond?.underlying_asset?.symbol,
      },
      assetId: currentBond.name,
      pendingDate: currentBond?.pending_date || 1,
      onSaleDate: currentBond?.on_sale_date || 1,
      activeDate: currentBond?.active_date || 1,
      maturityDate: currentBond?.maturity_date || 1,
      committedDate: currentBond?.committed_time || 1,
      distributedDate: currentBond?.distributed_time || 1,
      calculationDate: currentBond?.calculated_date || 1,
      liquidatedDate: currentBond?.liquidated_date || 0,
      saleRule: currentBond?.sale_rule || [],
      sousId: currentBond?.sous_id,
      apr: apr || 0,
      ytm: ytm || 0,
      commitInfo,
      cancelDate: currentBond?.cancel_date || 0,
      soldAmount: currentBond?.sold_amount || 0,
      liquidationPrice: currentBond.liquidation_price,
    };
    return newBond;
  } catch (e) {
    throw e;
  }
};

export const reFetchCurrentBond = (id: string, account: string) => async (dispatch: any) => {
  try {
    const currentBond = await callFetchCurrentBond(id, account);
    dispatch(setCurrentBond(currentBond));
  } catch (error) {}
};

export const fetchCurrentBond = (id: string, account: string) => async (dispatch: any) => {
  dispatch(setLoadingPage(true));
  try {
    const currentBond = await callFetchCurrentBond(id, account);
    dispatch(setCurrentBond(currentBond));
  } catch (error) {
    dispatch(setCurrentBond(null));
  }
  dispatch(setLoadingPage(false));
};

export const fetchListTransaction = (queryString: string) => async (dispatch: any) => {
  try {
    const listTransaction = await getListBondTransaction(queryString);

    dispatch(setListTransaction(listTransaction));
  } catch (e) {}
};

export default bondDetailSlice.reducer;
