import axios from 'axios';
import { useModalInfo } from '../../hooks/useModalInfo';
import { getDataWithCache, InitFunctionToCall } from '../../indexedDB/cacheController';

export const fetchStatisticsData = async (account: string, token: any) => {
  try {
    let res = {};

    try {
      const response = await useModalInfo(account ?? undefined, token ?? undefined, 6);
      res = { ...res, ...response };
    } catch (e) {}
    try {
      const responseFromApi = await axios({
        method: 'GET',
        baseURL: process.env.REACT_APP_COMMON_SERVICE_BASE_URL,
        url: 'statistics/POSI',
      }).then((res) => res.data.data);
      res = { ...res, ...responseFromApi };
    } catch (e) {}

    return res;
  } catch (e) {}
};

export const getMarketTrendSpot = async () => {
  try {
    const responseFromApi = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_BOND_EXCHANGE_URL,
      url: '/pairs?page=1&limit=7&sort-by=usd_volume_24h&sort-order=DESC',
    }).then((res) => res.data);
    return responseFromApi;
  } catch (e) {
    return [];
  }
};

export const getMarketTrendFutures = async () => {
  try {
    const responseFromApi = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_FUTURES_STATISTIC_BASE_URL,
      url: '/tickers?sort=-quote_volume&limit=7',
    }).then((res) => res.data);
    return responseFromApi;
  } catch (e) {
    return [];
  }
};

export const getMarketTrendNewList = async () => {
  try {
    const responseFromApi = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_BOND_EXCHANGE_URL,
      url: '/pairs?page=1&limit=7&sort-by=created_at&sort-order=DESC',
    }).then((res) => res.data);
    return responseFromApi;
  } catch (e) {
    return [];
  }
};

async function getFileByApi(component_name: string) {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_FILE_SERVICE_BASE_URL,
      url: `/files?component-name=${component_name}`,
    }).then((res) => res.data.data);
    return response;
  } catch (e) {
    return null;
  }
}

export const getListFile = async (component_name: string) => {
  try {
    const instanceOfCallApi = new InitFunctionToCall(getFileByApi, component_name);
    const response = await getDataWithCache(component_name, instanceOfCallApi);
    return response;
  } catch (e) {
    return null;
  }
};
