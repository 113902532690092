import {
  Button,
  Text,
  ModalBody,
  Modal,
  ModalContent,
  ModalOverlay,
  ChakraProvider,
  Flex,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import themeContext from '../../configs/contexts/ThemeContext';

interface IModalAlertGas {
  onDismiss: (val: boolean) => void;
  isOpenModal: boolean;
  config: any;
}

const ModalAlertGas: React.FC<IModalAlertGas> = ({ onDismiss, isOpenModal, config }) => {
  const { t } = useTranslation(['common']);

  const handleConfirm = () => {
    onDismiss(true);
  };

  const handleClose = () => {
    onDismiss(false);
  };

  return (
    <ChakraProvider theme={themeContext}>
      <Modal onClose={handleClose} blockScrollOnMount={true} isOpen={isOpenModal} size="md" isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" width="100%" height="100%" />
        <ModalContent bg="gray.800" px="24px" pb="24px" borderRadius="24px">
          <ModalHeader pb={'0px'} color={'teal.150'} fontSize={18} textAlign={'center'} mt={'16px'}>
            {t('Confirm transaction')}
          </ModalHeader>
          <ModalCloseButton color={'gray.500'} mt={'20px'} onClick={handleClose} />
          <ModalBody>
            <Flex mt="16px">
              <Text color="gray.500" textAlign="center">
                {t('common:This transaction might fail with this error')}:{' '}
                <Text as="span" color="red.400">
                  {config?.message || ''}
                </Text>
                . {t('err_out_of_gas')}. {t('Would you like to proceed?')}
              </Text>
            </Flex>
            <Flex flexDirection={'row'} mt={'30px'} mb={'20px'} justifyContent={'space-between'}>
              <Button
                w={'48%'}
                color={'white'}
                borderRadius={'25px'}
                h={'45px'}
                _hover={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
                bg="gray.800"
                borderWidth={'1px'}
                borderColor={'gray.500'}
                onClick={handleClose}
              >
                {t('Cancel')}
              </Button>
              <Button
                onClick={handleConfirm}
                w={'48%'}
                borderRadius={'25px'}
                h={'45px'}
                bg={'teal.150'}
                _hover={{ bg: 'teal.150', opacity: '0.7' }}
                _active={{ bg: 'teal.150', opacity: '0.7' }}
                color={'white'}
              >
                {t('Confirm')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ModalAlertGas;
