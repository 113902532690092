import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BONDS_URL}/v1/api`,
  timeout: 10000,
});

export const getListBondPools = (queryString: string) => {
  return instance.get(`/bond-pools?${queryString}`).then((res) => res.data.data);
};
