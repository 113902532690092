import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import Lottie from 'react-lottie';
import { Route } from 'react-router-dom';
import Loader from '../constants/loader.json';

interface RouteProps {
  component: any;
  layout: any;
  layoutParams?: any;
}

export function RouteCustom(props: RouteProps) {
  const { component: InnerComponent, layout: Layout, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(propsPre) => (
        <Layout>
          <Suspense
            fallback={
              <div className={'loading-container'}>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: Loader,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  speed={1}
                  height={'100px'}
                  width={'100px'}
                />
              </div>
            }
          >
            <InnerComponent {...propsPre} />
          </Suspense>
        </Layout>
      )}
    />
  );
}

RouteCustom.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
};

export default RouteCustom;
