import Metamask from './icons/Metamask';
import MathWallet from './icons/MathWallet';
import TokenPocket from './icons/TokenPocket';
import TrustWallet from './icons/TrustWallet';
import WalletConnect from './icons/WalletConnect';
import BinanceChain from './icons/BinanceChain';
import SafePalWallet from './icons/SafePalWallet';
import Coin98 from './icons/Coin98';
import CoinbaseWallet from './icons/CoinbaseWallet';
import { Config, ConnectorNames } from './types';

const connectors: Config[] = [
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
    priority: 2,
  },
  {
    title: 'Metamask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    priority: 1,
  },
  {
    title: 'Trust Wallet',
    icon: TrustWallet,
    connectorId: ConnectorNames.Injected,
    priority: 3,
  },
  {
    title: 'TokenPocket',
    icon: TokenPocket,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
  {
    title: 'MathWallet',
    icon: MathWallet,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
  {
    title: 'Coin98',
    icon: Coin98,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
  {
    title: 'Binance Chain',
    icon: BinanceChain,
    connectorId: ConnectorNames.BSC,
    priority: 999,
  },
  {
    title: 'SafePal',
    icon: SafePalWallet,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
  {
    title: 'Coinbase Wallet',
    icon: CoinbaseWallet,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
];

export default connectors;
export const connectorLocalStorageKey = 'connectorId';
export const walletLocalStorageKey = 'wallet';
export const walletConnectedLocalStorageKey = 'walletConnected';
