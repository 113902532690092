import { gql, GraphQLClient } from 'graphql-request';
import { SnapshotProposalState } from '../../configs/constants/governance';

const client = new GraphQLClient(process.env.REACT_APP_SNAPSHOT_GRAPHQL_ENDPOINT, { headers: {} });

export const getSpace = async () => {
  const query = gql`
    query Space($id: String!) {
      space(id: $id) {
        id
        name
        about
        network
        symbol
        network
        terms
        skin
        avatar
        twitter
        website
        github
        private
        domain
        members
        admins
        categories
        plugins
        followersCount
        voting {
          delay
          period
          type
          quorum
          hideAbstain
        }
        strategies {
          name
          network
          params
        }
        validation {
          name
          params
        }
        filters {
          minScore
          onlyMembers
        }
      }
    }
  `;
  const variables = {
    id: process.env.REACT_APP_SNAPSHOT_SPACE_ID,
  };
  const data = await client.request(query, variables);
  return data?.space;
};

export const getProposals = async (first: number, skip: number, state: SnapshotProposalState, author_in?: [string]) => {
  const query = gql`
    query Proposals(
      $first: Int!
      $skip: Int!
      $state: String!
      $space: String
      $space_in: [String]
      $author_in: [String]
    ) {
      proposals(
        first: $first
        skip: $skip
        where: { space: $space, state: $state, space_in: $space_in, author_in: $author_in }
      ) {
        id
        ipfs
        title
        body
        start
        end
        state
        author
        created
        choices
        space {
          id
          name
          members
          avatar
          symbol
        }
        scores_state
        scores_total
        scores
        votes
        quorum
        symbol
      }
    }
  `;
  const variables = {
    first,
    skip,
    space: process.env.REACT_APP_SNAPSHOT_SPACE_ID,
    state,
    author_in
  };
  const data = await client.request(query, variables);
  return data;
};

export const getProposal = async (proposalId) => {
  const query = gql`
    query Proposal($id: String!) {
      proposal(id: $id) {
        id
        ipfs
        title
        body
        discussion
        choices
        start
        end
        snapshot
        state
        author
        created
        plugins
        network
        type
        quorum
        symbol
        strategies {
          name
          network
          params
        }
        space {
          id
          name
        }
        scores_state
        scores
        scores_by_strategy
        scores_total
        votes
      }
    }
  `;
  const variables = {
    id: proposalId,
  };
  const data = await client.request(query, variables);
  return data;
};

export const getVotes = async (proposalId, first, skip, orderBy = 'vp', orderDirection = 'desc') => {
  const query = gql`
    query Votes(
      $id: String!
      $first: Int
      $skip: Int
      $orderBy: String
      $orderDirection: OrderDirection
      $voter: String
    ) {
      votes(
        first: $first
        skip: $skip
        where: { proposal: $id, vp_gt: 0, voter: $voter }
        orderBy: $orderBy
        orderDirection: $orderDirection
      ) {
        ipfs
        voter
        choice
        vp
        vp_by_strategy
      }
    }
  `;
  const variables = {
    id: proposalId,
    orderBy,
    orderDirection,
    first,
    skip,
  };
  const data = await client.request(query, variables);
  return data;
};

export const getVotesByVoters = async (proposalId, voters, first, skip, orderBy = 'vp', orderDirection = 'desc') => {
  const query = gql`
    query Votes(
      $id: String!
      $voters: [String]!
      $first: Int
      $skip: Int
      $orderBy: String
      $orderDirection: OrderDirection
      $voter: String
    ) {
      votes(
        first: $first
        skip: $skip
        where: { proposal: $id, voter_in: $voters, voter: $voter }
        orderBy: $orderBy
        orderDirection: $orderDirection
      ) {
        ipfs
        voter
        choice
        vp
        vp_by_strategy
      }
    }
  `;
  const variables = {
    id: proposalId,
    voters,
    orderBy,
    orderDirection,
    first,
    skip,
  };
  const data = await client.request(query, variables);
  return data;
};

export const getMyChoice = async (proposalId, account) => {
  const query = gql`
    query Votes(
      $id: String!
      $voter: String
    ) {
      votes(
        where: { proposal: $id,  voter: $voter }
      ) {
        choice
      }
    }
  `;
  const variables = {
    id: proposalId,
    voter: account
  };
  const data = await client.request(query, variables);
  return data;
};

export const getUsers = async (addresses, first, skip) => {
  const query = gql`
    query Users($addresses: [String]!, $first: Int, $skip: Int) {
      users(first: $first, skip: $skip, where: { id_in: $addresses }) {
        id
        name
        about
        avatar
        created
      }
    }
  `;
  const variables = {
    addresses,
    first,
    skip
  };
  const data = await client.request(query, variables);
  return data;
};
