import {
  Box,
  Text,
  SimpleGrid,
  Flex,
  useMediaQuery,
  Link,
  Modal as WrapModal,
  ModalOverlay,
  ModalContent,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { itemContact } from '../../configs/constants/type';
import Modal from '../../views/components/Modal';
import Typography from '../Typography';

interface ItemFooterProps {
  title?: string;
  content: itemContact[];
  isMobile?: boolean;
}
const ItemFooter: React.FC<ItemFooterProps> = ({ title, content, isMobile }) => {
  const { t } = useTranslation('swap');
  const [isLargerThan1242] = useMediaQuery('(max-width: 767px ) and (min-width: 0px )');
  const [isLargerThan1024] = useMediaQuery('(max-width: 1024px ) and (min-width: 0px )');
  const [isOpen, setIsOpen] = useState(false);
  const handleShowModal = () => {
    setIsOpen(true);
  };

  if (isMobile) {
    return <FooterMobile title={title} content={content} />;
  }

  const gray100ToGray600 = useColorModeValue('gray.600', 'gray.100');
  const colorHoverItemFooter = useColorModeValue('gray.800', 'gray.200');

  return (
    <>
      <WrapModal isOpen={isOpen} blockScrollOnMount={false} onClose={() => setIsOpen(!isOpen)} isCentered>
        <ModalOverlay width="100%" height="100%" />
        <ModalContent borderRadius="36px" color="white" bg="gray.800" maxWidth="657px" mx={{ base: '10px' }}>
          <Modal onClose={() => setIsOpen(!isOpen)}></Modal>
        </ModalContent>
      </WrapModal>
      <Box mt={isLargerThan1024 && '48px'}>
        <Typography type="headline4" color={gray100ToGray600}>
          {title}
        </Typography>
        <Box w="30px" h="2px" bg={gray100ToGray600} mt="16px" display={isLargerThan1242 ? 'none' : 'block'}></Box>
        <Flex justifyContent="space-between" mt="24px">
          <SimpleGrid
            flexWrap="wrap"
            display={'block'}
            columns={[3, 4, 6, 6, 1]}
            justifyContent={[null, null, 'space-between', 'space-between']}
          >
            {content?.map((e, index) => (
              <Text
                color="gray.500"
                fontSize={'14px'}
                mr="2"
                pt="16px"
                key={index}
                onClick={() => {
                  if (!e.active) {
                    handleShowModal();
                  }
                }}
                _hover={{ color: colorHoverItemFooter }}
                cursor="pointer"
              >
                <Link
                  href={e.active ? e.href : null}
                  isExternal={e.href !== '/whitepaper.pdf'}
                  _hover={{ borderColor: 'none' }}
                >
                  {t(e.title)}
                </Link>
              </Text>
            ))}
          </SimpleGrid>
        </Flex>
      </Box>
    </>
  );
};

const FooterMobile: React.FC<{ title: string; content: itemContact[] }> = memo(({ title, content }) => {
  const { t } = useTranslation('swap');
  const [isColor, setIsColor] = useState(false);
  const gray100ToGray600 = useColorModeValue('gray.600', 'gray.100');
  const gray800ToGray100 = useColorModeValue('gray.100', 'gray.800');
  const colorHoverItemFooter = useColorModeValue('gray.800', 'gray.200');

  const handleColor = () => {
    setIsColor(!isColor);
  };

  return (
    <Accordion allowMultiple mt="32px" color={'white'}>
      <AccordionItem borderTop={'none'} borderBottom={title === 'Blog' && 'none'} borderColor={gray800ToGray100}>
        <AccordionButton
          pb="0px"
          _hover={{
            background: 'none',
          }}
          onClick={handleColor}
        >
          <Typography pb="16px" type="headline5" color={gray100ToGray600}>
            {title}
          </Typography>
          <AccordionIcon ml="16px" color={isColor ? 'teal.150' : 'gray.600'} />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Flex direction={'column'}>
            {content?.map((e, index) => (
              <Typography
                key={`item_detail_${index}`}
                as={'a'}
                target={e.href !== '/whitepaper.pdf' && '_blank'}
                href={e.active ? e.href : null}
                type="body3-r"
                pt={index === 0 ? '0px' : '12px'}
                _hover={{
                  color: colorHoverItemFooter,
                }}
                color={'gray.500'}
              >
                {t(e.title)}
              </Typography>
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
});

export default ItemFooter;
