import { Flex } from '@chakra-ui/react';
import React from 'react';
import { ListMenuDesktop } from '../../../../configs/constants/home';
import DropdownMenu from './DropdownMenu';

const MenuItem: React.FC = () => {
  return (
    <Flex align="center" h="64px">
      {ListMenuDesktop?.map((e, index) => {
        return (
          <Flex ml="6px" key={index}>
            <DropdownMenu data={e} isMobile={false} />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default MenuItem;
