import { Box, Image, Center, Text, Button } from '@chakra-ui/react';
import React from 'react';
interface ModalProps {
  customText?: '';
  onClose: () => void;
}
const Modal: React.FC<ModalProps> = ({ onClose, customText }) => {
  return (
    <Box color="white" bg="gray.800" maxWidth="657px" borderRadius="32px">
      <Image src="/Group.svg" mx="auto" pb="30px" pt="50px" />
      <Center fontSize="24px" pb="12px" textAlign="center" fontWeight="600">
        Coming soon!!
      </Center>
      {customText && (<Text fontSize="16px" textAlign="center" mb="20px" color="gray.500" w={{ base: '80%', md: '65%', lg: '65%' }} mx="auto">
        {customText}
      </Text>)}
      <Text fontSize="16px" textAlign="center" color="gray.500" w={{ base: '80%', md: '65%', lg: '65%' }} mx="auto">
        We are trying to bring it to you as soon as possible. Please come back later
      </Text>
      <Center py="48px">
        <Button
          bg="teal.150"
          maxWidth="259px"
          w="100%"
          borderRadius="36px"
          h="56px"
          _hover={{
            bg: 'teal.150',
          }}
          onClick={() => onClose()}
        >
          OK
        </Button>
      </Center>
    </Box>
  );
};
export default Modal;
