import { Box, Button, Flex, NumberInput, NumberInputField, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { capitalize, floor, isEqual, min } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWalletModal } from '../../../components/WalletModal';
import useAuth from '../../../hooks/useAuth';
import { useAvailToPurchase, useBalance, useBondPurchasable, useBondTimeLock } from '../../../redux/bondDetail/hooks';
import { useTransactionAdder } from '../../../redux/transactions/hooks';
import { callEstGas } from '../../../utils/callHelpers';
import { formatBondBalance, getBondAbiByType } from '../../../utils/common';
import { useResponseModal } from '../../../utils/confirmModal';
import { getBep20Contract } from '../../../utils/contractHelpers';
import multicall from '../../../utils/multicall';
import ConfirmPurchase, { CantPurchaseModal } from './ConfirmPurchaseModal';
import { getBalanceNumber } from '../../../utils/formatBalance';
import useModal from '../../../components/UseModal/Modal';
import moment from 'moment';
import { calculateTime } from './TimeLineContent';
import useWeb3 from '../../../hooks/useWeb3';
export const Max_256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
let interval;

const NextOpeningTime = ({ timeObj }) => {
  const formatTime = (n: number) => (n > 9 ? n : `0${n}`);

  return (
    <Text fontWeight={500} fontSize={'14px'} color={'teal.150'} as={'span'}>
      {['days', 'hours', 'minutes', 'seconds'].map((tu, idx) => `${formatTime(timeObj[tu])}${idx !== 3 ? ':' : ''}`)}
    </Text>
  );
};

export const ConnectedComponent = ({ bondDetail, account, faceAssetBalance }) => {
  const { t } = useTranslation('bonds');
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(login, logout, account);
  const web3 = useWeb3();
  const [isAllowance, setIsAllowance] = useState(false);
  const [amount, setAmount] = useState('');
  const { assetId, faceAsset, bondAddress } = bondDetail;
  const bondAbi = getBondAbiByType(bondDetail?.type || 'normal');
  const timeLock = useBondTimeLock(bondAddress, account);
  const [timeObj, setTimeObj] = useState(calculateTime(moment(timeLock).unix()));
  const { availToPurchase, lastPrice } = useAvailToPurchase(bondAddress, 0);
  const isTimeLock = useMemo(() => timeObj?.time > 0, [timeObj]);
  const isPurchasable = useBondPurchasable(bondAddress, account, isTimeLock);
  // TODO get from server
  const maxBondLeft = 1000;
  const getMaxAmount = () => floor(min([availToPurchase, faceAssetBalance / lastPrice, maxBondLeft]), 8);
  const isMax = Number(amount) > 0 && isEqual(getMaxAmount(), Number(amount));
  const invalidAmount = Number(amount) > getMaxAmount();
  const isDisabledInput = isTimeLock || !account;
  const isDisabledButton = useMemo(
    () => isAllowance && (!!invalidAmount || !amount || isNaN(Number(amount)) || Number(amount) <= 0 || isTimeLock),
    [amount, timeObj],
  );

  const getButtonText = () => {
    if (!account) return t('connect');
    if (isAllowance) return t('purchase');
    return t('approve');
  };

  const setMaxAmount = () => {
    return setAmount(getMaxAmount().toString());
  };
  const { isOpen, onOpen: openConfirmPurchase, onClose } = useDisclosure();
  const [onOpenCantPurchase] = useModal(<CantPurchaseModal isOpenModal={true} />);

  const getTimeUntil = () => {
    setTimeObj(calculateTime(moment(timeLock).unix()));
  };

  const addTransaction = useTransactionAdder();

  const onOpen = () => {
    if (isPurchasable) {
      openConfirmPurchase();
    } else {
      onOpenCantPurchase();
    }
  };

  const onApprove = useCallback(async () => {
    if (account) {
      try {
        const contract = getBep20Contract(faceAsset?.address, web3);
        const method = contract.methods.approve(bondAddress, Max_256);
        const gas = await callEstGas(method, account);
        method.send({ from: account, gas }, async function (err, hash) {
          if (hash) {
            addTransaction({ hash } as any, {
              trigger: () => {
                getAllowance();
              },
            });
            await useResponseModal({
              title: t('transaction_submitted'),
              txHash: hash,
            });
          }
          if (err) {
            if (err?.message) {
              if (err?.message) {
                await useResponseModal({
                  title: t('approve_failed'),
                  errorMsg: err?.message,
                });
              }
            }
            throw err;
          }
        });
      } catch (e) {
        await useResponseModal({
          title: t('approve_failed'),
          errorMsg: e?.message,
        });
      }
    }
  }, [account, web3]);

  const handleClickButton = () => {
    if (!account) {
      onPresentConnectModal();
    } else {
      if (isAllowance) {
        onOpen();
      } else {
        onApprove();
      }
    }
  };

  const getAllowance = async () => {
    try {
      const [[res]] = await multicall(bondAbi, [
        {
          address: faceAsset?.address,
          name: 'allowance',
          params: [account, bondAddress],
        },
      ]);
      setIsAllowance(!!getBalanceNumber(Number(res)));
    } catch (e) {}
  };

  useEffect(() => {
    getAllowance();
  }, [account]);

  useEffect(() => {
    clearInterval(interval);
    interval = setInterval(() => {
      getTimeUntil();
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLock]);

  return (
    <Box
      boxShadow={'0px 0px 36px rgba(0, 0, 0, 0.28)'}
      px={['12px', '16px', '20px']}
      py={['12px']}
      borderRadius={'12px'}
      bg={'#1F2835'}
      mt={['16px']}
    >
      <Flex>
        <Text fontWeight={500} as={'span'} fontSize={'14px'} color={'gray.500'}>
          <Text display={['none', 'none', 'inline-block']} as={'span'}>
            {t('avail_to_purchase')}:&nbsp;&nbsp;
          </Text>
          <Text display={['inline-block', 'inline-block', 'none']} as={'span'}>
            {t('avail')}:&nbsp;&nbsp;
          </Text>
          <Text as={'span'} fontSize={['14px']} color={'white'}>
            {formatBondBalance(availToPurchase)} {capitalize(t('units'))}
          </Text>
        </Text>
        <Spacer />
        <Text fontWeight={500} as={'span'} fontSize={'14px'} color={'gray.500'}>
          <Text display={['inline-block']} as={'span'}>
            {t('price')}:&nbsp;&nbsp;
          </Text>
          {lastPrice ? (
            <Text as={'span'} fontSize={['14px']} color={'white'}>
              {formatBondBalance(lastPrice || 0)} {faceAsset?.name}
            </Text>
          ) : (
            <Text as={'span'} fontSize={['14px']} color={'gray.500'}>
              {t('loading')}...
            </Text>
          )}
        </Text>
      </Flex>
      <Flex alignItems={'center'} my={['8px']}>
        <Flex
          flex={1}
          border={'1.5px solid'}
          borderColor={invalidAmount ? 'red.400' : isDisabledInput ? 'gray.700' : 'gray.600'}
          borderRadius={'8px'}
          alignItems={'center'}
        >
          <NumberInput
            id={`input-number-container-staking`}
            isDisabled={isDisabledInput}
            onChange={(valueString) => setAmount(valueString)}
            value={amount}
            variant={'unstyled'}
            alignItems={'center'}
            maxW={'100%'}
            flex={1}
          >
            <NumberInputField
              borderWidth={0}
              color={'white'}
              maxLength={18}
              pr={'0px'}
              pl={'12px'}
              className={'input-number'}
              placeholder={account ? t('enter_amount') : t('wallet_required')}
              fontSize={['14px', '14px', '16px']}
              fontWeight={600}
              _placeholder={{
                color: 'gray.500',
              }}
            />
          </NumberInput>
          <Button
            m={'4px'}
            borderRadius={'6px'}
            bg={isMax ? 'teal.150' : isDisabledInput ? 'gray.700' : 'transparent'}
            color={isMax ? 'white' : isDisabledInput ? 'gray.500' : 'teal.150'}
            border={isDisabledInput ? '' : '1px solid'}
            borderColor={'teal.150'}
            height={'32px'}
            _hover={{
              bg: isDisabledInput ? '' : '',
            }}
            _active={{
              bg: isDisabledInput ? 'gray.300' : 'teal.150',
            }}
            _disabled={{ opacity: isDisabledInput ? '1' : '0.1' }}
            cursor={isDisabledInput ? 'no-drop' : 'pointer'}
            isDisabled={isDisabledInput}
            fontWeight={500}
            fontSize={'14px'}
            onClick={setMaxAmount}
          >
            {t('max')}
          </Button>
        </Flex>
        <Button
          h={'40px'}
          ml={['12px']}
          onClick={handleClickButton}
          color={isDisabledButton ? 'gray.500' : 'white'}
          fontWeight={500}
          fontSize={'14px'}
          borderRadius={'8px'}
          display={['none', 'none', 'block']}
          cursor={isDisabledButton ? 'no-drop' : 'pointer'}
          background={isDisabledButton ? 'gray.700' : 'teal.150'}
          _disabled={{ opacity: isDisabledButton ? '1' : '0.1' }}
          disabled={isDisabledButton}
          _hover={{
            background: isDisabledButton ? '' : 'green.500',
          }}
          _active={{
            background: isDisabledButton ? 'gray.300' : 'teal.150',
          }}
        >
          {getButtonText()}
        </Button>
      </Flex>
      {invalidAmount && (
        <Text fontWeight={400} fontSize={'14px'} color={'red.400'}>
          {t('input_error')}
        </Text>
      )}
      {isDisabledInput ? (
        <Text fontWeight={500} fontSize={'14px'} color={'gray.200'} as={'span'}>
          {t('next_open')}:&nbsp;
          <NextOpeningTime timeObj={timeObj} />
        </Text>
      ) : (
        <Text fontWeight={500} fontSize={'14px'} color={'gray.500'}>
          {!amount ? t('est_price') : `~${formatBondBalance(Number(lastPrice) * Number(amount))} ${faceAsset?.name}`}
        </Text>
      )}
      <Button
        h={'40px'}
        mt={['12px']}
        fontWeight={500}
        fontSize={'14px'}
        onClick={handleClickButton}
        width={'100%'}
        color={isDisabledButton ? 'gray.500' : 'white'}
        borderRadius={'8px'}
        display={['block', 'block', 'none']}
        cursor={isDisabledButton ? 'no-drop' : 'pointer'}
        background={isDisabledButton ? 'gray.700' : 'teal.150'}
        _disabled={{ opacity: isDisabledButton ? '1' : '0.1' }}
        disabled={isDisabledButton}
        _hover={{
          background: isDisabledButton ? '' : 'green.500',
        }}
        _active={{
          background: isDisabledButton ? 'gray.300' : 'teal.150',
        }}
      >
        {getButtonText()}
      </Button>
      <ConfirmPurchase
        isOpen={isOpen}
        onClose={onClose}
        account={account}
        bondAddress={bondAddress}
        amount={amount}
        assetId={assetId}
        price={lastPrice}
      />
    </Box>
  );
};

const PurchaseBondInput = ({ bondDetail }) => {
  const { t } = useTranslation('bonds');
  const { faceAsset } = bondDetail;
  const { account } = useWeb3React();
  const faceAssetBalance = useBalance(faceAsset?.address, account);

  return (
    <>
      <Flex>
        <Text fontWeight={500} as={'span'} fontSize={'14px'} color={'gray.500'}>
          {t('balance')}:&nbsp;&nbsp;
          <Text as={'span'} fontSize={['14px']} color={'white'}>
            {account ? `${formatBondBalance(faceAssetBalance)} ${faceAsset?.name}` : '--'}
          </Text>
        </Text>
      </Flex>
      <ConnectedComponent bondDetail={bondDetail} account={account} faceAssetBalance={faceAssetBalance} />
    </>
  );
};

export default PurchaseBondInput;
