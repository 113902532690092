import { createContext, useState, useEffect } from 'react';

let timeDebounce;

export default function useScrollListener() {
  const [data, setData] = useState({
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0,
  });

  // set up event listeners
  useEffect(() => {
    const handleScroll = () => {
      setData((last) => {
        return {
          x: window.scrollX,
          y: window.scrollY,
          lastX: last.x,
          lastY: last.y,
        };
      });
    };

    const throttledHandleScroll = () => {
      clearTimeout(timeDebounce);
      timeDebounce = setTimeout(handleScroll, 300);
    };
    window.addEventListener('scroll', throttledHandleScroll);

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, []);

  return data;
}

export const ScrollContext = createContext(null);
