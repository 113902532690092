import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import multicall from '../../utils/multicall';
import bondCommitABI from '../../configs/abi/bondCommit.json';
import { ethers } from 'ethers';
import { ERC20_ABI } from '../../constants/abis/erc20';
import tokens from '../../configs/constants/tokens';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BONDS_URL}/v1/api`,
  timeout: 10000,
});

export const getListBondTransaction = (queryString: string) => {
  return instance({
    method: 'get',
    url: `/transactions?${queryString}`,
    headers: {
      'X-Request-ID': uuidv4(),
    },
  }).then((response) => response.data?.data);
};

export const getCurrentBondWrapped = (id: string) => {
  if (id.length > 20) {
    return getListBondByAddresses([id]).then((res) => res[0]);
  } else if (id.length === 6) {
    return getListBondBySymbols([id]).then((res) => res[0]);
  }
  return getCurrentBond(id);
};

export const getCurrentBond = (id: string) => {
  return instance({
    method: 'POST',
    url: `/bonds/details`,
    headers: {
      'X-Request-ID': uuidv4(),
      'Content-Type': 'application/json',
    },
    data: {
      ids: [Number(id)],
    },
  }).then((response) => response.data?.data[0]);
};

export const getListBondById = (id: number[]) => {
  return instance({
    method: 'POST',
    url: `/bonds/details`,
    headers: {
      'X-Request-ID': uuidv4(),
      'Content-Type': 'application/json',
    },
    data: {
      ids: id,
    },
  }).then((response) => response.data?.data);
};

export const getListBondBySymbols = (symbols: string[]) => {
  return instance({
    method: 'POST',
    url: `/bonds/details`,
    headers: {
      'X-Request-ID': uuidv4(),
      'Content-Type': 'application/json',
    },
    data: {
      symbols,
    },
  }).then((response) => response.data?.data);
};

export const getListBondByAddresses = (addresses: string[]) => {
  return instance({
    method: 'POST',
    url: `/bonds/details`,
    headers: {
      'X-Request-ID': uuidv4(),
      'Content-Type': 'application/json',
    },
    data: {
      addresses,
    },
  }).then((response) => response.data?.data);
};

export const getCommitBondInfo = async (account: string, bondContract: string) => {
  try {
    const calls = [
      {
        address: bondContract,
        name: 'amountCommitted',
        params: [account],
      },
      {
        address: bondContract,
        name: 'distributionAmount',
        params: [account],
      },
    ];
    const result = await multicall(bondCommitABI, calls);
    let distributeBalance = 0;
    const mapBondContract = {
      '0xa062557f7272d381fd9a8139b7fd3ec4dc722843': '0x38D203C6c60C991bb5fFb95Ef6F31fBA62c3e0F3',
      '0xc36aa92decdf711ba2c36071e4f038853ef6f807': '0x04958340bfE02c95BfDE56f5d2635d574C9a4044',
    };
    if (
      bondContract.toLowerCase() === '0xa062557f7272d381fd9a8139b7fd3ec4dc722843' ||
      bondContract.toLowerCase() === '0xc36aa92decdf711ba2c36071e4f038853ef6f807'
    ) {
      const [res] = await multicall(ERC20_ABI, [
        {
          address: tokens.busd.address[56],
          name: 'balanceOf',
          params: [mapBondContract[bondContract.toLowerCase()]],
        },
      ]);
      distributeBalance = Number(ethers.utils.formatEther(res[0]));
    }
    return {
      amountCommitted: ethers.utils.formatEther(result[0][0][0]),
      distributionAmount: ethers.utils.formatEther(result[1][0]),
      distributeBalance,
    };
  } catch (err) {
    console.log(err);
  }
};
