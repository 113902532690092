import { createSlice } from '@reduxjs/toolkit';
import Web3 from 'web3';
import { getBalanceNumber } from '../../utils/formatBalance';
import { fetchUserBalances } from '../nft/fetchNftUser';
import { MarketplaceHistoryState } from '../types';
import {
  fetchMyAuctionHistory,
  fetchMyMarketHistory,
  getAuctionData,
  getBidData,
  getDataAuctionHistory,
  getDataMarketplaceHistory,
  getDetailNftById,
  getMarketData,
  getNftAuctionDetail,
  getNftMarketDetail,
} from './fetchNftUser';

const initialState: MarketplaceHistoryState = {
  MarketplaceHistoryData: [],
  AuctionHistoryData: [],
  filterGrade: 'All NFTs',
  filterPrice: null,
  searchQuery: '',
  auctionDetail: {},
  marketDetail: {},
  nftDetail: {},
  userData: {},
  auctionList: null,
  marketList: null,
  bidList: null,
  error: false,
  loading: false,
  myAuctionHistory: null,
  myMarketHistory: null,
  loadingBid: false,
};
export const nftMarketplaceSlice = createSlice({
  name: 'nftMarketplace',
  initialState,
  reducers: {
    setMarketplaceHistoryState: (state, action) => {
      const data = state;
      data.MarketplaceHistoryData = action.payload;
      return data;
    },
    setAuctionHistoryState: (state, action) => {
      const data = state;
      data.AuctionHistoryData = action.payload;
      return data;
    },
    setFilterGrade: (state, action) => {
      const data = state;
      data.filterGrade = action.payload;
      return data;
    },
    setFilterPrice: (state, action) => {
      const data = state;
      data.filterPrice = action.payload;
      return data;
    },
    setQuerySearch: (state, action) => {
      const data = state;
      data.searchQuery = action.payload;
      return data;
    },
    setAutionDetail: (state, action) => {
      const data = state;
      data.auctionDetail = action.payload || {};
      return data;
    },
    setMarketDetail: (state, action) => {
      const data = state;
      data.marketDetail = action.payload || {};
      return data;
    },
    setNftDetail: (state, action) => {
      const data = state;
      data.nftDetail = action.payload;
      return data;
    },
    setAuctionList: (state, action) => {
      const data = state;
      data.auctionList = action.payload;
      return data;
    },
    setMarketList: (state, action) => {
      const data = state;
      data.marketList = action.payload;
      return data;
    },
    setBidList: (state, action) => {
      const data = state;
      data.bidList = action.payload;
      return data;
    },
    setUserDetail: (state, action) => {
      const data = state;
      data.userData = action.payload;
      return data;
    },
    setError: (state, action) => {
      const data = state;
      data.error = action.payload;
      return data;
    },
    setLoading: (state, action) => {
      const data = state;
      data.loading = action.payload;
      return data;
    },
    setMyAuctionHistory: (state, action) => {
      const data = state;
      data.myAuctionHistory = action.payload;
      return data;
    },
    setMyMarketHistory: (state, action) => {
      const data = state;
      data.myMarketHistory = action.payload;
      return data;
    },
    setLoadingBid: (state, action) => {
      const data = state;
      data.loadingBid = action.payload;
      return data;
    },
  },
});

export const {
  setMarketplaceHistoryState,
  setAuctionHistoryState,
  setFilterGrade,
  setFilterPrice,
  setQuerySearch,
  setAutionDetail,
  setMarketDetail,
  setAuctionList,
  setLoading,
  setNftDetail,
  setBidList,
  setLoadingBid,
  setError,
  setUserDetail,
  setMarketList,
  setMyAuctionHistory,
  setMyMarketHistory,
} = nftMarketplaceSlice.actions;

export const fetchMarketplaceHistory = () => async (dispatch: any) => {
  const listMarketplaceHistory = getDataMarketplaceHistory;
  listMarketplaceHistory?.length
    ? dispatch(setMarketplaceHistoryState(listMarketplaceHistory))
    : dispatch(setMarketplaceHistoryState([]));
};

export const fetchAuctionHistory = () => async (dispatch: any) => {
  const listAuctionHistory = getDataAuctionHistory;
  listAuctionHistory?.length
    ? dispatch(setAuctionHistoryState(listAuctionHistory))
    : dispatch(setMarketplaceHistoryState([]));
};

export const setFilterGradeNftMarket = (grade: string) => (dispatch: any) => {
  dispatch(setFilterGrade(grade));
};

export const setFilterPriceNftMarket = (price: any) => (dispatch: any) => {
  dispatch(setFilterPrice(price));
};

export const setQuerySearchNftMarket = (params: string) => (dispatch: any) => {
  dispatch(setQuerySearch(params));
};

export const clearFilter = () => () => {
  // dispatch(setFilterGrade(''));
  // dispatch(setFilterPrice(''));
  // dispatch(setQuerySearch(''));
};

export const fetchDataAutionDetail = (id: number) => async (dispatch: any) => {
  dispatch(setLoading(true));
  const data = await getNftAuctionDetail(id);
  dispatch(setAutionDetail(data ?? {}));
  getDetailNftById(data.tokenId).then((nftDetail: any) => {
    dispatch(setNftDetail(nftDetail ?? {}));
  });
  setTimeout(() => {
    dispatch(setLoading(false));
  }, 500);
};

export const fetchDataMarketDetail = (id: number) => async (dispatch: any) => {
  dispatch(setLoading(true));
  const data = await getNftMarketDetail(id);
  dispatch(setMarketDetail(data ?? {}));
  getDetailNftById(data.tokenId).then((nftDetail: any) => {
    dispatch(setNftDetail(nftDetail ?? {}));
  });
  setTimeout(() => {
    dispatch(setLoading(false));
  }, 500);
};

export const fetchDataUserMarketPlace = (account: string) => async (dispatch: any) => {
  try {
    const stakingTokenBalances: any = await fetchUserBalances(account);
    const web3 = new Web3(Web3.givenProvider);
    if (web3.eth && account) {
      const bnbBalance = await web3.eth.getBalance(account);
      dispatch(
        setUserDetail({
          posiBalance: getBalanceNumber(stakingTokenBalances, 18, 6) || 0,
          bnbBalance: getBalanceNumber(bnbBalance as any, 18, 6) || 0,
        }),
      );
    }
  } catch (error) {}
};

export const fetchDataAuction = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    dispatch(setError(false));
    const listDataAuction = await getAuctionData(queryString);
    dispatch(setAuctionList(listDataAuction ?? null));
  } catch (e) {
    dispatch(setError(true));
  }
  dispatch(setLoading(false));
};

export const fetchDataMarket = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    dispatch(setError(false));
    const listDataMarket = await getMarketData(queryString);
    dispatch(setMarketList(listDataMarket ?? null));
  } catch (e) {
    dispatch(setError(true));
  }
  dispatch(setLoading(false));
};

export const fetchDataBid = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoadingBid(true));
  try {
    // dispatch(setError(false));
    const listDataBid = await getBidData(queryString);
    dispatch(setBidList(listDataBid ?? null));
  } catch (e) {
    // dispatch(setError(true));
  }
  dispatch(setLoadingBid(false));
};

export const clearError = () => async (dispatch: any) => {
  dispatch(setError(false));
};

export const fetchMyAuctions = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoading(true));
  const data = await fetchMyAuctionHistory(queryString);
  dispatch(setMyAuctionHistory(data || null));
  dispatch(setLoading(false));
};

export const fetchMyMarkets = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoading(true));
  const data = await fetchMyMarketHistory(queryString);
  dispatch(setMyMarketHistory(data || null));
  dispatch(setLoading(false));
};
export default nftMarketplaceSlice.reducer;
