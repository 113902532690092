import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getDataWithCache, InitFunctionToCall } from '../../indexedDB/cacheController';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_SWAP_API_BASE_URL}/v1`,
  timeout: 10000,
});

export const getTokenInfoFromApi = async (address: string) => {
  return await instance({
    method: 'GET',
    url: `/tokens/${address}`,
    headers: {
      'X-Request-ID': uuidv4(),
    },
  })
    .then((response) => response.data.data)
    .catch(() => {
      return null;
    });
};

export const getTokenInfo = async (address: string) => {
  try {
    const instanceOfCallApi = new InitFunctionToCall(getTokenInfoFromApi, address);
    const response = await getDataWithCache(`token_info_${address}`, instanceOfCallApi, 0);
    return response;
  } catch (e) {
    return null;
  }
};

export const createTokenSwap = async (userAddress: string, tokenAddress: string[]) => {
  try {
    const response = await instance({
      method: 'POST',
      url: `/tokens`,
      data: {
        user_address: userAddress,
        token_addresses: tokenAddress,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (err) {
    return undefined;
  }
};

export const getTokenOfUser = async (userAddress: string) => {
  return await instance({
    method: 'GET',
    url: `/users/${userAddress}/tokens`,
    headers: {
      'X-Request-ID': uuidv4(),
    },
  })
    .then((response) => response.data.data)
    .catch(() => {});
};

export const getListTokenBySymbol = async (keyword: string) => {
  return await instance({
    method: 'GET',
    url: `/tokens?q=${keyword}`,
    headers: {
      'X-Request-ID': uuidv4(),
    },
  })
    .then((response) => response.data.data)
    .catch(() => {});
};

export const deleteTokenSwap = async (userAddress, tokenAddress) => {
  try {
    const response = await instance({
      method: 'DELETE',
      url: `/users/${userAddress}/tokens/${tokenAddress}`,
      headers: {
        'X-Request-ID': uuidv4(),
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (err) {
    return undefined;
  }
};
