/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChainTypes, IBridgeAssetCall } from '../types';

export interface IChainName {
  [key: string]: string;
}
class EVMChain implements IBridgeAssetCall {
  protected type: ChainTypes;

  protected networkUrl: string;

  protected chainId: number;

  protected listRpc: string[];

  protected supportedChainIds: number[];

  protected chainName: IChainName;

  protected nativeCurrency: string;

  protected explorerUrl: string;

  protected bridgeAssetAbi: any;

  protected bridgeAssetAddresses: IChainName;

  constructor(
    type: ChainTypes,
    networkUrl: string,
    chainId: number,
    listRpc: string[],
    supportedChainIds: number[],
    chainName: IChainName,
    nativeCurrency: string,
    explorerUrl: string,
    bridgeAssetAbi: any,
    bridgeAssetAddresses: IChainName,
  ) {
    this.type = type;
    this.networkUrl = networkUrl;
    this.chainId = chainId;
    this.listRpc = listRpc;
    this.supportedChainIds = supportedChainIds;
    this.chainName = chainName;
    this.nativeCurrency = nativeCurrency;
    this.explorerUrl = explorerUrl;
    this.bridgeAssetAbi = bridgeAssetAbi;
    this.bridgeAssetAddresses = bridgeAssetAddresses;
  }

  getType() {
    return this.type;
  }

  getRpcUrl(): string {
    return this.networkUrl;
  }

  getChainId(): number {
    return this.chainId;
  }

  getListRpc(): string[] {
    return this.listRpc;
  }

  getSupportedChainIds(): number[] {
    return this.supportedChainIds;
  }

  getChainName(): IChainName {
    return this.chainName;
  }

  getNativeCurrency(): string {
    return this.nativeCurrency;
  }

  getExplorerUrl(): string {
    return this.explorerUrl;
  }

  getBridgeAssetAbi() {
    return this.bridgeAssetAbi;
  }

  getBridgeAssetAddress() {
    return this.bridgeAssetAddresses[this.chainId];
  }

  bridgeAsset(
    bridgeAssetContract: any,
    payableAmount: number,
    destBcId: number,
    recipient: string,
    amount,
    account: string,
    callbackFunc,
    gasPrice?: number
  ) {}
}
export default EVMChain;
