import { createSlice } from '@reduxjs/toolkit';
import { BondLaunchpad } from '../types';
import { getBondStatistic } from './fetchBonLaunchpad';

const initialState: BondLaunchpad = {
  statisticData: {},
};

export const bondSlice = createSlice({
  name: 'bondLaunchpad',
  initialState,
  reducers: {
    setStatisticData: (state, action) => {
      state.statisticData = action.payload;
      return state;
    },
  },
});

export const { setStatisticData } = bondSlice.actions;

export const fetchBondsStatistic = () => async (dispatch: any) => {
  const data = await getBondStatistic();
  if (data) {
    dispatch(setStatisticData(data));
  }
};

export default bondSlice.reducer;
