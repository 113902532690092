import axios from 'axios';
import { getDynamicGovernanceAddress } from '../../utils/addressHelpers';
import multicall from '../../utils/multicall';
import governanceAbi from '../../configs/abi/governanceAbi.json';

export const getGovernanceDetailData = async (proposal_id_by_index: string, proposalId: string, account?: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_GOVERNANCE_SERVICE_BASE_URL,
      url: `/proposal/detail?proposal_id_by_index=${proposal_id_by_index}`,
    }).then((res) => res.data);
    let isVoted = false;
    if (account && proposalId) {
      try {
        const governanceAddress = await getDynamicGovernanceAddress();
        const calls = [{ address: governanceAddress, name: 'hasVoted', params: [proposalId, account] }];
        const [isVotedRaw] = await multicall(governanceAbi, calls);
        isVoted = isVotedRaw[0];
      } catch (error) {}
    }
    if (response.success) {
      return {
        ...response.data.proposal,
        isVoted,
      };
    }
  } catch (e: any) {
    // throw Error();
    return null;
  }
};

export const getProposalsData = async (queryString: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_GOVERNANCE_SERVICE_BASE_URL,
      url: `/proposals?${queryString}`,
    }).then((res) => res.data);
    if (response.success) {
      return response.data;
    } else {
      return null;
    }
  } catch (e: any) {
    return null;
  }
};

export const getAddressData = async (vote: number, id: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_GOVERNANCE_SERVICE_BASE_URL,
      url: `/proposal_vote_receipts?proposal_id=${id}&support=${vote}`,
    }).then((res) => res.data);
    if (response.success) {
      return response.data.proposal_vote_receipts;
    }
  } catch (e: any) {
    return [];
  }
};
