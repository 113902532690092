import { ConnectorNames } from '../components/WalletModal/types';
import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
import { NetworkConnector } from './NetworkConnector';
import getNodeUrl, { getNodeUrlCastNft } from '../utils/getRpcUrl';

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL;
const rpcUrlCastNft = getNodeUrlCastNft();

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '56');

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`);
}

export const networkCastNft = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: rpcUrlCastNft } as any,
});

let networkLibrary: Web3Provider | undefined;

export function getNetworkLibrary(): Web3Provider {
  const rpcUrl = getNodeUrl();
  const network = new NetworkConnector({
    urls: { [NETWORK_CHAIN_ID]: rpcUrl } as any,
  });
  // eslint-disable-next-line no-return-assign
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any));
}

export function getNetworkLibraryCastNft(): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return new Web3Provider(networkCastNft.provider as any);
}

export const injected = new InjectedConnector({
  supportedChainIds: [56, 97, 900000],
});

export const bscConnector = new BscConnector({ supportedChainIds: [56, 97, 900000] });

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000,
});

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg',
});

// eslint-disable-next-line no-unused-vars
export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
};
