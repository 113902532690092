import { Flex, Image, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

interface FarmIntemProps {
  account: any;
  farm: any;
  renderValues: any;
  value: any;
  valueUsd: any;
  assetDisplay?: string;
}

const FarmItem: React.FC<FarmIntemProps> = ({ account, farm, value, valueUsd, renderValues, assetDisplay = 'LP' }) => {
  const whiteToGray700 = useColorModeValue('gray.700', 'white');

  return (
    <Flex py={['6px', '10px', '10px', '10px']}>
      <Flex mt={['0px', '5px', '5px', '5px']}>
        <Flex>
          <Image src={farm?.images} w="40px" h="40px" />
        </Flex>
        <Flex ml="10px" align="center">
          <Text fontFamily={'Poppins'} fontStyle={'normal'} fontWeight={'500'} fontSize={'14px'} color={whiteToGray700}>
            {farm?.name}
          </Text>
        </Flex>
      </Flex>
      <Spacer />
      <Flex direction={'column'} mt={['0px', '10px', '10px', '10px']} mr="4px">
        <Flex justifyContent={'flex-end'}>
          <Text fontFamily={'Poppins'} fontStyle={'normal'} fontWeight={'600'} fontSize={'14px'} color={whiteToGray700}>
            {account ? renderValues(value) : '--'} {assetDisplay}
          </Text>
        </Flex>
        <Flex justifyContent={'flex-end'} mt={['0px', '5px', '5px', '5px']}>
          <Text fontFamily={'Poppins'} fontStyle={'normal'} fontWeight={'500'} fontSize={'12px'} color={'cyan.400'}>
            ~${account ? renderValues(valueUsd) : '--'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FarmItem;
