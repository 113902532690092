import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { BASE_REFERRAL_LINK } from '../../configs';
import { getStatusCompetition } from '../../utils/common';
import { ReferralCompetitionState, WithdrawalTx } from '../types';
import {
  fetchDataReferral,
  getDataReferralLink,
  fetchDataReferralRankingList,
  fetchDataReferralByUser,
  fetchDataWithdrawalHistory,
  fetchDataCompetitionBalance,
} from './fetchReward';

const initialState: ReferralCompetitionState = {
  rewardData: 0,
  yourRanking: '',
  referralLink: '',
  referralEarned: [],
  currentCompetition: null,
  listOfCompetition: [],
  competitionOverview: {},
  listRanking: [],
  balance: 0,
  history: [],
};
export const referralCompetitionSlice = createSlice({
  name: 'referralCompetition',
  initialState,
  reducers: {
    setRewardState: (state, action) => {
      const data = state;
      data.rewardData = action.payload;
      return data;
    },
    setCurrentCompetition: (state, action) => {
      const data = state;
      data.currentCompetition = action.payload;
      return data;
    },
    setListOfCompetition: (state, action) => {
      const data = state;
      data.listOfCompetition = action.payload;
      return data;
    },
    setDataCompetitionOverview: (state, action) => {
      const data = state;
      data.competitionOverview = action.payload;
      return data;
    },
    setDataRankingList: (state, action) => {
      const data = state;
      data.listRanking = action.payload;
      return data;
    },
    setReferralLinkState: (state, action) => {
      const data = state;
      data.referralLink = action.payload;
      return data;
    },
    setReferralEarnedState: (state, action) => {
      const data = state;
      data.referralEarned = action.payload;
      return data;
    },
    setRankingState: (state, action) => {
      const data = state;
      data.yourRanking = action.payload;
      return data;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
      return state;
    },
    setWithdrawalHistory: (state, action) => {
      state.history = action.payload;
      return state;
    },
  },
});

export const {
  setRewardState,
  setBalance,
  setWithdrawalHistory,
  setRankingState,
  setCurrentCompetition,
  setListOfCompetition,
  setDataCompetitionOverview,
  setDataRankingList,
  setReferralLinkState,
  setReferralEarnedState,
} = referralCompetitionSlice.actions;

export const fetchGlobalDataReferral = () => async (dispatch: any) => {
  const dataReferral = await fetchDataReferral();
  const dataReferralRankingList = await fetchDataReferralRankingList();
  if (dataReferral && dataReferral.length > 0) {
    const currentCompetition = dataReferral[0];
    dispatch(
      setCurrentCompetition({
        ...currentCompetition,
        startTime: currentCompetition?.start,
        endTime: currentCompetition?.end,
        description: 'Invited person has to farm at least 100 POSI.',
        prizePool: currentCompetition?.prize_pool,
        // listOfDistributeReward: [
        //   {
        //     name: 'Rank 1',
        //     description: '30% of Pools',
        //   },
        //   {
        //     name: 'Rank 2',
        //     description: '70% of Pools',
        //   },
        // ],
      }),
    );
    const status = getStatusCompetition(currentCompetition?.start, currentCompetition?.end);
    const customData = { ...currentCompetition, status };
    dispatch(setDataCompetitionOverview(customData));
  }
  if (dataReferralRankingList) {
    const newList = dataReferralRankingList.sort((a: any, b: any) => Number(a.place) - Number(b.place));
    dispatch(
      setDataRankingList((newList && newList?.sort((a: any, b: any) => Number(a.place) - Number(b.place))) ?? []),
    );
  }
};

export const fetchGlobalDataReferralByUser =
  (competitionId: string, account: string) => async (dispatch: any, getState: any) => {
    dispatch(fetchReferralLink(account));
    const dataReferralByUser = await fetchDataReferralByUser(competitionId, account);
    const dataWithdrawalHistory = await fetchDataWithdrawalHistory(account);
    const dataCompetitionBalance = await fetchDataCompetitionBalance(account);
    let history: WithdrawalTx[] = [];

    if (dataReferralByUser) {
      dispatch(
        setReferralEarnedState([
          {
            users: dataReferralByUser?.total_ref || 0,
            usersInDay: dataReferralByUser?.new_ref_last_24_h || 0,
          },
        ]),
      );
    }

    if (account) {
      if (!_.isEmpty(dataWithdrawalHistory)) {
        history = dataWithdrawalHistory.map((w) => ({
          txId: w.txId,
          amount: w.amount.replaceAll('-', ''),
          time: w.CreatedAt,
        }));
      }
      dispatch(setBalance(Number(dataCompetitionBalance)));
      dispatch(setWithdrawalHistory(history));
      const { listRanking } = getState().referralCompetition;
      if (listRanking) {
        const exist = listRanking.findIndex((r) => r.address === account);
        if (exist !== -1) {
          dispatch(
            setRewardState({
              reward: Number(listRanking[exist].reward?.amount) + Number(listRanking[exist].reward?.bonus) || 0,
              ranking: exist + 1,
            }),
          );
        } else {
          dispatch(
            setRewardState({
              reward: '--',
              ranking: '--',
            }),
          );
        }
      }
    }
  };

export const fetchPublicDataOfCompetition = () => async (dispatch: any) => {
  dispatch(fetchGlobalDataReferral());
  // dispatch(fetchListOfCompetition());
  // dispatch(fetchListRakingOfCompetition());
  // dispatch(fetchReward());
};

export const fetchReferralLink = (account: string) => async (dispatch: any) => {
  const ref = await getDataReferralLink(account);
  const refLink = `${BASE_REFERRAL_LINK}/?ref=${ref?.data?.refId || ''}`;
  if (refLink) {
    dispatch(setReferralLinkState(refLink));
  }
};

export const getReferralCompetition = (address: string) => async (dispatch: any) => {
  try {
    const dataWithdrawalHistory = await fetchDataWithdrawalHistory(address);
    const dataCompetitionBalance = await fetchDataCompetitionBalance(address);
    let history: WithdrawalTx[] = [];
    if (!_.isEmpty(dataWithdrawalHistory)) {
      history = dataWithdrawalHistory.map((w) => ({
        txId: w.txId,
        amount: w.amount.replaceAll('-', ''),
        time: w.CreatedAt,
      }));
    }
    dispatch(setBalance(Number(dataCompetitionBalance)));
    dispatch(setWithdrawalHistory(history));
  } catch (e) {}
};

export default referralCompetitionSlice.reducer;
