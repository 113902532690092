import { Flex, Image, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

interface VaultItemProps {
  vault: any;
  account: any;
  renderValues: any;
  value: any;
  valueUsd: any;
}

const VaultItem: React.FC<VaultItemProps> = ({ vault, value, valueUsd, renderValues }) => {
  const whiteToGray700 = useColorModeValue('gray.700', 'white');

  return (
    <Flex mt={['6px', '10px', '10px', '10px']}>
      <Flex mt={['0px', '5px', '5px', '5px']} align="center">
        <Flex>
          <Image src={vault?.images} w="40px" h="40px" />
        </Flex>
        <Flex ml="10px">
          <Text fontFamily={'Poppins'} fontStyle={'normal'} fontWeight={'500'} fontSize={'14px'} color={whiteToGray700}>
            {vault?.token?.symbol}
          </Text>
        </Flex>
      </Flex>
      <Spacer />
      <Flex direction={'column'} mt={['0px', '10px', '10px', '10px']} mr="4px">
        <Flex justifyContent={'flex-end'}>
          <Text fontFamily={'Poppins'} fontStyle={'normal'} fontWeight={'600'} fontSize={'14px'} color={whiteToGray700}>
            {renderValues(value)} {vault?.token?.symbol}
          </Text>
        </Flex>
        <Flex justifyContent={'flex-end'} mt={['0px', '5px', '5px', '5px']}>
          <Text fontFamily={'Poppins'} fontStyle={'normal'} fontWeight={'500'} fontSize={'12px'} color={'cyan.400'}>
            ~$
            {renderValues(valueUsd)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default VaultItem;
