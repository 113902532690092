import { ILiquidityPoolDetail } from '../redux/types';

const poolsSpotExchange: ILiquidityPoolDetail[] = [
  {
    pid: 1,
    apr: 0,
    aprReward: 0,
    aprHistory: {
      7: 0,
      30: 0,
    },
    poolKey: {
      56: '0x260315a7879e77c7a7b30904fb8bdd5a588fb42d64cceb3329777da7f22ec636',
      97: '0x2d58f75b41e7f28e9e8b9faee88c7be43f73614f3210928fa86e8dd2f7fa4984',
    },
    pair: {
      base: {
        contract_address: {
          56: '0x55d398326f99059ff775485246999027b3197955',
          97: '0xBB88DCC878aE3d28e5D20aBF8057edd2f623Be85',
        },
        name: 'USDT',
        symbol: 'USDT',
      },
      quote: {
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
        name: 'BUSD',
        symbol: 'BUSD',
      },
    },
    liquidity: {
      base: 0,
      quote: 0,
      total: 0,
    },
    pairManager: '0x18da82E287Dc9F684E437c55448F2AF9EEED29d7',
    strategy: '0x0000000000000000000000000000000000000000',
    totalRewards: 0,
    rewardInStaking: 0,
    rewardPoolAddress: {
      56: '0xccc1c1538f8870018239fb1b78a0758b7de80bfa',
      97: '0xc23faf9fe32030d353e3ecf5a6b0b0ab85e6f7e2',
    },
    liquidityPoolAddress: {
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
    },
    userData: {
      stakedBalance: 0,
      balance: {
        base: 0,
        quote: 0,
      },
    },
    limitLiquidity: 1000,
    totalQuoteDeposited: 0,
    limitLiquidityPool: 1000,
  },
  {
    pid: 2,
    apr: 0,
    aprReward: 0,
    aprHistory: {
      7: 0,
      30: 0,
    },
    poolKey: {
      56: '0xa7ebf223e72da7dd732b45a877fa9d42f47d145d747eece22972ddd118a3e143',
      97: '0x2d58f75b41e7f28e9e8b9faee88c7be43f73614f3210928fa86e8dd2f7fa4984',
    },
    pair: {
      base: {
        contract_address: {
          56: '0x55d398326f99059ff775485246999027b3197955',
          97: '0xBB88DCC878aE3d28e5D20aBF8057edd2f623Be85',
        },
        name: 'USDT',
        symbol: 'USDT',
      },
      quote: {
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
        name: 'BUSD',
        symbol: 'BUSD',
      },
    },
    liquidity: {
      base: 0,
      quote: 0,
      total: 0,
    },
    pairManager: '0x18da82E287Dc9F684E437c55448F2AF9EEED29d7',
    strategy: '0x0000000000000000000000000000000000000000',
    totalRewards: 0,
    rewardInStaking: 0,
    rewardPoolAddress: {
      56: '0xaa60a6f62e39a706594ef60dad5c13e709edd2d6',
      97: '0x4925bd0b4f23084f2f17e80ff0efdec8dea2a26c',
    },
    liquidityPoolAddress: {
      56: '0x7fdb2e6e1e5300d54724b15bb4bb0b01ccba9567',
      97: '0x7fdb2e6e1e5300d54724b15bb4bb0b01ccba9567',
    },
    userData: {
      stakedBalance: 0,
      balance: {
        base: 0,
        quote: 0,
      },
    },
    limitLiquidity: 1000,
    totalQuoteDeposited: 0,
    limitLiquidityPool: 1000,
  },
  {
    pid: 3,
    apr: 0,
    aprReward: 0,
    aprHistory: {
      7: 0,
      30: 0,
    },
    poolKey: {
      56: '0xce20591cf15628214df53bec1367987d2551816c6f3ed2031487d14c30752f1a',
      97: '0x02b8c479ff59019b45bf22a932df67204406498f993a8d7f27945544b83b0305',
    },
    pair: {
      base: {
        contract_address: {
          56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
          97: '0xea8Aa8d9E0D13afcD0020648eE9B4e037D609422',
        },
        name: 'DAI',
        symbol: 'DAI',
      },
      quote: {
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
        name: 'BUSD',
        symbol: 'BUSD',
      },
    },
    liquidity: {
      base: 0,
      quote: 0,
      total: 0,
    },
    rewardInStaking: 0,
    pairManager: '0x68f0207F43a22361349f0763D40f80D72d2a14A0',
    strategy: '0x0000000000000000000000000000000000000000',
    totalRewards: 0,
    rewardPoolAddress: {
      56: '0xccc1c1538f8870018239fb1b78a0758b7de80bfa',
      97: '0xc23faf9fe32030d353e3ecf5a6b0b0ab85e6f7e2',
    },
    userData: {
      stakedBalance: 0,
      balance: {
        base: 0,
        quote: 0,
      },
    },
    liquidityPoolAddress: {
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
    },
    limitLiquidity: 1000,
    totalQuoteDeposited: 0,
    limitLiquidityPool: 1000,
  },
  {
    pid: 4,
    apr: 0,
    aprReward: 0,
    aprHistory: {
      7: 0,
      30: 0,
    },
    poolKey: {
      56: '0xbae21f18595bafa583945749d5e6f324e9ad2059939028cd224fd046d3b0c96c',
      97: '0x0384b534c58e593e3dcdcf756030a2808baff51a49242ca188c0078f241a7436',
    },
    pair: {
      base: {
        contract_address: {
          56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
          97: '0xAD2692a35dD50d3f9078C5eD186C70c3f445ECc0',
        },
        name: 'USDC',
        symbol: 'USDC',
      },
      quote: {
        contract_address: {
          56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          97: '0xA17B8ef86694ebA2bC50ac6bf901D39D0728E3C1',
        },
        name: 'BUSD',
        symbol: 'BUSD',
      },
    },
    liquidity: {
      base: 0,
      quote: 0,
      total: 0,
    },
    rewardInStaking: 0,
    pairManager: '0x19770602c74Cea3684bE6d38ca40AcEa711Fd94b',
    strategy: '0x0000000000000000000000000000000000000000',
    totalRewards: 0,
    rewardPoolAddress: {
      56: '0xccc1c1538f8870018239fb1b78a0758b7de80bfa',
      97: '0xc23faf9fe32030d353e3ecf5a6b0b0ab85e6f7e2',
    },
    userData: {
      stakedBalance: 0,
      balance: {
        base: 0,
        quote: 0,
      },
    },
    liquidityPoolAddress: {
      56: '0x18859fdd32fc19b21c353fc9fa06c7585e1805a2',
      97: '0x5ae589e69b46c81690ef4b45f6c7dbeb38abfdc1',
    },
    limitLiquidity: 1000,
    totalQuoteDeposited: 0,
    limitLiquidityPool: 1000,
  },
];

export default poolsSpotExchange;
