import BigNumber from 'bignumber.js';
import erc20 from '../../configs/abi/erc20.json';
import masterchefABI from '../../configs/abi/masterchef.json';
import masterchefCakeABI from '../../configs/abi/masterchefCake.json';
import multicall from '../../utils/multicall';
import { getAddress, getMasterchefCakeAddress } from '../../utils/addressHelpers';
import farmsConfig from '../../configs/constants/farms';
import { getFarmApy, getFarmCakeApr } from '../../utils/apy';
import { formatBigNumber } from '../../hooks/useModalInfo';
import { TYPE_OF_FARM } from '../../configs/constants/types';
import tokens from '../../configs/constants/tokens';
import vaultAbiBUSD from '../../configs/abi/vaultAbiBUSD.json';

const fetchFarms = async (listPrices) => {
  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      if (farmConfig.type === TYPE_OF_FARM.MANUAL) {
        const lpAddress = getAddress(farmConfig.lpAddresses);
        const stakingManager = getAddress(farmConfig.stakingManager);
        const quoteTokenAddress =
          farmConfig?.quoteToken?.symbol === 'wBNB'
            ? getAddress(tokens.bnb.address)
            : getAddress(farmConfig.quoteToken.address);
        const calls = [
          // Balance of token in the LP contract
          {
            address: getAddress(farmConfig.token.address),
            name: 'balanceOf',
            params: [lpAddress],
          },
          // Balance of quote token on LP contract
          {
            address: quoteTokenAddress,
            name: 'balanceOf',
            params: [lpAddress],
          },
          // Balance of LP tokens in the master chef contract
          {
            address: lpAddress,
            name: 'balanceOf',
            params: [stakingManager],
          },
          // Total supply of LP tokens
          {
            address: lpAddress,
            name: 'totalSupply',
          },
        ];
        const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply] = await multicall(erc20, calls);
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply));
        // Total value in staking in quote token value
        const lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
          .div(new BigNumber(10).pow(18))
          .times(new BigNumber(2))
          .times(lpTokenRatio);
        const rawQuoteTokenBalance = new BigNumber(quoteTokenBalanceLP).div(new BigNumber(10).pow(18));
        const rawTokenBalance = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(18));

        const [info, totalAllocPoint, totalPositionPerBlockRaw] = await multicall(masterchefABI, [
          {
            address: stakingManager,
            name: 'poolInfo',
            params: [farmConfig.pid],
          },
          {
            address: stakingManager,
            name: 'totalAllocPoint',
          },
          {
            address: stakingManager,
            name: 'positionPerBlock',
            params: [],
          },
        ]);

        const allocPoint = new BigNumber(info.allocPoint._hex);
        const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint));

        const quoteTokenPriceUsd =
          listPrices[
            farmConfig.quoteToken.symbol.toLowerCase() === 'wbnb' ? 'bnb' : farmConfig.quoteToken.symbol.toLowerCase()
          ];

        const totalPositionPerBlock = formatBigNumber(new BigNumber(totalPositionPerBlockRaw[0]._hex));
        const rewardPrice =
          listPrices[
            (farmConfig?.earnedToken && farmConfig?.earnedToken.symbol.toLowerCase()) ||
              (farmConfig?.token && farmConfig?.token.symbol.toLowerCase()) ||
              'ptx'
          ];
        const totalLiquidity = new BigNumber(lpTotalInQuoteToken).times(quoteTokenPriceUsd);
        const apr = getFarmApy(
          poolWeight as BigNumber,
          rewardPrice,
          totalLiquidity,
          new BigNumber(totalPositionPerBlock),
        );
        let cakeApr = 0;
        if (!!farmConfig.pancakePid) {
          const arrConfig = farmConfig.pancakePid.split('_');
          const cakePid = Number(arrConfig[0]);
          const lpAddressCake = arrConfig[1];
          const masterchefCakeAddress = getMasterchefCakeAddress();
          const [info, totalAllocPointCake] = await multicall(masterchefCakeABI, [
            {
              address: masterchefCakeAddress,
              name: 'poolInfo',
              params: [cakePid],
            },
            {
              address: masterchefCakeAddress,
              name: 'totalAllocPoint',
            },
          ]);
          const [quoteTokenBalanceLPCake] = await multicall(erc20, [
            {
              address: getAddress(farmConfig.quoteToken.address),
              name: 'balanceOf',
              params: [lpAddressCake],
            },
          ]);

          const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLPCake).div(new BigNumber(10).pow(18));
          // Amount of quoteToken in the LP that are staked in the MC
          const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio);

          // Total staked in LP, in quote token value
          const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2));

          const allocPointCake = info ? new BigNumber(info.allocPoint?._hex) : new BigNumber(0);
          const poolWeightCake = totalAllocPointCake ? allocPointCake.div(new BigNumber(totalAllocPointCake)) : 0;
          const totalLiquidityCake = new BigNumber(lpTotalInQuoteToken).times(quoteTokenPriceUsd);
          cakeApr = getFarmCakeApr(
            poolWeightCake as BigNumber,
            new BigNumber(listPrices['cake'] || 8),
            totalLiquidityCake,
          );
        }

        return {
          ...farmConfig,
          allocPoint: allocPoint.toJSON(),
          apr,
          aprInPancake: cakeApr,
          totalAllocPoint: new BigNumber(totalAllocPoint).toJSON(),
          rawQuoteTokenBalance: new BigNumber(rawQuoteTokenBalance).toJSON(),
          rawTokenBalance: new BigNumber(rawTokenBalance).toJSON(),
        };
      } else {
        const posiStakingManager = getAddress(farmConfig.stakingManager);

        const callWithPosiStaking = [
          {
            address: posiStakingManager,
            name: 'totalAllocPoint',
            params: [],
          },
          {
            address: posiStakingManager,
            name: 'positionPerBlock',
            params: [],
          },
          {
            address: posiStakingManager,
            name: 'poolInfo',
            params: [farmConfig.pid],
          },
          {
            address: posiStakingManager,
            name: 'userInfo',
            params: [0, getAddress(farmConfig.lpAddresses)],
          },
        ];
        const tokenAddress = getAddress(
          farmConfig?.token.symbol?.toLowerCase() === 'bnb' ? tokens.wbnb.address : farmConfig.token.address,
        );
        const callBalanceOfPair = [
          {
            address: getAddress(farmConfig.lpAddresses),
            name: 'balanceOf',
            params: [posiStakingManager],
          },
          {
            address: getAddress(farmConfig.lpAddresses),
            name: 'totalSupply',
            params: [],
          },
          {
            address: tokenAddress,
            name: 'balanceOf',
            params: [getAddress(farmConfig.lpAddresses)],
          },
        ];

        const [totalLPStakedRaw, lpTotalSupplyRaw, totalPosiStakedRaw] = await multicall(erc20, callBalanceOfPair);

        const totalLPStaked = new BigNumber(totalLPStakedRaw[0]._hex);
        const lpTotalSupply = new BigNumber(lpTotalSupplyRaw[0]._hex);

        const totalPosiStaked = new BigNumber(totalPosiStakedRaw[0]._hex);

        const [totalAllocPointRaw, totalPositionPerBlockRaw, posiBusdAllocPointRaw, userInfoVault] = await multicall(
          masterchefABI,
          callWithPosiStaking,
        );

        const totalAllocPoint = new BigNumber(totalAllocPointRaw[0]._hex);
        const totalPositionPerBlock = formatBigNumber(new BigNumber(totalPositionPerBlockRaw[0]._hex));
        const posiBusdAllocPoint = new BigNumber(posiBusdAllocPointRaw[1]._hex);
        const harvestInterval = new BigNumber(posiBusdAllocPointRaw[5]._hex).toNumber();
        const amountVaultInStaking = formatBigNumber(new BigNumber(userInfoVault[0]._hex));

        const vaultManager = getAddress(farmConfig.vaultManager);
        const callsActionArray = [
          {
            address: vaultManager,
            name: 'nearestCompoundingTime',
            params: [],
          },
          {
            address: vaultManager,
            name: 'totalSupply',
            params: [],
          },
          {
            address: vaultManager,
            name: 'rewardForCompounder',
            params: [],
          },
          {
            address: vaultManager,
            name: 'pendingPositionNextCompound',
            params: [],
          },
          {
            address: vaultManager,
            name: 'percentFeeForCompounding',
            params: [],
          },
        ];

        try {
          const [
            nearestCompoundingTimeRaw,
            totalSupplyRaw,
            rewardForCompounderRaw,
            pendingPositionNextCompoundRaw,
            percentFeeForCompoundingRaw,
          ] = await multicall(vaultAbiBUSD, callsActionArray);

          const nearestCompoundingTime: any = new BigNumber(nearestCompoundingTimeRaw[0]._hex).toFixed();
          const totalSupply = formatBigNumber(new BigNumber(totalSupplyRaw[0]._hex));
          const rewardForCompounder = formatBigNumber(new BigNumber(rewardForCompounderRaw[0]._hex));
          const pendingPositionNextCompound = formatBigNumber(new BigNumber(pendingPositionNextCompoundRaw[0]._hex));
          const percentFeeForCompounding: any = new BigNumber(percentFeeForCompoundingRaw[0]._hex).toFixed();

          const lpTokenRatio = new BigNumber(totalLPStaked).div(new BigNumber(lpTotalSupply));
          const lpTotalInQuoteToken = new BigNumber(totalPosiStaked)
            .div(new BigNumber(10).pow(18))
            .times(new BigNumber(2))
            .times(lpTokenRatio);

          const allocPoint = new BigNumber(posiBusdAllocPoint);
          const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint));
          const newTotalPosiStaked = formatBigNumber(totalLPStaked);

          const totalLiquidity = new BigNumber(lpTotalInQuoteToken).times(
            listPrices[farmConfig?.token.symbol?.toLowerCase()],
          );
          const rewardPrice = listPrices['ptx'];

          const apr = getFarmApy(
            poolWeight as BigNumber,
            rewardPrice,
            totalLiquidity,
            new BigNumber(totalPositionPerBlock),
          );

          return {
            ...farmConfig,
            nearestCompoundingTime,
            apr,
            totalAllocPoint: totalSupply,
            poolWeight: poolWeight.toJSON(),
            posiPerBlock: totalPositionPerBlock,
            lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
            rewardForCompounder,
            pendingPositionNextCompound,
            percentFeeForCompounding,
            harvestInterval,
            amountVaultInStaking,
            totalPosiStaked: newTotalPosiStaked,
          };
        } catch (e) {}
      }
    }),
  );
  return data;
};

export default fetchFarms;
