import React from 'react';
import { Flex, Image, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import Typography from '../Typography';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../Button';
import ItemFooter from './ItemFooter';
import DividerGradient from '../DividerGradient';
import { listItemFooter, socialNetworkIcons } from '../../configs/constants/home';
import ImageHoverComponents from '../ImageHover';
import { registerToken } from '../../utils/wallet';
import PriceComponent from '../PriceComponent';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Footer: React.FC = () => {
  const { t } = useTranslation(['swap', 'home']);
  const [isLargerThan1024] = useMediaQuery('(max-width: 1024px ) and (min-width: 0px )');
  const [isLargerThan700] = useMediaQuery('(max-width: 700px ) and (min-width: 0px )');
  const [isLargerThan360] = useMediaQuery('(max-width: 360px ) and (min-width: 0px )');
  const [isLargerThan340] = useMediaQuery('(max-width: 340px ) and (min-width: 0px )');

  const dataStatistics = useSelector((state: RootStateOrAny) => state?.home?.statistics);
  const { market_cap, max_supply, total_supply, circulating_supply, total_value_burned } = dataStatistics ?? {};
  const backgroundFooter = useColorModeValue('#fff', 'gray.850');
  const backgroundCardStatistic = useColorModeValue('#fff', 'gray.800');
  const gray500ToGray400 = useColorModeValue('gray.400', 'gray.500');
  const gray100ToGray700 = useColorModeValue('gray.700', 'gray.100');
  const gray700ToGray100 = useColorModeValue('gray.100', 'gray.700');
  const gray600ToGray400 = useColorModeValue('gray.400', 'gray.600');
  const gray700ToGray300 = useColorModeValue('gray.300', 'gray.700');
  const boxShadow = useColorModeValue('0px 9px 28px rgba(0, 0, 0, 0.08)', 'none');
  const bgHoverLogoMetamask = useColorModeValue('rgba(0, 0, 0, 0.3)', 'rgb(31 38 50)');
  const logoPositionDarkToLight = useColorModeValue('/img/logo_position_light.svg', '/img/logo_position_dark.svg');
  const history = useHistory();

  const renderItemSpacer = (title: string, value: number, prefix?: string, price?: number) => {
    return (
      <Flex mt="12px" w="100%" justifyContent={'space-between'}>
        <Typography w="60%" type="body2" color={gray500ToGray400}>
          {t(`home:${title}`)}
        </Typography>
        <Flex w="40%">
          <PriceComponent
            styles={{
              color: gray100ToGray700,
              fontSize: '14px',
              fontWeight: '500',
            }}
            priceOfCoin={price || 1}
            value={Number(value) || 0}
            decimals="2"
            prefix={prefix || ''}
          />
        </Flex>
      </Flex>
    );
  };

  const renderImage = (ml?: string) => {
    const handleLinkToExchange = () => {
      history.push('/swap');
    };

    const handleAddToken = () => {
      registerToken('0x5ca42204cdaa70d5c773946e69de942b85ca6706', 'POSI', 18, 'https://position.exchange/favicon.ico');
    };

    return (
      <Flex>
        <Flex
          mr={isLargerThan340 ? '16px' : '28px'}
          ml={ml || isLargerThan340 ? '16px' : '28px'}
          bg={gray700ToGray100}
          align={'center'}
          _hover={{ bg: bgHoverLogoMetamask }}
          justify="center"
          h="40px"
          w={'40px'}
          cursor="pointer"
          borderRadius="10px"
          onClick={handleAddToken}
        >
          <Image boxSize={'28px'} src="/images/logoPartner/logo_metamask.svg" />
        </Flex>

        <ButtonComponent
          w={[isLargerThan360 ? '107px' : '131px', '158px', '158px', '131px']}
          onClick={handleLinkToExchange}
          variant="contained"
          size="button2"
        >
          {t('home:buy_posi')}
        </ButtonComponent>
      </Flex>
    );
  };

  return (
    <Flex bg={backgroundFooter} w="100%" pb="80px" pt="32px" px={['16px', '16px', '24px', '24px', '0px']}>
      <Flex direction={'column'} maxW={'1200px'} w="100%" mx="auto">
        <Flex
          direction={isLargerThan1024 || isLargerThan700 ? 'column' : 'row'}
          w="100%"
          justifyContent="space-between"
        >
          <Flex
            justifyContent={'space-between'}
            w={isLargerThan360 ? '100%' : isLargerThan1024 || isLargerThan700 ? '95%' : '30%'}
            direction={isLargerThan1024 && !isLargerThan700 ? 'row' : 'column'}
          >
            <Flex direction={'column'}>
              <Flex>
                <Image src={logoPositionDarkToLight} />
                {(!isLargerThan1024 || isLargerThan700) && renderImage()}
              </Flex>
              <Typography
                my="20px"
                w={['100%', '100%', '270px', '100%', '100%']}
                type="paragraph2"
                color={gray600ToGray400}
              >
                {t('home:exchange_platform')}
              </Typography>
              {isLargerThan1024 && !isLargerThan700 && renderImage('0px')}
            </Flex>
            <Flex
              w={
                isLargerThan360
                  ? '100%'
                  : isLargerThan700
                  ? ['95%', '95%', '95%']
                  : ['40%', '40%', '40%', isLargerThan1024 ? '40%' : '100%']
              }
              // w={isLargerThan1024 && !isLargerThan700 ? '40%' : isLargerThan700 ? ['95%', '95%', '90%'] : 'auto'}
              direction={'column'}
              borderRadius={'16px'}
              bg={backgroundCardStatistic}
              boxShadow={boxShadow}
              px="20px"
              pt="4px"
              pb="16px"
              // w="100%"
            >
              {renderItemSpacer('max_supply', max_supply)}
              {renderItemSpacer('total_supply', total_supply)}
              {renderItemSpacer('circulating_supply', circulating_supply)}
              {renderItemSpacer('total_burned', total_value_burned)}
              {renderItemSpacer('market_cap', market_cap, '$')}
            </Flex>
          </Flex>
          <Flex hidden={isLargerThan700} w={isLargerThan1024 ? '95%' : '65%'} justifyContent="space-between">
            {listItemFooter.map((e, index) => {
              return <ItemFooter title={t(e.title)} content={e.content} key={index} />;
            })}
          </Flex>
          {isLargerThan700 && (
            <>
              {listItemFooter.map((e, index) => {
                return <ItemFooter isMobile={true} title={t(e.title)} content={e.content} key={index} />;
              })}
            </>
          )}
        </Flex>
        <DividerGradient mt="48px" mb="32px" color={gray700ToGray300} />
        <Flex direction={isLargerThan700 ? 'column' : 'row'} w="100%" justifyContent={'space-between'}>
          <Typography type="paragraph2" letterSpacing={'0.2em'} color={'gray.500'}>
            {t('home:des_footer')}
          </Typography>
          <Flex
            boxSize={'28px'}
            mt={isLargerThan700 && '12px'}
            w={['60%', isLargerThan700 ? '40%' : '30%', '30%', '20%']}
            justify={'space-between'}
          >
            {socialNetworkIcons?.map((e, index) => {
              const imgDarkToLight = useColorModeValue(e?.iconLight, e?.iconDark);
              const boxShadow = useColorModeValue('drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1))', 'none');
              return (
                <ImageHoverComponents
                  link={e.href}
                  filter={boxShadow}
                  index={index}
                  imgUrlHover={e.iconHover}
                  imgUrl={imgDarkToLight}
                  key={`icons_social_${index}`}
                />
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
