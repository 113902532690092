import BridgeAssetAdapter from '../BridgeAssetAdapter';
import BNBChain from '../models/BNBChain';
import POSIChain from '../models/POSIChain';
import { ChainTypes } from '../types';
import bridgeAssetAbi from '../../configs/abi/bridgeAsset.json';
import bridgeAssetPSCAbi from '../../configs/abi/bridgeAssetPSC.json';
import addresses from '../../configs/constants/contracts';

export const bnbChain = new BNBChain(
  ChainTypes.BNB,
  process.env.REACT_APP_NETWORK_URL,
  Number(process.env.REACT_APP_CHAIN_ID),
  [process.env.REACT_APP_NODE_1, process.env.REACT_APP_NODE_2, process.env.REACT_APP_NODE_3],
  JSON.parse(process.env.REACT_APP_BSC_CHAIN_ID_SUPPORTED || '[56, 97]'),
  {
    56: 'Binance Smart Chain Mainnet',
    97: 'Binance Smart Chain Testnet',
  },
  ChainTypes.BNB,
  process.env.REACT_APP_BSC_EXPLORER,
  bridgeAssetAbi,
  addresses.bridgeAssetBSC,
);

const posiChain = new POSIChain(
  ChainTypes.POSI,
  process.env.REACT_APP_POSI_NETWORK_URL,
  Number(process.env.REACT_APP_POSI_CHAIN_ID),
  [process.env.REACT_APP_POSI_NODE_1, process.env.REACT_APP_POSI_NODE_2, process.env.REACT_APP_POSI_NODE_3],
  JSON.parse(process.env.REACT_APP_POSI_CHAIN_ID_SUPPORTED || '[920000,910000,900000]'),
  {
    920000: 'POSI Chain Devnet',
    910000: 'POSI Chain Testnet',
    900000: 'POSI Chain Mainnet',
  },
  ChainTypes.POSI,
  process.env.REACT_APP_POSI_EXPLORER,
  bridgeAssetPSCAbi,
  addresses.bridgeAssetPSC,
);

const adapter = new BridgeAssetAdapter([bnbChain, posiChain]);

export const configNetworkByChainId = {
  [Number(process.env.REACT_APP_POSI_CHAIN_ID)]: posiChain,
  [Number(process.env.REACT_APP_CHAIN_ID)]: bnbChain,
  56: bnbChain,
};

export default adapter;
