import { Subject } from 'rxjs';

const subject = new Subject();

export const switchNetworkTransporter = {
  sendMessage: (chainDetail) => {
    subject.next(chainDetail);
  },
  clearMessages: () => subject.next(),
  onMessage: () => subject.asObservable(),
};
