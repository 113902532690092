import {
  getCurrency,
  listMenu,
  IconFooter,
  ItemFooter,
  Language,
  ItemCheckBoxFeedback,
  ItemListPartner,
  ItemListNeedHelp,
} from './type';

export const listLanguage: Language[] = [
  {
    title: 'English',
    language: 'en',
    image: 'language-english',
  },
];

export const getCurrencys: getCurrency[] = [
  {
    titleCurrency: 'BNB',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'GLX',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'POSI',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'ETH',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'ADA',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'SOL',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'CAKE',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'BTC',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'BUSD',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
  {
    titleCurrency: 'USDT',
    amount: '1000',
    iamgeCurrency: 'position-coin',
  },
];

export const listMenus: listMenu[] = [
  {
    title: 'Trading',
  },
  {
    title: 'Exchange',
  },
  {
    title: 'Swap',
  },
  {
    title: 'Pools',
  },
  {
    title: 'Docs',
  },
  {
    title: 'Chart',
  },
];
export const socialNetworkIcons: IconFooter[] = [
  {
    iconDark: '/images/logoSocial/social_youtube_dark.svg',
    iconHover: '/images/logoSocial/social_youtube_hover.svg',
    iconLight: '/images/logoSocial/social_youtube_light.svg',
    href: 'https://www.youtube.com/@PositionExchange',
  },
  {
    iconDark: '/images/logoSocial/social_discord_dark.svg',
    iconHover: '/images/logoSocial/social_discord_hover.svg',
    iconLight: '/images/logoSocial/social_discord_light.svg',
    href: 'https://discord.gg/position-exchange',
  },
  {
    iconDark: '/images/logoSocial/social_reddit_dark.svg',
    iconHover: '/images/logoSocial/social_reddit_hover.svg',
    iconLight: '/images/logoSocial/social_reddit_light.svg',
    href: 'https://www.reddit.com/r/PositionExchange/',
  },
  {
    iconDark: '/images/logoSocial/social_telegram_dark.svg',
    iconLight: '/images/logoSocial/social_telegram_light.svg',
    iconHover: '/images/logoSocial/social_telegram_hover.svg',
    href: 'https://t.me/PositionExchange',
  },
  {
    iconDark: '/images/logoSocial/social_twitter_dark.svg',
    iconLight: '/images/logoSocial/social_twitter_light.svg',
    iconHover: '/images/logoSocial/social_twitter_hover.svg',
    href: 'https://twitter.com/PositionEx',
  },
  {
    iconLight: '/images/logoSocial/social_medium_light.svg',
    iconDark: '/images/logoSocial/social_medium_dark.svg',
    iconHover: '/images/logoSocial/social_medium_hover.svg',
    href: ' https://positionex.medium.com',
  },
  {
    iconLight: '/images/logoSocial/social_github_light.svg',
    iconDark: '/images/logoSocial/social_github_dark.svg',
    iconHover: '/images/logoSocial/social_github_hover.svg',
    href: 'https://github.com/PositionExchange',
  },
];

export const listItemFooter: ItemFooter[] = [
  {
    title: 'Trading',
    content: [
      { title: 'Forum', href: 'https://forum.position.exchange/', active: true },
      { title: 'Voting', href: 'https://position.exchange/governance/core', active: true },
      { title: 'Announcements', href: 'https://t.me/PositionAnn', active: true },
    ],
  },
  {
    title: 'About Us',
    content: [
      { title: 'Documentation', href: ' https://docs.position.exchange', active: true },
      { title: 'Position Exchange', href: 'https://position.exchange/', active: true },
      {
        title: 'POSI token',
        href: 'https://bscscan.com/token/0x5ca42204cdaa70d5c773946e69de942b85ca6706',
        active: true,
      },
      { title: 'Whitepaper', href: '/whitepaper.pdf', active: true },
    ],
  },
  {
    title: 'Support',
    content: [
      { title: 'Help Center', active: false },
      { title: 'Contact Us', href: 'mailto:contact@position.exchange', active: true },
      {
        title: 'Feedback',
        href: 'https://forum.position.exchange/c/ideas-suggestions/5',
        active: true,
      },
    ],
  },
  {
    title: 'Products',
    content: [
      { title: 'Trading', href: 'https://position.exchange/trading', active: true },
      { title: 'Farming', href: 'https://position.exchange/farming', active: true },
      { title: 'Staking', href: 'https://position.exchange/pools', active: true },
      { title: 'NFTs', href: 'https://position.exchange/nfts', active: true },
    ],
  },
  {
    title: 'Blog',
    content: [
      { title: 'News', href: ' https://positionex.medium.com', active: true },
      { title: 'FAQ', href: 'https://docs.position.exchange/faq', active: true },
    ],
  },
];

export const ListModalRanting = [
  {
    imgUrl: {
      on: 'icon_star_on',
      off: 'icon_star_off',
    },
  },
  {
    imgUrl: {
      on: 'icon_star_on',
      off: 'icon_star_off',
    },
    mx: '20px',
  },
  {
    imgUrl: {
      on: 'icon_star_on',
      off: 'icon_star_off',
    },
  },
  {
    imgUrl: {
      on: 'icon_star_on',
      off: 'icon_star_off',
    },
    mx: '20px',
  },
  {
    imgUrl: {
      on: 'icon_star_on',
      off: 'icon_star_off',
    },
  },
];

export const StatusRating = {
  1: 'very_bad',
  2: 'bad',
  3: 'good',
  4: 'very_good',
  5: 'excellent',
};

export const ListCheckBoxFeedBack: ItemCheckBoxFeedback[] = [
  {
    title: 'checkbox_feedback_1',
    name: 'checkBox1',
    value: 'It’s hard to navigate the page I want',
  },
  {
    title: 'checkbox_feedback_2',
    name: 'checkBox2',
    value: 'The new Menu Bar take too many space',
  },
  {
    title: 'checkbox_feedback_3',
    name: 'checkBox3',
    value: 'The new Menu Bar make the website take long to load',
  },
  {
    title: 'checkbox_feedback_4',
    name: 'checkBox4',
    value: 'Sometime the Menu Bar is hiding. I want it alway be there',
  },
  {
    title: 'checkBox5',
    name: 'checkBox5',
  },
];

export const ListMenuDesktop = [
  {
    title: '',
    className: 'intro_more',
    activeMenu: 'governance',
    item: [
      {
        title: 'bridge',
        imgUrl: 'icon_bonds_exchange',
        description: 'bridge-description',
        href: '/bridge',
        activeItem: 'bridge',
      },
      {
        title: 'governance',
        imgUrl: 'governance',
        description: 'have',
        href: '/governance/core',
        activeItem: 'governance',
      },
      {
        title: 'Docs',
        imgUrl: 'docs',
        description: 'everything',
        href: 'https://docs.position.exchange/',
        isLink: true,
      },
      {
        title: 'Whitepaper',
        imgUrl: 'whitepaper',
        href: '/whitepaper.pdf',
        description: 'about_project',
        isLink: true,
      },
    ],
  },
  {
    title: 'Swap',
    activeMenu: ['/swap'],
    href: '/swap',
    item: [],
  },
  {
    title: 'Trade',
    activeMenu: 'trade-v1',
    item: [
      {
        title: 'Trade v2',
        isLink: true,
        imgUrl: 'icon-spot',
        description: 'spot_des_v2',
        href: 'https://position.exchange/trade',
      },
      { title: 'Trade v1', imgUrl: 'icon-spot', description: 'spot_des', href: '/trade-v1?type=stable' },
    ],
  },
  {
    title: 'earn',
    className: 'intro_earn',
    activeMenu: ['/farming', '/pools', '/futures-staking'],
    item: [
      { title: 'farm', imgUrl: 'farm', description: 'earn_by_staking', href: '/farming' },
      { title: 'pool', imgUrl: 'pool', description: 'earn_by', href: '/pools' },
      {
        title: 'futures_staking',
        imgUrl: 'futures_staking',
        description: 'earn_by_futures_staking',
        href: 'https://position.exchange/futures-staking',
        isLink: true,
      },
    ],
  },
  {
    title: 'derivatives',
    item: [
      {
        title: 'busd-m',
        imgUrl: 'usd-m',
        description: 'busd-m-des',
        isLink: true,
        href: 'https://futures.position.exchange/USD-M',
      },
      {
        title: 'coin-m',
        imgUrl: 'coin-m',
        description: 'coin-m-des',
        isLink: true,
        isNew: true,
        href: 'https://futures.position.exchange/COIN-M/POSI/BUSD',
      },
    ],
  },
  {
    title: 'win',
    className: 'intro_win',
    activeMenu: ['/competition/referral', '/competition/buying'],
    item: [
      {
        title: 'referral_competition',
        imgUrl: 'logo_referral_competition',
        description: 'invite_other',
        href: '/competition/referral',
      },
      {
        title: 'buying_competition',
        imgUrl: 'logo_buying_competition',
        description: 'description_buying',
        href: '/competition/buying',
      },
    ],
  },
  {
    title: 'NFTs',
    className: 'intro_nfts',
    activeMenu: ['/nfts', '/nft/marketplace/auction'],
    item: [
      { title: 'cast_nfts', imgUrl: 'cast_nfts', description: 'cast_own_nfts', href: '/nfts' },
      {
        title: 'market_place',
        imgUrl: 'nfts_market_place',
        description: 'buy_sell',
        href: '/nft/marketplace/auction',
      },
    ],
  },
  {
    title: 'bonds',
    activeMenu: 'bond',
    isNew: true,
    item: [
      { title: 'all_bonds', imgUrl: 'icon_bonds', description: 'all_bonds_description', href: '/bonds', isBond: true },
      { title: 'bond_p2p', imgUrl: 'icon_bonds', description: 'des_bond_p2p', href: '/bonds/p2p', isNew: true },
      {
        title: 'exchange_bond',
        imgUrl: 'icon_bonds_exchange',
        description: 'exchange_bond_des',
        href: '/bond-exchange?type=bond',
        isBondExchange: '/bond-exchange/',
      },
      { title: 'launchpad_bond', imgUrl: 'icon_bonds', description: 'launchpad_bond_des', href: '/launchpad/bond' },
    ],
  },
  {
    title: 'referral',
    activeMenu: '/referral',
    href: '/referral',
    item: [],
  },
];

export const ListMenuMobile = [
  {
    title: 'trade',
    className: 'intro_1',
    activeMenu: ['/trade-v1/', '/swap/', '/launchpad/bond'],
    isBondExchange: ['/bond-exchange'],
    item: [
      { title: 'swap', imgUrl: 'classic-swap', description: 'swap_your', href: '/swap' },
      {
        title: 'busd-m',
        imgUrl: 'usd-m',
        description: 'busd-m-des',
        isLink: true,
        href: 'https://futures.position.exchange/USD-M',
      },
      {
        title: 'coin-m',
        imgUrl: 'coin-m',
        description: 'coin-m-des',
        isLink: true,
        isNew: true,
        href: 'https://futures.position.exchange/COIN-M/POSI/BUSD',
      },
      {
        title: 'spot',
        imgUrl: 'icon-spot',
        description: 'spot_des',
        href: '/trade-v1',
        isBondExchange: '/trade/',
      },
      {
        title: 'Spot v2',
        imgUrl: 'icon-spot',
        description: 'spot_des_v2',
        isLink: true,
        href: 'https://position.exchange/trade',
      },
      {
        title: 'exchange_bond',
        imgUrl: 'icon_bonds_exchange',
        description: 'exchange_bond_des',
        href: '/bond-exchange',
      },
      { title: 'launchpad_bond', imgUrl: 'icon_bonds', description: 'launchpad_bond_des', href: '/launchpad/bond' },
    ],
  },

  {
    title: 'earn',
    className: 'intro_2',
    activeMenu: ['/farming', '/pools', '/referral', '/bonds', '/bonds/p2p'],
    item: [
      { title: 'farm', imgUrl: 'farm', description: 'earn_by_staking', href: '/farming' },
      { title: 'pool', imgUrl: 'pool', description: 'earn_by', href: '/pools' },
      {
        title: 'referral',
        imgUrl: 'logo_referral_competition',
        description: 'description_referral',
        href: '/referral',
      },
      { title: 'all_bonds', imgUrl: 'icon_bonds', description: 'all_bonds_description', href: '/bonds', isBond: true },
      { title: 'bond_p2p', imgUrl: 'icon_bonds', description: 'des_bond_p2p', href: '/bonds/p2p', isNew: true },
      {
        title: 'futures_staking',
        imgUrl: 'futures_staking',
        description: 'earn_by_futures_staking',
        href: 'https://position.exchange/futures-staking',
        isLink: true,
      },
    ],
  },
  {
    title: 'win',
    className: 'intro_3',
    activeMenu: ['/competition/referral', '/competition/buying'],
    item: [
      {
        title: 'referral_competition',
        imgUrl: 'logo_referral_competition',
        description: 'invite_other',
        href: '/competition/referral',
      },
      {
        title: 'buying_competition',
        imgUrl: 'logo_buying_competition',
        description: 'description_buying',
        href: '/competition/buying',
      },
    ],
  },
  {
    title: 'nft',
    className: 'intro_4',
    activeMenu: ['/nfts', '/nft/marketplace/auction'],
    item: [
      { title: 'cast_nfts', imgUrl: 'cast_nfts', description: 'cast_own_nfts', href: '/nfts' },
      {
        title: 'market_place',
        imgUrl: 'nfts_market_place',
        description: 'buy_sell',
        href: '/nft/marketplace/auction',
      },
    ],
  },
  {
    title: 'more',
    className: 'intro_5',
    activeMenu: 'governance',
    item: [
      {
        title: 'bridge',
        imgUrl: 'icon_bonds_exchange',
        description: 'bridge-description',
        href: '/bridge',
        activeItem: 'bridge',
      },
      {
        title: 'governance',
        imgUrl: 'governance',
        description: 'have',
        href: '/governance/core',
        activeItem: 'governance',
      },
      {
        title: 'Docs',
        imgUrl: 'docs',
        description: 'everything',
        href: 'https://docs.position.exchange/',
        isLink: true,
      },
      {
        title: 'Whitepaper',
        imgUrl: 'whitepaper',
        href: '/whitepaper.pdf',
        description: 'about_project',
        isLink: true,
      },
    ],
  },
];

export const ListLogoPartner: ItemListPartner[] = [
  {
    imgUrlHoverLight: '/images/logoPartner/logo_bsc_light.svg',
    imgUrlHover: '/images/logoPartner/logo_bsc.svg',
    imgUrl: '/images/logoPartner/logo_bsc_gray.svg',
    link: 'https://bscscan.com/token/0x5ca42204cdaa70d5c773946e69de942b85ca6706',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_certik_light.svg',
    imgUrlHover: '/images/logoPartner/logo_certik.svg',
    imgUrl: '/images/logoPartner/logo_certik_gray.svg',
    link: 'https://www.certik.com/projects/positionexchange',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_dapp_light.svg',
    imgUrlHover: '/images/logoPartner/logo_dapp.svg',
    imgUrl: '/images/logoPartner/logo_dapp_gray.svg',
    link: 'https://dappradar.com/binance-smart-chain/defi/position-exchange',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_coingecko_light.svg',
    imgUrlHover: '/images/logoPartner/logo_coingecko.svg',
    imgUrl: '/images/logoPartner/logo_coingecko_gray.svg',
    link: 'https://www.coingecko.com/en/coins/position-token',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_gate.svg',
    imgUrlHover: '/images/logoPartner/logo_gate.svg',
    imgUrl: '/images/logoPartner/logo_gate_gray.svg',
    link: 'https://www.gate.io/trade/POSI_USDT',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_trust_wallet_light.svg',
    imgUrlHover: '/images/logoPartner/logo_trust_wallet.svg',
    imgUrl: '/images/logoPartner/logo_trust_wallet_gray.svg',
    link: 'https://trustwallet.com/',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_bitcoin_light.svg',
    imgUrlHover: '/images/logoPartner/logo_bitcoin.svg',
    imgUrl: '/images/logoPartner/logo_bitcoin_gray.svg',
    link: 'https://news.bitcoin.com/position-exchange-the-new-next-gen-decentralized-trading-and-exchange-platform/',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_cointelegraph_light.svg',
    imgUrlHover: '/images/logoPartner/logo_cointelegraph.svg',
    imgUrl: '/images/logoPartner/logo_cointelegraph_gray.svg',
    link: 'https://cointelegraph.com/press-releases/position-exchange-the-new-decentralized-trading-protocol-on-the-bsc/',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_bnb_light.svg',
    imgUrlHover: '/images/logoPartner/logo_bnb.svg',
    imgUrl: '/images/logoPartner/logo_bnb_gray.svg',
    link: 'https://www.bnbchain.world/en',
  },
  {
    imgUrlHoverLight: '/images/logoPartner/logo_coinmarketcap_light.svg',
    imgUrlHover: '/images/logoPartner/logo_coinmarketcap.svg',
    imgUrl: '/images/logoPartner/logo_coinmarketcap_gray.svg',
    link: 'https://coinmarketcap.com/currencies/position-exchange/',
  },
];

export const listNeedHelp: ItemListNeedHelp[] = [
  {
    href: 'https://position.zendesk.com/hc/en-us/requests/new',
    title: 'chart_support',
    des: 'des_chart_support',
  },
  {
    href: 'https://position.zendesk.com/hc/en-us/sections/4706765996815-FAQ',
    title: 'faq',
    des: 'des_faq',
  },
  {
    href: 'https://positionex.medium.com/',
    title: 'blog',
    des: 'des_blog',
  },
  {
    href: 'https://docs.position.exchange/',
    title: 'docs',
    des: 'des_docs',
  },
];
