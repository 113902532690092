import { createSlice } from '@reduxjs/toolkit';
import { IInformationSwap } from '../types';
import { getDataRefunds } from './fetch';

const initialState: IInformationSwap = {
  gasRefund: null,
  searchTokenInput: '',
};

export const swapPageSlice = createSlice({
  name: 'swapPage',
  initialState,
  reducers: {
    setGasRefundState: (state, action) => {
      const data = state;
      data.gasRefund = action.payload;
      return data;
    },
    setSearchTokenInput: (state, action) => {
      const data = state;
      data.searchTokenInput = action.payload;
      return data;
    },
  },
});

export const { setGasRefundState, setSearchTokenInput } = swapPageSlice.actions;

export const fetchDataGasRefund = () => async (dispatch: any) => {
  const gasRefundData = await getDataRefunds();
  if (gasRefundData) {
    dispatch(setGasRefundState(gasRefundData ?? {}));
  }
};

export default swapPageSlice.reducer;
