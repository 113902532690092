import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { AppState } from '../redux/store';
import liquidityPoolAbi from '../configs/abi/exchange-liquidity-pool.json';
import liquidityNftManagerAbi from '../configs/abi/liquidity_nft_manager.json';
import useRefresh from './useRefresh';
import multicall from '../utils/multicall';
import BigNumber from 'bignumber.js';
import {
  setBalanceToPair,
  setCurrentPrice,
  setListPair,
  setMyLiquidity,
  setOpenOrders,
  setOpenPositionManagers,
  setPairDetailData,
  setStakedAmounts,
} from '../redux/bondExchange';
import { LiquidityStakeStatus, TypeOfOrderConfig, TypeOfTradeConfig } from '../configs/constants/bondExchange';
import { useMakeMarketOrder } from './useSpotExchangeHouse';
import liquidityNftStakingAbi from '../configs/abi/liquidityNftStaking.json';
import _, { flatMap, isEmpty, isEqual, uniq } from 'lodash';
import { getBalanceNumber, getFullDisplayBalance } from '../utils/formatBalance';
import { ILiquidityPoolDetail, LiquidityItem, PairExchange, PriceSide } from '../redux/types';
import useInterval from './useInterval';
import { useResponseModal } from '../utils/confirmModal';
import { useGetApiPrices } from '../redux/hooks';
import { getAddress, useGetLiquidityNftManager } from '../utils/addressHelpers';
import { formatNumberWithNumeral } from '../utils/common';
import { getSpotHouseAbi } from '../utils/abiHelper';
import tokens from '../configs/constants/tokens';
import Web3 from 'web3';
import { useTokenBalanceWithSimpleAddress } from '../redux/wallet/hooks';
import poolsSpotExchange from '../configs/poolsSpotExchange';

export const useListPairUpdateData = () => {
  const dispatch = useDispatch();
  useInterval(() => {
    if (window.listPairClass) {
      const listPairExchange = store.getState()?.bondExchange?.listOfPair || [];
      const pairDetailData = store.getState()?.bondExchange?.pairDetailData;
      const listRealTimeUpdate = window.listPairClass.getListUpdate(listPairExchange);
      if (listRealTimeUpdate.length > 0) {
        let count = 0;
        const newList = listPairExchange.map((item) => {
          const exist = listRealTimeUpdate.find((itemRealTime) => itemRealTime.address === item.spot_manager_address);

          if (exist) {
            if (
              exist?.change_24h !== item.change_24h ||
              exist?.price !== item.price ||
              exist?.quote_volume_24h !== item.quote_volume_24h ||
              exist?.base_volume_24h !== item.base_volume_24h
            ) {
              count++;
            }
            return {
              ...item,
              ...exist,
            };
          }
          return item;
        });
        if (count > 0) {
          const pairDetailUpdate =
            pairDetailData && newList?.find((e) => e?.spot_manager_address === pairDetailData?.spot_manager_address);
          if (pairDetailUpdate) {
            const newListPairDetail = {
              ...pairDetailData,
              ...pairDetailUpdate,
            };

            dispatch(setPairDetailData(newListPairDetail));
          }
          dispatch(setListPair(newList));
        }
      }
    }
  }, 1000);
};

export const useMarketTradeData = () => {
  const [listData, setListData] = useState([]);

  useInterval(() => {
    if (window.marketTrade) {
      const listRealTime = window.marketTrade.getListData();
      if (
        listRealTime.length &&
        listData.length &&
        listRealTime[0] &&
        listData[0] &&
        listRealTime[0].pair_symbol !== listData[0].pair_symbol
      ) {
        setListData([]);
      }
      if (
        listRealTime.length !== listData.length ||
        (listRealTime.length === listData.length &&
          listRealTime[0] &&
          listData[0] &&
          listRealTime[0].txHash !== listData[0].txHash)
      ) {
        setListData(listRealTime);
        store.dispatch(setCurrentPrice(listRealTime && listRealTime[0].price || 0));
      }
    }
  }, 1000);

  return useMemo(() => listData, [listData]);
};

export const useListPairExchange = () => {
  const listPairExchange = useSelector((state: AppState) => state.bondExchange.listOfPair) || [];

  return useMemo(() => listPairExchange, [listPairExchange]);
};

/**
 * @param {string[]} tokenAddressList
 * @description get balance of address, exclude ether
 */

const checkIsBNB = (address) => {
  if (address === getAddress(tokens.wbnb.address)) {
    return true;
  } else {
    return false;
  }
};

export const useUserTokenBalances = (tokenAddressList: string[]) => {
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const web3 = new Web3(Web3.givenProvider);

  const [balanceForm, setBalance] = useState([]);

  const balances = useTokenBalanceWithSimpleAddress(account, tokenAddressList);

  useEffect(() => {
    if (balances.length) {
      if (balanceForm.length === 0) {
        setBalance(balances);
        dispatch(
          setBalanceToPair({
            base: balances[0],
            quote: balances[1],
          }),
        );
      } else {
        if (balances[0] !== balanceForm[0] || balances[1] !== balanceForm[1]) {
          setBalance(balances);
          dispatch(
            setBalanceToPair({
              base: balances[0],
              quote: balances[1],
            }),
          );
        }
      }
    }
  }, [balances]);

  return useMemo(() => balanceForm, [balanceForm]);
};

export const useGetBalanceExchangeFromRedux = () => {
  const balanceExchange = useSelector((state: AppState) => state.bondExchange.balance);
  return useMemo(() => balanceExchange, [balanceExchange]);
};

export const useGetDataFilter = () => {
  const selectBond = useSelector((s: AppState) => s?.bondExchange?.filterPairSymbol);
  const selectTradeType = useSelector((s: AppState) => s?.bondExchange?.filterSide);
  const selectOrderType = useSelector((s: AppState) => s?.bondExchange?.filterType);
  const dateRange = useSelector((s: AppState) => s?.bondExchange?.filterTime);

  return useMemo(
    () => ({
      selectBond,
      selectTradeType,
      selectOrderType,
      dateRange,
    }),
    [selectBond, selectTradeType, selectOrderType, dateRange],
  );
};

export const useGetGeneralInfo = () => {
  const tradeType = useSelector((state: AppState) => state.bondExchange.tradeType);
  const orderType = useSelector((state: AppState) => state.bondExchange.orderType);
  const price = useSelector((state: AppState) => state.bondExchange.price);
  const quantityBase = useSelector((state: AppState) => state.bondExchange.quantityBase);
  const quantityQuote = useSelector((state: AppState) => state.bondExchange.quantityQuote);
  const quantityTP = useSelector((state: AppState) => state.bondExchange.quantityTP);
  const quantitySL = useSelector((state: AppState) => state.bondExchange.quantitySL);
  const percentEarned = useSelector((state: AppState) => state.bondExchange.percentEarned);
  const percentLoss = useSelector((state: AppState) => state.bondExchange.percentLoss);
  const currentPair = useSelector((state: AppState) => state.bondExchange.currentPair);

  return useMemo(
    () => ({
      tradeType,
      orderType,
      price,
      quantityBase,
      quantityQuote,
      quantityTP,
      quantitySL,
      percentEarned,
      percentLoss,
      currentPair,
    }),
    [
      tradeType,
      orderType,
      price,
      quantityBase,
      quantityQuote,
      quantityTP,
      quantitySL,
      percentEarned,
      percentLoss,
      currentPair,
    ],
  );
};

export const useExchangePairDetail = () => {
  const pairDetailData = useSelector((s: AppState) => s?.bondExchange?.pairDetailData);

  return useMemo(() => pairDetailData, [pairDetailData]);
};

export const useBuyOrSellSpotExchange = () => {
  const { handleCallOpenMarket, handleCallOpenLimit } = useMakeMarketOrder();
  const handleBuyOrSell = useCallback(async () => {
    const generalInfo = store.getState().bondExchange;
    try {
      if (
        Number(generalInfo?.currentPair?.max_range) <=
        Math.abs(Number(generalInfo?.price) - Number(generalInfo?.currentPrice?.price))
      ) {
        if (generalInfo.tradeType === TypeOfTradeConfig.BUY) {
          return useResponseModal({
            title: 'Error',
            errorMsg: `Price can't be lower than ${
              Number(generalInfo?.currentPrice?.price) - Number(generalInfo?.currentPair?.max_range)
            }, higher than or equal to ${Number(generalInfo?.currentPrice?.price)}`,
          });
        } else {
          return useResponseModal({
            title: 'Error',
            errorMsg: `Price can't be lower than ${Number(generalInfo?.currentPrice?.price)}, higher than or equal to ${
              Number(generalInfo?.currentPrice?.price) + Number(generalInfo?.currentPair?.max_range)
            }`,
          });
        }
      }
      if (generalInfo.orderType === TypeOfOrderConfig.LIMIT) {
        // limit

        return handleCallOpenLimit(
          generalInfo?.currentPair?.spot_manager_address,
          generalInfo.tradeType as unknown as TypeOfTradeConfig,
          new BigNumber(generalInfo?.quantityBase).times(new BigNumber(10).pow(18)).toFixed(),
          Number(generalInfo?.price || 0),
          generalInfo?.currentPair?.basis_point,
        );
      } else {
        // market
        if (
          generalInfo.tradeType === TypeOfTradeConfig.BUY &&
          generalInfo?.currentPair?.quote?.symbol.toLowerCase().includes('posi')
        ) {
          return handleCallOpenMarket(
            generalInfo?.currentPair?.spot_manager_address,
            generalInfo.tradeType as unknown as TypeOfTradeConfig,
            new BigNumber(generalInfo?.quantityQuote).times(new BigNumber(10).pow(18)).toFixed(),
          );
        } else {
          return handleCallOpenMarket(
            generalInfo?.currentPair?.spot_manager_address,
            generalInfo.tradeType as unknown as TypeOfTradeConfig,
            new BigNumber(generalInfo?.quantityBase).times(new BigNumber(10).pow(18)).toFixed(),
          );
        }
      }
    } catch (e) {
      return;
    }
  }, [store, handleCallOpenMarket]);

  return { handleBuyOrSell };
};

const mapListOrderPendingToListOrderOpen = (listOrderPending, listOfPair): any => {
  try {
    const flatListOrderPending = listOrderPending;

    const listOrderOpen = flatListOrderPending.reduce((prev: any[], orders: any) => {
      const data = flatMap(orders?.data);
      if (isEmpty(data)) return prev;
      const openOrders = data.map((order: any) => {
        const currentPair = listOfPair.find(
          (a) => a?.spot_manager_address.toLowerCase() === orders?.spotManager.toLowerCase(),
        );
        const [isBuy, quantity, partialFilled, pip, blockNumber, orderIdOfTrader, orderId] = order;
        const formattedPip = getBalanceNumber(Number(pip), 0);
        const formattedBlockNumber = getBalanceNumber(Number(blockNumber), 0);
        if (isEqual(formattedBlockNumber, 0)) return null;
        const openOrder = {
          side: isBuy ? 'buy' : 'sell',
          size: getBalanceNumber(Number(quantity)),
          filled: getBalanceNumber(Number(partialFilled)),
          pip: formattedPip,
          price: formattedPip / (currentPair.basis_point || 1),
          blockNumber: formattedBlockNumber,
          orderId: getBalanceNumber(Number(orderId), 0),
          orderIdOfTrader: getBalanceNumber(Number(orderIdOfTrader), 0),
          quote: currentPair ? currentPair.quote.symbol : '',
          base: currentPair ? currentPair.base.symbol : '',
          type: currentPair ? currentPair.type : '',
          positionManager: currentPair.spot_manager_address,
        };
        return openOrder;
      });
      return [...prev, ...openOrders.filter(Boolean)];
    }, []);
    return listOrderOpen;
  } catch (e) {
    return [];
  }
};

const sortListOpenOrderDesc = (a, b) => b.blockNumber - a.blockNumber;

export const listBondPMAddressOld = [
  '0x2A485CB0d2904ce00Ff78A92aa8f8121A440d46c',
  '0x51f6ac4C51d72A7A7250f88403dd3056f09e6685',
  '0x146729fD7Eac0D5E54b53DfB1AEc7f62FdEb768A',
];

export const useOpenOrders = () => {
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  const listOfPair = useListPairExchange();
  const spotHouseAddress = useSelector((s: AppState) => s?.bondExchange?.spotHouseAddress);
  const { account } = useWeb3React();

  const getOpenOrders = useCallback(async () => {
    // spotHouseGroup would be {[spotHouseAddress]: spotHouseListOfPair}
    // spotHouseListOfPair is the filled array only `spotHouseAddress`
    const spotHouseGroup = _.groupBy(listOfPair, 'spot_house_address');
    const promises = [];
    const spotManagerCallIndex = {};
    Object.keys(spotHouseGroup).forEach((spotHouseAddress, i) => {
      const calls = [];
      spotHouseGroup[spotHouseAddress].forEach(({ spot_manager_address }, j) => {
        calls.push({
          address: spotHouseAddress,
          name: 'getPendingLimitOrders',
          params: [spot_manager_address, account],
        });
        if (!spotManagerCallIndex[i]) {
          // initialize
          spotManagerCallIndex[i] = [];
        }
        // map i,j => spot_manager_address
        // i is the index of the spot house
        // j is the index its data
        spotManagerCallIndex[i].push(spot_manager_address);
      });
      promises.push(multicall(getSpotHouseAbi(spotHouseAddress), calls));
    });
    const results = await Promise.all(promises);
    if (results && results.length) {
      const mapSpotManager = [];
      results.forEach((spotArr, i) => {
        if (!mapSpotManager[i]) {
          mapSpotManager[i] = [];
        }
        if (spotArr && spotArr.length > 0) {
          spotArr.forEach((data, j) => {
            mapSpotManager[i].push({
              spotManager: spotManagerCallIndex[i][j],
              data,
            });
          });
        }
      });
      const newList = _.concat(...mapSpotManager);
      const openPMs = [];

      if (!isEmpty(newList)) {
        const listOrderOpen = mapListOrderPendingToListOrderOpen(newList, listOfPair);
        newList
          .flatMap((p) => p)
          .forEach((p, index) => {
            if (!isEmpty(p)) {
              openPMs.push(listOfPair[index]?.spot_manager_address);
            }
          });
        dispatch(setOpenOrders(listOrderOpen.sort(sortListOpenOrderDesc)));
        dispatch(setOpenPositionManagers(openPMs));
      }
    }
  }, [account, spotHouseAddress, listOfPair]);

  useEffect(() => {
    getOpenOrders();
  }, [fastRefresh, getOpenOrders]);
};

export const getListUniqLiquidityPoolAddress = (): string[] => {
  const list = poolsSpotExchange.map((a) => getAddress(a?.liquidityPoolAddress));
  const newList = uniq(list);
  return newList;
};

export const useMyLiquidity = (account) => {
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();

  const prices = useGetApiPrices();

  const getMyLiquidity = useCallback(async () => {
    try {
      if (prices) {
        const listLiquidityPoolAddress = getListUniqLiquidityPoolAddress();
        const liquidityNftManager = await useGetLiquidityNftManager();
        if (liquidityNftManager && listLiquidityPoolAddress && account) {
          const poolsExchange = store.getState().bondExchange.poolsExchange || [];
          const [nftListRes] = await multicall(liquidityNftManagerAbi, [
            {
              address: liquidityNftManager,
              name: 'tokensOfOwner',
              params: [account],
            },
          ]);
          const nftList =
            isEmpty(nftListRes) || isEmpty(nftListRes[0]) ? [] : nftListRes[0].map((i) => getBalanceNumber(i._hex, 0));

          let stakedNftList = [];
          const callsListNFTstaked = poolsExchange.map((p) => ({
            address: getAddress(p.rewardPoolAddress),
            name: 'getPlayerIds',
            params: [account, getAddress(p?.poolKey)],
          }));
          const listTokenIdRaw = await multicall(liquidityNftStakingAbi, callsListNFTstaked);
          if (listTokenIdRaw && listTokenIdRaw.length > 0) {
            listTokenIdRaw.forEach((tokenIdRaw) => {
              const listIdNft =
                (tokenIdRaw && tokenIdRaw[0] && tokenIdRaw[0]?.map((a) => getBalanceNumber(a._hex, 0))) || [];
              listIdNft.shift();
              stakedNftList = stakedNftList.concat(listIdNft || []);
            });
          }

          const liquidityInfoList = [];
          const liquidityRewardList = [];
          const liquidityClaimableValueList = [];

          const nftArr = [...nftList, ...stakedNftList];

          for (const nftId of nftArr) {
            for (let i = 0; i < listLiquidityPoolAddress.length; i++) {
              try {
                const [info, reward, claimable] = await multicall(liquidityPoolAbi, [
                  {
                    address: listLiquidityPoolAddress[i],
                    name: 'liquidityInfo',
                    params: [nftId],
                  },
                  {
                    address: listLiquidityPoolAddress[i],
                    name: 'pendingReward',
                    params: [nftId],
                  },
                  {
                    address: listLiquidityPoolAddress[i],
                    name: 'getNftWithdrawInfo',
                    params: [nftId],
                  },
                ]);
                if (info && reward && claimable) {
                  liquidityInfoList.push(info);
                  liquidityRewardList.push(reward);
                  liquidityClaimableValueList.push(claimable);
                  break;
                }
              } catch (e) {
              }
            }
          }

          let stakedAmounts = {};
          let totalInvestmentByRewardPoolAddress = {};

          if (liquidityInfoList) {
            const formattedData: LiquidityItem[] = [...nftList, ...stakedNftList].map((nftId, index) => {
              const { poolId, quoteDeposited, quoteAmount, baseAmount } = liquidityInfoList[index];
              const poolInfo = poolsExchange.find(
                (pool) => getAddress(pool.poolKey).toLowerCase() === poolId.toLowerCase(),
              );
              if (poolInfo) {
                const { pair, apr, pid, aprReward, rewardPoolAddress: rewardPoolAddressPair } = poolInfo;

                const {
                  base: { symbol: baseSymbol },
                  quote: { symbol: quoteSymbol },
                } = pair;
                const totalInvestment =
                  getBalanceNumber(quoteDeposited._hex) * Number(prices[quoteSymbol.toLowerCase()] || 0);
                stakedAmounts = {
                  ...stakedAmounts,
                  [pid]: stakedAmounts[pid] ? stakedAmounts[pid] + totalInvestment : totalInvestment,
                };

                const rewardPoolAddress = getAddress(rewardPoolAddressPair);

                totalInvestmentByRewardPoolAddress = {
                  ...totalInvestmentByRewardPoolAddress,
                  [rewardPoolAddress.toLowerCase()]: totalInvestmentByRewardPoolAddress[rewardPoolAddress.toLowerCase()]
                    ? totalInvestmentByRewardPoolAddress[rewardPoolAddress.toLowerCase()] + totalInvestment
                    : totalInvestment,
                };
                const stakedStatus = stakedNftList.includes(nftId)
                  ? LiquidityStakeStatus.STAKED
                  : LiquidityStakeStatus.UNSTAKED;

                const claimableBase = getBalanceNumber(liquidityClaimableValueList[index]?.claimableBaseAmount._hex);
                const claimableQuote = getBalanceNumber(liquidityClaimableValueList[index]?.claimableQuoteAmount._hex);
                const totalClaimableInQuote = getBalanceNumber(
                  liquidityClaimableValueList[index]?.totalClaimableAmountInQuote._hex,
                );

                const aprAfterCheckStaked = isEqual(stakedStatus, LiquidityStakeStatus.STAKED)
                  ? Number(apr) + Number(aprReward) < 0
                    ? 0
                    : Number(apr) + Number(aprReward)
                  : Number(apr) < 0
                    ? 0
                    : Number(apr);

                return {
                  nftId,
                  pid,
                  poolId,
                  rewardPoolAddress: rewardPoolAddress,
                  baseSymbol: baseSymbol,
                  quoteSymbol: quoteSymbol,
                  apr: `${aprAfterCheckStaked.toFixed(2)}%`,
                  totalInvestment,
                  stakedStatus,
                  quoteDeposited: getBalanceNumber(quoteDeposited._hex),
                  quoteAmount: getBalanceNumber(quoteAmount._hex),
                  baseAmount: getBalanceNumber(baseAmount._hex),
                  claimableBase,
                  claimableQuote,
                  totalClaimableInQuote,
                } as unknown as LiquidityItem;
              }
            });
            const callsNFTEarned = poolsExchange.map((p) => ({
              address: getAddress(p.rewardPoolAddress),
              name: 'pendingPosition',
              params: [getAddress(p?.poolKey), account],
            }));

            const powerStakedCall = poolsExchange.map((p) => ({
              address: getAddress(p.rewardPoolAddress),
              name: 'userInfo',
              params: [getAddress(p?.poolKey), account],
            }));
            const powerStakedRaw = await multicall(liquidityNftStakingAbi, powerStakedCall);

            const earnedNftList = await multicall(liquidityNftStakingAbi, callsNFTEarned);

            const formattedDataWithTotalEarnings = formattedData.map((lqi, index) => {
              if (lqi) {
                const { stakedStatus, quoteDeposited, totalClaimableInQuote, totalInvestment, quoteSymbol } = lqi;
                const liquidityEarnings = getBalanceNumber(liquidityRewardList[index]);

                const indexOfListCall = callsNFTEarned?.findIndex((a) => a?.params[0] === lqi?.poolId);

                const totalStakeEarned = getBalanceNumber(earnedNftList && earnedNftList[indexOfListCall][0]._hex) || 0;

                const indexOfListCallPower = powerStakedCall?.findIndex((a) => a?.address === lqi?.rewardPoolAddress);

                const powerStakeInPool = getFullDisplayBalance(powerStakedRaw[indexOfListCallPower][0]._hex) || 1;

                const stakeEarnings = isEqual(stakedStatus, LiquidityStakeStatus.STAKED)
                  ? new BigNumber(quoteDeposited).div(powerStakeInPool).times(totalStakeEarned).toNumber()
                  : 0;

                const totalEarningsInBaseToken =
                  liquidityEarnings * Number(prices[quoteSymbol?.toLowerCase()]) +
                  stakeEarnings * Number(prices['posi'] || 1);
                // +  totalClaimableInQuote * Number(prices[quoteSymbol?.toLowerCase()]);

                return {
                  ...lqi,
                  totalInvestment: `$${formatNumberWithNumeral(totalInvestment, 2)}`,
                  totalEarnings: `$${formatNumberWithNumeral(totalEarningsInBaseToken, 2)} (${Number(
                    (totalEarningsInBaseToken / Number(totalInvestment)) * 100,
                  ).toFixed(2)}%)`,
                };
              }
            });
            if (formattedDataWithTotalEarnings.length) {
              const newListFilter = formattedDataWithTotalEarnings.filter((a) => !!(a && a?.nftId));
              const newList = newListFilter.sort((a, b) => Number(b?.nftId) - Number(a?.nftId));
              dispatch(setStakedAmounts(stakedAmounts));
              dispatch(setMyLiquidity(newList));
            } else {
              dispatch(setMyLiquidity([]));
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [account, prices]);

  useEffect(() => {
    getMyLiquidity();
  }, [fastRefresh, getMyLiquidity]);
};

export const getMyLiquidity = async (prices, account: string, dispatch) => {
  try {
    if (prices) {
      const listLiquidityPoolAddress = getListUniqLiquidityPoolAddress();
      const liquidityNftManager = await useGetLiquidityNftManager();
      if (liquidityNftManager && listLiquidityPoolAddress && account) {
        const poolsExchange = store.getState().bondExchange.poolsExchange || [];
        const [nftListRes] = await multicall(liquidityNftManagerAbi, [
          {
            address: liquidityNftManager,
            name: 'tokensOfOwner',
            params: [account],
          },
        ]);
        const nftList =
          isEmpty(nftListRes) || isEmpty(nftListRes[0]) ? [] : nftListRes[0].map((i) => getBalanceNumber(i._hex, 0));

        let stakedNftList = [];
        const callsListNFTstaked = poolsExchange.map((p) => ({
          address: getAddress(p.rewardPoolAddress),
          name: 'getPlayerIds',
          params: [account, getAddress(p?.poolKey)],
        }));
        const listTokenIdRaw = await multicall(liquidityNftStakingAbi, callsListNFTstaked);
        if (listTokenIdRaw && listTokenIdRaw.length > 0) {
          listTokenIdRaw.forEach((tokenIdRaw) => {
            const listIdNft =
              (tokenIdRaw && tokenIdRaw[0] && tokenIdRaw[0]?.map((a) => getBalanceNumber(a._hex, 0))) || [];
            listIdNft.shift();
            stakedNftList = stakedNftList.concat(listIdNft || []);
          });
        }

        const liquidityInfoList = [];
        const liquidityRewardList = [];
        const liquidityClaimableValueList = [];

        const nftArr = [...nftList, ...stakedNftList];

        for (const nftId of nftArr) {
          for (let i = 0; i < listLiquidityPoolAddress.length; i++) {
            try {
              const [info, reward, claimable] = await multicall(liquidityPoolAbi, [
                {
                  address: listLiquidityPoolAddress[i],
                  name: 'liquidityInfo',
                  params: [nftId],
                },
                {
                  address: listLiquidityPoolAddress[i],
                  name: 'pendingReward',
                  params: [nftId],
                },
                {
                  address: listLiquidityPoolAddress[i],
                  name: 'getNftWithdrawInfo',
                  params: [nftId],
                },
              ]);
              if (info && reward && claimable) {
                liquidityInfoList.push(info);
                liquidityRewardList.push(reward);
                liquidityClaimableValueList.push(claimable);
                break;
              }
            } catch (e) {
            }
          }
        }

        let stakedAmounts = {};
        let totalInvestmentByRewardPoolAddress = {};

        if (liquidityInfoList) {
          const formattedData: LiquidityItem[] = [...nftList, ...stakedNftList].map((nftId, index) => {
            const { poolId, quoteDeposited, quoteAmount, baseAmount } = liquidityInfoList[index];
            const poolInfo = poolsExchange.find(
              (pool) => getAddress(pool.poolKey).toLowerCase() === poolId.toLowerCase(),
            );
            if (poolInfo) {
              const { pair, apr, pid, aprReward, rewardPoolAddress: rewardPoolAddressPair } = poolInfo;

              const {
                base: { symbol: baseSymbol },
                quote: { symbol: quoteSymbol },
              } = pair;
              const totalInvestment =
                getBalanceNumber(quoteDeposited._hex) * Number(prices[quoteSymbol.toLowerCase()] || 0);
              stakedAmounts = {
                ...stakedAmounts,
                [pid]: stakedAmounts[pid] ? stakedAmounts[pid] + totalInvestment : totalInvestment,
              };

              const rewardPoolAddress = getAddress(rewardPoolAddressPair);

              totalInvestmentByRewardPoolAddress = {
                ...totalInvestmentByRewardPoolAddress,
                [rewardPoolAddress.toLowerCase()]: totalInvestmentByRewardPoolAddress[rewardPoolAddress.toLowerCase()]
                  ? totalInvestmentByRewardPoolAddress[rewardPoolAddress.toLowerCase()] + totalInvestment
                  : totalInvestment,
              };
              const stakedStatus = stakedNftList.includes(nftId)
                ? LiquidityStakeStatus.STAKED
                : LiquidityStakeStatus.UNSTAKED;

              const claimableBase = getBalanceNumber(liquidityClaimableValueList[index]?.claimableBaseAmount._hex);
              const claimableQuote = getBalanceNumber(liquidityClaimableValueList[index]?.claimableQuoteAmount._hex);
              const totalClaimableInQuote = getBalanceNumber(
                liquidityClaimableValueList[index]?.totalClaimableAmountInQuote._hex,
              );

              const aprAfterCheckStaked = isEqual(stakedStatus, LiquidityStakeStatus.STAKED)
                ? Number(apr) + Number(aprReward) < 0
                  ? 0
                  : Number(apr) + Number(aprReward)
                : Number(apr) < 0
                  ? 0
                  : Number(apr);

              return {
                nftId,
                pid,
                poolId,
                rewardPoolAddress: rewardPoolAddress,
                baseSymbol: baseSymbol,
                quoteSymbol: quoteSymbol,
                apr: `${aprAfterCheckStaked.toFixed(2)}%`,
                totalInvestment,
                stakedStatus,
                quoteDeposited: getBalanceNumber(quoteDeposited._hex),
                quoteAmount: getBalanceNumber(quoteAmount._hex),
                baseAmount: getBalanceNumber(baseAmount._hex),
                claimableBase,
                claimableQuote,
                totalClaimableInQuote,
              } as unknown as LiquidityItem;
            }
          });
          const callsNFTEarned = poolsExchange.map((p) => ({
            address: getAddress(p.rewardPoolAddress),
            name: 'pendingPosition',
            params: [getAddress(p?.poolKey), account],
          }));

          const powerStakedCall = poolsExchange.map((p) => ({
            address: getAddress(p.rewardPoolAddress),
            name: 'userInfo',
            params: [getAddress(p?.poolKey), account],
          }));
          const powerStakedRaw = await multicall(liquidityNftStakingAbi, powerStakedCall);

          const earnedNftList = await multicall(liquidityNftStakingAbi, callsNFTEarned);

          const formattedDataWithTotalEarnings = formattedData.map((lqi, index) => {
            if (lqi) {
              const { stakedStatus, quoteDeposited, totalClaimableInQuote, totalInvestment, quoteSymbol } = lqi;
              const liquidityEarnings = getBalanceNumber(liquidityRewardList[index]);

              const indexOfListCall = callsNFTEarned?.findIndex((a) => a?.params[0] === lqi?.poolId);

              const totalStakeEarned = getBalanceNumber(earnedNftList && earnedNftList[indexOfListCall][0]._hex) || 0;

              const indexOfListCallPower = powerStakedCall?.findIndex((a) => a?.address === lqi?.rewardPoolAddress);

              const powerStakeInPool = getFullDisplayBalance(powerStakedRaw[indexOfListCallPower][0]._hex) || 1;

              const stakeEarnings = isEqual(stakedStatus, LiquidityStakeStatus.STAKED)
                ? new BigNumber(quoteDeposited).div(powerStakeInPool).times(totalStakeEarned).toNumber()
                : 0;

              const totalEarningsInBaseToken =
                liquidityEarnings * Number(prices[quoteSymbol?.toLowerCase()]) +
                stakeEarnings * Number(prices['posi'] || 1);
              // +  totalClaimableInQuote * Number(prices[quoteSymbol?.toLowerCase()]);

              return {
                ...lqi,
                totalInvestment: `$${formatNumberWithNumeral(totalInvestment, 2)}`,
                totalEarnings: `$${formatNumberWithNumeral(totalEarningsInBaseToken, 2)} (${Number(
                  (totalEarningsInBaseToken / Number(totalInvestment)) * 100,
                ).toFixed(2)}%)`,
              };
            }
          });
          if (formattedDataWithTotalEarnings.length) {
            const newListFilter = formattedDataWithTotalEarnings.filter((a) => !!(a && a?.nftId));
            const newList = newListFilter.sort((a, b) => Number(b?.nftId) - Number(a?.nftId));
            dispatch(setStakedAmounts(stakedAmounts));
            dispatch(setMyLiquidity(newList));
          } else {
            dispatch(setMyLiquidity([]));
          }
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const useCurrentPrice = () => {
  const [priceState, setPriceState] = useState({
    price: 0,
    type: PriceSide.BALANCE,
  });

  useInterval(() => {
    const currentPrice = store.getState().bondExchange.currentPrice;
    if (currentPrice?.price !== priceState.price || currentPrice.type !== priceState.type) {
      setPriceState(currentPrice);
    }
  }, 1000);

  return priceState;
};

export const usePoolsExchangeData = (): ILiquidityPoolDetail[] => {
  const listOfPool = useSelector((s: AppState) => s?.bondExchange?.poolsExchange);

  return useMemo(() => listOfPool, [listOfPool]);
};

export const usePoolExchangeByPid = (pid: string): ILiquidityPoolDetail => {
  const listOfPool = useSelector((s: AppState) => s?.bondExchange?.poolsExchange);
  const existPool = listOfPool.find((a) => `${a?.pair?.base?.symbol}${a.pid}` === pid);

  return useMemo(() => existPool, [existPool]);
};

export const usePoolExchangeByPoolKey = (poolKey: string): ILiquidityPoolDetail => {
  const listOfPool = useSelector((s: AppState) => s?.bondExchange?.poolsExchange);
  const existPool = listOfPool.find((a) => getAddress(a.poolKey)?.toLowerCase() === poolKey?.toLowerCase());
  return useMemo(() => existPool, [existPool]);
};

export const usePoolsExchangeByPairManager = (pairManager: string): ILiquidityPoolDetail[] => {
  const listOfPool = useSelector((s: AppState) => s?.bondExchange?.poolsExchange);
  const list = listOfPool.filter((a) => a?.pairManager?.toLowerCase() === pairManager?.toLowerCase());

  return useMemo(() => list, [list]);
};

export const getPairPriceWithPairManager = (pairManager: string): PairExchange => {
  const { listOfPair } = store.getState().bondExchange;

  const pairExist = listOfPair.find((a) => a?.spot_manager_address?.toLowerCase() === pairManager?.toLowerCase());

  return pairExist;
};

export const getPairPriceWithPairManagerHook = (pairManager: string): PairExchange => {
  const listOfPair = useSelector((s: AppState) => s?.bondExchange?.listOfPair);

  const pairExist = listOfPair.find((a) => a?.spot_manager_address?.toLowerCase() === pairManager?.toLowerCase());

  return useMemo(() => pairExist, [pairExist]);
};
export const useGetMyLiquidity = (): LiquidityItem[] => {
  const myLiquidity = useSelector((s: AppState) => s?.bondExchange?.myLiquidity);

  return useMemo(() => myLiquidity, [myLiquidity]);
};
