import { extendTheme } from '@chakra-ui/react';

import { createBreakpoints } from '@chakra-ui/theme-tools';
import styles from './theme/styles';

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
  '3xl': '128em',
});

const themeContext = extendTheme({
  colors: {
    teal: {
      150: '#1AC486',
    },
    gray: {
      75: '#F1F5F9',
      100: '#F2F5F9',
      160: '#EDF2F7',
      150: '#1F2838',
      400: '#AAB5C3',
      450: '#2D3748',
      250: '#3C4859',
      500: '#768597',
      550: '#C4C4C4',
      350: '#1F2835',
      650: '#1D2128',
      700: '#262F3D',
      800: '#1A212A',
      850: '#12161E',
      900: '#10141A',
      950: '#12161E',
    },
    blue: {
      150: '#0BC5EA',
    },
    black: {
      150: '#1F2838',
    },
    colorsText: {
      100: '#27DC8A',
      subText: '#718096',
    },
    bgCountdown: {
      start: '#27DC8A',
      end: '#11BF97',
    },
    purple: {
      650: '#6D50A8',
    },
  },
  fontWeights: {
    semibold: '600',
    regular: '400',
    medium: '500',
  },
  breakpoints,
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  ...styles,
});

export default themeContext;
