export const listTask = [
  {
    id: 1,
    title: '',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Access <span class='gradient-text'>POSIDAY</span> page</p></li>
    <li><p class="ml-26">Open <span class='gradient-text'>Treasure Hunt</span> tab</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>Daily Checkin</span> task</p></li>
    <li><p class="ml-26">Sign to confirm</p></li>
  </ol>`,
    totalCount: 7,
    propToUse: 'count',
  },
  {
    id: 4,
    title: '<span class="fz-16">Open 1 Long order with minimum $10 to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class='md-link' href="https://futures.position.exchange/USD-M/BTC/BUSD" target="_blank">Position.Exchange</a></p></li>
    <li><p class="ml-26">Choose Derivatives tab</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>BUSD-M</span> or <span class='gradient-text'>Coin-M</span> Futures</p></li>
    <li><p class="ml-26">Place a long position with an amount of $10 minimum</p></li>
    <li><p class="ml-26">Confirm Transaction on your wallet</p></li>
    <li><p class="ml-26">Comeback and claim your reward</p></li>
  </ol>`,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 5,
    title: '<span class="fz-16">Open 1 Short order with minimum $10 to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class='md-link' href="https://futures.position.exchange/USD-M/BTC/BUSD" target="_blank">Position.Exchange</a></p></li>
    <li><p class="ml-26">Choose Derivatives tab</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>BUSD-M</span> or <span class='gradient-text'>Coin-M</span> Futures</p></li>
    <li><p class="ml-26">Place a short position with an amount of $10 minimum</p></li>
    <li><p class="ml-26">Confirm Transaction on your wallet</p></li>
    <li><p class="ml-26">Comeback and claim your reward</p></li>
  </ol>`,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 6,
    title: '<span class="fz-16">Open 1 Long order with minimum $100 to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class='md-link' href="https://futures.position.exchange/USD-M/BTC/BUSD" target="_blank">Position.Exchange</a></p></li>
    <li><p class="ml-26">Choose Derivatives tab</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>BUSD-M</span> or <span class='gradient-text'>Coin-M</span> Futures</p></li>
    <li><p class="ml-26">Place a long position with an amount of $100 minimum</p></li>
    <li><p class="ml-26">Confirm Transaction on your wallet</p></li>
    <li><p class="ml-26">Comeback and claim your reward</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 7,
    title: '<span class="fz-16">Open 1 Short order with minimum $100 to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class='md-link' href="https://futures.position.exchange/USD-M/BTC/BUSD" target="_blank">Position.Exchange</a></p></li>
    <li><p class="ml-26">Choose Derivatives tab</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>BUSD-M</span> or <span class='gradient-text'>Coin-M</span> Futures</p></li>
    <li><p class="ml-26">Place a short position with an amount of $100 minimum</p></li>
    <li><p class="ml-26">Confirm Transaction on your wallet</p></li>
    <li><p class="ml-26">Comeback and claim your reward</p></li>
  </ol>`,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 8,
    title:
      '<span class="fz-16">Trade $500 minimum on <a class="md-link" href="https://position.exchange/trade/USDT/BUSD?type=stable" target="_blank">USDT/BUSD (Spot)</a> to earn 50 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/trade/USDT/BUSD?type=stable" target="_blank">Position.Exchange</a></p></li>
    <li><p class="ml-26">Select Trade tab</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>USDT/BUSD</span> pair</p></li>
    <li><p class="ml-26">Place buy or sell orders with total worth of $500</p></li>
    <li><p class="ml-26">Confirm Transaction on your wallet</p></li>
    <li><p class="ml-26">Comeback and claim your reward</p></li>
  </ol>
  `,
    totalCount: 500,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 9,
    title:
      '<span class="fz-16">Buy 1 ticket in <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy a ticket with 15 POSI</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 10,
    title:
      '<span class="fz-16">Win a prize in <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> to earn 20 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy tickets and try your luck</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 11,
    title:
      '<span class="fz-16">Spend 50 POSI buying <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> ticket to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy lottery tickets with total amount 50 POSI</p></li>
  </ol>
  `,
    totalCount: 50,
    propToUse: 'token_amount',
  },
  {
    id: 12,
    title:
      '<span class="fz-16">Spend 100 POSI buying <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> ticket to earn 20 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy lottery tickets with total amount 100 POSI</p></li>
  </ol>
  `,
    totalCount: 100,
    propToUse: 'token_amount',
  },
  {
    id: 13,
    title:
      '<span class="fz-16">Win a prize in <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> to earn 20 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/daily-lottery" target="_blank">Daily Lottery</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy tickets and try your luck</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 14,
    title:
      '<span class="fz-16">Buy 1 ticket in <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> to earn 10 points within 10 minutes</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy a ticket with 5 POSI</p></li>
    <li><p class="ml-26">Wait around 10 minutes to earn points</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 15,
    title:
      '<span class="fz-16">Play <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> 1 time to earn 10 points within 10 minutes</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy a ticket and try your luck</p></li>
    <li><p class="ml-26">Wait around 10 minutes to earn points</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 16,
    title:
      '<span style="display: inline-block; width: 470px;" class="fz-16">Win any prize in <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> 1 time to earn 10 points within 10 minutes</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy a ticket or use your free spin to try your luck</p></li>
    <li><p class="ml-26">Wait around 10 minutes to earn points</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 17,
    title:
      '<span class="fz-16">Spend 50 POSI buying ticket in <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a>  to earn 15 points within 10 minutes</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy tickets with total amount 50 POSI</p></li>
    <li><p class="ml-26">Wait around 10 minutes to earn points</p></li>
  </ol>
  `,
    totalCount: 50,
    propToUse: 'token_amount',
  },
  {
    id: 18,
    title:
      '<span class="fz-16">Spend 100 POSI buying ticket in <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> to earn 20 points within 10 minutes</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Buy tickets with total amount 100 POSI</p></li>
    <li><p class="ml-26">Wait around 10 minutes to earn points</p></li>
  </ol>
  `,
    totalCount: 100,
    propToUse: 'token_amount',
  },
  {
    id: 19,
    title:
      '<span class="fz-16">Total rewards in <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> reach 50 POSI to earn 10 points within 10 minutes</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/posi-birthday/lucky-posi" target="_blank">Lucky POSI</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Play 1 free turn every 12 hours, at 12:00 (UTC+0) and 00:00 (UTC+0) every day or buy a ticket to spin with 5 POSI to try your luck and reach the reward.</p></li>
    <li><p class="ml-26">Wait around 10 minutes to earn points</p></li>
  </ol>
  `,
    totalCount: 50,
    propToUse: 'token_amount',
  },
  {
    id: 20,
    title:
      '<span class="fz-16">Cast 1 <a class="md-link" href="https://position.exchange/nfts" target="_blank">NFT</a> with minimum value $100 to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to <a class="md-link" href="https://position.exchange/nfts" target="_blank">NFTs</a> page on Position.Exchange</p></li>
    <li><p class="ml-26">Enter the amount of 100 POSI to the [POSI par value] text box and Cast</p></li>
    <li><p class="ml-26">Confirm your transaction and wait for your Card to be cast</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 21,
    title:
      '<span class="fz-16">Own 2 <a class="md-link" href="https://position.exchange/nfts" target="_blank">NFT</a> with minimum value $100 to earn 15 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to <a class="md-link" href="https://position.exchange/nfts" target="_blank">NFTs</a> page on Position.Exchange</p></li>
    <li><p class="ml-26">Enter the amount of 100 POSI to the [POSI par value] text box and Cast</p></li>
    <li><p class="ml-26">Confirm your transaction and wait for your Card to be cast</p></li>
  </ol>
  `,
    totalCount: 2,
    propToUse: 'count',
  },
  {
    id: 22,
    title:
      '<span class="fz-16">Stake 1 <a class="md-link" href="https://position.exchange/nfts" target="_blank">NFT card</a> into NFTs pool to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to <a class="md-link" href="https://position.exchange/pools" target="_blank">Pools</a> page on Position.Exchange</p></li>
    <li><p class="ml-26">Select the NFTs Pool you want to stake in</p></li>
    <li><p class="ml-26">Click (tap) on stake & select a NFT card </p></li>
    <li><p class="ml-26">Click (tap) confirm and confirm the action in your wallet</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 23,
    title:
      '<span class="fz-16">Own 2 stars or higher <a class="md-link" href="https://position.exchange/nfts" target="_blank">NFT cards</a> to earn 20 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to <a class="md-link" href="https://position.exchange/nfts" target="_blank">NFTs</a> page on Position.Exchange</p></li>
    <li><p class="ml-26">Enter any amount to the [POSI par value] text box and Cast</p></li>
    <li><p class="ml-26">Confirm your transaction and wait your card to be cast</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 24,
    title:
      '<span class="fz-16">Buy a <a class="md-link" href="https://position.exchange/posi-birthday/nfts-game" target="_blank">Birthday NFT</a> to earn 10 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">On Position Exchange home page, click on the tab <a class="md-link" href="https://position.exchange/posi-birthday/nfts-game" target="_blank">POSI DAY</a></p></li>
    <li><p class="ml-26">Click <span class='gradient-text'>Buy</span> to receive an exclusive NFT card designed for POSI's first birthday at the price of 101 POSI.</p></li>
    <li><p class="ml-26">Open and receive one of the 3+ NFT cards (Dreamer, Challenger, Conqueror).</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 25,
    title: '<span class="fz-16">Rank 50 or better in leaderboard to earn 15 points</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Complete the tasks and gain as many points as possible to be in rank of 50</p></li>
    <li><p class="ml-26">System checks your rank and verifies the task accomplishment.</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 26,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/referral" target="_blank">Invite</a> at least 1 friend to Position.Exchange to earn 30 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Send your <a class="md-link" href="https://position.exchange/referral" target="_blank">Referral Link</a> copied from Referral tab to your friend</p></li>
    <li><p class="ml-26">Ask your friend to access our exchange via your link and stake in Farm.</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 28,
    title:
      '<span class="fz-16">Follow <a class="md-link" href="https://twitter.com/PositionEx" target="_blank">@PositionEx</a> on Twitter to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Access to <a class="md-link" href="https://twitter.com/PositionEx" target="_blank">PositionEx</a> on Twitter and follow us</p></li>
    <li><p class="ml-26">Submit your username to verify</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 29,
    title:
      '<span class="fz-16">Join <a class="md-link" href="https://t.me/PositionExchange" target="_blank">t.me/PositionExchange</a> on Telegram to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Join <a class="md-link" href="https://t.me/PositionExchange" target="_blank">t.me/PositionExchange</a> on Telegram</p></li>
    <li><p class="ml-26">Submit your username to verify</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 30,
    title:
      '<span class="fz-16">Retweet a random post on <a class="md-link" href="https://twitter.com/PositionEx" target="_blank">@PositionEx</a> to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Access to <a class="md-link" href="https://twitter.com/PositionEx" target="_blank">PositionEx</a> on Twitter and follow us</p></li>
    <li><p class="ml-26">Retweet any post you like</p></li>
    <li><p class="ml-26">Submit your retweet link</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 31,
    title:
      '<span class="fz-16">Post a Tweet with hashtag <span class="gradient-text">#PositionTurns1</span> to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Post a Tweet with hashtag <span class="gradient-text">#PositionTurns1</span></p></li>
    <li><p class="ml-26">Submit your Tweet link</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 32,
    title:
      '<span class="fz-16">Join us on <a class="md-link" href="https://discord.com/invite/bfa6EsRh3m" target="_blank">Discord</a> to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Join <a class="md-link" href="https://discord.com/invite/bfa6EsRh3m" target="_blank">Position Exchange</a> on Discord</p></li>
    <li><p class="ml-26">Submit your username to verify</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 33,
    title:
      '<span class="fz-16">Follow <a class="md-link" href="https://www.facebook.com/PositionEx/" target="_blank">Position Exchange</a> on Facebook to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Access to our <a class="md-link" href="https://www.facebook.com/PositionEx/" target="_blank">Facebook Page</a> to like and follow</p></li>
    <li><p class="ml-26">Submit your Facebook username to verify</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 34,
    title:
      '<span class="fz-16">Follow <a class="md-link" href="t.me/PositionAnn" target="_blank">t.me/PositionAnn</a> on Telegram to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Follow <a class="md-link" href="t.me/PositionAnn" target="_blank">t.me/PositionAnn</a> on Telegram</p></li>
    <li><p class="ml-26">Submit your username to verify</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 35,
    title:
      '<span class="fz-16">Trade $500 minimum on <a class="md-link" href="https://position.exchange/trade/USDT/BUSD?type=stable" target="_blank">USDT/BUSD</a> (Spot) to earn 50 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/trade/USDT/BUSD?type=stable" target="_blank">Trade</a> tab on our website</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>USDT/BUSD</span></p></li>
    <li><p class="ml-26">Choose Buy or Sell</p></li>
    <li><p class="ml-26">Fill the information in the trading interface; entry price (by limit order), order type and quantity = $500 minimum</p></li>
    <li><p class="ml-26">Press Buy/Sell button</p></li>
    <li><p class="ml-26">Confirm</p></li>
  </ol>
  `,
    totalCount: 500,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 36,
    title:
      '<span class="fz-16">Trade $500 minimum on <a class="md-link" href="https://position.exchange/trade/USDC/BUSD?type=stable" target="_blank">USDC/BUSD</a> (Spot) to earn 50 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/trade/USDC/BUSD?type=stable" target="_blank">Trade</a> tab on our website</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>USDC/BUSD</span></p></li>
    <li><p class="ml-26">Choose Buy or Sell</p></li>
    <li><p class="ml-26">Fill the information in the trading interface; entry price (by limit order), order type and quantity = $500 minimum</p></li>
    <li><p class="ml-26">Press Buy/Sell button</p></li>
    <li><p class="ml-26">Confirm</p></li>
  </ol>
  `,
    totalCount: 500,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 37,
    title:
      '<span class="fz-16">Trade $500 minimum on <a class="md-link" href="https://position.exchange/trade/DAI/BUSD?type=stable" target="_blank">DAI/BUSD</a> (Spot) to earn 50 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Open <a class="md-link" href="https://position.exchange/trade/DAI/BUSD?type=stable" target="_blank">Trade</a> tab on our website</p></li>
    <li><p class="ml-26">Choose <span class='gradient-text'>DAI/BUSD</span></p></li>
    <li><p class="ml-26">Choose Buy or Sell</p></li>
    <li><p class="ml-26">Fill the information in the trading interface; entry price (by limit order), order type and quantity = $500 minimum</p></li>
    <li><p class="ml-26">Press Buy/Sell button</p></li>
    <li><p class="ml-26">Confirm</p></li>
  </ol>
  `,
    totalCount: 500,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 38,
    title:
      '<span class="fz-16">Stake 100 POSI minimum to any <a class="md-link" href="https://position.exchange/pools" target="_blank">Pool</a> (except NFT) to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to the <a class="md-link" href="https://position.exchange/pools" target="_blank">Pools</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Select the Pool you want to stake in (except NFT Pool)</p></li>
    <li><p class="ml-26">Click on <span class='gradient-text'>Stake</span></p></li>
    <li><p class="ml-26">Enter the amount of 100 POSI minimum (not including RFI)</p></li>
    <li><p class="ml-26">Click <span class='gradient-text'>Confirm</span>. Your wallet will ask you to confirm the action</p></li>
  </ol>
  `,
    totalCount: 100,
    propToUse: 'token_amount',
  },
  {
    id: 39,
    title:
      '<span class="fz-16">Stake $20 minimum into <a class="md-link" href="https://position.exchange/farming" target="_blank">Farms</a> to earn 20 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to the <a class="md-link" href="https://position.exchange/farming" target="_blank">Farms</a> page and locate your Farm. Click anywhere on the row showing your pair. It will expand to show more details</p></li>
    <li><p class="ml-26">After a short wait, the Enable button will change to Deposit. Click it and a new window will appear</p></li>
    <li><p class="ml-26">Type the amount of $20 minimum into the field</p></li>
    <li><p class="ml-26">Click Confirm. Your wallet will ask you to confirm your action</p></li>
  </ol>
  `,
    totalCount: 20,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 40,
    title:
      '<span class="fz-16">Stake $20 minimum into <a class="md-link" href="https://position.exchange/pools" target="_blank">pools</a> (except NFT) to earn 20 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to the <a class="md-link" href="https://position.exchange/pools" target="_blank">Pools</a> tab on Position Exchange</p></li>
    <li><p class="ml-26">Select the Pool you want to stake in (except NFTs Pool)</p></li>
    <li><p class="ml-26">Click on <span class='gradient-text'>Stake</span></p></li>
    <li><p class="ml-26">Enter the amount you would like to stake ($20 minimum)</p></li>
    <li><p class="ml-26">Click <span class='gradient-text'>Confirm</span>. Your wallet will ask you to confirm the action</p></li>
  </ol>
  `,
    totalCount: 20,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 41,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> any pairs listed in Trade with any amount to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to the <a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> tab</p></li>
    <li><p class="ml-26">Choose token among USDT - BUSD - DAI - USDC</p></li>
    <li><p class="ml-26">Enter the amount to swap</p></li>
    <li><p class="ml-26">Choose available swap option: Position Exchange</p></li>
    <li><p class="ml-26">Select gas fee level you want</p></li>
    <li><p class="ml-26">Check the details, and click the <span class='gradient-text'>Swap</span> button</p></li>
    <li><p class="ml-26">Click the <span class='gradient-text'>Confirm Swap</span> button and confirm the action in your wallet</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 42,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> $10 with any pairs listed in Trade to earn 15 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to the <a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> tab</p></li>
    <li><p class="ml-26">Choose token among USDT - BUSD - DAI - USDC</p></li>
    <li><p class="ml-26">Enter the amount equivalent to $10</p></li>
    <li><p class="ml-26">Choose available swap option: Position Exchange</p></li>
    <li><p class="ml-26">Select gas fee level you want</p></li>
    <li><p class="ml-26">Check the details, and click the <span class='gradient-text'>Swap</span> button</p></li>
    <li><p class="ml-26">Click the <span class='gradient-text'>Confirm Swap</span> button and confirm the action in your wallet</p></li>
  </ol>
  `,
    totalCount: 10,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 43,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> $50 with any pairs listed in Trade to earn 20 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to the <a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> tab</p></li>
    <li><p class="ml-26">Choose token among USDT - BUSD - DAI - USDC</p></li>
    <li><p class="ml-26">Enter the amount equivalent to $50</p></li>
    <li><p class="ml-26">Choose available swap option: Position Exchange</p></li>
    <li><p class="ml-26">Select gas fee level you want</p></li>
    <li><p class="ml-26">Check the details, and click the <span class='gradient-text'>Swap</span> button</p></li>
    <li><p class="ml-26">Click the <span class='gradient-text'>Confirm Swap</span> button and confirm the action in your wallet</p></li>
  </ol>
  `,
    totalCount: 50,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 44,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> $100 with any pairs listed in Trade to earn 35 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to the <a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> tab</p></li>
    <li><p class="ml-26">Choose token among USDT - BUSD - DAI - USDC</p></li>
    <li><p class="ml-26">Enter the amount equivalent to $100</p></li>
    <li><p class="ml-26">Choose available swap option: Position Exchange</p></li>
    <li><p class="ml-26">Select gas fee level you want</p></li>
    <li><p class="ml-26">Check the details, and click the <span class='gradient-text'>Swap</span> button</p></li>
    <li><p class="ml-26">Click the <span class='gradient-text'>Confirm Swap</span> button and confirm the action in your wallet</p></li>
  </ol>
  `,
    totalCount: 100,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 45,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> $500 with any pairs listed in Trade to earn 50 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Go to the <a class="md-link" href="https://position.exchange/swap/#/USDT/BUSD" target="_blank">Swap</a> tab</p></li>
    <li><p class="ml-26">Choose token among USDT - BUSD - DAI - USDC</p></li>
    <li><p class="ml-26">Enter the amount equivalent to $500</p></li>
    <li><p class="ml-26">Choose available swap option: Position Exchange</p></li>
    <li><p class="ml-26">Select gas fee level you want</p></li>
    <li><p class="ml-26">Check the details, and click the <span class='gradient-text'>Swap</span> button</p></li>
    <li><p class="ml-26">Click the <span class='gradient-text'>Confirm Swap</span> button and confirm the action in your wallet</p></li>
  </ol>
  `,
    totalCount: 500,
    propToUse: 'usd_amount_traded',
  },
  {
    id: 46,
    title:
      '<span class="fz-16">Add <a class="md-link" href="https://position.exchange/liquidity/#/add/0x5CA42204cDaa70d5c773946e69dE942b85CA6706/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" target="_blank">liquidity</a> to any pool listed on Add Liquidity tab to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Visit the <a class="md-link" href="https://position.exchange/liquidity/#/add/0x5CA42204cDaa70d5c773946e69dE942b85CA6706/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" target="_blank">Liquidity</a> page on Position Exchange</p></li>
    <li><p class="ml-26">Choose one from listed pairs to add liquidity</p></li>
    <li><p class="ml-26">Enter the amount on either of two tokens. The other will be automatically calculated</p></li>
    <li><p class="ml-26">Click <span class='gradient-text'>Confirm Supply</span> on the confirmation message pop-up</p></li>
    <li><p class="ml-26">Confirm the action in your wallet</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 47,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/referral" target="_blank">Invite</a> at least 1 friend to Position.Exchange to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Send your <a class="md-link" href="https://position.exchange/referral" target="_blank">Referral Link</a> copied from Referral tab to your friend</p></li>
    <li><p class="ml-26">Ask your friend to access our exchange via your link and stake in Farm.</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 48,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/referral" target="_blank">Invite</a> at least 1 friend to Position.Exchange to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Send your <a class="md-link" href="https://position.exchange/referral" target="_blank">Referral Link</a> copied from Referral tab to your friend</p></li>
    <li><p class="ml-26">Ask your friend to access our exchange via your link and stake in Farm.</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
  {
    id: 49,
    title:
      '<span class="fz-16"><a class="md-link" href="https://position.exchange/referral" target="_blank">Invite</a> at least 1 friend to Position.Exchange to earn 10 point</span>',
    description: `<ol class='ol-style'>
    <li><p class="ml-26">Send your <a class="md-link" href="https://position.exchange/referral" target="_blank">Referral Link</a> copied from Referral tab to your friend</p></li>
    <li><p class="ml-26">Ask your friend to access our exchange via your link and stake in Farm.</p></li>
  </ol>
  `,
    totalCount: 1,
    propToUse: 'count',
  },
];
