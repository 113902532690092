import { useMemo } from 'react';
import { Box, Flex, Image, Text, useMediaQuery, useToast as useToastUI } from '@chakra-ui/react';

// Toasts
const useToast = () => {
  const [isSmallThan1024] = useMediaQuery('(max-width: 1024px ) and (min-width: 0px )');
  const toast: any = useToastUI();
  const helpers = useMemo(() => {
    return {
      toastError: (title: any, description?: any) => {
        return toast({
          status: 'error',
          duration: 2500,
          render: () => (
            <Flex
              d={'flex'}
              borderRadius={'20px'}
              px={'20px'}
              py={'15px'}
              bg="gray.800"
              w={['350px', '400px']}
              alignItems={'center'}
              justifyContent={'space-between'}
              border="1px"
              borderColor="red.500"
              mb={isSmallThan1024 ? '70px' : '0px'}
            >
              <Image src={'/img/error-icon.svg'} w={'32px'} h={'32px'} />
              <Flex
                width={'100%'}
                pl="8px"
                color={'white'}
                textAlign="left"
                flexDirection={'column'}
                justify="flex-start"
              >
                <Text width={'100%'} fontWeight="600" fontSize="16px" textAlign="left" color="red.500">
                  {title || ''}
                </Text>
                <Text justify="flex-start" fontSize={'13px'} textAlign="left" color={'red.400'}>
                  {description || ''}
                </Text>
              </Flex>
            </Flex>
          ),
        });
      },
      toastSuccess: (title: string, description?: any) => {
        return toast({
          status: 'success',
          duration: 2500,
          render: () => (
            <Box
              d={'flex'}
              borderRadius={'20px'}
              px={'20px'}
              py={'15px'}
              bg="gray.800"
              w={['350px', '400px']}
              alignItems={'center'}
              justifyContent={'space-between'}
              border="1px"
              borderColor="teal.150"
              mb={isSmallThan1024 ? '70px' : '0px'}
            >
              <Image src={'/img/checked.svg'} w={'32px'} h={'32px'} />
              <Flex
                width={'100%'}
                pl="8px"
                color={'white'}
                textAlign="left"
                flexDirection={'column'}
                justify="flex-start"
              >
                <Text width={'100%'} fontWeight="600" fontSize="16px" textAlign="left" color="teal.150">
                  {title || ''}
                </Text>
                <Text justify="flex-start" fontSize={'13px'} textAlign="left" color={'gray.500'}>
                  {description || ''}
                </Text>
              </Flex>
            </Box>
          ),
        });
      },
      toastErrorTx: (title: any, description?: any) => {
        return toast({
          status: 'error',
          duration: 2500,
          position: 'top-right',
          render: () => (
            <Flex position="relative">
              <Flex
                d={'flex'}
                borderRadius={'20px'}
                px={'20px'}
                py={'15px'}
                bg="gray.800"
                w="fit-content"
                alignItems={'center'}
                justifyContent={'space-between'}
                border="1px"
                borderColor="red.500"
                position="absolute"
                top="100px"
                right="0px"
              >
                <Image src={'/img/error-icon.svg'} w={'32px'} h={'32px'} />
                <Flex
                  width={'100%'}
                  pl="8px"
                  color={'white'}
                  textAlign="left"
                  flexDirection={'column'}
                  justify="flex-start"
                >
                  <Text width={'100%'} fontWeight="600" fontSize="16px" textAlign="left" color="red.500">
                    {title || ''}
                  </Text>
                  <Text
                    wordBreak="break-word"
                    maxWidth="280px"
                    justify="flex-start"
                    fontSize={'13px'}
                    textAlign="left"
                    color={'red.400'}
                  >
                    {description || ''}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ),
        });
      },
      toastSuccessTx: (title: string, description?: any) => {
        return toast({
          status: 'success',
          duration: 2500,
          position: 'top-right',
          render: () => (
            <Flex position="relative">
              <Flex
                d={'flex'}
                borderRadius={'20px'}
                px={'20px'}
                py={'15px'}
                bg="gray.800"
                w="fit-content"
                alignItems={'center'}
                justifyContent={'space-between'}
                border="1px"
                borderColor="teal.150"
                position="absolute"
                top="100px"
                right="0px"
              >
                <Image src={'/img/checked.svg'} w={'32px'} h={'32px'} />
                <Flex
                  width={'100%'}
                  pl="8px"
                  color={'white'}
                  textAlign="left"
                  flexDirection={'column'}
                  justify="flex-start"
                >
                  <Text width={'100%'} fontWeight="600" fontSize="16px" textAlign="left" color="teal.150">
                    {title || ''}
                  </Text>
                  <Text
                    wordBreak="break-word"
                    maxWidth="280px"
                    justify="flex-start"
                    fontSize={'13px'}
                    textAlign="left"
                    color={'gray.500'}
                  >
                    {description || ''}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ),
        });
      },
      toastWarning: (title: string, description?: any) => {
        return toast({
          title: title,
          description: description || '',
          status: 'warning',
          duration: 2500,
        });
      },
      toastSuccessCustom: (title: string, description?: any, icon?: any) => {
        return toast({
          status: 'success',
          duration: 2500,
          position: 'top-right',
          render: () => (
            <Flex position="relative">
              <Flex
                d={'flex'}
                borderRadius={'20px'}
                px={'20px'}
                py={'15px'}
                bg="gray.800"
                w="fit-content"
                alignItems={'center'}
                justifyContent={'space-between'}
                border="1px"
                borderColor="teal.150"
                position="absolute"
                top="100px"
                right="0px"
              >
                {icon ? icon : <Image src={'/img/bell-ring.svg'} w={'32px'} h={'32px'} />}
                <Flex
                  width={'100%'}
                  pl="8px"
                  color={'white'}
                  textAlign="left"
                  flexDirection={'column'}
                  justify="flex-start"
                >
                  <Text width={'100%'} fontWeight="600" fontSize="16px" textAlign="left" color="teal.150">
                    {title || ''}
                  </Text>
                  <Text
                    wordBreak="break-word"
                    maxWidth="280px"
                    justify="flex-start"
                    fontSize={'13px'}
                    textAlign="left"
                    color={'gray.500'}
                  >
                    {description || ''}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ),
        });
      },
    };
  }, [toast]);

  return helpers;
};

export default useToast;
