import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetApiPrice } from '../../redux/hooks';
import { formatBalanceWithNumeral, formatBalanceWithNumerals } from '../../utils/common';

interface LockedBalanceOverviewProps {
  dataState: any;
  showBalance: boolean;
}
const LockedBalanceOverview: React.FC<LockedBalanceOverviewProps> = ({ dataState, showBalance }) => {
  const pricePosi = useGetApiPrice('posi');
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px ) and (min-width: 0px )');
  const { t } = useTranslation('header');

  const renderTotalLocked = (price: any) => {
    if (price) {
      if (isSmallerThan768) {
        return `${formatBalanceWithNumerals(Number(price), 4)}`;
      }
      return `${formatBalanceWithNumeral(Number(price), 6)}`;
    }
    if (isSmallerThan768) {
      return `${formatBalanceWithNumerals(Number(0), 4)}`;
    }
    return `${formatBalanceWithNumeral(Number(0), 6)}`;
  };
  return (
    <Flex
      borderRadius={'16px'}
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      p="10px"
      bgSize="cover"
      bgRepeat="no-repeat"
      h={['95px', '96px', '96px', '96px', '96px']}
      position="relative"
      w="100%"
    >
      <Text
        color={'gray.500'}
        textAlign="center"
        fontSize={'14px'}
        fontFamily="Poppins"
        fontStyle="normal"
        fontWeight="500"
        mb={['0px', '10px', '10px', '10px', '10px']}
      >
        {`${t('total_lock')}`}
      </Text>
      <Text
        color={'teal.150'}
        textAlign="center"
        fontWeight="600"
        fontSize={'24px'}
        fontFamily="Poppins"
        fontStyle="normal"
      >
        {showBalance ? renderTotalLocked(Number(dataState?.totalUsdLocked) / Number(pricePosi || 1)) : '✲✲✲✲✲✲'} POSI
      </Text>
      <Text
        textAlign="center"
        fontWeight="500"
        fontFamily="Poppins"
        fontStyle="normal"
        color={'cyan.400'}
        fontSize={['12px', '13px', '13px', '13px', '13px']}
      >
        {showBalance ? `~$${renderTotalLocked(dataState?.totalUsdLocked)}` : '✲✲✲✲✲✲'}
      </Text>
    </Flex>
  );
};

export default LockedBalanceOverview;
