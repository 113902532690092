import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import { BLOCKS_PER_YEAR, CAKE_PER_BLOCK } from '../configs';
import generalNFTRewardABI from '../configs/abi/generalNFTReward.json';
import useRefresh from '../hooks/useRefresh';
import { getGeneralNFTRewardAddress, getNewGeneralNFTRewardAddress } from './addressHelpers';
import multicall from './multicall';
import liquidityNftStakingAbi from '../configs/abi/liquidityNftStaking.json';

/**
 * Get the APY value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APY is NaN or infinite.
 */
export const getPoolApy = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number | null => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR);
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked);
  const apy = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
  return apy.isNaN() || !apy.isFinite() ? null : apy.toNumber();
};

export const getPoolNFTApy = (): number | null => {
  const [apy, setApy] = useState<number | null>(null);
  const { slowRefresh } = useRefresh();
  const getApr = useCallback(async () => {
    try {
      const [totalSupply, rewardRate] = await multicall(generalNFTRewardABI, [
        {
          address: getGeneralNFTRewardAddress(),
          name: 'totalSupply',
          params: [],
        },
        {
          address: getGeneralNFTRewardAddress(),
          name: '_rewardRate',
          params: [],
        },
      ]);
      if (totalSupply && rewardRate) {
        const bigTotalSupply: any = new BigNumber(totalSupply && totalSupply[0]._hex);
        const bigRewardRate: any = new BigNumber(rewardRate && rewardRate[0]._hex);
        const apyRaw: number = (((bigRewardRate * 31556926) / bigTotalSupply) * 100) as number;
        setApy(apyRaw);
      }
    } catch (err: any) {}
  }, []);

  useEffect(() => {
    getApr();
  }, [slowRefresh]);
  return apy;
};

export const getPoolNftApr = async (contractGeneralReward: string): Promise<number | null> => {
  try {
    const [totalSupply, rewardRate] = await multicall(generalNFTRewardABI, [
      {
        address: contractGeneralReward,
        name: 'totalSupply',
        params: [],
      },
      {
        address: contractGeneralReward,
        name: '_rewardRate',
        params: [],
      },
    ]);
    if (totalSupply && rewardRate) {
      const bigTotalSupply: any = new BigNumber(totalSupply && totalSupply[0]._hex);
      const bigRewardRate: any = new BigNumber(rewardRate && rewardRate[0]._hex);
      const apyRaw: number = (((bigRewardRate * 31556926) / bigTotalSupply) * 100) as number;
      return apyRaw;
    }
  } catch (err: any) {
    return 0;
  }
};

export const getLiquidityPoolRewardApr = async (
  rewardContractAddress: string,
  poolKey: string,
  priceEarningToken: string | number,
  quoteTokenPrice: string | number,
) => {
  try {
    const callPoolLiquidityNft = [
      {
        address: rewardContractAddress,
        name: 'poolInfo',
        params: [poolKey],
      },
      {
        address: rewardContractAddress,
        name: 'totalAllocPoint',
        params: [],
      },
      {
        address: rewardContractAddress,
        name: 'getPositionPerBlock',
        params: [],
      },
    ];

    const [poolLiquidityNftAllocPointRaw, poolLiquidityNft, poolLiquidityNftPositionPerBlockRaw] = await multicall(
      liquidityNftStakingAbi,
      callPoolLiquidityNft,
    );

    const allocPoint = new BigNumber((poolLiquidityNftAllocPointRaw && poolLiquidityNftAllocPointRaw[2]._hex) || 0);

    const poolWeight = allocPoint.div(new BigNumber((poolLiquidityNft && poolLiquidityNft[0]._hex) || 1));
    const apr = getFarmApy(
      poolWeight as BigNumber,
      new BigNumber(priceEarningToken),
      new BigNumber((poolLiquidityNftAllocPointRaw && poolLiquidityNftAllocPointRaw[1]._hex) || 0).times(
        quoteTokenPrice,
      ),
      new BigNumber(poolLiquidityNftPositionPerBlockRaw),
    );
    return { apr, poolWeight };
  } catch (err: any) {
    return { apr: 0, poolWeight: 0 };
  }
};

export const getLiquidityPoolRewardAprWithData = async (
  poolLiquidityNftAllocPointRaw: any,
  poolLiquidityNft: any,
  poolLiquidityNftPositionPerBlockRaw: any,
  priceEarningToken: string | number,
  quoteTokenPrice: string | number,
) => {
  try {
    const allocPoint = new BigNumber((poolLiquidityNftAllocPointRaw && poolLiquidityNftAllocPointRaw[2]._hex) || 0);

    const poolWeight = allocPoint.div(new BigNumber((poolLiquidityNft && poolLiquidityNft[0]._hex) || 1));
    const apr = getFarmApy(
      poolWeight as BigNumber,
      new BigNumber(priceEarningToken),
      new BigNumber((poolLiquidityNftAllocPointRaw && poolLiquidityNftAllocPointRaw[1]._hex) || 0).times(
        quoteTokenPrice,
      ),
      new BigNumber(poolLiquidityNftPositionPerBlockRaw),
    );
    return { apr, poolWeight };
  } catch (err: any) {
    return { apr: 0, poolWeight: 0 };
  }
};

export const getPoolNFTNewApy = (): number | null => {
  const [apy, setApy] = useState<number | null>(null);
  const { slowRefresh } = useRefresh();
  const getApr = useCallback(async () => {
    try {
      const [totalSupply, rewardRate] = await multicall(generalNFTRewardABI, [
        {
          address: getNewGeneralNFTRewardAddress(),
          name: 'totalSupply',
          params: [],
        },
        {
          address: getNewGeneralNFTRewardAddress(),
          name: '_rewardRate',
          params: [],
        },
      ]);
      if (totalSupply && rewardRate) {
        const bigTotalSupply: any = new BigNumber(totalSupply && totalSupply[0]._hex);
        const bigRewardRate: any = new BigNumber(rewardRate && rewardRate[0]._hex);
        const apyRaw: number = (((bigRewardRate * 31556926) / bigTotalSupply) * 100) as number;
        setApy(apyRaw);
      }
    } catch (err: any) {}
  }, []);

  useEffect(() => {
    getApr();
  }, [slowRefresh]);
  return apy;
};

/**
 * Get farm APY value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getFarmApy = (
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  posiPerBlock?: BigNumber,
): number | null => {
  // console.log(posiPerBlock.toNumber(), poolWeight.toString(), poolLiquidityUsd.toString());
  const yearlyCakeRewardAllocation = (posiPerBlock || CAKE_PER_BLOCK).times(BLOCKS_PER_YEAR).times(poolWeight);
  const apy = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100);
  return apy.isNaN() || !apy.isFinite() ? null : apy.toNumber();
};

export const getFarmCakeApr = (poolWeight: BigNumber, cakePrice: BigNumber, poolLiquidityUsd: BigNumber): number => {
  const yearlyCakeRewardAllocation = poolWeight ? poolWeight.times(CAKE_PER_BLOCK) : new BigNumber(NaN);
  const cakeRewardsApr = yearlyCakeRewardAllocation.times(cakePrice).div(poolLiquidityUsd).times(100);
  let cakeRewardsAprAsNumber = null;
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber();
  }
  return cakeRewardsAprAsNumber;
};

export default null;
