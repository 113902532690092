import { Contract } from 'ethers';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import bondABI from '../configs/abi/bond.json';
import bondLendingABI from '../configs/abi/bondLending.json';
import bondCommitABI from '../configs/abi/bondCommit.json';
import cakeAbi from '../configs/abi/cake.json';
import bep20Abi from '../configs/abi/erc20.json';
import generalNFTReward from '../configs/abi/generalNFTReward.json';
import generalNFTRewardNewAbi from '../configs/abi/generalNFTRewardNew.json';
import lotteryAbi from '../configs/abi/lottery.json';
import PoolEarn3rdTokenAbi from '../configs/abi/PoolEarn3rdToken.json';
import posiGovernanceAbi from '../configs/abi/governanceAbi.json';
import posiTicketAbi from '../configs/abi/posiTicketAbi.json';
import exchangeLiquidityPoolAbi from '../configs/abi/exchange-liquidity-pool.json';
import ifoAbi from '../configs/abi/ifo.json';
import lpTokenAbi from '../configs/abi/lpToken.json';
import masterChef from '../configs/abi/masterchef.json';
import posiBusdPairABI from '../configs/abi/posiBusdPair.json';
import posiMarketplace from '../configs/abi/posiMarketplace.json';
import posiNft from '../configs/abi/posiNft.json';
import posiNFTFactory from '../configs/abi/posiNFTFactory.json';
import posiNFTMintProxy from '../configs/abi/posiNFTMintProxy.json';
import positionReferralABI from '../configs/abi/positionReferral.json';
import posiBondLendingAbi from '../configs/abi/bondLendingAbi.json';
import sousChef from '../configs/abi/sousChef.json';
import vaultAbiBNB from '../configs/abi/vaultAbiBNB.json';
import vaultAbiBUSD from '../configs/abi/vaultAbiBUSD.json';
import positionBondRouterABI from '../configs/abi/positionBondRouter.json';
import vaults from '../configs/constants/vaults';
import poolsConfig from '../configs/pools';
import { Vault } from '../redux/types';

// Addresses
import {
  getAddress,
  getCakeAddress,
  getDynamicGovernanceAddress,
  getGeneralNFTRewardAddress,
  getLotteryAddress,
  getMarketplaceProxyAddress,
  getMasterChefAddress,
  getNewGeneralNFTRewardAddress,
  getPosiBusdPairAddress,
  getPosiFactory,
  getPosiGovernanceAddress,
  getPosiNftAddress,
  getPosiNFTMintProxy,
  getPosiTicketAddress,
  getV3GeneralNFTRewardAddress,
  useGetPositionHouseAddressAndAbi,
} from './addressHelpers';
import { getWeb3NoAccount } from './web3';
import { getBridgeAssetAbi, getBridgeAssetAddress } from '../adapters/services/BridgeAssetService';
import { ChainTypes } from '../adapters/types';
import contracts from '../configs/constants/contracts';

export const getContract = (abi: any, address: string, web3?: Web3) => {
  const web3NoAccount = getWeb3NoAccount();
  const _web3 = web3 ?? web3NoAccount;
  // @ts-ignore
  return new _web3.eth.Contract(abi as unknown as AbiItem, address, { transactionConfirmationBlocks: 3 });
};

export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3);
};
export const getLpContract = (address: string, web3?: Web3) => {
  return getContract(lpTokenAbi, address, web3);
};
export const getIfoContract = (address: string, web3?: Web3) => {
  return getContract(ifoAbi, address, web3);
};
export const getPositionReferralContract = (address: string, web3?: Web3) => {
  return getContract(positionReferralABI, address, web3);
};
export const getSouschefContract = (id: any, web3?: Web3) => {
  const config = poolsConfig.find((pool) => pool.sousId === id);
  return getContract(sousChef, getAddress(config?.contractAddress as any), web3);
};

export const getPosiGovernanceContract = (web3?: Web3) => {
  return getContract(posiGovernanceAbi, getPosiGovernanceAddress(), web3);
};

export const getPosiTicketContract = (web3?: Web3) => {
  return getContract(posiTicketAbi, getPosiTicketAddress(), web3);
};

export const getPosiVaultContract = (vid: number, web3?: Web3) => {
  const vaultRaw = vaults.find((obj) => obj.id === vid) as Vault;
  return getContract(
    vaultRaw.tokenSymbol === 'BNB' ? vaultAbiBNB : vaultAbiBUSD,
    getAddress(vaultRaw.vaultAddress),
    web3,
  );
};

export const getPosiNftContract = (web3?: Web3) => {
  return getContract(posiNft, getPosiNftAddress(), web3);
};

export const getPosiBusdPairContract = (web3?: Web3) => {
  return getContract(posiBusdPairABI, getPosiBusdPairAddress(), web3);
};

export const getGeneralNFTRewardsContract = (web3?: Web3) => {
  return getContract(generalNFTReward, getGeneralNFTRewardAddress(), web3);
};
export const getNewGeneralNFTRewardsContract = (web3?: Web3) => {
  return getContract(generalNFTRewardNewAbi, getNewGeneralNFTRewardAddress(), web3);
};
export const getV3GeneralNFTRewardsContract = (web3?: Web3) => {
  return getContract(generalNFTRewardNewAbi, getV3GeneralNFTRewardAddress(), web3);
};
export const getMarketplaceProxyContract = (web3?: Web3) => {
  return getContract(posiMarketplace, getMarketplaceProxyAddress(), web3);
};
export const getCakeContract = (web3?: Web3) => {
  return getContract(cakeAbi, getCakeAddress(), web3);
};
export const getMasterchefContract = (web3?: Web3) => {
  return getContract(masterChef, getMasterChefAddress(), web3);
};
export const getStakeManagerToEarn3rdContract = (address: string, web3?: Web3) => {
  return getContract(PoolEarn3rdTokenAbi, address, web3);
};
export const getPosiNFTMintProxyContract = (web3?: Web3) => {
  return getContract(posiNFTMintProxy, getPosiNFTMintProxy(), web3);
};

export const getPosiNFTFactoryContract = (web3?: Web3) => {
  return getContract(posiNFTFactory, getPosiFactory(), web3);
};

export const getBondContract = (address: string, web3?: Web3) => {
  return getContract(bondABI, address, web3);
};

export const getBondCommitContract = (address: string, web3?: Web3) => {
  return getContract(bondCommitABI, address, web3);
};

export const getBondLendingFactoryContract = (web3?: Web3) => {
  return getContract(bondLendingABI, getAddress(contracts.bondLendingFactory), web3);
};

export const getPositionBondRouterContract = (web3?: Web3) => {
  return getContract(positionBondRouterABI, getAddress(contracts.positionBondRouter), web3);
};

export const getBondLendingContract = (address: string, web3?: Web3) => {
  return getContract(posiBondLendingAbi, address, web3);
};

// export const getLendingPoolContract = (web3?: Web3) => {
//   return getContract(lendingPool, getLendingPoolAddress(), web3)
// }

// export const getWethGatewayContract = (web3?: Web3) => {
//   return getContract(wethGateway, getWethGatewayAddress(), web3)
// }

// export const getDebtWETHContract = (interestRate: string, web3?: Web3) => {
// 	const [stableDebtWETH, variableDebtWETH] = getDebtWETHAddresses()
//   const isStableRate = interestRate === '1'

//   return getContract(
//     isStableRate ? StableDebtTokenABI : VariableDebtTokenABI,
//     isStableRate ? stableDebtWETH : variableDebtWETH,
//     web3
//   )
// }

export const useGetPositionHouseContract = async (pairManager: string, web3: any): Promise<Contract> => {
  const { spotHouseAddress, spotHouseABI } = await useGetPositionHouseAddressAndAbi(pairManager);

  return getSpotContract(spotHouseABI, spotHouseAddress, web3);
};

export const getSpotContract = (abi: any, address: string, web3?: Web3): Contract => {
  return getContract(abi, address, web3) as unknown as Contract;
};
export const getDynamicGovernanceContract = async (web3?: Web3) => {
  const governanceAddress = await getDynamicGovernanceAddress();
  return getContract(posiGovernanceAbi, governanceAddress, web3);
};

export const useGetExchangeLiquidityContractByAddress = async (address: string, web3?: Web3) => {
  return getContract(exchangeLiquidityPoolAbi, address, web3);
};

export const getLotteryContract = (web3?: Web3) => {
  return getContract(lotteryAbi, getLotteryAddress(), web3);
};

export const getBridgeAssetContract = (type: ChainTypes, web3?: Web3) => {
  const bridgeAssetAbi = getBridgeAssetAbi(type);
  const bridgeAssetAddress = getBridgeAssetAddress(type);
  return getContract(bridgeAssetAbi, bridgeAssetAddress, web3);
};
