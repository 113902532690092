import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPosiFactory } from '../../utils/addressHelpers';
import multicall from '../../utils/multicall';
import posiNftFactoryABI from '../../configs/abi/posiNFTFactory.json';
import { NftCastedHistory, NftState } from '../types';
import {
  fetchNFTUserAllowances,
  fetchUserBalances,
  getNftHistories,
  getTokensOfUser,
  getTokenStaked,
  getTokenNewStaked,
  getNftPurchasedId,
} from './fetchNftUser';
import BigNumber from 'bignumber.js';
import { calculateMiningPower } from '../../utils/calculatePower';
import { getBalanceNumber } from '../../utils/formatBalance';
const initialState: NftState = {
  myNftData: [],
  nftCastedHistoryData: [],
  nftStaked: [],
  nftsBirthday: null,
};
export const nftSlice = createSlice({
  name: 'Nfts',
  initialState,
  reducers: {
    addDataToMyNft: (state, action: any) => {
      const data = state;
      data.myNftData = [action.payload, ...data.myNftData];
      return data;
    },
    deleteDataFromMyNft: (state, action: any) => {
      const data = state;
      data.myNftData = data.myNftData.filter((e) => e.id !== action.payload);
      return data;
    },
    addDataToNftCastedHistory: (state, action: PayloadAction<NftCastedHistory>) => {
      const data = state;
      data.nftCastedHistoryData = [action.payload, ...data.nftCastedHistoryData];
    },
    setMyNft: (state, action) => {
      const data = state;
      data.myNftData = action.payload;
    },
    setNftUserData: (state, action) => {
      const data = state;
      data.userData = action.payload;
      return data;
    },
    setNftUserDataAllowanceByField: (state, action: PayloadAction<{ field: string; value: string }>) => {
      const data = state;
      const { field, value } = action.payload;

      const index = field === 'isApproveCast' ? 0 : 1;

      const newArr = data?.userData?.allowance;

      newArr[index] = value;

      data.userData = {
        ...data.userData,
        allowance: newArr,
      };
      return data;
    },
    setNftHistoryData: (state, action) => {
      const data = state;
      data.nftCastedHistoryData = action.payload;
    },
    addNftStakesToMyNft: (state, action) => {
      const data = state;
      data.myNftData = data.myNftData.concat(action.payload);
      return data;
    },
    setNftStaked: (state, action) => {
      const data = state;
      data.nftStaked = action.payload;
      return data;
    },
    setNftBirthdayPosi: (state, action) => {
      const data = state;
      data.nftsBirthday = action.payload;
      return data;
    },
  },
});

export const {
  addDataToMyNft,
  deleteDataFromMyNft,
  addDataToNftCastedHistory,
  setNftUserData,
  setMyNft,
  setNftHistoryData,
  setNftUserDataAllowanceByField,
  addNftStakesToMyNft,
  setNftStaked,
  setNftBirthdayPosi,
} = nftSlice.actions;

export const handlerAddDataToMyNft = (value: any) => (dispatch: any) => {
  dispatch(addDataToMyNft(value));
};

export const handlerDeleteDataFromToMyNft = (id: string) => (dispatch: any) => {
  dispatch(deleteDataFromMyNft(id));
};

export const handlerAddDataToNftCastedHistory = (value: any) => (dispatch: any) => {
  dispatch(addDataToNftCastedHistory(value));
};

// fetch nft user data
export const fetchNftUserDataAsync = (account: any) => async (dispatch: any) => {
  const userNftsAllowances = await fetchNFTUserAllowances(account);
  const stakingTokenBalances: any = await fetchUserBalances(account);
  const arrayOfUserDataObjects = {
    allowance: userNftsAllowances,
    tokenBalances: stakingTokenBalances,
  };
  getNftHistories(account).then((histories: any) => {
    dispatch(setNftHistoryData(histories || []));
  });
  dispatch(setNftUserData({ ...arrayOfUserDataObjects }));
};

export const fetchAllNftOfUser =
  (account: any, posiNftContract?: any, generalNFTRewardContract?: any, generalNFTRewardContractNew?: any, generalNFTRewardContractv3?: any) =>
  async (dispatch: any) => {
    const tokensInfo = await getTokensOfUser(account, posiNftContract);
    const stakedTokens = generalNFTRewardContract && await getTokenStaked(account, generalNFTRewardContract) || [];
    const stakedTokensNew = generalNFTRewardContractNew && await getTokenNewStaked(account, generalNFTRewardContractNew) || [];
    const stakedTokensv3 = generalNFTRewardContractv3 && await getTokenStaked(account, generalNFTRewardContractv3) || [];
    const newList = [...stakedTokens.map(data=>({stakeVersion: 'v1', ...data})), ...stakedTokensNew.map(data=>({stakeVersion: 'v2', ...data})), ...stakedTokensv3.map(data=>({stakeVersion: 'v3', ...data}))];//stakedTokens.length ? stakedTokens.concat(stakedTokensNew) : stakedTokensNew;
    tokensInfo?.length ? dispatch(setMyNft(tokensInfo)) : dispatch(setMyNft([]));
    newList?.length || newList.length ? dispatch(setNftStaked(newList)) : dispatch(setNftStaked([]));
  };

export const fetchNftBirthdayOfUser = (account: any) => async (dispatch: any) => {
  const nftId = await getNftPurchasedId(account);
  if (nftId) {
    const call = [
      {
        address: getPosiFactory(),
        name: 'getGego',
        params: [nftId],
      },
    ];

    const [gegoInfoRaw] = await multicall(posiNftFactoryABI, call);

    const data = {
      id: call[0].params[0],
      grade: new BigNumber(gegoInfoRaw['grade']._hex)?.toJSON(),
      amount: getBalanceNumber(new BigNumber(gegoInfoRaw['amount']._hex)?.toNumber()),
      quality: new BigNumber(gegoInfoRaw['quality']._hex)?.toJSON(),
      author: gegoInfoRaw['author'],
      createdTime: new BigNumber(gegoInfoRaw['createdTime']._hex)?.toJSON(),
      minBurnTime: new BigNumber(gegoInfoRaw['createdTime']._hex)
        ?.multipliedBy(1000)
        .plus(new BigNumber(gegoInfoRaw['lockedDays']._hex)?.toNumber() * 86400000)
        .toNumber(),
      lockedDays: new BigNumber(gegoInfoRaw['lockedDays']._hex)?.toJSON(),
      erc20: gegoInfoRaw['erc20'],
      action: 'myNft',
      miningPower: calculateMiningPower(
        new BigNumber(gegoInfoRaw['grade']._hex)?.toJSON(),
        new BigNumber(gegoInfoRaw['quality']._hex)?.toJSON(),
        getBalanceNumber(new BigNumber(gegoInfoRaw['amount']._hex)?.toNumber()),
      ),
    };

    dispatch(setNftBirthdayPosi(data));
  } else {
    dispatch(setNftBirthdayPosi(null));
  }
};

export default nftSlice.reducer;
