import {
  Button,
  Center,
  ChakraProvider,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { getDetailNetWorkByChainId } from '../../adapters/services/BridgeAssetService';
import themeContext from '../../configs/contexts/ThemeContext';
import ErrorJson from '../../constants/error.json';
import Loader from '../../constants/loading.json';

import { useGetChainIdFromWindow } from '../../hooks/useGetChainIdFromWindow';
import { getBscScanLink, getSnapshotProposalLink } from '../../utils';
import Typography from '../Typography';

interface IResponseModal {
  isOpenModal: boolean;
  onDismiss?: (val?: any) => void;
  txh?: string;
  errorMsg?: string;
  titleContent?: any;
  additionalMsg?: any;
  objectResult?: any;
  isSubmit?: any;
  idHash?: string;
  customBtn?: any;
  isPending?: boolean;
}

const ResponseModal: React.FC<IResponseModal> = ({
  isOpenModal,
  onDismiss = () => null,
  txh,
  errorMsg,
  titleContent,
  additionalMsg,
  objectResult,
  isSubmit,
  idHash,
  customBtn,
  isPending,
}) => {
  return (
    <ChakraProvider theme={themeContext}>
      <Modal size={'md'} isCentered blockScrollOnMount={true} isOpen={isOpenModal} onClose={onDismiss}>
        <ModalOverlay width="100%" height="100%" />
        <ModalContent
          zIndex="9999"
          p={['16px', '16px', '24px', '24px']}
          mx="10px"
          background={'gray.800'}
          borderRadius="24px"
        >
          <ModalCloseButton
            _hover={{ bg: 'gray.800' }}
            _active={{ bg: 'gray.800' }}
            align="center"
            color={'gray.500'}
            display={isPending && !(errorMsg || txh) ? 'none' : 'flex'}
          />
          <ModalBody p="0px">
            {errorMsg ? (
              <FailedModal titleContent={titleContent} errorMessage={errorMsg} />
            ) : txh ? (
              <SuccessModal
                txHash={txh}
                titleContent={titleContent}
                additionalMsg={additionalMsg}
                objectResult={objectResult}
                isSubmit={isSubmit}
              />
            ) : isPending ? (
              <PendingModal />
            ) : (
              <SuccessModal
                txHash={''}
                titleContent={titleContent}
                additionalMsg={additionalMsg}
                objectResult={objectResult}
                isSubmit={isSubmit}
                idHash={idHash}
                customBtn={customBtn}
              />
            )}
            <Flex mb={'24px'} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

const SuccessModal = ({
  txHash,
  titleContent,
  additionalMsg,
  objectResult,
  isSubmit = false,
  idHash,
  customBtn,
}: {
  txHash: string;
  titleContent?: any;
  additionalMsg?: any;
  objectResult?: any;
  isSubmit?: any;
  idHash?: string;
  customBtn?: any;
}) => {
  const chainId = useGetChainIdFromWindow();
  const { t } = useTranslation(['nft', 'swap']);
  // if (!chainId) return null;
  const currentNetwork = getDetailNetWorkByChainId(chainId);
  const link = getBscScanLink(chainId || Number(process.env.REACT_APP_CHAIN_ID || 56), txHash, 'transaction');
  const linkSnapshot = (idHash && getSnapshotProposalLink(idHash)) || '';
  return (
    <Flex flexDirection={'column'} pt={'32px'} justifyContent={'center'}>
      <Center w="100%">
        <Image
          width={'100px'}
          height={'100px'}
          src="https://storage.googleapis.com/webapp-statics/assets/bondLending/check-circle.svg"
        />
      </Center>
      <Flex mt="29px" textAlign={'center'} flexDirection={'column'} justifyContent={'center'}>
        <Typography type="headline4" color="gray.100">
          {titleContent || t('Success')}
        </Typography>
        {typeof additionalMsg === 'string' ? (
          <Text color={'gray.500'} fontSize={'14px'} mt="0px">
            {additionalMsg || t('Success')}
          </Text>
        ) : (
          additionalMsg
        )}
        {txHash && (
          <Flex w="100%" justify="space-between" flexDirection={['column', 'row', 'row', 'row', 'row', 'row']}>
            <Flex w="100%" direction={'column'}>
              <Typography mt="12px" color={'gray.500'} type="body2">
                {t('swap:des_submited')}
              </Typography>
              <Flex mx="auto" justify={'center'} mt="24px" onClick={() => window.open(link)} cursor="pointer">
                <Typography type="button2" color={'cyan.400'}>
                  {t('swap:view_transaction')}
                </Typography>
                <Image
                  ml="14px"
                  src="https://storage.googleapis.com/webapp-statics/assets/bondLending/chevron_right_icon_blue.svg"
                />
              </Flex>
            </Flex>
            {objectResult && (
              <Button
                // onClick={handleGoToItem}
                mx={'auto'}
                as={'a'}
                href={
                  objectResult?.AuctionListed?.returnValues?.id
                    ? `/nft/marketplace/auction/${objectResult?.AuctionListed?.returnValues?.id}/${objectResult?.AuctionListed?.returnValues?.nft}`
                    : `/nft/marketplace/market/${objectResult?.MarketListed?.returnValues?.id}/${objectResult?.MarketListed?.returnValues?.nft}`
                }
                mt={['16px', '16px', '16px', '16px', '16px', '16px']}
                mb={['0px', '16px', '16px', '16px', '16px', '16px']}
                background={'blue.400'}
                _hover={{ background: 'blue.400' }}
                color={'white'}
                borderRadius={'24px'}
                h={'48px'}
                w={['100%', '48%', '48%', '48%', '48%', '48%']}
              >
                {t('nft:view_item')}
              </Button>
            )}
          </Flex>
        )}
        {idHash && (
          <Flex
            mx="auto"
            alignItems={'center'}
            as={Link}
            href={linkSnapshot}
            target="_blank"
            color="cyan.400"
            mt="16px"
            fontSize="14px"
            fontWeight={'medium'}
          >
            {t('swap:view_proposal_detail')}{' '}
            <Flex ml="4px">
              <svg width={8} height={12} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.25 1.75L6.5 7L1.25 12.25"
                  stroke="#0BC5EA"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Flex>
          </Flex>
        )}
        {customBtn && (
          <Flex w="100%" justify="space-between" flexDirection={['column', 'row', 'row', 'row', 'row', 'row']}>
            {customBtn}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const FailedModal = ({ errorMessage, titleContent }: { errorMessage: string; titleContent?: any }) => {
  const { t } = useTranslation(['nft']);
  return (
    <Flex flexDirection={'column'} pt={'32px'} justifyContent={'center'}>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: ErrorJson,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={'100px'}
        height={'100px'}
      />
      <Flex textAlign={'center'} flexDirection={'column'} justifyContent={'center'}>
        <Text color={'white'} fontSize={'22px'} fontWeight={600}>
          {titleContent || t('Failed')}
        </Text>
        <Text color="red.400" fontSize="13px">
          {errorMessage || ''}
        </Text>
      </Flex>
    </Flex>
  );
};

const PendingModal = () => {
  const { t } = useTranslation('swap');
  const gray50ToGray900 = useColorModeValue('gray.900', 'white');

  return (
    <Flex flexDirection={'column'} pt={'19x'} justifyContent={'center'}>
      <Lottie
        options={{
          loop: true,
          animationData: Loader,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={'100px'}
        height={'100px'}
      />
      <Typography textAlign="center" mt="16px" color={gray50ToGray900} type="headline4">
        {t('processing')}
      </Typography>
      <Typography textAlign="center" mx="auto" mt="12px" color="gray.500" w="280px" type="body2-r">
        {t('des_pending_modal')}
      </Typography>
    </Flex>
  );
};

export default ResponseModal;
