import { getMessaging, isSupported } from 'firebase/messaging/sw';
import { getToken as getTokenFirebase } from 'firebase/messaging';

import app from './index';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { readDataInStorage } from '../indexedDB/cacheController';

export const getToken = async (account: string) => {
  return new Promise((resolve, reject) => {
    isSupported()
      .then((response) => {
        const messaging = getMessaging(app);
        // (window as any).safari.pushNotification.requestPermission('https://localhost:3000').then((token) => {
        //   callback(token);
        //
        // });
        // if (!('Notification' in window)) {
        //   // Check if the browser supports notifications
        //   alert('This browser does not support desktop notification');
        // } else if (Notification.permission === 'granted') {
        //   // Check whether notification permissions have already been granted;
        //   // if so, create a notification
        //   const notification = new Notification('Hi there!');
        //   // …
        // } else if (Notification.permission !== 'denied') {
        //   // We need to ask the user for permission
        //   Notification.requestPermission().then((permission) => {
        //     // If the user accepts, let's create a notification
        //     if (permission === 'granted') {
        //       const notification = new Notification('Hi there!');
        //       // …
        //     }
        //   });
        // }
        if (response) {
          getTokenFirebase(messaging, {
            vapidKey: process.env.REACT_APP_VAPID,
          })
            .then(async (currentToken) => {
              if (currentToken) {
                resolve(currentToken);

                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
              }
            })
            .catch((err) => {
              if (err?.message && err?.message?.includes('messaging/permission-blocked')) {
                reject('');
              } else {
                getToken(account);
              }
            });
        } else {
          reject('');
        }
      })
      .catch((e) => {
        reject('');
      });
  });
};

/**
 * object data sample of fcmInfo
 * {
 *   exp: timestamp
 *   token: token
 *   deviceId: uuid
 * }
 */

/**
 *
 * @param account string account
 */
export const checkingAndGetToken = async (account: string, isRefreshCall = false): Promise<string> => {
  let deviceId;
  let exp;
  let token;
  try {
    let isChangeToken = false;
    // expire token: config 1 month
    const expTokenFcm = localStorage.getItem('fcmInfo');
    // init data
    if (expTokenFcm) {
      const expTokenFcmData = JSON.parse(expTokenFcm);
      let isExistRegistration = false;
      if (navigator && navigator.serviceWorker) {
        const listRegistrations = await navigator.serviceWorker.getRegistrations();
        const exist = listRegistrations.find((a) => a?.scope.includes('/firebase-cloud-messaging-push-scope'));
        if (exist) {
          isExistRegistration = true;
        }
      }
      if (!isExistRegistration) {
        token = await getToken(account);
        exp = moment().add(1, 'month').unix();
        isChangeToken = true;
      } else if (expTokenFcmData?.deviceId) {
        deviceId = expTokenFcmData?.deviceId;
        const currentTime = moment().unix();
        if (currentTime > Number(expTokenFcmData?.exp)) {
          // refresh token
          isChangeToken = true;
          token = await getToken(account);
          exp = moment().add(1, 'month').unix();
        } else {
          token = expTokenFcmData?.token;
          exp = expTokenFcmData?.exp;
        }
      }
    } else {
      // first time
      deviceId = uuidv4();
      token = await getToken(account);
      exp = moment().add(1, 'month').unix();
    }
    let listAccounts = [];

    const listAccountRegistrations = await readDataInStorage('account');
    if (listAccountRegistrations) {
      const listAccountsWithTopic = JSON.parse(listAccountRegistrations.data);
      if (listAccountsWithTopic.length > 0) {
        listAccounts = listAccountsWithTopic.map((item) => item?.user_address);
      }

      if (!listAccounts.includes(account && account.toLowerCase())) {
        listAccounts.push(account && account.toLowerCase());
      }
    } else {
      listAccounts.push(account && account.toLowerCase());
    }

    if (!isRefreshCall) {
      localStorage.setItem(
        'fcmInfo',
        JSON.stringify({
          deviceId,
          exp,
          token,
        }),
      );

      // send to server
      // if change account: send same token if not expire
      // call send data....
      const res = await registerWithServer([account], token, deviceId);
      if (res) {
        return token;
      }
    } else {
      if (isChangeToken) {
        localStorage.setItem(
          'fcmInfo',
          JSON.stringify({
            deviceId,
            exp,
            token,
          }),
        );

        // send to server
        // if change account: send same token if not expire
        // call send data....
        const res = await registerWithServer([account], token, deviceId);
        if (res) {
          return token;
        }
      }
    }
    return '';
  } catch (e) {
    return '';
  }
};

export const registerWithServer = async (listAccounts: string[], token: string, deviceId: string): Promise<boolean> => {
  try {
    await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_NOTIFICATION_URL}/tokens`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        user_address: listAccounts,
        device_token: token,
        device_platform: 'web',
        device_id: deviceId,
        device_name: '',
      },
    });
    return true;
  } catch (e) {
    return false;
  }
};
