interface Database {
  write(data: { [key: string]: number | string | null | undefined });

  read(keyName: string);

  delete(keyName: string);

  update(data: { [key: string]: number | string | null | undefined });
}

class IndexedDB implements Database {
  private DBInstance;
  private readonly dbName: string;
  private readonly primaryKey: string;

  constructor(indexedDB: any, dbName: string, schema: string[]) {
    // Open (or create) the database
    const instance = indexedDB.open(dbName, 1);
    instance.onsuccess = (e) => {
      this.DBInstance = instance.result;
    };
    this.dbName = dbName;
    this.primaryKey = schema[0];

    // Create the schema
    instance.onupgradeneeded = function () {
      const db = instance.result;
      const store = db.createObjectStore(dbName, { keyPath: schema[0] });
      // indexes
      schema.forEach((i, index) => {
        if (index !== 0) {
          store.createIndex(i, i, { unique: false });
        }
      });
    };
  }

  private async checkCreatedInstance() {
    return new Promise((resolve) => {
      if (this.DBInstance) {
        resolve(true);
      } else {
        this.checkAndResolve(resolve);
      }
    });
  }

  private checkAndResolve(resolve) {
    if (this.DBInstance) {
      resolve(true);
    } else {
      setTimeout(() => {
        this.checkAndResolve(resolve);
      }, 100);
    }
  }

  public async write(data: { [key: string]: number | string | null | undefined }) {
    await this.checkCreatedInstance();
    const transaction = this.DBInstance.transaction(this.dbName, 'readwrite');

    const objectStore = transaction.objectStore(this.dbName);

    const request = objectStore.add(data);

    return new Promise((resolve) => {
      request.onsuccess = () => {
        if (request.result) {
          resolve(true);
        }
        resolve(false);
      };

      request.onerror = () => {
        resolve(false);
      };
    });
  }

  public async update(data: { [key: string]: number | string | null | undefined }) {
    await this.checkCreatedInstance();
    const transaction = this.DBInstance.transaction(this.dbName, 'readwrite');
    const objectStore = transaction.objectStore(this.dbName);

    const request = objectStore.get(data.name);

    return new Promise((resolve) => {
      request.onsuccess = () => {
        if (request.result) {
          const dataRaw = request.result;
          dataRaw.data = data.data;
          // Create a request to update
          const updateRequest = objectStore.put(dataRaw);
          updateRequest.onsuccess = () => {
            return resolve(null);
          };
        }
        return resolve(null);
      };

      request.onerror = (error) => {
        return resolve(null);
      };
    });
  }

  public async read(keyName: string) {
    await this.checkCreatedInstance();
    const transaction = this.DBInstance.transaction(this.dbName, 'readwrite');
    const objectStore = transaction.objectStore(this.dbName);

    const request = objectStore.get(keyName);

    return new Promise((resolve) => {
      request.onsuccess = () => {
        if (request.result) {
          return resolve(request.result);
        }
        return resolve(null);
      };

      request.onerror = () => {
        return resolve(null);
      };
    });
  }

  public async delete(keyName: string) {
    const transaction = this.DBInstance.transaction(this.dbName, 'readwrite');

    const objectStore = transaction.objectStore(this.dbName);

    const request = objectStore.delete(keyName);

    return new Promise((resolve) => {
      request.onsuccess = () => {
        if (request.result) {
          resolve(true);
        }
        resolve(false);
      };

      request.onerror = () => {
        resolve(false);
      };
    });
  }
}

class LocalStorageDatabase implements Database {
  private readonly inMemStorage: Storage;
  private readonly keyStorage: string;
  private readonly keyPrefix = 'cache_';

  constructor(primaryKey: string) {
    this.inMemStorage = window.localStorage;
    this.keyStorage = primaryKey;
  }

  public async write(data: { [key: string]: number | string | null | undefined }) {
    this.inMemStorage.setItem(`${this.keyPrefix}${data[this.keyStorage].toString()}`, JSON.stringify(data));
    return true;
  }

  public async update(data: { [key: string]: number | string | null | undefined }) {
    this.inMemStorage.setItem(`${this.keyPrefix}${data[this.keyStorage].toString()}`, JSON.stringify(data));
    return true;
  }

  public async read(keyName: string) {
    const data = this.inMemStorage.getItem(`${this.keyPrefix}${keyName}`);
    return JSON.parse(data);
  }

  public async delete(keyName: string) {
    this.inMemStorage.removeItem(`${this.keyPrefix}${keyName}`);
    return true;
  }
}

/**
 *
 * @param dbName string
 * @param schema string[] first of list is keypath
 * @returns instance of Database
 */
export function resolveDB(dbName: string, schema: string[]): Database {
  const indexedDB =
    window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
  window.IDBTransaction = window.IDBTransaction ||
    window.webkitIDBTransaction ||
    window.msIDBTransaction || { READ_WRITE: 'readwrite' };
  window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
  // checking browser support
  if (!indexedDB) {
    // eslint-disable-next-line no-console
    return new LocalStorageDatabase(schema[0]);
  }
  return new IndexedDB(indexedDB, dbName, schema);
}
