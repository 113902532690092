import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchCurrentLotteryId,
  fetchCurrentLotteryRoundDetail,
  getUserPrizeByRound,
  getUserTicketsByRound,
  getListWinnerByLotteryId,
  fetchWinningHistory,
} from './service';
import { ListWinnerItem, LotteryResponse, WinningHistory } from '../types';
import { formatTicketNumberToUse } from '../../utils/common';
import moment from 'moment';

export interface PrizeItem {
  totalPrize: number;
  lotteryId: number | string;
  luckyNumberOfRound: string;
  isClaimed: boolean;
  data: {
    timestamp: number;
    luckyNumber: string;
    amount: number;
    bracket: number;
    reward: number;
  }[];
}

interface TicketItem {
  lotteryId: number | string;
  data: {
    timestamp: number;
    luckyNumber: string;
    amount: number;
    status: boolean;
  }[];
}

interface LotteryState {
  currentRound: LotteryResponse;
  previousRound: LotteryResponse;
  listPrize: PrizeItem[];
  listTicket: TicketItem[];
  previousRoundListWinner: ListWinnerItem[];
  previousRoundLuckyWinners: string;
  winningHistory: WinningHistory[];
  isDraw: boolean;
}

const initialState: LotteryState = {
  currentRound: null,
  listPrize: [],
  listTicket: [],
  previousRoundListWinner: [],
  previousRound: null,
  previousRoundLuckyWinners: null,
  winningHistory: [],
  isDraw: false,
};

export const lotterySlice = createSlice({
  name: 'lottery',
  initialState,
  reducers: {
    setCurrentLotteryRound: (state, action) => {
      state.currentRound = {
        ...state.currentRound,
        ...action.payload,
      };
    },
    updateDraw: (state, action) => {
      state.isDraw = action.payload;
    },
    addItemIntoListPrize: (state, action: PayloadAction<PrizeItem>) => {
      state.listPrize.push(action.payload);
    },
    modifyItemListPrize: (state, action: PayloadAction<{ lotteryId: string; field: string; value: any }>) => {
      const currentList = state.listPrize;
      const { lotteryId, field, value } = action.payload;

      const currentIndex = currentList.findIndex((a) => Number(a?.lotteryId) === Number(lotteryId));
      if (currentIndex !== -1) {
        state.listPrize[currentIndex][field] = value;
      }
    },
    addItemIntoListTicket: (state, action: PayloadAction<TicketItem>) => {
      state.listTicket.push(action.payload);
    },
    modifyItemListTicket: (state, action: PayloadAction<TicketItem>) => {
      const currentList = state.listTicket;

      const currentIndex = currentList.findIndex((a) => Number(a?.lotteryId) === Number(state.currentRound.lotteryId));
      if (currentIndex !== -1) {
        state.listTicket[currentIndex] = action.payload;
      }
    },
    setPreviousRoundListWinner: (state, action: PayloadAction<ListWinnerItem[]>) => {
      state.previousRoundListWinner = action.payload;
    },
    setPreviousRoundLuckyWinners: (state, action: PayloadAction<string>) => {
      state.previousRoundLuckyWinners = action.payload;
    },
    setPreviousRoundDetail: (state, action) => {
      state.previousRound = {
        ...state.previousRound,
        ...action.payload,
      };
    },
    setWinningHistory: (state, action) => {
      state.winningHistory = action.payload;
    },
  },
});

// Actions
export const {
  setCurrentLotteryRound,
  addItemIntoListPrize,
  addItemIntoListTicket,
  modifyItemListTicket,
  setPreviousRoundListWinner,
  setPreviousRoundLuckyWinners,
  setPreviousRoundDetail,
  setWinningHistory,
  modifyItemListPrize,
  updateDraw,
} = lotterySlice.actions;

// Thunks
export const getCurrentRoundId = () => async (dispatch: any) => {
  try {
    const metaDataLottery = await fetchCurrentLotteryId();
    dispatch(setCurrentLotteryRound(metaDataLottery));
    if (!!metaDataLottery?.currentLotteryId) {
      dispatch(getCurrentRound(metaDataLottery?.currentLotteryId));
    }
  } catch (e) {}
};

export const getPreviousRound = (lotteryId: number) => async (dispatch: any) => {
  try {
    const lotteryDetail = await fetchCurrentLotteryRoundDetail(lotteryId.toString());
    dispatch(setPreviousRoundDetail(lotteryDetail));
  } catch (e) {}
};

export const getCurrentRound = (currentLotteryId: string) => async (dispatch: any) => {
  try {
    const currentLottery = await fetchCurrentLotteryRoundDetail(currentLotteryId);
    dispatch(setCurrentLotteryRound(currentLottery));
  } catch (e) {}
};

export const getWinningHistory = (currentLotteryId: string) => async (dispatch: any) => {
  try {
    const currentLottery = await fetchWinningHistory(Number(currentLotteryId));
    dispatch(setWinningHistory(currentLottery.reverse()));
  } catch (e) {}
};

export const fetchPreviousRoundListWinner = (lotteryId: number) => async (dispatch: any) => {
  try {
    if (!lotteryId) {
      dispatch(setPreviousRoundListWinner([]));
      return;
    }
    const { item_count, players } = await getListWinnerByLotteryId(lotteryId);
    const listWinner: ListWinnerItem[] = players.map((p) => ({
      id: p.id,
      ticketNumber: formatTicketNumberToUse(`1${p.ticket_number}`),
      ticketCount: p.ticket_count,
      prizeLevel: p.prize_level,
      address: p.player_address,
      prize: p.prize,
    }));
    dispatch(setPreviousRoundListWinner(listWinner));
    dispatch(setPreviousRoundLuckyWinners(item_count));
  } catch (e) {}
};

export const getListPrizeByRound = (lotteryId: string, address: string) => async (dispatch: any, getState: any) => {
  try {
    const { listPrize, currentRound } = getState().lottery;
    const existRound = listPrize.find((item: any) => Number(item.lotteryId) === Number(lotteryId));
    if (currentRound) {
      if (!existRound) {
        const dataRound = await getUserPrizeByRound(lotteryId, address);
        if (dataRound) {
          dispatch(addItemIntoListPrize(dataRound));
        }
      }
    } else {
      setTimeout(() => dispatch(getUserPrizeByRound(lotteryId, address)), 50);
    }
  } catch (e) {}
};

export const getListTicketByRound = (lotteryId: string, address: string) => async (dispatch: any, getState: any) => {
  try {
    const { listTicket, currentRound } = getState().lottery;
    const existRound = listTicket.find((item: any) => Number(item.lotteryId) === Number(lotteryId));
    if (currentRound) {
      if (!existRound || Number(lotteryId) === Number(currentRound?.currentLotteryId)) {
        if (Number(lotteryId) === Number(currentRound?.currentLotteryId)) {
          const dataRound = await getUserTicketsByRound(lotteryId, address);
          if (existRound) {
            if (dataRound) {
              dispatch(modifyItemListTicket(dataRound));
            }
          } else {
            if (dataRound) {
              dispatch(addItemIntoListTicket(dataRound));
            }
          }
        } else {
          if (!existRound) {
            const dataRound = await getUserTicketsByRound(lotteryId, address);
            if (dataRound) {
              dispatch(addItemIntoListTicket(dataRound));
            }
          }
        }
      }
    } else {
      setTimeout(() => dispatch(getListTicketByRound(lotteryId, address)), 50);
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateLuckyNumberToCurrentRound = (luckyNumber: string) => (dispatch: any, getState: any) => {
  try {
    const { currentRound } = getState().lottery;
    if (!currentRound?.finalNumber && moment().isSameOrAfter(moment(Number(currentRound?.endTime) * 1000), 'second')) {
      dispatch(setCurrentLotteryRound({ finalNumber: luckyNumber }));
    }
  } catch (e) {}
};

export default lotterySlice.reducer;
