/* eslint-disable no-console */
// Set of helper functions to facilitate wallet setup

import { BASE_BSC_SCAN_URL } from '../configs/index';

export class WindowChain extends Window {
  ethereum: any;
}

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (networkDetail) => {
  const { chainId, chainName, nativeCurrency, rpc, explorerUrl } = networkDetail;
  const provider = (window as WindowChain).ethereum;
  if (provider) {
    const chainIdActive = chainId || parseInt(process.env.REACT_APP_CHAIN_ID || '56', 10);
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainIdActive.toString(16)}`,
            chainName: chainName[chainIdActive] || 'Binance Smart Chain Mainnet',
            nativeCurrency: {
              name: nativeCurrency || 'BNB',
              symbol: nativeCurrency.toLowerCase() || 'bnb',
              decimals: 18,
            },
            rpcUrls: [rpc || 'https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: [explorerUrl || `${BASE_BSC_SCAN_URL}/`],
          },
        ],
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined");
    return false;
  }
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string,
) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  });

  return tokenAdded;
};
