import { AbiItem } from 'web3-utils';
import { Interface } from '@ethersproject/abi';
import { getWeb3NoAccount } from './web3';
import MultiCallAbi from '../configs/abi/Multicall.json';
import { getMulticallAddress } from './addressHelpers';
import ERC_20_ABI from '../configs/abi/erc20.json';

export interface Call {
  address: string; // Address of the contract
  name: string; // Function name on the contract (example: balanceOf)
  params?: any[]; // Function params
}

let multiContract = null;

const multicall = async (abi: any[], calls: Call[]) => {
  try {
    const web3 = getWeb3NoAccount();
    const multi = new web3.eth.Contract(MultiCallAbi as unknown as AbiItem, getMulticallAddress());
    if (!multiContract) {
      multiContract = multi;
    }
    const itf = new Interface(abi);
    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)]);
    const { returnData } = await multi.methods.aggregate(calldata).call();
    const res = returnData.map((call: any, i: any) => itf.decodeFunctionResult(calls[i].name, call));
    return res;
  } catch (error) {}
};

const web3 = getWeb3NoAccount();
const itf = new Interface(ERC_20_ABI);

export const multicallERC = async (abi: any[], calls: Call[]) => {
  try {
    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)]);
    if (!multiContract) {
      multiContract = new web3.eth.Contract(MultiCallAbi as unknown as AbiItem, getMulticallAddress());
    }
    const { returnData } = await multiContract.methods.aggregate(calldata).call();
    const res = returnData.map((call: any, i: any) => itf.decodeFunctionResult(calls[i].name, call));
    return res;
  } catch (error) {}
};

export const multicallERCPerChain = async (wbe3ByChain, calls: Call[]) => {
  try {
    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)]);
    const  multiContract = new wbe3ByChain.eth.Contract(MultiCallAbi as unknown as AbiItem, getMulticallAddress());
    const { returnData } = await multiContract.methods.aggregate(calldata).call();
    const res = returnData.map((call: any, i: any) => itf.decodeFunctionResult(calls[i].name, call));
    return res;
  } catch (error) {}
};

export default multicall;
