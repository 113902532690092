import React from "react";
import Svg from "./Svg";
import { SvgProps } from "./type";

const CopyIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg fill="#1AC486" viewBox="0 0 24 24" {...props}>
      <path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z" />
    </Svg>
  );
};

export default CopyIcon;