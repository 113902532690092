import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, Image, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import { useOnClickOutside } from '../../../../hooks/useOutside';

import { useGetLastProposalState } from '../../../../redux/hooks';
import { getIsClickToNewCompetition } from '../../../../utils/common';
import Typography from '../../../Typography';
import ItemDetailMenu from './ItemDetailMenu';

const DropdownMenu: React.FC<{ data: any; isMobile: boolean }> = memo(({ data, isMobile }) => {
  const { t } = useTranslation(['swap', 'header']);
  const [isOpen, setIsOpen] = useState(false);
  const [activeBg, setActiveBg] = useState(false);
  const [isLargerThan400] = useMediaQuery('(max-width: 400px ) and (min-width: 0px )');
  const [isSmallThan1600] = useMediaQuery('(max-width: 1600px ) and (min-width: 0px )');
  const [isSmallThan1024] = useMediaQuery('(max-width: 1024px ) and (min-width: 0px )');
  const { title, activeMenu, item, className, isBondExchange } = data ?? {};

  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const newBuyingComp = getIsClickToNewCompetition('buying');
  const newFarmingComp = getIsClickToNewCompetition('farming');
  const newReferralComp = getIsClickToNewCompetition('referral');
  const isExitLabelNewComp = newBuyingComp || newFarmingComp || newReferralComp;
  const lastProposalState = useGetLastProposalState();
  const isActiveGovernance = lastProposalState?.state;
  const isGovernance = isActiveGovernance !== 'executed' && isActiveGovernance !== 'failed';
  const path = location.pathname;

  const bgContentDetail = useColorModeValue('#fff', '#1F2835');
  const boxShadow = useColorModeValue('0px 4px 24px rgba(0, 0, 0, 0.12)', '0px 2px 36px rgba(0, 0, 0, 0.36)');

  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'absolute',
    placement: isMobile ? 'top' : 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      return target && !tooltipRef?.contains(target) && setIsOpen(false);
    };

    targetRef?.addEventListener('mouseenter', showDropdownMenu);
    targetRef?.addEventListener('mouseleave', hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu);
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen, isMobile]);

  useOnClickOutside(
    {
      current: targetRef,
    },
    () => {
      setIsOpen(false);
      setActiveBg(false);
    },
  );

  const renderItemDetail = () => {
    return (
      <ItemDetailMenu
        data={item}
        isGovernance={isGovernance}
        newReferralComp={newReferralComp}
        newBuyingComp={newBuyingComp}
      />
    );
  };

  const renderIcon = () => {
    return <Flex mb="2px" w="4px" h="4px" borderRadius="50%" bg="teal.150" />;
  };

  const renderTitleMenuDesktop = () => {
    const gray100ToGray600 = useColorModeValue('gray.600', 'gray.100');
    const imgDotDarkToLight = useColorModeValue('/img/dots-horizontal-light.svg', '/img/dots-horizontal.svg');
    const isActiveMenu = path?.includes(activeMenu) || activeMenu?.includes(path);

    return (
      <Flex
        h="60px"
        align={'center'}
        onPointerDown={() => {
          setIsOpen((s) => !s);
        }}
        className={className}
        borderBottom={title === 'referral' ? (path === activeMenu ? '1px' : '') : isActiveMenu ? '1px' : ''}
        borderColor={title === 'referral' ? (path === activeMenu ? 'teal.150' : '') : isActiveMenu ? 'teal.150' : ''}
        cursor="pointer"
        color={
          title === 'referral'
            ? path === activeMenu
              ? 'teal.150'
              : gray100ToGray600
            : path?.includes(activeMenu) || activeMenu?.includes(path)
            ? 'teal.150'
            : gray100ToGray600
        }
        _hover={{
          borderBottom: '1px',
          borderColor: 'teal.150',
        }}
        mx={title !== '' && ['', '', '', '12px', '16px', '20px']}
        fontSize={isSmallThan1600 ? '14px' : '16px'}
        fontWeight="500"
      >
        {item.length === 0 ? (
          <Link style={{ height: '60px', lineHeight: '60px' }} to={data?.href}>
            {t(title)}
          </Link>
        ) : (
          <>
            {t(title) || (
              <Image
                cursor="pointer"
                src={
                  isOpen || activeMenu?.includes(path) || path?.includes(activeMenu)
                    ? '/img/dots-horizontal-active.svg'
                    : imgDotDarkToLight
                }
                width={'24px'}
                h={'24px'}
              />
            )}
          </>
        )}
        {data?.isNew && (
          <Typography
            ml="4px"
            px="6px"
            borderRadius="5px"
            bg="rgba(26, 196, 134, 0.2)"
            color="teal.150"
            type="body1"
            w="fit-content"
            fontSize="12px"
            fontWeight={'medium'}
          >
            New
          </Typography>
        )}
        {title === 'win' && isExitLabelNewComp && renderIcon()}
        {item.length > 0 ? (
          <>
            <Flex display={!isOpen ? 'block' : 'none'}>
              {!isOpen && (
                <ChevronDownIcon
                  color={
                    isOpen || activeMenu?.includes(path) || path?.includes(activeMenu) ? 'teal.150' : gray100ToGray600
                  }
                />
              )}
            </Flex>

            {isOpen && <ChevronUpIcon color={isOpen ? 'teal.150' : gray100ToGray600} />}
          </>
        ) : null}
        {title === '' && isGovernance && renderIcon()}
      </Flex>
    );
  };

  const renderTitleMenuMobile = () => {
    const isColor = path?.includes(isBondExchange) || path?.includes(activeMenu) || activeMenu?.includes(path);
    const bgActive = useColorModeValue('gray.100', 'rgba(45, 55, 72, 0.8)');

    return (
      <Flex
        w="53px"
        h="64px"
        onPointerDown={() => {
          setIsOpen((s) => !s);
          setActiveBg(true);
        }}
        align="center"
        className={className}
        borderRadius="8px"
        fontSize="12px"
        fontWeight="400"
        justify="center"
        color={isColor ? 'teal.150' : 'gray.500'}
        bg={activeBg ? bgActive : ''}
        direction="column"
      >
        {title === 'more' && isGovernance && renderIcon()}
        {title === 'win' && isExitLabelNewComp && renderIcon()}
        <Image src={`/icons/icon_${title}_${isColor ? 'on' : 'off'}.svg`} boxSize="18px" />
        {t(title) || <Image cursor="pointer" src={'/img/dots-horizontal-active.svg'} width={'24px'} h={'24px'} />}
      </Flex>
    );
  };

  return (
    <Box h="64px" minH="64px" ref={setTargetRef}>
      {!isMobile && renderTitleMenuDesktop()}
      {isMobile && renderTitleMenuMobile()}
      {item.length > 0 && isOpen && (
        <Flex ref={setTooltipRef} {...attributes.popper} style={{ ...styles.popper }}>
          <Box
            mt="6px"
            visibility={!isOpen ? 'hidden' : 'visible'}
            zIndex="999"
            pointerEvents={!isOpen ? 'none' : 'auto'}
            bg={bgContentDetail}
            border="none"
            borderRadius="12px"
            p="12px"
            w={isLargerThan400 ? '340px' : '344px'}
            fontSize="12px"
            fontWeight={'400'}
            color="gray.500"
            mx={isSmallThan1024 ? '0px' : '10px'}
            mb={isSmallThan1024 ? '15px' : ''}
            boxShadow={boxShadow}
          >
            {renderItemDetail()}
          </Box>
        </Flex>
      )}
    </Box>
  );
});

export default DropdownMenu;
