import { createAction } from '@reduxjs/toolkit';

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('swap/selectCurrency');
export const switchCurrencies = createAction<void>('swap/switchCurrencies');
export const switchCurrenciesV2 = createAction<void>('swap/switchCurrenciesV2');
export const typeInput = createAction<{ field: Field; typedValue: string }>('swap/typeInput');
export const replaceSwapState = createAction<{
  field: Field;
  typedValue: string;
  inputCurrencyId?: string;
  outputCurrencyId?: string;
  recipient: string | null;
  gasLimit?: number;
  gasPrice?: number;
  gasLimitPanCake?: number;
  isInitSdk?: boolean;
}>('swap/replaceSwapState');
export const setRecipient = createAction<{ recipient: string | null }>('swap/setRecipient');
export const setGasLimit = createAction<{ gasLimit: number }>('swap/setGasLimit');
export const setGasPrice = createAction<{ gasPrice: number }>('swap/setGasPrice');
export const setGasLimitPanCake = createAction<{ gasLimitPanCake: number }>('swap/setGasLimitPanCake');
export const setIsInitSdk = createAction<{ isInitSdk: boolean }>('swap/setIsInitSdk');
export const setActivePercent = createAction<boolean>('swap/setActivePercent');
