import { Flex, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { ListMenuMobile } from '../../../../configs/constants/home';
import DropdownMenu from './DropdownMenu';

const ItemMenuMobile: React.FC = () => {
  const [isSmallThan1110] = useMediaQuery('(max-width: 1110px ) and (min-width: 0px )');
  const bgMenuMobile = useColorModeValue('#fff', 'linear-gradient(0deg, #1A202C, #1A202C)');
  const boxShadow = useColorModeValue('0px 4px 24px rgba(0, 0, 0, 0.12)', '0px -4px 36px rgba(0, 0, 0, 0.24)');

  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      position="fixed"
      bottom="0px"
      h="64px"
      align={'center'}
      zIndex="99999"
      display={isSmallThan1110 ? 'flex' : 'none'}
      bg={bgMenuMobile}
      px={['10px', '20px', '50px']}
      boxShadow={boxShadow}
      color="white"
    >
      {ListMenuMobile?.map((e, index) => {
        return (
          <Flex ml="6px" key={`menu-mobile-${index}`}>
            <DropdownMenu data={e} isMobile={true} />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default ItemMenuMobile;
