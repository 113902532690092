import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { getListBondBySymbols } from '../bondDetail/fetchBondDetailData';
import { fetchDataPublicBonds, fetchUserBondBalances } from './fetchBondLendingUser';

interface IBondLending {
  formData: any;
  formLocalData: any;
  focusedSelect: any;
  bondAmount: number;
  listNFTAlreadyAddCollateral: any[];
  typeOfCollateral: string;
  userData: {
    bondBalance: number;
    listNftCollateral: any[];
  };
  dataPublic: {
    availToPurchase: number;
    totalSupply: number;
    collateralAmount: number;
    totalSoldAmountClaimed: number;
    underlyingAssetStatus: number;
  };
}

const initialState: IBondLending = {
  formData: {
    selectedNFTs: [],
    totalParValue: 0,
  },
  formLocalData: {
    selectedNftsLocal: [],
    totalParValueLocal: 0,
  },
  focusedSelect: -1,
  bondAmount: 0,
  listNFTAlreadyAddCollateral: [],
  typeOfCollateral: '',
  userData: null,
  dataPublic: null,
};
export const bondLendingSlice = createSlice({
  name: 'bondLending',
  initialState,
  reducers: {
    setBondLendingFormData: (state, { payload }) => {
      const data = state;
      data.formData = { ...state.formData, ...payload };
      return data;
    },
    setFocusedSelect: (state, { payload }) => {
      const data = state;
      data.focusedSelect = payload;
      return data;
    },
    setSelectedNftLocal: (state, { payload }) => {
      state.formLocalData = payload;
      return state;
    },
    setUserDataBondLending: (
      state,
      action: PayloadAction<{
        bondAmount?: number;
        listNFTAlreadyAddCollateral?: any[];
        listNFTPending?: any[];
      }>,
    ) => {
      const data = state;
      data.bondAmount = action.payload.bondAmount;
      data.listNFTAlreadyAddCollateral = action.payload.listNFTAlreadyAddCollateral;
      return data;
    },
    setTypeCollateral: (state, action: PayloadAction<any>) => {
      const data = state;
      data.typeOfCollateral = action.payload;
      return data;
    },
    setUserDataBonds: (state, action: PayloadAction<any>) => {
      const data = state;
      data.userData = action.payload;
      return data;
    },
    setBondsDataPublic: (state, action: PayloadAction<any>) => {
      const data = state;
      data.dataPublic = action.payload;
      return data;
    },
  },
});

export const {
  setUserDataBondLending,
  setBondLendingFormData,
  setFocusedSelect,
  setSelectedNftLocal,
  setTypeCollateral,
  setUserDataBonds,
  setBondsDataPublic,
} = bondLendingSlice.actions;

export const checkUniqueBondSymbol = (symbol: string) => async (dispatch: any) => {
  if (symbol.length && symbol.length <= 6) {
    try {
      await getListBondBySymbols([symbol]);
      dispatch(setBondLendingFormData({ isDuplicateBondSymbol: true }));
    } catch {
      dispatch(setBondLendingFormData({ isDuplicateBondSymbol: false }));
    }
  }
};

export const fetchBondsUserDataAsync = (account: string, tokenAddress: string) => async (dispatch: any) => {
  if (account && tokenAddress) {
    const userData = await fetchUserBondBalances(account, tokenAddress);

    dispatch(
      setUserDataBonds({
        bondBalance: userData.newBondsBalance,
        listNftCollateral: userData.listNFTWithInfo,
      }),
    );
  }
};

export const fetchBondsDataPublic = (tokenAddress: string) => async (dispatch: any) => {
  if (tokenAddress) {
    const data = await fetchDataPublicBonds(tokenAddress);

    dispatch(
      setBondsDataPublic({
        availToPurchase: new BigNumber(data.bondSupply).minus(new BigNumber(data.totalSupply)).toFixed(),
        totalSupply: data.totalSupply,
        collateralAmount: data.collateralAmount,
        totalSoldAmountClaimed: data.totalSoldAmountClaimed,
        underlyingAssetStatus: data.underlyingAssetStatus,
      }),
    );
  }
};

export default bondLendingSlice.reducer;
