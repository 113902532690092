import { Vault } from './../redux/types';
import addresses from '../configs/constants/contracts';
import tokens from '../configs/constants/tokens';
import aTokens from '../configs/constants/lendingPoolTokens/aTokens';
import variableDebtTokens from '../configs/constants/lendingPoolTokens/variableDebtTokens';
import stableDebtTokens from '../configs/constants/lendingPoolTokens/stableDebtTokens';
import vaults from '../configs/constants/vaults';
import store from '../redux/store';
import { getSpotHouseAbi } from './abiHelper';

export const getAddress = (address: any): string => {
  const mainNetChainId = 56;
  const chainId = process.env.REACT_APP_CHAIN_ID;
  return address[chainId === '56' ? mainNetChainId : 97];
};

export const getPosiGovernanceAddress = () => {
  return getAddress(addresses.positionTokenGovernor);
};

export const getPosiTicketAddress = () => {
  return getAddress(addresses.positionTokenBuyTicket);
};

export const getPosiVaultAddress = () => {
  return getAddress(addresses.posiVault);
};

export const getPosiReferralAddress = () => {
  return getAddress(addresses.positionReferral);
};

export const getCakeAddress = () => {
  return getAddress(tokens.posi.address);
};
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChefv2);
};
export const getPosiNftAddress = () => {
  return getAddress(addresses.posiNFT);
};
export const getPosiBusdPairAddress = () => {
  return getAddress(addresses.posiBusdPair);
};
export const getGeneralNFTRewardAddress = () => {
  return getAddress(addresses.generalNFTReward);
};
export const getNewGeneralNFTRewardAddress = () => {
  return getAddress(addresses.newGeneralNFTReward);
};
export const getV3GeneralNFTRewardAddress = () => {
  return getAddress(addresses.v3GeneralNFTReward);
};
export const getMarketplaceProxyAddress = () => {
  return getAddress(addresses.marketPlaceProxy);
};
export const getPOSINFTFActoryAddress = () => {
  return getAddress(addresses.posiNFTFactory);
};
export const getPosiNFTMintProxy = () => {
  return getAddress(addresses.posiNFTMintProxy);
};
export const getPosiFactory = () => {
  return getAddress(addresses.posiNFTFactory);
};
export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall);
};
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address);
};
export const getWethAddress = () => {
  return getAddress(tokens.weth.address); // TODO: change tokens to /lendingPoolTokens/tokens
};

export const getAWethAddress = () => {
  return getAddress(aTokens.aWETH.address);
};

export const getDebtWETHAddresses = () => {
  return [
    getAddress(stableDebtTokens.stableDebtWETH.address).toLowerCase(),
    getAddress(variableDebtTokens.variableDebtWETH.address).toLowerCase(),
  ];
};

// export const getChainLinkDataFeedPriceAddress = (pair: string) => {
// 	return getAddress(addresses.chainLinkDataFeed[pair])
// }
export const getVaultAddress = (vid: number) => {
  const vaultRaw = vaults.find((obj) => obj.id === vid) as Vault;
  return getAddress(vaultRaw.vaultAddress);
};

export const getDynamicGovernanceAddress = async (): Promise<string> => {
  return new Promise((resolve) => {
    const { proposalsDetailData } = store.getState().governance;
    if (proposalsDetailData && proposalsDetailData?.governanceAddress) {
      resolve(proposalsDetailData?.governanceAddress);
    } else if (proposalsDetailData && proposalsDetailData) {
      const address = getPosiGovernanceAddress();
      resolve(address);
    } else {
      setTimeout(() => {
        return getRetry(resolve);
      }, 500);
    }
  });
};
const getRetry = (resolve: any) => {
  const { proposalsDetailData } = store.getState().governance;
  if (proposalsDetailData && proposalsDetailData?.governanceAddress) {
    resolve(proposalsDetailData?.governanceAddress);
  } else if (proposalsDetailData && proposalsDetailData) {
    const address = getPosiGovernanceAddress();
    resolve(address);
  } else {
    setTimeout(() => {
      return getRetry(resolve);
    }, 500);
  }
};

export const useGetPositionHouseAddressAndAbi = async (
  pairManager: string,
): Promise<{
  spotHouseAddress: string;
  spotHouseABI: any;
}> => {
  return new Promise((resolve) => {
    const { listOfPair } = store.getState().bondExchange;
    if (listOfPair && listOfPair.length > 0) {
      const existPair = listOfPair.find((obj) => obj.spot_manager_address === pairManager);
      resolve({
        spotHouseAddress: existPair?.spot_house_address,
        spotHouseABI: getSpotHouseAbi(existPair?.spot_house_address),
      });
    } else {
      setTimeout(() => {
        return useGetSpotHouseAddress(pairManager, resolve);
      }, 500);
    }
  });
};

export const useGetSpotHouseAddress = async (pairManager: string, resolve) => {
  const { listOfPair } = store.getState().bondExchange;
  if (listOfPair && listOfPair.length > 0) {
    const existPair = listOfPair.find((obj) => obj.spot_manager_address === pairManager);
    resolve({
      spotHouseAddress: existPair?.spot_house_address,
      spotHouseABI: getSpotHouseAbi(existPair?.spot_house_address),
    });
  } else {
    setTimeout(() => {
      return useGetSpotHouseAddress(pairManager, resolve);
    }, 500);
  }
};

export const getMasterchefCakeAddress = () => {
  return getAddress(addresses.masterchefCake);
};

export const useGetLiquidityNftManager = async (): Promise<string> => {
  return new Promise((resolve) => {
    const { liquidityNftManagerAddress } = store.getState().bondExchange;
    if (liquidityNftManagerAddress && liquidityNftManagerAddress !== '') {
      resolve(liquidityNftManagerAddress);
    } else {
      return retryGetLiquidityNftManager(resolve);
    }
  });
};

export const retryGetLiquidityNftManager = async (resolve) => {
  const { liquidityNftManagerAddress } = store.getState().bondExchange;
  if (liquidityNftManagerAddress && liquidityNftManagerAddress !== '') {
    resolve(liquidityNftManagerAddress);
  } else {
    setTimeout(() => {
      return retryGetLiquidityNftManager(resolve);
    }, 500);
  }
};

export const getLotteryAddress = () => {
  return getAddress(addresses.lottery);
};

export const getPosiNftBirthdayAddress = () => {
  return getAddress(addresses.posiNftBirthdayMint);
};

export const getVrfCoordinatorAddress = () => {
  return getAddress(addresses.vrfCoordinator);
};

export const getBridgeAssetContractAddress = () => {
  return getAddress(addresses.bridgeAssetBSC);
};
