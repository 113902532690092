import { Text } from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import React, { memo } from 'react';
import { SUFFIX_VALUE } from '../../configs';
import PriceComponent from '../PriceComponent';

const CardExchange = memo(
  ({
    color,
    priceOfCoin,
    value,
    ...rest
  }: {
    color?: string;
    priceOfCoin: number | string;
    value: string | number;
    [key: string]: any;
  }) => {
    const { account } = useWeb3React();
    const amount = 0;

    if (!account) {
      return (
        <Text fontSize="14px" lineHeight="1.1" color="cyan.400">
          ~${amount.toFixed(3)}
        </Text>
      );
    }

    return (
      <PriceComponent
        styles={{ color: color, fontSize: '14px', display: 'inline', ...rest }}
        priceOfCoin={Number(priceOfCoin)}
        value={Number(value) ?? 0}
        decimals={rest.formatDecimals || SUFFIX_VALUE}
        prefix="~$"
      />
    );
  },
  (prev, next) => prev.priceOfCoin === next.priceOfCoin && next.value === prev.value,
);

export default CardExchange;
