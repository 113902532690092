import { useEffect, useMemo, useState } from 'react';
import useRefresh from '../../hooks/useRefresh';
import { getBalanceNumber } from '../../utils/formatBalance';
import multicall from '../../utils/multicall';
import erc20Abi from '../../configs/abi/erc20.json';
import moment from 'moment';
import useWeb3 from '../../hooks/useWeb3';
import { getBondContract } from '../../utils/contractHelpers';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { setIsLoadingDataChart } from '.';
import { useGetCurrentBondDetail } from '../../hooks/useBondState';
import { getBondAbiByType } from '../../utils/common';
import bondLaunchpadAbi from '../../configs/abi/bondLaunchpad.json';

export const useAvailToPurchase = (bondAddress, issuePrice) => {
  const { fastRefresh } = useRefresh();
  const [avail, setAvail] = useState({ availToPurchase: 0, lastPrice: issuePrice });
  const web3 = useWeb3();
  const bondDetail = useGetCurrentBondDetail();
  const getAvailToPurchase = async () => {
    try {
      const bondAbi = getBondAbiByType(bondDetail?.type || 'normal');
      const contract = getBondContract(bondAddress, web3);
      const [[bondSupply], [totalSupply]] = await multicall(bondAbi, [
        {
          address: bondAddress,
          name: 'bondSupply',
        },
        {
          address: bondAddress,
          name: 'totalSupply',
        },
      ]);
      const _bondSupply = getBalanceNumber(Number(bondSupply));
      const _totalSupply = getBalanceNumber(Number(totalSupply));
      try {
        const range0 = await contract.methods.bondPriceRange(0).call();
        if (range0) {
          let range = range0;
          let index = 0;
          let max = getBalanceNumber(Number(range[1]));
          let price = getBalanceNumber(Number(range[2]));
          while (max < _bondSupply) {
            if (_totalSupply < max) {
              setAvail({ availToPurchase: max - _totalSupply, lastPrice: price });
              max = _bondSupply;
              return;
            }
            range = await contract.methods.bondPriceRange(++index).call();
            max = getBalanceNumber(Number(range[1]));
            price = getBalanceNumber(Number(range[2]));
          }
          setAvail({ availToPurchase: _bondSupply - _totalSupply, lastPrice: price });
        } else {
          setAvail({ availToPurchase: _bondSupply - _totalSupply, lastPrice: issuePrice });
        }
      } catch (e) {
        setAvail({ availToPurchase: _bondSupply - _totalSupply, lastPrice: issuePrice });
      }
    } catch (e) {
      setAvail({ availToPurchase: 0, lastPrice: issuePrice });
    }
  };
  useEffect(() => {
    getAvailToPurchase();
  }, [bondAddress, fastRefresh, bondDetail]);
  return avail;
};

export const useBalance = (address, account) => {
  const [balance, setBalance] = useState(0);
  const { fastRefresh } = useRefresh();

  const getBalance = async () => {
    try {
      if (account) {
        const [[_balance]] = await multicall(erc20Abi, [
          {
            address,
            name: 'balanceOf',
            params: [account],
          },
        ]);
        setBalance(getBalanceNumber(Number(_balance)));
      } else setBalance(0);
    } catch (e) { }
  };

  useEffect(() => {
    getBalance();
  }, [address, account, fastRefresh]);
  return balance;
};

export const useTotalAmountCommitted = (address) => {
  const [totalCommitted, setTotalCommitted] = useState(0);
  const { fastRefresh } = useRefresh();

  const getTotalAmountCommitted = async () => {
    try {
      const [[_totalAmountCommitted]] = await multicall(bondLaunchpadAbi, [
        {
          address,
          name: 'totalAmountCommitted',
        },
      ]);
      setTotalCommitted(getBalanceNumber(Number(_totalAmountCommitted)));
    } catch (e) { }
  };

  useEffect(() => {
    getTotalAmountCommitted();
  }, [address, fastRefresh]);
  return totalCommitted;
};

export const useTotalUserCommitted = (address) => {
  const [totalUserCommitted, setTotalUserCommitted] = useState(0);
  const { fastRefresh } = useRefresh();

  const getTotalUserCommitted = async () => {
    try {
      const [[_totalUserCommitted]] = await multicall(bondLaunchpadAbi, [
        {
          address,
          name: 'totalUserCommitted',
        },
      ]);
      setTotalUserCommitted(Number(_totalUserCommitted));
    } catch (e) { }
  };

  useEffect(() => {
    getTotalUserCommitted();
  }, [address, fastRefresh]);
  return totalUserCommitted;
};

export const useBondTimeLock = (address, account) => {
  const [timeLock, setTimeLock] = useState(moment('01-01-2022', 'DD-MM-YYYY').valueOf());
  const { fastRefresh } = useRefresh();
  const bondDetail = useGetCurrentBondDetail();

  const getTimeLock = async () => {
    try {
      const bondAbi = getBondAbiByType(bondDetail?.type || 'normal');
      const [[res]] = await multicall(bondAbi, [
        {
          address,
          name: 'deadline',
        },
      ]);
      setTimeLock(Number(res) * 1000);
    } catch (e) { }
  };

  useEffect(() => {
    getTimeLock();
  }, [address, account, fastRefresh, bondDetail]);
  return useMemo(() => timeLock, [timeLock]);
};

export const useBondPurchasable = (bondAddress, account, isTimeLock) => {
  const [isPurchasable, setIsPurchasable] = useState(false);
  const web3 = useWeb3();
  const getIsPurchasable = async () => {
    try {
      const contract = getBondContract(bondAddress, web3);
      const res = await contract.methods.isPurchasable(account).call();
      setIsPurchasable(res);
    } catch (e) { }
  };

  useEffect(() => {
    getIsPurchasable();
  }, [account, isTimeLock]);
  return isPurchasable;
};

export const getBondPrices = async (bondContract, i) => {
  const res = await bondContract.methods.bondPriceRange(i).call();
  const min = getBalanceNumber(new BigNumber(res.min), 18);
  const max = getBalanceNumber(new BigNumber(res.max), 18);
  const price = getBalanceNumber(new BigNumber(res.price), 18);

  return {
    min,
    max,
    price,
  };
};

export const useGetRangeBondPrices = (bondAddress) => {
  let i = 0;
  const [rangePrice, setRangePrice] = useState([]);
  const web3 = useWeb3();
  const contract = getBondContract(bondAddress, web3);
  const dispatch = useDispatch();
  const fetchRangePrice = async () => {
    let newState = [];
    try {
      while (i < 50) {
        const rangeI = await getBondPrices(contract, i);
        if (rangeI.price) {
          newState = newState.concat(rangeI);
          setRangePrice(newState);
          i++;
        } else {
          break;
        }
      }
      return newState;
    } catch (error) {
      dispatch(setIsLoadingDataChart());
    }
  };

  useEffect(() => {
    if (bondAddress) {
      fetchRangePrice();
    }
  }, [bondAddress]);
  return rangePrice;
};
