/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { formatNumberWithCompactShort, formatNumberWithNumeral } from '../../utils/common';
import {
  fetchStatisticsData,
  getListFile,
  getMarketTrendFutures,
  getMarketTrendNewList,
  getMarketTrendSpot,
} from './fetchData';

export enum MarketTrendType {
  SPOT = 'SPOT',
  FUTURES = 'FUTURES',
  NEW_LIST = 'NEW_LIST',
}

const defaultPositionFeatures = [
  {
    id: 1,
    title: 'futures',
    description: 'futures',
    imgLight: '/images/homepage/feat-futures-light.svg',
    imgSrc: '/images/homepage/feat-futures-dark.svg',
    link: 'https://futures.position.exchange/BTC/BUSD',
  },
  {
    id: 2,
    title: 'nft_marketplace',
    description: 'nft_marketplace',
    imgLight: '/images/homepage/feat-marketplace-light.svg',
    imgSrc: '/images/homepage/feat-marketplace-dark.svg',
    link: 'https://position.exchange/nft/marketplace/auction',
  },
  {
    id: 3,
    title: 'bonds',
    description: 'bonds',
    imgLight: '/images/homepage/feat-bonds-light.svg',
    imgSrc: '/images/homepage/feat-bonds-dark.svg',
    link: 'https://position.exchange/bonds',
  },
  {
    id: 4,
    title: 'swap',
    description: 'swap',
    imgLight: '/images/homepage/feat-swap-light.svg',
    imgSrc: '/images/homepage/feat-swap-dark.svg',
    link: 'https://position.exchange/swap',
  },
  {
    id: 5,
    title: 'earning',
    description: 'earning',
    imgLight: '/images/homepage/feat-earning-light.svg',
    imgSrc: '/images/homepage/feat-earning-dark.svg',
    link: 'https://position.exchange/farming',
  },
  {
    id: 6,
    title: 'referral',
    description: 'referral',
    imgLight: '/images/homepage/feat-referral-light.svg',
    imgSrc: '/images/homepage/feat-referral-dark.svg',
    link: 'https://position.exchange/referral',
  },
];

interface ItemListFile {
  file_name: string;
  file_id: string;
  public_url: string;
  href: string;
  ordinal_number: number;
}

export interface IHomeState {
  marketTrendType: string;
  marketTrendData: any;
  positionFeatures: any[];
  marketTrendDataLoading: boolean;
  listFile: ItemListFile[];
  statistics: {
    max_supply: string;
    circulating_supply: string;
    total_supply: string;
    total_value_locked: string;
    total_value_burned: string;
    total_rfi_distributed: string;
    total_pending_reward: string;
    market_cap: string;
    number_of_holders: string;
    number_of_twitter_followers: string;
  };
}

const initialState: IHomeState = {
  statistics: null,
  marketTrendType: MarketTrendType.SPOT,
  marketTrendData: [],
  marketTrendDataLoading: true,
  positionFeatures: defaultPositionFeatures,
  listFile: [],
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setDataStatistics: (state, action: PayloadAction<any>) => {
      state.statistics = action.payload;
      return state;
    },
    setMarketTrendType: (state, action: PayloadAction<string>) => {
      state.marketTrendType = action.payload;
    },
    setMarketTrendDataLoading: (state, action: PayloadAction<boolean>) => {
      state.marketTrendDataLoading = action.payload;
    },
    setMarketTrendData: (state, action: PayloadAction<any>) => {
      state.marketTrendData = action.payload;
    },
    setListFileData: (state, action: PayloadAction<any>) => {
      state.listFile = action.payload;
    },
  },
});

export default homeSlice.reducer;

// Actions
export const { setListFileData, setDataStatistics, setMarketTrendType, setMarketTrendDataLoading, setMarketTrendData } =
  homeSlice.actions;

// Thunks
export const fetchStatisticsDataAsync = (account: string, token: any) => async (dispatch: any) => {
  const data = await fetchStatisticsData(account, token);
  dispatch(setDataStatistics(data));
};

export const fetchFileServiceData = (component_name: string) => async (dispatch: any) => {
  const data = await getListFile(component_name);
  dispatch(setListFileData(data?.files));
};

export const fetchMarketTrendDataAsync = (firstLoading) => async (dispatch: any, getState: any) => {
  const {
    home: { marketTrendType },
  } = getState();
  if (firstLoading) dispatch(setMarketTrendDataLoading(true));
  switch (marketTrendType) {
    case MarketTrendType.SPOT: {
      const {
        data: { pairs },
      } = await getMarketTrendSpot();
      const resData = pairs.map((raw, index) => ({
        id: index,
        base: raw.base?.symbol,
        quote: raw.quote?.symbol,
        image: raw?.base?.logo_url,
        lastPrice: `${formatNumberWithNumeral(raw.price)}`,
        change24h: formatNumberWithNumeral(raw.change_24h, 2),
        volume: `$${formatNumberWithCompactShort(raw.quote_volume_24h)}`,
        marketCap: `$${formatNumberWithCompactShort(raw.market_cap)}`,
        type: 'spot',
        link: `https://position.exchange/trade/${raw.base?.symbol}/${raw.quote?.symbol}`,
        route: `/trade/${raw.base?.symbol}/${raw.quote?.symbol}`,
      }));
      dispatch(setMarketTrendData(resData));
      break;
    }
    case MarketTrendType.FUTURES: {
      const { data } = await getMarketTrendFutures();
      const resData = data.map((raw, index) => ({
        id: index,
        base: isEqual(raw.collateral, 'coin') ? raw.quote_symbol : raw.base_symbol,
        quote: isEqual(raw.collateral, 'coin') ? raw.base_symbol : raw.quote_symbol,
        image: raw.logo_url,
        lastPrice: `${formatNumberWithNumeral(raw.last_price)}`,
        change24h: formatNumberWithNumeral(raw.price_changed_percent, 2),
        volume: `$${formatNumberWithCompactShort(raw.quote_volume)}`,
        type: 'futures',
        marketCap: '',
        link: isEqual(raw.collateral, 'coin')
          ? `https://futures.position.exchange/COIN-M/${raw.quote_symbol}/${raw.base_symbol}`
          : `https://futures.position.exchange/USD-M/${raw.base_symbol}/${raw.quote_symbol}`,
      }));
      dispatch(setMarketTrendData(resData));
      break;
    }
    case MarketTrendType.NEW_LIST: {
      const {
        data: { pairs },
      } = await getMarketTrendNewList();
      const resData = pairs.map((raw, index) => ({
        id: index,
        base: raw.base?.symbol,
        quote: raw.quote?.symbol,
        image: raw?.base?.logo_url,
        lastPrice: `${formatNumberWithNumeral(raw.price)}`,
        change24h: formatNumberWithNumeral(raw.change_24h, 2),
        volume: `$${formatNumberWithCompactShort(raw.quote_volume_24h)}`,
        marketCap: `$${formatNumberWithCompactShort(raw.market_cap)}`,
        type: 'new_list',
        link: `https://position.exchange/trade/${raw.base?.symbol}/${raw.quote?.symbol}`,
        route: `/trade/${raw.base?.symbol}/${raw.quote?.symbol}`,
      }));
      dispatch(setMarketTrendData(resData));
      break;
    }
  }
  dispatch(setMarketTrendDataLoading(false));
};
