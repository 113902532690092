import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, last, max, unionBy } from 'lodash';
import {
  getListAsk,
  getListBid,
  getListWithTotalSize,
  getOrderBookRowCount,
  sortListAsk,
  sortListBid,
} from '../../utils/orderBook';
import {
  fetchPublicDataPoolsExchangeService,
  fetchUserDataPoolsExchange,
  getAprOfListPoolsExchange,
  getDataOrderBook,
  getListMarketTradeFromApi,
  getListOrder,
  getListPairInSpotExchange,
  getPairsDetail,
  getTransactionHistoryList,
} from './services';
import MarketTrade from '../../utils/marketTrade';
import ListPair from '../../utils/listPairClass';
import { BondExchangeState, LiquidityItem, OpenOrder, PairExchange, PriceSide, TypeOfPairExchange } from '../types';
import {
  checkAmountNumber,
  deleteUndefinedOrNullOrEmptyStringObject,
  getCalculateAmount,
  getCalculatePercent,
  getParamsFromPath,
  serialize,
} from '../../utils/common';
import { TypeOfInputConfig } from '../../configs/constants/bondExchange';
import SocketClientIO from '../../utils/socketIOClient';
import { AppState } from '../store';
import BigNumber from 'bignumber.js';
import poolsSpotExchange from '../../configs/poolsSpotExchange';
import { calculateFee, getUserFee } from '../../utils/trade/tradeHelper';
import { estimateBaseAmountFromObByQuote, estimateQuoteAmountFromOB } from '@positionex/swap';
import { getListBondByAddresses } from '../bondDetail/fetchBondDetailData';

const initialState: BondExchangeState = {
  currentPair: null,
  currentBond: null,
  currentPrice: {
    price: 0,
    type: PriceSide.BALANCE,
  },
  tradeType: 'buy',
  orderType: 'market',
  price: '',
  quantityBase: '',
  quantityQuote: '',
  orderBook: {
    data: [[], []],
    priceInterval: 0,
    maxSize: 0,
    totalRows: 0,
    symbol: '',
  },
  orderBookTemp: {
    data: [],
    maxSize: 0,
    totalRows: 0,
    priceInterval: 0,
    symbol: '',
  },
  openOrders: [],
  myLiquidity: [],
  openPositionManagers: [],
  hideOtherSymbols: false,
  marketTradeList: [],
  quantitySL: '',
  quantityTP: '',
  percentEarned: '',
  percentLoss: '',
  listOfPair: [],
  spotHouseAddress: '',
  spotFundingAddress: '',
  exchangeLiquidityPoolAddress: '',
  newExchangeLiquidityPoolAddress: '',
  liquidityNftManagerAddress: '',
  balance: {
    base: 0,
    quote: 0,
  },
  listOrderHistory: null,
  listTradeHistory: null,
  pairDetailData: null,
  balanceClaim: {
    base: 0,
    quote: 0,
  },
  filterPairSymbol: '',
  filterSide: '',
  filterType: '',
  filterTime: {
    startTime: 0,
    endTime: 0,
  },
  poolsExchange: poolsSpotExchange,
  feeRatio: {
    // TODO get from server
    0: 0.002,
    1: 0.002,
    // n
  },
  transactionHistoryList: null,
  hidenOtherLiquidity: true,
};
export const bondExchangeSlice = createSlice({
  name: 'bondExchange',
  initialState,
  reducers: {
    setCurrentPair: (state, action: PayloadAction<PairExchange>) => {
      const data = state;
      data.currentPair = action.payload;
      return data;
    },
    setTradeType: (state, action: PayloadAction<string>) => {
      const data = state;
      data.tradeType = action.payload;
      data.quantitySL =
        Number(
          action.payload === 'buy'
            ? getCalculatePercent(data.price, data.percentLoss, TypeOfInputConfig.LOSS)
            : getCalculatePercent(data.price, data.percentLoss, TypeOfInputConfig.GAIN),
        ).toFixed(data?.currentPair?.quote_asset_precision) || '';
      data.quantityTP =
        Number(
          data.tradeType === 'buy'
            ? getCalculatePercent(data.price, data.percentEarned, TypeOfInputConfig.GAIN)
            : getCalculatePercent(data.price, data.percentEarned, TypeOfInputConfig.LOSS),
        ).toFixed(data?.currentPair?.quote_asset_precision) || '';
      return data;
    },
    setOrderType: (state, action: PayloadAction<string>) => {
      const data = state;
      const price = data?.currentPrice?.price.toString();
      data.price = price;
      data.quantityQuote =
        new BigNumber(getCalculateAmount(data.quantityBase, price, true)).toFixed(
          data?.currentPair?.quote_asset_precision,
        ) || '0';
      data.orderType = action.payload;
      return data;
    },
    setPrice: (state, action: PayloadAction<string>) => {
      const data = state;
      data.price = action.payload;
      data.quantityQuote =
        Number(getCalculateAmount(data.quantityBase, action.payload, true)).toFixed(
          data?.currentPair?.quote_asset_precision,
        ) || '0';
      data.quantitySL =
        Number(getCalculatePercent(action.payload, data.percentLoss, TypeOfInputConfig.LOSS)).toFixed(
          data?.currentPair?.quote_asset_precision,
        ) || '0';
      data.quantityTP =
        Number(getCalculatePercent(action.payload, data.percentEarned, TypeOfInputConfig.GAIN)).toFixed(
          data?.currentPair?.quote_asset_precision,
        ) || '0';
      return data;
    },
    setGain: (state, action: PayloadAction<string>) => {
      const data = state;
      data.percentEarned = action.payload;
      data.quantityTP =
        Number(
          data.tradeType === 'buy'
            ? getCalculatePercent(data.price, action.payload, TypeOfInputConfig.GAIN)
            : getCalculatePercent(data.price, action.payload, TypeOfInputConfig.LOSS),
        ).toFixed(data?.currentPair?.quote_asset_precision) || '0';
      return data;
    },
    setLoss: (state, action: PayloadAction<string>) => {
      const data = state;
      data.percentLoss = action.payload;
      data.quantitySL =
        Number(
          data.tradeType === 'buy'
            ? getCalculatePercent(data.price, action.payload, TypeOfInputConfig.LOSS)
            : getCalculatePercent(data.price, action.payload, TypeOfInputConfig.GAIN),
        ).toFixed(data?.currentPair?.quote_asset_precision) || '0';
      return data;
    },
    setQuantityBaseState: (state, action: PayloadAction<{ base: number | string; quote: number }>) => {
      const data = state;
      const { base, quote } = action.payload;
      data.quantityBase = base;
      data.quantityQuote = checkAmountNumber(quote, data?.currentPair?.quote_asset_precision);
      return data;
    },
    setQuantityQuoteState: (state, action: PayloadAction<{ base: number | string; quote: number }>) => {
      const data = state;
      const payload = action.payload;
      data.quantityQuote = payload.quote;
      data.quantityBase = checkAmountNumber(payload.base, data?.currentPair?.base_asset_precision);
      return data;
    },
    setQuantityTP: (state, action: PayloadAction<string>) => {
      const data = state;
      data.quantityTP = action.payload;
      if (action.payload && data.price) {
        data.percentEarned = Number(
          data.tradeType === 'buy'
            ? getCalculatePercent(action.payload, data.price, TypeOfInputConfig.QUANTITY_TP)
            : getCalculatePercent(data.price, action.payload, TypeOfInputConfig.QUANTITY_SL),
        ).toFixed(data?.currentPair?.quote_asset_precision);
      }
      return data;
    },
    setQuantitySL: (state, action: PayloadAction<string>) => {
      const data = state;
      data.quantitySL = action.payload;
      if (action.payload && data.price) {
        data.percentLoss = Number(
          data.tradeType === 'buy'
            ? getCalculatePercent(data.price, action.payload, TypeOfInputConfig.QUANTITY_SL)
            : getCalculatePercent(action.payload, data.price, TypeOfInputConfig.QUANTITY_TP),
        ).toFixed(data?.currentPair?.quote_asset_precision);
      }
      return data;
    },
    concatMarketTradeList: (state, action: PayloadAction<any[]>) => {
      const data = state;
      data.marketTradeList = action.payload.concat(data.marketTradeList);
      data.marketTradeList = data.marketTradeList.slice(0, 50);
      return data;
    },
    setListPair: (state, action: PayloadAction<PairExchange[]>) => {
      const data = state;
      data.listOfPair = action.payload;
    },
    setFilterPairSymbol: (state, action: PayloadAction<any>) => {
      const data = state;
      data.filterPairSymbol = action.payload;
      return data;
    },
    setFilterSide: (state, action: PayloadAction<any>) => {
      const data = state;
      data.filterSide = action.payload;
      return data;
    },
    updateSymbolOrderBook: (state, action: PayloadAction<string>) => {
      const data = state;
      data.orderBookTemp.symbol = action.payload;
      return data;
    },
    setFilterType: (state, action: PayloadAction<any>) => {
      const data = state;
      data.filterType = action.payload;
      return data;
    },
    setFilterTime: (state, action: PayloadAction<any>) => {
      const data = state;
      data.filterTime = action.payload;
      return data;
    },
    setSpotAddress: (
      state,
      action: PayloadAction<{
        houseAddress: string;
        fundingAddress: string;
        exchangeLiquidityPoolAddress: string;
        liquidityNftManagerAddress: string;
        newExchangeLiquidityPoolAddress: string;
      }>,
    ) => {
      const data = state;
      data.spotHouseAddress = action.payload.houseAddress;
      data.spotFundingAddress = action.payload.fundingAddress;
      data.exchangeLiquidityPoolAddress = action.payload.exchangeLiquidityPoolAddress;
      data.newExchangeLiquidityPoolAddress = action.payload.newExchangeLiquidityPoolAddress;
      data.liquidityNftManagerAddress = action.payload.liquidityNftManagerAddress;
      return data;
    },
    setBalanceToPair: (state, action: PayloadAction<any>) => {
      const data = state;
      data.balance = action.payload;
    },
    setBalanceClaim: (state, action: PayloadAction<any>) => {
      const data = state;
      data.balanceClaim = action.payload;
    },
    setOrderBookTempData: (state, action: PayloadAction<any[]>) => {
      state.orderBookTemp.data = action.payload;
      state.orderBookTemp.totalRows = getOrderBookRowCount(action.payload[0].length, action.payload[1].length);
    },
    updateOrderBookDataFromSocket: (state) => {
      if (!isEqual(state.orderBook.data, state.orderBookTemp.data)) {
        state.orderBook.data = state.orderBookTemp.data;
        state.orderBook.totalRows = state.orderBookTemp.totalRows;
        state.orderBook.maxSize = state.orderBookTemp.maxSize;
      }
    },
    setOrderBookPriceInterval: (state, action: PayloadAction<number>) => {
      state.orderBook.priceInterval = action.payload;
    },
    setOrderBookMaxSize: (state, action: PayloadAction<number>) => {
      state.orderBookTemp.maxSize = action.payload;
    },
    setListOrderHistory: (state, action: PayloadAction<any>) => {
      const data = state;
      data.listOrderHistory = action.payload;
      return data;
    },
    setListTradeHistory: (state, action: PayloadAction<any>) => {
      const data = state;
      data.listTradeHistory = action.payload;
      return data;
    },
    setPairDetailData: (state, action: PayloadAction<any>) => {
      const data = state;
      data.pairDetailData = action.payload;
      return data;
    },
    setOpenOrders: (state, action: PayloadAction<OpenOrder[]>) => {
      state.openOrders = action.payload;
    },
    setMyLiquidity: (state, action: PayloadAction<LiquidityItem[]>) => {
      state.myLiquidity = action.payload;
    },
    setOpenPositionManagers: (state, action: PayloadAction<Array<string>>) => {
      state.openPositionManagers = action.payload;
    },
    setHideOtherSymbols: (state, action: PayloadAction<boolean>) => {
      state.hideOtherSymbols = action.payload;
    },
    setCurrentPrice: (state, action: PayloadAction<number>) => {
      const side =
        action.payload > state.currentPrice.price
          ? PriceSide.UP
          : action.payload < state.currentPrice.price
          ? PriceSide.DOWN
          : PriceSide.BALANCE;
      state.currentPrice = {
        price: action.payload,
        type: side,
      };
    },
    setStakedAmounts: (state, action: PayloadAction<{ [key: number]: number }>) => {
      const stakedAmounts = action.payload;

      const newList = state.poolsExchange.map(({ pid, userData, ...others }) => ({
        pid,
        ...others,
        userData: {
          ...userData,
          stakedBalance: stakedAmounts[pid],
        },
      }));
      state.poolsExchange = newList;
    },
    setPublicPoolExchangeData: (state, action) => {
      const listData = action.payload;
      const newList = state?.poolsExchange.map((pool, index) => ({
        ...pool,
        ...listData[index],
        userData: {
          ...pool.userData,
        },
      }));
      state.poolsExchange = newList;
    },
    setPoolExchangeAprData: (state, action) => {
      const listData = action.payload;
      const newList = state?.poolsExchange.map((pool, index) => ({
        ...pool,
        apr: listData[index].apr,
        aprReward: listData[index].aprReward,
      }));
      state.poolsExchange = newList;
    },
    setUserDataPoolExchange: (state, action) => {
      const listData = action.payload;
      const newList = state?.poolsExchange.map((pool, index) => ({
        ...pool,
        userData: {
          ...pool.userData,
          ...listData[index],
        },
      }));
      state.poolsExchange = newList;
    },
    setTransactionHistory: (state, action: PayloadAction<any>) => {
      state.transactionHistoryList = action.payload;
      return state;
    },
    setHiddenOtherLiquidity: (state, action: PayloadAction<any>) => {
      state.hidenOtherLiquidity = action.payload;
      return state;
    },
    setCurrentBond: (state, action) => {
      state.currentBond = action.payload;
      return state;
    },
  },
});

export const {
  setCurrentPair,
  setCurrentBond,
  setQuantityTP,
  setQuantitySL,
  setTradeType,
  setPrice,
  setOrderType,
  setQuantityBaseState,
  setQuantityQuoteState,
  setOrderBookTempData,
  setOrderBookPriceInterval,
  setOrderBookMaxSize,
  updateOrderBookDataFromSocket,
  concatMarketTradeList,
  setListPair,
  setSpotAddress,
  setBalanceToPair,
  setListOrderHistory,
  setPairDetailData,
  setBalanceClaim,
  setOpenOrders,
  setMyLiquidity,
  setOpenPositionManagers,
  setHideOtherSymbols,
  setGain,
  setLoss,
  setListTradeHistory,
  setFilterPairSymbol,
  setFilterSide,
  setFilterType,
  setFilterTime,
  setCurrentPrice,
  setStakedAmounts,
  setUserDataPoolExchange,
  setPublicPoolExchangeData,
  setPoolExchangeAprData,
  setTransactionHistory,
  setHiddenOtherLiquidity,
  updateSymbolOrderBook,
} = bondExchangeSlice.actions;

export const setQuantityBase = (baseQuantity: string) => (dispatch, getState) => {
  const { bondExchange } = getState();
  const { orderBook, tradeType, orderType, price, feeRatio } = bondExchange;
  if (baseQuantity) {
    const quoteAmount = estimateQuoteAmountFromOB(
      Number(baseQuantity),
      tradeType === 'buy',
      orderBook.data,
      orderType === 'limit' && price,
    );
    const setData = calculateFee(
      {
        base: baseQuantity,
        quote: quoteAmount,
        isBuy: tradeType === 'buy',
      },
      getUserFee(feeRatio),
    );

    dispatch(setQuantityBaseState(setData));
  } else {
    dispatch(
      setQuantityQuoteState({
        base: 0,
        quote: 0,
      }),
    );
  }
};

export const setQuantityQuote = (quoteAmount: string | number) => (dispatch, getState) => {
  const { bondExchange } = getState();
  quoteAmount = Number(quoteAmount);
  const { orderBook, tradeType, orderType, price, feeRatio } = bondExchange;
  let baseAmount;
  if (quoteAmount) {
    if (orderType == 'market') {
      baseAmount = estimateBaseAmountFromObByQuote(quoteAmount, tradeType == 'buy', orderBook.data);
    } else {
      // TODO calculate from estimateBaseAmountFromObByQuote to cover over price limit buy/sell
      baseAmount = quoteAmount / price;
    }
    baseAmount = baseAmount * (1 - getUserFee(feeRatio));
    dispatch(
      setQuantityQuoteState({
        base: baseAmount,
        quote: quoteAmount,
      }),
    );
  } else {
    dispatch(
      setQuantityQuoteState({
        base: 0,
        quote: 0,
      }),
    );
  }
};

export const setPairExchange = (pair: PairExchange) => (dispatch: any) => {
  if (!window.marketTrade) {
    window.marketTrade = new MarketTrade(pair?.spot_manager_address);
  }
  dispatch(setCurrentBond(null));
  dispatch(setCurrentPair(pair));
  dispatch(setCurrentPrice(Number(pair?.price)));
  dispatch(getListMarketTrade(pair?.spot_manager_address));
  dispatch(setPrice(pair?.price.toString()));
  dispatch(setQuantityQuote(''));
  dispatch(setQuantityBase(''));
  dispatch(setQuantityTP(''));
  dispatch(setQuantitySL(''));
  dispatch(fetchPairDetailData(pair?.spot_manager_address));
  if (!!pair && pair?.type === TypeOfPairExchange.BOND) {
    dispatch(getBondDetailByAddress(pair?.base?.contract_address.toLowerCase()));
  }
};

const getBondDetailByAddress = (bondAddress: string) => async (dispatch: any) => {
  try {
    const bondDetailData = await getListBondByAddresses([bondAddress]).then((res) => res[0] || null);
    if (!!bondDetailData && Object.keys(bondDetailData).length > 0) {
      dispatch(setCurrentBond(bondDetailData));
    }
  } catch (e) {}
};

/**
 *
 * @param baseSymbol baseSymbol or pair manager
 * @param quoteSymbol quoteSymbol
 * @description find pair in server and set list or redirect to 404 page
 */
const findPairInServer = async (baseSymbol: any, quoteSymbol: any, history: any, dispatch: any, getState: any) => {
  let pathName = 'bond-exchange';
  if (window.location.pathname.includes('trade')) {
    pathName = 'trade';
  }

  // find in list not public
  const param = getParamsFromPath(baseSymbol, quoteSymbol);
  if (!param) {
    if (pathName === 'bond-exchange' || pathName === 'trade') {
      history.push(`/404`);
    }
  }
  deleteUndefinedOrNullOrEmptyStringObject(param);
  const queryString = serialize(param);
  const data = await getListPairInSpotExchange(queryString);
  const { pairs: dataPairExchange } = data;
  if (dataPairExchange && dataPairExchange.length > 0) {
    const choosePair = dataPairExchange.find((a) => {
      return (
        (a.base.symbol.trim().toLowerCase() === baseSymbol.trim().toLowerCase() &&
          a.quote.symbol.trim().toLowerCase() === quoteSymbol.trim().toLowerCase()) ||
        (a.base.contract_address.trim().toLowerCase() === baseSymbol.trim().toLowerCase() &&
          a.quote.contract_address.trim().toLowerCase() === quoteSymbol.trim().toLowerCase()) ||
        a.spot_manager_address.trim().toLowerCase() === baseSymbol.trim().toLowerCase()
      );
    });
    if (!choosePair) {
      if (pathName === 'bond-exchange' || pathName === 'trade') {
        history.push(`/404`);
      }
    }
    dispatch(setPairExchange(choosePair));

    const currentList = getState().bondExchange.listOfPair;
    const currentPairInList = currentList.find((a) => a.spot_manager_address === choosePair.spot_manager_address);
    if (!currentPairInList) {
      const newList = [...currentList, { ...choosePair, isHidden: true }];
      dispatch(setListPair(newList));
      window.listPairClass.initList(newList);
    }
  } else {
    if (pathName === 'bond-exchange' || pathName === 'trade') {
      history.push(`/404`);
    }
  }
};

export const setListPairExchange =
  (baseSymbol: string, quoteSymbol: string, history: any) => async (dispatch: any, getState: any) => {
    let pathName = 'bond-exchange';
    if (window.location.pathname.includes('trade')) {
      pathName = 'trade';
    }
    try {
      const listInfo = getState().bondExchange.listOfPair;
      if (listInfo.length) {
        if (listInfo) {
          // change pair in list pair
          if (baseSymbol || quoteSymbol) {
            if (baseSymbol || quoteSymbol) {
              const exist = listInfo.find((a) => {
                return (
                  (a.base.symbol.trim().toLowerCase() === baseSymbol.trim().toLowerCase() &&
                    a.quote.symbol.trim().toLowerCase() === quoteSymbol.trim().toLowerCase()) ||
                  (a.base.contract_address.trim().toLowerCase() === baseSymbol.trim().toLowerCase() &&
                    a.quote.contract_address.trim().toLowerCase() === quoteSymbol.trim().toLowerCase()) ||
                  a.spot_manager_address.trim().toLowerCase() === baseSymbol.trim().toLowerCase()
                );
              });
              if (exist) {
                dispatch(setPairExchange(exist));
              } else {
                // find in list not public
                findPairInServer(baseSymbol, quoteSymbol, history, dispatch, getState);
              }
            }
          } else {
            const { search } = window.location;
            if (search === '') {
              history.push(`/${pathName}/${listInfo[0].base.symbol}/${listInfo[0].quote.symbol}`);
            } else if (search.includes(TypeOfPairExchange.BOND)) {
              const exist = listInfo.find((a) => a.type === TypeOfPairExchange.BOND);
              if (exist) {
                history.push({ pathname: `/${pathName}/${exist.base.symbol}/${exist.quote.symbol}`, search: search });
              } else {
                history.push(`/${pathName}/${listInfo[0].base.symbol}/${listInfo[0].quote.symbol}`);
              }
            } else {
              const exist = listInfo.find((a) => a.type === TypeOfPairExchange.STABLE);
              if (exist) {
                history.push({ pathname: `/${pathName}/${exist.base.symbol}/${exist.quote.symbol}`, search: search });
              } else {
                history.push(`/${pathName}/${listInfo[0].base.symbol}/${listInfo[0].quote.symbol}`);
              }
            }
          }
        }
      } else {
        const params = {
          page: 1,
          limit: 1000,
        };
        const queryString = serialize(params);
        const dataPairExchange = await getListPairInSpotExchange(queryString);
        if (dataPairExchange) {
          dispatch(setListPair(dataPairExchange?.pairs || []));
          if (!window.listPairClass) {
            window.listPairClass = new ListPair();
          }
          window.listPairClass.initList(dataPairExchange?.pairs || []);
          dispatch(
            setSpotAddress({
              houseAddress: dataPairExchange?.spot_house_address,
              fundingAddress: dataPairExchange?.spot_fund_address,
              exchangeLiquidityPoolAddress: dataPairExchange?.liquidity_pool_address,
              newExchangeLiquidityPoolAddress: '0x7fdb2e6e1e5300d54724b15bb4bb0b01ccba9567',
              liquidityNftManagerAddress:
                dataPairExchange?.nonfungible_position_liquidity_pool || '0x876e9d41e02ef3fccbb80ffa52a986fbff1affac',
            }),
          );
          if (dataPairExchange?.pairs.length === 0) {
            history.push('/404');
          }
          if (baseSymbol || quoteSymbol) {
            const exist = dataPairExchange?.pairs.find((a) => {
              return (
                (a.base.symbol.trim().toLowerCase() === baseSymbol.trim().toLowerCase() &&
                  a.quote.symbol.trim().toLowerCase() === quoteSymbol.trim().toLowerCase()) ||
                (a.base.contract_address.trim().toLowerCase() === baseSymbol.trim().toLowerCase() &&
                  a.quote.contract_address.trim().toLowerCase() === quoteSymbol.trim().toLowerCase()) ||
                a?.spot_manager_address.trim().toLowerCase() === baseSymbol.trim().toLowerCase()
              );
            });
            if (exist) {
              dispatch(setPairExchange(exist));
            } else {
              // find in list not public
              findPairInServer(baseSymbol, quoteSymbol, history, dispatch, getState);
            }
          } else {
            const { search } = window.location;
            const { pairs } = dataPairExchange;
            if (search === '') {
              history.push(`/${pathName}/${pairs[0].base.symbol}/${pairs[0].quote.symbol}`);
            } else if (search.includes(TypeOfPairExchange.BOND)) {
              const exist = pairs.find((a) => a.type === TypeOfPairExchange.BOND);
              if (exist) {
                history.push({
                  pathname: `/${pathName}/${exist.base.symbol}/${exist.quote.symbol}`,
                  search: search,
                });
              } else {
                history.push(`/${pathName}/${pairs[0].base.symbol}/${pairs[0].quote.symbol}`);
              }
            } else {
              const exist = pairs.find((a) => a.type === TypeOfPairExchange.STABLE);
              if (exist) {
                history.push({
                  pathname: `/${pathName}/${exist.base.symbol}/${exist.quote.symbol}`,
                  search: search,
                });
              } else {
                history.push(`/${pathName}/${pairs[0].base.symbol}/${pairs[0].quote.symbol}`);
              }
            }
          }
          dispatch(initSocketForListPair());
        }
      }
    } catch (e) {
      if (pathName === 'bond-exchange' || pathName === 'trade') {
        history.push(`/404`);
      }
    }
  };

export const getListPairWithSearch = (queryString: string) => async (dispatch: any, getState: any) => {
  try {
    const data = await getListPairInSpotExchange(queryString);

    const { pairs: dataPairExchange } = data;

    const currentList = getState().bondExchange.listOfPair;
    const currentPair = getState().bondExchange.currentPair;

    const currentPairInList = currentList.find((a) => a.spot_manager_address === currentPair.spot_manager_address);
    const currentPairInNewList = dataPairExchange.find(
      (a) => a.spot_manager_address === currentPair.spot_manager_address,
    );

    if (currentPairInList && !currentPairInNewList) {
      dataPairExchange.push({ ...currentPairInList, isHidden: true });
    }

    dispatch(setListPair(dataPairExchange));
    window.listPairClass.initList(dataPairExchange);
  } catch (e) {}
};

export const getListMarketTrade = (pair: string) => async (dispatch: any) => {
  try {
    window.marketTrade.emptyList();
    const listMarketTrade = await getListMarketTradeFromApi(`pair-address=${pair}&limit=50`);
    if (listMarketTrade?.market_trades.length > 0) {
      const newList = listMarketTrade?.market_trades.map((item) => ({
        pair_symbol: item.pair_address,
        tx_hash: item.txn_hash,
        price: item.quote_price,
        amount: item.trade_amount,
        side: item.side,
        time: item.timestamp || '',
      }));
      window.marketTrade.updateWithAList(newList, pair);
    } else {
      window.marketTrade.updateWithAList([], pair);
    }
  } catch (e) {
    window.marketTrade.updateWithAList([], pair);
  }
  dispatch(initSocketForMarketTrade(pair));
};

export const initSocketForMarketTrade = (newTicket: string) => () => {
  try {
    const socketApi = new SocketClientIO(`${newTicket}@market-trade`, 'market-trade');
    socketApi.subscribe('market-trade', (data) => {
      if (newTicket === data?.address) {
        const newData = {
          pair_symbol: data.address,
          tx_hash: data.tx_hash,
          price: Number(data.price),
          amount: Number(data.amount),
          side: data.side,
          time: data.time || '',
        };
        window.marketTrade.updateListRecentOrder(newData, newTicket);
      }
    });
  } catch (e) {}
};

export const updateOrderBookPriceInterval = (data: number, symbol: string) => (dispatch: any) => {
  dispatch(setOrderBookPriceInterval(data));
  dispatch(fetchOrderBookData(symbol));
};

export const updateOrderBookData = (data: any) => (dispatch: any, getState: any) => {
  const {
    bondExchange: { orderBookTemp, orderBook },
  } = getState();
  const {
    data: [bids, asks],
  } = orderBookTemp;
  const { priceInterval } = orderBook;
  const { bids: b, asks: a } = data;
  const uBids = getListBid(priceInterval, [b]);
  const uAsks = getListAsk(priceInterval, [a]);
  const listBid = unionBy(uBids, bids, 'price');
  const listAsk = unionBy(uAsks, asks, 'price');
  const sumListBid = getListWithTotalSize(listBid.sort(sortListBid));
  const sumListAsk = getListWithTotalSize(listAsk.sort(sortListAsk));
  const maxSize = max([last(sumListBid)?.totalSize, last(sumListAsk)?.totalSize]);
  dispatch(setOrderBookMaxSize(maxSize));
  dispatch(setOrderBookTempData([sumListBid, sumListAsk]));
};

export const fetchOrderBookData = (pairSymbol: string) => async (dispatch: any, getState: any) => {
  const {
    bondExchange: {
      orderBook: { priceInterval: defaultPriceInterval, data: currentOrderBookData },
      orderBookTemp: { symbol: currentSymbol },
      currentPair,
    },
  } = getState();
  const { spot_manager_address } = currentPair ?? {};
  if (!spot_manager_address) return;
  let asks = [],
    bids = [];
  if (pairSymbol === currentSymbol) {
    asks = currentOrderBookData[1];
    bids = currentOrderBookData[0];
  } else {
    const data = await getDataOrderBook(spot_manager_address);
    asks = data?.asks || [];
    bids = data?.bids || [];
  }
  const listBid = getListBid(defaultPriceInterval, bids);
  const listAsk = getListAsk(defaultPriceInterval, asks);
  const sumListBid = getListWithTotalSize(listBid.sort(sortListBid));
  const sumListAsk = getListWithTotalSize(listAsk.sort(sortListAsk));
  const maxSize = max([last(sumListBid)?.totalSize, last(sumListAsk)?.totalSize]);
  dispatch(setOrderBookMaxSize(maxSize));
  dispatch(setOrderBookTempData([sumListBid, sumListAsk]));
  dispatch(updateSymbolOrderBook(pairSymbol));
};

export const initOrderBookSocket = () => (dispatch: any, getState: any) => {
  const {
    bondExchange: { currentPair },
  }: AppState = getState();
  const { spot_manager_address } = currentPair ?? {};
  if (spot_manager_address) {
    const _socket = new SocketClientIO(`${spot_manager_address}@order-book-update`, 'depth');
    _socket.subscribe('depth', (params) => {
      const { address: pairSymbol, data: orderBookData } = params;

      if (isEqual(pairSymbol, spot_manager_address)) dispatch(updateOrderBookData(orderBookData));
    });
  }
};

export const initSocketForListPair = () => () => {
  try {
    const socketApi = new SocketClientIO(`ticker@all`, '24hTicker');
    socketApi.subscribe('24hTicker', (data) => {
      if (data && data.length > 0) {
        if (window.listPairClass) {
          window.listPairClass.updateList(data);
        }
      }
    });
  } catch (e) {
    // console.log('e', e);
  }
};

export const fetchListOrderHistory = (queryString: string) => async (dispatch: any) => {
  try {
    const detailData = await getListOrder(queryString);
    dispatch(setListOrderHistory(detailData));
  } catch (e) {}
};

export const fetchListTradeHistory = (queryString: string) => async (dispatch: any) => {
  try {
    const listTrade = await getListOrder(queryString);
    dispatch(setListTradeHistory(listTrade));
  } catch (e) {}
};

export const fetchPairDetailData = (pairSymbol) => async (dispatch: any) => {
  try {
    const detailData = await getPairsDetail(pairSymbol);
    dispatch(setPairDetailData(detailData));
  } catch (e) {}
};

export const fetchPublicDataPoolsExchange = () => async (dispatch: any, getState: any) => {
  const listPrices = getState().prices.data;
  if (listPrices) {
    const listData = await fetchPublicDataPoolsExchangeService(listPrices);
    if (listData.length > 0) {
      dispatch(setPublicPoolExchangeData(listData));
    }
  } else {
    setTimeout(() => {
      dispatch(fetchPublicDataPoolsExchange());
    }, 200);
  }
};

export const fetchOnlyAprForPoolsExchange = () => async (dispatch: any, getState: any) => {
  const listPrices = getState().prices.data;
  if (listPrices) {
    const listData = await getAprOfListPoolsExchange(listPrices);
    if (listData.length > 0) {
      dispatch(setPoolExchangeAprData(listData));
    }
  } else {
    setTimeout(() => {
      dispatch(fetchOnlyAprForPoolsExchange());
    }, 50);
  }
};

export const fetchUserDataPoolExchange = (account: string) => async (dispatch: any) => {
  const listUserData = await fetchUserDataPoolsExchange(account);

  if (listUserData.length > 0) {
    dispatch(setUserDataPoolExchange(listUserData));
  }
};

export const setListPairLiquidity = () => async (dispatch: any) => {
  const params = {
    page: 1,
    limit: 1000,
  };
  const queryString = serialize(params);
  const dataPairExchange = await getListPairInSpotExchange(queryString);
  if (dataPairExchange) {
    dispatch(setListPair(dataPairExchange?.pairs || []));
    dispatch(
      setSpotAddress({
        houseAddress: dataPairExchange?.spot_house_address,
        fundingAddress: dataPairExchange?.spot_fund_address,
        exchangeLiquidityPoolAddress: dataPairExchange?.liquidity_pool_address,
        newExchangeLiquidityPoolAddress: '0x7fdb2e6e1e5300d54724b15bb4bb0b01ccba9567',
        liquidityNftManagerAddress:
          dataPairExchange?.nonfungible_position_liquidity_pool || '0x1bf3beb5747fa187ab8a1ba483609d107d509642',
      }),
    );
  }
};

// list transaction history

export const fetchTransactionHistory = (queryString: string) => async (dispatch: any) => {
  const data = await getTransactionHistoryList(queryString);
  dispatch(setTransactionHistory(data));
};

export default bondExchangeSlice.reducer;
