/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import farmsConfig from '../../configs/constants/farms';
import fetchFarms from './fetchFarms';
import { fetchFarmUserData, fetchUserBalance } from './fetchFarmUser';
import { FarmsState } from '../types';
import { Farm, FarmConfig } from '../../configs/constants/types';

const initialState: FarmsState = {
  data: [...farmsConfig],
  forceRefresh: 0,
  myReferralCode: 0,
};

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload;
      const maxApr = Math.max(
        ...liveFarmsData.map((element) => {
          if (element?.isFinished) return 0;
          return Number(element?.apr || 0);
        }),
      );
      state.maxApr = maxApr;
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find((f) => f?.id === farm?.id);
        return { ...farm, ...liveFarmData };
      });
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      arrayOfUserDataObjects.forEach((userDataEl: any) => {
        const { id } = userDataEl;
        const farmIndex = state.data.findIndex((farmData) => farmData.id === id);
        state.data[farmIndex] = { ...state.data[farmIndex], userData: userDataEl.userData };
      });
    },
    setFarmUserDataByField: (
      state,
      action: PayloadAction<{ id: string | number; field: string; data: number | string }>,
    ) => {
      const { data, field, id } = action.payload;

      const currentList = state.data;

      const currentIndex = currentList.findIndex((a) => a?.id === id);
      if (currentIndex !== -1) {
        state.data[currentIndex] = {
          ...state.data[currentIndex],
          userData: { ...(state.data[currentIndex] && state.data[currentIndex]?.userData), [field]: data },
        };
      }
    },
    setPathOfFarmUserData: (state, action: PayloadAction<{ id: string | number; data: { [key: string]: any } }>) => {
      const { data, id } = action.payload;

      const currentList = state.data;

      const currentIndex = currentList.findIndex((a) => a?.id === id);
      if (currentIndex !== -1) {
        state.data[currentIndex] = {
          ...state.data[currentIndex],
          userData: { ...(state.data[currentIndex] && state.data[currentIndex]?.userData), ...data },
        };
      }
    },
    setForceRefresh: (state) => {
      state.forceRefresh = state.forceRefresh + 1;
    },
    setMyReferralCode: (state, action) => {
      const data = state;
      data.myReferralCode = action?.payload;
    },
  },
});

// Actions
export const {
  setFarmsPublicData,
  setPathOfFarmUserData,
  setFarmUserDataByField,
  setFarmUserData,
  setForceRefresh,
  setMyReferralCode,
} = farmsSlice.actions;

// Thunks
export const fetchFarmsPublicDataAsync = () => async (dispatch: any, getState: any) => {
  const listPrices = getState().prices.data;
  if (listPrices) {
    const farms = await fetchFarms(listPrices);
    if (farms.length) {
      dispatch(setFarmsPublicData(farms));
    }
  } else {
    setTimeout(() => {
      dispatch(fetchFarmsPublicDataAsync());
    }, 500);
  }
};
export const fetchFarmUserDataAsync = (account: any) => async (dispatch: any) => {
  try {
    const arrayOfUserDataObjects = await Promise.all(
      farmsConfig.map(async (farm: FarmConfig) => {
        try {
          const userData = await fetchFarmUserData(farm, account);
          return { id: farm?.id, userData };
        } catch (e: any) {
          return {
            tokenAllowance: '0',
            quoteTokenAllowance: '0',
            lpAllowance: '0',
            tokenBalance: '0',
            quoteTokenBalance: '0',
            lpBalance: '0',

            earnings: 0,
            lpStaked: 0,
            stakedBalance: 0,
            pendingEarned: 0,
            lpBalanceInCake: '0',
          };
        }
      }),
    );
    dispatch(setFarmUserData({ arrayOfUserDataObjects }));
  } catch (e) {}
};

export const getMyReferralCode = (account: string) => async (dispatch: any) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL}/getVaultRefCode?address=${account}`,
    ).then((res) => res.json());
    if (response?.success) {
      dispatch(setMyReferralCode(response?.code || 0));
    } else {
      dispatch(setMyReferralCode(0));
    }
  } catch (e) {
    dispatch(setMyReferralCode(0));
  }
};

export const getUserFarmBalance = (id: number, account: string) => async (dispatch: any, getState: any) => {
  try {
    const farmList = getState().farms.data || [];
    if (farmList && farmList.length > 0) {
      const existFarm: FarmConfig = farmList.find((f: FarmConfig) => Number(f.id) === id);
      if (existFarm) {
        const dataBalance = await fetchUserBalance(existFarm, account);
        dispatch(setPathOfFarmUserData({ id: existFarm.id, data: dataBalance }));
      }
    }
  } catch (e) {}
};

export const forceRefreshFarmCalculatorLine = () => async (dispatch) => {
  setTimeout(() => {
    dispatch(setForceRefresh());
  }, 400);
};

export default farmsSlice.reducer;
