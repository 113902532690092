import axios from 'axios';
import BigNumber from 'bignumber.js';
import erc20ABI from '../../configs/abi/erc20.json';
import posiNftFactoryABI from '../../configs/abi/posiNFTFactory.json';
import birthdayNftSaleAbi from '../../configs/abi/birthdayNftSale.json';
import tokens from '../../configs/constants/tokens';
import { getAddress, getPosiFactory, getPosiNftBirthdayAddress, getPosiNFTMintProxy } from '../../utils/addressHelpers';
import { calculateMiningPower } from '../../utils/calculatePower';
import { getBalanceNumber } from '../../utils/formatBalance';
import multicall from '../../utils/multicall';

export const fetchNFTUserAllowances = async (account: string) => {
  try {
    const posiNFTMintProxyAddress = getPosiNFTMintProxy();
    const posiNFTFactoryAddress = getPosiFactory();
    const contractAddress = getAddress(tokens.posiv2.address);
    const calls = [
      { address: contractAddress, name: 'allowance', params: [account, posiNFTMintProxyAddress] },
      { address: contractAddress, name: 'allowance', params: [account, posiNFTFactoryAddress] },
    ];
    const rawLpAllowances = await multicall(erc20ABI, calls);
    const parsedLpAllowances = rawLpAllowances?.map((lpBalance: any) => {
      return new BigNumber(lpBalance)?.toJSON();
    });
    return parsedLpAllowances;
  } catch (error) {
    return [];
  }
};
export const fetchUserBalances = async (account: any) => {
  try {
    const calls = [
      {
        address: getAddress(tokens.posiv2.address),
        name: 'balanceOf',
        params: [account],
      },
    ];
    const tokenBalancesRaw = await multicall(erc20ABI, calls);
    const parsedBalances = new BigNumber(tokenBalancesRaw).toJSON();
    return parsedBalances;
  } catch (error) {
    return '';
  }
};

export const getTokensOfUser = async (account: any, posiNftContract: any) => {
  try {
    const posiNFTFactoryAddress = getPosiFactory();
    const tokenIds = await posiNftContract?.methods?.tokensOfOwner(account).call();
    const calls = tokenIds?.map((id: any) => ({
      address: posiNFTFactoryAddress,
      name: 'getGego',
      params: [id],
    }));
    const pendingTokenInfos = await multicall(posiNftFactoryABI, calls);
    const parseTokenInfos = pendingTokenInfos?.map((info: any, index: any) => {
      return {
        id: calls[index].params[0],
        grade: new BigNumber(info['grade']._hex)?.toJSON(),
        amount: getBalanceNumber(new BigNumber(info['amount']._hex)?.toNumber()),
        quality: new BigNumber(info['quality']._hex)?.toJSON(),
        author: info['author'],
        createdTime: new BigNumber(info['createdTime']._hex)?.toJSON(),
        minBurnTime: new BigNumber(info['createdTime']._hex)
          ?.multipliedBy(1000)
          .plus(new BigNumber(info['lockedDays']._hex)?.toNumber() * 86400000)
          .toNumber(),
        lockedDays: new BigNumber(info['lockedDays']._hex)?.toJSON(),
        erc20: info['erc20'],
        action: 'myNft',
        miningPower: calculateMiningPower(
          new BigNumber(info['grade']._hex)?.toJSON(),
          new BigNumber(info['quality']._hex)?.toJSON(),
          getBalanceNumber(new BigNumber(info['amount']._hex)?.toNumber()),
        ),
      };
    });
    return parseTokenInfos;
  } catch (error) {
    return [];
  }
  // if (tokenIds?.length) {
  //   const tokenInfos = Promise.all(
  //     tokenIds.map(async (e: any) => {
  //       const res = await posiNftFactory?.methods?.getGego(e).call();
  //       const info: any = {};
  //       info.id = e;
  //       info.grade = res.grade;
  //       info.amount = getBalanceNumber(res.amount);
  //       info.quality = res.quality;
  //       info.author = res.author;
  //       info.createdTime = res.createdTime;
  //       info.minBurnTime = Number(res.createdTime) * 1000 + Number(res.lockedDays) * 86400 * 1000;
  //       info.erc20 = res.erc20;
  //       info.action = 'myNft';
  //       info.lockedDays = res.lockedDays;
  //       info.miningPower = calculateMiningPower(res.grade, res.quality, getBalanceNumber(res.amount));
  //       return info;
  //     }),
  //   );
  //   return tokenInfos;
  // }
};

export const getTokenStaked = async (account: any, generalNFTRewardContract: any) => {
  try {
    const res = await generalNFTRewardContract?.methods?.getPlayerIds(account).call();
    const newRes = Array.from(res);
    newRes.shift();
    const posiNFTFactoryAddress = getPosiFactory();
    const calls = newRes?.map((id: any) => ({
      address: posiNFTFactoryAddress,
      name: 'getGego',
      params: [id],
    }));
    const pendingTokenInfos = await multicall(posiNftFactoryABI, calls);
    const parseTokenInfos = pendingTokenInfos?.map((info: any, index: any) => {
      return {
        id: calls[index].params[0],
        grade: new BigNumber(info['grade']._hex)?.toJSON(),
        amount: getBalanceNumber(new BigNumber(info['amount']._hex)?.toNumber()),
        quality: new BigNumber(info['quality']._hex)?.toJSON(),
        author: info['author'],
        createdTime: new BigNumber(info['createdTime']._hex)?.toJSON(),
        minBurnTime: new BigNumber(info['createdTime']._hex)
          ?.multipliedBy(1000)
          .plus(new BigNumber(info['lockedDays']._hex)?.toNumber() * 86400000)
          .toNumber(),
        lockedDays: new BigNumber(info['lockedDays']._hex)?.toJSON(),
        erc20: info['erc20'],
        action: 'myNft',
        isStaked: 'old',
        miningPower: calculateMiningPower(
          new BigNumber(info['grade']._hex)?.toJSON(),
          new BigNumber(info['quality']._hex)?.toJSON(),
          getBalanceNumber(new BigNumber(info['amount']._hex)?.toNumber()),
        ),
        type: 'Staked',
      };
    });
    return parseTokenInfos;
  } catch (error) {
    return [];
  }
  // const res = await generalNFTRewardContract.methods.getPlayerIds(account).call();
  // const newRes = Array.from(res);
  // newRes.shift();
  // if (newRes?.length) {
  //   const tokenInfos = Promise.all(
  //     newRes.map(async (e: any) => {
  //       const res = await posiNftFactory?.methods?.getGego(e).call();
  //       const info: any = {};
  //       info.id = e;
  //       info.grade = res.grade;
  //       info.amount = getBalanceNumber(res.amount);
  //       info.quality = res.quality;
  //       info.author = res.author;
  //       info.createdTime = res.createdTime;
  //       info.minBurnTime = Number(res.createdTime) * 1000 + Number(res.lockedDays) * 86400 * 1000;
  //       info.erc20 = res.erc20;
  //       info.action = 'myNft';
  //       info.lockedDays = res.lockedDays;
  //       info.miningPower = calculateMiningPower(res.grade, res.quality, getBalanceNumber(res.amount));
  //       info.type = 'Staked';
  //       return info;
  //     }),
  //   );
  //   return tokenInfos;
  // }
};

export const getTokenNewStaked = async (account: any, generalNFTRewardContract: any) => {
  try {
    const res = await generalNFTRewardContract?.methods?.getPlayerIds(account).call();
    const newRes = Array.from(res);
    newRes.shift();
    const posiNFTFactoryAddress = getPosiFactory();
    const calls = newRes?.map((id: any) => ({
      address: posiNFTFactoryAddress,
      name: 'getGego',
      params: [id],
    }));
    const pendingTokenInfos = await multicall(posiNftFactoryABI, calls);
    const parseTokenInfos = pendingTokenInfos?.map((info: any, index: any) => {
      return {
        id: calls[index].params[0],
        grade: new BigNumber(info['grade']._hex)?.toJSON(),
        amount: getBalanceNumber(new BigNumber(info['amount']._hex)?.toNumber()),
        quality: new BigNumber(info['quality']._hex)?.toJSON(),
        author: info['author'],
        createdTime: new BigNumber(info['createdTime']._hex)?.toJSON(),
        minBurnTime: new BigNumber(info['createdTime']._hex)
          ?.multipliedBy(1000)
          .plus(new BigNumber(info['lockedDays']._hex)?.toNumber() * 86400000)
          .toNumber(),
        lockedDays: new BigNumber(info['lockedDays']._hex)?.toJSON(),
        erc20: info['erc20'],
        action: 'myNft',
        isStaked: 'new',
        miningPower: calculateMiningPower(
          new BigNumber(info['grade']._hex)?.toJSON(),
          new BigNumber(info['quality']._hex)?.toJSON(),
          getBalanceNumber(new BigNumber(info['amount']._hex)?.toNumber()),
        ),
        type: 'Staked',
      };
    });
    return parseTokenInfos;
  } catch (error) {
    return [];
  }
  // const res = await generalNFTRewardContract.methods.getPlayerIds(account).call();
  // const newRes = Array.from(res);
  // newRes.shift();
  // if (newRes?.length) {
  //   const tokenInfos = Promise.all(
  //     newRes.map(async (e: any) => {
  //       const res = await posiNftFactory?.methods?.getGego(e).call();
  //       const info: any = {};
  //       info.id = e;
  //       info.grade = res.grade;
  //       info.amount = getBalanceNumber(res.amount);
  //       info.quality = res.quality;
  //       info.author = res.author;
  //       info.createdTime = res.createdTime;
  //       info.minBurnTime = Number(res.createdTime) * 1000 + Number(res.lockedDays) * 86400 * 1000;
  //       info.erc20 = res.erc20;
  //       info.action = 'myNft';
  //       info.lockedDays = res.lockedDays;
  //       info.miningPower = calculateMiningPower(res.grade, res.quality, getBalanceNumber(res.amount));
  //       info.type = 'Staked';
  //       return info;
  //     }),
  //   );
  //   return tokenInfos;
  // }
};

export const getNftHistories = async (address: any) => {
  try {
    const histories = await axios.get(`https://nftapi.position.exchange/getCastedNftHistory?address=${address}`);
    if (histories.status === 200) {
      return histories.data.data;
    }
  } catch (error) {
    return [];
  }
};

export const getNftPurchasedId = async (account: any) => {
  try {
    const tokenAddress = getPosiNftBirthdayAddress();
    const calls = [
      {
        address: tokenAddress,
        name: 'nftPurchased',
        params: [account],
      },
    ];

    const [idRaw] = await multicall(birthdayNftSaleAbi, calls);

    const id = parseInt(idRaw[0]._hex || 0, 16);
    return id;
  } catch (error) {}
};
