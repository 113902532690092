import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStatusCompetition } from '../../utils/common';
import { fetchDataCompetitionBalance, fetchDataWithdrawalHistory } from '../buyingCompetition/fetchData';
import { FarmingCompetitionState, IPairItem } from '../types';
import { fetchDataFarmingCompetition, fetchDataFarmingRankingList, getFarmingCompetitionUserData } from './fetchData';

const initialState: FarmingCompetitionState = {
  listRanking: [],
  currentCompetition: null,
  competitionOverview: {},
  competitionBalance: 0,
  withdrawHistory: [],
  listPair: [],
  totalUsdVolume: 0,
};
export const farmingCompetitionSlice = createSlice({
  name: 'farmingCompetition',
  initialState,
  reducers: {
    setDataRankingList: (state, action) => {
      const data = state;
      data.listRanking = action.payload;
      return data;
    },
    setCurrentCompetition: (state, action) => {
      const data = state;
      data.currentCompetition = action.payload;
      return data;
    },
    setDataCompetitionOverview: (state, action) => {
      const data = state;
      data.competitionOverview = action.payload;
      return data;
    },
    setFarmingCompetitionUserData: (
      state,
      action: PayloadAction<{ totalUsdVolume: number; listPair: IPairItem[] }>,
    ) => {
      const data = state;
      data.listPair = action.payload.listPair;
      data.totalUsdVolume = action.payload.totalUsdVolume;
      return data;
    },
    setDataCompetitionBalance: (state, action: PayloadAction<{ balance: number | string; historyList: any[] }>) => {
      const data = state;
      data.competitionBalance = action.payload.balance;
      data.withdrawHistory = action.payload.historyList;
      return data;
    },
  },
});

export const {
  setCurrentCompetition,
  setFarmingCompetitionUserData,
  setDataRankingList,
  setDataCompetitionOverview,
  setDataCompetitionBalance,
} = farmingCompetitionSlice.actions;

export const fetchPublicDataOfFarmingCompetition = () => async (dispatch: any) => {
  dispatch(fetchListRankingOfFarmingCompetition());
  dispatch(fetchCurrentCompetition());
};

export const fetchCurrentCompetition = () => async (dispatch: any) => {
  const data = await fetchDataFarmingCompetition();
  if (data) {
    dispatch(
      setCurrentCompetition({
        ...data,
        startTime: data?.start,
        endTime: data?.end,
        prizePool: data?.prize_pool,
      }),
    );
    const status = getStatusCompetition(data?.start, data?.end);
    const customData = { ...data, status };
    dispatch(setDataCompetitionOverview(customData));
  }
};

export const fetchListRankingOfFarmingCompetition = () => async (dispatch: any) => {
  const listRankings = await fetchDataFarmingRankingList();
  if (listRankings) {
    const newList = listRankings.sort((a: any, b: any) => Number(a.place) - Number(b.place));
    dispatch(
      setDataRankingList((newList && newList?.sort((a: any, b: any) => Number(a.place) - Number(b.place))) ?? []),
    );
  }
};

export const fetchDataFarming = (competitionId: number, account: string) => async (dispatch: any) => {
  try {
    const userData = await getFarmingCompetitionUserData(competitionId, account);

    dispatch(
      setFarmingCompetitionUserData({
        totalUsdVolume: Number(userData?.total_usd_volume),
        listPair: userData?.lp_details || [],
      }),
    );
  } catch (e) {}
};

export const getFarmingCompetition = (address: string) => async (dispatch: any) => {
  try {
    const listHistoryResponse = await fetchDataWithdrawalHistory(address);
    const balanceResponse = await fetchDataCompetitionBalance(address);
    dispatch(setDataCompetitionBalance({ balance: balanceResponse || 0, historyList: listHistoryResponse || [] }));
  } catch (e) {}
};

export default farmingCompetitionSlice.reducer;
