import axios from 'axios';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { waitForReceipt } from '../hooks/useVaults';
import i18n from 'i18next';
import { Max_256 } from '../views/BondDetail/Component/PurchaseBondInput';
import confirmModal from './confirmModal';
import store from '../redux/store';
import { changeMessage, MessageType } from '../redux/toasts';
import { multicallERCPerChain } from './multicall';
import { formatBigNumber } from '../hooks/useModalInfo';
import { formatWeiToEther } from './bridgeAssetUtil';

const address0 = '0x0000000000000000000000000000000000000000';
export const approve = async (lpContract: any, masterChefContract: any, account: any) => {
  const method = lpContract.methods.approve(masterChefContract.options.address, ethers.constants.MaxUint256);
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas });
};

export const approveWithListTransaction = async (lpContract: any, masterChefContract: any, account: any, cb: any) => {
  const method = lpContract.methods.approve(masterChefContract.options.address, ethers.constants.MaxUint256);
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas }, cb);
};

export const approveWithListTransactionSimple = async (lpContract: any, spender: any, account: any, cb: any) => {
  const method = lpContract.methods.approve(spender, Max_256);
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas }, cb);
};

export const approveDecompose = async (lpContract: any, posiNftFactory: any, account: any, tokenId: any) => {
  const method = lpContract.methods.approve(posiNftFactory.options.address, tokenId);
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas });
};

export const callEstGas = async (method: any, account: string, value?: string, prefixError?: string) => {
  try {
    if (window.isPosiApp) {
      // no call est gas when using in posi wallet application
      return  3000000;
    }
    const gas = await method.estimateGas({ from: account, value });
    const newGas = Number(gas) * 1.5;
    return Math.floor(newGas);
  } catch (err: any) {
    let errorHandle = null;
    try {
      const newMsg = err.message.replace('Internal JSON-RPC error.', '');
      errorHandle = JSON.parse(newMsg);
      if (errorHandle?.message && prefixError) {
        const listMsg = i18n.store.data[i18n.language].common;
        const object = JSON.parse(newMsg);
        const string = object.message.replaceAll('execution reverted: ', '');
        errorHandle = { message: listMsg[`${prefixError}${string}`] };
      }
    } catch (e: any) {
      errorHandle = err;
      if (e?.message && prefixError) {
        const listMsg = i18n.store.data[i18n.language].common;
        const string = e.message.replaceAll('execution reverted: ', '');
        errorHandle = { message: listMsg[`${prefixError}${string}`] };
      }
    }
    const result = await confirmModal(errorHandle || err);
    if (result) {
      return 900000;
    } else {
      throw Error();
    }
  }
};

export const callEstGasForExchange = async (method: any, account: string, value?: string, prefixError?: string) => {
  try {
    const gas = await method.estimateGas({ from: account, value });
    const newGas = Number(gas);
    store.dispatch(changeMessage({ type: MessageType.ConfirmInWallet }));
    return Math.floor(newGas);
  } catch (err: any) {
    let errorHandle = null;
    try {
      const newMsg = err.message.replace('Internal JSON-RPC error.', '');
      errorHandle = JSON.parse(newMsg);
      if (errorHandle?.message && prefixError) {
        const listMsg = i18n.store.data[i18n.language].common;
        const object = JSON.parse(newMsg);
        const string = object.message.replaceAll('execution reverted: ', '');
        errorHandle = { message: listMsg[`${prefixError}${string}`] };
      }
    } catch (e: any) {
      errorHandle = err;
      if (e?.message && prefixError) {
        const listMsg = i18n.store.data[i18n.language].common;
        const string = e.message.replaceAll('execution reverted: ', '');
        errorHandle = { message: listMsg[`${prefixError}${string}`] };
      }
    }
    const result = await confirmModal(errorHandle || err);
    if (result) {
      store.dispatch(changeMessage({ type: MessageType.ConfirmInWallet }));
      return 900000;
    } else {
      store.dispatch(changeMessage({ type: MessageType.Close }));
      throw Error();
    }
  }
};

export const callEstGasVault = async (method: any, account: string, value?: string) => {
  try {
    const gas = await method.estimateGas({ from: account, value });
    const newGas = Number(gas) * 1.5;
    return Math.floor(newGas);
  } catch (err: any) {
    return 2500000;
  }
};

export const getRefferData = async (account: string) => {
  try {
    const response = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL,
      url: `/fetchRefInfo`,
      params: {
        address: account,
      },
    }).then((res) => res.data);
    if (response && response?.referrer && response?.referrer !== '') {
      return Web3.utils.toChecksumAddress(response?.referrer);
    } else {
      return address0;
    }
  } catch (e: any) {
    return address0;
  }
};

export const stake = async (masterChefContract: any, pid: any, amount: any, account: any) => {
  const addressZero = await getRefferData(account);
  if (pid === 0) {
    const method = masterChefContract.methods.deposit(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
      addressZero,
    );
    const gas = await callEstGas(method, account);
    return method
      .send({ from: account, gas: gas })
      .on('transactionHash', (tx: any) => {
        return tx.transactionHash;
      })
      .then((tx: any) => tx);
  }

  const method = masterChefContract.methods.deposit(
    pid,
    new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
    addressZero,
  );
  const gas = await callEstGas(method, account);
  return method
    .send({ from: account, gas: Math.floor(gas * 1.5) })
    .on('transactionHash', (tx: any) => {
      return tx.transactionHash;
    })
    .then((tx: any) => tx);
};

export const stakeWithTransaction = async (
  masterChefContract: any,
  pid: any,
  amount: any,
  account: any,
  callback: any,
) => {
  const addressZero = await getRefferData(account);
  if (pid === 0) {
    const method = masterChefContract.methods.deposit(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
      addressZero,
    );
    const gas = await callEstGas(method, account);
    return method.send({ from: account, gas: gas }, callback);
  }
  let method: any = null;
  if (pid > 200) {
    method = masterChefContract.methods.deposit(new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed());
  } else {
    method = masterChefContract.methods.deposit(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
      addressZero,
    );
  }

  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas: Math.floor(gas * 1.5) }, callback);
};

export const sousStake = async (sousChefContract: any, amount: any, decimals = 18, account: any) => {
  const method = sousChefContract.methods.deposit(
    new BigNumber(amount).times(new BigNumber(10).pow(decimals).toFixed()),
  );
  const gas = await callEstGas(method, account);
  return method
    .send({ from: account, gas: Math.floor(gas * 1.5) })
    .on('transactionHash', (tx: any) => {
      return tx.transactionHash;
    })
    .then((tx: any) => tx);
};

export const sousStakeBnb = async (sousChefContract: any, amount: any, account: any) => {
  const method = sousChefContract.methods.deposit();
  const gas = await callEstGas(method, account);
  return method
    .send({ from: account, gas: gas, value: new BigNumber(amount).times(new BigNumber(10).pow(18).toFixed()) })
    .on('transactionHash', (tx: any) => {
      return tx.transactionHash;
    })
    .then((tx: any) => tx);
};

export const emergencyWithdraw = async (masterChefContract: any, pid: any, account: any) => {
  if (pid < 0) {
    pid = 0;
  }
  const method = masterChefContract.methods.emergencyWithdraw(pid);
  const gas = await callEstGas(method, account);
  return method
    .send({ from: account, gas: gas })
    .on('transactionHash', (tx: any) => {
      return tx.transactionHash;
    })
    .then((tx: any) => tx);
};

export const unstake = async (masterChefContract: any, pid: any, amount: any, account: any) => {
  const method = masterChefContract.methods.withdraw(
    pid,
    new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
  );
  const gas = await callEstGas(method, account);
  return method
    .send({ from: account, gas: Math.floor(gas * 1.5) })
    .on('transactionHash', (tx: any) => {
      return tx.transactionHash;
    })
    .then((tx: any) => tx);
};

export const unstakeWithTransaction = async (
  masterChefContract: any,
  pid: any,
  amount: any,
  account: any,
  callback: any,
) => {
  let method: any = null;
  if (pid > 200) {
    method = masterChefContract.methods.withdraw(new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed());
  } else {
    method = masterChefContract.methods.withdraw(pid, new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed());
  }
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas: Math.floor(gas * 1.5) }, callback);
};

export const sousUnstake = async (sousChefContract: any, amount: any, decimals = 18, account: any) => {
  // shit code: hard fix for old CTK and BLK
  if (sousChefContract.options.address === '0x3B9B74f48E89Ebd8b45a53444327013a2308A9BC') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx: any) => {
        return tx.transactionHash;
      })
      .then((tx: any) => tx);
  }
  if (sousChefContract.options.address === '0xBb2B66a2c7C2fFFB06EA60BeaD69741b3f5BF831') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx: any) => {
        return tx.transactionHash;
      });
  }
  if (sousChefContract.options.address === '0x453a75908fb5a36d482d5f8fe88eca836f32ead5') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx: any) => {
        return tx.transactionHash;
      });
  }

  const method = sousChefContract.methods.withdraw(
    new BigNumber(amount).times(new BigNumber(10).pow(decimals).toFixed()),
  );
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas: gas }).on('transactionHash', (tx: any) => {
    return tx.transactionHash;
  });
};

export const sousEmergencyUnstake = async (sousChefContract: any, amount: any, account: any) => {
  const method = sousChefContract.methods.emergencyWithdraw();
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas }).on('transactionHash', (tx: any) => {
    return tx.transactionHash;
  });
};

export const harvest = async (masterChefContract: any, pid: any, account: any) => {
  // if (pid === 0) {
  //   return masterChefContract.methods
  //     .leaveStaking('0')
  //     .send({ from: account, gas: 500000 })
  //     .on('transactionHash', (tx: any) => {
  //       return tx.transactionHash;
  //     })
  //     .then((tx: any) => tx);
  // }
  const addressZero = await getRefferData(account);
  const method = masterChefContract.methods.deposit(pid, '0', addressZero);
  const gas = await callEstGas(method, account);
  return method
    .send({ from: account, gas: gas })
    .on('transactionHash', (tx: any) => {
      return tx.transactionHash;
    })
    .then((tx: any) => tx);
};

export const compound = async (masterChefContract: any, pid: any, amount: any, account: any) => {
  const addressZero = await getRefferData(account);
  const method = masterChefContract.methods.deposit(
    pid,
    new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
    addressZero,
  );
  const gas = await callEstGas(method, account);
  return method
    .send({ from: account, gas: gas })
    .on('transactionHash', (tx: any) => {
      return tx.transactionHash;
    })
    .then((tx: any) => tx);
};

export const compoundWithTransaction = async (
  masterChefContract: any,
  pid: any,
  amount: any,
  account: any,
  callback: any,
) => {
  const addressZero = await getRefferData(account);
  const method = masterChefContract.methods.deposit(
    pid,
    new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
    addressZero,
  );
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas: gas }, callback);
};

export const soushHarvest = async (sousChefContract: any, account: any) => {
  const method = sousChefContract.methods.deposit('0');
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas: gas }).on('transactionHash', (tx: any) => {
    return tx.transactionHash;
  });
};

export const soushHarvestBnb = async (sousChefContract: any, account: any) => {
  const method = sousChefContract.methods.deposit();
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas: gas, value: new BigNumber(0) }).on('transactionHash', (tx: any) => {
    return tx.transactionHash;
  });
};
// nft

export const mintNft = async (posiNFTFactory: any, amountMint: any, account: any, web3: any) => {
  const method = posiNFTFactory.methods.mint([
    new BigNumber(amountMint).times(new BigNumber(10).pow(18)).toFixed(),
    0,
    1,
    0,
    0,
  ]);
  const gas = await callEstGas(method, account);
  const res = (await new Promise((resolve, reject) => {
    method.send(
      {
        from: account,
        gas: gas,
      },
      function (err, hash) {
        if (hash) {
          waitForReceipt(web3, hash, function (receipt) {
            resolve(receipt);
          });
        }
        if (err) {
          reject(err);
        }
      },
    );
  })) as any;
  const log = res.logs.find((logx: any) =>
    logx.topics.includes('0xd0608f24faeb4f509b42a0b5a1572c0d0aefdded730ad8045d24f2cf1458dfe0'),
  );
  const [grade, quality, amount, resBaseId, tLevel, ruleId, nftType, author, erc20, createdTime, blockNum, lockedDays] =
    await ethers.utils.defaultAbiCoder.decode(
      [
        'uint256',
        'uint256',
        'uint256',
        'uint256',
        'uint256',
        'uint256',
        'uint256',
        'address',
        'address',
        'uint256',
        'uint256',
        'uint256',
      ],
      log.data,
    );
  const id = parseInt(log.topics[1], 16);
  const returnValues = {
    id,
    grade: new BigNumber(grade._hex).toNumber(),
    quality: new BigNumber(quality._hex).toString(),
    amount: Number(new BigNumber(amount._hex).toFixed()),
    resBaseId: new BigNumber(resBaseId._hex).toNumber(),
    tLevel: new BigNumber(tLevel._hex).toNumber(),
    ruleId: new BigNumber(ruleId._hex).toNumber(),
    nftType: new BigNumber(nftType._hex).toNumber(),
    author: new BigNumber(author._hex).toString(),
    erc20: new BigNumber(erc20._hex).toString(),
    createdTime: new BigNumber(createdTime._hex).toNumber(),
    blockNum: new BigNumber(blockNum._hex).toNumber(),
    lockedDays: new BigNumber(lockedDays._hex).toNumber(),
  };

  const newRest: any = {
    events: {
      GegoAdded: { returnValues },
    },
  };
  return newRest;
  // return method
  //   .send({ from: account, gas: gas })
  //   .on('transactionHash', (tx: any) => {
  //     return tx;
  //   })
  //   .then((tx: any) => tx);
};

export const decomposeNft = async (posiNFTFactory: any, tokenId: number | string, account: any) => {
  const method = posiNFTFactory.methods.burn(tokenId);
  const gas = await callEstGas(method, account);
  return method
    .send({ from: account, gas: gas })
    .on('transactionHash', (tx: any) => {
      return tx;
    })
    .then((tx: any) => tx);
};

export const decomposeNftWithTransaction = async (
  posiNFTFactory: any,
  tokenId: number | string,
  account: any,
  callback: any,
) => {
  const method = posiNFTFactory.methods.burn(tokenId);
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas: gas }, callback);
};

export const getNativeTokenAmount = (web3, account) => {
  try {
    return web3.eth.getBalance(account).then((res) => formatBigNumber(new BigNumber(res)));
  } catch (error) {
    return 0;
  }
};

export const getERCTokenAmount = async (web3, account, tokenAddresses: string) => {
  const call = [{ address: tokenAddresses, name: 'balanceOf', params: [account] }];
  const res = await multicallERCPerChain(web3, call);
  const { balance } = res[0];
  if (!balance) {
    return 0;
  }
  return formatBigNumber(new BigNumber(balance._hex || 0));
};

export const getAllowance = async (web3, account, spenderAddress, tokenAddresses) => {
  const call = [{ address: tokenAddresses, name: 'allowance', params: [account, spenderAddress] }];
  const res = await multicallERCPerChain(web3, call);
  const allowanceObj = res[0];
  if (!allowanceObj) {
    return 0;
  }
  return formatBigNumber(new BigNumber(allowanceObj[0]._hex || 0));
};

export const transferToOtherChains = async (
  bridgeContract: any,
  transferToOtherBlockchain: number,
  destBcId: number,
  recipient: string,
  amount: string | number,
  account: string,
  callback: any,
) => {
  const method = bridgeContract.methods.transferToOtherBlockchain(
    recipient,
    new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
  );
  const gas = await callEstGas(method, account);
  return method.send({ from: account, gas: gas }, callback);
};

export const transferNativeToOtherChains = async (
  bridgeContract: any,
  payableAmount: number,
  destBcId: number,
  recipient: string,
  amount: string | number,
  account: string,
  callback: any,
  // gasPrice?: number,
) => {
  const method = bridgeContract.methods.transferToOtherBlockchain(
    destBcId,
    recipient,
    new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
  );
  const gas = await callEstGas(
    method,
    account,
    new BigNumber(payableAmount).times(new BigNumber(10).pow(18)).toFixed(),
  );
  // const transactionFee = formatWeiToEther(gas * gasPrice);
  return method.send(
    {
      from: account,
      gas: gas,
      value: new BigNumber(payableAmount).times(new BigNumber(10).pow(18)).toFixed(),
    },
    callback,
  );
};
