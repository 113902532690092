import { Flex, Image, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useGetApiPrice } from '../../redux/hooks';

interface PoolItemProps {
  dataState: any;
  account: any;
  value: any;
  valueUsd: any;
  renderValues: any;
  assetDisplay?: string;
}

const PoolItem: React.FC<PoolItemProps> = ({ dataState, account, value, valueUsd, renderValues, assetDisplay }) => {
  const posiLpPrice = useGetApiPrice('posi');
  const whiteToGray700 = useColorModeValue('gray.700', 'white');

  return (
    <Flex direction={'column'} mb={['6px', '10px', '10px', '10px']}>
      <Flex mt={['6px', '10px', '10px', '10px']}>
        <Flex mt={['0px', '5px', '5px', '5px']} align="center">
          <Flex>
            <Image src={dataState?.poolImage} w="40px" h="40px" />
          </Flex>
          <Flex ml="10px">
            <Text
              fontFamily={'Poppins'}
              fontStyle={'normal'}
              fontWeight={'500'}
              fontSize={'14px'}
              color={whiteToGray700}
            >
              {dataState?.pname}
            </Text>
          </Flex>
        </Flex>
        <Spacer />
        <Flex direction={'column'} mt={['0px', '10px', '10px', '10px']} mr="4px">
          <Flex justifyContent={'flex-end'}>
            <Text
              fontFamily={'Poppins'}
              fontStyle={'normal'}
              fontWeight={'600'}
              fontSize={'14px'}
              color={whiteToGray700}
            >
              {account ? renderValues(value) : '0'} {assetDisplay || dataState?.stakingToken?.symbol}
            </Text>
          </Flex>
          <Flex justifyContent={'flex-end'} mt={['0px', '5px', '5px', '5px']}>
            <Text fontFamily={'Poppins'} fontStyle={'normal'} fontWeight={'500'} fontSize={'12px'} color="cyan.400">
              ~$
              {(posiLpPrice && renderValues(valueUsd)) || '0'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PoolItem;
