import BigNumber from 'bignumber.js';
import generalNFTRewardABI from '../../configs/abi/generalNFTReward.json';
import erc20ABI from '../../configs/abi/erc20.json';
import sousChefABI from '../../configs/abi/sousChef.json';
import PoolEarn3rdTokenABI from '../../configs/abi/PoolEarn3rdToken.json';
import poolsConfig from '../../configs/pools';
import { LIQUIDITY_NFT, POOL_CATEGORY, PoolConfig, PoolType } from '../../configs/types';
import { getAddress, getPOSINFTFActoryAddress } from '../../utils/addressHelpers';
import multicall from '../../utils/multicall';
import { getWeb3NoAccount } from '../../utils/web3';
import posiNFTABI from '../../configs/abi/posiNft.json';
import posiNFTFactoryABI from '../../configs/abi/posiNFTFactory.json';
import exchangeLiquidityAbi from '../../configs/abi/exchange-liquidity-pool.json';
import exchangeLiquidityAbiV2 from '../../configs/abi/exchange-liquidity-pool-v2.json';
import { formatBigNumber } from '../../hooks/useModalInfo';
import { listItemNfts } from '../../configs/constants/nfts';
import store from '../store';
import { updateNftListCard } from '.';
import poolsExchangeConfig from '../../configs/poolsSpotExchange';
import liquidityNftStakingAbi from '../../configs/abi/liquidityNftStaking.json';
import liquidityNftStakingDexV2Abi from '../../configs/abi/liquidityNftStakingDexV2.json';
import liquidityNftManagerAbi from '../../configs/abi/liquidity_nft_manager.json';
import PositionNondisperseLiquidityAbi from '../../configs/abi/positionNondisperseLiquidity.json';

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'BNB' && p.poolCategory !== POOL_CATEGORY.NFT);
const nonBnbAndNFTPools = poolsConfig.filter(
  (p) => p.stakingToken.symbol !== 'BNB' && p.poolCategory === POOL_CATEGORY.NFT,
);
const bnbPools = poolsConfig.filter((p) => p.stakingToken.symbol === 'BNB');
const earning3rdTokenPools = poolsConfig.filter((p) => p.type === PoolType.EarnToken);
const earningPosiTokenPools = poolsConfig.filter(
  (p) => p.type === PoolType.EarnPOSI && p.poolCategory !== POOL_CATEGORY.NFT,
);

const web3 = getWeb3NoAccount();

export const fetchPoolsAllowance = async (account: any) => {
  const calls = nonBnbPools.map((p) => ({
    address: getAddress(p.stakingToken.address),
    name: 'allowance',
    params: [account, getAddress(p.contractAddress)],
  }));
  const callsNFT = nonBnbAndNFTPools.map((p) => ({
    address: getAddress(p.stakingToken.address),
    name: 'isApprovedForAll',
    params: [account, getAddress(p.contractAddress)],
  }));

  const allowances = await multicall(erc20ABI, calls);
  const allowancesNFT = await multicall(posiNFTABI, callsNFT);
  const nonBNBAndNonNFT = nonBnbPools.map((pool, index) => ({
    ...pool,
    allowance: new BigNumber((allowances && allowances[index]) || 0).toJSON(),
  }));
  const nonBNBAndNFT = nonBnbAndNFTPools.map((pool, index) => ({
    ...pool,
    allowance: Number(allowancesNFT && allowancesNFT[index][0]) || Number(false),
  }));

  return [...nonBNBAndNonNFT, ...nonBNBAndNFT];
};

export const fetchUserBalances = async (account: any) => {
  // Non BNB pools
  const calls = nonBnbPools.map((p) => ({
    address: getAddress(p.stakingToken.address),
    name: 'balanceOf',
    params: [account],
  }));

  const tokenBalancesRaw = await multicall(erc20ABI, calls);

  const tokenBalances = nonBnbPools.map((pool, index) => ({
    ...pool,
    userBalance: new BigNumber((tokenBalancesRaw && tokenBalancesRaw[index]) || 0).toJSON(),
  }));

  // BNB pools
  const bnbBalance = await web3.eth.getBalance(account);
  const bnbBalances = bnbPools.map((pool) => ({ ...pool, [pool.id]: new BigNumber(bnbBalance).toJSON() }));

  return [...tokenBalances, ...bnbBalances];
};

export const fetchUserStakeBalances = async (account: any) => {
  const callsEarnPosiPool = earningPosiTokenPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [p.sousId, account],
  }));

  const callsEarnTokenPool = earning3rdTokenPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }));

  const userInfo = await multicall(sousChefABI, callsEarnPosiPool);
  const userInfoEarn3rdToken = await multicall(PoolEarn3rdTokenABI, callsEarnTokenPool);

  const callsNFTStakedBalance = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'balanceOf',
      params: [account],
    }));

  const listBalanceStakedNft = await multicall(generalNFTRewardABI, callsNFTStakedBalance);

  const stakedBalanceNftPool = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((pool, index) => ({
      ...pool,
      stakedBalance: formatBigNumber(new BigNumber((listBalanceStakedNft && listBalanceStakedNft[index][0]._hex) || 0)),
    }));

  const callsNFTStakedBalanceLiquidity = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length !== 42,
    )
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'userInfo',
      params: [getAddress(p?.poolKey), account],
    }));

  const listBalanceStakedNftLiquidityRaw = await multicall(liquidityNftStakingAbi, callsNFTStakedBalanceLiquidity);

  const callsNFTStakedBalanceLiquidityV2 = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length === 42,
    )
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'userInfo',
      params: [getAddress(p?.poolKey), account],
    }));

  const listBalanceStakedNftLiquidityV2Raw = await multicall(
    liquidityNftStakingDexV2Abi,
    callsNFTStakedBalanceLiquidityV2,
  );
  const stakedBalanceNftPoolLiquidityV2 = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length === 42,
    )
    .map((pool, index) => ({
      ...pool,
      stakedBalance: formatBigNumber(
        new BigNumber((listBalanceStakedNftLiquidityV2Raw && listBalanceStakedNftLiquidityV2Raw[index][0]._hex) || 0),
      ),
    }));

  const stakedBalanceNftPoolLiquidity = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length !== 42,
    )
    .map((pool, index) => ({
      ...pool,
      stakedBalance: formatBigNumber(
        new BigNumber((listBalanceStakedNftLiquidityRaw && listBalanceStakedNftLiquidityRaw[index][0]._hex) || 0),
      ),
    }));

  const stakedBalancePoolEarnPosi = earningPosiTokenPools.map((pool, index) => ({
    ...pool,
    stakedBalance: new BigNumber(userInfo && userInfo[index] && userInfo[index][0]._hex).toJSON(),
  }));

  const stakedBalancePoolEarnToken = earning3rdTokenPools.map((pool, index) => ({
    ...pool,
    stakedBalance: new BigNumber((userInfoEarn3rdToken && userInfoEarn3rdToken[index][0]._hex) || 0).toJSON(),
  }));
  return [
    ...stakedBalancePoolEarnPosi,
    ...stakedBalancePoolEarnToken,
    ...stakedBalanceNftPool,
    ...stakedBalanceNftPoolLiquidity,
    ...stakedBalanceNftPoolLiquidityV2,
  ];
};

export const fetchUserPendingRewards = async (account: any) => {
  const callsEarnPosiPool = earningPosiTokenPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingPosition',
    params: [p.sousId, account],
  }));

  const callsEarn3rdPool = earning3rdTokenPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }));

  const callsNFTEarned = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'earned',
      params: [account],
    }));
  const callsNFTLockedReward = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: '_rewardLockedUp',
      params: [account],
    }));

  const callsNFTEarnedLiquidityNft = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length !== 42,
    )
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'pendingPosition',
      params: [getAddress(p?.poolKey), account],
    }));

  const callsNFTEarnedLiquidityNftDexV2 = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length === 42,
    )
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'pendingPosition',
      params: [getAddress(p?.poolKey), account],
    }));

  const earnedNft = await multicall(generalNFTRewardABI, callsNFTEarned);
  const rewardLockedUpNft = await multicall(generalNFTRewardABI, callsNFTLockedReward);
  const earnedLiquidityNftRaw = await multicall(liquidityNftStakingAbi, callsNFTEarnedLiquidityNft);
  const earnedLiquidityNftDexV2Raw = await multicall(liquidityNftStakingDexV2Abi, callsNFTEarnedLiquidityNftDexV2);

  const totalRewardList = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((pool, index) => ({
      ...pool,
      pendingReward: formatBigNumber(
        new BigNumber((earnedNft && earnedNft[index] && earnedNft[index][0]._hex) || 0).plus(
          new BigNumber((rewardLockedUpNft && rewardLockedUpNft[index] && rewardLockedUpNft[index][0]._hex) || 0),
        ),
      ),
    }));
  const totalRewardLiquidityNft = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length !== 42,
    )
    .map((pool, index) => ({
      ...pool,
      pendingReward: formatBigNumber(
        new BigNumber(
          (earnedLiquidityNftRaw && earnedLiquidityNftRaw[index] && earnedLiquidityNftRaw[index][0]._hex) || 0,
        ),
      ),
    }));
  const totalRewardLiquidityNftDexV2 = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length === 42,
    )
    .map((pool, index) => ({
      ...pool,
      pendingReward: formatBigNumber(
        new BigNumber(
          (earnedLiquidityNftDexV2Raw &&
            earnedLiquidityNftDexV2Raw[index] &&
            earnedLiquidityNftDexV2Raw[index][0]._hex) ||
            0,
        ),
      ),
    }));

  const pendingRewards = await multicall(sousChefABI, callsEarnPosiPool);
  const pendingRewards3rdToken = await multicall(PoolEarn3rdTokenABI, callsEarn3rdPool);

  const earningPosiTokenPoolsPending = earningPosiTokenPools.map((pool, index) => ({
    ...pool,
    pendingReward: formatBigNumber(new BigNumber((pendingRewards && pendingRewards[index]) || 0)),
  }));

  const earning3rdTokenPoolsPending = earning3rdTokenPools.map((pool, index) => ({
    ...pool,
    pendingReward: formatBigNumber(new BigNumber((pendingRewards3rdToken && pendingRewards3rdToken[index]) || 0)),
  }));

  return [
    ...earningPosiTokenPoolsPending,
    ...earning3rdTokenPoolsPending,
    ...totalRewardList,
    ...totalRewardLiquidityNft,
    ...totalRewardLiquidityNftDexV2,
  ];
};

export const getListCardPending = async (account: string) => {
  // NFT
  const callsNFTStaked = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'getPlayerIds',
      params: [account],
    }));
  const callsNFTCanStake = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((p) => ({
      address: getAddress(p.stakingToken.address),
      name: 'tokensOfOwner',
      params: [account],
    }));

  const listCardStakedIdRaw = await multicall(generalNFTRewardABI, callsNFTStaked);
  const listCardCanStakeIdRaw = await multicall(posiNFTABI, callsNFTCanStake);

  const listCardNftWithPool = await Promise.all(
    nonBnbAndNFTPools
      .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
      .map(async (p: PoolConfig, index: number) => {
        let listCardStakedId = [];
        let listNFT = [];
        let listCanStake = [];

        const newRes = Array.from(
          (listCardStakedIdRaw && listCardStakedIdRaw[index] && listCardStakedIdRaw[index].gegoId) || [],
        );
        newRes.shift();
        if (newRes.length > 0) {
          const newResFromBN: any = newRes.map((a: any) => new BigNumber(a._hex).toString());
          listCardStakedId = newResFromBN;
        }
        if (listCardCanStakeIdRaw && listCardCanStakeIdRaw[index] && listCardCanStakeIdRaw[index][0]) {
          const list = listCardCanStakeIdRaw[index][0];
          listNFT = list.map((a: any) => new BigNumber(a._hex).toString());
        }
        const listNFTAfterSort = listNFT.filter((a: any) => !listCardStakedId.includes(a));

        if (listNFTAfterSort.length > 0) {
          try {
            let listNFTWithInfoRaw = [];
            if (p?.stakingToken?.symbol === 'NFT') {
              const callsGego = listNFTAfterSort.map((x: string) => ({
                address: getPOSINFTFActoryAddress(),
                name: 'getGego',
                params: [x],
              }));

              listNFTWithInfoRaw = await multicall(posiNFTFactoryABI, callsGego);
            } else {
              const callsGego = listNFTAfterSort.map((x: string) => ({
                address: getAddress(p?.stakingToken?.address),
                name: 'liquidityInfo',
                params: [x],
              }));

              listNFTWithInfoRaw = await multicall(exchangeLiquidityAbi, callsGego);
            }

            let listNFTWithInfo = [];
            if (listNFTWithInfoRaw && listNFTWithInfoRaw.length > 0) {
              listNFTWithInfo = listNFTWithInfoRaw.map((z: any, index: number) => {
                const newObject: any = {};
                Object.keys(z).forEach((prop: string) => {
                  if (z[prop]?._isBigNumber) {
                    if (prop === 'amount' || prop === 'quoteDeposited') {
                      newObject[prop] = formatBigNumber(new BigNumber(z[prop]._hex));
                    } else {
                      newObject[prop] = new BigNumber(z[prop]._hex).toString();
                    }
                  }
                  if (prop === 'poolId') {
                    newObject[prop] = z[prop];
                  }
                });
                return {
                  tokenId: listNFTAfterSort[index] || 0,
                  ...newObject,
                };
              });
            }
            if (listNFTWithInfo.length > 0) {
              const newListNFTWithInfo = listNFTWithInfo.map((z: any) => {
                const valueItem = listItemNfts.find((q: any) => Number(q?.id) === Number(z.grade));
                return { ...z, ...valueItem };
              });
              if (p?.stakingToken?.symbol !== 'NFT') {
                const currentPoolRewardAddress = getAddress(p?.contractAddress);
                const listPoolIncludeByPoolReward = poolsExchangeConfig.filter(
                  (a) => a.rewardPoolAddress === currentPoolRewardAddress,
                );
                const listPoolKey = listPoolIncludeByPoolReward.map((v) => getAddress(v?.poolKey));
                const listNftInCurrentPoolReward = newListNFTWithInfo.filter((z) => listPoolKey.includes(z?.poolId));
                listCanStake = listNftInCurrentPoolReward;
              } else {
                listCanStake = newListNFTWithInfo;
              }
            }
          } catch (err: any) {}
        }
        return {
          ...p,
          listCardRemaining: listCanStake,
        };
      }),
  );

  const callsNFTCanStakeLiquidityNft = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT)
    .map((p) => ({
      address: getAddress(p.stakingToken.address),
      name: 'tokensOfOwner',
      params: [account],
    }));

  const listCardCanStakeIdLiquidityNftRaw = await multicall(liquidityNftManagerAbi, callsNFTCanStakeLiquidityNft);
  const listCardNftWithPoolLiquidityNft = await Promise.all(
    nonBnbAndNFTPools
      .filter((pool) => pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT)
      .map(async (p: PoolConfig, index: number) => {
        let listNFT = [];
        let listCanStake = [];

        if (
          listCardCanStakeIdLiquidityNftRaw &&
          listCardCanStakeIdLiquidityNftRaw[index] &&
          listCardCanStakeIdLiquidityNftRaw[index][0]
        ) {
          const list = listCardCanStakeIdLiquidityNftRaw[index][0];
          listNFT = list.map((a: any) => new BigNumber(a._hex).toString());
        }
        const listNFTAfterSort = listNFT;
        if (listNFTAfterSort.length > 0) {
          try {
            let listNFTWithInfoRaw = [];
            const isDexV2Farm = p?.poolKey && getAddress(p?.poolKey).length == 42;
            const callsGego = listNFTAfterSort.map((x: string) => ({
              address: getAddress(p?.liquidityPoolAddress),
              name: isDexV2Farm ? 'liquidity' : 'liquidityInfo',
              params: [x],
            }));

            listNFTWithInfoRaw = await multicall(
              isDexV2Farm ? PositionNondisperseLiquidityAbi : exchangeLiquidityAbi,
              callsGego,
            );

            const newListNft = listNFTWithInfoRaw.map((a, index) => {
              return {
                ...a,
                tokenId: listNFTAfterSort[index],
                isCompare: isDexV2Farm
                  ? a?.pool.toLowerCase() === getAddress(p?.poolKey).toLowerCase()
                  : a?.poolId.toLowerCase() === getAddress(p?.poolKey).toLowerCase(),
              };
            });
            let listNFTWithInfo = [];
            if (newListNft && newListNft.length > 0) {
              listNFTWithInfo = newListNft
                .filter((x) => x?.isCompare)
                .map((z: any) => {
                  const newObject: any = {};
                  Object.keys(z).forEach((prop: string) => {
                    if (z[prop]?._isBigNumber) {
                      if (prop === 'amount' || prop === 'quoteDeposited' || prop === 'power') {
                        if (prop === 'power') {
                          newObject['liquidity'] = formatBigNumber(new BigNumber(z[prop]._hex));
                        } else {
                          newObject[prop] = formatBigNumber(new BigNumber(z[prop]._hex));
                        }
                      } else {
                        newObject[prop] = new BigNumber(z[prop]._hex).toString();
                      }
                    }
                    if (prop === 'poolId') {
                      newObject[prop] = z[prop];
                    }
                  });
                  return {
                    tokenId: z?.tokenId,
                    ...newObject,
                  };
                });
            }
            if (listNFTWithInfo.length > 0) {
              listCanStake = listNFTWithInfo;
            }
          } catch (err: any) {}
          return {
            ...p,
            listCardRemaining: listCanStake,
          };
        }
        return {
          ...p,
          listCardRemaining: [],
        };
      }),
  );
  const newList = listCardNftWithPool.length
    ? listCardNftWithPool.concat(listCardNftWithPoolLiquidityNft)
    : listCardNftWithPoolLiquidityNft;
  store.dispatch(updateNftListCard(newList, 'listCardRemaining'));
};

export const getListCardStaked = async (account: string) => {
  const callsNFTStaked = nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'getPlayerIds',
      params: [account],
    }));
  const listCardStakedIdRaw = await multicall(generalNFTRewardABI, callsNFTStaked);

  const listCardNftWithPool = await Promise.all(
    nonBnbAndNFTPools
      .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
      .map(async (p: PoolConfig, index: number) => {
        let listCardStakedId = [];
        let listStaked = [];

        const newRes = Array.from(listCardStakedIdRaw[index].gegoId);
        newRes.shift();
        if (newRes.length > 0) {
          const newResFromBN: any = newRes.map((a: any) => new BigNumber(a._hex).toString());
          listCardStakedId = newResFromBN;
        }
        const listNFTAfterSort = listCardStakedId;

        if (listNFTAfterSort.length > 0) {
          try {
            let listNFTWithInfoRaw = [];
            if (p?.stakingToken?.symbol === 'NFT') {
              const callsGego = listNFTAfterSort.map((x: string) => ({
                address: getPOSINFTFActoryAddress(),
                name: 'getGego',
                params: [x],
              }));

              listNFTWithInfoRaw = await multicall(posiNFTFactoryABI, callsGego);
            } else {
              const callsGego = listNFTAfterSort.map((x: string) => ({
                address: getAddress(p?.liquidityPoolAddress),
                name: 'liquidityInfo',
                params: [x],
              }));

              listNFTWithInfoRaw = await multicall(exchangeLiquidityAbi, callsGego);
            }

            let listNFTWithInfo = [];
            if (listNFTWithInfoRaw && listNFTWithInfoRaw.length > 0) {
              listNFTWithInfo = listNFTWithInfoRaw.map((z: any, index: number) => {
                const newObject: any = {};
                Object.keys(z).forEach((prop: string) => {
                  if (z[prop]?._isBigNumber) {
                    if (prop === 'amount' || prop === 'quoteDeposited') {
                      newObject[prop] = formatBigNumber(new BigNumber(z[prop]._hex));
                    } else {
                      newObject[prop] = new BigNumber(z[prop]._hex).toString();
                    }
                  }
                });
                return {
                  tokenId: listNFTAfterSort[index] || 0,
                  ...newObject,
                };
              });
            }
            if (listNFTWithInfo.length > 0) {
              const newListNFTWithInfo = listNFTWithInfo.map((z: any) => {
                const valueItem = listItemNfts.find((q: any) => Number(q?.id) === Number(z.grade));
                return { ...z, ...valueItem };
              });
              listStaked = newListNFTWithInfo;
            }
          } catch (err: any) {}
          return {
            ...p,
            listCardStaked: listStaked,
          };
        } else {
          return {
            ...p,
            listCardStaked: [],
          };
        }
      }),
  );

  const callsNFTStakedLiquidityNft = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length !== 42,
    )
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'getPlayerIds',
      params: [account, getAddress(p?.poolKey)],
    }));

  const listCardStakedIdLiquidityNftRaw = await multicall(liquidityNftStakingAbi, callsNFTStakedLiquidityNft);

  const listCardNftWithPoolLiquidityNft = await Promise.all(
    nonBnbAndNFTPools
      .filter(
        (pool) =>
          pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
          pool?.poolKey &&
          getAddress(pool?.poolKey).length !== 42,
      )
      .map(async (p: PoolConfig, index: number) => {
        let listCardStakedId = [];
        let listStaked = [];

        const newRes = listCardStakedIdLiquidityNftRaw
          ? Array.from(
              listCardStakedIdLiquidityNftRaw &&
                listCardStakedIdLiquidityNftRaw[index] &&
                listCardStakedIdLiquidityNftRaw[index][0],
            )
          : [];
        newRes.shift();
        if (newRes.length > 0) {
          const newResFromBN: any = newRes.map((a: any) => new BigNumber(a._hex).toString());
          listCardStakedId = newResFromBN;
        }
        const listNFTAfterSort = listCardStakedId;

        if (listNFTAfterSort.length > 0) {
          try {
            const callsGego = listNFTAfterSort.map((x: string) => ({
              address: getAddress(p?.liquidityPoolAddress),
              name: 'liquidityInfo',
              params: [x],
            }));

            const listNFTWithInfoRaw = await multicall(exchangeLiquidityAbi, callsGego);

            let listNFTWithInfo = [];
            if (listNFTWithInfoRaw && listNFTWithInfoRaw.length > 0) {
              listNFTWithInfo = listNFTWithInfoRaw.map((z: any, index: number) => {
                const newObject: any = {};
                Object.keys(z).forEach((prop: string) => {
                  if (z[prop]?._isBigNumber) {
                    if (prop === 'amount' || prop === 'quoteDeposited') {
                      newObject[prop] = formatBigNumber(new BigNumber(z[prop]._hex));
                    } else {
                      newObject[prop] = new BigNumber(z[prop]._hex).toString();
                    }
                  }
                });
                return {
                  tokenId: listNFTAfterSort[index] || 0,
                  ...newObject,
                };
              });
            }
            if (listNFTWithInfo.length > 0) {
              listStaked = listNFTWithInfo;
            }
          } catch (err: any) {}
          return {
            ...p,
            listCardStaked: listStaked,
          };
        } else {
          return {
            ...p,
            listCardStaked: [],
          };
        }
      }),
  );

  const callsNFTStakedLiquidityNftDexV2 = nonBnbAndNFTPools
    .filter(
      (pool) =>
        pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
        pool?.poolKey &&
        getAddress(pool?.poolKey).length === 42,
    )
    .map((p) => ({
      address: getAddress(p.contractAddress),
      name: 'getPlayerIds',
      params: [account, getAddress(p?.poolKey)],
    }));

  const listCardStakedIdLiquidityNftDexV2Raw = await multicall(
    liquidityNftStakingDexV2Abi,
    callsNFTStakedLiquidityNftDexV2,
  );

  const listCardNftWithPoolLiquidityNftDexV2 = await Promise.all(
    nonBnbAndNFTPools
      .filter(
        (pool) =>
          pool?.stakingToken?.symbol === LIQUIDITY_NFT.liquidityNFT &&
          pool?.poolKey &&
          getAddress(pool?.poolKey).length === 42,
      )
      .map(async (p: PoolConfig, index: number) => {
        let listCardStakedId = [];
        let listStaked = [];

        const newRes = listCardStakedIdLiquidityNftDexV2Raw
          ? Array.from(
              listCardStakedIdLiquidityNftDexV2Raw &&
                listCardStakedIdLiquidityNftDexV2Raw[index] &&
                listCardStakedIdLiquidityNftDexV2Raw[index][0],
            )
          : [];
        newRes.shift();
        if (newRes.length > 0) {
          const newResFromBN: any = newRes.map((a: any) => new BigNumber(a._hex).toString());
          listCardStakedId = newResFromBN;
        }
        const listNFTAfterSortNew = listCardStakedId;
        if (listNFTAfterSortNew.length > 0) {
          try {
            const callsGego = listNFTAfterSortNew.map((x: string) => ({
              address: getAddress(p?.liquidityPoolAddress),
              name: 'liquidity',
              params: [x],
            }));

            const listNFTWithInfoDEXRaw = await multicall(PositionNondisperseLiquidityAbi, callsGego);
            let listNFTWithInfo = [];
            if (listNFTWithInfoDEXRaw && listNFTWithInfoDEXRaw.length > 0) {
              listNFTWithInfo = listNFTWithInfoDEXRaw.map((z: any, index: number) => {
                const newObject: any = {};
                Object.keys(z).forEach((prop: string) => {
                  if (z[prop]?._isBigNumber) {
                    if (prop === 'amount' || prop === 'quoteDeposited' || prop === 'power') {
                      if (prop === 'power') {
                        newObject['liquidity'] = formatBigNumber(new BigNumber(z[prop]._hex));
                      } else {
                        newObject[prop] = formatBigNumber(new BigNumber(z[prop]._hex));
                      }
                    } else {
                      newObject[prop] = new BigNumber(z[prop]._hex).toString();
                    }
                  }
                });
                return {
                  tokenId: listNFTAfterSortNew[index] || 0,
                  ...newObject,
                };
              });
            }
            if (listNFTWithInfo.length > 0) {
              listStaked = listNFTWithInfo;
            }
          } catch (err: any) {}
          return {
            ...p,
            listCardStaked: listStaked,
          };
        } else {
          return {
            ...p,
            listCardStaked: [],
          };
        }
      }),
  );

  nonBnbAndNFTPools
    .filter((pool) => pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT)
    .forEach((pool, index) => {
      if (pool?.isFinished) {
        if (
          listCardNftWithPool &&
          listCardNftWithPool[index] &&
          listCardNftWithPool[index].listCardStaked &&
          listCardNftWithPool[index].listCardStaked.length > 1
        ) {
          localStorage.setItem('isOldStaked', '1');
        } else {
          localStorage.setItem('isOldStaked', '0');
        }
      }
    });

  const newList = listCardNftWithPool.length
    ? listCardNftWithPool.concat(listCardNftWithPoolLiquidityNft).concat(listCardNftWithPoolLiquidityNftDexV2)
    : (listCardNftWithPoolLiquidityNft || []).concat(listCardNftWithPoolLiquidityNftDexV2);
  store.dispatch(updateNftListCard(newList, 'listCardStaked'));
};

export const fetchUserBalance = async (stakingToken: string, account: any) => {
  if (!!stakingToken) {
    const [tokenBalancesRaw] = await multicall(erc20ABI, [
      {
        address: stakingToken,
        name: 'balanceOf',
        params: [account],
      },
    ]);
    return new BigNumber(tokenBalancesRaw || 0).toJSON();
  } else {
    // BNB pools
    const bnbBalance = await web3.eth.getBalance(account);

    return bnbBalance;
  }
};
