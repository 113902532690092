import { createSlice } from '@reduxjs/toolkit';
import { ReferralState } from '../types';
import {
  getDataBonusHistory,
  getDataFarmHistory,
  getDataPoolHistory,
  getDataReferralHistory,
  getGeneral,
  getSummarys,
} from './fetchDataRefferal';
const initialState: ReferralState = {
  farmData: [],
  poolData: [],
  referralData: [],
  bonusData: null,
  summaryData: {},
  generalData: {},
  isLoadingRequest: false,
  showDetail: localStorage.getItem('show_detail_referral') || '0',
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    setGeneralData: (state, action) => {
      const data = state;
      data.generalData = action.payload;
      return data;
    },
    setFarmReferralState: (state, action) => {
      const data = state;
      data.farmData = action.payload;
      return data;
    },
    setLoadingRequest: (state, action) => {
      const data = state;
      data.isLoadingRequest = action.payload;
    },
    setPoolReferralState: (state, action) => {
      const data = state;
      data.poolData = action.payload;
      return data;
    },
    setReferralState: (state, action) => {
      const data = state;
      data.referralData = action.payload;
      return data;
    },
    setSummaryData: (state, action) => {
      const data = state;
      data.summaryData = action?.payload;
    },
    setBonusData: (state, action) => {
      const data = state;
      data.bonusData = action?.payload;
    },
    setShowDetail: (state) => {
      const data = state;
      const currentStateShowDetail = localStorage.getItem('show_detail_referral') || '0';
      const isShowDetail = (Number(currentStateShowDetail) + 1) % 2;
      data.showDetail = isShowDetail.toString();
      localStorage.setItem('show_detail_referral', isShowDetail.toString());
      return data;
    },
  },
});

export const {
  setFarmReferralState,
  setPoolReferralState,
  setReferralState,
  setSummaryData,
  setLoadingRequest,
  setBonusData,
  setGeneralData,
  setShowDetail,
} = referralSlice.actions;

export const fetDataRefferalHistory = () => async () => {
  // const listDataFarmHistory = await getDataFarmHistory;
  // const listDataPoolHistory = await getDataPoolHistory;
  // const listDataReferralHistory = await getDataReferralHistory;
  // listDataFarmHistory?.length
  //   ? dispatch(setFarmReferralState(listDataFarmHistory))
  //   : dispatch(setFarmReferralState([]));
  // listDataPoolHistory?.length
  //   ? dispatch(setPoolReferralState(listDataPoolHistory))
  //   : dispatch(setPoolReferralState([]));
  // listDataReferralHistory?.length ? dispatch(setReferralState(listDataReferralHistory)) : dispatch(setReferralState([]));
};

export const fetchListReferral = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoadingRequest(true));
  const listDataReferralHistory = await getDataReferralHistory(queryString);
  dispatch(setReferralState(listDataReferralHistory ?? []));
  dispatch(setLoadingRequest(false));
};

export const fetchListFarm = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoadingRequest(true));
  const listDataFarmlHistory = await getDataFarmHistory(queryString);
  dispatch(setFarmReferralState(listDataFarmlHistory ?? []));
  dispatch(setLoadingRequest(false));
};

export const fetchListBonus = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoadingRequest(true));
  const listDataBonusHistory = await getDataBonusHistory(queryString);
  dispatch(setBonusData(listDataBonusHistory ?? []));
  dispatch(setLoadingRequest(false));
};

export const fetchListPool = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoadingRequest(true));
  const listDataPoollHistory = await getDataPoolHistory(queryString);
  dispatch(setPoolReferralState(listDataPoollHistory ?? []));
  dispatch(setLoadingRequest(false));
};

export const fetchSummaryData = (account: string) => async (dispatch: any) => {
  const res = await getSummarys(account);
  dispatch(setSummaryData(res ?? {}));
};

export const fetchGeneralData = (account: string) => async (dispatch: any) => {
  const res = await getGeneral(account);
  dispatch(setGeneralData(res ?? {}));
};

export default referralSlice.reducer;
