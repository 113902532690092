import {
  ChakraProvider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import themeContext from '../../configs/contexts/ThemeContext';
import LoaderJson from '../../constants/loading.json';

interface IPendingModal {
  isOpenModal: boolean;
  onDismiss?: (val?: any) => void;
  titleContent?: any;
  description?: any;
}

const PendingModal: React.FC<IPendingModal> = ({ isOpenModal, onDismiss = () => null, titleContent, description }) => {
  const { t } = useTranslation('common');
  return (
    <ChakraProvider theme={themeContext}>
      <Modal size={'md'} isCentered blockScrollOnMount={true} isOpen={isOpenModal} onClose={onDismiss}>
        <ModalOverlay width="100%" height="100%" />
        <ModalContent zIndex="9999" background={'gray.800'} borderRadius="24px" py={0} px={0}>
          <ModalCloseButton align="center" color={'gray.500'} />
          <ModalBody py="0px">
            <Flex flexDirection={'column'} pt={'32px'} justifyContent={'center'}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: LoaderJson,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                width={'100px'}
                height={'100px'}
              />
              <Flex textAlign={'center'} mt="16px" flexDirection={'column'} justifyContent={'center'}>
                <Text color={'white'} fontSize={'22px'} fontWeight={600}>
                  {titleContent || t('Pending')}
                </Text>
                <Text color="gray.500" mt={description ? '8px' : '0px'} fontSize="13px">
                  {description || ''}
                </Text>
              </Flex>
            </Flex>
            <Flex mb={'24px'} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default PendingModal;
