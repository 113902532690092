import { Flex, Text, Button } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundPage: React.FC<{ urlCustom?: string }> = ({ urlCustom }) => {
  const history = useHistory();

  const handleRedirect = () => {
    if (urlCustom) {
      history.push(urlCustom);
    } else {
      history.push('/');
    }
  };

  return (
    <Flex alignItems="center" justifyContent="center" minHeight={'90vh'} w="100%">
      <Flex textAlign="center" justifyContent="center" flexDirection={'column'}>
        <Text
          fontWeight="600"
          fontSize="110px"
          bg="linear-gradient(270deg, #1AC486 -55.17%, #00C9FF 175.12%)"
          bgClip={'text'}
        >
          404
        </Text>
        <Text color="white" fontWeight="600" fontSize="40px" lineHeight="60px">
          Oops! Page not found
        </Text>
        <Text mt="8px" color="gray.500" fontWeight="400" fontSize="16px" lineHeight="24px">
          The page you are looking for does not exist. It might have been moved or deleted.
        </Text>
        <Button
          mx="auto"
          _hover={{ bg: 'teal.150' }}
          _active={{ bg: 'teal.150' }}
          borderRadius="12px"
          mt="12px"
          bg="teal.150"
          w="fit-content"
          color="white"
          fontSize="16px"
          onClick={handleRedirect}
        >
          Go to homepage
        </Button>
      </Flex>
    </Flex>
  );
};

export default NotFoundPage;
