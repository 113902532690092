/* eslint-disable no-unused-vars */
import {FC} from 'react';
import {SvgProps} from './icons/type';

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

// eslint-disable-next-line no-unused-vars
export type Login = (connectorId: ConnectorNames) => void;

export interface Config {
  title: string;
  icon: FC<SvgProps>;
  connectorId: ConnectorNames;
  priority: number;
}
export type Handler = () => void;
