import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import NewHeader from '../components/Menu/NewMenu';
import { dragElement } from '../utils/common';

interface BaseLayoutProps {
  height?: string | string[];
  paddingTop?: any;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children, paddingTop }) => {
  const [isSmallThan480] = useMediaQuery('(max-width: 480px ) and (min-width: 0px )');

  const menu = useMemo(() => {
    return <NewHeader />;
  }, []);
  const element = document && document.getElementById('birthday-float-icon-base-layout');
  if (element) {
    dragElement(element);
  }
  return (
    <>
      <Box bg={'#090a0e'} minH="100vh" w="100%">
        {menu}
        <Flex h="max-content" width={'100%'} mt={isSmallThan480 && '34px'} flexDirection={'column'}>
          {children}
          {/* <Flex width={'100%'} flexDirection={'column'}>
              <Footer />
            </Flex> */}
        </Flex>
      </Box>
    </>
  );
};

export default BaseLayout;
