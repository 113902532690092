import { Box, Flex, Image, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { LIST_LANGUAGE } from '../../configs';
import { useOnClickOutside } from '../../hooks/useOutside';

const Translate: React.FC = () => {
  const { i18n } = useTranslation();
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  };

  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: 'absolute',
    placement: 'bottom',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      return target && !tooltipRef?.contains(target) && setIsOpen(false);
    };

    targetRef?.addEventListener('mouseenter', showDropdownMenu);
    targetRef?.addEventListener('mouseleave', hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener('mouseenter', showDropdownMenu);
      targetRef?.removeEventListener('mouseleave', hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  useOnClickOutside(
    {
      current: targetRef,
    },
    () => {
      setIsOpen(false);
    },
  );

  const bgTranslate = useColorModeValue('#fff', '#1F2835');
  const bgHoverTranslate = useColorModeValue('gray.100', 'gray.700');
  const whiteToGray400 = useColorModeValue('gray.400', 'white');

  return (
    <Box ref={setTargetRef}>
      <Flex
        px="9px"
        onPointerDown={() => {
          setIsOpen((s) => !s);
        }}
        className="hover-icon"
        h="64px"
        lineHeight="64px"
        align="center"
        justifyItems="center"
      >
        <Image
          cursor="pointer"
          className={'arrow-child-icon-hover'}
          src={'/img/language.svg'}
          width={'20px'}
          h={'20px'}
        />
        <Image
          cursor="pointer"
          className={'arrow-child-icon'}
          src={'/img/language-active.svg'}
          width={'20px'}
          h={'20px'}
        />
      </Flex>

      {isOpen && (
        <Flex
          borderColor="gray.700"
          borderRadius="24px"
          maxW="135px"
          bg={bgTranslate}
          direction="column"
          style={styles.popper}
          ref={setTooltipRef}
          {...attributes.popper}
        >
          {LIST_LANGUAGE.map((item) => (
            <Flex
              w="100%"
              key={item?.value}
              m="0px"
              cursor={'pointer'}
              p="6px"
              textAlign="center"
              justifyContent="center"
              onClick={() => handleChangeLanguage(item?.value)}
              color={i18n.language === item?.value ? 'teal.150' : whiteToGray400}
              _focus={{ background: 'gray.700' }}
              _hover={{ bg: bgHoverTranslate }}
              _selected={{ bg: bgTranslate }}
              _active={{ bg: bgTranslate }}
            >
              {item?.label}
            </Flex>
          ))}
        </Flex>
      )}
    </Box>
  );
};
export default Translate;
