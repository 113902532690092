import { Box, Flex, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import FallingSnow from '../components/FallingSnow';
import Footer from '../components/Footer';
import NewHeader from '../components/Menu/NewMenu';
import { dragElement } from '../utils/common';

interface LandingLayoutProps {
  paddingTop?: any;
  isHiddenFooter?: boolean;
}

const LandingLayout: React.FC<LandingLayoutProps> = ({ children, paddingTop, isHiddenFooter }) => {
  const [isBiggerThan1920] = useMediaQuery(' (min-width: 1920px )');

  const menu = useMemo(() => {
    return <NewHeader />;
  }, []);

  const bgColor = useColorModeValue('#fff', '#090a0e');

  const boxShadow = useColorModeValue('0px -13px 44px rgba(0, 0, 0, 0.04)', '0px -13px 44px rgba(0, 0, 0, 0.16)');
  const element = document && document.getElementById('birthday-float-icon-landing-layout');
  if (element) {
    dragElement(element);
  }
  return (
    <>
      <Box bg={bgColor} minH="100vh" w={'100%'}>
        {menu}
        {/* <FallingSnow/> */}
        <Flex
          w={'100%'}
          justifyContent={'center'}
          flexDirection={'column'}
          position={'relative'}
          minH="100vh"
          h="max-content"
          overflow="hidden"
        >
          {/* right */}
          <Box
            position="absolute"
            top={'120px'}
            display={'block'}
            right={isBiggerThan1920 ? '-544px' : 'calc(100% - 1920px - 544px)'}
            h={'1344px'}
            w={'1344px'}
            bg="radial-gradient(44.65% 44.65% at 48.1% 48.7%, rgba(202, 88, 255, 0.16) 0%, rgba(46, 191, 145, 0) 100%)"
            opacity={0.6}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'100px'}
            display={'block'}
            right={isBiggerThan1920 ? '-450px' : 'calc(100% - 1920px - 450px)'}
            h={'2281px'}
            w={'780px'}
            bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.02) 34.74%, rgba(255, 255, 255, 0) 52.65%)"
            boxShadow={boxShadow}
            transform={'matrix(0.71, 0.71, 0.71, -0.71, 0, 0)'}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'1600px'}
            display={'block'}
            right={isBiggerThan1920 ? '-577px' : 'calc(100% - 1920px - 577px)'}
            h={'1152px'}
            w={'1152px'}
            bg="radial-gradient(39.19% 39.19% at 52.26% 46.35%, rgba(15, 194, 151, 0.24) 1.46%, rgba(13, 114, 103, 0) 95.86%)"
            opacity={0.4}
            zIndex={0}
            transform={'matrix(-1, 0, 0, 1, 0, 0)'}
          />
          <Box
            position="absolute"
            top={'2000px'}
            display={'block'}
            right={isBiggerThan1920 ? '-1000px' : 'calc(100% - 1920px - 1000px)'}
            h={'2017px'}
            w={'780px'}
            bg="linear-gradient(171.84deg, rgba(255, 255, 255, 0) 0.2%, rgba(255, 255, 255, 0.02) 33.72%)"
            transform={'rotate(-135deg)'}
            boxShadow={boxShadow}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'3864px'}
            display={'block'}
            right={isBiggerThan1920 ? '-577px' : 'calc(100% - 1920px - 577px)'}
            h={'1344px'}
            w={'1344px'}
            bg="radial-gradient(44.65% 44.65% at 48.1% 48.7%, rgba(202, 88, 255, 0.16) 0%, rgba(46, 191, 145, 0) 100%)"
            opacity={0.6}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'5618px'}
            display={'block'}
            right={isBiggerThan1920 ? '-544px' : 'calc(100% - 1920px - 544px)'}
            h={'1344px'}
            w={'1344px'}
            bg="radial-gradient(44.65% 44.65% at 48.1% 48.7%, rgba(202, 88, 255, 0.16) 0%, rgba(46, 191, 145, 0) 100%)"
            opacity={0.6}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'5700px'}
            display={'block'}
            right={isBiggerThan1920 ? '-450px' : 'calc(100% - 1920px - 450px)'}
            h={'2281px'}
            w={'780px'}
            bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.02) 34.74%, rgba(255, 255, 255, 0) 52.65%)"
            boxShadow={boxShadow}
            transform={'matrix(0.71, 0.71, 0.71, -0.71, 0, 0)'}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'7349px'}
            display={'block'}
            right={isBiggerThan1920 ? '-577px' : 'calc(100% - 1920px - 577px)'}
            h={'1152px'}
            w={'1152px'}
            bg="radial-gradient(39.19% 39.19% at 52.26% 46.35%, rgba(15, 194, 151, 0.24) 1.46%, rgba(13, 114, 103, 0) 95.86%)"
            opacity={0.4}
            zIndex={0}
            transform={'matrix(-1, 0, 0, 1, 0, 0)'}
          />
          {/* end right */}
          {/* left deco */}
          <Box
            position="absolute"
            top={'320px'}
            display={'block'}
            left={'-610px'}
            h={'1152px'}
            w={'1152px'}
            bg="radial-gradient(39.19% 39.19% at 52.26% 46.35%, rgba(13, 218, 169, 0.26) 1.46%, rgba(17, 152, 138, 0) 95.86%)"
            opacity={0.4}
            zIndex={0}
            transform={'matrix(-1, 0, 0, 1, 0, 0)'}
          />
          <Box
            position="absolute"
            top={'-200px'}
            display={'block'}
            left={'-1000px'}
            h={'2017px'}
            w={'780px'}
            boxShadow={boxShadow}
            bg="linear-gradient(171.84deg, rgba(255, 255, 255, 0) 0.2%, rgba(255, 255, 255, 0.02) 33.72%)"
            transform={'matrix(0.71, -0.71, -0.71, -0.71, 0, 0)'}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'2216px'}
            display={'block'}
            left={'-891px'}
            h={'1344px'}
            w={'1344px'}
            bg="radial-gradient(29.35% 29.35% at 63.1% 53.39%, #BF37FF 0%, rgba(185, 36, 255, 0.2) 0.01%, rgba(211, 118, 255, 0) 93.75%)"
            opacity={0.4}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'2220px'}
            display={'block'}
            left={'-1500px'}
            h={'2042px'}
            w={'1561px'}
            bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.02) 34.74%, rgba(255, 255, 255, 0) 52.65%)"
            transform={'rotate(135deg)'}
            boxShadow={boxShadow}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'3749px'}
            display={'block'}
            left={'-757px'}
            h={'1152px'}
            w={'1152px'}
            bg="radial-gradient(39.19% 39.19% at 52.26% 46.35%, rgba(13, 218, 169, 0.28) 1.46%, rgba(17, 152, 138, 0) 95.86%)"
            opacity={0.4}
            transform="matrix(-1, 0, 0, 1, 0, 0)"
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'3249px'}
            display={'block'}
            left={'-950px'}
            h={'2017px'}
            w={'780px'}
            boxShadow={boxShadow}
            bg="linear-gradient(171.84deg, rgba(255, 255, 255, 0) 0.2%, rgba(255, 255, 255, 0.02) 33.72%)"
            transform={'matrix(0.71, -0.71, -0.71, -0.71, 0, 0)'}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'5818px'}
            display={'block'}
            left={'-757px'}
            h={'1152px'}
            w={'1152px'}
            bg="radial-gradient(39.19% 39.19% at 52.26% 46.35%, rgba(13, 218, 169, 0.28) 1.46%, rgba(17, 152, 138, 0) 95.86%)"
            opacity={0.4}
            transform="matrix(-1, 0, 0, 1, 0, 0)"
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'5318px'}
            display={'block'}
            left={'-950px'}
            h={'2017px'}
            w={'780px'}
            boxShadow={boxShadow}
            bg="linear-gradient(171.84deg, rgba(255, 255, 255, 0) 0.2%, rgba(255, 255, 255, 0.02) 33.72%)"
            transform={'matrix(0.71, -0.71, -0.71, -0.71, 0, 0)'}
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'5818px'}
            display={'block'}
            left={'-757px'}
            h={'1152px'}
            w={'1152px'}
            bg="radial-gradient(39.19% 39.19% at 52.26% 46.35%, rgba(13, 218, 169, 0.28) 1.46%, rgba(17, 152, 138, 0) 95.86%)"
            opacity={0.4}
            transform="matrix(-1, 0, 0, 1, 0, 0)"
            zIndex={0}
          />
          <Box
            position="absolute"
            top={'7714px'}
            display={'block'}
            left={'-891px'}
            h={'1344px'}
            w={'1344px'}
            bg="radial-gradient(29.35% 29.35% at 63.1% 53.39%, #BF37FF 0%, rgba(185, 36, 255, 0.2) 0.01%, rgba(211, 118, 255, 0) 93.75%)"
            opacity={0.4}
            zIndex={0}
          />
          {/* end left */}
          <div style={{ zIndex: '9' }}>{children}</div>
          <Flex
            width={'100%'}
            flexDirection={'column'}
            zIndex="4"
            justifyContent="center"
            hidden={isHiddenFooter}
            display={location.pathname.includes('governance') ? 'none' : 'flex'}
          >
            <Footer />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default LandingLayout;
