import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, isEqual, orderBy } from 'lodash';
import { OPTION_VOTE, SnapshotProposalState } from '../../configs/constants/governance';
import { getListCommunityProposals } from '../../hooks/useGovernance';
import { ListVoteGovernance } from '../types';
import { getAddressData, getGovernanceDetailData, getProposalsData } from './fetchGovUser';
import { getMyChoice, getProposal, getSpace, getVotes } from './services';

const initialState: ListVoteGovernance = {
  proposalsDetailData: null,
  listHistory: [],
  spaceData: null,
  contentDetail: [],
  voteForList: [],
  voteAgainstList: [],
  proposalsData: null,
  isLoadingRequest: false,
  communityGovernanceList: {
    active: [],
    pending: [],
    closed: [],
  },
  newCommunityGovernanceState: {
    title: '',
    description: '',
    options: [
      { id: 0, content: '', isDelete: false, isError: false, errorMsg: '' },
      { id: 1, content: '', isDelete: false, isError: false, errorMsg: '' },
    ],
    blockNumber: 0,
    endDate: null,
    startDate: null,
    isConfirm: false,
  },
};
export const governanceSlice = createSlice({
  name: 'governance',
  initialState,
  reducers: {
    setListProposalsDetailState: (state, action) => {
      const data = state;
      data.proposalsDetailData = { ...state.proposalsDetailData, ...action.payload };
      return data;
    },
    setProposalDetailtotalVotes: (state, action) => {
      const data = state;
      data.proposalsDetailData.totalVotesByChoice = action.payload;
      return data;
    },
    setProposalDetailtotalVoters: (state, action) => {
      const data = state;
      data.proposalsDetailData.totalVotersByChoice = action.payload;
      return data;
    },
    setProposalDetailtotalVotePower: (state, action) => {
      const data = state;
      data.proposalsDetailData.totalVotePowerByChoice = action.payload;
      return data;
    },
    setCommunityVoteWarningDisplay: (state, action) => {
      const data = state;
      data.proposalsDetailData.isHideVoteWarning = action.payload;
      return data;
    },
    setCommunityProposalVoteChoice: (state, action) => {
      const data = state;
      if (data.proposalsDetailData) data.proposalsDetailData.myChoice = action.payload;
      return data;
    },
    setListVoteForState: (state, action) => {
      const data = state;
      data.voteForList = action.payload;
      return data;
    },
    setLoadingRequest: (state, action) => {
      const data = state;
      data.isLoadingRequest = action.payload;
    },
    setListVoteAgainstState: (state, action) => {
      const data = state;
      data.voteAgainstList = action.payload;
      return data;
    },
    setHistoryState: (state, action) => {
      const data = state;
      data.listHistory = action.payload;
      return data;
    },
    setListProposalsState: (state, action) => {
      const data = state;
      data.proposalsData = action.payload;
      return data;
    },
    setContentState: (state, action) => {
      const data = state;
      data.contentDetail = action.payload;
      return data;
    },
    setCommunityGovernanceList: (state, action) => {
      const data = state;
      data.communityGovernanceList[action.payload.state] = action.payload.list;
      return data;
    },
    setNewCommunityGovernanceState: (state, action) => {
      const data = state;
      const newData = action.payload;
      data.newCommunityGovernanceState[newData?.field] = newData?.data;
      return data;
    },
    clearStateNewCommunityGovernance: (state) => {
      const data = state;
      data.newCommunityGovernanceState = {
        title: '',
        description: '',
        options: [
          { id: 0, content: '', isDelete: false, isError: false, errorMsg: '' },
          { id: 1, content: '', isDelete: false, isError: false, errorMsg: '' },
        ],
        blockNumber: 0,
        endDate: null,
        startDate: null,
        isConfirm: false,
      };
      return data;
    },
    setSpace: (state, action) => {
      const data = state;
      data.spaceData = action.payload;
      return data;
    },
    setLoadingVp: (state, action) => {
      const data = state;
      if (data.proposalsDetailData)
        data.proposalsDetailData.isLoadingVp = action.payload;
      return data;
    },
  },
});

export const {
  setListProposalsDetailState,
  setHistoryState,
  setContentState,
  setListVoteForState,
  setListVoteAgainstState,
  setListProposalsState,
  setLoadingRequest,
  setCommunityGovernanceList,
  setProposalDetailtotalVotes,
  setProposalDetailtotalVoters,
  setProposalDetailtotalVotePower,
  setNewCommunityGovernanceState,
  setCommunityVoteWarningDisplay,
  clearStateNewCommunityGovernance,
  setCommunityProposalVoteChoice,
  setLoadingVp,
  setSpace
} = governanceSlice.actions;

export const fetchAddressDetailGovernance = (vote: number, id: string) => async (dispatch: any) => {
  dispatch(setLoadingRequest(true));
  const ListAddressDetail = await getAddressData(vote, id);
  if (vote === OPTION_VOTE.VOTE_FOR) {
    dispatch(setListVoteForState(ListAddressDetail));
  }
  if (vote === OPTION_VOTE.VOTE_AGAINST) {
    dispatch(setListVoteAgainstState(ListAddressDetail));
  }
  dispatch(setLoadingRequest(false));
};

export const fetchCommunityProposalDetail = (proposalId: string) => async (dispatch: any) => {
  dispatch(setLoadingRequest(true));
  const isHideVoteWarning = localStorage.getItem('hideVoteWarning');
  const listProposalsDetail = await getProposal(proposalId);
  const { proposal } = listProposalsDetail;
  if (listProposalsDetail) {
    dispatch(setListProposalsDetailState(proposal));
    dispatch(setCommunityVoteWarningDisplay(isHideVoteWarning));
  }
  dispatch(setLoadingRequest(false));
};

export const fetchCommunityProposaltotalVotes = (proposalId, choices, getVotingPowers, strategies) => async (dispatch: any, getState: any) => {
  dispatch(setLoadingVp(true));
  const { votes } = await getVotes(proposalId, 30000, 0);
  const store = getState();
  const snapshot = store?.governance?.proposalsDetailData?.snapshot;
  const votePowers = await getVotingPowers(snapshot, votes.map(v => v.voter), strategies);
  const totalVotesByChoice = choices.map((_, index) => votes.filter(v => isEqual(v.choice, index + 1)).length);
  const totalVpByChoice = choices.map((_, index) => votes.filter(v => isEqual(v.choice, index + 1)).reduce((cur, val) => cur + Number(votePowers[val.voter] ?? 0), 0));
  const totalVotersByChoice = choices.map((_, index) => votes.filter(v => isEqual(v.choice, index + 1)).map(v => v.voter));
  if (totalVotesByChoice) {
    dispatch(setProposalDetailtotalVotes(totalVotesByChoice));
    dispatch(setProposalDetailtotalVoters(totalVotersByChoice));
    dispatch(setProposalDetailtotalVotePower(totalVpByChoice));
  }
  dispatch(setLoadingVp(false));
};

export const fetchCommunityVoteChoice = (proposalId, account) => async (dispatch: any) => {
  dispatch(setLoadingRequest(true));
  const { votes } = await getMyChoice(proposalId, account);
  if (!isEmpty(votes)) dispatch(setCommunityProposalVoteChoice(votes[0].choice - 1));
  else dispatch(setCommunityProposalVoteChoice(-1));
  dispatch(setLoadingRequest(false));
};

export const fetchProposalsDetailGovernance =
  (proposal_id_by_index: string, proposalId: string, account: string) => async (dispatch: any) => {
    dispatch(setLoadingRequest(true));
    const ListProposalsDetail = await getGovernanceDetailData(proposal_id_by_index, proposalId, account ?? undefined);
    if (ListProposalsDetail) {
      dispatch(setListProposalsDetailState(ListProposalsDetail));
    }
    dispatch(setLoadingRequest(false));
  };

export const fetchProposalsGovernance = (queryString: string) => async (dispatch: any) => {
  dispatch(setLoadingRequest(true));
  const ListProposals = await getProposalsData(queryString);
  if (ListProposals) {
    dispatch(setListProposalsState(ListProposals ?? null));
  }
  dispatch(setLoadingRequest(false));
};

export const handleCleanupGovernanceDetail = () => (dispatch: any) => {
  dispatch(setListProposalsDetailState(null));
  dispatch(setListVoteForState([]));
  dispatch(setListVoteAgainstState([]));
};

export const fetchListSpaceGovernance = () => async (dispatch: any) => {
  const spaceData = await getSpace();
  if (spaceData) {
    dispatch(setSpace(spaceData));
  }
};

export const fetchAllProposalByStatus = () => (dispatch: any) => {
  try {
    dispatch(fetchListCommunityGovernance(0, 1000, 'active', 'desc'));
    dispatch(fetchListCommunityGovernance(0, 1000, 'pending', 'asc'));
    dispatch(fetchListCommunityGovernance(0, 1000, 'closed', 'asc'));
  } catch (e) {

  }
}

export const fetchListCommunityGovernance =
  (skip: number, limit = 1000, state: string, orderDirection: string) =>
    async (dispatch: any) => {
      dispatch(setLoadingRequest(true));
      try {
        const listProposals = await getListCommunityProposals(skip, limit, state, orderDirection);
        let newList = [];
        if (state === SnapshotProposalState.Active) {
          newList = orderBy(listProposals, ['end', 'start', 'created'], ['asc', 'desc', 'asc']);
        } else if (state === SnapshotProposalState.Closed) {
          newList = orderBy(listProposals, ['end', 'created'], ['asc', 'asc']);
        } else {
          newList = orderBy(listProposals, ['start', 'created'], ['asc', 'asc']);
        }

        dispatch(setCommunityGovernanceList({ list: newList, state }));
      } catch (error) {
        dispatch(setCommunityGovernanceList({ list: [], state }));
      }
      dispatch(setLoadingRequest(false));
    };

export default governanceSlice.reducer;
