import { transferToOtherChains } from '../../utils/callHelpers';
import EVMChain from './EVMChain';

class BNBChain extends EVMChain {
  bridgeAsset(bridgeAssetContract: any, payableAmount: number, destBcId, recipient, amount, account, callbackFunc) {
    return transferToOtherChains(
      bridgeAssetContract,
      payableAmount,
      Number(destBcId),
      recipient,
      amount,
      account,
      callbackFunc,
    );
  }
}

export default BNBChain;
