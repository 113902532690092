import React from 'react';
import { Text, Flex, Image, useColorModeValue } from '@chakra-ui/react';
import { Config, ConnectorNames, Login } from './types';
import { connectorLocalStorageKey, walletLocalStorageKey } from './config';

interface Props {
  walletConfig: Config;
  login: Login;
  onDismiss: () => void;
  isLatestSelect: boolean;
  isFirst?: boolean;
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss, isLatestSelect, isFirst = false }) => {
  const { title, icon: Icon } = walletConfig;
  const handleConnectWallet = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

    // Since iOS does not support Trust Wallet we fall back to WalletConnect
    if (walletConfig.title === 'Trust Wallet' && isIOS) {
      login(ConnectorNames.WalletConnect);
    } else {
      login(walletConfig.connectorId);
    }

    localStorage.setItem(walletLocalStorageKey, walletConfig.title);
    localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
    onDismiss();
  };
  const gray400ToGray500 = useColorModeValue('gray.400', 'gray.500');
  const gray700ToGray75 = useColorModeValue('gray.75', 'gray.700');

  return (
    <Flex
      direction="column"
      onClick={handleConnectWallet}
      w="50%"
      h={['90px', '100px', '120px', '120px']}
      _hover={{
        bg: gray700ToGray75,
        // color: 'white',
      }}
      border={isLatestSelect && '1.5px solid'}
      borderColor={gray700ToGray75}
      borderRadius="16px"
      align="center"
      justify="center"
      cursor="pointer"
      color={gray400ToGray500}
      position="relative"
    >
      <Icon width="40px" />
      <Text fontSize="14px" fontWeight="500" pt="4px">
        {title}
      </Text>
      {isLatestSelect ? (
        <Image position="absolute" top="12px" right="12px" ml="5px" src="img/clock-counter-clockwise.svg" />
      ) : isFirst ? (
        <Flex
          position="absolute"
          top="12px"
          right="12px"
          width="8px"
          height="8px"
          zIndex="999"
          borderRadius="50%"
          background="red.400"
          className="blink"
        />
      ) : null}
    </Flex>
  );
};

export default WalletCard;
