import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export interface PageMetaProps {
  title?: string;
  description?: string;
  ogUrl?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
}
const getValueOfObjByString = (o: any, s: string): any => {
  try {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < a.length; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    // eslint-disable-next-line consistent-return
    return o ?? '';
  } catch (e) {
    return '';
  }
};

const useDynamicTitle = (selector: string) => {
  const state = useSelector((s) => getValueOfObjByString(s, selector));
  return useMemo(() => state, [state]);
};

export const getBasePath = (path) => {
  if (path.startsWith('/governance/community/new')) {
    return '/governance/community/new';
  } else if (path.startsWith('/governance/core/') || path.startsWith('/governance/community/')) {
    return '/governance/proposal/detail';
  } else if (path.startsWith('/nft/marketplace/auction')) {
    return '/nft/marketplace/auction';
  } else if (path.startsWith('/nft/marketplace/market')) {
    return '/nft/marketplace/market';
  } else if (path.startsWith('/swap')) {
    return '/swap';
  } else if (path.startsWith('/liquidity')) {
    return '/liquidity';
  } else if (path.startsWith('/launchpad') || path.startsWith('/launchpad')) {
    return '/launchpad';
  } else if (path.startsWith('/governance')) {
    return '/governance';
  } else if (path.startsWith('/bonds')) {
    return '/bonds';
  } else if (path.startsWith('/posi-birthday/daily-lottery')) {
    return '/posi-birthday/daily-lottery';
  } else if (path.startsWith('/posi-birthday/lucky-posi')) {
    return '/posi-birthday/lucky-posi';
  } else if (path.startsWith('/posi-birthday/treasure-hunt')) {
    return '/posi-birthday/treasure-hunt';
  } else if (path.startsWith('/posi-birthday/nfts-game')) {
    return '/posi-birthday/nfts-game';
  } else if (path.startsWith('/posi-birthday')) {
    return '/posi-birthday';
  } else {
    return path;
  }
};

const getDynamicTitleSelector = (path: string) => {
  let selector;
  if (path.startsWith('/nft/marketplace/auction')) {
    selector = 'nftMarketplace.auctionDetail.tokenId';
  } else if (path.startsWith('/nft/marketplace/market')) {
    selector = 'nftMarketplace.marketDetail.tokenId';
  } else if (path.startsWith('/governance/core/') || path.startsWith('/governance/community/')) {
    selector = 'governance.proposalsDetailData.title';
  }
  return selector;
};

const useCustomMeta = (path: string, t: any): any => {
  const basePath = getBasePath(path);
  const selectorPath = getDynamicTitleSelector(path);
  const dynamicTitle = useDynamicTitle(selectorPath);

  switch (basePath) {
    case '/':
      return { title: `${t('Position Exchange')}` };
    case '/trading':
      return { title: `${t('Trading')}` };
    case '/swap':
      return { title: `${t('Exchange')}` };
    case '/liquidity':
      return { title: `${t('Exchange')}` };
    case '/farming':
      return { title: `${t('Farms')}` };
    case '/nfts':
      return { title: `${t('NFTs')}` };
    case '/pools':
      return { title: `${t('Pools')}` };
    case '/bonds':
      return { title: `${t('Bonds')}` };
    case '/referral':
      return { title: `${t('Referral')}` };
    case '/vault':
      return { title: `${t('Vault')}` };
    case '/governance/community/new':
      return { title: `Add proposal | Governance` };
    case '/governance/proposal/detail':
      return { title: dynamicTitle ? `${dynamicTitle} | Governance` : 'Governance' };
    case '/governance':
      return { title: `${t('Governance')}` };
    case '/nft/marketplace/auction': {
      return { title: dynamicTitle ? `#${dynamicTitle} | Auction | MarketPlace` : 'Auction | MarketPlace' };
    }
    case '/nft/marketplace/market': {
      return { title: dynamicTitle ? `#${dynamicTitle} | Market | MarketPlace` : 'Market | MarketPlace' };
    }
    case '/nft/marketplace/history/market': {
      return { title: 'Market History | MarketPlace' };
    }
    case '/nft/marketplace/history/auction': {
      return { title: 'Auction History | MarketPlace' };
    }
    case '/nft/marketplace':
      return { title: `${t('NFT Marketplace')}` };
    case '/competition/buying':
      return { title: `${t('Buying Competition')}` };
    case '/competition/farming':
      return { title: `${t('Farming Competition')}` };
    case '/competition/referral':
      return { title: `${t('Referral Competition')}` };
    case '/launchpad':
      return { title: `${t('Launchpad')}` };
    case '/posi-birthday/daily-lottery':
      return { title: `${t('Daily Lottery | Birthday')}` };
    case '/posi-birthday/lucky-posi':
      return { title: `${t('Lucky POSI | Birthday')}` };
    case '/posi-birthday/treasure-hunt':
      return { title: `${t('Treasure Hunt | Birthday')}` };
    case '/posi-birthday/nfts-game':
      return { title: `${t('NFTs Game | Birthday')}` };
    case '/posi-birthday':
      return { title: `${t('Birthday')}` };
    case '/bonds/p2p/create':
      return { title: `${t('Create | P2P Bond')}` };
    case '/bond-lending':
      return { title: `${t('Bond Lending')}` };
    default:
      return null;
  }
};

const defaultDescription =
  'Position Exchange is the new community driven decentralized trading platform powered by the POSI token. Trade, Swap, Farm, Stake & Earn in an attractive and user-friendly platform.';
export default function PageMeta(props: PageMetaProps) {
  const { pathname = '' } = useLocation();
  const { t } = useTranslation('page-meta');
  const pageMeta: PageMetaProps = useCustomMeta(pathname, t);
  const { title, description, ogUrl, ogImage, ogTitle, ogDescription } = { ...pageMeta, ...props };
  const _ogTitle = ogTitle ?? title;
  const _description = description ?? defaultDescription;
  const _ogDescription = ogDescription ?? _description;
  return (
    <Helmet defer={false} titleTemplate="%s | Position Exchange" defaultTitle="Position Exchange">
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {_ogTitle && <meta content={`${title} | Position Exchange`} property="og:title" name="og:title" />}
      {_ogDescription && <meta content={_ogDescription} property="og:description" name="og:description" />}
      {title && <title>{title}</title>}
      {_description && <meta content={_description} property="description" name="description" />}
    </Helmet>
  );
}
