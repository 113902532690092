import { RepeatIcon } from '@chakra-ui/icons';
import {
  ChakraProvider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  keyframes,
  usePrefersReducedMotion,
  Image,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useSelector, Provider } from 'react-redux';
import themeContext from '../../configs/contexts/ThemeContext';
import LoaderJson from '../../constants/loading.json';
import store, { AppState } from '../../redux/store';
import { MessageType } from '../../redux/toasts';
import Typography from '../Typography';

interface IPendingModal {
  isOpenModal: boolean;
  onDismiss?: (val?: any) => void;
  titleContent?: any;
  description?: any;
}

const PendingModalWithStateTrans: React.FC<IPendingModal> = ({ isOpenModal, onDismiss = () => null, description }) => {
  const { t } = useTranslation('common');
  const messageType = useSelector((state: AppState) => state.toasts?.messageType);

  useEffect(() => {
    if (messageType === MessageType.Close) {
      onDismiss();
    }
  }, [messageType]);

  const spin = keyframes`
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
  `;
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${spin} infinite 2s linear`;

  return (
    <Provider store={store}>
      <ChakraProvider theme={themeContext}>
        <Modal size={'md'} isCentered blockScrollOnMount={true} isOpen={isOpenModal} onClose={onDismiss}>
          <ModalOverlay width="100%" height="100%" />
          <ModalContent zIndex="9999" background={'gray.800'} borderRadius="24px" py={0} px={0}>
            <ModalCloseButton align="center" color={'gray.500'} />
            <ModalBody py="0px">
              <Flex flexDirection={'column'} pt={'32px'} justifyContent={'center'}>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: LoaderJson,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  width={'100px'}
                  height={'100px'}
                />
                <Flex textAlign={'center'} mt="16px" flexDirection={'column'} justifyContent={'center'}>
                  <Text color={'white'} fontSize={'22px'} fontWeight={600}>
                    {t('processing')}
                  </Text>
                  <Flex mx="auto" color={'teal.150'} mt="20px" alignItems={'center'}>
                    <RepeatIcon
                      hidden={!(messageType === MessageType.EstimatingGas)}
                      animation={animation}
                      color="teal.150"
                    />
                    <Image
                      hidden={messageType === MessageType.EstimatingGas}
                      w="24px"
                      h="24px"
                      src="/img/checked.svg"
                    />
                    <Typography type="body1" as="span" ml="6px">
                      {messageType === MessageType.EstimatingGas ? t('EstimatingGas') : t('EstimatedGas')}
                    </Typography>
                  </Flex>
                  <Typography
                    type="body1"
                    color={messageType === MessageType.ConfirmInWallet ? 'teal.150' : 'gray.500'}
                    mt="8px"
                    alignItems={'center'}
                  >
                    {messageType === MessageType.ConfirmInWallet && (
                      <RepeatIcon animation={animation} color="teal.150" className="rotate-infinite" />
                    )}
                    <Text as="span" ml="6px">
                      {t('ConfirmInWallet')}
                    </Text>
                  </Typography>
                </Flex>
              </Flex>
              <Flex mb={'24px'} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </Provider>
  );
};

export default PendingModalWithStateTrans;
