/* eslint-disable no-unused-vars */
import { ChainId, JSBI, Percent, Token, WETH } from '@pancakeswap/sdk';

// export const ROUTER_ADDRESS = '0xD99D1c33F9fC3444f8101754aBC46c52416550D1';
// export const ROUTER_ADDRESS = '0x05ff2b0db69458a0750badebc4f9e13add608c7f';
export const ROUTER_ADDRESS = {
  97: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
}; // v2
export const ROUTER_ADDRESS_POSITION_EX = {
  97: '0x6dc1Ac57E96cb918BDd9E67e15Fe9a0F79AFFA3b',
  56: '0xdef145c89ee38d8e86fd40a9af466038d8f0252f',
};

// a list of tokens by chain
type ChainTokenList = {
  readonly // eslint-disable-next-line no-unused-vars
  [chainId in ChainId]: Token[];
};

export const POSI_BUSD_PID = 0;
export const POSI_SINGLE_PID = 1;

export const POSI_MAIN = {
  name: 'Position Exchange',
  symbol: 'POSI',
  address: '0x5CA42204cDaa70d5c773946e69dE942b85CA6706',
  chainId: 56,
  decimals: 18,
  logoURI: 'https://position.exchange/favicon.ico',
};

export const POSI_TEST1 = {
  name: 'Position Exchange',
  symbol: 'POSI',
  address: '0x2a0151ad6ead421e5325c4b6808a9fd5e0440a36',
  chainId: 97,
  decimals: 18,
  logoURI: 'https://position.exchange/favicon.ico',
};
export const POSI_TEST2 = {
  name: 'Position Exchange',
  symbol: 'POSI',
  address: '0x7A7916FBB9F82fc02164Dca73534Bc1F0aD03875',
  chainId: 97,
  decimals: 18,
  logoURI: 'https://position.exchange/favicon.ico',
};

export const POSI_TEST = {
  name: 'Position Exchange',
  symbol: 'POSI',
  address: '0x1f42b7B569D5ABE7117b2CD4e9A7d248b4F2023a',
  chainId: 97,
  decimals: 18,
  logoURI: 'https://position.exchange/favicon.ico',
};

export const CAKE = new Token(
  ChainId.MAINNET,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'PancakeSwap Token',
);
export const WBNB = new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB');
export const DAI = new Token(
  ChainId.MAINNET,
  '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  18,
  'DAI',
  'Dai Stablecoin',
);
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'BUSD', 'Binance USD');

export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC');
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD');
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
);
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
);

export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
);

interface TokenList {
  [symbol: string]: Token;
}

export const defineTokens = <T extends TokenList>(t: T) => t;

export const testnetTokens = defineTokens({
  wbnb: new Token(
    97,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  cake: new Token(
    97,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    97,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    97,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    97,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
} as const);

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [97]: [WETH[ChainId.TESTNET]],
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], BUSD, BTCB, ETH, USDC],
  [ChainId.TESTNET]: [testnetTokens.wbnb, testnetTokens.cake, testnetTokens.busd],
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, BTCB],
};

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [CAKE, WBNB],
    [BUSD, USDT],
    [DAI, USDT],
  ],
};

export const NetworkContextName = 'NETWORK';

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 200;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH

export const SRC_IMAGE_BNB = 'https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png';
export const SRC_IMAGE_POSI = 'https://position.exchange/favicon.ico';
