import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

import firebaseConfigDev from './config/firebase.config.dev';
import firebaseConfigProd from './config/firebase.config.prod';

const firebaseConfig =
  process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'pilot'
    ? firebaseConfigProd
    : firebaseConfigDev;

const app = initializeApp(firebaseConfig);
export const dbFirebase = getFirestore(app);

export default app;
