/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceApiResponse, PriceFeedKeyResponse, PriceState } from '../types';

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: '',
  data: undefined,
  priceFeedKey: {},
};

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiResponse>('prices/fetch', async () => {
  const response = await fetch(`${process.env.REACT_APP_PRICE_BASE_URL}/prices`);
  const data = (await response.json()) as PriceApiResponse;
  // Return normalized token names

  const objPrice = Object.keys(data.prices).reduce((accum, token) => {
    return {
      ...accum,
      [token.toLowerCase()]: data.prices[token],
    };
  }, {});
  objPrice['posi_wbnb_lp'] = objPrice['posi_bnb_lp'];
  if (!objPrice.hasOwnProperty('ptx')) {
    objPrice['ptx'] = objPrice['posi'];
  }
  return {
    update_at: data.update_at,
    prices: objPrice,
  };
});

export const fetchPriceFeedKeys = createAsyncThunk<any>('prices/fetch_feed_key', async () => {
  const response = await fetch(`${process.env.REACT_APP_BONDS_URL}/v1/api/bonds/price-feedkeys`);
  const data = (await response.json()) as PriceFeedKeyResponse;
  const dataList = data.data || [];
  const objectPriceFeedKeys = {};
  if (dataList.length) {
    dataList.forEach((item) => {
      objectPriceFeedKeys[item.address.toLowerCase()] = item.feed_key || '';
      objectPriceFeedKeys[item.symbol.toLowerCase()] = item.feed_key || '';
    });
  }
  return objectPriceFeedKeys;
});

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPrices.fulfilled, (state, action: PayloadAction<PriceApiResponse>) => {
      state.isLoading = false;
      state.lastUpdated = action.payload.update_at;
      state.data = action.payload.prices;
    });
    builder.addCase(fetchPriceFeedKeys.fulfilled, (state, action: PayloadAction<Record<string, string>>) => {
      state.priceFeedKey = action.payload;
    });
  },
});

export default pricesSlice.reducer;
