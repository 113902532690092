import BigNumber from 'bignumber.js';
import erc20ABI from '../configs/abi/erc20.json';
import { WrappedTokenInfo } from '../redux/lists/hooks';
import multicall from '../utils/multicall';
import { getTokenAmount } from '../adapters/services/BridgeAssetService';
import { ChainTypes } from '../adapters/types';

export const useModalInfo = async (
  account: string | undefined,
  posiToken: WrappedTokenInfo,
  decimals: number,
  chainId?: number,
) => {
  try {
    // info
    const calls = [
      {
        address: posiToken?.address,
        name: 'balanceOf',
        params: [account],
      },
    ];

    const balanceOfPosi = await multicall(erc20ABI, calls);
    if (chainId === 900000 && !!account) {
      const balance = await getTokenAmount(ChainTypes.POSI, '', true, account);
      localStorage.setItem('balanceOfPosi', !!balance ? balance : '0');
      return {
        balanceOfPosi: !!balance ? balance : 0,
      };
    } else {
      localStorage.setItem(
        'balanceOfPosi',
        balanceOfPosi ? formatBigNumberToNumber(new BigNumber(balanceOfPosi[0].balance._hex), decimals) : '0',
      );
      return {
        balanceOfPosi: balanceOfPosi
          ? formatBigNumberToNumber(new BigNumber(balanceOfPosi[0].balance._hex), decimals)
          : 0,
      };
    }
  } catch (e) {}
};

export const formatBigNumber = (amount: BigNumber, decimals = 18): number => {
  if (amount) {
    const decimalWei = 10 ** decimals;
    const newAmount = amount.dividedBy(new BigNumber(decimalWei));
    const amountString = newAmount.toString();
    // const amountDecimal = fromWei(amountString, 'ether');
    return Number(amountString);
  }
  return 0;
};

const formatBigNumberToNumber = (amount: BigNumber, decimals: number): string => {
  if (amount) {
    const newAmount = amount.dividedBy(new BigNumber('1000000000000000000'));
    const amountString = newAmount.toString();
    // const amountDecimal = fromWei(amountString, 'ether');
    const amountNumber = Number(amountString).toFixed(decimals);
    return amountNumber;
  }
  return '0';
};

export const formatBigNumberToString = (amount: BigNumber): string => {
  if (amount) {
    const newAmount = amount.dividedBy(new BigNumber('1000000000000000000'));
    const amountString = newAmount.toFixed();
    return amountString;
  }
  return '0';
};
