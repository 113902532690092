import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/store';
import { Bond, BondTransactionResponse } from '../redux/types';

export const useGetCurrentBondDetail = (): Bond => {
  const bondDetail = useSelector((state: AppState) => state.bondDetail?.currentBond);

  return useMemo(() => {
    return bondDetail;
  }, [bondDetail]);
};

export const useGetBondPageLoading = (): boolean => {
  const isLoading = useSelector((state: AppState) => state.bondDetail?.loading);

  return useMemo(() => {
    return isLoading;
  }, [isLoading]);
};

export const useGetBondTransactions = (): BondTransactionResponse | undefined => {
  const listTransaction = useSelector((state: AppState) => state.bondDetail?.listTransaction);

  return useMemo(() => {
    return (
      listTransaction || { current_page: 0, total_page: 0, items: [], total_transactions: 0, transaction_per_page: 10 }
    );
  }, [listTransaction]);
};

export const useGetListBond = () => {
  const listBond = useSelector((state: AppState) => state?.bonds?.listBond);
  return useMemo(() => {
    return listBond;
  }, [listBond]);
};

export const useIsLoadingBond = () => {
  return useSelector((state: AppState) => state?.bonds?.isLoading);
};

export const useGetTotalBondPage = () => {
  const totalPage = useSelector((state: AppState) => state?.bonds?.totalPage);
  return useMemo(() => {
    return totalPage;
  }, [totalPage]);
};
export const useGetBondUserData = () => {
  const availableUnit = useSelector((state: AppState) => state.bondDetail?.availableUnit);
  const stakedUnit = useSelector((state: AppState) => state.bondDetail?.stakedUnit);
  const underlyingAssetStatus = useSelector((state: AppState) => state.bondDetail?.underlyingAssetStatus);
  const isApprove = useSelector((state: AppState) => state.bondDetail?.isApprove);
  const apr = useSelector((state: AppState) => state.bondDetail?.apr);
  const ytm = useSelector((state: AppState) => state.bondDetail?.ytm);
  const liquidationTime = useSelector((state: AppState) => state.bondDetail?.liquidationTime);

  return useMemo(() => {
    return {
      stakedUnit,
      availableUnit,
      total: stakedUnit + availableUnit,
      liquidationTime,
      underlyingAssetStatus,
      isApprove,
      apr,
      ytm,
    };
  }, [availableUnit, stakedUnit, underlyingAssetStatus, isApprove, apr, ytm, liquidationTime]);
};

export interface BondUserData {
  availableUnit: number;
  stakedUnit: number;
  underlyingAssetStatus: number;
  isApprove: boolean;
}

export const useHasNewBond = () => {
  const listBond = useSelector((state: AppState) => state?.bonds?.listBond);
  const totalBond = useSelector((state: AppState) => state?.bonds?.totalBond);
  let hasNewBond = false;
  const currentDate = moment();
  listBond.forEach((e) => {
    if (moment(currentDate).isSameOrBefore(Number(e.active_date) * 1000)) {
      hasNewBond = true;
      return;
    }
  });

  return useMemo(() => {
    return {
      length: totalBond,
      hasNewBond,
    };
  }, [listBond, totalBond]);
};
