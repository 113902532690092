import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeOfTradeConfig } from '../configs/constants/bondExchange';
import { useTransactionAdder } from '../redux/transactions/hooks';
import { SpotHouse } from '../typeChain/spotHouse';
import { useGetExchangeLiquidityContractByAddress, useGetPositionHouseContract } from '../utils/contractHelpers';
import multicall from '../utils/multicall';
import useWeb3 from './useWeb3';
import ERC20_ABI from '../configs/abi/erc20.json';
import liquidityNftManagerAbi from '../configs/abi/liquidity_nft_manager.json';
import useRefresh from './useRefresh';
import { getAddress, useGetLiquidityNftManager, useGetPositionHouseAddressAndAbi } from '../utils/addressHelpers';
import { callEstGasForExchange } from '../utils/callHelpers';
import { useWeb3React } from '@web3-react/core';
import { usePendingModalWithStateTrans, useResponseModal } from '../utils/confirmModal';
import { formatBigNumber } from './useModalInfo';
import { useDispatch } from 'react-redux';
import { fetchUserDataPoolExchange, setBalanceClaim } from '../redux/bondExchange';
import store from '../redux/store';
import { getMyLiquidity } from './useBondExchangeData';
import { useGetApiPrices } from '../redux/hooks';
import { fetchPoolsUserDataAsync } from '../redux/pools';
import { PairSpotExchange } from '../redux/types';

// use position house function here
export const estimateGas = async (contract: SpotHouse, methodName: string, ...args) => {
  const gasEst = await contract.estimateGas[methodName](...args);
  const newGas = Math.floor(Number(gasEst));
  return newGas;
};

export const useAllowanceTokenExchange = (
  pairManager: string,
  tokenBaseAddress: string,
  tokenQuoteAddress: string,
  owner: string,
) => {
  const [isApprove, setIsApprove] = useState({ baseApprove: false, quoteApprove: false });
  const { fastRefresh } = useRefresh();

  const getAllowance = useCallback(async () => {
    if (tokenBaseAddress && tokenBaseAddress && owner) {
      const spotHouseAddress = await useGetPositionHouseAddressAndAbi(pairManager);
      const [allowanceBase, allowanceQuote] = await multicall(ERC20_ABI, [
        { address: tokenBaseAddress, name: 'allowance', params: [owner, spotHouseAddress.spotHouseAddress] },
        { address: tokenQuoteAddress, name: 'allowance', params: [owner, spotHouseAddress.spotHouseAddress] },
      ]);
      if (allowanceBase && allowanceQuote) {
        if (
          new BigNumber(allowanceBase[0].toString()).isGreaterThan(new BigNumber(0)) !== isApprove?.baseApprove ||
          new BigNumber(allowanceQuote[0].toString()).isGreaterThan(new BigNumber(0)) !== isApprove?.quoteApprove
        ) {
          setIsApprove({
            baseApprove: new BigNumber(allowanceBase[0].toString()).isGreaterThan(new BigNumber(0)),
            quoteApprove: new BigNumber(allowanceQuote[0].toString()).isGreaterThan(new BigNumber(0)),
          });
        }
      }
    }
  }, [tokenBaseAddress, tokenQuoteAddress, owner]);

  const setApprovalToken = (field: string) => {
    setIsApprove((state) => ({
      ...state,
      [field]: true,
    }));
  };

  useEffect(() => {
    getAllowance();
  }, [getAllowance, fastRefresh]);

  return { isApprove, getAllowance, setApprovalToken };
};

export const getSpecialSymbol = (
  symbol: string,
): {
  isSpecial: boolean;
  isBnb: boolean;
} => {
  const isSpecial =
    symbol.toLowerCase().includes('posi') ||
    symbol.toLowerCase().includes('bnb') ||
    symbol.toLowerCase().includes('wbnb');
  const isBnb = symbol.toLowerCase().includes('bnb') || symbol.toLowerCase().includes('wbnb');
  return { isSpecial, isBnb };
};

export const useMakeMarketOrder = () => {
  const { t } = useTranslation(['bondExchange']);
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const addTransaction = useTransactionAdder();

  const handleCallOpenMarket = useCallback(
    async (spotManager: string, side: TypeOfTradeConfig, quantity: string) => {
      usePendingModalWithStateTrans();
      const spotExchangeContract = await useGetPositionHouseContract(spotManager, web3);

      const currentPair = store.getState().bondExchange.currentPair;

      const { isSpecial: isSpecifyCase, isBnb } = getSpecialSymbol(currentPair?.quote?.symbol);

      const method =
        isSpecifyCase && side === TypeOfTradeConfig.BUY
          ? spotExchangeContract.methods.openMarketOrderWithQuote(
              spotManager,
              side === TypeOfTradeConfig.BUY ? 0 : 1,
              !isBnb && quantity,
            )
          : spotExchangeContract.methods.openMarketOrder(spotManager, side === TypeOfTradeConfig.BUY ? 0 : 1, quantity);

      const gas = await callEstGasForExchange(method, account, isBnb ? quantity : undefined, 'spot_error_');

      return method.send(
        { from: account, gas: Math.floor(gas), value: isBnb ? quantity : '0x0' },
        async function (err, hash) {
          if (hash) {
            addTransaction({ hash } as any, {
              title: `Successful ${side === TypeOfTradeConfig.BUY ? 'Buy' : 'Sell'}`,
              titleFail: `Failed ${side === TypeOfTradeConfig.BUY ? 'Buy' : 'Sell'}`,
              trigger: () => {
                try {
                } catch (e) {}
              },
            });
            await useResponseModal({
              title: t('transaction_submitted'),
              txHash: hash,
            });
          }
          if (err) {
            if (err?.message) {
              if (err?.message) {
                await useResponseModal({ title: t('buy_error'), errorMsg: err?.message });
                return;
              }
            }
            throw err;
          }
          return;
        },
      );
    },
    [web3, account],
  );

  // not use
  const handleCallOpenMarketWithQuote = useCallback(
    async (spotManager: string, side: TypeOfTradeConfig, quantity: string) => {
      usePendingModalWithStateTrans();
      const spotExchangeContract = await useGetPositionHouseContract(spotManager, web3);
      // TODO implement me
      const method = spotExchangeContract.methods.openMarketOrderWithQuote(
        spotManager,
        side === TypeOfTradeConfig.BUY ? 0 : 1,
        quantity,
      );

      const gas = await callEstGasForExchange(method, account, undefined, 'spot_error_');

      return method.send({ from: account, gas: Math.floor(gas) }, async function (err, hash) {
        if (hash) {
          addTransaction({ hash } as any, {
            title: `Successful ${side === TypeOfTradeConfig.BUY ? 'Buy' : 'Sell'}`,
            titleFail: `Failed ${side === TypeOfTradeConfig.BUY ? 'Buy' : 'Sell'}`,
            trigger: () => {
              try {
              } catch (e) {}
            },
          });
          await useResponseModal({
            title: t('transaction_submitted'),
            txHash: hash,
          });
        }
        if (err) {
          if (err?.message) {
            if (err?.message) {
              await useResponseModal({ title: t('buy_error'), errorMsg: err?.message });
              return;
            }
          }
          throw err;
        }
        return;
      });
    },
    [web3, account],
  );

  const handleCallOpenLimit = useCallback(
    async (spotManager: string, side: TypeOfTradeConfig, quantity: string, price: number, basisPoint: number) => {
      usePendingModalWithStateTrans();
      const spotExchangeContract = await useGetPositionHouseContract(spotManager, web3);
      // TODO implement me

      const currentPair = store.getState().bondExchange.currentPair;
      const { isSpecial: isSpecifyCase, isBnb } = getSpecialSymbol(currentPair?.quote?.symbol);
      // default is openLimitOrder
      let callingMethodFn = 'openLimitOrder'
      // TODO check isBnb case
      if(isSpecifyCase && side === TypeOfTradeConfig.BUY){
        // if abi exist openBuyLimitOrderExactInput
        if(spotExchangeContract.methods['openBuyLimitOrderExactInput']){
          callingMethodFn = 'openBuyLimitOrderExactInput'
        }
      }
      const method = spotExchangeContract.methods[callingMethodFn](
        spotManager,
        side === TypeOfTradeConfig.BUY ? '0' : '1',
        quantity,
        Math.floor(price * basisPoint).toString(), 
      );
      const gas = await callEstGasForExchange(method, account, isBnb ? quantity : undefined, 'spot_error_');
      let hashX = '';
      return method.send(
        { from: account, gas: Math.floor(gas), value: isBnb ? quantity : '0x0' },
        async function (err, hash) {
          if (hash) {
            hashX = hash;
            addTransaction({ hash } as any, {
              // summary: `Open order successfully`,
              title: `Open order successfully`,
              titleFail: `Open order failed`,
              trigger: () => {
                try {
                } catch (e) {}
              },
            });
            await useResponseModal({
              title: t('transaction_submitted'),
              txHash: hash,
            });
          }
          if (err) {
            if (err?.message) {
              if (err?.message) {
                await useResponseModal({ title: t('Open order failed'), errorMsg: err?.message });
                return;
              }
            }
            throw err;
          }
          return hashX;
        },
      );
    },
    [web3, account],
  );

  return { handleCallOpenMarket, handleCallOpenLimit, handleCallOpenMarketWithQuote };
};

export const useCancelLimitOrder = () => {
  const { t } = useTranslation(['bondExchange']);
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const addTransaction = useTransactionAdder();

  const handleCancelLimitOrder = useCallback(
    async (spotManager: string, orderId: number, pip: number) => {
      usePendingModalWithStateTrans();
      const spotExchangeContract = await useGetPositionHouseContract(spotManager, web3);
      // TODO implement me
      const method = spotExchangeContract.methods.cancelLimitOrder(spotManager, orderId, pip);
      const gas = await callEstGasForExchange(method, account, undefined, 'spot_error_');

      return method.send({ from: account, gas: Math.floor(gas) }, async function (err, hash) {
        if (hash) {
          addTransaction({ hash } as any, {
            title: `Cancel limit order successful`,
            titleFail: `Cancel limit order failed`,
            trigger: () => {
              try {
              } catch (e) {}
            },
          });
          await useResponseModal({
            title: t('transaction_submitted'),
            txHash: hash,
          });
        }
        if (err) {
          if (err?.message) {
            if (err?.message) {
              await useResponseModal({ title: t('Cancel limit order failed'), errorMsg: err?.message });
              return;
            }
          }
          throw err;
        }
        return;
      });
    },
    [web3, account],
  );

  const handleCancelAllLimitOrderByPair = useCallback(
    async (spotManager: string) => {
      usePendingModalWithStateTrans();
      const spotExchangeContract = await useGetPositionHouseContract(spotManager, web3);
      const method = spotExchangeContract.methods.cancelAllLimitOrder(spotManager);
      const gas = await callEstGasForExchange(method, account, undefined, 'spot_error_');

      method.send({ from: account, gas: Math.floor(gas) }, async function (err, hash) {
        if (hash) {
          addTransaction({ hash } as any, {
            // summary: `Open order successfully`,
            title: `Cancel all limit order successfully`,
            titleFail: `Cancel all limit order failed`,
            trigger: () => {
              try {
              } catch (e) {}
            },
          });
          await useResponseModal({
            title: t('transaction_submitted'),
            txHash: hash,
          });
        }
        if (err) {
          if (err?.message) {
            if (err?.message) {
              await useResponseModal({ title: t('Cancel all limit order failed'), errorMsg: err?.message });
              return;
            }
          }
          throw err;
        }
        return;
      });
    },
    [web3, account],
  );

  const handleCancelAllLimitOrder = useCallback(
    async (spotManagers: string[]) => {
      Promise.allSettled(spotManagers.map(async (pm) => await handleCancelAllLimitOrderByPair(pm)));
    },
    [web3, account],
  );

  return { handleCancelLimitOrder, handleCancelAllLimitOrderByPair, handleCancelAllLimitOrder };
};

export const useRemoveLiquidity = () => {
  const { t } = useTranslation(['bondExchange']);
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const prices = useGetApiPrices();
  const dispatch = useDispatch();

  const handleRemoveLiquidity = useCallback(
    async (tokenId: string, liquidityPoolAddress: string) => {
      usePendingModalWithStateTrans();
      const liquidityContract = await useGetExchangeLiquidityContractByAddress(liquidityPoolAddress, web3);
      const method = liquidityContract.methods.removeLiquidity(tokenId);
      const gas = await callEstGasForExchange(method, account);

      return method.send({ from: account, gas: Math.floor(gas) }, async function (err, hash) {
        if (hash) {
          addTransaction({ hash } as any, {
            title: `Remove liquidity successful`,
            titleFail: `Remove liquidity failed`,
            trigger: () => {
              try {
                getMyLiquidity(prices, account, dispatch);
                dispatch(fetchUserDataPoolExchange(account));
              } catch (e) {}
            },
          });
          await useResponseModal({
            title: t('transaction_submitted'),
            txHash: hash,
          });
        }
        if (err) {
          if (err?.message) {
            if (err?.message) {
              await useResponseModal({ title: t('Remove liquidity failed'), errorMsg: err?.message });
              return;
            }
          }
          throw err;
        }
        return;
      });
    },
    [web3, account, prices, dispatch],
  );

  return { handleRemoveLiquidity };
};

export const useGetBalanceClaim = (spotManager: string, account: string) => {
  const [formState, setFormSate] = useState({
    quoteAmount: 0,
    baseAmount: 0,
  });
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  const getBalance = useCallback(async () => {
    const getCanClaim = async () => {
      try {
        if (account && spotManager) {
          const { spotHouseAddress, spotHouseABI } = await useGetPositionHouseAddressAndAbi(spotManager);
          const [_dataClaim] = await multicall(spotHouseABI, [
            { address: spotHouseAddress, name: 'getAmountClaimable', params: [spotManager, account] },
          ]);
          const baseAmountData = formatBigNumber(new BigNumber(_dataClaim[1]._hex));
          const quoteAmountData = formatBigNumber(new BigNumber(_dataClaim[0]._hex));
          if (formState?.baseAmount !== baseAmountData || formState?.quoteAmount !== quoteAmountData) {
            setFormSate({
              baseAmount: baseAmountData,
              quoteAmount: quoteAmountData,
            });
            dispatch(setBalanceClaim({ base: baseAmountData, quote: quoteAmountData }));
          }
        }
      } catch (e) {}
    };
    getCanClaim();
  }, [account, spotManager, dispatch, fastRefresh]);
  useEffect(() => {
    getBalance();
  }, [fastRefresh, getBalance]);
};

export const useClaimAsset = () => {
  const { t } = useTranslation(['bondExchange']);
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const handleClaimAsset = useCallback(
    async (spotManager: string) => {
      usePendingModalWithStateTrans();
      const spotExchangeContract = await useGetPositionHouseContract(spotManager, web3);
      const method = spotExchangeContract.methods.claimAsset(spotManager);
      const gas = await callEstGasForExchange(method, account, undefined, 'spot_error_');
      return method.send({ from: account, gas: Math.floor(gas) }, async function (err, hash) {
        if (hash) {
          addTransaction({ hash } as any, {
            title: `Successful Claim`,
            titleFail: `Failed Claim`,
            trigger: () => {
              try {
              } catch (e) {}
            },
          });
          await useResponseModal({ title: t('transaction_submitted'), txHash: hash });
        }
        if (err) {
          if (err?.message) {
            if (err?.message) {
              await useResponseModal({ title: 'Error Claim', errorMsg: err?.message });
              return;
            }
          }
          throw err;
        }
        return;
      });
    },
    [web3, account],
  );
  return { handleClaimAsset };
};

export const useAllowanceTokenLiquidityPoolExchange = (
  tokenBaseAddress: string,
  tokenQuoteAddress: string,
  owner: string,
  liquidityPoolAddress: string,
) => {
  const [isApprove, setIsApprove] = useState({ baseApprove: false, quoteApprove: false });
  const { fastRefresh } = useRefresh();

  const getAllowance = useCallback(async () => {
    if (tokenBaseAddress && tokenBaseAddress && liquidityPoolAddress && owner) {
      const [allowanceBase, allowanceQuote] = await multicall(ERC20_ABI, [
        { address: tokenBaseAddress, name: 'allowance', params: [owner, liquidityPoolAddress] },
        { address: tokenQuoteAddress, name: 'allowance', params: [owner, liquidityPoolAddress] },
      ]);
      if (allowanceBase && allowanceQuote) {
        if (
          new BigNumber(allowanceBase[0].toString()).isGreaterThan(new BigNumber(0)) !== isApprove?.baseApprove ||
          new BigNumber(allowanceQuote[0].toString()).isGreaterThan(new BigNumber(0)) !== isApprove?.quoteApprove
        ) {
          setIsApprove({
            baseApprove: new BigNumber(allowanceBase[0].toString()).isGreaterThan(new BigNumber(0)),
            quoteApprove: new BigNumber(allowanceQuote[0].toString()).isGreaterThan(new BigNumber(0)),
          });
        }
      }
    }
  }, [tokenBaseAddress, tokenQuoteAddress, liquidityPoolAddress, owner]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance, fastRefresh]);

  const setApprovalToken = (field: string) => {
    setIsApprove((state) => ({
      ...state,
      [field]: true,
    }));
  };

  return { isApprove, getAllowance, setApprovalToken };
};

const isBnbInPair = (pair: PairSpotExchange) => {
  const bnbIsBase = pair.base?.symbol.toLowerCase() === 'bnb' || pair.base?.symbol.toLowerCase() === 'wbnb';
  const bnbIsQuote = pair.quote?.symbol.toLowerCase() === 'bnb' || pair.quote?.symbol.toLowerCase() === 'wbnb';

  return { bnbIsBase, bnbIsQuote };
};

export const useAddLiquidityExchange = () => {
  const { t } = useTranslation(['bondExchange']);
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const addTransaction = useTransactionAdder();
  const dispatch = useDispatch();
  const prices = useGetApiPrices();

  const handleAddLiquidity = useCallback(
    async (
      contractAddress: string,
      poolId: string,
      baseAmount: string,
      quoteAmount: string,
      cb?: any,
      cbHash?: (val: string) => void,
    ) => {
      usePendingModalWithStateTrans();
      const liquidityPoolContract = await useGetExchangeLiquidityContractByAddress(contractAddress, web3);

      const poolsExchange = store.getState().bondExchange?.poolsExchange;
      const currentPool = poolsExchange.find((p) => getAddress(p?.poolKey).toLowerCase() === poolId.toLowerCase());

      const { bnbIsBase, bnbIsQuote } = isBnbInPair(currentPool?.pair);

      const method = bnbIsBase
        ? liquidityPoolContract.methods.addLiquidity([
            poolId,
            new BigNumber(quoteAmount || '0').times(new BigNumber(10).pow(18)).toFixed(),
          ])
        : bnbIsQuote
        ? liquidityPoolContract.methods.addLiquidity([
            poolId,
            new BigNumber(baseAmount || '0').times(new BigNumber(10).pow(18)).toFixed(),
          ])
        : liquidityPoolContract.methods.addLiquidity([
            poolId,
            new BigNumber(baseAmount || '0').times(new BigNumber(10).pow(18)).toFixed(),
            new BigNumber(quoteAmount || '0').times(new BigNumber(10).pow(18)).toFixed(),
          ]);

      const gas = await callEstGasForExchange(
        method,
        account,
        bnbIsBase
          ? new BigNumber(baseAmount || '0').times(new BigNumber(10).pow(18)).toFixed()
          : bnbIsQuote
          ? new BigNumber(quoteAmount || '0').times(new BigNumber(10).pow(18)).toFixed()
          : undefined,
      );

      return method.send(
        {
          from: account,
          gas: Math.floor(gas),
          value: bnbIsBase
            ? new BigNumber(baseAmount || '0').times(new BigNumber(10).pow(18)).toFixed()
            : bnbIsQuote
            ? new BigNumber(quoteAmount || '0').times(new BigNumber(10).pow(18)).toFixed()
            : '0x0',
        },
        async function (err, hash) {
          if (hash) {
            cbHash && cbHash(hash);
            addTransaction({ hash } as any, {
              title: `Successful Add Liquidity`,
              titleFail: `Failed Add Liquidity`,
              trigger: () => {
                try {
                  cb && cb();
                  getMyLiquidity(prices, account, dispatch);
                  dispatch(fetchPoolsUserDataAsync(account));
                  dispatch(fetchUserDataPoolExchange(account));
                } catch (e) {}
              },
            });
            await useResponseModal({
              title: t('transaction_submitted'),
              txHash: hash,
            });
          }
          if (err) {
            if (err?.message) {
              if (err?.message) {
                await useResponseModal({ title: t('err_add_liquidity'), errorMsg: err?.message });
                return;
              }
            }
            throw err;
          }
          return;
        },
      );
    },
    [web3, account, prices, dispatch],
  );

  return { handleAddLiquidity };
};

export const useAllowanceCloseLiquidity = (owner: string, spender: string) => {
  const [isApprove, setIsApprove] = useState({ tokenApprove: false });
  const { fastRefresh } = useRefresh();

  const getAllowance = useCallback(async () => {
    const liquidityNftManagerAddress = await useGetLiquidityNftManager();

    if (owner) {
      const [allowance] = await multicall(liquidityNftManagerAbi, [
        { address: liquidityNftManagerAddress, name: 'isApprovedForAll', params: [owner, spender] },
      ]);
      if (allowance) {
        setIsApprove({
          tokenApprove: allowance[0],
        });
      }
    }
  }, [owner]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance, fastRefresh]);

  const setApprovalToken = (field: string) => {
    setIsApprove((state) => ({ ...state, [field]: true }));
  };

  return { isApprove, setApprovalToken };
};
