/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import poolsConfig from '../../configs/pools';
import { fetchPoolsTotalStaking } from './fetchPools';
import {
  fetchPoolsAllowance,
  fetchUserBalance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
  getListCardPending,
  getListCardStaked,
} from './fetchPoolsUser';
import { PoolsState } from './types';
import { LIQUIDITY_NFT, POOL_CATEGORY, PoolType } from '../../configs/types';
import { Pool } from '../types';
import { getPoolApy, getPoolNftApr } from '../../utils/apy';
import { getBondPropertyByUsd } from '../../utils/common';
import BigNumber from 'bignumber.js';
import { getBalanceNumber } from '../../utils/formatBalance';
import { usePosiPerBlockForPool } from '../../hooks/useContract';
import { getAddress } from '../../utils/addressHelpers';

const initialState: PoolsState = { data: [...poolsConfig], history: [], stakedList: [], forceRefresh: 0 };

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload;
      const maxApr = Math.max(
        ...livePoolsData.map((element) => {
          if (element?.isFinished) return 0;
          return Number(element.apr);
        }),
      );
      state.maxApr = maxApr;
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.id === pool.id);
        return { ...pool, ...livePoolData };
      });
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload;
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry: any) => entry.id === pool.id);
        return { ...pool, userData: { ...pool?.userData, ...userPoolData } };
      });
    },
    updatePoolUserDataNft: (state, action: any) => {
      const { field, value } = action.payload;
      if (value && value.length) {
        value.forEach((item) => {
          const index = state.data.findIndex((p) => p.id === item.id);
          if (index > -1) {
            state.data[index] = {
              ...state.data[index],
              userData: { ...(state.data[index] && state.data[index].userData), [field]: item[field] } as any,
            };
          }
        });
      }
    },
    updatePoolUserData: (state, action: any) => {
      const { field, value, id } = action.payload;
      const index = state.data.findIndex((p) => p.id === id);
      if (index > -1) {
        state.data[index] = {
          ...state.data[index],
          userData: { ...(state.data[index] && state.data[index].userData), [field]: value } as any,
        };
      }
    },
    updatePathPoolUserData: (state, action: any) => {
      const { value, id } = action.payload;
      const index = state.data.findIndex((p) => p.id === id);
      if (index > -1) {
        state.data[index] = {
          ...state.data[index],
          userData: { ...(state.data[index] && state.data[index].userData), ...value } as any,
        };
      }
    },
    setForceRefresh: (state) => {
      state.forceRefresh = state.forceRefresh + 1;
    },
  },
});

// Actions
export const {
  setPoolsPublicData,
  updatePathPoolUserData,
  setPoolsUserData,
  updatePoolUserData,
  updatePoolUserDataNft,
  setForceRefresh,
} = PoolsSlice.actions;

// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch: any, getState: any) => {
  const listPrices = getState().prices.data;
  const currentBlock = getState().block.currentBlock;
  if (listPrices && currentBlock) {
    const totalStakings = await fetchPoolsTotalStaking(listPrices);
    const liveData = await Promise.all(
      poolsConfig.map(async (pool) => {
        const totalStaking = totalStakings.find((entry) => entry.id === pool.id);

        if (pool.poolCategory !== POOL_CATEGORY.NFT) {
          const stakingTokenPrice = listPrices[pool.stakingToken.symbol.toLowerCase()];
          const rewardTokenPrice = listPrices[pool.earningToken.symbol.toLowerCase()];

          const posiPerBlock = await usePosiPerBlockForPool(
            pool.sousId,
            pool?.type === PoolType.EarnToken ? getAddress(pool.contractAddress) : undefined,
          );
          if (pool?.sousId === 8) {
            // TODO: remove hard code
            totalStaking.totalStaked = new BigNumber(totalStaking.totalStaked)
              .minus(new BigNumber('7.62249335506112451569429e+23'))
              .toFixed();
          }
          const apr = getPoolApy(
            pool?.poolCategory === POOL_CATEGORY.BOND
              ? getBondPropertyByUsd(totalStaking?.bondData, 'face_value', listPrices)
              : Number(stakingTokenPrice),
            Number(rewardTokenPrice),
            getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals),
            posiPerBlock,
          );

          let isFinished = false;

          if (pool?.endBlock < currentBlock) {
            isFinished = true;
          }

          return {
            ...totalStaking,
            apr,
            isFinished: pool?.type === PoolType.EarnToken ? isFinished : pool.isFinished,
          };
        } else {
          let apr = 0;
          if (!pool.isFinished) {
            if (pool?.stakingToken?.symbol !== LIQUIDITY_NFT.liquidityNFT) {
              apr = await getPoolNftApr(getAddress(pool.contractAddress));
            } else {
              apr = (totalStaking as any).apr;
            }
          }
          return {
            ...totalStaking,
            apr: apr,
          };
        }
      }),
    );

    dispatch(setPoolsPublicData(liveData));
  } else {
    setTimeout(() => {
      dispatch(fetchPoolsPublicDataAsync());
    }, 500);
  }
};

export const fetchPoolsUserDataAsync = (account: string) => async (dispatch: any) => {
  if (account) {
    const allowances: any = await fetchPoolsAllowance(account);
    const stakingTokenBalances: any = await fetchUserBalances(account);
    const stakedBalances: any = await fetchUserStakeBalances(account);
    const pendingRewards: any = await fetchUserPendingRewards(account);
    const userData = poolsConfig.map((pool) => {
      const allowancesPool = allowances.find((z) => z.id === pool.id);
      const pendingRewardsPool = pendingRewards.find((z) => z.id === pool.id);
      const stakedBalancesPool = stakedBalances.find((z) => z.id === pool.id);
      if (pool.poolCategory !== POOL_CATEGORY.NFT) {
        const stakingTokenBalancesPool = stakingTokenBalances.find((z) => z.id === pool.id);
        return {
          id: pool.id,
          allowance: allowancesPool.allowance,
          stakingTokenBalance: stakingTokenBalancesPool.userBalance,
          stakedBalance: stakedBalancesPool.stakedBalance,
          pendingReward: pendingRewardsPool.pendingReward,
        };
      } else {
        return {
          id: pool.id,
          allowance: allowancesPool.allowance,
          stakedBalance: stakedBalancesPool.stakedBalance,
          pendingReward: pendingRewardsPool.pendingReward,
        };
      }
    });
    dispatch(setPoolsUserData(userData));
    if (account) {
      getListCardPending(account);
      getListCardStaked(account);
    }
  }
};

export const fetchPoolsUserDataBalanceAsync = (id: number, account: string) => async (dispatch: any, getState: any) => {
  try {
    const farmList = getState().pools.data || [];
    if (farmList && farmList.length > 0) {
      const existFarm: Pool = farmList.find((f: Pool) => Number(f.id) === id);
      if (existFarm) {
        const stakingTokenBalance: any = await fetchUserBalance(getAddress(existFarm?.stakingToken?.address), account);
        dispatch(updatePathPoolUserData({ id, value: { stakingTokenBalance } }));
        if (account && existFarm?.poolCategory === POOL_CATEGORY.NFT) {
          getListCardPending(account);
          getListCardStaked(account);
        }
      }
    }
  } catch (e) {}
};

export const updateNftListCard = (listCardRemaining: any[], field: string) => async (dispatch: any) => {
  dispatch(updatePoolUserDataNft({ field, value: listCardRemaining }));
};

export const forceRefreshPoolCalculatorLine = () => async (dispatch) => {
  setTimeout(() => {
    dispatch(setForceRefresh());
  }, 400);
};

export default PoolsSlice.reducer;
