import { Button, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

type State = {
  hasError: boolean;
  error: any;
};
type Props = {
  fallback?: React.ReactNode;
};

const ErrorComponent: React.FC<{ error: any }> = ({ error }) => {
  return (
    <>
      <Flex width="100%" height="100vh" justifyContent="center" alignItems="center">
        <Flex mx="auto" flexDirection="column">
          <Image mx="auto" w={['200px', '200px', '300px', '300px', '300px', '300px']} src="/img/error-page.svg" />
          <Text mx="auto" color="white" fontSize="28px" mt="16px" mb="0px" fontWeight="600">
            Whoops!!!
          </Text>
          <Text mx="auto" color="white" fontSize="28px" mt="0px" fontWeight="600">
            Something went wrong!
          </Text>

          <Text mx="auto" textAlign="center" color="#718096" fontSize="18px" mt="0px">
            If anything makes you feel uncomfortable, please report it to us!
          </Text>
          <Text mx="auto" textAlign="center" color="red" fontSize="18px" mt="0px">
            Error: {error?.message || ''}
          </Text>
          <Text mx="auto" textAlign="center" color="red" fontSize="18px" mt="16px">
            {error?.stack || error || ''}
          </Text>

          <Button
            mx="auto"
            borderRadius="32px"
            borderColor="#1AC486"
            width="200px"
            bg="#1AC486"
            _hover={{ bg: '#1AC486' }}
            _active={{ bg: '#1AC486' }}
            height="48px"
            color="white"
            fontWeight="600"
            boxShadow="none"
            onClick={() => {
              window.open('https://t.me/PositionExchange', '_blank');
            }}
          >
            Report Now
          </Button>
          <Button
            mx="auto"
            borderRadius="32px"
            borderColor="#1AC486"
            width="200px"
            bg="#1AC486"
            _hover={{ bg: '#1AC486' }}
            _active={{ bg: '#1AC486' }}
            height="48px"
            color="white"
            fontWeight="600"
            boxShadow="none"
            mt="16px"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any) {
    this.setState({ error: error });
  }

  render() {
    console.log('error', this.state.error);
    if (this.state.error?.name === 'ChunkLoadError') {
      window.location.reload();
      return null;
    }
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorComponent error={this.state.error} />;
    }
    return this.props.children;
  }
}
