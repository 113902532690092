import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllowancePerChain, getTokenAmount } from '../../adapters/services/BridgeAssetService';
import { ChainTypes } from '../../adapters/types';
import { IBridgeAssetDetail } from '../types';

const initialState: IBridgeAssetDetail = {
  sourceChainTokenAmount: 0,
  destinationChainTokenAmount: 0,
  allowanceBridgeContract: 0,
  feeBridgeAsset: 0.3,
  recipient: '',
  relayerFee: 0.1,
  minimumBridgeAmount: {
    POSI: 10,
  },
};

export const bridgeAssetSlice = createSlice({
  name: 'bridgeAsset',
  initialState,
  reducers: {
    setSourceTokenAmount: (state, action: PayloadAction<number>) => {
      state.sourceChainTokenAmount = action.payload;
    },
    setDestinationTokenAmount: (state, action: PayloadAction<number>) => {
      state.destinationChainTokenAmount = action.payload;
    },
    setAllowanceBridgeContract: (state, action: PayloadAction<number>) => {
      state.allowanceBridgeContract = action.payload;
    },
    setRecipient: (state, action: PayloadAction<string>) => {
      state.recipient = action.payload;
    },
  },
});

export const { setSourceTokenAmount, setDestinationTokenAmount, setAllowanceBridgeContract, setRecipient } =
  bridgeAssetSlice.actions;

export const getSourceChainTokenAmount =
  (chainType: ChainTypes, tokenAddress: string, isNative: boolean, account: string) => (dispatch) => {
    if (!account) {
      return dispatch(setSourceTokenAmount(0));
    }
    getTokenAmount(chainType, tokenAddress, isNative, account)
      .then((res) => {
        dispatch(setSourceTokenAmount(res));
      })
      .catch(() => {
        return dispatch(setSourceTokenAmount(0));
      });
  };

export const getDestinationChainTokenAmount =
  (chainType: ChainTypes, tokenAddress: string, isNative: boolean, account: string) => (dispatch) => {
    if (!account) {
      return dispatch(setDestinationTokenAmount(0));
    }
    getTokenAmount(chainType, tokenAddress, isNative, account)
      .then((res) => {
        dispatch(setDestinationTokenAmount(res));
      })
      .catch(() => {
        return dispatch(setDestinationTokenAmount(0));
      });
  };

// export const getAllTokenAmount = async () => (dispatch) => {};

export const getAllowanceSourceChainToken =
  (chainType: ChainTypes, account: string, spenderAddress: string, tokenAddress: string) => (dispatch) => {
    if (!account) {
      return dispatch(setAllowanceBridgeContract(0));
    }
    getAllowancePerChain(chainType, account, spenderAddress, tokenAddress)
      .then((res) => {
        dispatch(setAllowanceBridgeContract(res));
      })
      .catch(() => {
        return dispatch(setAllowanceBridgeContract(0));
      });
  };

export default bridgeAssetSlice.reducer;
