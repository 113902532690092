export const TRADE_TYPE = ['buy', 'sell'];
export const ORDER_TYPE = ['limit', 'market'];

export enum TypeOfTradeConfig {
  BUY = 'buy',
  SELL = 'sell',
}

export enum OptionTradeConfig {
  TRADE_TYPE = 'tradeType',
}

export enum TypeOfOrderConfig {
  LIMIT = 'limit',
  MARKET = 'market',
}

export enum TypeOfNameConfig {
  POST = 'poss',
  IOC = 'ioc',
}

export enum TypeOfInputConfig {
  QUANTITY_QUOTE = 'quantity_quote',
  QUANTITY_BASE = 'quantity_base',
  QUANTITY_TP = 'quantity_tp',
  QUANTITY_SL = 'quantity_sl',
  GAIN = 'gain',
  LOSS = 'loss'
}

export enum DataTypeConfig {
  NUMBER = 'number'
}

export const statusColor = {
  pending: 'purple.400',
  cancelled: 'red.400',
  filled: 'teal.150',
  partial_filled: 'white',
}

export const typeOrder = {
  limit: 'limit',
  market: 'market'
}

export const typeTrade = {
  buy: 'buy',
  sell: 'sell'
}

export const statusOrder = {
  pending: 'pending',
  partial_filled: 'partial_filled',
  filled: 'filled',
  cancelled: 'cancelled'
}

export enum LiquidityStakeStatus {
  STAKED = 'Staking',
  UNSTAKED = '--',
}