import { useMediaQuery } from '@chakra-ui/react';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import ButtonComponent from '../Button';
import { useWalletModal } from '../WalletModal';

interface IConnectToWallet {
  [key: string]: any;
  hidden?: boolean;
  size?: 'button1' | 'button2' | 'button3';
}

const ConnectToWallet: React.FC<IConnectToWallet> = memo(({ hidden, size, ...rest }) => {
  const { login, logout } = useAuth();
  const { t } = useTranslation('pool');
  const { onPresentConnectModal } = useWalletModal(login, logout);
  const [isSmallThan480] = useMediaQuery('(max-width: 480px ) and (min-width: 0px )');

  return (
    <ButtonComponent
      hidden={hidden}
      variant="contained"
      size={size || isSmallThan480 ? 'button3' : 'button2'}
      {...rest}
      onClick={onPresentConnectModal}
    >
      {isSmallThan480 ? t('connect') : t('connect_wallet')}
    </ButtonComponent>
  );
});

export default ConnectToWallet;
