import { Flex, Spacer, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetApiPrice } from '../../redux/hooks';
import { formatBalanceWithNumeral, formatBalanceWithNumerals } from '../../utils/common';

interface LockedHarvestOverviewProps {
  dataState: any;
  showBalance: boolean;
}

const LockedHarvestOverview: React.FC<LockedHarvestOverviewProps> = ({ dataState, showBalance }) => {
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px ) and (min-width: 0px )');
  const renderTotalLocked = (price: any) => {
    if (price) {
      if (isSmallerThan768) {
        return `${formatBalanceWithNumerals(Number(price), 4)}`;
      }
      return `${formatBalanceWithNumeral(Number(price), 6)}`;
    }
    if (isSmallerThan768) {
      return `${formatBalanceWithNumerals(Number(0), 4)}`;
    }
    return `${formatBalanceWithNumeral(Number(0), 6)}`;
  };
  const pricePosi = useGetApiPrice('ptx');

  const { t } = useTranslation('header');
  return (
    <Flex direction={'column'} className="slide-in-content" position="relative" width="100%">
      <Flex
        w={'100%'}
        _hover={{
          cursor: 'pointer',
        }}
        className="parent-modal-balance-row"
        alignItems="center"
        direction={'column'}
        justifyContent={'center'}
      >
        <Flex alignItems="center" justify="center" my="8px">
          <Flex ml="10px" textAlign="center">
            <Text
              color={'gray.500'}
              fontFamily={'Poppins'}
              fontStyle={'normal'}
              fontWeight={'500'}
              fontSize={['12px', '14px', '14px', '14px', '14px']}
            >{`${t('total_harvest')}`}</Text>
          </Flex>
        </Flex>
        <Spacer />
        <Flex alignItems="center">
          <Flex justifyContent="end" flexDirection="column" textAlign="center" w="100%">
            <Text
              fontFamily={'Poppins'}
              fontStyle={'normal'}
              fontWeight={'600'}
              fontSize={'24px'}
              color={'teal.150'}
              mb={['0px', '10px', '10px', '10px', '10px']}
            >
              {showBalance ? renderTotalLocked(Number(dataState?.totalUsdEarning) / Number(pricePosi || 1)) : '✲✲✲✲✲✲'}{' '}
              PTX
            </Text>
            <Text color={'cyan.400'} fontStyle={'normal'} fontSize={['12px', '13px', '13px', '13px', '13px']}>
              {showBalance ? `~$${renderTotalLocked(dataState?.totalUsdEarning)}` : '✲✲✲✲✲✲'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LockedHarvestOverview;
