import { Flex, useMediaQuery } from '@chakra-ui/react';
import MenuItem from './components/MenuItem';

const MenuPc = () => {
  const [isSmallThan1110] = useMediaQuery('(max-width: 1110px ) and (min-width: 0px )');

  return (
    <Flex display={isSmallThan1110 ? 'none' : 'flex'} h="100%">
      <MenuItem />
    </Flex>
  );
};

export default MenuPc;
