import { createSlice } from '@reduxjs/toolkit';
import { AddNewRecipient, ListRecipient } from '../types';
import { checkBoolean } from './hooks';

interface RecipientState {
  showRecipient: boolean;
  addNewRecipient: AddNewRecipient;
  listRecipient: ListRecipient[];
  itemRecipient: ListRecipient;
}

const initialState: RecipientState = {
  showRecipient: checkBoolean(localStorage.getItem('showRecipient')),
  addNewRecipient: AddNewRecipient.default,
  listRecipient: [],
  itemRecipient: null,
};

export const recipientSlice = createSlice({
  name: 'recipient',
  initialState,
  reducers: {
    setRecipientSwap: (state, action) => {
      const data = state;
      data.showRecipient = checkBoolean(action.payload);
      return data;
    },
    setPageAddRecipient: (state, action) => {
      const data = state;
      data.addNewRecipient = action.payload;
      return data;
    },
    setListRecipient: (state, action) => {
      const data = state;
      data.listRecipient = action.payload;
      return data;
    },
    setItemRecipient: (state, action) => {
      const data = state;
      data.itemRecipient = action.payload;
      return data;
    },
  },
});

export const { setRecipientSwap, setPageAddRecipient, setListRecipient, setItemRecipient } = recipientSlice.actions;

export default recipientSlice.reducer;
