import tokens from './tokens';
import { VaultConfig } from './types';

const vaults: VaultConfig[] = [
  {
    pid: 0,
    id: 1,
    rewardPriceLabel: 'POSI',
    tokenSymbol: 'BUSD',
    vaultAddress: {
      97: '0x69ec4d70ac5d2d2db496a158e5628862824944dc', //0x2A11b3c92906BB70962E289DccC373606cbC893E old one
      56: '0xf35848441017917a034589BfbEc4B3783BB39cb2',
    },
    pairAddress: {
      97: '0x787cF64b9F6E3d9E120270Cb84e8Ba1Fe8C1Ae09',
      56: '0x00222d964a2077301309809ab3bf56485c126a9c',
    },
    token: tokens.busd,
    isEmergencyWithdraw: true,
    isFinished: false,
    time: 12,
  },
  {
    pid: 2,
    id: 2,
    formatDecimals: 8,
    rewardPriceLabel: 'POSI_IN_BNB',
    tokenSymbol: 'BNB',
    vaultAddress: {
      97: '0x69ec4d70ac5d2d2db496a158e5628862824944dc',
      56: '0xc1742a30b7469f49f37239b1c2905876821700e8',
    },
    pairAddress: {
      97: '0x787cF64b9F6E3d9E120270Cb84e8Ba1Fe8C1Ae09',
      56: '0x254baa324a7e8876f4d51c3eff4b962f16672c5f',
    },
    token: tokens.bnb,
    isEmergencyWithdraw: true,
    isFinished: false,
    time: 12,
  },
];

export default vaults;
