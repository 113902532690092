import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isAddress } from 'web3-utils';
import { ADDRESS0 } from '../configs';
import { useGetApiPrice, useGetApiPrices } from '../redux/hooks';
import { useTransactionAdder } from '../redux/transactions/hooks';
import { Vault } from '../redux/types';
import { fetchVaultUserDataAsync } from '../redux/vault';
import { callEstGas, callEstGasVault } from '../utils/callHelpers';
import { calculateNetworkFee, formatNumberWithNumeral } from '../utils/common';
import { confirmModal, confirmNetWorkFeeModalModal, usePendingModal, useResponseModal } from '../utils/confirmModal';
import { getPosiVaultContract } from '../utils/contractHelpers';
import getNodeUrl from '../utils/getRpcUrl';
import useWeb3 from './useWeb3';

export const getAddressFromVaultReferralCode = async (code: string | number) => {
  if (!new BigNumber(code).isEqualTo(0)) {
    if (isAddress(code.toString())) {
      return code;
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL}/getRefAddressByVaultCode?code=${code}`,
        ).then((res) => res.json());
        if (response?.success) {
          return response?.address;
        } else {
          return ADDRESS0;
        }
      } catch (e) {
        return ADDRESS0;
      }
    }
  } else {
    return ADDRESS0;
  }
};

const node = getNodeUrl();
const provider = new ethers.providers.JsonRpcProvider(node);

export async function waitForReceipt(web3, hash, cb) {
  web3.eth.handleRevert = true;
  await web3.eth.getTransactionReceipt(hash, function (err, receipt) {
    if (err) {
      throw Error(err.message);
    }
    if (receipt !== null) {
      // Transaction went through
      if (cb) {
        cb(receipt);
      }
    } else {
      // Try again in 1 second
      window.setTimeout(function () {
        waitForReceipt(web3, hash, cb);
      }, 1000);
    }
  });
}

export async function getRevertReason(web3, txHash) {
  try {
    const tx = await web3.eth.getTransaction(txHash);
    let result = await web3.eth.call(tx, tx.blockNumber);
    result = result.startsWith('0x') ? result : `0x${result}`;

    if (result && result.substr(138)) {
      const reason = web3.utils.toAscii(result.substr(138));
      return reason;
    } else {
      return 'Transaction failed';
    }
  } catch (err) {
    return err?.message || '';
  }
}

export function waitForReceiptWithEther(hash) {
  try {
    return provider.getTransactionReceipt(hash);
  } catch (err) {
    return err?.message || '';
  }
}

export const onUseVault = (vid: number) => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const vaultContract = getPosiVaultContract(vid, web3);
  const priceBNB = useGetApiPrice('bnb');
  const pricePOSI = useGetApiPrice('posi');
  const prices = useGetApiPrices();
  const { t } = useTranslation(['pool', 'vault', 'farm', 'swap']);
  const dispatch = useDispatch();
  const addTransaction = useTransactionAdder();

  const handleClaimBounty = useCallback(async () => {
    try {
      const method = vaultContract.methods.compound();
      const gas = await callEstGas(method, account as string);
      const networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
      const result = await confirmModal({
        title: t('vault:claim_title'),
        content: t('vault:claim_bounty_content', {
          network_fee: `<span class="white-text">${formatNumberWithNumeral(networkFeeBNB, 6)}</span>`,
          network_fee_symbol: `<span class="teal-text">BNB</span>`,
          network_fee_usd: formatNumberWithNumeral(networkFeeBNB * priceBNB, 6),
        }),
      });
      if (result) {
        usePendingModal({ titleContent: t('vault:waiting_transaction') });

        method.send({ from: account, gas: Math.floor(gas * 1.5) }, async function (err, hash) {
          if (hash) {
            addTransaction({ hash } as any, {
              summary: `Claim bounty success`,
              title: 'Transaction receipt',
              titleFail: 'Transaction receipt',
            });
            await useResponseModal({
              title: t('vault:transaction_submitted'),
              txHash: hash,
            });
          }
          if (err) {
            if (err?.message) {
              if (err?.message) {
                await useResponseModal({ title: t('vault:claim_failed'), errorMsg: err?.message });
              }
            }
            throw err;
          }
        });
        return true;
      }
    } catch (err: any) {
      if (err?.message) {
        await useResponseModal({ title: t('vault:claim_failed'), errorMsg: err?.message });
      }
      throw err;
    }
  }, [account, vaultContract]);

  const calculateGasEstDeposit = useCallback(
    async (maxBalanceG: string | number, code: string | number) => {
      try {
        const addressReferral = await getAddressFromVaultReferralCode(code);
        const method = vaultContract.methods.deposit(addressReferral);
        const gas = await callEstGasVault(
          method,
          account as string,
          new BigNumber(maxBalanceG).times(new BigNumber(10).pow(18)).toFixed(),
        );
        const networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
        return networkFeeBNB;
      } catch (e) {}
    },
    [priceBNB, vaultContract, account],
  );

  const calculateGasEstDepositPair = useCallback(
    async (amountA: string, code: string | number) => {
      try {
        const amountBNB = Number(amountA) * (pricePOSI / priceBNB);
        const addressReferral = await getAddressFromVaultReferralCode(code);
        const method = vaultContract.methods.depositTokenPair(
          new BigNumber(amountA).times(new BigNumber(10).pow(18)).toFixed(),
          addressReferral,
        );
        if (amountBNB && amountBNB > 0.02) {
          const gas = await callEstGasVault(
            method,
            account as string,
            new BigNumber(amountBNB).times(new BigNumber(10).pow(18)).toFixed(),
          );
          const networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
          return networkFeeBNB;
        }
      } catch (e) {}
    },
    [priceBNB, vaultContract, account, pricePOSI, priceBNB],
  );

  const handleStakeTokenIntoVault = useCallback(
    async (amount: string, vault: Vault, code: string | number, gasLimit?: number) => {
      try {
        const addressReferral = await getAddressFromVaultReferralCode(code);
        let method;
        if (vault.tokenSymbol === 'BNB') {
          method = vaultContract.methods.deposit(addressReferral);
        } else {
          method = vaultContract.methods.deposit(
            new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
            addressReferral,
          );
        }
        let networkFeeBNB;
        let gas;
        if (vault.tokenSymbol === 'BNB') {
          gas = await callEstGas(
            method,
            account as string,
            new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
          );
          networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
          networkFeeBNB = gasLimit;
        } else {
          gas = await callEstGas(method, account as string);
          networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
        }
        const result = await confirmNetWorkFeeModalModal({
          title: t('vault:Confirmation_for_stake'),
          content: t('vault:confirm_content', {
            type: t('farm:Stake'),
            balance: `<span class="white-text">${amount}</span>`,
            symbol: `<span class="teal-text">${vault?.tokenSymbol}</span>`,
            network_fee: `<span class="white-text">${formatNumberWithNumeral(networkFeeBNB, 6)}</span>`,
            network_fee_symbol: `<span class="teal-text">BNB</span>`,
            network_fee_usd: formatNumberWithNumeral(networkFeeBNB * priceBNB, 6),
          }),
          listNote: [
            `${t('vault:note_1', {
              balance: `<span class="white-text">${amount}</span>`,
              symbol: `<span class="teal-text">${vault?.tokenSymbol}</span>`,
            })}`,
            `${t('vault:note_2')}`,
          ],
        });
        if (result) {
          usePendingModal({ titleContent: t('vault:waiting_transaction') });
          if (vault.tokenSymbol === 'BNB') {
            method.send(
              {
                from: account,
                gas: Math.floor(gas * 1.5),
                value: new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
              },
              async function (err, hash) {
                if (hash) {
                  addTransaction({ hash } as any, {
                    summary: `Staked ${amount} BNB in BNB Vault`,
                    title: 'Successful Stake',
                    titleFail: 'Failed Stake',
                    trigger: () => {
                      dispatch(fetchVaultUserDataAsync(account));
                      const amountGtag = (Number(amount) * Number(prices[vault?.tokenSymbol?.toLowerCase()])).toFixed(
                        4,
                      );
                      window.gtag('event', `purchase`, {
                        transaction_id: hash || '',
                        value: amountGtag,
                        currency: 'USD',
                        items: [
                          {
                            id: `vault_p1`,
                            name: `Vault ${vault?.tokenSymbol}`,
                            category: 'Vault',
                            quantity: 1,
                            price: amountGtag,
                          },
                        ],
                      });
                    },
                  });
                  await useResponseModal({
                    title: t('vault:transaction_submitted'),
                    txHash: hash,
                  });
                }
                if (err) {
                  if (err?.message && err?.code === 4001) {
                    await useResponseModal({
                      title: t('vault:stake_failed'),
                      errorMsg: t('vault:stake_failed_description'),
                    });
                  }
                  throw err;
                }
              },
            );
            return true;
          } else {
            method.send(
              {
                from: account,
                gas: Math.floor(gas * 1.5),
              },
              async function (err, hash) {
                if (hash) {
                  addTransaction({ hash } as any, {
                    summary: `Staked ${amount} BUSD in BUSD Vault`,
                    title: 'Successful Stake',
                    titleFail: 'Failed Stake',
                    trigger: () => {
                      dispatch(fetchVaultUserDataAsync(account));
                      const amountGtag = (Number(amount) * Number(prices[vault?.tokenSymbol?.toLowerCase()])).toFixed(
                        4,
                      );
                      window.gtag('event', `purchase`, {
                        transaction_id: hash || '',
                        value: amountGtag,
                        currency: 'USD',
                        items: [
                          {
                            id: `vault_p1`,
                            name: `Vault ${vault?.tokenSymbol}`,
                            category: 'Vault',
                            quantity: 1,
                            price: amountGtag,
                          },
                        ],
                      });
                    },
                  });
                  await useResponseModal({
                    title: t('vault:transaction_submitted'),
                    txHash: hash,
                  });
                }
                if (err) {
                  if (err?.message && err?.code === 4001) {
                    await useResponseModal({
                      title: t('vault:stake_failed'),
                      errorMsg: t('vault:stake_failed_description'),
                    });
                  }
                  throw err;
                }
              },
            );
            return true;
          }
        }
      } catch (err: any) {
        if (err?.message) {
          await useResponseModal({ title: t('vault:stake_failed'), errorMsg: t('vault:stake_failed_description') });
        }
        throw err;
      }
    },
    [account, vaultContract, prices],
  );

  const handleStakeLpTokenIntoVault = useCallback(
    async (amount: string, vault: Vault, code: string | number) => {
      try {
        const addressReferral = await getAddressFromVaultReferralCode(code);
        const method = vaultContract.methods.depositLP(
          new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
          addressReferral,
        );
        const tokenSymbol = vault.tokenSymbol;
        const gas = await callEstGas(method, account as string);
        const networkFeeBNB = calculateNetworkFee(Math.floor(gas * 2));
        const result = await confirmNetWorkFeeModalModal({
          title: t('vault:Confirmation_for_stake'),
          content: t('vault:confirm_content', {
            type: t('farm:Stake'),
            balance: `<span class="white-text">${amount}</span>`,
            symbol: `<span class="teal-text">POSI-${tokenSymbol} LP</span>`,
            network_fee: `<span class="white-text">${formatNumberWithNumeral(networkFeeBNB, 6)}</span>`,
            network_fee_symbol: `<span class="teal-text">BNB</span>`,
            network_fee_usd: formatNumberWithNumeral(networkFeeBNB * priceBNB, 6),
          }),
          listNote: [
            `${t('vault:note_1', {
              balance: `<span class="white-text">${amount}</span>`,
              symbol: `<span class="teal-text">POSI-${tokenSymbol} LP</span>`,
            })}`,
            `${t('vault:note_refund')}`,
          ],
        });
        if (result) {
          usePendingModal({ titleContent: t('vault:waiting_transaction') });
          method.send({ from: account, gas: Math.floor(gas * 2) }, async function (err, hash) {
            if (hash) {
              addTransaction({ hash } as any, {
                summary: `Staked ${amount} POSI-${tokenSymbol} LP in ${tokenSymbol} Vault`,
                title: 'Successful Stake',
                titleFail: 'Failed Stake',
                trigger: () => {
                  dispatch(fetchVaultUserDataAsync(account));
                  const amountGtag = (
                    Number(amount) * Number(prices[`posi_${vault?.tokenSymbol?.toLowerCase()}_lp`])
                  ).toFixed(4);
                  window.gtag('event', `purchase`, {
                    transaction_id: hash || '',
                    value: amountGtag,
                    currency: 'USD',
                    items: [
                      {
                        id: `vault_p1`,
                        name: `Vault ${vault?.tokenSymbol}`,
                        category: 'Vault',
                        quantity: 1,
                        price: amountGtag,
                      },
                    ],
                  });
                },
              });
              await useResponseModal({
                title: t('vault:transaction_submitted'),
                txHash: hash,
              });
            }
            if (err) {
              if (err?.message) {
                if (err?.message) {
                  await useResponseModal({
                    title: t('vault:stake_failed'),
                    errorMsg: t('vault:unstake_failed_description'),
                  });
                }
              }
              throw err;
            }
          });
          return true;
        }
      } catch (err: any) {
        if (err?.message) {
          await useResponseModal({ title: t('vault:stake_failed'), errorMsg: t('vault:unstake_failed_description') });
        }
        throw err;
      }
    },
    [account, vaultContract, prices],
  );

  const handleStakePairIntoVault = useCallback(
    async (amountA: string, amountB: string, vault: Vault, code: string | number, gasLimit?: number) => {
      try {
        const addressReferral = await getAddressFromVaultReferralCode(code);
        let method;
        if (vault.tokenSymbol === 'BNB') {
          method = vaultContract.methods.depositTokenPair(
            new BigNumber(amountB).times(new BigNumber(10).pow(18)).toFixed(),
            addressReferral,
          );
        } else {
          method = vaultContract.methods.depositTokenPair(
            new BigNumber(amountA).times(new BigNumber(10).pow(18)).toFixed(),
            new BigNumber(amountB).times(new BigNumber(10).pow(18)).toFixed(),
            addressReferral,
          );
        }
        let networkFeeBNB;
        let gas;
        if (vault.tokenSymbol === 'BNB') {
          gas = await callEstGas(
            method,
            account as string,
            new BigNumber(amountA).times(new BigNumber(10).pow(18)).toFixed(),
          );
          networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
          networkFeeBNB = gasLimit;
        } else {
          gas = await callEstGas(method, account as string);
          networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
        }
        const result = await confirmNetWorkFeeModalModal({
          title: t('vault:Confirmation_for_stake'),
          content: t('vault:confirm_content_pair', {
            type: t('farm:Stake'),
            balance: `<span class="white-text">${vault?.tokenSymbol === 'BNB' ? amountB : amountA}</span>`,
            symbol: `<span class="teal-text">${'POSI'}</span>`,
            balance2: `<span class="white-text">${vault?.tokenSymbol === 'BNB' ? amountA : amountB}</span>`,
            symbol2: `<span class="teal-text">${vault?.tokenSymbol}</span>`,
            network_fee: `<span class="white-text">${formatNumberWithNumeral(networkFeeBNB, 6)}</span>`,
            network_fee_symbol: `<span class="teal-text">BNB</span>`,
            network_fee_usd: formatNumberWithNumeral(networkFeeBNB * priceBNB, 6),
          }),
          listNote: [
            `${t('vault:note_pair_1', {
              balance: `<span class="white-text">${vault?.tokenSymbol === 'BNB' ? amountB : amountA}</span>`,
              symbol: `<span class="teal-text">${'POSI'}</span>`,
              balance2: `<span class="white-text">${vault?.tokenSymbol === 'BNB' ? amountA : amountB}</span>`,
              symbol2: `<span class="teal-text">${vault?.tokenSymbol}</span>`,
            })}`,
            `${t('vault:note_refund')}`,
          ],
        });
        if (result) {
          usePendingModal({ titleContent: t('vault:waiting_transaction') });
          if (vault.tokenSymbol === 'BNB') {
            method.send(
              {
                from: account,
                gas: Math.floor(gas * 1.5),
                value: new BigNumber(amountA).times(new BigNumber(10).pow(18)).toFixed(),
              },
              async function (err, hash) {
                if (hash) {
                  addTransaction({ hash } as any, {
                    summary: `Staked ${amountB} POSI and ${amountA} ${vault?.tokenSymbol} in ${vault?.tokenSymbol} Vault`,
                    title: 'Successful Stake',
                    titleFail: 'Failed Stake',
                    trigger: () => {
                      const amountGtag = (
                        Number(amountA) * Number(prices[vault?.tokenSymbol?.toLowerCase()]) +
                        Number(amountB) * Number(pricePOSI)
                      ).toFixed(4);
                      window.gtag('event', `purchase`, {
                        transaction_id: hash || '',
                        value: amountGtag,
                        currency: 'USD',
                        items: [
                          {
                            id: `vault_p1`,
                            name: `Vault ${vault?.tokenSymbol}`,
                            category: 'Vault',
                            quantity: 1,
                            price: amountGtag,
                          },
                        ],
                      });
                    },
                  });
                  await useResponseModal({
                    title: t('vault:transaction_submitted'),
                    txHash: hash,
                  });
                }
                if (err) {
                  if (err?.message) {
                    if (err?.message) {
                      await useResponseModal({
                        title: t('vault:stake_failed'),
                        errorMsg: t('vault:stake_failed_description'),
                      });
                    }
                  }
                  throw err;
                }
              },
            );
          } else {
            method.send(
              {
                from: account,
                gas: Math.floor(gas * 1.5),
              },
              async function (err, hash) {
                if (hash) {
                  addTransaction({ hash } as any, {
                    summary: `Staked ${amountB} POSI and ${amountA} ${vault?.tokenSymbol} in ${vault?.tokenSymbol} Vault`,
                    title: 'Successful Stake',
                    titleFail: 'Failed Stake',
                    trigger: () => {
                      dispatch(fetchVaultUserDataAsync(account));
                      const amountGtag =
                        vault.tokenSymbol === 'BNB'
                          ? (
                              Number(amountA) * Number(prices[vault?.tokenSymbol?.toLowerCase()]) +
                              Number(amountB) * Number(pricePOSI)
                            ).toFixed(4)
                          : (
                              Number(amountA) * Number(pricePOSI) +
                              Number(amountB) * Number(prices[vault?.tokenSymbol?.toLowerCase()])
                            ).toFixed(4);
                      window.gtag('event', `purchase`, {
                        transaction_id: hash || '',
                        value: amountGtag,
                        currency: 'USD',
                        items: [
                          {
                            id: `vault_p1`,
                            name: `Vault ${vault?.tokenSymbol}`,
                            category: 'Vault',
                            quantity: 1,
                            price: amountGtag,
                          },
                        ],
                      });
                    },
                  });
                  await useResponseModal({
                    title: t('vault:transaction_submitted'),
                    txHash: hash,
                  });
                }
                if (err) {
                  if (err?.message) {
                    if (err?.message) {
                      await useResponseModal({
                        title: t('vault:stake_failed'),
                        errorMsg: t('vault:stake_failed_description'),
                      });
                    }
                  }
                  throw err;
                }
              },
            );
          }
          return true;
        }
      } catch (err: any) {
        if (err?.message) {
          await useResponseModal({ title: t('vault:stake_failed'), errorMsg: t('vault:stake_failed_description') });
        }
        throw err;
      }
    },
    [account, vaultContract, pricePOSI, prices],
  );

  const handleUnstakeTokenIntoVault = useCallback(
    async (amount: string, vault: Vault, isReceiveBUSD: boolean) => {
      try {
        const method = vaultContract.methods.withdraw(
          new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
          isReceiveBUSD,
        );
        const posiPrice = prices['posi'];
        const quoteTokenPrice = prices[vault?.tokenSymbol?.toLowerCase()];
        const totalPriceStaked = Number(amount) * quoteTokenPrice;
        const posiAmount = totalPriceStaked / (2 * posiPrice) ?? '--';
        const quoteTokenAmount = totalPriceStaked / (2 * quoteTokenPrice) ?? '--';
        const customReceiveDetail = `<span>
        <span class="white-text">${formatNumberWithNumeral(posiAmount)}</span>
        <span class="teal-text">POSI</span>
        <span class="sub_text">and</span>
        <span class="white-text">${formatNumberWithNumeral(quoteTokenAmount)}</span>
        <span class="teal-text">${vault.tokenSymbol}</span>
        </span>`;
        const defaultReceive = `<span class="white-text">${formatNumberWithNumeral(amount)}</span> `;
        const gas = await callEstGas(method, account as string);
        const networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
        const result = await confirmNetWorkFeeModalModal({
          title: t('vault:Confirmation_for_unstake'),
          content: t('vault:confirm_content_unstake', {
            type: t('farm:Unstake'),
            balance: `${isReceiveBUSD ? defaultReceive : customReceiveDetail}`,
            symbol: `<span class="teal-text">${isReceiveBUSD ? vault?.tokenSymbol : ''}</span>`,
            network_fee: `<span class="white-text">${formatNumberWithNumeral(networkFeeBNB, 6)}</span>`,
            network_fee_symbol: `<span class="teal-text">BNB</span>`,
            network_fee_usd: formatNumberWithNumeral(networkFeeBNB * priceBNB, 6),
          }),
          listNote: [`${t('vault:note_refund')}`],
        });
        if (result) {
          usePendingModal({ titleContent: t('vault:waiting_transaction') });
          method.send(
            {
              from: account,
              gas: Math.floor(gas * 1.5),
            },
            async function (err, hash) {
              if (hash) {
                addTransaction({ hash } as any, {
                  summary: `Unstaked ${amount} ${vault?.tokenSymbol} from ${vault?.tokenSymbol} Vault`,
                  title: 'Unstaked successfully',
                  titleFail: 'Failed to Unstake',
                  trigger: () => {
                    dispatch(fetchVaultUserDataAsync(account));

                    const amountGtag = (Number(amount) * Number(pricePOSI)).toFixed(4);
                    window.gtag('event', `refund`, {
                      transaction_id: hash || '',
                      value: amountGtag,
                      currency: 'USD',
                      items: [
                        {
                          id: `vault_p1`,
                          name: `Vault ${vault?.tokenSymbol}`,
                          category: 'Vault',
                          quantity: 1,
                          price: amountGtag,
                        },
                      ],
                    });
                  },
                });
                await useResponseModal({
                  title: t('vault:transaction_submitted'),
                  txHash: hash,
                });
              }
              if (err) {
                if (err?.message) {
                  if (err?.message) {
                    await useResponseModal({ title: t('vault:unstake_failed'), errorMsg: err?.message });
                  }
                }
                throw err;
              }
            },
          );

          return true;
        }
      } catch (err: any) {
        if (err?.message) {
          await useResponseModal({ title: t('vault:unstake_failed'), errorMsg: err?.message });
        }
        throw err;
      }
    },
    [account, vaultContract, pricePOSI],
  );

  const handleUnstakeLPIntoVault = useCallback(
    async (amount: string, vault: Vault) => {
      try {
        const method = vaultContract.methods.withdrawLP(
          new BigNumber(amount).times(new BigNumber(10).pow(18)).toFixed(),
        );
        const gas = await callEstGas(method, account as string);
        const networkFeeBNB = calculateNetworkFee(Math.floor(gas * 1.5));
        const result = await confirmNetWorkFeeModalModal({
          title: t('vault:Confirmation_for_unstake'),
          content: t('vault:confirm_content_unstake', {
            type: t('farm:Unstake'),
            balance: `<span class="white-text">${amount}</span>`,
            symbol: `<span class="teal-text">${`POSI-${vault?.tokenSymbol}-LP`}</span>`,
            network_fee: `<span class="white-text">${formatNumberWithNumeral(networkFeeBNB, 6)}</span>`,
            network_fee_symbol: `<span class="teal-text">BNB</span>`,
            network_fee_usd: formatNumberWithNumeral(networkFeeBNB * priceBNB, 6),
          }),
          listNote: [`${t('vault:note_refund')}`],
        });
        if (result) {
          usePendingModal({ titleContent: t('vault:waiting_transaction') });

          method.send(
            {
              from: account,
              gas: Math.floor(gas * 1.5),
            },
            async function (err, hash) {
              if (hash) {
                addTransaction({ hash } as any, {
                  summary: `Unstaked ${amount} POSI-${vault?.tokenSymbol}-LP from ${vault?.tokenSymbol} Vault`,
                  title: 'Unstaked successfully',
                  titleFail: 'Failed to Unstake',
                  trigger: () => {
                    dispatch(fetchVaultUserDataAsync(account));
                    const amountGtag = (Number(amount) * Number(pricePOSI)).toFixed(4);
                    window.gtag('event', `refund`, {
                      transaction_id: hash || '',
                      value: amountGtag,
                      currency: 'USD',
                      items: [
                        {
                          id: `vault_p1`,
                          name: `Vault ${vault?.tokenSymbol}`,
                          category: 'Vault',
                          quantity: 1,
                          price: amountGtag,
                        },
                      ],
                    });
                  },
                });
                await useResponseModal({
                  title: t('vault:transaction_submitted'),
                  txHash: hash,
                });
              }
              if (err) {
                if (err?.message) {
                  if (err?.message) {
                    await useResponseModal({ title: t('vault:unstake_failed'), errorMsg: err?.message });
                  }
                }
                throw err;
              }
            },
          );

          return true;
        }
      } catch (err: any) {
        if (err?.message) {
          await useResponseModal({ title: t('vault:unstake_failed'), errorMsg: err?.message });
        }
        throw err;
      }
    },
    [account, vaultContract, pricePOSI],
  );
  return {
    handleStakeTokenIntoVault,
    handleUnstakeLPIntoVault,
    handleUnstakeTokenIntoVault,
    handleClaimBounty,
    handleStakeLpTokenIntoVault,
    handleStakePairIntoVault,
    calculateGasEstDepositPair,
    calculateGasEstDeposit,
  };
};
