import React from 'react';
import { Flex, Image, Spacer, useMediaQuery, useColorModeValue, Link as LinkCharkra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHasNewBond } from '../../../../redux/global/reducer';
import { useHasNewBond } from '../../../../hooks/useBondState';
import { useIsClickNewBond } from '../../../../redux/hooks';
import useModal from '../../../UseModal/Modal';
import InDevelopModal from '../../../InDevelopModal.tsx';
import Typography from '../../../Typography';

const ItemDetailMenu: React.FC<{ data: any; isGovernance: boolean; newReferralComp: boolean; newBuyingComp: boolean }> =
  ({ data, newBuyingComp, newReferralComp, isGovernance }) => {
    const { t } = useTranslation(['swap', 'header']);
    const [isSmallerThan768] = useMediaQuery('(max-width: 768px ) and (min-width: 0px )');
    const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px ) and (min-width: 0px )');
    const whiteToGray600 = useColorModeValue('gray.600', '#fff');
    const gray500ToGray400 = useColorModeValue('gray.400', 'gray.500');
    const gray700ToGray50 = useColorModeValue('gray.50', 'gray.700');

    // render content detail of menu
    const detailComponent = (data: any, href: string) => {
      const { title, imgUrl, description, isBondExchange, activeItem } = data;
      const imgUrlDarkLight = useColorModeValue(`${imgUrl}_light`, imgUrl);

      const renderIconActive = () => {
        return <Flex ml="10px" w="8px" h="8px" mt="8px" borderRadius="50%" bg="teal.150" />;
      };

      return (
        <Flex
          className="parent-modal-balance-row"
          w="100%"
          _hover={{ bg: gray700ToGray50, cursor: 'pointer' }}
          borderRadius={'12px'}
          h={isSmallerThan1024 ? '80px' : '90px'}
          align="center"
          cursor="pointer"
        >
          <Flex justifyContent={'center'} mr="20px" flexDirection={'column'} pl="24px">
            <Image mx="auto" boxSize={'32px'} src={`/img/${imgUrlDarkLight}.svg`} />
            <Flex w="100%" hidden={!data?.isNew}>
              <Typography
                mt="4px"
                px="6px"
                borderRadius="5px"
                bg="rgba(26, 196, 134, 0.2)"
                color="teal.150"
                type="body1"
                w="fit-content"
                fontSize="12px"
                fontWeight={'medium'}
              >
                New
              </Typography>
            </Flex>
          </Flex>
          <Flex flexDirection={'column'} w="60%">
            <Flex
              fontSize={'16px'}
              fontWeight={'500'}
              color={
                location.pathname === href ||
                (activeItem && location?.pathname?.includes(activeItem)) ||
                location.pathname.includes(isBondExchange)
                  ? 'teal.150'
                  : whiteToGray600
              }
            >
              {t(title)}
              {title === 'buying_competition' && newBuyingComp && renderIconActive()}
              {title === 'governance' && isGovernance && renderIconActive()}
              {title === 'referral_competition' && newReferralComp && renderIconActive()}
            </Flex>
            <Typography color={gray500ToGray400} w="100%" type="paragraph3">
              {t(description)}
            </Typography>
          </Flex>
          <Spacer />
          <Flex w="15%" justify="center" align="center">
            <Image
              mx="6px"
              display={isSmallerThan1024 ? 'none' : 'flex'}
              className={!isSmallerThan768 ? 'arrow-child-animation' : ''}
              boxSize="10px"
              src="/img/arrow-teal.svg"
            />
          </Flex>
        </Flex>
      );
    };

    // handle redirect
    const dispatch = useDispatch();
    const hasNewBond = useHasNewBond();
    const isClickNewBond = useIsClickNewBond();
    const [handleInDev] = useModal(<InDevelopModal isOpenModal={true} />);
    const handleClickToBondPage = () => {
      hasNewBond.length !== isClickNewBond && dispatch(setHasNewBond({ visit: hasNewBond.length }));
    };

    const renderContentDetail = (data: any) => {
      const { isLink, href, isBond, isDev } = data ?? {};

      if (isLink) {
        return (
          <LinkCharkra _hover={{ textDecoration: 'none' }} href={href}>
            {detailComponent(data, href)}
          </LinkCharkra>
        );
      }

      if (isDev) {
        return (
          <Flex w="100%" onClick={handleInDev}>
            {detailComponent(data, href)}
          </Flex>
        );
      }

      if (isBond) {
        return (
          <Link to={'/bonds'} onClick={handleClickToBondPage}>
            {detailComponent(data, href)}
          </Link>
        );
      }
      return (
        <Link style={{ width: '100%' }} to={href}>
          {detailComponent(data, href)}
        </Link>
      );
    };

    return (
      <>
        {data.map((e, index) => {
          return <Flex key={`${data?.title}_${index}`}> {renderContentDetail(e)} </Flex>;
        })}
      </>
    );
  };

export default ItemDetailMenu;
