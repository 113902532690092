import { createSlice } from '@reduxjs/toolkit';
import { VaultConfig } from '../../configs/constants/types';
import vaultsConfig from '../../configs/constants/vaults';
import { Vault, VaultState } from '../types';
import fetchVaults from './fetchVault';
import { fetchVaultUserData } from './fetchVaultUser';

const initialState: VaultState = { data: [...vaultsConfig], myReferralCode: 0 };

export const vaultsSlice = createSlice({
  name: 'Vaults',
  initialState,
  reducers: {
    setVaultsPublicData: (state, action) => {
      const liveVaultsData: Vault[] = action.payload;
      state.data = state.data.map((vault) => {
        const liveVaultData = liveVaultsData.find((f) => f.pid === vault.pid);
        return { ...vault, ...liveVaultData };
      });
    },
    setVaultUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      arrayOfUserDataObjects.forEach((userDataEl: any) => {
        const { index } = userDataEl;
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
    },
    setMyReferralCode: (state, action) => {
      const data = state;
      data.myReferralCode = action?.payload;
    },
  },
});
export const { setVaultsPublicData, setVaultUserData, setMyReferralCode } = vaultsSlice.actions;

export const fetchVaultsPublicDataAsync = () => async (dispatch: any) => {
  try {
    const vaults = await fetchVaults();
    dispatch(setVaultsPublicData(vaults));
  } catch (e) {}
};

export const getMyReferralCode = (account: string) => async (dispatch: any) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_REFERRAL_SERVICE_BASE_URL}/getVaultRefCode?address=${account}`,
    ).then((res) => res.json());
    if (response?.success) {
      dispatch(setMyReferralCode(response?.code || 0));
    } else {
      dispatch(setMyReferralCode(0));
    }
  } catch (e) {
    dispatch(setMyReferralCode(0));
  }
};

export const fetchVaultUserDataAsync = (account: string) => async (dispatch: any) => {
  if (account) {
    try {
      const arrayOfUserDataObjects = await Promise.all(
        vaultsConfig.map(async (vaultConfig: VaultConfig, index: number) => {
          const vaultDataForUser = await fetchVaultUserData(account, vaultConfig);
          return { index, ...vaultDataForUser };
        }),
      );
      dispatch(setVaultUserData({ arrayOfUserDataObjects }));
    } catch (e) {}
  } else {
    const arr = vaultsConfig.map((a, index) => ({
      index,
      stakedBalance: 0,
      earned: 0,
      pendingEarned: 0,
      allowance: 0,
      tokenBalance: 0,
      farmLpBalance: 0,
      allowanceLpFarm: 0,
      vaultReferralCode: 0,
      totalLpStaked: 0,
      busdBalance: 0,
      posiBalance: 0,
      allowancePosi: 0,
    }));
    dispatch(
      setVaultUserData({
        arrayOfUserDataObjects: arr,
      }),
    );
  }
};

export default vaultsSlice.reducer;
