import {
  Flex,
  Modal as ModalMain,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../components/Button';

export interface IModalClaimCollateral {
  isOpenModal: boolean;
  onDismiss?: (val?: any) => void;
}

const ModalClaimCollateral: React.FC<IModalClaimCollateral> = ({ isOpenModal, onDismiss = () => null }) => {
  const { t } = useTranslation(['bonds']);
  const [isSmallThan768] = useMediaQuery('(max-width: 768px ) and (min-width: 0px )');

  return (
    <ModalMain
      size={'md'}
      blockScrollOnMount={false}
      isOpen={isOpenModal}
      onClose={onDismiss}
      isCentered={!isSmallThan768}
    >
      <ModalOverlay width="100%" height="100%" />
      <ModalContent mx="12px" background={'gray.800'} p="24px" borderRadius="24px">
        <ModalHeader p={0} fontSize={'24px'} color="white" textAlign={'center'}>
          {t('claiming_collateral')} (?)
        </ModalHeader>
        <ModalCloseButton color={'gray.500'} mt={'20px'} onClick={onDismiss} />
        <ModalBody p="0px">
          <Flex bg="gray.900" mt="16px" p="16px" borderRadius="12px">
            <Text color="gray.400">{t('claiming_collateral_title')}</Text>
          </Flex>
          <Flex mt="16px" color="gray.200">
            {t('claiming_collateral_description')}
          </Flex>
          <Flex w="100%">
            <ButtonComponent onClick={onDismiss} w="100%" mt="16px" variant="contained">
              {t('acknowledge')}
            </ButtonComponent>
          </Flex>
        </ModalBody>
      </ModalContent>
    </ModalMain>
  );
};

export default ModalClaimCollateral;
